/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import $ from 'jquery';
import sanitize from 'service/sanitize-string/sanitize-string';

export default function (attributeName, model, options = {}) {
	const __label = document.createElement('span');

	const setText = function (text) {
		$(__label).html(sanitize(text));

		if (options.wrapWord) {
			$(__label).addClass('wrapword');
		}
	};

	/* refresh the displayed value */
	const __refresh = function () {
		let value;

		if (options.valueProcessor) {
			value = options.valueProcessor.call(this, model.get(attributeName));
			setText(value);

		} else {
			value = options.displayValue ?
				options.displayValue.getDisplayValue(model.get(attributeName)) :
				model.get(attributeName);

			setText((value === null) ? '' : value); // empty string for null
		}
	};

	/* binding the refresh if value of model attribute has changed */
	model.on(`change:${attributeName}`, __refresh);

	__refresh();

	this.getDOMNode = function () {
		return __label;
	};
}

