import TileView from 'tile-view';
import { assign, forEach } from 'lodash';
import t from 'translate';
import formView from 'components/form-view/form-view';
import store from 'store';
import { chooseRoute, formCfgFactory } from '../../shared';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('general-list.Administrator'),

	acl: [{
		op: READ,
		checkpoint: 'system.settings.settings'
	}],

	actions: () => ['systemSettings/init', 'languages/init', 'timezones/init'],

	loaded: ({ tile }) => {
		const formViews = [];

		// create new FormView instance for each custom attribute
		forEach(store.getters['systemSettings/clinician'], (setting) => {
			const cfg = formCfgFactory({ setting });

			chooseRoute([{
				when: () => setting.systemSettingLabel === 'admin.i18n.defaultLanguage',
				then: () => {
					assign(cfg.fields[0], {
						type: 'select',
						collection: store.state.languages.collection,
						labelField: 'languageLabel',
						keyField: 'languageId'
					});
				}
			}, {
				when: () => setting.systemSettingLabel === 'admin.i18n.defaultTimezone',
				then: () => {
					assign(cfg.fields[0], {
						type: 'select',
						collection: store.state.timezones.collection,
						labelField: 'timezoneName',
						keyField: 'timezoneName'
					});
				}
			}, {
				when: () => setting.systemSettingSourceId === 'system.settings.deadlineUnit',
				then: () => {
					assign(cfg.fields[0], {
						type: 'select',
						values: [{
							label: t('Minutes'),
							value: 'Minutes'
						}, {
							label: t('Hours'),
							value: 'Hours'
						}, {
							label: t('Days'),
							value: 'Days'
						}],
						labelField: 'label',
						keyField: 'value'
					});
				}
			}]);

			formViews.push(formView(cfg).$el);
		});

		tile.$el.append(formViews);
	}
});

