import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import ExportTemplate from './export-template';

const ExportTemplatesEntity = MultipleEntities.extend({
	urls,
	model: ExportTemplate,
	idName: 'dataExportTemplateId',
	eventNamespace: 'export-template',

	getAll () {
		return this.retrieve('templates');
	},

	getReady () {
		return this.retrieve('readyTemplates');
	}
});

export default new ExportTemplatesEntity();
