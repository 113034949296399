import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import moment from 'moment';
import PlanInstance from 'repo/plan-instances/entity';
import repository from 'repository';
import store from 'store';
import systemSettings from 'system-settings';
import t from 'translate';
import TileView from 'tile-view';
import warning from 'modules/treatments/components/warning';
import handleReady from 'modules/treatments/shared/handle-ready';
import { assign, get, isUndefined, split } from 'lodash';
import { Collection } from 'backbone';
import redirectOnSave from 'modules/general/shared/redirect-on-save';
import { ADD } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';

const RESPONDENT_ID = 'respondent/respondentId';

export default TileView.extend({
	title: t('Add plan'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.PLANS,
		op: ADD
	}],

	tileData: () => ({
		respondent: store.getters.respondent,
		treatment: store.getters.treatment,
		currentTreatment: (store.state.treatment || {}).model,
		treatments: new Collection(),
		plans: new Collection()
	}),

	fetchPlans: (tile) => {
		tile.plans.reset();
		const plans = repository.currentClinicianPlans({
			query: '',
			enabled: true,
			manual: true,
			withNoTreatmentType: 1,
			treatmentType: tile.currentTreatment.get('treatmentType').id ||
				tile.currentTreatment.get('treatmentType'),
			start: 0
		});
		plans.then(() => {
			tile.plans.reset(plans.models);
		});
	},

	init: ({ tile }) => {
		const initialDate = get(store.getters.urlParams, 'datetime');
		const startTime = split(systemSettings.getString('DEFAULT_ASSESSMENT_TIME'), ':');
		const startTimestamp = moment(initialDate).hours(startTime[0]).minutes(startTime[1]).unix();

		tile.limit = tile.config().limit || 10;
		tile.searchable = tile.config().searchable;

		const newPlanProps = assign(
			{ start: datetime(startTimestamp * 1000).toObject() },
			tile.respondent && { respondentId: tile.respondent.respondentId },
			tile.treatment && { treatment: tile.treatment.id }
		);

		tile.planInstance = new PlanInstance(newPlanProps);

		if (tile.currentTreatment) {
			tile.fetchPlans(tile);
		}

		tile.listenTo(tile.planInstance, 'change:treatment', (planInstance, treatmentId) => {
			tile.currentTreatment = tile.treatments.get(treatmentId);

			if (tile.searchable) {
				return;
			}

			tile.fetchPlans(tile);
		});
	},

	determineDisabled: (view, formView, formModel) => {
		view.listenTo(formModel, 'change:treatment', () => {
			view.model.set('disabled', !formModel.has('treatment'));
		});
	},

	loaded: ({ tile }) => {
		tile.$el.append(warning(tile.currentTreatment).$el);

		let timezone = tile.respondent ? tile.respondent.respondentTimezoneName : false;

		const searchTreatments = ({ view, respondentId }) => {
			if (isUndefined(respondentId)) {
				view.model.set('disabled', true).set('value', null);
				return view.collection.reset();
			}
			view.setLoading();

			store.dispatch('treatments/initForMainRespondent', { respondentId }).then(() => {
				view.setLoaded();
				view.collection.reset(store.getters['treatments/ready']);

				const isSingleTreatment = view.collection.size() === 1;

				if (isSingleTreatment) {
					tile.planInstance.set('treatment', view.collection.models[0].get('id'));
					view.setInitial();
				}

				!view.collection.size() && view.model.set('value', null);

				view.model.set('disabled', isSingleTreatment);
				view.setReadOnly(isSingleTreatment);
			});
		};

		const fields = [!store.getters[RESPONDENT_ID] && {
			key: 'respondentId',
			type: 'search',
			label: t('Respondent'),
			mandatory: true,
			limit: tile.limit,
			provideResults: (search) => repository.searchRespondents({
				search,
				respondentAccountEnabled: true,
				start: 0,
				limit: tile.limit
			}),
			placeholder: t('Type respondent name'),
			itemLabel: (model) => model.displayName(),
			customize: (view, formView) => {
				formView.on('change:respondentId', (changedModel, changedValue) => {
					const respondents = view.provideResults();
					respondents.then(() => {
						const respondent = respondents.get(changedValue);

						if (changedValue && respondent) {
							timezone = respondent.get('respondentTimezoneName');
						}
					});
				});
			}
		}, !store.getters['treatment/treatmentId'] && {
			key: 'treatment',
			type: 'select',
			collection: tile.treatments,
			description: t('Select a treatment'),
			label: t('Treatment'),
			readonly: !tile.planInstance.has('respondentId'),
			mandatory: true,
			customize: (view) => {
				if (store.getters[RESPONDENT_ID]) {
					searchTreatments({
						view,
						respondentId: store.getters[RESPONDENT_ID]
					});
				}

				view.listenTo(
					tile.planInstance,
					'change:respondentId',
					(changedModel, respondentId) => {
						searchTreatments({ view, respondentId });
					}
				);
			}
		}, tile.searchable ?
			{
				key: 'workflowId',
				type: 'search',
				label: t('Plan'),
				mandatory: true,
				disabled: !tile.planInstance.has('treatment'),
				provideResults: (search) => repository.currentClinicianPlans({
					query: search === ' ' ? '' : search,
					enabled: true,
					manual: true,
					withNoTreatmentType: 1,
					treatmentType: tile.currentTreatment.get('treatmentType').id ||
						tile.currentTreatment.get('treatmentType'),
					start: 0,
					limit: tile.limit
				}),
				placeholder: t('Type plan name'),
				itemLabel: (model) => model.get('name'),
				collection: tile.plans,
				customize: tile.determineDisabled
			} :
			{
				key: 'workflowId',
				type: 'select',
				label: t('Plan'),
				disabled: !tile.planInstance.has('treatment'),
				mandatory: true,
				collection: tile.plans,
				labelField: 'name',
				keyField: 'workflowId',
				description: t('Select plan'),
				customize: tile.determineDisabled
			},
		{
			label: t('Start date'),
			key: 'start',
			type: 'datetime',
			mandatory: true
		}];

		tile.form = formView({
			name: 'add-plan',
			model: tile.planInstance,
			listenTo: ['submit'],
			beforeSave: () => {
				tile.planInstance.setStartDate(tile.planInstance.get('start'), timezone);

				if (!tile.planInstance.get('respondentId')) {
					const respondentId = store.getters[RESPONDENT_ID];
					tile.planInstance.set('respondentId', respondentId);
				}
			},
			onAfterSave: () => {
				redirectOnSave({
					config: get(tile.config(), 'redirectOnSave', {}),
					fallback: ['plan-properties', { planInstanceId: tile.planInstance.getId() }],
					otherwise: () => {
						// disable submit, user have to change something
						tile.form.disableSubmit();
					}
				});

				tile.planInstance.unset('workflowInstanceId');
			},
			fields,
			buttons: [{
				caption: t('Add'),
				type: 'submit',
				name: 'add-workflow-instance-submit',
				role: 'submit'
			}]
		});

		tile.planInstance.on('change', () => {
			tile.handleComplete({ tile });
		});

		handleReady(tile);
		tile.handleComplete({ tile });
	},

	handleComplete: ({ tile }) => {
		if ((tile.treatment && !tile.treatment.complete)) {
			tile.form.disableSubmit();

		} else {
			tile.form.enableSubmit();
		}
	}
});
