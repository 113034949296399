const DEFAULT_ASSESSMENT_ORDER = 'first';

export default () => ({
	folder: {
		type: 'folder',
		fields: {
			name: 'Group',
			context: null,
			assessmentContext: null,
			assessmentOrder: null,
			customCode: false
		},
		children: []
	},
	assessment: {
		type: 'assessment',
		fields: {
			name: 'Assessment data wizard'
		},
		preventAdd: true
	},
	respondent: {
		type: 'respondent',
		fields: {
			name: 'Respondent data wizard'
		},
		preventAdd: true
	},
	treatment: {
		type: 'treatment',
		fields: {
			name: 'Treatment data wizard'
		},
		preventAdd: true
	},
	assessmentQuestion: {
		type: 'assessmentQuestion',
		fields: {
			assessment: null,
			assessmentOrder: DEFAULT_ASSESSMENT_ORDER,
			assessmentContext: null,
			identity: null,
			assessmentQuestionCode: null,
			name: 'Assessment question'
		},
		warning: false
	},
	assessmentCalculation: {
		type: 'assessmentCalculation',
		fields: {
			assessment: null,
			assessmentOrder: DEFAULT_ASSESSMENT_ORDER,
			assessmentContext: null,
			identity: null,
			name: 'Assessment calculation'
		},
		warning: false
	},
	assessmentAttribute: {
		type: 'assessmentAttribute',
		fields: {
			assessment: null,
			assessmentOrder: DEFAULT_ASSESSMENT_ORDER,
			assessmentContext: null,
			identity: null,
			name: 'Assessment attribute'
		},
		warning: false
	},
	assessmentFlag: {
		type: 'assessmentFlag',
		fields: {
			assessment: null,
			assessmentOrder: DEFAULT_ASSESSMENT_ORDER,
			assessmentContext: null,
			identity: null,
			name: 'Assessment flag'
		},
		warning: false
	},
	respondentAttribute: {
		type: 'respondentAttribute',
		fields: {
			name: 'Respondent attribute',
			identity: null
		},
		warning: false
	},
	respondentCustomAttribute: {
		type: 'respondentCustomAttribute',
		fields: {
			name: 'Respondent custom attribute',
			identity: null
		},
		warning: false
	},
	respondentCustomAttributeHistory: {
		type: 'respondentCustomAttributeHistory',
		fields: {
			assessmentOrder: DEFAULT_ASSESSMENT_ORDER,
			name: 'Respondent custom attribute history',
			identity: null
		},
		warning: false
	},
	treatmentAttribute: {
		type: 'treatmentAttribute',
		fields: {
			identity: null,
			name: 'Treatment attribute'
		},
		warning: false
	},
	treatmentCustomAttribute: {
		name: 'Treatment custom attribute',
		type: 'treatmentCustomAttribute',
		fields: {
			treatmentType: null,
			identity: null,
			name: 'Treatment custom attribute'
		},
		warning: false
	}
});
