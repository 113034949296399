import { View } from 'backbone';
import Vue from 'vue';
import store from 'store';
import t from 'translate';
import can from 'acl-can';

export default View.extend({
	clear () {
		this.$el.empty();
		return this;
	},

	render () {
		const vm = new Vue({
			el: document.createElement('div'),
			store,
			data: () => ({
				canAdd: can.edit('system.workflows.workflows'),
				canRemove: can.edit('system.workflows.workflows'),
				fields: ['code'],
				labels: {
					empty: t('plan-editor.trigger.assessment-flag.no-flags-selected'),
					code: t('plan-editor.trigger.assessment-flag.column.flag-code'),
					removeItem: t('Remove flag'),
					removeQuestion: t('Are you sure you wish to remove flag?'),
					removeSuccess: t('Flag removed successfully'),
					selectItem: t('plan-editor.trigger.assessment-flag.add-prompt'),
					addSuccess: t('Flag added')
				},
				getters: {
					allItems: 'assessmentFlags/all',
					items: 'plan/flags'
				},
				actions: {
					removeItem: 'plan/removeFlag',
					addItem: 'plan/addFlag'
				}
			}),
			template: `
				<assign-items 
					:can-remove="canRemove"
					:fields="fields"
					:labels="labels"
					:getters="getters"
					:actions="actions"
				/>
			`
		});

		this.$el.append(vm.$el);
	}
});
