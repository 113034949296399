import { toLower } from 'lodash';
import repository from 'repository';

export default async (rolesetName) => {
	const results = await repository.rolesetByName(rolesetName);

	if (results.length && toLower(results[0].name) === toLower(rolesetName)) {
		return false;
	}

	return true;
};
