import { escape, map, trim } from 'lodash';
import $, { when } from 'jquery';
import t from 'translate';
import repository from 'repository';
import CardDialog from 'service/card-dialog/card-dialog';
import formView from 'components/form-view/form-view';
import can from 'acl-can';
import Assignment from 'components/assignment';
import handleResponseErrors from 'modules/general/shared/handle-response-errors';

const escapeModels = (models) => map(models, (model) => {
	model.set('respondentGroupCategory', escape(model.get('respondentGroupCategory')));
	model.set('respondentGroupName', escape(model.get('respondentGroupName')));
	return model;
});

export default function (group) {
	const respondentGroupsForClinicianGroup = repository
		.getRespondentGroupsForClinicianGroup(group.getId());
	const respondentGroups = repository.getRespondentGroups();

	const $container = $('<div />');
	const dialog = new CardDialog({
		content: $container[0],
		title: t('Edit group')
	});

	dialog.setLoading();

	when(
		group,
		respondentGroupsForClinicianGroup,
		respondentGroups
	).then(() => {
		respondentGroups.models = escapeModels(respondentGroups.models);
		dialog.setLoaded();
		const form = formView({
			name: 'edit-clinician-group',
			model: group,
			readonly: !can.edit('system.settings.administratorgroups'),
			errorMessage: handleResponseErrors,
			fields: [{
				key: 'administratorGroupName',
				label: t('Name'),
				type: 'text',
				required: true,
				validators: {
					validator: (value) => !!trim(value),
					invalidMessage: t('system-settings.clinician-groups.cannot-be-empty')
				}
			}, {
				key: 'administratorGroupCategory',
				label: t('Category'),
				type: 'text'
			}]
		});

		dialog.onCancel = function () {
			!form.model.get('administratorGroupName').length && form.model.revert();
		};

		$container.append(form.$el);

		const assignment = new Assignment({
			label: t('Assigned respondent groups'),
			parent: $container[0],
			readonly: !can.edit('system.settings.administratorgroups'),
			allItemsCollection: respondentGroups,
			relationCollection: respondentGroupsForClinicianGroup,
			relationAttributes: { administratorGroupId: group.getId() },
			itemPrimaryKey: 'respondentGroupId',
			selectorLabel: t('Select group to add'),
			afterAdd: () => {
				respondentGroupsForClinicianGroup.fetch()
					.then((items) => assignment.table.setItems(items));
			},
			selectorRender: (item) =>
				`${item.respondentGroupName} [${item.respondentGroupCategory}]`,
			columns: [{
				key: 'respondentGroupName',
				label: t('Group name')
			}, {
				key: 'respondentGroupCategory',
				label: t('Group category')
			}],
			emptyListMessage: t('The clinician group has no respondent groups assigned')
		});
	});
}
