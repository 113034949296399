import t from 'translate';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import consentDialog from '../dialogs/consent';
import appBar from 'app-bar';

const card = {
	name: 'consents',
	title: t('Consent templates'),
	url: 'consents',

	ctrl: ({ populate }) =>  {
		if (can.add('system.settings.consents')) {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'add',
				title: t('Add consent'),
				click: () => {
					const redirect = (consentId) => {
						cardUrl.openCard('edit-consent', {
							consentId
						});
					};
					consentDialog({
						consent: {},
						action: 'consent/addConsent',
						successMsg: t('New consent has been set'),
						title: t('Make a new consent'),
						done: redirect
					});
				}
			});
		}
		populate();
	}
};

export { card as consents };
