import { assign } from 'lodash';
import $, { when } from 'jquery';
import { View } from 'backbone';
import t from 'translate';
import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import cardurl from 'cwcardurl';
import appContext from 'app-context';
import ExportInstance from 'repo/exports/export-instance';
import repository from 'repository';

export default View.extend({
	title: t('Create new export'),
	acl: [{
		checkpoint: 'system.export.templates',
		op: 'ADD'
	}],

	render () {
		this.setLoading();
		assign(this, {
			$selectEl: $('<div />').appendTo(this.el),
			$formEl: $('<div />').appendTo(this.el),
			contexts: repository.getContexts(),
			exportTemplates: repository.getReadyExportTemplates()
		});

		when(this.contexts, this.exportTemplates)
			.done(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	populateInstance () {
		const template = this.exportTemplate;

		this.exportInstance = new ExportInstance({
			fkDataExportInstanceTemplateId: template.get('dataExportTemplateId'),
			dataExportInstanceAllowOverride: template.get('dataExportTemplateAllowOverride'),
			dataExportInstanceIsSubmittedOnly: template.get('dataExportTemplateIsSubmittedOnly'),
			dataExportInstanceIncludeUnsubmittedAssessments:
			!template.get('dataExportTemplateIsSubmittedOnly'),
			dataExportInstanceFilterContext: template.get('dataExportTemplateFilterContext'),
			dataExportTemplateTreatmentTypes: template.get('dataExportTemplateTreatmentTypes'),
			dataExportInstanceFilterDateRange: template.get('dataExportTemplateFilterDateRange'),
			start: template.get('dataExportTemplateFilterDateRangeStart'),
			stop: template.get('dataExportTemplateFilterDateRangeStop'),
			dataExportInstanceFileFormat: template.get('dataExportTemplateFileFormat'),
			dataExportInstanceIsAvailableApi: template.get('dataExportTemplateIsAvailableApi'),
			dataExportInstanceIsPublic: template.get('dataExportTemplateIsPublic'),
			patient: template.get('patient') || true,
			testRespondent: template.get('testRespondent') || false
		});
		return this;
	},

	loaded () {
		if (!this.exportTemplates.length) {
			this.$el.append(
				$('<h2 />').html(t('There are no exportable templates available'))
			);
			return;
		}

		this.exportTemplate = this.exportTemplates.at(0);
		this.populateInstance().renderForm();
	},

	renderForm () {
		const inst = this;
		const exportInstance = this.exportInstance;

		this.$el.append(
			$('<h2 />').html(t('system-manager.FilterExport'))
		);

		this.listenTo(this.exportInstance, 'change:fkDataExportInstanceTemplateId', (model, id) => {
			this.exportTemplate = this.exportTemplates.findWhere({
				dataExportTemplateId: `${id}`
			});
			this.populateInstance().renderForm();
		});

		const cfg = {
			name: 'system-export-assessmentdata',
			model: this.exportInstance,
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			readonly: !inst.exportTemplate.get('dataExportTemplateAllowOverride'),

			onBeforeSave () {
				const unsubmitted =
					!exportInstance.get('dataExportInstanceIncludeUnsubmittedAssessments');
				exportInstance.set('dataExportInstanceIsSubmittedOnly', unsubmitted);

				if (!!exportInstance.get('start') && !!exportInstance.get('stop')) {
					const start = datetime(exportInstance.get('start'))
						.toUtcFromTimezone(appContext.get('user').get('timezoneName'))
						.toObject();
					const stop = datetime(exportInstance.get('stop'))
						.toUtcFromTimezone(appContext.get('user').get('timezoneName'))
						.toObject();

					this.model.set({
						dataExportInstanceFilterDateRangeStart: start,
						dataExportInstanceFilterDateRangeStop: stop
					});
				}
			},
			onAfterSave () {
				inst.card().close();
				cardurl.openCard('system-exports-templates');
				appContext.trigger('system-export-instance.add');
			},
			fields: [{
				key: 'fkDataExportInstanceTemplateId',
				label: t('Select template'),
				type: 'select',
				collection: this.exportTemplates,
				labelField: 'dataExportTemplateName',
				keyField: 'dataExportTemplateId',
				readonly: false
			}, {
				key: 'dataExportInstanceAllowOverride',
				type: 'checkbox',
				label: t('export-template-management.Override allowed'),
				readonly: true
			}, {
				key: 'dataExportInstanceIncludeUnsubmittedAssessments',
				type: 'checkbox',
				label: t('export-template-management.IncludeUnsubmittedAssessments')
			}, {
				key: 'patient',
				type: 'checkbox',
				label: t('export-template-management.Include production data'),
				readonly: false
			}, {
				key: 'testRespondent',
				type: 'checkbox',
				label: t('export-template-management.Include test data'),
				readonly: false
			}, {
				key: 'dataExportInstanceFilterContext',
				type: 'select',
				multiple: true,
				label: t('export-template-management.ExportTemplateFilterContext'),
				collection: this.contexts,
				labelField: 'assessmentInstanceContextLabel',
				keyField: 'assessmentInstanceContextId',
				readonly: false
			}, {
				key: 'dataExportInstanceFilterDateRange',
				label: t('export-template-management.ExportTemplateDateField'),
				type: 'select',
				values: [{
					ALL: t('general-list.all')
				}, {
					WEEK: t('general-list.lastWeek')
				}, {
					MONTH: t('general-list.lastMonth')
				}, {
					QUARTER: t('general-list.lastQuarter')
				}, {
					YEAR: t('general-list.lastYear')
				}, {
					CUSTOM: t('general-list.range')
				}]
			}, {
				key: 'start',
				label: t('export-template-management.ExportTemplateFilterFromDate'),
				type: 'date',
				mandatory: true,
				show: {
					event: 'change:dataExportInstanceFilterDateRange',
					condition (model) {
						return model.get('dataExportInstanceFilterDateRange') === 'CUSTOM';
					}
				}
			}, {
				key: 'stop',
				label: t('export-template-management.ExportTemplateFilterToDate'),
				type: 'date',
				mandatory: true,
				show: {
					event: 'change:dataExportInstanceFilterDateRange',
					condition (model) {
						return model.get('dataExportInstanceFilterDateRange') === 'CUSTOM';
					}
				}
			}, {
				key: 'dataExportInstanceFileFormat',
				label: t('export-template-management.ExportTemplateFileFormat'),
				type: 'select',
				values: [{
					CSV: 'CSV'
				}, {
					SPSS: 'SPSS'
				}]
			}],

			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.html(formView(cfg).$el);
	}
});

