import can from 'acl-can';
import appbar from 'app-bar';
import t from 'translate';
import newRespondentGroup from '../dialogs/new-respondent-group';

const card = {
	name: 'system-settings-respondent-groups',
	title: t('Respondent groups'),
	url: 'system/respondent-groups',

	ctrl: ({ populate }) => {
		can.add('system.settings.respondentgroups') && appbar.addButton({
			sourceCard: card.name,
			icon: 'add',
			title: t('New respondent group'),
			click: newRespondentGroup
		});

		populate();
	}
};

export { card as respondentGroups };

