var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sidebar-preview" },
    [
      _c("sidebar-items", {
        attrs: {
          items: _vm.items,
          type: _vm.state.type,
          clickable: _vm.clickable
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }