export default {
	closeCard ({ respondent, data, close, name }) {
		const currentRespondentId =
			+data.get('respondentId') ||
			+data.get('id');
		const removedRespondentId = +respondent.getId();

		if (currentRespondentId === removedRespondentId) {
			close({ name });
		}
	}
};
