<template>
	<div>
		<section v-if="canAdd('data_integration_rules')" class="data-integration__add-container">
			<button
				v-translate
				:disabled="!assessments || !codes"
				class="submit-button__button submit-button__button--submit"
				type="submit"
				@click="add"
			>dataIntegration.dataMapping.actions.add</button>
		</section>
		<div v-if="dataMappingList.length">
			<table class="data-integration__data-mapping__table table">
				<thead>
					<tr>
						<th
							v-for="(header, i) in translatedHeaders"
							:key="i"
							scope="col"
						>{{header}}</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in dataMappingList"
						:key="item.id"
						:class="rowClass"
						tabindex="0"
						@click="update(item)"
					>
						<td>
							{{item.superAssessment.code}}
						</td>
						<td>
							{{portalLabel(item.submitPortal)}}
						</td>
						<td>
							{{item.code.code}}
						</td>
						<td>
							{{item.code.codeType.code}}
						</td>
						<td :class="allowClass(item, className)">
							{{allowLabel(item, 'dataMapping')}}
						</td>
						<td v-if="canDelete('data_integration_rules')">
							<button
								class="icon-button__button"
								@click.stop="remove(item)"
							>
								<icon name="delete" :small="true" />
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<p v-translate>
				dataIntegration.dataMapping.hint
			</p>
		</div>
		<div v-else v-translate>
			No items
		</div>
	</div>
</template>

<script>
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import dialog from '../../dialogs/data-mapping';
import store from 'store';
import translateHeaders from '../../helpers/translate-headers';
import t from 'translate';
import { allowClass, allowLabel } from '../../helpers/allow-handler';
import { mapGetters } from 'vuex';
import { canAdd, canDelete, canUpdate } from '../../helpers/acl-handler';
import { assign, filter, find, get, map, uniqBy } from 'lodash';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	data: ({ tile }) => ({
		allowClass,
		allowLabel,
		className: 'data-integration__data-mapping',
		emptyData: { access: true, code: null, superAssessment: null, submitPortal: null },
		fields: ['assessment', 'portal', 'code', 'codeType', 'allow'],
		id: tile.cardContext().get('ruleSetId'),
		modalCfg: {
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
			action: 'addAndRefreshDataMapping',
			item: {},
			success: t('dataIntegration.dataMapping.messages.added'),
			title: 'dataIntegration.dataMapping.actions.add',
			type: 'dataMapping'
		},
		portalOptions: [{
			value: 'ALL',
			label: 'ALL'
		},
		{
			value: 'ADMIN-PORTAL',
			label: 'Clinician'
		}, {
			value: 'RESPONDENT-PORTAL',
			label: 'Respondent'
		}],
		updating: false
	}),

	computed: {
		...mapGetters({
			assessments: 'dataIntegration/assessmentList',
			codes: 'dataIntegration/codes/list',
			dataMappingList: 'dataIntegration/dataMappingList',
			params: 'urlParams',
			ruleSetData: 'dataIntegration/ruleSets/singleData'
		}),

		availableCodes () {
			const enterprise = get(this.ruleSetData, 'enterprise.id', null);
			return filter(this.codes, (code) => {
				if (enterprise && !code.enterprise) {
					return false;
				} else if (!code.enterprise) {
					return true;
				}
				return code.enterprise.id === enterprise;
			});
		},

		codeTypes: ({ availableCodes }) => uniqBy(
			map(availableCodes, 'codeType'),
			'id'
		),

		rowClass () {
			return this.canUpdate('data_integration_rules') ? 'clickable-row' : '';
		},

		translatedHeaders () {
			return translateHeaders({
				fields: this.fields,
				translation: 'dataIntegration.dataMapping.fields'
			});
		}
	},

	methods: {
		add () {
			dialog(assign({
				action: 'addAndRefreshDataMapping',
				assessments: this.assessments,
				codes: this.availableCodes,
				portalOptions: this.portalOptions,
				codeTypeOptions: this.codeTypes
			}, assign(this.modalCfg, { item: { ...this.emptyData } })));
		},

		canAdd,

		canDelete,

		canUpdate,

		portalLabel (item) {
			return find(this.portalOptions, { value: item }).label;
		},

		remove (item) {
			return confirmation({
				icon: 'delete',
				warning: true,
				title: t('dataIntegration.dataMapping.actions.remove'),
				message: t('dataIntegration.dataMapping.actions.delete')
			}).done(() => {
				const data = { mappingId: item.id, ruleSet: { id: this.params.ruleSetId } };
				store.dispatch('dataIntegration/deleteAndRefreshDataMapping', data)
					.then(() => {
						cwalert.success(t('dataIntegration.dataMapping.messages.deleted'));
					});
			});
		},

		update (item) {
			if (this.canUpdate('data_integration_rules')) {
				const superAssessment = {
					label: item.superAssessment.code,
					value: item.superAssessment.id
				};
				const code = {
					label: item.code.code,
					value: item.code.id,
					codeType: {
						code: item.code.codeType ?
							item.code.codeType.code :
							''
					}
				};
				const submitPortal = find(this.portalOptions, { value: item.submitPortal });
				dialog({
					...this.modalCfg,
					action: 'updateAndRefreshDataMapping',
					item: {
						...item,
						code,
						submitPortal,
						superAssessment,
						ruleSetId: this.params.ruleSetId
					},
					assessments: this.assessments,
					codes: this.availableCodes,
					codeTypeOptions: this.codeTypes,
					portalOptions: this.portalOptions,
					success: t('dataIntegration.dataMapping.messages.updated'),
					title: 'dataIntegration.dataMapping.actions.update'
				});
			}
		}
	}
};
</script>
