import t from 'translate';
import appBar from 'app-bar';
import store from 'store';

const card = {
	title: t('consents.consent'),
	name: 'consent-instance',
	url: 'consent/:consentInstanceId',

	ctrl: ({ actions }, { consentInstanceId }) => actions([
		['consentInstance/init', { consentInstanceId }],
		['consentInstance/initOptions', { consentInstanceId }]
	]).then(() => {
		if (store.getters['consentInstance/clinicianCanSubmit']) {
			appBar.addCardLink({
				sourceCard: card.name,
				card: 'submit-consent',
				icon: 'edit',
				title: t('Edit consent instance'),
				query: { consentInstanceId }
			});
		}
	})
};

export { card as consentInstance };
