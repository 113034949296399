import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { assign } from 'lodash';

export default SingleEntity.extend({
	urlRoot: urls.clinicianInGroup.url,
	eventNamespace: 'clinician-in-clinician-group',
	idAttribute: 'relationId',
	initialize (rawObj) {
		// thank you b💩ckend!
		this.attributes = assign(this.attributes, rawObj.group);
	}
});

