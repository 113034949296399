import { Model, Collection } from 'backbone';
import { find, get } from 'lodash';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import CWTable from 'components/table/table';
import cwalert from 'cwalert';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import { clinicianName } from 'service/display-name/display-name';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Clinicians denied access to this respondent'),
	actions: ['respondent/deniedClinicians/init'],
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.RESPONDENTS.CLINICIAN_DENIED
	}],

	tileData: ({ tile }) => ({
		limit: tile.config().limit || 10,
		clinicians: new Collection()
	}),

	loaded: ({ tile }) => {
		const dummyModel = new Model();
		const className = 'search-results-list__entry clinicians-' +
			'list__entry clinicians-list__clinician-';
		const mainClassName = 'search-results-list__entry--main';

		tile.form = formView({
			name: 'respondent-clinicians',
			model: dummyModel,
			preventSave: true,
			loader: false,
			disabled: !can.add(CLINICIAN.RESPONDENTS.CLINICIAN_DENIED),

			fields: [{
				key: 'clinicianId',
				type: 'search',
				label: t('Clinician'),
				limit: tile.limit,
				provideResults: (query) => repository.searchClinicians({
					search: query,
					administratorAccountEnabled: true,
					start: 0,
					limit: tile.limit
				}),
				placeholder: t('Type clinician name'),
				itemLabel: (clinician) => clinicianName(clinician.toJSON()),
				onSelectItem: (clinician, view) => {
					const clinicianId = clinician.get('administratorId');
					const list = get(store.state, 'respondent.deniedClinicians.data');
					const clinicianList = find(list, { administratorId: clinicianId });

					if (list && clinicianList) {
						cwalert.notice(t('Clinician {clinicianName} is' +
							' already denied to access respondent {respondentName}', {
							clinicianName: clinicianName(clinician.toJSON()),
							respondentName: store.state.respondent.model.displayName()
						}));
					} else {
						store.dispatch('respondent/deniedClinicians/add', clinician.toJSON())
							.then(() => {
								cwalert.success(t(`denialList.addedClinicianToRespondent`, {
									clinicianName: clinicianName(clinician.toJSON()),
									respondentName: store.state.respondent.model.displayName()
								}));
							});
						view.clear();
					}
				}
			}]
		});

		const tableCfg = {
			parent: tile.el,
			store: {
				items: () => get(store.state, 'respondent.deniedClinicians.data'),
				mutation: 'respondent/deniedClinicians/update'
			},
			emptyListMessage: t('denialList.emptyRespondentList'),
			columns: [{
				label: t('Full name'),
				css: `${className}full-name ${mainClassName}`,
				render () {
					return clinicianName(this.item);
				}
			}],
			actions: {}
		};

		if (can.remove(CLINICIAN.RESPONDENTS.CLINICIAN_DENIED)) {
			tableCfg.actions['delete'] = (clinician) => {
				confirmation({
					title: t('Remove'),
					icon: 'unlink',
					message: t(`denialList.removedClinicianFromRespondent`, {
						clinicianName: clinicianName(clinician),
						respondentName: store.state.respondent.model.displayName()
					}),
					warning: true
				}).then(() => {
					store.dispatch('respondent/deniedClinicians/remove', clinician).then(() => {
						cwalert.success(t(`denialList.removedClinician`, {
							clinicianName: clinicianName(clinician)
						}));
					});
				});
			};
		}
		tile.$el.append(tile.form.$el);
		tile.table = new CWTable(tableCfg);
	}
});
