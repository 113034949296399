<template>
	<section v-if="items" :class="className('section')">
		<p
			v-if="items.length"
			:class="className('description')"
		>({{items.length}}) {{t(description)}}</p>
		<ul :class="className('list')">
			<li v-for="(item, i) in items" :key="i" :class="className('entry')">
				<check-item
					:item="item"
					:class-prefix="classPrefix"
					:info="info(item)"
					:solve="solve"
				/>
			</li>
		</ul>
	</section>
</template>

<script>
import Vue from 'vue';
import CheckItem from './check-item';
import t from 'service/lang/translate';

export default Vue.component('check-items', {
	components: { CheckItem },
	props: {
		classPrefix: {
			type: String,
			default: 'config-check'
		},
		items: {
			type: Array,
			default: () => []
		},
		info: {
			type: Function,
			required: true
		},
		solve: {
			type: Function,
			required: true
		},
		description: {
			type: String,
			default: 'cardConfig.healthCheck.tilesMismatch'
		}
	},
	methods: {
		t,
		className (name) {
			return `${this.classPrefix}__${name}`;
		}
	}
});
</script>
