import renderGenerated from './render-generated';
import $ from 'jquery';
import { forEach } from 'lodash';

export default (tile) => {
	const $list = $('<ul class="reports__list tile__list" />').appendTo(tile.$el);

	let generatingCount = 0;
	tile.assessmentReports.each((report) => {
		const $listItem = $('<li class="reports__list-item tile__list-item" />').appendTo($list);
		const generatedReport = tile.reportList.findWhere({
			assessmentReportCode: report.get('assessmentReportCode')
		});

		if (generatedReport) {
			renderGenerated({
				report: generatedReport,
				$listItem,
				assessmentInstance: tile.assessmentInstance,
				mediaReferences: tile.mediaReferences
			});

		} else {
			forEach($listItem, (item) => {
				item.classList.add('reports__list-item--generating');
			});

			$('<span class="reports__list-item" />')
				.html(report.get('assessmentReportName'))
				.appendTo($listItem);
			generatingCount++;
		}
	});

	return generatingCount;
};

