import { forEach } from 'lodash';
import $ from 'jquery';
import TileView from 'tile-view';
import Table from 'components/table/table';
import cardurl from 'cwcardurl';
import t from 'translate';
import datetime from 'datetime';
import repository from 'repository';
import can from 'acl-can';
import Button from 'button';
import cwalert from 'cwalert';
import appContext from 'app-context';

const className = 'decisions-list__entry decisions-list__';

export default TileView.extend({
	title: t('My decisions'),
	acl: [{
		op: 'read',
		checkpoint: 'administrator.respondents.manualinputs'
	}, {
		op: 'read',
		checkpoint: 'administrator.respondents.respondents'
	}],

	tileData: () => ({
		decisions: repository.getDecisionsForCurrentClinician()
	}),

	loaded ({ tile }) {
		tile.table = new Table({
			parent: tile.el,
			collection: tile.decisions,
			sortable: true,

			columns: [{
				label: t('Issued'),
				css: `${className}issued`,
				render () {
					return datetime(this.item.get('issueDate'))
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				label: t('Treatment'),
				css: `${className}treatment`,
				key: 'treatmentName',
				render () {
					const href = cardurl.buildUrl('treatment', {
						treatmentId: this.item.get('treatment')
					});

					return $(`<a href="${href}">${this.item.get('treatmentName')}</a>`)[0];
				}
			}, {
				label: t('Respondent'),
				css: `${className}respondent`,
				render () {
					const href = cardurl.buildUrl('respondent-status', {
						respondentId: this.item.get('respondentId')
					});

					return $(`<a href="${href}">${this.item.get('respondentDisplayName')}</a>`)[0];
				}
			}, {
				label: t('Assessment Plan'),
				css: `${className}assessment-plan`,
				key: 'workflowInstanceName'
			}, {
				label: t('Title'),
				css: `${className}title`,
				key: 'message'

			}, {
				label: t('Action'),
				css: `${className}action-container`,
				render () {
					const methodName = `render-${this.item.get('inputType')}`;

					if (can.add('administrator.respondents.manualinputs')) {
						tile[methodName].call(this, tile);
					}
				}
			}]
		});
	},

	'render-yes-no' (tile) {
		const $td = $(this.td);
		const model = this.item;
		const buttonsCfg = [{
			label: t('Yes'),
			value: 'yes'
		}, {
			label: t('No'),
			value: 'no'
		}];

		const saveChanges = (changes) => {
			tile.setDataLoading();
			model.save(changes).then(() => {
				cwalert.success(t('manual-input.properties.saveSuccessful'));
				tile.decisions.fetch().then(() => {
					tile.setDataLoaded();
				});
			});
		};

		const createButton = (button) => new Button({
			caption: button.label,
			css: `${className}action`,
			data: {
				action: `decision-response:${button.value}`
			}
		}, () => {
			saveChanges({
				value: button.value
			});
		});

		forEach(buttonsCfg, (button) => {
			$td.append(createButton(button));
		});
	}
});
