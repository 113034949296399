import renderList from './render-list';
import t from 'translate';
import TileView from 'tile-view';
import repository from 'repository';
import $ from 'jquery';

export default TileView.extend({
	title: t('assessment.properties.availableReports'),
	className: 'reports__container',
	acl: [{
		checkpoint: 'administrator.respondents.assessmentreports',
		op: 'READ'
	}, {
		checkpoint: 'administrator.respondents.respondents',
		op: 'READ'
	}],

	cardData: () => [
		'assessmentInstance',
		'assessmentInstanceId',
		'assessmentId',
		'mediaReferences'
	],
	tileData: ({ tile }) => {
		const assessmentId = tile.assessmentId || tile.assessmentInstance.get('assessmentId');
		const assessmentInstanceId = tile.assessmentInstanceId || tile.assessmentInstance.getId();

		return {
			assessmentInstance:
				tile.assessmentInstance ||
				repository.assessmentInstance(assessmentInstanceId),
			assessmentReports: repository.getReportsByData({ assessmentId }),
			reportList: repository.assessmentInstanceReportInstances(assessmentInstanceId)
		};
	},

	clearInterval: (tile) => {
		tile.checkInterval && clearTimeout(tile.checkInterval);
	},

	loaded: ({ tile }) => {
		const $noReports = (noReportsTxt) => $('<p class="no-items-notice" />').html(noReportsTxt);

		tile.clearInterval(tile);

		if (tile.assessmentInstance.isSubmitted() && tile.assessmentReports.size()) {
			tile.listTheReports(tile);

		} else {
			let noReportsTxt = t('assessment.properties.no-reports');

			if (tile.assessmentReports.size()) {
				noReportsTxt = t('The assessment is not delivered yet');
			}

			tile.clear();
			$noReports(noReportsTxt).appendTo(tile.$el);
		}
	},

	listTheReports: (tile) => {
		const deliveredAt = tile.assessmentInstance.getDeliveredDate().unixtime;

		const populate = () => {
			tile.$el.empty();
			return renderList(tile);
		};

		const check = (callback) => {
			tile.reportList.fetch().then(callback);
		};

		const checkIfGenerated = () => {
			const notGeneratedYet = populate();

			if (notGeneratedYet > 0) {
				const timeElapsed = (new Date().getTime() / 1000) - deliveredAt;
				let interval = 1000; // check rapidly for the first 5 seconds

				// check every 3 seconds for the first minute
				if (timeElapsed >= 5 && timeElapsed < 60) {
					interval = 3000;
				}

				if (timeElapsed >= 60) { // check every minute after a minute has passed
					interval = 60000;
				}

				tile.checkInterval = setTimeout(() => {
					check(checkIfGenerated);
				}, interval);
			}
		};

		checkIfGenerated();
	},

	onRemove: ({ tile }) => {
		tile.clearInterval(tile);
	}
});

