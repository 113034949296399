import SingleEntity from '../shared/single-entity';
import urls from './urls';
import Backbone from 'backbone';
import { ajax } from 'jquery';
import t from 'translate';
import datetime from 'datetime';

export default SingleEntity.extend({
	urlRoot: urls.single.url,
	eventNamespace: 'information-package-instance',
	statusLabels: {
		NON_ACTIVATE: t('Not activated'),
		ACTIVATE: t('Active'),
		COMPLETE: t('Completed')
	},
	getStartDate () {
		return this.get('startAt');
	},

	setStartDate (date, timezone) {
		const newDate = datetime(date).toUtcFromTimezone(timezone).toMoment();
		this.set('startAt', newDate.format('YYYY-MM-DD HH:mm'));
		return this;
	},

	isActive () {
		return this.get('state') === 'ACTIVATE';
	},

	activate () {
		return this._toggleActive(true);
	},

	deactivate () {
		return this._toggleActive(false);
	},

	_toggleActive (bool) {
		this.set('state', {
			true: 'ACTIVATE',
			false: 'NON_ACTIVATE'
		}[bool]);

		return this.save();
	},

	complete () {
		this.set('isCompleted', true);
		const url = Backbone.config().backendRootUrl['default'] + urls.complete.url(this.getId());

		return ajax({ url, type: 'PUT' });
	},

	isCompleted () {
		return !!this.get('isCompleted');
	},

	status () {
		return this.statusLabels[this.get('state')];
	}
});

