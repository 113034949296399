import AppStore from './store-apps-list/tile';
import ConnectedAppDetails from './connected-app-details/tile';
import ConnectedAppStore from './connected-apps-list/tile';
import MiniAppConnectionTreatment from './mini-app-connection-treatment/tile';

export default {
	'store-apps-list': AppStore,
	'connected-apps-list': ConnectedAppStore,
	'connected-app-details': ConnectedAppDetails,
	'mini-app-connection-treatment': MiniAppConnectionTreatment
};
