import TileView from 'tile-view';
import { assign } from 'lodash';
import formView from 'components/form-view/form-view';
import t from 'translate';
import can from 'acl-can';
import repository from 'repository';
import Clinician from 'repo/clinicians/clinician';
import systemSettings from 'system-settings';

export default TileView.extend({

	acl: [{
		op: 'read',
		checkpoint: 'administrator.respondents.document-owner'
	}],

	cardData: () => ['document'],

	tileData: ({ tile }) => {
		const clinicianId = tile.document.get('ownerClinicianId');
		const displayName = tile.document.get('displayName') || '';
		const clinician = clinicianId ? new Clinician({
			administratorId: clinicianId,
			displayName
		}) : null;

		return {
			clinician: clinician ? clinician.retrieve() : null
		};
	},

	init: ({ tile }) => {
		tile.limit = tile.config().limit || 10;
		tile.document.on('change:isFinalized', () => tile.loaded({ tile }));
	},

	loaded: ({ tile }) => {
		const disabled =
			(
				tile.document.get('isFinalized') &&
				!systemSettings.getBoolean('CHANGE_OWNER_IN_SUBMITTED')
			) ||
			!can.edit('administrator.respondents.document-owner');

		const fields = [{
				key: 'ownerClinicianId',
				type: 'search',
				label: t('Document owner'),
				limit: tile.limit,
				provideResults: (search) => {
					const params = assign({
						search,
						administratorAccountEnabled: true,
						start: 0,
						limit: tile.limit
					}, tile.document.get('requestParams'));

					return repository.searchClinicians(params);
				},
				placeholder: t('Type clinician name'),
				itemLabel: (model) => model.displayName(),
				customize: (view) => {
					view.showItem(tile.clinician);
				}
			}];

		tile.form = formView({
			name: 'document-owner',
			model: tile.document,
			disabled,
			loader: false,
			preventSave: true, // changes are saved in card controller, such a great idea
			fields
		});

		tile.$el.html(tile.form.$el);
	}
});
