import TileView from 'tile-view';
import cardUrl from 'cwcardurl';
import can from 'acl-can';
import datetime from 'datetime';
import t from 'translate';
import TimezoneWarning from 'components/timezone-warning';
import { mapActions, mapGetters } from 'vuex';
import { respondentName } from 'service/display-name/display-name';
import showField from 'service/show-field/index';
import { get } from 'lodash';

export default TileView.extend({
	title: '',
	acl: [{
		checkpoint: 'administrator.respondents.respondents',
		op: 'READ'
	}],
	className: 'narrow-tile',

	actions: () => [['respondent/init']],

	Vue: (tile) => ({
		data: {
			config: tile.config()
		},
		components: { TimezoneWarning },
		computed: {
			...mapGetters({ clinicianData: 'respondentClinicians/data' }),
			...mapGetters({ respondent: 'respondent/data' }),
			...mapGetters({ respondentGroupsData: 'respondentGroups/currentRespondent' }),
			...mapGetters({ respondentId: 'respondent/respondentId' }),
			...mapGetters({ respondentLabel: 'respondent/label' }),
			allowClinicians () {
				const show = this.config.showClinician;
				return show && can.read('administrator.respondents.administrators');
			},
			allowGroups () {
				const show = this.config.showGroups;
				return show && can.read('administrator.respondents.groups');
			},
			displayName () {
				return respondentName(this.respondent, { preventEscape: true });
			},
			respondentLabel () {
				return this.config.labelAsLink;
			},

			show: ({ respondent }) => showField({
				config: get(tile.config(), 'fields', {}),
				field: {
					respondentUsername: respondent.respondentUsername,
					key: 'respondentUsername'
				}
			})
		},
		methods: {
			...mapActions({ initClinicians: 'respondentClinicians/init' }),
			...mapActions({ initGroups: 'respondentGroups/initForCurrentRespondent' }),
			transformData ({ date, dateOnly = false }) {
				if (date) {
					const format = dateOnly ? 'date.formats.dateOnly' : 'date.formats.dateTime';
					return datetime(date)
						.setTimezone(this.respondent.respondentTimezoneName)
						.toMoment()
						.format(t(format));
				}
			},
			url (respondentId) {
				return cardUrl.buildUrl('respondent-status', { respondentId });
			}
		},
		created () {
			if (this.allowGroups) {
				this.initGroups(this.respondentId);
			}

			if (this.allowClinicians) {
				this.initClinicians(this.respondentId);
			}
		},
		template:
		`<section class="respondent-label-large__content">
			<ul class="respondent-label-large__container">
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Respondent</label>
					<p class="respondent-label-large__value--important">
						<span v-if="!respondentLabel">{{ displayName }}</span>
						<a v-else :href="url(respondentId)">{{ displayName }}</a>
					</p>
				</li>
				<li
					v-if="show.respondentUsername"
					class="respondent-label-large__element-container"
				>
					<label
						class="respondent-label-large__label--important"
						v-translate
					>respondent-management.username</label>
					<p
						class="respondent-label-large__value--important"
					>{{ respondent['respondentUsername'] }}</p>
				</li>
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Last login</label>
					<p
						class="respondent-label-large__value--important"
					>{{ transformData({ date: respondent.respondentLastLogin }) }}</p>
				</li>
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Birthdate</label>
					<p
						class="respondent-label-large__value--important"
					>{{ transformData({
						date: respondent.respondentBirthdate,
						dateOnly: true })
					}}</p>
				</li>
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Address</label>
					<p
						class="respondent-label-large__value--important"
					>{{ respondent['respondentAddress'] }}</p>
				</li>
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Email</label>
					<p class="respondent-label-large__value--important">
						<a
							:href="'mailto:' + respondent.respondentEmail"
						>{{ respondent['respondentEmail'] }}</a>
					</p>
				</li>
				<li class="respondent-label-large__element-container">
					<label
						class="respondent-label-large__label--important"
						v-translate
					>Phone</label>
					<p class="respondent-label-large__value--important">
						<a
							:href="'tel:' + respondent.respondentPhone"
						>{{ respondent['respondentPhone'] }}</a>
					</p>
				</li>
			</ul>
			<div v-if="allowGroups" class="table__container">
				<table v-if="respondentGroupsData.length > 0" class="table">
					<thead>
						<tr>
							<th class="name">{{ 'Respondent groups' }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="single in respondentGroupsData">
							<td class="name">
								<p class="name">{{ single['respondentGroupName'] }}</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p
					v-if="respondentGroupsData.length === 0"
					class="no-items-notice"
				>{{ 'The respondent has no groups assigned' }}</p>
			</div>
			<div v-if="allowClinicians" class="table__container">
				<table v-if="clinicianData.length > 0" class="table">
					<thead>
						<tr>
							<th class="name">{{ 'Respondent clinicians' }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="single in clinicianData">
							<td class="name">
								<p class="name">{{ single['administratorUsername'] }}</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p
					v-if="clinicianData.length === 0"
					class="no-items-notice"
				>{{ 'The respondent has no clinicians assigned' }}</p>
			</div>
			<timezone-warning :respondent="respondent" />
		</section>`
	})
});
