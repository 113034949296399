import { noop } from 'lodash';
import editMixin from './_edit-mixin';

export default {
	name: 'editAssessment',
	mixins: [editMixin],
	props: {
		assessments: {
			type: Array,
			default: () => []
		},
		attributeDisabled: {
			type: Boolean,
			default: false
		},
		attributes: {
			type: Array,
			default: () => []
		},
		calculations: {
			type: Array,
			default: () => []
		},
		contexts: {
			type: Array,
			default: () => []
		},
		elementType: {
			type: String,
			required: true
		},
		error: {
			type: String,
			default: ''
		},
		flags: {
			type: Array,
			default: () => []
		},
		formData: {
			type: Object,
			required: true
		},
		formOptions: {
			type: Object,
			default: () => ({})
		},
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		mirror: {
			type: Boolean,
			default: false
		},
		questions: {
			type: Array,
			default: () => []
		},
		warning: {
			type: Function,
			default: noop
		},
		v: {
			type: Object,
			required: true
		}
	},

	template: `
		<span>
			<select-input
				:options="assessments"
				:disabled="mirror"
				classPrefix="tree-edit"
				label="Assessment"
				itemLabel="assessmentName"
				:icon="error"
				:mandatory="true"
				:invalid="v.formData.assessment.$error"
				:placeholder="translate('Select assessment')"
				v-model="formData.assessment"
			/>
			<select-input
				:options="formOptions.assessmentOrder"
				:clearable="false"
				:disabled="mirror"
				classPrefix="tree-edit"
				label="Assessment order"
				:placeholder="translate('Select order')"
				v-model="formData.assessmentOrder"
			/>
			<select-input
				:options="contexts"
				:containerClass="warning({ display: mirror })"
				:disabled="mirror"
				:placeholder="translate('Select context')"
				classPrefix="tree-edit"
				label="Context"
				itemLabel="assessmentInstanceContextLabel"
				v-model="formData.assessmentContext"
			/>
			<div v-if="!loading">
				<select-input
					v-if="elementType === 'assessmentAttribute'"
					:options="attributes"
					classPrefix="tree-edit"
					label="Attribute"
					v-model="formData.identity"
					:mandatory="true"
					:invalid="v.formData.identity.$error"
					itemLabel="label"
					:disabled="attributeDisabled"
					:placeholder="translate('Select attribute')"
					:translate-items="true"
				/>
				<select-input
					v-if="elementType === 'assessmentCalculation'"
					:options="calculations"
					classPrefix="tree-edit"
					label="Calculation"
					v-model="formData.identity"
					:mandatory="true"
					:invalid="v.formData.identity.$error"
					:disabled="attributeDisabled"
					:placeholder="translate('Select calculation')"
					itemLabel="name"
				/>
				<select-input
					v-if="elementType === 'assessmentFlag'"
					:options="flags"
					:containerClass="warning({ display: mirror })"
					classPrefix="tree-edit"
					label="Flag"
					v-model="formData.identity"
					:mandatory="true"
					:invalid="v.formData.identity.$error"
					:disabled="attributeDisabled"
					:placeholder="translate('Select flag')"
					itemLabel="name"
				/>
				<select-input
					v-if="elementType === 'assessmentQuestion'"
					:options="questions"
					classPrefix="tree-edit"
					label="Question"
					v-model="formData.identity"
					:mandatory="true"
					:invalid="v.formData.identity.$error"
					:disabled="attributeDisabled"
					:placeholder="translate('Select question')"
					itemLabel="name"
				/>
			</div>
			<div v-else v-loader-spinner:small class="export-template__loader"></div>
		</span>
		`
};
