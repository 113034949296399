import {
	Clinicians, ClinicianGroups, ClinicianAttributeDefinitions, ClinicianAttributeValues,
	ClinicianGroupRespondentGroups, ClinicianInClinicianGroups
} from './definitions';
import urls from './urls';
import { GET, POST, DELETE }  from 'service/ajax/ajax';
import ClinicianInRoles from './clinician-in-roles';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all ClinicianGroups.
	 *
	 * @function getClinicianGroups
	 * @returns {Object} {{#crossLink "ClinicianGroups"}}{{/crossLink}}.
	 * @example
	 * 		Repository.getClinicianGroups();
	 */
	getClinicianGroups: () => ClinicianGroups.getAll(),

	/**
	 * Get clinician by id.
	 *
	 * @function getClinician
	 * @deprecated
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} {{#crossLink "Clinician"}}{{/crossLink}}.
	 * @example
	 * 		Repository.getClinician(19);
	 */
	getClinician: (clinicianId) => Clinicians.getById(clinicianId),

	/**
	 * Get clinician by id.
	 *
	 * @function clinician
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} {{#crossLink "Clinician"}}{{/crossLink}}.
	 * @example
	 * 		Repository.clinician(19);
	 */
	clinician: (clinicianId) => Clinicians.getById(clinicianId),

	/**
	 * Get all ClinicianAttributeDefinitions.
	 *
	 * @function getClinicianAttributeDefinitions
	 * @returns {Object} - New ClinicianAttributeDefinitions Collection.
	 * @example
	 * 		Repository.getClinicianAttributeDefinitions();
	 */
	getClinicianAttributeDefinitions: () => ClinicianAttributeDefinitions.getAll(),

	/**
	 * Get all ClinicianAttributeValues for a clinician.
	 *
	 * @function getClinicianAttributeValues
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} - New ClinicianAttributeValues Collection.
	 * @example
	 * 		Repository.getClinicianAttributeValues(4);
	 */
	getClinicianAttributeValues: (clinicianId) => ClinicianAttributeValues.getAll(clinicianId),

	/**
	 * Get all ClinicianGroupRespondentGroups for the given ClinicianGroupId.
	 *
	 * @function getRespondentGroupsForClinicianGroup
	 * @param {number|string} groupId - Clinician Group ID.
	 * @returns {Object} - New ClinicianGroupRespondentGroups Collection.
	 * @example
	 * 		Repository.getRespondentGroupsForClinicianGroup(2);
	 */
	getRespondentGroupsForClinicianGroup: (groupId) =>
		ClinicianGroupRespondentGroups.getAll(groupId),

	/**
	 * Create connection between Clinician Group and Respondent Group.
	 *
	 * @param {Object} params - Parameters.
	 * @param {string|number} params.clinicianGroupId - Clinician Group ID.
	 * @param {string|number} params.respondentGroupId - Respondent Group ID.
	 * @returns {Object} - Promise.
	 * @example
	 * 		repository.createClinicianGroupRespondentGroup({
	 *		   clinicianGroupId: 19,
	 *		   respondentGroupId: 3
	 * 		});
	 */
	createClinicianGroupRespondentGroup: ({ clinicianGroupId, respondentGroupId }) => POST({
		url: urls.groupHasAccessToRespondentGroup.url,
		data: {	administratorGroupId: clinicianGroupId, respondentGroupId }
	}),

	/**
	 * Remove connection between Clinician Group and Respondent Group.
	 *
	 * @function deleteClinicianGroupRespondentGroup
	 * @param {string|number} relationId - Relation ID.
	 * @returns {Object} - Promise.
	 * @example
	 * 		repository.deleteClinicianGroupRespondentGroup(203);
	 */
	deleteClinicianGroupRespondentGroup: (relationId) => DELETE({
		url: urls.groupHasAccessToRespondentGroup.url + relationId
	}),

	/**
	 * Get new collection of Clinicians which match search params.
	 *
	 * @function searchClinicians
	 * @param {object} params - Search params.
	 * @returns {object} - New Clinicians Collection.
	 * @example
	 * 		Repository.searchClinicians({
	 * 			query: 'jon',
	 * 			limit: 10,
	 * 			start: 0
	 * 		});
	 */
	searchClinicians: (params) => Clinicians.search(params),

	/**
	 * Find clinicians by given search params.
	 *
	 * @function clinicians
	 * @param {object} params - Search params.
	 * @returns {object} - Clinicians data.
	 * @example
	 * 		Repository.clinicians({
	 * 			query: 'jon',
	 * 			limit: 10,
	 * 			start: 0
	 * 		});
	 */
	clinicians: (params) => GET({
		url: urls.searchClinicians.url(params)
	}),

	/**
	 * Get Clinicians which match search params.
	 *
	 * @function searchAvailableClinicians
	 * @param {object} params - Search params.
	 * @returns {object} - Clinicians.
	 * @example
	 * 		Repository.searchAvailableClinicians({
	 * 			query: 'jon',
	 * 			limit: 10,
	 * 			start: 0,
	 * 			respondentId: 6,
	 * 			TreatmentTypeId: 4
	 * 		});
	 */
	searchAvailableClinicians: (params) => GET({
		url: urls.searchAvailableClinicians(params)
	}),

	/**
	 * Get all ClinicianInClinicianGroups for the given clinicianId.
	 *
	 * @function getClinicianInClinicianGroups
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} - New ClinicianInClinicianGroups Collection.
	 * @example
	 * 		Repository.getClinicianInClinicianGroups(3);
	 */
	getClinicianInClinicianGroups: (clinicianId) =>
		ClinicianInClinicianGroups.getForClinician(clinicianId),

	/**
	 * Get all ClinicianInRoles for the given clinicianId.
	 *
	 * @function getClinicianInRoles
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} - New ClinicianInRoles Collection.
	 * @example
	 * 		Repository.getClinicianInRoles(3);
	 */
	getClinicianInRoles: (clinicianId) => ClinicianInRoles.getForClinician(clinicianId),

	/**
	 * Get all assigned groups for the given clinicianId.
	 *
	 * @function clinicianAssignedGroups
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.clinicianAssignedGroups(3);
	 */
	clinicianAssignedGroups: ({ clinicianId }) => GET({
		url: urls.clinicianAssignedGroups.url(clinicianId)
	}),
	/**
	 * Get all assigned roles for the given clinicianId.
	 *
	 * @function clinicianAssignedRoles
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.clinicianAssignedRoles(3);
	 */
	clinicianAssignedRoles: ({ clinicianId }) => GET({
		url: urls.clinicianAssignedRoles.url(clinicianId)
	}),

	/**
	 * Assign group for the given clinicianId.
	 *
	 * @function assignClinicianGroup
	 * @param {Object} params - Parameters.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @param {number|string} params.clinicianGroupId - Clinician group ID.
	 * @param {Object} params.timeRestriction - Time restriction object.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.assignClinicianGroup({
	 * 		 	clinicianId: 1,
	 * 		 	clinicianGroupId: 1,
	 * 		 	timeRestriction: {
	 *				validFrom: new Date(),
	 *				validTo: new Date()
	 * 		 	}
	 * 		});
	 */
	assignClinicianGroup: ({ clinicianId, clinicianGroupId, timeRestriction }) => POST({
		url: urls.clinicianAssignedGroup.url({ clinicianId, clinicianGroupId }),
		data: timeRestriction
	}),

	/**
	 * Assign access role for the given clinicianId.
	 *
	 * @function assignClinicianRole
	 * @param {Object} params - Parameters.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @param {number|string} params.clinicianRoleId - Clinician role ID.
	 * @param {Object} params.timeRestriction - Time restriction object.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.assignClinicianRole({
	 * 		 	clinicianId: 1,
	 * 		 	clinicianRoleId: 1,
	 * 		 	timeRestriction: {
	 *				validFrom: new Date(),
	 *				validTo: new Date()
	 * 		 	}
	 * 		});
	 */
	assignClinicianRole: ({ clinicianId, clinicianRoleId, timeRestriction }) => POST({
		url: urls.clinicianAssignedRole.url({ clinicianId, clinicianRoleId }),
		data: timeRestriction
	}),

	/**
	 * Delete group for the given clinicianId.
	 *
	 * @function removeClinicianGroup
	 * @param {Object} params - Parameters.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @param {number|string} params.clinicianGroupId - Clinician group ID.
	 * @param {Object} params.timeRestriction - Time restriction object.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.removeClinicianGroup({
	 * 		    clinicianId: 1,
	 * 		    clinicianGroupId: 1
	 * 		});
	 */
	removeClinicianGroup: ({ clinicianId, clinicianGroupId, timeRestriction }) => DELETE({
		url: urls.clinicianAssignedGroup.url({ clinicianId, clinicianGroupId }),
		data: timeRestriction
	}),

	/**
	 * Delete access role for the given clinicianId.
	 *
	 * @function removeClinicianRole
	 * @param {Object} params - Parameters.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @param {number|string} params.clinicianRoleId - Clinician role ID.
	 * @param {Object} params.timeRestriction - Time restriction object.
	 * @returns {Object} - Promise.
	 * @example
	 * 		Repository.removeClinicianRole({
	 * 		    clinicianId: 1,
	 * 		    clinicianGroupId: 1
	 * 		});
	 */
	removeClinicianRole: ({ clinicianId, clinicianRoleId, timeRestriction }) => DELETE({
		url: urls.clinicianAssignedRole.url({ clinicianId, clinicianRoleId }),
		data: timeRestriction
	}),

	/**
	 * Get current lockout status for clinician.
	 *
	 * @function clinicianLockStatus
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.clinicianLockStatus(respondentId);
	 */
	clinicianLockStatus: (clinicianId) => GET({ url: urls.lockStatus(clinicianId) }),

	/**
	 * Release lock for clinician.
	 *
	 * @function unlockClinician
	 * @param {number|string} clinicianId - Clinician ID.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.unlockClinician(clinicianId);
	 */
	unlockClinician: (clinicianId) => DELETE({ url: urls.unlock(clinicianId) })
};
