var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isCompleteTreatment
    ? _c(
        "p",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "incomplete-treatment"
        },
        [_vm._v("treatment.labels.unavailable")]
      )
    : _c(
        "form",
        {
          staticClass: "add-consent-instance__container",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _vm.noContext
            ? _c("respondent-search", {
                model: {
                  value: _vm.respondent,
                  callback: function($$v) {
                    _vm.respondent = $$v
                  },
                  expression: "respondent"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.inTreatmentContext
            ? _c("select-input", {
                attrs: {
                  options: _vm.treatments,
                  "item-label": "id",
                  "get-option-label": _vm.treatmentLabel,
                  label: "Treatment",
                  disabled: !_vm.respondentSelected,
                  required: true
                },
                on: {
                  input: function($event) {
                    return _vm.$v.treatment.$touch()
                  }
                },
                model: {
                  value: _vm.treatment,
                  callback: function($$v) {
                    _vm.treatment = $$v
                  },
                  expression: "treatment"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.roles.length
            ? _c("select-input", {
                attrs: {
                  options: _vm.roles,
                  "get-option-label": _vm.getRoleLabel,
                  label: "Role",
                  "item-label": "roleId",
                  required: true
                },
                model: {
                  value: _vm.role,
                  callback: function($$v) {
                    _vm.role = $$v
                  },
                  expression: "role"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              options: _vm.availableConsents,
              "item-label": "name",
              label: "Consent Template",
              disabled: !_vm.consentInputEnabled,
              required: true
            },
            on: {
              input: function($event) {
                return _vm.$v.consent.$touch()
              }
            },
            model: {
              value: _vm.consent,
              callback: function($$v) {
                _vm.consent = $$v
              },
              expression: "consent"
            }
          }),
          _vm._v(" "),
          _c("datetime-input", {
            staticClass: "datetime-input",
            attrs: {
              label: "Start date",
              "class-prefix": "clinician-group",
              required: true
            },
            on: {
              input: function($event) {
                return _vm.$v.startAt.$touch()
              }
            },
            model: {
              value: _vm.startAt,
              callback: function($$v) {
                _vm.startAt = $$v
              },
              expression: "startAt"
            }
          }),
          _vm._v(" "),
          _c(
            "button-group",
            { attrs: { "class-prefix": "add-consent-instance" } },
            [
              _c("submit-button", {
                attrs: {
                  "can-submit": _vm.canSubmit,
                  label: "consents.addInstance"
                }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }