import t from 'translate';
import Link from 'repo/links/entity';
import formViewDialog from 'service/form-view-dialog';

const showDialog = function () {
	const link = new Link();

	const formConfig = {
		name: 'add-link',
		model: link,
		successMessage: t('Successfully added'),
		fields: [{
			key: 'name',
			type: 'text',
			label: t('Name'),
			mandatory: true,
			max: 255
		}, {
			key: 'url',
			type: 'text',
			label: t('URL'),
			mandatory: true,
			max: 1024
		}, {
			key: 'description',
			type: 'textarea',
			label: t('Description'),
			mandatory: true
		}, {
			key: 'openInNewWindow',
			type: 'checkbox',
			label: t('Open in new window')
		}]
	};

	const dialogConfig = {
		title: t('New link')
	};

	formViewDialog({ formConfig, dialogConfig });
};

export default showDialog;

