import t from 'translate';
import appBar from 'app-bar';
import addMediaCategoryDialog from '../dialogs/new-media-category';
import store from 'store';
import can from 'acl-can';

const card = {
	title: t('Media categories'),
	name: 'system-settings-media-categories',
	url: 'system/media-categories',

	ctrl: () => {
		if (can.add('system.settings.media')) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New media category'),
				click: addMediaCategoryDialog
			});
		}

		return store.dispatch('media/categories/init');
	}
};

export { card as pageCategories };
