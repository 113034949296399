import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import store from 'store';
import can from 'acl-can';
import systemSettings from 'system-settings';
import { assign } from 'lodash';

export default TileView.extend({
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'EDIT'
	}],
	title: t('Edit treatment'),

	actions: ['treatment/init'],

	loaded: ({ tile }) => {
		assign(tile, {
			treatment: store.state.treatment.model,
			respondentTimezone: store.getters.respondent.respondentTimezoneName
		});

		const canSeeStatus = systemSettings.getBoolean('ENABLE_TREATMENT_STATUS') &&
			can.read('administrator.respondents.treatment-status');

		const fields = [{
			key: 'treatmentType',
			label: t('Treatment type'),
			type: 'paragraph',
			customize: (view) => {
				view.model.set('value', tile.treatment.get('treatmentType').name);
			}
		}, {
			key: 'name',
			label: t('Treatment name'),
			mandatory: true,
			type: 'text'
		}, canSeeStatus && {
			key: 'customStatus',
			label: t('Status'),
			type: 'text',
			readonly: !can.edit('administrator.respondents.treatment-status')
		}, {
			key: 'enabled',
			label: t('Enabled'),
			type: 'checkbox'
		}, {
			key: 'startAt',
			label: t('Start date'),
			type: 'datetime',
			timezone: tile.respondentTimezone
		}, {
			key: 'endAt',
			label: t('End date'),
			timezone: tile.respondentTimezone,
			type: 'datetime'
		}];

		const formConfig = {
			name: 'edit-treatment',
			model: tile.treatment,
			successMessage: t('Successfully saved treatment'),
			errorMessage: t('Error saving treatment'),
			loader: false,
			saveIndicator: true,
			fields
		};

		tile.form = formView(formConfig);
		tile.$el.append(tile.form.$el);
	}
});

