import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import ColumnTreatmentTypeAttribute from './treatment-type-attribute';

const ColumnTreatmentTypeAttributes = MultipleEntities.extend({
	urls,
	eventNamespace: 'export-template-column-treatment-type-attribute',
	model: ColumnTreatmentTypeAttribute,

	getByColumn (columnId) {
		return this.retrieve('treatmentTypeAttributes', columnId);
	}
});

export default new ColumnTreatmentTypeAttributes();
