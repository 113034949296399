import TileView from 'tile-view';
import { sprintf } from 'sprintf';
import { isUndefined, replace } from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';

export default TileView.extend({
	actions: ['clinicianAttributes/getCurrentClinicianValues'],
	acl: {
		op: 'or',
		checkpoints: [{
			checkpoint: 'system.administratorpreferences.attributes',
			op: 'read'
		}, {
			checkpoint: 'system.settings.treatment-types',
			op: 'add'
		}]
	},

	Vue: (tile) => ({
		data: {
			cfg: tile.config()
		},

		template: `
			<div class="mini-value-tile">
				<div class="mini-value-tile__container">
					<div class="mini-value-tile__text-container">
						<p class="mini-value-tile__title">
						{{title}}
						</p>
						<p v-if="subtitle" class="mini-value-tile__subtitle">
							<span>{{subtitle}}</span>
						</p>
					</div>

					<div class="mini-value-tile__value-container">
						<span class="mini-value-tile__value">{{value}}</span>
					</div>
				</div>
			</div>
		`,

		computed: {
			title () {
				return this.sprintf('title');
			},
			subtitle () {
				return this.sprintf('subtitle');
			},
			rawValue () {
				const getterName = 'clinicianAttributes/currentClinicianValuesbyAttributeId';
				const values = this.$store.getters[getterName];
				return !isUndefined(values[this.cfg.clinicianAttributeId]) ?
					values[this.cfg.clinicianAttributeId] :
					EMPTY_CHAR;
			},
			value () {
				return this.cfg.valueFormat ? this.sprintf('valueFormat') : this.rawValue;
			}
		},

		methods: {
			sprintf (field) {
				return this.nanToEmpty(sprintf(this.cfg[field], this.rawValue));
			},
			nanToEmpty: (value) => replace(value, 'NaN', EMPTY_CHAR)
		}
	})
});
