import can from 'acl-can';
import appbar from 'app-bar';
import newClinicianGroup from 'modules/system-settings/dialogs/new-clinician-group';
import t from 'translate';

const card = {
	name: 'system-settings-clinician-groups',
	title: t('Clinician groups'),
	url: 'system/clinician-groups',

	controller () {
		this.populate();

		can.add('system.settings.administratorgroups') && appbar.addButton({
			sourceCard: card.name,
			icon: 'add',
			title: t('New clinician group'),
			click: newClinicianGroup
		});
	}
};

export { card as clinicianGroups };

