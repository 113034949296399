<template>
	<div>
		<section class="data-integration__add-container">
			<button
				:disabled="!availableCodes.length || !canEdit"
				class="submit-button__button submit-button__button--submit"
				type="submit"
				@click="add"
			>{{t('dataIntegration.codeVariations.actions.add')}}</button>
		</section>
		<table
			v-if="codeVariations.length"
			class="data-integration__code-variations table"
		>
			<thead>
				<tr>
					<th
						v-for="(header, i) in translatedHeaders"
						:key="i"
					>{{header}}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="item in codeVariations"
					:key="item.id"
				>
					<td
						v-for="(field, i) in fields"
						:key="i"
					>
						<p class="table-cell-label">
							{{translatedHeaders[i]}}
						</p>
						{{columnValue(field, item)}}</td>
					<td>
						<button
							class="icon-button__button"
							@click.stop="remove(item)"
						>
							<icon name="delete" :small="true" />
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else>{{t('No items')}}</span>
	</div>
</template>

<script>
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import dialog from '../../dialogs/code-variations';
import store from 'store';
import translateHeaders from '../../helpers/translate-headers';
import t from 'translate';
import { find, filter, isArray, map, merge, get } from 'lodash';
import { mapGetters } from 'vuex';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { CODES } from 'store/data-integration/codes';

export default {
	instant: true,
	title: t('dataIntegration.codeVariations.plural'),
	// acl: [{}],
	actions: ({ $store }) => {
		const codeId = $store.getters.urlParams.codeId;
		const data = $store.getters['dataIntegration/codes/data'];
		const enterprise = get(data, 'enterprise.id', null);
		return [
			['dataIntegration/codeVariations/init', codeId],
			['dataIntegration/codes/searchCodesByEnterprise', enterprise]
		];
	},
	data: ({ tile }) => ({
		codeId: tile.cardContext().get('codeId'),
		fields: ['code', 'name', ['codeType', 'code'], ['codeType', 'name']],
		modalCfg: {
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
			action: 'codeVariations/addAndRefresh',
			item: {
				code: null,
				codeId: tile.cardContext().get('codeId')
			},
			refresh: tile.runQuery,
			success: t('Item added successfully'),
			title: 'dataIntegration.codeVariations.actions.add',
			type: 'codeVariation'
		},
		updating: false
	}),

	computed: {
		...mapGetters({
			codeData: 'dataIntegration/codes/data',
			codes: CODES.WITH_ENTERPRISE,
			codeVariations: 'dataIntegration/codes/codeVariations'
		}),

		availableCodes: ({ codeId, codes, codeVariations }) => filter(
			codes,
			(code) => +code.id !== +codeId && !find(codeVariations, { id: code.id })
		),

		canEdit: () => can.edit(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE),

		translatedHeaders: ({ fields }) => {
			const headers = map(fields, (field) =>
				isArray(field) ? `${field[0]}.${field[1]}` : field);

			return translateHeaders({
				fields: headers,
				translation: 'dataIntegration.codeVariations.fields'
			});
		}
	},

	methods: {
		add () {
			dialog(merge({ codes: this.availableCodes }, this.modalCfg));
		},

		columnValue: (column, item) => isArray(column) ? item[column[0]][column[1]] : item[column],

		remove (item) {
			return confirmation({
				icon: 'delete',
				warning: true,
				title: t('dataIntegration.codeVariations.actions.remove'),
				message: t('dataIntegration.codeVariations.actions.delete')
			}).done(() => {
				store.dispatch('dataIntegration/codeVariations/deleteAndRefresh', {
					codeId: this.codeId,
					variantId: item.id
				}).then(() => {
					cwalert.success(t('dataIntegration.codeVariations.messages.deleted'));
				});
			});
		}
	}
};
</script>
