import Backbone from 'backbone';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import Url from 'lib/url/url';
import can from 'acl-can';
import t from 'translate';

const ACL = 'system.export.respondents';

export default Backbone.View.extend({
	title: t('admin-portal.ExportRespondent'),
	acl: [{
		checkpoint: 'system.export.respondents',
		op: 'OPEN'
	}],

	render: function () {

		this.$el.append($('<h2/>').html(t('system-manger.FilterExport')));

		var M = Backbone.Model.extend({
			defaults: {
				anonymous: true,
				test: false

			},
			sync: function () {
				var dfd = $.Deferred();
				var url = new Url(
					'backend-commons/export/rest/respondent/all?' + $.param(this.attributes),
					null,
					{ querySeparator: '', prependBackendRoot: 'system' });
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		var cfg = {
			name: 'system-export-respondents',
			model: new M(),
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: false,
			fields: [{
				key: 'anonymous',
				type: 'checkbox',
				label: t('Unidentified respondent'),
				disabled: !can.supervise(ACL)
			}, {
				key: 'test',
				type: 'checkbox',
				label: t(`${ACL}.TestAccounts`)
			}],

			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
