import t from 'translate';
import store from 'store';

const card = {
	title: t('Unanswered messages'),
	name: 'unanswered-messages',
	url: 'unanswered-messages',
	ctrl: () => store.dispatch('messages/initUnreplied')
};

export { card as UnansweredMessages };
