var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-treatment-program__program" }, [
    _c(
      "div",
      {
        staticClass: "edit-treatment-program__header",
        class: { "is-active": _vm.isProgramActive }
      },
      [
        _c("h3", { staticClass: "edit-treatment-program__program-title" }, [
          _c("button", { on: { click: _vm.selectProgram } }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.$t("treatmentProgram.program")) +
                "\n\t\t\t"
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm._l(_vm.program.modules, function(module, moduleIndex) {
          return _c(
            "module-editor",
            { key: module.id, attrs: { index: moduleIndex, module: module } },
            [
              _vm._l(module.sections, function(section, sectionIndex) {
                return _c(
                  "section-editor",
                  {
                    key: section.id,
                    attrs: {
                      index: sectionIndex,
                      "module-id": module.id,
                      section: section
                    }
                  },
                  _vm._l(section.contentPackage.elements, function(element) {
                    return _c("div", { key: element.id }, [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(element.alias) + "\n\t\t\t\t"
                      )
                    ])
                  }),
                  0
                )
              }),
              _vm._v(" "),
              !module.sections
                ? _c("div", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("treatmentProgram.noSections")) +
                        "\n\t\t\t"
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit-treatment-program__module-footer" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "edit-treatment-program__add-section",
                      on: {
                        click: function($event) {
                          return _vm.addSection(module.id)
                        }
                      }
                    },
                    [
                      _c("icon", { attrs: { name: "plus" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("treatmentProgram.section.add")))
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        }),
        _vm._v(" "),
        !_vm.program.modules
          ? _c("div", { staticClass: "edit-treatment-program__no-modules" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.$t("treatmentProgram.noModules")) +
                  "\n\t\t"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "edit-treatment-program__program-footer" }, [
          _c(
            "button",
            {
              staticClass: "edit-treatment-program__add-module",
              on: { click: _vm.addModule }
            },
            [
              _c("icon", { attrs: { name: "plus" } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("treatmentProgram.module.add")))
              ])
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }