import labelLarge from './respondent-label-large/tile';
import miniRespondentData from './mini-respondent-data/tile';
import newRespondent from './new-respondent/tile';
import newRespondentExternal from './new-respondent-external/tile';
import quickSearch from './respondent-quick-search/tile';
import respondentAttributes from './respondent-attributes/tile';
import respondentClinicians from './respondent-clinicians/tile';
import respondentGroups from './respondent-groups/tile';
import respondentPersonalia from './respondent-personalia/tile';
import search from './search-respondents/tile';
import respondentDenial from './respondent-denial-list/tile';
import respondentRelations from './respondent-relations/tile';
import respondentRelationAddDelete from './respondent-relation-add-delete';
import respondentLockout from './respondent-lockout';

export default {
	'respondent-quick-search': quickSearch,
	'respondent-label-large': labelLarge,
	'respondent-personalia': respondentPersonalia,
	'new-respondent': newRespondent,
	'new-respondent-external': newRespondentExternal,
	'respondent-attributes': respondentAttributes,
	'respondent-groups': respondentGroups,
	'respondent-clinicians': respondentClinicians,
	'search-respondents': search,
	'mini-respondent-data': miniRespondentData,
	'respondent-denial-list': respondentDenial,
	'respondent-relations': respondentRelations,
	'respondent-relation-add-delete': respondentRelationAddDelete,
	'respondent-lockout': respondentLockout
};
