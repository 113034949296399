import MultipleEntities from '../shared/multiple-entities';
import ClinicianInRole from './clinician-in-role';
import urls from './urls';

/**
 * ClinicianInRoles Entity
 * @class ClinicianInRoles
 * @extends MultipleEntities
 */
const ClinicianInRoles = MultipleEntities.extend({
	model: ClinicianInRole,
	urls,
	eventNamespace: 'clinician-in-role',
	idName: 'relationId',

	canAdd (model) {
		return +model.get('administratorId') === +this.ownerId;
	},

	getForClinician (clinicianId) {
		return this.retrieve('clinicianInRoles', clinicianId);
	}
});

export default new ClinicianInRoles();
