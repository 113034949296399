import mixin from './_mixin';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default (modalCfg) => new Vue({
	mixins: [mixin(modalCfg)],
	data: {
		updating: false
	},
	computed: {
		...mapGetters({ codeTypes: 'dataIntegration/codeTypes/list' }),
		...mapGetters({ enterprises: 'dataIntegration/enterprises/list' }),
		codeTypeOptions () {
			return this.selectOptions(this.filterEnabled(this.codeTypes));
		},
		enterpriseOptions () {
			return this.selectOptions(this.filterEnabled(this.enterprises));
		}
	},
	template: `
		<standard-dialog
			:config="config"
			:codeTypeOptions="codeTypeOptions"
			:enterpriseOptions="enterpriseOptions"
			:processing="processing"
			:updating="updating"
			:vuelidate="$v"
			:warnings="warnings"
		/>
	`,
	validations: {
		item: {
			code: {
				required
			},
			codeType: {
				required
			},
			name: {
				required
			}
		}
	}
});
