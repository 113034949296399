'use strict';

import t from 'translate';
import $ from 'jquery';
import repository from 'repository';

const card = {
	title: t('View link'),
	name: 'view-link',
	url: 'link/:id/view',

	controller: function (packageId) {
		const link = repository.getLink(packageId);

		this.cardContext().set({ link });

		$.when(link).done(() => this.populate());
	}
};

export {card as ViewLink};
