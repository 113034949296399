import can from 'acl-can';
import cwalert from 'cwalert';
import t from 'translate';
import appBar from 'app-bar';
import cardurl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import AccessRole from 'repo/access-roles/access-role-entity';

const card = {
	title: t('system-access-management.card-title'),
	name: 'system-access-management-role',
	url: ['system/access/role/:roleId'],
	appEvents: {
		'access-role.delete' (role) {
			const currentRole = this.cardContext().get('access-role');

			if (
				currentRole &&
				currentRole.get('administratorRoleId') === role.get('administratorRoleId')
			) {
				this.close();
			}
		}
	},
	controller (roleId) {
		const self = this;
		const accessRole = new AccessRole({ administratorRoleId: roleId });
		accessRole.fetch().then(() => {
			this.cardContext().set('access-role', accessRole);
			this.populate();

			if (!can.remove('system.settings.administratorroles')) {
				return;
			}

			appBar.addButton(card.name, {
				title: t('Delete role'),
				icon: 'delete',
				click () {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete role'),
						message: t('Do you really want to delete the role {role}?', {
							role: accessRole.get('administratorRoleName')
						})
					}).done(function () {
						accessRole.destroy({
							success () {
								cwalert.success(t('general-list.RemoveSuccessfully'));
								self.close();
							},
							error () {
								cwalert.failure(t('general-list.RemoveError'));
							}
						});
					});
				}
			});
		});

		this.cardContext().on('change:access-role', function (context, accessRole) {
			if (accessRole) {
				this.navigate(cardurl.buildUrl(card.name, {
					roleId: accessRole.get('administratorRoleId')
				}));
			} else {
				this.navigate(cardurl.buildUrl('system-access-management'));
			}
		}, this);
	}
};

export { card as accessManagementRole };
