import { Model } from 'backbone';
import { assign } from 'lodash';

const Clinician = Model.extend({
	url: 'backend-commons/auth/admin.json',

	getTimezone () {
		return this.get('timezoneName');
	},
	getId () {
		return this.get('userId');
	}
});

const clinician = new Clinician();

export default () => assign(clinician, clinician.fetch());
