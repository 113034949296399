<template>
	<div>
		<div class="consent-instances__search">
			<input
				v-model="search"
				tabindex="0"
				class="consent-instances__search-input"
				type="text"
				@keyup="somethingSelected"
			/>
			<button
				v-translate
				tabindex="0"
				class="consent-instances__search-clear"
				:disabled="!isSomethingSelected"
				type="reset"
				@click="clearSearch()"
			>Clear all</button>
			<icon
				v-if="!searchValue"
				class-name="consent-instances__search-icon-search"
				name="search"
				:small="true"
			/>
		</div>
		<consent-instances
			:items="consentInstances"
			:fields="fields"
			:on-click="open"
			:class-prefix="classPrefix"
		>
			<template #header="{ label }">
				{{label}}
			</template>

			<template #cell="{ item, fieldKey }">
				<p
					v-if="labelVisible({ item, key: fieldKey })"
					class="table-cell-label"
				>{{label(fieldKey)}}</p>
				<span v-if="fieldKey === 'status'">
					<icon
						:name="iconName(item.status)"
						:title="description(item)"
					/>
					<span :class="elementClass('status-title')">{{title(item)}}</span>
				</span>
				<button
					v-else-if="fieldKey === 'deleteAction' && allowDelete(item)"
					:class="elementClass('remove-action')"
					@click.stop="remove(item)"
				>
					<icon name="delete" />
				</button>

				<span v-else>{{item[fieldKey]}}</span>
			</template>

		</consent-instances>
	</div>
</template>

<script>
import t from 'translate';
import ConsentInstances from '../../components/consent-instances';
import { assign, find, get, map, isEmpty, kebabCase } from 'lodash';
import relationName from '../../shared/relation-name';
import cardUrl from 'cwcardurl';
import formatDate from '../../shared/format-date';
import { NOT_SUBMITTED } from '../../shared/statuses';
import { description, title } from '../../shared/get-title';

export default {
	title: t('consents.newlyModified'),
	acl: [{
		checkpoint: 'administrator.respondents.consents',
		op: 'READ'
	}],
	actions: ({ $store }) => [['consentInstances/initNewlyModifiedConsents', {
		clinicianId: $store.getters.userId,
		value: ''
	}]],

	components: { ConsentInstances },

	data: () => ({
		classPrefix: 'consent-instances',
		fields: [
			{ key: 'status', label: t('consents.status') },
			{ key: 'name', label: t('consents.name') },
			{ key: 'assignedTo', label: t('consents.assignedTo') },
			{ key: 'givenBy', label: t('consents.firstSubmittedBy') },
			{ key: 'submittedOn', label: t('consents.firstSubmittedOn') },
			{ key: 'lastModifiedOn', label: t('consents.lastModifiedOn') },
			{ key: 'modifiedBy', label: t('consents.lastModifiedBy') },
			{ key: 'deleteAction', label: '' }
		],
		searchValue: null,
		isSomethingSelected: false
	}),
	computed: {
		timezone: ({ $store }) => $store.getters.userTimezone,

		consentInstances: ({ $store, timezone }) => map(
			$store.getters['consentInstances/items'],
			(item) => assign({}, item, {
				assignedTo: relationName({ person: item.respondent }),
				submittedOn: formatDate(item.firstSubmittedAt, timezone),
				lastModifiedOn: formatDate(item.updatedAt, timezone),
				givenBy: relationName({ person: item.firstSubmittedBy, includeClinician: true }),
				modifiedBy: relationName({ person: item.updatedBy, includeClinician: true })
			})
		),
		search: {
			get () {
				return this.searchValue;
			},
			set (value) {
				const clinicianId = this.$store.getters.userId;
				this.searchValue = value;
				this.$store.dispatch('consentInstances/initNewlyModifiedConsents', {
					clinicianId,
					value
				});
			}
		}
	},
	methods: {
		description,
		title,
		elementClass (element) {
			return `${this.classPrefix}__${element}`;
		},
		allowDelete (item) {
			return item.status === NOT_SUBMITTED;
		},
		open (item) {
			cardUrl.openCard('consent-instance', {
				consentInstanceId: item.id
			});
		},
		remove (item) {
			const consentInstanceId = item.id;
			this.$store.dispatch(
				'consentInstances/remove',
				consentInstanceId
			);
		},
		somethingSelected () {
			this.isSomethingSelected = !isEmpty(this.searchValue);
		},
		initSearch () {
			const clinicianId = this.$store.getters.userId;
			this.$store.dispatch('consentInstances/initNewlyModifiedConsents', {
				clinicianId,
				value: this.searchValue
			});
		},
		clearSearch () {
			this.searchValue = '';
			this.initSearch();
			this.somethingSelected();
		},
		iconName: (status) => `consent-${kebabCase(status)}`,
		label (key) {
			return get(find(this.fields, { key }), 'label', '');
		},
		labelVisible ({ item, key }) {
			return key === 'deleteAction' ? this.allowDelete(item) : true;
		}
	}
};
</script>
