'use strict';

import Backbone from 'backbone';
import t from 'translate';

const card = {
	title: t('Plan Simulator'),
	name: 'plan-simulator',
	url: 'plan/:planId/simulator',

	controller: function () {
		this.cardContext().set('simulator-log', new Backbone.Collection());
		this.populate();
	}
};

export { card as PlanSimulator };
