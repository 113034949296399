<template>
	<div class="treatment-attribute__container" :class="additionalStates">
		<div class="treatment-attribute__title-container">
			<p v-if="title" class="treatment-attribute__title">
				{{title}}
			</p>
			<p v-if="subtitle" class="treatment-attribute__subtitle">
				{{subtitle}}
			</p>
		</div>
		<p class="treatment-attribute__value">
			{{displayValue}}
		</p>
	</div>
</template>

<script>
import t from 'service/lang/translate';
import { TREATMENT } from 'store/treatments/treatment';
import { every, find, forEach, get, invoke, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { EMPTY_CHAR } from 'lib/chars';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

export default {
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}],
	title: '',
	actions: ['treatment/getAttributesValues'],
	data: ({ tile }) => ({ cfg: tile.config() }),

	computed: {
		mainClasses: () => ['treatment-attribute'],
		title: ({ cfg, mt }) => mt(cfg.title),
		subtitle: ({ cfg, mt }) => mt(cfg.subtitle),
		attributes: ({ $store }) => $store.getters[TREATMENT.ATTRIBUTE_VALUES],
		value: ({ attributeValue, cfg }) => attributeValue(cfg.treatmentTypeAttributeId),
		displayValue: ({ value, formattedValue }) => isNil(value) ? EMPTY_CHAR : formattedValue,

		formattedValue: ({ cfg, value }) => cfg.dateFormat ?
			DateTime.fromISO(value).toFormat(cfg.dateFormat) :
			value,

		additionalStates ({ cfg }) {
			const states = [];

			forEach(cfg.states, (state) => {
				const passed = every(
					state.calc,
					(treatmentTypeAttributeId, calc) => invoke(
						this,
						calc,
						this.attributeValue(treatmentTypeAttributeId)
					)
				);

				if (passed) {
					states.push(state.name);
				}
			});

			return states;
		}
	},

	methods: {
		mt (phrase) {
			return this.cfg.translate ? t(phrase) : phrase;
		},

		dateBeforeNow (value) {
			return DateTime.fromISO(value) < DateTime.local();
		},

		isEmpty (value) {
			return isNil(value);
		},

		isNotEmpty (value) {
			return !isNil(value);
		},

		attributeValue (treatmentTypeAttributeId) {
			return get(
				find(this.attributes, { typeAttribute: `${treatmentTypeAttributeId}` }),
				'value'
			);
		}
	}
};
</script>
