<template>
	<div class="object-information__container object-information__content">
		<div class="object-information__element-container">
			<p v-translate class="object-information__label-container">
				Plan
			</p>
			<p class="object-information__value-container">
				<a :href="planUrl">{{planName}}</a>
			</p>
		</div>
		<div class="object-information__element-container important">
			<p v-translate class="object-information__label-container">
				Recipient
			</p>
			<p class="object-information__value-container">
				<strong>{{recipientName}}</strong> ({{recipientType}})
			</p>
		</div>
		<div class="object-information__element-container">
			<p v-translate class="object-information__label-container">
				Start date
			</p>
			<p class="object-information__value-container">
				{{startDate}}
			</p>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash';
import cardUrl from 'cwcardurl';
import { respondentName, clinicianName } from 'service/display-name/display-name';
import datetime from 'datetime';
import t from 'translate';

export default {
	acl: [],
	instant: true,
	computed: {
		decision: ({ $store }) => get($store.state, 'decision.data'),

		planName: ({ decision }) => decision.workflowInstanceName,

		planUrl: ({ decision }) => cardUrl.buildUrl('plan-properties', {
			planInstanceId: decision.workflowInstanceId
		}),

		recipientType: ({ decision }) => decision.recipientIsRespondent ?
			t('Respondent') :
			t('Clinician'),

		recipientName: ({ decision }) => decision.recipientIsRespondent ?
			respondentName(decision.role.respondent) :
			clinicianName(get(decision.role, 'clinician') || decision),

		startDate: ({ $store, decision }) => datetime(decision.issueDate)
			.setTimezone(get($store.getters, 'respondent.respondentTimezoneName'))
			.toMoment()
			.format(t('date.formats.dateTime'))
	}
};
</script>
