import ShortcutTile from 'components/mini-tiles/shortcut';
import t from 'translate';

export default ShortcutTile.extend({
	acl: {
		op: 'or',
		checkpoints: [{
			checkpoint: 'system.settings.settings',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.administratorgroups',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.administratorattributes',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.administratorroles',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.respondentattributes',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.respondentgroups',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.contexts',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.assessments',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.remotescoring',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.reports',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.contentmanagement',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.languages',
			op: 'READ'
		}, {
			checkpoint: 'system.settings.export-templates',
			op: 'READ'
		}]
	},
	targetCard: 'system-settings',
	content: t('System Settings')
});
