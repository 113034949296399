import can from 'acl-can';
import t from 'translate';

const card = {
	url: 'treatment/:treatmentId/video-chat',
	name: 'video-chat',
	title: t('Live video stream'),
	acl: 'administrator.respondents.treatments',
	ctrl: ({ close, populate, cardData }, { treatmentId }) => {
		if (!can.read(card.acl) || !treatmentId) {
			close();
			return;
		}

		if (treatmentId) {
			cardData.set({ treatmentId });
			populate();
		}
	}
};

export { card as chat };
