import Backbone from 'backbone';
import acl from 'acl';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import appContext from 'app-context';
import t from 'translate';

export default Backbone.View.extend({

	title: t('system-access-management.role-properties'),
	acl: [{
		checkpoint: 'system.settings.administratorroles',
		op: 'READ'
	}],

	noRoleSelected: function () {
		$(this.el).append('<p>' + t('No role selected.') + '</p>');
	},

	render: function () {
		var role = this.cardContext().get('access-role');
		var tile = this;
		if (!role) {
			this.noRoleSelected();
			return;
		}

		tile.$el.append(formView({
			name: 'edit-role',
			model: role,
			onAfterSave: appContext.trigger.bind(appContext, 'access-role.change'),
			readonly: !acl.checkAccess({
				checkpoint: 'system.settings.administratorroles',
				op: 'EDIT'
			}),


			fields: [{
				key: 'administratorRoleName',
				type: 'text',
				required: true
			}, {
				key: 'administratorRoleActive',
				type: 'checkbox'
			}]
		}).$el);
	}
});
