import appBar from 'app-bar';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import { when } from 'jquery';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('dataIntegration.codes.name'),
	name: 'data-integration-code-edit',
	url: 'data-integration/code/:codeId',

	ctrl: ({ close, setLoaded, setLoading }, { codeId }) =>  {

		if (can.delete(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE) && codeId !== '0') {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'delete',
				title: t('dataIntegration.codes.actions.remove'),
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('dataIntegration.codes.actions.remove'),
						message: t('dataIntegration.codes.actions.delete')
					}).done(() => {
						setLoading();
						store.dispatch('dataIntegration/codes/delete', codeId).then(() => {
							cwalert.success(t('dataIntegration.codes.messages.deleted'));
							close({ go: 'data-integration/codes' });
						}).catch(() => {
							setLoaded();
							cwalert.error(t('dataIntegration.codes.messages.inUse'));
						});
					});
				}
			});
		}
		return when(
			store.dispatch('dataIntegration/codes/getSingle', codeId),
			store.dispatch('dataIntegration/enterprises/search', { enabled: true, search: '' })
		);
	}
};

export { card as codeEdit };
