<template>
	<form @submit.prevent="onSubmit">
		<text-input
			v-model="data.name"
			:disabled="disabled"
			:invalid="invalidName"
			:label="$t('Name')"
			:mandatory="true"
			@input="updateName"
		/>
		<media-search
			v-model="data.cover"
			:label="$t('Cover image')"
			:mime-types="allowedCoverTypes"
			@input="onUpdate"
		/>
		<text-input
			v-model="data.description"
			:disabled="disabled"
			:label="$t('Description')"
			:multiline="true"
			@input="onUpdate"
		/>
		<checkbox-input
			v-model="data.isDefault"
			:disabled="disabled"
			:label="$t('Is default')"
			input-class="checkbox-input"
			text-class="checkbox-text"
			@input="onUpdate"
		/>
		<select-input
			v-model="enterprise"
			container-class="select-container"
			class-prefix="data-integration"
			label="dataIntegration.enterprise.name"
			:disabled="disabledEnterprise"
			:mandatory="true"
			:options="enterprises"
			:placeholder="$t('dataIntegration.enterprise.actions.select')"
			:searchable="false"
			@input="onEnterpriseUpdate"
		/>
		<button-group v-if="addAction">
			<submit-button
				:can-submit="!$v.data.$invalid"
				:label="$t('Save')"
			/>
		</button-group>
	</form>
</template>

<script>
import can from 'acl-can';
import cwalert from 'cwalert';
import { assign, concat, debounce, find } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { SYSTEM } from 'service/acl/checkpoints.json';
import MediaSearch from 'modules/media/components/media-search';

export default {
	components: { MediaSearch },
	props: {
		action: {
			type: String,
			required: true
		},
		closeCard: {
			type: Function,
			required: true
		}
	},

	data: ({ $t }) => ({
		enterpriseToAdd: null,
		saving: false,
		emptyEnterprise: {
			label: $t('dataIntegration.enterprise.options.noEnterprise'),
			value: null
		},
		allowedCoverTypes: [
			'image/jpeg',
			'image/png',
			'image/gif'
		]
	}),

	computed: {
		...mapGetters({
			data: 'treatmentType/data',
			enabledEnterprises: 'treatmentType/enabledEnterprises',
			ruleSet: 'treatmentType/ruleSet'
		}),
		addAction: ({ action }) => action === 'add',
		canAdd: () => can.add(SYSTEM.SETTINGS.TREATMENT_TYPES),
		canEdit: () => can.edit(SYSTEM.SETTINGS.TREATMENT_TYPES),
		disabled () {
			const allowed = this.addAction ? this.canAdd : this.canEdit;
			return !allowed || this.saving;
		},
		disabledEnterprise () {
			return this.disabled || this.ruleSet;
		},
		enterprise: {
			get () {
				const source = this.addAction ? this.enterpriseToAdd : this.data.enterprise;
				return source ?
					assign({ label: source.name, value: source.id }, source) :
					this.emptyEnterprise;
			},
			set (option) {
				const enterprise = option.value ?
					{ ...find(this.enabledEnterprises, { id: option.value }) } :
					this.emptyEnterprise;

				if (this.addAction) {
					this.enterpriseToAdd = enterprise;
				}
				this.data.enterprise = enterprise;
			}
		},
		enterprises ({ emptyEnterprise, $store }) {
			if ($store.getters['treatmentType/enterprises'].length) {
				return concat(
					assign(emptyEnterprise, { label: emptyEnterprise.label }),
					[...$store.getters['treatmentType/enterprises']]
				);
			}
			return [];
		},
		invalidName: ({ $v }) => !$v ? false : $v.data.name.$dirty && $v.data.name.$invalid
	},

	created () {
		this.searchRuleSet();
	},

	methods: {
		...mapActions('treatmentType', ['add', 'update', 'determineRuleSet']),
		onEnterpriseUpdate () {
			this.onUpdate();
			this.searchRuleSet();
		},
		onSubmit () {
			if (!this.$v.data.$invalid) {
				this.saving = true;
				this.add(this.data).then((response) => {
					cwalert.success(this.$t('Treatment type saved successfully'));
					this.closeCard({ // taken from the prop
						openCard: 'edit-treatment-type',
						cardQuery: { treatmentTypeId: response.id }
					});
				});
			}
		},
		onUpdate: debounce(function () {
			if (!this.addAction && !this.$v.data.$invalid) {
				const enterpriseExists = this.data.enterprise && this.data.enterprise.id;
				const data = assign(
					{ ...this.data },
					{ enterprise: enterpriseExists ? this.data.enterprise : null }
				);
				this.update(data).then(() => {
					cwalert.success(this.$t('Treatment type updated successfully'));
				}).catch(() => {
					cwalert.error(this.$t('A rule set already defined for the current enterprise'));
				});
			}
		}, 500),
		searchRuleSet () {
			if (!this.addAction) {
				this.determineRuleSet(this.data.id);
			}
		},
		updateName () {
			this.$v.data.name.$touch();

			if (!this.addAction) {
				this.onUpdate();
			}
		}
	},

	validations: {
		data: {
			name: { required }
		}
	}
};
</script>
