import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { sync } from 'backbone';

export default SingleEntity.extend({
	eventNamespace: 'export-template-column-treatment-type-attribute',
	url: urls.treatmentTypeAttribute.url,
	onSync (method, model, options) {
		if (method === 'create') {
			options.type = 'PUT';
		}
		// eslint-disable-next-line prefer-rest-params
		return sync.apply(this, arguments);
	}
});
