import { trim } from 'lodash';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';
import cwalert from 'cwalert';
import appContext from 'app-context';
import handleResponseErrors from 'modules/general/shared/handle-response-errors';

export default function () {

	const formConfig = {
		name: 'add-respondent-group',
		item: { name: '', category: '', common: false },
		submit: (group) => {
			store.dispatch('respondentGroups/add', group)
				.then(() => {
					cwalert.success(t(
						'Respondent group {group} has been added',
						{ group: group.name }
					));
					appContext.trigger('dialog:cancel');
				})
				.catch(handleResponseErrors);
		},
		fields: [{
			key: 'name',
			type: 'text',
			label: t('Group name'),
			mandatory: true,
			validators: {
				validator: (value) => !!trim(value),
				invalidMessage: t('system-settings.respondent-groups.cannot-be-empty')
			}
		}, {
			key: 'category',
			type: 'text',
			label: t('Group category')
		}, {
			key: 'common',
			type: 'checkbox',
			label: t('Group shall be available for clinician selection')
		}]
	};

	const dialogConfig = {
		title: t('New respondent group')
	};

	formViewDialog({
		formConfig,
		dialogConfig
	});
}
