import Notification from './notification';

/**
 * @class Repository
 */
export default {
	/**
	 * Get notifications for clinician.
	 *
	 * @function  notification
	 * @param {string} type - Notification type.
	 * @returns {Object} Promise.  {{#crossLink "NotificationEntity"}}{{/crossLink}}.
	 * @example
	 *        repository.notification('flagsUnseen')
	 */
	notification (type) {
		const notification = new Notification({ type });
		return notification.retrieve();
	}
};
