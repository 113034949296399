import exports from './system-exports/tile';
import exportAssessmentData from './system-exports-assessment-data/tile';
import exportAssessmentPlans from './system-exports-assessment-plans/tile';
import exportAssessmentStatus from './system-exports-assessment-status/tile';
import exportClinicianGroups from './system-exports-clinician-groups/tile';
import exportClinicians from './system-exports-clinicians/tile';
import groupsMapping from './system-exports-clinician-to-respondent-groups-mapping/tile';
import exportLoginSessions from './system-exports-login-sessions/tile';
import exportRespondentGroups from './system-exports-respondent-groups/tile';
import exportRespondents from './system-exports-respondents/tile';
import exportTemplates from './system-exports-templates/tile';
import templateDetails from './template-details/tile';
import addExport from './add-export/tile';
import exportTreatments from './system-exports-treatments/tile';
import exportLogs from './system-exports-logs';

export default {
	'system-exports': exports,
	'system-exports-assessment-data': exportAssessmentData,
	'system-exports-assessment-plans': exportAssessmentPlans,
	'system-exports-assessment-status': exportAssessmentStatus,
	'system-exports-clinician-groups': exportClinicianGroups,
	'system-exports-clinicians': exportClinicians,
	'system-exports-clinician-to-respondent-groups-mapping': groupsMapping,
	'system-exports-login-sessions': exportLoginSessions,
	'system-exports-respondent-groups': exportRespondentGroups,
	'system-exports-respondents': exportRespondents,
	'system-exports-templates': exportTemplates,
	'template-details': templateDetails,
	'add-export': addExport,
	'system-exports-treatments': exportTreatments,
	'system-exports-logs': exportLogs
};
