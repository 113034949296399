import codeType from '../dialogs/code-types';
import addConfig from '../helpers/add-config';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('dataIntegration.codeType.plural'),
	name: 'data-integration-code-types',
	url: 'data-integration/code-types',

	ctrl: ({ populate }) =>  {
		addConfig({
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
			action: 'codeTypes/add',
			card,
			dataIntegrationFn: codeType,
			item: { code: '', enabled: false, name: '' },
			populate,
			type: 'codeType'
		});
		populate();
	}
};

export { card as codeTypes };
