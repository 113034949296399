import MultipleEntities from '../shared/multiple-entities';
import ContentPackageInstance from './cp-instance';
import urls from './urls';
import moment from 'moment';

const ContentPackageInstances = MultipleEntities.extend({
	model: ContentPackageInstance,
	urls,
	eventNamespace: 'information-package-instance',

	canAdd (model) {
		return +model.get('respondent') === +this.ownerId;
	},

	getAll () {
		return this.retrieve('listAll');
	},

	getByRespondentId (respondentId) {
		return this.retrieve('listByRespondent', respondentId);
	},

	getByTreatmentId (treatmentId) {
		return this.retrieve('listByTreatment', treatmentId);
	},

	_sort (iteratee) {
		return this.sortBy(iteratee);
	},

	sortByDate ({ desc }) {
		const clone = this.clone();
		clone.reset(this._sort((model) => (desc ? -1 : 1) * moment(model.get('startAt'))));

		return clone;
	}
});

export default new ContentPackageInstances();
