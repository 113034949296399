import t from 'translate';
import systemSettings from 'system-settings';
import repository from 'repository';
import { when } from 'jquery';
import store from 'store';

const card = {
	name: 'clinician-my-profile',
	title: t('My Profile'),
	url: 'my-profile',

	ctrl: ({ cardData }) => {
		if (!systemSettings.getBoolean('ENABLE_CLINICIAN_PERSONAL_SETTINGS_PAGE')) {
			return;
		}

		const clinician = repository.clinician(store.state.user.userId);
		const circleInit = systemSettings.getBoolean('ENABLE_CIRCLES') ?
			store.dispatch('circles/profile/init') :
			{};

		return when(
			store.dispatch('languages/init', { includeDisabled: true }),
			store.dispatch('timezones/init'),
			clinician,
			circleInit
		).then(() => {
			store.dispatch('languages/setSelected', clinician.get('administratorLanguageId'));
			cardData.set({
				model: clinician,
				userType: 'administrator'
			});
		});
	}
};

export { card as MyProfile };
