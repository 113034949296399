/**
 * State : editable, readonly, disabled
 * tr     : HTML tr.
 *
 * Model     : model
 * attrName  : model attribute name.
 *
 * @param params
 */
import celllabel from 'cell-label';

const Cell = function (params) {

	const __td = document.createElement('td');

	if (params.className) {
		__td.className = params.className;
	}
	params.tr.appendChild(__td);
	celllabel(__td, params.label);

	__td.appendChild(params.renderer
		.getDOMNode());
};

export default Cell;

