var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canAdd
        ? _c("div", [
            _c("h2", { staticClass: "respondent-relation__label" }, [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.t("Create a new relation")) + "\n\t\t"
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "form-view__hint" }, [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.t("Set a new relation")) + "\n\t\t"
              )
            ])
          ])
        : _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "form-view__hint"
            },
            [
              _vm._v(
                "\n\t\t" +
                  _vm._s(_vm.t("Not authorised to add a relation")) +
                  "\n\t"
              )
            ]
          ),
      _vm._v(" "),
      _vm.options.length
        ? _c("div", { staticClass: "respondent-relation__select-container" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onSubmit($event)
                  }
                }
              },
              [
                _vm.canAdd
                  ? _c("select-input", {
                      attrs: {
                        options: _vm.options,
                        "container-class": "respondent-relation",
                        "class-prefix": "respondent-relation",
                        label: "Choose relation type",
                        mandatory: true,
                        required: true
                      },
                      on: { input: _vm.triggerChange },
                      model: {
                        value: _vm.relation,
                        callback: function($$v) {
                          _vm.relation = $$v
                        },
                        expression: "relation"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.relation
                  ? _c(
                      "fieldset",
                      {
                        staticClass: "respondent-relation__respondent-container"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "respondent-relation__respondent-left"
                          },
                          [_vm._v(_vm._s(_vm.respondent1.role))]
                        ),
                        _vm._v(" "),
                        _c("a", {
                          class: _vm.iconClasses,
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.toggle($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "respondent-relation__respondent-right"
                          },
                          [_vm._v(_vm._s(_vm.respondent2.role))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.relation
                  ? _c(
                      "fieldset",
                      { staticClass: "respondent-relation__input-container" },
                      [
                        _c("text-input", {
                          attrs: {
                            "class-additional": "input-left",
                            "class-prefix": "respondent-relation",
                            disabled: true,
                            label: "",
                            "show-label": false
                          },
                          model: {
                            value: _vm.setFirst().name,
                            callback: function($$v) {
                              _vm.$set(_vm.setFirst(), "name", $$v)
                            },
                            expression: "setFirst().name"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "respondent-relation__input-right" },
                          [
                            _c("quick-search", {
                              attrs: {
                                clear: _vm.clearResults,
                                "excluded-id": _vm.respondent1.id,
                                "get-results": _vm.getResults,
                                "on-click": _vm.setRespondent,
                                list: _vm.list,
                                loading: _vm.loading,
                                url: "",
                                urls: false,
                                label: _vm.$t("Respondent search")
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("submit-button", {
                          attrs: {
                            label: "Save",
                            "can-submit": _vm.canSubmit,
                            "class-prefix": "respondent-relation",
                            "container-class": "button-container"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("header", { staticClass: "respondent-relation__label-table" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.t("Existing relations")) + "\n\t")
      ]),
      _vm._v(" "),
      _c("respondent-relations", { attrs: { id: _vm.id } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }