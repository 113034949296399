import TileView from 'tile-view';
import can from 'acl-can';
import t from 'translate';
import datamodel from 'components/old-components/datamodel/datamodel';
import cardurl from 'cwcardurl';
import repository from 'repository';

export default TileView.extend({
	title: t('system-manager.AdministratorRoles'),
	acl: [{
		checkpoint: 'system.settings.administratorroles',
		op: 'READ'
	}],

	init () {
		this.roles = repository.getClinicianRoles();
	},

	onRender () {
		this.roles.done(this.loaded.bind(this)).always(this.setLoaded.bind(this));
	},

	loaded () {
		this.table = new datamodel.grid.Table({
			css: 'wide-table',
			parent: this.el,
			collection: this.roles,
			readonly: !can.edit('system.settings.administratorroles'),

			columns: {
				administratorRoleName: {
					label: t('general-list.Name'),
					rendererFactory: new datamodel.widgets.LabelFactory()
				},
				administratorRoleActive: {
					className: 'system-access-list__active',
					label: t('system-manager.RoleActive'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				}
			},

			container: this.el,

			actions: {
				remove: {
					disabled: !can.remove('system.settings.administratorroles'),
					confirmationConfig: {
						icon: 'delete',
						warning: true,
						title: t('Delete role'),
						message: t('general-list.ConfirmRemove')
					}
				}
			},

			onRowClick () {
				if (can.read('system.settings.administratorroles')) {
					cardurl.openCard('system-access-management-role', {
						roleId: this.get('administratorRoleId')
					});
				}
			}
		});
	}
});

