import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import pageEditor from './page-editor';
import { Deferred } from 'jquery';

export default TileView.extend({
	acl: [{
		op: 'ADD',
		checkpoint: 'system.settings.contentmanagement'
	}],
	title: t('Edit page'),

	cardData: () => ['page', 'categories'],

	tileData: ({ tile }) => ({
		addPage: tile.page.isNew()
	}),

	loaded: ({ tile }) => {
		if (tile.addPage) {
			tile.setTitle(t('Add page'));
		}
		const originalPermalink = tile.page.get('alias');

		tile.form = formView({
			name: 'edit-page',
			model: tile.page,
			saveIndicator: true,
			listenTo: ['Submit'],
			onAfterSave: () => {
				tile.addPage && tile.card().close({ openCard: 'pages' });
			},
			fields: [{
				key: 'name',
				type: 'text',
				label: t('Name'),
				mandatory: true
			}, {
				key: 'alias',
				// @TODO use form-view unique-username (type: 'username') when backend will support
				// permalink check for pages
				type: 'text',
				mandatory: true,
				label: t('Permanent link'),
				validators: {
					validator: (value) => {
						const promise = Deferred();

						if (value === originalPermalink) {
							promise.resolve();

						} else {
							// can't use array of validators here, because permalink check is
							// asynchronous so it overrides validator with regexp
							const re = /^[a-z0-9-_.]+$/;

							if (!re.test(value)) {
								promise.reject();

							} else {
								const page = repository.getPageByPermalink(value);
								page.then(() => {
									// reject() to tell the validator that the page with entered
									// permalink exists
									promise.reject();
								}, () => {
									promise.resolve();
								});
							}
						}

						return promise;
					},
					invalidMessage: t('links.permanent.hint')
				}
			}, {
				key: 'category',
				type: 'multiselect',
				label: t('Category'),
				collection: tile.categories,
				header: t('Select page category')
			}, {
				key: 'content',
				type: 'text',
				label: t('Content'),
				customize: pageEditor(tile.page)
			}],
			buttons: [{
				caption: tile.addPage ? t('Add') : t('Save'),
				name: 'save',
				type: 'submit'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});
