import t from 'translate';
import store from 'store';

const card = {
	title: t('Respondent information packages'),
	name: 'content-package-instance-list',
	url: [
		'treatment/:treatmentId/content-packages',
		'respondent/:respondentId/content-packages'
	],

	ctrl: ({ populate }, { respondentId }) => {
		if (respondentId) {
			store.dispatch('treatment/resetId');
		}

		populate();
	}
};

export { card as InstanceList };
