import t from 'translate';
import mixin from './_mixin';
import { validation } from './_validation-mixin';
import { mapGetters } from 'vuex';

export default () => ({
	name: 'respondent-attribute',
	mixins: [mixin, validation],

	data: () => ({
		defaultType: t('Respondent attribute'),
		identifierField: 'dataExportTemplateColumnRespondentFieldName',
		ids: {
			identity: 'dataExportTemplateColumnRespondentFieldId'
		}
	}),

	computed: {
		...mapGetters({
			attributes: 'respondent/attributes/translatedList'
		})
	}
});
