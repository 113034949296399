import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import { when } from 'jquery';
import appConnectionDialog from '../dialogs/treatment-app-connection';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	url: [
		'connected-apps',
		'treatment/:treatmentId/connected-apps/'
	],
	name: 'connected-apps',
	title: t('Connected Apps'),

	ctrl: (context, { treatmentId, respondentId }) => {
		if (can.add(SYSTEM.SETTINGS.SETTINGS)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('Connect a new App'),
				click: appConnectionDialog
			});
		}

		return when(
			store.dispatch('apps/initConnected', {
				'treatment.id': treatmentId,
				'respondent.id': respondentId
			}),
			store.dispatch('apps/initActivated'),
			store.dispatch('treatment/init', { treatmentId })
		);
	}
};

export { card as connectedApp };
