import pagesList from './pages-list/tile';
import page from './page/tile';
import edit from './edit-page/tile';
import PageCategories from './system-settings-page-categories/tile';
import simpleTreatmentPages from '../../pages/tiles/simple-treatment-pages/tile';

export default {
	'pages-list': pagesList,
	page,
	'edit-page': edit,
	'simple-treatment-pages': simpleTreatmentPages,
	'system-settings-page-categories': PageCategories
};
