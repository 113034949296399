<template>
	<assign-items
		:fields="fields"
		:labels="labels"
		:getters="getters"
		:actions="actions"
	/>
</template>

<script>
import t from 'translate';

export default {
	title: t('Role sets'),
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	actions: (tile) => {
		const planId = tile.cardContext().get('planId');
		return [
			['plan/init', { planId }],
			['roleSets/searchAll'],
			['plan/getRoleSets', planId]
		];
	},

	data: () => ({
		fields: ['name', 'description'],
		labels: {
			empty: t('The assessment plan has no role set assigned'),
			name: t('Name'),
			description: t('Description'),
			removeItem: t('Remove the role set'),
			removeQuestion: t('Are you sure you wish to remove the role set?'),
			removeSuccess: t('Role set removed successfully'),
			selectItem: t('- select role set to add -'),
			addSuccess: t('Role set added')
		},
		getters: {
			allItems: 'roleSets/list',
			items: 'plan/roleSets'
		},
		actions: {
			removeItem: 'plan/deleteRoleSet',
			addItem: 'plan/addRoleSet'
		}
	})
};
</script>
