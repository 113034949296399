import ContentPackageInstances from './cp-instances';
import ContentPackageInstanceElements from './cp-instance-elements';
import { DELETE, PUT } from 'service/ajax/ajax';
import urls from './urls';
import { assign } from 'lodash';

/**
 * @class Repository
 */
export default {

	/**
	 * Get all ContentPackageInstances assigned to a respondent.
	 *
	 * @function respondentContentPackages
	 * @param {number} respondentId - Respondent ID.
	 * @returns {object}            - New ContentPackageInstances Collection.
	 * @example
	 *        repository.respondentContentPackages(19);
	 */
	respondentContentPackages: (respondentId) =>
		ContentPackageInstances.getByRespondentId(respondentId),

	/**
	 * Get all ContentPackageInstances assigned to a treatment.
	 *
	 * @function treatmentContentPackageInstances
	 * @param {number} treatmentId - Treatment ID.
	 * @returns {object}           - New ContentPackageInstances Collection.
	 * @example
	 *        repository.treatmentContentPackageInstances(8);
	 */
	treatmentContentPackageInstances: (treatmentId) =>
		ContentPackageInstances.getByTreatmentId(treatmentId),

	/**
	 * Get ContentPackageInstance by id.
	 *
	 * @function contentPackageInstance
	 * @param {number} id - Content Package Instance ID.
	 * @returns {object}  - New ContentPackageInstances Model.
	 * @example
	 *        repository.contentPackageInstance(7);
	 */
	contentPackageInstance: (id) => ContentPackageInstances.getById(id),

	/**
	 * Get ContentPackageInstanceElements by id.
	 *
	 * @function contentPackageInstanceElements
	 * @param {number} id - Content Package Instance ID.
	 * @returns {object}  - New ContentPackageInstanceElements Collection.
	 * @example
	 *        repository.contentPackageInstanceElements(7);
	 */
	contentPackageInstanceElements: (id) =>
		ContentPackageInstanceElements.getByContentPackageInstanceId(id),

	/**
	 * Get ContentPackageInstanceExercises.
	 *
	 * @function contentPackageInstanceExercises
	 * @param {object} params            - Parameters.
	 * @param {number} params.treatment  - Treatment ID.
	 * @param {number} params.respondent - Respondent ID.
	 * @returns {object}                 - New ContentPackageInstanceExercises Collection.
	 * @example
	 * 		repository.contentPackageInstanceExercises({ treatment: 4, respondent: 19 });
	 */
	contentPackageInstanceExercises: (params) =>
		ContentPackageInstanceElements.exercisesWithRoles(params),

	/**
	 * @param {object} data - Content Package Instance.
	 * @returns {object}    - Promise.
	 * @example
	 * 		repository.activateContentPackageInstance(1984);
	 */
	activateContentPackageInstance: (data) => PUT({
		url: urls.cpInstance(data.id),
		data: assign({}, data, { state: 'ACTIVATE' })
	}),

	/**
	 * @param {object} data - Content Package Instance.
	 * @returns {object}    - Promise.
	 * @example
	 * 		repository.deactivateContentPackageInstance(1984);
	 */
	deactivateContentPackageInstance: (data) => PUT({
		url: urls.cpInstance(data.id),
		data: assign({}, data, { state: 'NON_ACTIVATE' })
	}),

	/**
	 * @param {number} id - Content Package Instance.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.completeContentPackageInstance(1984);
	 */
	completeContentPackageInstance: (id) => PUT({ url: urls.complete.url(id) }),

	/**
	 * @param {number} id - Content Package Instance.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.deleteContentPackageInstance(1984);
	 */
	deleteContentPackageInstance: (id) => DELETE({ url: urls.cpInstance(id) })
};
