import formViewDialog from 'service/form-view-dialog';
import Context from 'repo/assessments/context';
import t from 'translate';

export default function () {
	var formConfig = {
		name: 'add-context',
		model: new Context(),

		fields: [{
			key: 'assessmentInstanceContextLabel',
			type: 'text',
			label: t('Label'),
			mandatory: true
		}, {
			key: 'assessmentInstanceContextOrder',
			type: 'number',
			label: t('Order'),
			mandatory: true
		}]
	};

	formViewDialog({
		formConfig: formConfig,
		dialogConfig: {
			title: t('New assessment context')
		}
	});
};

