import t from 'translate';
import store from 'store';
import List from '../flag-list/tile';

export default List.extend({
	title: t('Respondent flags'),
	columns: ['isRaised', 'isSeen', 'date', 'title', 'assessment', 'treatment'],
	render () {
		this.collection = store.state.flags.collection;
		this.load();
	}
});
