import SearchTileView from 'core/engine/tile/views/search-tile-view';
import cardUrl from 'cwcardurl';
import store from 'store';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints';
import { READ } from 'service/acl/access-levels';

export default SearchTileView.extend({
	title: t('Role sets'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.TREATMENT_ROLES,
		op: READ
	}],

	searchFn (params) {
		const result = store.dispatch('roleSets/search', params);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},

	tableCfg () {
		const className = 'role-set-list';
		const config = {
			store: {
				items: () => store.getters['roleSets/list'],
				count: 'roleSets/count',
				mutation: 'roleSets/setList',
				total: 'roleSets/total'
			},
			click: (model) => {
				cardUrl.openCard('edit-role-set', {
					roleSetId: model.id
				});
			},
			columns: [{
				label: t('Set name'),
				key: 'name',
				css: `${className}__name`
			}, {
				label: t('Description'),
				key: 'description',
				css: `${className}__description`
			}]
		};
		return config;
	}
});
