import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import store from 'store';
import { filter, size } from 'lodash';

export default CounterMiniTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.respondents',
		op: 'READ'
	}, {
		checkpoint: 'administrator.respondents.assessmentplans',
		op: 'READ'
	}],
	caption: t('Assessments'),
	listen: () => store.state.assessmentInstances.collection,
	actions: ['assessmentInstances/init'],
	count: () => filter(store.state.assessmentInstances.collection.toJSON(), (assessmentInstance) =>
		!assessmentInstance.assessmentInstanceIsSubmitted && !assessmentInstance.isSubmitted
	).length,
	total: () => size(store.state.assessmentInstances.collection.toJSON()),

	targetCard: () => ['respondent-assessments', {
		respondentId: store.getters['respondent/respondentId']
	}]
});
