import t from 'translate';
import { Model } from 'backbone';
import store from 'store';
import formViewDialog, { closeDialog } from 'service/form-view-dialog';
import cwalert from 'cwalert';
import { NONE, TREATMENT_TYPE_ID } from 'store/engine/variant-criteria';

export default () => {
	const currentVariant = store.getters['cards/selectedVariant'].name || '';
	const dialogConfig = { title: t('Clone card variant') };
	const formConfig = {
		name: 'name',
		preventSave: true,
		loader: true,
		model: new Model({ name: `${currentVariant}`, criterion: 'none' }),
		onAfterSave (model) {
			this.setLoading();
			const { name, criterion } = model.toJSON();

			store.dispatch('cards/cloneVariant', { criterion, name }).then(() => {
				closeDialog();
				cwalert.success(t('Card variant has been cloned'));
			});

		},
		fields: [{
			key: 'criterion',
			type: 'select',
			label: t('Variant selection criteria'),
			mandatory: true,
			values: [{
				id: NONE,
				name: t('None')
			}, {
				id: TREATMENT_TYPE_ID,
				name: t('Treatment type')
			}]
		}, {
			key: 'name',
			type: 'text',
			label: t('Variant name'),
			mandatory: true,
			max: 64
		}]
	};

	formViewDialog({ formConfig, dialogConfig });
};
