<template>
	<div class="clinician-assigned-groups time-restricted-access">
		<form class="form-view" :novalidate="omitValidation" @submit.prevent="submit">
			<div
				class="form-view__field-container form-view__field-container--text"
				:class="isInvalid"
			>
				<select-input
					v-model="formData.group"
					:options="groups"
					:disabled="!canAssign"
					item-label="name"
					:label="$t('- select clinician group to add -')"
				/>
			</div>
			<div class="form__date-range">
				<datetime-input
					v-model="formData.validFrom"
					:label="$t('Start date')"
					class="datetime-input"
					class-prefix="clinician-group"
				/>
				<span class="date-separator">―</span>
				<datetime-input
					v-model="formData.validTo"
					:label="$t('End date')"
					class="datetime-input"
					class-prefix="clinician-group"
				/>
			</div>
			<submit-button :label="$t('Save')" />
		</form>
		<div class="table__container">
			<table v-if="assignedGroups.length" class="table">
				<thead>
					<tr>
						<th>{{$t('Group name')}}</th>
						<th>{{$t('Group category')}}</th>
						<th>{{$t('Valid')}}</th>
						<th>{{$t('Valid from')}}</th>
						<th>{{$t('Valid until')}}</th>
						<th v-if="canRemove" />
					</tr>
				</thead>
				<tbody>
					<tr v-for="(group, index) in assignedGroups" :key="group.id">
						<td>
							<p class="table-cell-label">
								{{$t('Group name')}}
							</p>
							{{handleEmpty(group.name)}}
						</td>
						<td>
							<p class="table-cell-label">
								{{$t('Group category')}}
							</p>
							{{handleEmpty(group.category)}}
						</td>
						<td>
							<p class="table-cell-label">
								{{$t('Valid')}}
							</p>
							<icon
								name="check-circle"
								:class="groupValid(group).icon"
								:title="groupValid(group).title"
							/>
						</td>
						<td>
							<p class="table-cell-label">
								{{$t('Valid from')}}
							</p>
							{{handleDate(group.validFrom)}}
						</td>
						<td>
							<p class="table-cell-label">
								{{$t('Valid until')}}
							</p>
							{{handleDate(group.validTo)}}
						</td>
						<td v-if="canRemove" class="table__row-actions">
							<div class="delete-button__container">
								<button
									tabindex="0"
									class="delete-button__action icon icon--delete icon-2x"
									@click="removeGroup(group, index)"
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<p v-else>
				{{$t('The clinician has no groups assigned')}}
			</p>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import cwalert from 'cwalert';
import acl from 'service/acl/acl';
import moment from 'moment';
import Modernizr from 'modernizr';
import confirmation from 'components/confirmation/confirmation';
import { required } from 'vuelidate/lib/validators';
import { find, forOwn, isNull, reject } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import Icon from 'components/icon';
import { handleEmpty, handleDate } from '../../helpers';
import { fromUtc } from 'service/datetime/datetime';

export default {
	title: t('Assigned clinician groups'),
	acl: [{
		checkpoint: SYSTEM.CLINICIANS.GROUPS,
		op: READ
	}],
	actions: ({ $store }) => [
		['clinician/init', $store.getters.urlParams.id],
		['clinicianGroups/initAll'],
		['clinician/initAssignedGroups', { clinicianId: $store.getters.urlParams.id }]
	],

	components: { Icon },
	data: () => ({
		formData: {
			group: null,
			validFrom: null,
			validTo: null
		}
	}),

	validations: {
		formData: {
			group: {
				required
			}
		}
	},

	computed: {
		clinician: ({ $store }) => $store.getters['clinician/clinician'],
		assignedGroups: ({ $store }) => $store.getters['clinician/assignedGroups'],
		timezone: ({ clinician }) => clinician.timezoneName,

		groups: ({ $store, assignedGroups }) => reject(
			$store.getters['clinicianGroups/all'],
			(group) => find(assignedGroups, { id: group.id })
		),

		datetimePolyfill () {
			return !Modernizr.inputtypes['datetime-local'];
		},

		isMe ({ $store }) {
			return $store.getters.isMe({ id: $store.getters.urlParams.clinicianId });
		},

		canAssign () {
			return acl.checkAccess({
				checkpoint: SYSTEM.CLINICIANS.GROUPS,
				op: this.isMe ? 'SUPERVISOR' : 'ADD'
			});
		},

		canRemove () {
			return acl.checkAccess({
				checkpoint: SYSTEM.CLINICIANS.GROUPS,
				op: this.isMe ? 'SUPERVISOR' : 'DELETE'
			});
		},

		isInvalid () {
			return this.$v.formData.group.$error && 'invalid';
		},

		omitValidation () {
			return Modernizr.isios;
		}
	},

	methods: {
		handleEmpty,
		handleDate (date) {
			return handleDate({ date, timezone: this.timezone });
		},

		submit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.setLoading();
				this.$store.dispatch('clinician/assignGroup', {
					data: this.formData,
					timezone: this.timezone
				}).then(() => {
					cwalert.success(t('Clinician group has been added'));
					this.setLoaded();
					this.$v.$reset();

					forOwn(this.formData, (val, key) => {
						this.formData[key] = null;
					});
				}, () => {
					cwalert.error(t('Error'));
					this.setLoaded();
				});
			}
		},

		removeGroup (group, index) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove clinician group'),
				message: t('Are you sure you wish to remove clinician group?')
			}).then(() => {
				this.setLoading();
				this.$store.dispatch('clinician/removeAssignedGroup', { group, index })
					.then(() => {
						cwalert.success(t('Clinician group removed successfully'));
						this.setLoaded();
					}, () => {
						cwalert.error(t('Error'));
						this.setLoaded();
					});
			});
		},

		groupValid ({ validFrom, validTo }) {
			const isValid = {
				true: {
					icon: 'icon--check-circle',
					title: this.$t('Valid')
				},
				false: {
					icon: 'icon--error table-cell__icon--invalid',
					title: this.$t('Invalid')
				}
			};

			const now = moment().tz(this.timezone);
			const rangeStart = validFrom ?
				fromUtc({ date: validFrom, timezone: this.timezone }) :
				null;
			const rangeEnd = validTo ?
				fromUtc({ date: validTo, timezone: this.timezone }) :
				null;

			return isValid[this.inRange(now, rangeStart, rangeEnd)];
		},

		inRange: (datetime, rangeStart, rangeEnd) => {
			if (isNull(rangeEnd) && isNull(rangeStart)) {
				return true;
			} else if (isNull(rangeStart)) {
				return datetime < moment(rangeEnd);
			} else if (isNull(rangeEnd)) {
				return datetime > moment(rangeStart);
			}

			return datetime.isBetween(rangeStart, rangeEnd);
		}
	}

};
</script>
