import MultipleEntities from '../shared/multiple-entities';
import CheckpointGroup from './checkpoint-group-entity';
import urls from './urls';

/**
 * Timezones Entity
 * @class CheckpointGroupss
 * @extends MultipleEntities
 */

const CheckpointGroupssEntity = MultipleEntities.extend({
	model: CheckpointGroup,
	urls,
	idName: 'administratorRoleAccessCategoryId',
	eventNamespace: 'checkpoint-group',

	/**
	 * Get all CheckpointGroups
	 * @method getAll
	 * @return {Object} new CheckpointGroups Collection
	 * @example
	 *        Repository.CheckpointGroups.getAll();
	 */
	getAll: function () {
		return this.retrieve('allCheckpointGroups');
	}
});

export default new CheckpointGroupssEntity();
