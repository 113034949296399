import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Plan from './entity';
import $ from 'jquery';
import { assign } from 'lodash';

/**
 * Plans Entity
 * @class Plans
 * @extends MultipleEntities
 */
const PlansEntity = MultipleEntities.extend({
	urls,
	model: Plan,
	idName: 'workflowId',
	eventNamespace: 'plan',

	getAll () {
		return this.retrieve('allPlans');
	},

	getForCurrentClinician (params) {
		return this.retrieve('currentClinicianPlans', params);
	},

	_getFiltered (filterFn) {
		const that = this.clone();
		const dfd = $.Deferred();
		that.reset();
		assign(that, dfd);

		this.getForCurrentClinician().then((plans) => {
			that.add(plans.filter(filterFn));
			that.resolve();
		});
		return that;
	},

	getByRespondentId (respondentId) {
		return this.retrieve('respondentAvailablePlans', respondentId);
	},

	search (params) {
		params.workflowName = params.search; // b💩ckend doesn't handle standard `search` param…
		return this.retrieve('search', params);
	}
});

export default new PlansEntity();

