import t from 'translate';
import mixin from './_mixin';
import { assessmentValidation } from './_validation-mixin';
import { mapGetters } from 'vuex';
import { transform, assign } from 'lodash';

export default () => ({
	name: 'assessment-attribute',
	mixins: [mixin, assessmentValidation],

	data: () => ({
		defaultType: t('Assessment attribute'),
		identifierField: 'name',
		ids: {
			identity: 'name'
		}
	}),

	computed: {
		...mapGetters({
			assessments: 'exportTemplate/sortedAssessments',
			contexts: 'contexts/sortedWithEmpty',
			attributes: 'assessment/attributes/translatedList'
		}),

		attributeDisabled () {
			return this.mirror || (!this.attributes.length && this.loading);
		},

		columnLabel () {
			return this.formData.assessment ? this.formData.assessment.assessmentCode : '';
		},

		identifierLabel () {
			return this.formData.identity ? this.formData.identity.name : '';
		}
	},

	methods: {
		initUniqueFields () {
			// retransform values to entities and set them to proper data models
			assign(this.formData, transform(this.item.fields, (memo, value, key) =>
				assign(memo, {
					identity: this.valueToEntity({
						entities: this.attributes,
						key,
						value
					}),
					assessmentQuestionCode: {
						assessmentQuestionCode: value
					}
				}[key])));
		}
	}
});
