import { toLower } from 'lodash';
import repository from 'repository';

export default async (roleName) => {
	const results = await repository.roleByName(roleName);

	if (results.length && toLower(results[0].name) === toLower(roleName)) {
		return false;
	}

	return true;
};
