import TileView from 'tile-view';
import can from 'acl-can';
import repository from 'repository';
import datamodel from 'components/old-components/datamodel/datamodel';
import t from 'translate';

export default TileView.extend({
	acl: [{
		checkpoint: 'system.settings.languages',
		op: 'OPEN'
	}],
	className: '',
	title: t('system-manager.settings.Languages'),

	init () {
		this.languages = repository.getLanguages();
	},

	onRender () {
		this.languages.done(this.loaded.bind(this)).always(this.setLoaded.bind(this));
	},

	loaded () {
		this.table = new datamodel.grid.Table({
			css: 'wide-table',
			collection: this.languages,
			noFetch: true,
			readonly: !can.edit('system.settings.languages'),
			columns: {
				languageCode: {
					label: t('system-manager.settings.language.Code'),
					rendererFactory: new datamodel.widgets.LabelFactory()
				},
				languageLabel: {
					label: t('system-manager.settings.language.Label'),
					rendererFactory: new datamodel.widgets.LabelFactory()
				},
				languageEnabled: {
					className: 'language-list__enabled',
					label: t('system-manager.settings.language.Enabled'),
					rendererFactory: new datamodel.widgets.CheckboxFactory({
						rendererOptions: {
							checkedValue: '1',
							uncheckedValue: '0',
							label: 'languageLabel'
						}
					})
				}
			},
			container: this.el
		});
	}
});
