import can from 'acl-can';
import t from 'translate';
import appBar from 'app-bar';
import repository from 'repository';
import { when } from 'jquery';

const card = {
	title: t('Clinician Search'),
	name: 'search-clinicians',
	url: 'clinician/search/?q=(:search)',

	ctrl: ({ cardConfig, cardData }) => {
		can.add('system.administrators.administrators') && appBar.addCardLink(card.name, {
			card: 'clinician-add',
			icon: 'add',
			title: t('Add a clinician')
		});
		const groups = can.read('system.administrators.groups') ?
			repository.getClinicianGroups() :
			{};

		const roles = repository.getActiveClinicianRoles();

		cardData.set({
			hideResults: cardConfig.startEmpty,
			groups,
			roles
		});

		return when(groups, roles);
	}
};

export { card as SearchClinicians };
