import appBar from 'app-bar';
import t from 'translate';
import store from 'store';
import eventHandlers from '../shared/event-handlers-mixin';

const card = {
	name: 'respondent-status',
	url: 'respondent/:respondentId/status',
	title: t('respondent.status'),

	appEvents: {
		'respondent.delete respondent.lose-access' (respondent) {
			eventHandlers.closeCard({
				respondent,
				data: this.cardData,
				close: this.close,
				name: card.name
			});
		}
	},

	ctrl: ({ populate }, { respondentId }) => {
		store.dispatch('treatment/resetId');
		appBar.addCardLink({
			sourceCard: card.name,
			card: 'respondent-edit',
			icon: 'edit',
			title: t('Respondent management'),
			query: { respondentId }
		});
		populate();
	}
};

export { card as RespondentStatus };

