import MultipleEntities from '../shared/multiple-entities';
import Checkpoint from './checkpoint-entity';
import urls from './urls';

/**
 * Timezones Entity
 * @class Checkpoints
 * @extends MultipleEntities
 */

const CheckpointsEntity = MultipleEntities.extend({
	model: Checkpoint,
	urls,
	idName: 'administratorRoleAccessId',
	eventNamespace: 'checkpoint',

	/**
	 * Get all Checkpoints
	 * @method getAll
	 * @return {Object} new Checkpoints Collection
	 * @example
	 *        Repository.Checkpoints.getAll();
	 */
	getAll: function () {
		return this.retrieve('allCheckpoints');
	}
});

export default new CheckpointsEntity();
