import enterprises from '../dialogs/enterprises';
import addConfig from '../helpers/add-config';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('dataIntegration.enterprise.name'),
	name: 'data-integration-enterprises',
	url: 'enterprises',

	ctrl: ({ populate }) =>  {
		addConfig({
			acl: SYSTEM.SETTINGS.ENTERPRISE,
			action: 'enterprises/add',
			card,
			dataIntegrationFn: enterprises,
			item: {
				enabled: false,
				enterpriseId: '',
				secret: '',
				code: '',
				name: ''
			},
			populate,
			type: 'enterprise'
		});
		populate();
	}
};

export { card as enterprises };
