import t from 'translate';
import can from 'acl-can';
import appBar from 'app-bar';
import cardUrl from 'cwcardurl';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	name: 'treatment-programs',
	title: t('treatmentProgram.treatmentPrograms'),
	url: 'treatment-programs',
	ctrl: ({ populate }) => {
		can.add(SYSTEM.SETTINGS.TREATMENT_PROGRAMS) && appBar.addButton({
			sourceCard: card.name,
			title: t('treatmentProgram.add'),
			icon: 'add',
			click: () => cardUrl.openCard('add-treatment-program')
		});

		populate();
	}
};

export { card as treatmentPrograms };
