import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * SystemEventLog
 * @class SystemEventLog
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.systemEventLog.url,
	eventNamespace: 'system-event-log'
});

