import List from './list-treatment-type/tile';
import Add from './add-treatment-type';
import Edit from './edit-treatment-type';
import Attributes from './treatment-type-attributes/tile';
import ClinicianGroups from './treatment-type-clinician-groups';
import RoleSets from './treatment-type-role-sets/tile';

export default {
	'list-treatment-type': List,
	'add-treatment-type': Add,
	'edit-treatment-type': Edit,
	'treatment-type-attributes': Attributes,
	'treatment-type-clinician-groups': ClinicianGroups,
	'treatment-type-role-sets': RoleSets
};
