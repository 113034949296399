import $ from 'jquery';
import appBar from 'app-bar';
import appUrl from 'service/app-url/app-url';
import reportFixes from 'service/report/fixes';
import repository from 'repository';
import store from 'store';
import TileView from 'tile-view';
import { assign, get, isUndefined, pick, replace } from 'lodash';
import { warn } from 'service/log/log';
import t from 'service/lang/translate';

export default TileView.extend({
	className: 'report__container',
	acl: [{
		checkpoint: 'administrator.respondents.assessmentreports',
		op: 'READ'
	}],

	cardData: () => ['assessmentInstanceId', 'reportId', 'cardId'],

	fetchData: ({ tile, params }) => {
		tile.reportContent = repository.getReportContent(params);
		tile.reportContent.then(() => {
			tile.setLoaded();

			if (isUndefined(tile.reportContent.getHTML())) {
				tile.disable();
			} else {
				return tile.renderReport(tile);
			}
		});
	},

	loaded: ({ tile }) => {
		tile.setLoading();
		let params = {};

		if (tile.assessmentInstanceId && tile.reportId) {
			params = pick(tile, ['assessmentInstanceId', 'reportId']);

		} else {
			assign(params, {
				assessmentCode: tile.config().assessmentCode,
				reportCode: tile.config().reportCode
			});

			if (store.getters['treatment/treatmentId']) {
				params.treatmentId = store.getters['treatment/treatmentId'];

			} else if (store.getters['respondent/respondentId']) {
				params.respondentId = store.getters['respondent/respondentId'];

			} else {
				tile.disable();
			}
		}

		tile.fetchData({ tile, params });
	},

	renderReport: (tile) => {
		const paths = tile.reportContent.get('paths');
		const html = replace(`${tile.reportContent.getHTML()}`, /{(\w+)?}/g, (match, key) => {
			if (isUndefined(paths[key])) {
				warn('Report html parsing: unknown variable:', key);

			} else {
				return appUrl(paths[key]);
			}
		});

		const $reportBody = $('<div class="report__body" />')
			.html(reportFixes(html))
			.appendTo(tile.$el);
		// eslint-disable-next-line lodash/prefer-lodash-method
		$reportBody.find('td.sidebar').attr('valign', 'top');
		appBar.addButton(get(tile, 'cardId'), {
			url: appUrl(tile.reportContent.get('pdfUrl')),
			icon: 'file-pdf',
			title: t('Download pdf')
		});
	}
});

