import TileView from 'tile-view';
import t from 'translate';
import store from 'store';
import { get } from 'lodash';
import { SimpleList, itemTemplate } from 'components/simple-list/simple-list';

export default TileView.extend({
	title: t('Available exercises'),

	acl: [{
		checkpoint: 'administrator.respondents.informationpackages',
		op: 'read'
	}],

	actions: () => ['contentPackageInstances/exercises/init'],

	loaded: ({ tile }) => {
		const treatmentExercises = store.getters['contentPackageInstances/exercises/active'];
		const respondentId = store.getters['respondent/respondentId'];
		const roleItem = (item) => itemTemplate({
			config: tile.config().items,
			name: 'role',
			value: get(item.instance.treatmentRole, 'roleName', null),
			className: 'item-description__role item-description--small'
		});

		const packageItem = (item) => itemTemplate({
			config: tile.config().items,
			name: 'package',
			value: item.instance.package.name,
			className: `
				item-description__package 
				item-description--highlight 
				item-description--small
			`
		});

		const renderDescription = (item) => `${roleItem(item)} ${packageItem(item)}`;

		tile.list = new SimpleList({
			el: tile.el,
			items: treatmentExercises,
			urlParams: (item) => (['content-package-instance-element', {
				respondent: respondentId,
				instance: item.instance.id,
				id: item.id
			}]),

			className: {
				positive: (item) => item.state === 'COMPLETED',
				negative: (item) => !item.elementVersion.optional && item.state !== 'COMPLETED',
				neutral: (item) => item.elementVersion.optional && item.state !== 'COMPLETED'
			},

			classNameDesc: {
				positive: () => t('Exercise is completed'),
				negative: () => t('Exercise is not completed - required'),
				neutral: () => t('Exercise is not completed')
			},

			content: (item) => `
				<i class="fa simple-list__item-icon icon--exercises "></i>
				<span class="simple-list__item-counter">${item.exercises.length}</span>
				<div class="simple-list__item-description simple-list__item-description--counter">
					<h3 class="item-description__title">${item.elementVersion.alias}</h3>
					${renderDescription(item)}
				</div>`
		});

		tile.$el.html(tile.list.$el);
	}
});
