import appBar from 'app-bar';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	title: t('Relation type`s details'),
	name: 'relation-type-edit',
	url: 'relation-type/:relationTypeId',

	ctrl: ({ populate }, { relationTypeId }) =>  {

		if (relationTypeId !== '0') {
			can.add(SYSTEM.SETTINGS.RELATION_TYPES) && appBar.addButton({
				sourceCard: card.name,
				icon: 'delete',
				title: t('Delete relation'),
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Remove relation type'),
						message: t('Are you sure you want to delete this relation type?')
					}).done(() => {
						store.dispatch('relationTypes/delete', relationTypeId).then(() => {
							cwalert.success(t('Relation type has been deleted'));
							cardUrl.openCard('system/relation-types');
						});
					});
				}
			});
		}

		populate();
	}
};

export { card as relationTypeEdit };
