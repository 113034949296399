<script>
import selectList from '../components/select-list';
import { get, flatten, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

const stages = ['identity', 'selection'];

export default {
	name: 'AssessmentWizard',
	components: { selectList },
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			assessment: null,
			stage: stages[0],
			loading: false,
			selected: {
				attributes: [],
				calculations: [],
				flags: [],
				questions: []
			}
		};
	},
	computed: {
		...mapGetters({
			assessments: 'exportTemplate/sortedAssessments',
			attributes: 'assessment/attributes/translatedList',
			calculations: 'assessment/calculations',
			flags: 'assessment/flags',
			questions: 'assessment/questions'
		}),

		isFirstStage () {
			return this.stage === stages[0];
		}
	},

	watch: {
		stage (val) {
			this.$emit('stage-change', val);
		}
	},

	created () {
		this.$emit('stage-change', stages[0]);
	},

	methods: {
		...mapActions({
			getFlags: 'assessment/getFlags',
			getCalculations: 'assessment/getCalculations',
			getQuestions: 'assessment/getQuestions',
			getAttributes: 'assessment/attributes/init'
		}),

		onSelect ({ type, selected }) {
			this.selected[type] = selected;
		},

		goNext () {
			this.loading = true;
			const assessmentId = get(this.assessment, 'assessmentId');

			Promise.all([
				this.getFlags({ assessmentId }),
				this.getCalculations({ assessmentId }),
				this.getQuestions({ assessmentId }),
				this.getAttributes()
			]).then(() => {
				this.loading = false;
				this.stage = stages[1];
			});
		},

		goBack () {
			this.assessment = null;
			this.stage = stages[0];
		},

		submit () {
			const { assessmentId: assessment, assessmentCode: columnLabel } = this.assessment;
			const defaultProps = ({ code }) => ({
				identity: code,
				identifierLabel: code,
				columnLabel,
				assessment
			});
			const columns = map(this.selected, (selected, type) =>
				map(selected, (item) => ({
					attributes: {
						identity: get(item, 'id'),
						type: 'assessmentAttribute',
						identifierLabel: get(item, 'name'),
						columnLabel,
						assessment
					},
					calculations: {
						type: 'assessmentCalculation',
						...defaultProps(item)
					},
					flags: {
						type: 'assessmentFlag',
						...defaultProps(item)
					},
					questions: {
						type: 'assessmentQuestion',
						...defaultProps(item)
					}
				}[type])));

			this.$emit('submit', flatten(columns));
		}
	}
};
</script>

<template>
	<form v-if="isFirstStage" @submit.prevent="goNext">
		<h3
			v-translate
			class="modal-dialog__selection-title"
		>Assessment information</h3>
		<select-input
			v-model="assessment"
			:options="assessments"
			label="Assessment"
			item-label="assessmentName"
			:mandatory="true"
		/>
		<button-group>
			<div
				v-if="loading"
				v-loader-spinner
				class="buttons-container__loader"
			/>
			<submit-button label="Next" :can-submit="assessment && !loading" />
		</button-group>
	</form>
	<form v-else @submit.prevent="submit">
		<div>
			<select-list
				v-if="attributes.length"
				loop-key="id"
				type="attributes"
				item-label="label"
				title="Select attributes"
				:collection="attributes"
				@select="onSelect"
			/>
			<select-list
				v-if="calculations.length"
				loop-key="assessmentCalculationId"
				type="calculations"
				title="Select calculations"
				:collection="calculations"
				@select="onSelect"
			/>
			<select-list
				v-if="flags.length"
				loop-key="assessmentFlagId"
				type="flags"
				title="Select flags"
				:collection="flags"
				@select="onSelect"
			/>
			<select-list
				v-if="questions.length"
				loop-key="assessmentQuestionId"
				type="questions"
				title="Select questions"
				:collection="questions"
				@select="onSelect"
			/>
		</div>
		<button-group>
			<plain-button :on-click="goBack" label="Back" />
			<submit-button label="Add" />
		</button-group>
	</form>
</template>
