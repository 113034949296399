import urlify from 'service/urlify/urlify';
import { get, includes, isNull, reduce, replace } from 'lodash';

const prefix = 'backend-commons/data-integration';

const codeUrl = `${prefix}/code`;
const codeTypeUrl = `${prefix}/code-type`;
const codeVariationUrl = `${prefix}/code`;
const enterpriseUrl = 'backend-commons/enterprise';
const maintenanceModeUrl = `backend-commons/system/maintenance-mode/partner-api`;
const ruleSetUrl = `${prefix}/rule-set`;

const paramsMapping = [{
	from: 'filter[enterprise.id]=null',
	to: 'ex[enterprise.id]'
}, {
	from: 'filter[treatmentType.id]=null',
	to: 'ex[treatmentType.id]'
}];

const searchParams = {
	code: ['code', 'name', 'codeType.code', 'codeType.name', 'enterprise.name'],
	codeType: ['code', 'name'],
	enterprise: ['enterpriseId', 'code', 'name', 'secret'],
	ruleSet: ['treatmentType.name', 'enterprise.name', 'defaultCodes.name', 'defaultMappedTo']
};

const updateMapping = (mapping, params) => {
	if (searchParams[params.name] && !!params.search) {
		const filterList = reduce(searchParams[params.name], (result, child) => {
			result.push(`search[${child}]=${params.search}`);
			return result;
		}, []);
		mapping.source = `search=${params.search}`;
		mapping.replaceWith = filterList.join('&');
	}
};

const search = (name, params) => {
	const mapping = {};
	const omit = [];

	if (get(params, 'name')) {
		/*
		#justb💩ckendthings -- `defaultCodes` has to be a standard GET param and it can't be `null`
		 */
		const adjustParams = (field) => {
			omit.push(field);

			if (isNull(params[field])) {
				params[field] = '';
			}
		};

		if (params.name === 'ruleSet') {
			adjustParams('defaultCodes');
		}

		updateMapping(mapping, params);
		delete (params.name);
	}
	const suffix = reduce(paramsMapping, (result, child) => {
		if (includes(result, child.from)) {
			return replace(result, child.from, child.to);
		}
		return result;
	}, mapping.source ?
		replace(urlify(params, { omit }), mapping.source, mapping.replaceWith) :
		urlify(params, { omit }));

	return `${name}/?${suffix}`;
};

const singleId = (name, id) => `${name}/${id}`;

export default {
	code: (id) => singleId(codeUrl, id),
	codes: `${codeUrl}/`,
	codesByEmptyEnterprise: `${codeUrl}/?ex[enterprise]`,
	codesByEnterprise: (id) => `${codeUrl}/?filter[enterprise.id]=${id}&filter[enabled]=true`,
	codeType: (id) => singleId(codeTypeUrl, id),
	codeTypes: `${codeTypeUrl}/`,
	codeVariation: (params) => `${codeVariationUrl}/${params.codeId}/variant/${params.variantId}`,
	codeVariations: `${codeVariationUrl}/`,
	dataMappings: `${prefix}/data-mapping/`,
	dataMapping: ({ id, mappingId = '' }) => `${ruleSetUrl}/${id}/data-mapping/${mappingId}`,
	enterprise: (id) => singleId(enterpriseUrl, id),
	enterprises: `${enterpriseUrl}/`,
	maintenanceMode: `${maintenanceModeUrl}`,
	roleMapping: ({ cardId, id = '' }) => `${ruleSetUrl}/${cardId}/role-mapping/${id}`,
	ruleSet: (id) => singleId(ruleSetUrl, id),
	ruleSets: `${ruleSetUrl}/`,
	ruleSetsByTreatmentType: (id) => `${ruleSetUrl}/?filter[treatmentType.id]=${id}`,
	ruleSetsDefault: `${ruleSetUrl}/?ex[treatmentType.id]&ex[enterprise.id]`,
	ruleSetsWithEnterprise: `${ruleSetUrl}/?filter[enterprise.id]`,
	ruleSetsWithTreatmentType: `${ruleSetUrl}/?filter[treatmentType.id]`,
	searchCodes: (params) => search(codeUrl, params),
	searchCodeTypes: (params) => search(codeTypeUrl, params),
	searchEnterprises: ({ start = 0, limit = 10 }) =>
		`${enterpriseUrl}/?start=${start}&limit=${limit}`,
	searchEnterprisesForTreatmentType: ({ start = 0, limit = 10 }) =>
		`${enterpriseUrl}/available-for-treatment-type/?start=${start}&limit=${limit}`,
	searchRuleSets: (params) => search(ruleSetUrl, params),
	treatmentTypes: 'backend-commons/treatment-type/?include=enterprise',
	updateMaintenanceMode: (action) => `${maintenanceModeUrl}/${action}`
};
