import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import TreatmentField from './treatment-field';

const TreatmentFields = MultipleEntities.extend({
	urls,
	eventNamespace: 'export-template-treatment-field',
	model: TreatmentField,

	getAll () {
		return this.retrieve('treatmentFields');
	}
});

export default new TreatmentFields();
