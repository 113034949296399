<template functional>
	<li class="treatment-label__element-container">
		<div class="treatment-label__label-container">
			<label v-translate class="treatment-label__value">{{props.label}}</label>
		</div>
		<div class="treatment-label__value-container">
			<p class="treatment-label__value">
				<slot />
			</p>
		</div>
	</li>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		}
	}
};
</script>
