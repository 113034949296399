import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * Report Entity
 * @class Report
 * @extends SingleEntity
 */
export default SingleEntity.extend({
    urlRoot: urls.report.url,
    idAttribute: 'assessmentReportId',
    eventNamespace: 'report'
});
