import { includes } from 'lodash';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import repository from 'repository';
import IPElement from 'repo/content-packages/element';

export default (informationPackage, ipElement, limit = 10) => {
	const cpElement = ipElement ?
		ipElement.clone() :
		new IPElement({
			informationPackage: informationPackage.getId()
		});

	const formConfig = () => ({
		name: 'new-information-package',
		model: cpElement,
		loader: true,
		keysNotSubmitted: ['permalink'],
		beforeSave: () => {
			// make a cleanup, thank you b💩ckend!
			const copy = cpElement.clone();
			cpElement.set({
				assessment: null,
				page: null,
				uploadedMedia: null,
				link: null
			}, { silent: true });

			if (includes(['ASSESSMENT', 'EXERCISE'], cpElement.get('type'))) {
				cpElement.set('assessment', copy.get('assessment'), { silent: true });

			} else if (cpElement.get('type') === 'PAGE') {
				cpElement.set('page', copy.get('page'), { silent: true });

			} else if (cpElement.get('type') === 'MEDIA') {
				cpElement.set('uploadedMedia', copy.get('uploadedMedia'), { silent: true });

			} else if (cpElement.get('type') === 'LINK') {
				cpElement.set('link', copy.get('link'), { silent: true });
			}
		},

		fields: [{
			key: 'type',
			type: 'radio',
			values: repository.getContentPackageElementTypes(),
			label: t('Type'),
			mandatory: true,
			customize: (view) => {
				view.model.on('change:value', () => {
					cpElement.set({
						alias: null,
						assessment: null,
						page: null,
						uploadedMedia: null,
						permalink: ''
					});
				});
			}
		}, {
			key: 'assessment',
			type: 'search',
			limit,
			label: t('Assessment'),
			mandatory: true,
			description: t('Select assessment'),
			show: {
				event: 'change',
				condition: () => includes(['ASSESSMENT', 'EXERCISE'], cpElement.get('type'))
			},
			provideResults: (search) => repository.searchAssessments({
				search, limit, filter: { exercise: cpElement.get('type') === 'EXERCISE' }
			}),
			itemLabel: (model) => model.get('assessmentName'),
			customize: (view) => {
				if (!includes(['ASSESSMENT', 'EXERCISE'], cpElement.get('type'))) {
					return;
				}
				view.setLoading();
				const assessment = repository.getAssessmentById(cpElement.get('assessment'));
				assessment.then(() => {
					view.showItem(assessment);
					view.setLoaded();
				});
			}
		}, {
			key: 'page',
			type: 'search',
			limit,
			label: t('Page'),
			mandatory: true,
			description: t('Select page'),
			show: {
				event: 'change',
				condition: () => cpElement.get('type') === 'PAGE'
			},
			provideResults: (search) => repository.searchPages({ search, limit, start: 0 }),
			itemLabel: (model) => model.get('name'),
			customize: (view) => {
				if (cpElement.get('type') !== 'PAGE') {
					return;
				}
				view.setLoading();
				const page = repository.getPageById(cpElement.get('page'));
				page.then(() => {
					view.showItem(page);
					view.setLoaded();
				});
			}
		}, {
			key: 'uploadedMedia',
			type: 'search',
			label: t('Media'),
			mandatory: true,
			limit,
			description: t('Select media'),
			show: {
				event: 'change',
				condition: () => cpElement.get('type') === 'MEDIA'
			},
			provideResults: (search) => repository.searchMedia({ search, limit, start: 0 }),
			itemLabel: (model) => model.get('title'),
			onSelectItem: (media) => {
				cpElement.set({
					alias: media.get('title'),
					permalink: media.get('alias')
				});
			},
			customize (view) {
				if (cpElement.get('type') !== 'MEDIA') {
					return;
				}
				view.setLoading();
				const mediaModel = repository.mediaModel(cpElement.get('uploadedMedia'));
				mediaModel.then(() => {
					cpElement.set({ permalink: mediaModel.get('alias') });
					view.showItem(mediaModel);
					view.setLoaded();
				});
			}
		}, {
			key: 'link',
			type: 'search',
			limit,
			label: t('Link'),
			mandatory: true,
			labelField: 'name',
			description: t('Select link'),
			show: {
				event: 'change',
				condition: () => cpElement.get('type') === 'LINK'
			},
			provideResults: (search) => repository.searchLinks({ search, limit, start: 0 }),
			itemLabel: (model) => model.get('name'),
			customize: (view) => {
				if (cpElement.get('type') !== 'LINK') {
					return;
				}
				view.setLoading();
				const link = repository.getLink(cpElement.get('link'));
				link.then(() => {
					view.showItem(link);
					view.setLoaded();
				});
			}
		}, {
			key: 'permalink',
			type: 'paragraph',
			label: t('Permanent link'),
			multiline: true,
			show: {
				event: 'change',
				condition: () => cpElement.get('type') === 'MEDIA'
			}
		}, {
			key: 'alias',
			type: 'text',
			label: t('Alias'),
			mandatory: true
		}, {
			key: 'order',
			type: 'number',
			label: t('Order')
		}, {
			key: 'optional',
			type: 'checkbox',
			label: t('Is optional')
		}]
	});

	formViewDialog({
		formConfig,
		dialogConfig: {
			title: cpElement.isNew() ?
				t('Add information package element') :
				t('Edit information package element')
		}
	});
};

