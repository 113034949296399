import Vue from 'vue';
import { sprintf } from 'sprintf';
import { get, find, isNull } from 'lodash';
import evaluate from './evaluate';
import between from 'lib/between';
import convertValues from './convert-values';
import { EMPTY_CHAR } from 'lib/chars';

/**
 * Helper function for rendering a counter
 * @param type {Object} type of counter (value or symbol)
 * @param data {string} data to be displayed
 * @param ruleSets {collection} styles for symbol
 * @param values
 */

export default ({ type, data, ruleSets, values }) => ({
	value: () => {
		const vue = new Vue({
			el: document.createElement('div'),
			data: { value: get(data, 'value') },
			filters: {
				format: (value) => isFinite(value) && !isNull(value) ?
					sprintf(ruleSets[0].format, value) :
					EMPTY_CHAR

			},
			template: `<p v-once class="datasource-counter__value">{{value | format}}</p>`
		});

		return vue.$el;
	},

	symbol: () => {
		const mappedValues = convertValues(values);
		const ruleSet = find(ruleSets, (rule) =>
			between({
				start: evaluate(rule.start, mappedValues),
				end: evaluate(rule.end, mappedValues),
				value: +get(data, 'value')
			}));

		const vue = new Vue({
			el: document.createElement('div'),
			data: {
				color: { color: `#${get(ruleSet, 'color')}` }
			},

			computed: {
				symbol: () => get(ruleSet, 'symbol')
			},

			template: `
				<i
					class="datasource-counter__symbol fa"
					:class="symbol"
					:style="color"
					v-once
				></i>
			`
		});

		return vue.$el;
	}
})[type];
