const DisplayValue = function (map) {

	this.getDisplayValue = function (attributeValue) {
		if (typeof map[attributeValue] === 'undefined') {
			return `&lt;&lt;&nbsp;${attributeValue}&nbsp&gt;&gt;`;

		}
		return map[attributeValue];

	};

	this.getMap = function () {
		return map;
	};
};

const BoolDisplayValue = function (map) {

	this.getDisplayValue = function (attributeValue) {
		if (attributeValue) {
			return map['true'];

		}
		return map['false'];

	};

	this.getMap = function () {
		return map;
	};
};

const IdColonDisplayValue = function () {

	this.getDisplayValue = function (attributeValue) {
		return (`${attributeValue}`).replace(/^\d+?:/, '');
	};

	this.getMap = function () {
		return {};
	};
};

export default {
	DisplayValue,
	BoolDisplayValue,
	IdColonDisplayValue
};
