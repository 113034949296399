import appBar from 'app-bar';
import t from 'translate';

const card = {
	name: 'system-settings-clinician-attributes-add',
	title: t('Add clinician attribute'),
	url: 'system/clinician/attributes/add',

	controller () {
		this.populate();

		appBar.addCardLink(card.name, {
			card: 'system-settings-clinician-attributes',
			title: t('System settings - clinician attributes'),
			icon: 'clinician-attribute'
		});
	}
};

export { card as addClinicianAttributes };

