<template>
	<div class="edit-treatment-program__program">
		<div
			class="edit-treatment-program__header"
			:class="{ 'is-active': isProgramActive }"
		>
			<h3 class="edit-treatment-program__program-title">
				<button @click="selectProgram">
					{{$t('treatmentProgram.program')}}
				</button>
			</h3>
		</div>
		<div>
			<module-editor
				v-for="(module, moduleIndex) in program.modules"
				:key="module.id"
				:index="moduleIndex"
				:module="module"
			>
				<section-editor
					v-for="(section, sectionIndex) in module.sections"
					:key="section.id"
					:index="sectionIndex"
					:module-id="module.id"
					:section="section"
				>
					<div
						v-for="element in section.contentPackage.elements"
						:key="element.id"
					>
						{{element.alias}}
					</div>
				</section-editor>

				<div v-if="!module.sections">
					{{$t('treatmentProgram.noSections')}}
				</div>

				<div class="edit-treatment-program__module-footer">
					<button
						class="edit-treatment-program__add-section"
						@click="addSection(module.id)"
					>
						<icon name="plus" />
						<span>{{$t('treatmentProgram.section.add')}}</span>
					</button>
				</div>
			</module-editor>

			<div v-if="!program.modules" class="edit-treatment-program__no-modules">
				{{$t('treatmentProgram.noModules')}}
			</div>

			<div class="edit-treatment-program__program-footer">
				<button
					class="edit-treatment-program__add-module"
					@click="addModule"
				>
					<icon name="plus" />
					<span>{{$t('treatmentProgram.module.add')}}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from 'components/icon';
import addModuleDialog from 'modules/treatment-programs/dialogs/add-module-dialog';
import addSectionDialog from 'modules/treatment-programs/dialogs/add-section-dialog';
import ModuleEditor from './module-editor';
import SectionEditor from './section-editor';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';
import {
	PROGRAM
} from '../../../constants';

export default {
	components: {
		ModuleEditor,
		SectionEditor,
		Icon
	},

	computed: {
		activeItem: ({ $store }) => $store.getters[TREATMENT_PROGRAM.ACTIVE_ITEM],
		program: ({ $store }) => $store.getters[TREATMENT_PROGRAM.DATA],
		isProgramActive: ({ activeItem }) => activeItem.type === PROGRAM
	},

	methods: {
		selectProgram () {
			this.$store.dispatch(
				TREATMENT_PROGRAM.SET_ACTIVE_ITEM,
				{ type: PROGRAM, id: null }
			);
		},

		addModule () {
			addModuleDialog();
		},

		addSection (moduleId) {
			addSectionDialog(moduleId);
		}
	}
};
</script>
