import t from 'translate';
import repository from 'repository';
import { when } from 'jquery';

const card = {
	title: t('Edit page'),
	name: 'edit-page',
	url: 'page/:pageId/edit',

	ctrl: ({ cardData }, { pageId }) => {
		const page = repository.getPageById(pageId);
		const categories = repository.getPageCategories();
		cardData.set({ page, categories });

		return when(page, categories);
	}
};

export { card as Edit };
