import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	urlRoot: urls.singleGroup.url,
	eventNamespace: 'clinician-group',
	idAttribute: 'administratorGroupId',
	defaults: {
		administratorGroupCategory: ''
	}
});

