import t from 'translate';
import repository from 'repository';
import appBar from 'app-bar';
import addLinkCategoryDialog from '../dialogs/new-link-category';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('Link categories'),
	name: 'system-settings-link-categories',
	url: 'system/link-categories',

	ctrl: ({ cardData }) => {
		const linkCategories = repository.getLinkCategories();
		cardData.set({ linkCategories });

		if (can.add(SYSTEM.SETTINGS.LINKS)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New link category'),
				click: addLinkCategoryDialog
			});
		}

		return linkCategories;
	}
};

export { card as linkCategories };
