<template>
	<consent-instances
		:items="consentInstances"
		:fields="fields"
		:on-click="open"
		:class-prefix="classPrefix"
	>
		<template #header="{ label }">
			{{label}}
		</template>

		<template #cell="{ fieldKey, item }">
			<p
				v-if="labelVisible({ item, key: fieldKey })"
				class="table-cell-label"
			>{{label(fieldKey)}}</p>
			<span>
				<icon
					v-if="fieldKey === 'consentName'"
					:name="iconName(item)"
					:title="description(item)"
				/>
				<button
					v-if="fieldKey === 'deleteAction' && allowDelete(item)"
					class="icon-button__button consent-instances__icon"
					@click.stop="remove(item)"
				>
					<icon name="delete" :small="true" />
				</button>
				<span v-else>{{item[fieldKey]}}</span>
			</span>
		</template>
	</consent-instances>
</template>

<script>
import t from 'translate';
import ConsentInstances from '../../components/consent-instances';
import { assign, find, get, kebabCase, map } from 'lodash';
import { NOT_SUBMITTED, labels } from '../../shared/statuses';
import relationName from '../../shared/relation-name';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'service/cwalert';
import formatDate from '../../shared/format-date';
import { description, title } from '../../shared/get-title';
import { READ } from 'service/acl/access-levels';

export default {
	title: t('consents.available'),
	acl: [{
		checkpoint: 'administrator.respondents.consents',
		op: READ
	}],
	actions: () => ['consentInstances/init'],
	components: { ConsentInstances },
	data: () => ({
		classPrefix: 'consent-instances',
		fields: [
			{ key: 'consentName', label: t('consents.name') },
			{ key: 'statusLabel', label: t('consents.status') },
			{ key: 'assignedTo', label: t('consents.assignedTo') },
			{ key: 'givenBy', label: t('consents.firstSubmittedBy') },
			{ key: 'submittedOn', label: t('consents.firstSubmittedOn') },
			{ key: 'lastModifiedOn', label: t('consents.lastModifiedOn') },
			{ key: 'modifiedBy', label: t('consents.lastModifiedBy') },
			{ key: 'deleteAction', label: t('Delete') }
		]
	}),

	computed: {
		timezone: ({ $store }) => $store.getters['respondent/timezone'],

		consentInstances: ({ $store, timezone }) => map(
			$store.getters['consentInstances/items'],
			(item) => assign({}, item, {
				statusLabel: labels()[item.status],
				consentName: item.consentVersion.name,
				assignedTo: relationName({ person: item.respondent }),
				submittedOn: formatDate(item.firstSubmittedAt, timezone),
				lastModifiedOn: formatDate(item.updatedAt, timezone),
				givenBy: relationName({ person: item.firstSubmittedBy, includeClinician: true }),
				modifiedBy: relationName({ person: item.updatedBy, includeClinician: true })
			})
		)
	},

	methods: {
		elementClass (element) {
			return `${this.classPrefix}__${element}`;
		},

		allowDelete: (item) => item.status === NOT_SUBMITTED,

		iconName: (item) => {
			const status = kebabCase(item.status);
			return `consent-${status}`;
		},

		open (item) {
			cardUrl.openCard('consent-instance', { consentInstanceId: item.id });
		},

		remove (item) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('consents.removeConfirmation.title'),
				message: t('consents.removeConfirmation.message')
			}).done(() => {
				this.$store.dispatch('consentInstances/remove', item.id).then(() => {
					cwalert.success(t('consents.removeConfirmation.success'));
				});
			});
		},
		label (key) {
			return get(find(this.fields, { key }), 'label', '');
		},
		labelVisible ({ item, key }) {
			return key === 'deleteAction' ? this.allowDelete(item) : true;
		},
		description,
		title
	}
};
</script>
