<script>
import { inRange } from 'lodash';

export default {
	name: 'SelectList',
	props: {
		title: {
			type: String,
			default: ''
		},
		loopKey: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		itemLabel: {
			type: String,
			default: 'name'
		},
		collection: {
			type: Array,
			required: true
		}
	},

	data () {
		return {
			selected: []
		};
	},

	computed: {
		allSelected () {
			return this.selected.length === this.collection.length;
		},

		checkboxClasses () {
			return ['modal-dialog__selection-checkbox', 'checkbox-input__input'];
		},

		itemLabelClasses () {
			return ['modal-dialog__selection-label', 'checkbox-input__label-text'];
		},

		someSelected () {
			return inRange(this.selected.length, 1, this.collection.length);
		}
	},

	watch: {
		selected (selected) {
			this.$emit('select', {
				type: this.type,
				selected
			});
		}
	},

	methods: {
		selectAll () {
			this.selected = [...this.collection];
		},

		deselectAll () {
			this.selected = [];
		},

		toggleAll () {
			if (this.allSelected) {
				this.deselectAll();
			} else {
				this.selectAll();
			}
		}
	}
};
</script>

<template>
	<section class="modal-dialog__selection">
		<h3
			v-translate
			class="modal-dialog__selection-title"
		>{{title}}</h3>
		<ul>
			<li class="checkbox-input__field-container">
				<label class="checkbox-input__label-container">
					<input
						:checked="allSelected"
						:indeterminate.prop="someSelected"
						:class="checkboxClasses"
						type="checkbox"
						@click="toggleAll"
					/>
					<span v-translate :class="itemLabelClasses">Select all</span>
				</label>
			</li>
			<li
				v-for="item in collection"
				:key="item[loopKey]"
				class="checkbox-input__field-container"
			>
				<label class="checkbox-input__label-container">
					<input
						v-model="selected"
						:class="checkboxClasses"
						:value="item"
						type="checkbox"
					/>
					<span :class="itemLabelClasses">
						{{item[itemLabel]}}
					</span>
				</label>
			</li>
		</ul>
	</section>
</template>
