<template>
	<div class="export-template-tile">
		<tree-view
			:items="data"
			:blueprints="blueprints"
			:download-url="downloadUrl"
			:file="file"
			:icons="icons"
			:on-add="onAdd"
			:save-template="saveTemplate"
			:tree-store-config="treeStoreConfig"
			:types="types"
			:update-helpers="updateHelpers"
			name="export-template"
			@passButtonRefs="setButtonRefs"
		/>
		<column-edit />
		<columns-wizard
			v-if="wizard.show"
			:type="wizard.type"
			:show="wizard.show"
			:on-close="onClose"
			@onSubmit="bulkAdd"
		/>
	</div>
</template>

<script>
import treeView from 'components/tree-view/tree-view';
import cardUrl from 'cwcardurl';
import columnEdit from '../../components/column-edit';
import blueprints from '../../blueprints/blueprints';
import store from 'store';
import columnsWizard from '../../dialogs/columns-wizard';
import { defer, forEach, merge } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { updateName } from '../../helpers/update-name';
import validationTypes from '../../helpers/validation-types';
import { updateFields, updateMirrorContext, updatePastedContext } from '../../helpers/update';
import { respondentWarning } from '../../helpers/warnings';

let templateId;
const COMPONENT_NAME = 'export-template';
const DEFAULT_TEMPLATE = '#ItemID#_#Assessment#_#Context#';

export default {
	title: '',
	acl: [],
	actions: (tile) => {
		templateId = tile.cardContext().get('exportTemplateId');

		return [
			['exportTemplateGui/init', { templateId, userId: store.getters.userId }],
			['contexts/init'],
			['treatmentTypes/initForCurrentClinician'],
			['assessment/attributes/init'],
			['treatment/attributes/init'],
			['respondent/attributes/init'],
			['respondent/attributes/initCustom']
		];
	},

	components: {
		treeView,
		columnEdit: columnEdit(COMPONENT_NAME),
		columnsWizard
	},

	data () {
		return {
			config: this.tile.config(),
			blueprints,
			buttonRef: null,
			defaultTemplate: DEFAULT_TEMPLATE,
			downloadUrl: this.tile.cardContext().get('exportTemplateUrl'),
			file: {
				name: `template_${templateId}.json`,
				type: 'text/json'
			},
			icons: {
				exportTemplate: 'export',
				folder: 'group',
				assessment: 'wizard',
				respondent: 'wizard',
				treatment: 'wizard',
				assessmentQuestion: 'assessment-question',
				assessmentCalculation: 'assessment-calculation',
				assessmentAttribute: 'assessment-attribute',
				assessmentFlag: 'assessment-flag',
				respondentAttribute: 'respondent-attribute',
				respondentCustomAttribute: 'respondent-data',
				respondentCustomAttributeHistory: 'respondent-data',
				treatmentAttribute: 'treatment-attribute',
				treatmentCustomAttribute: 'treatment-data',
				warning: 'error'
			},
			wizard: {
				type: '',
				path: '',
				show: false,
				item: null
			},
			treeStoreConfig: {
				checked: false,
				expanded: false,
				highlighted: false,
				handleUncheck: true
			},
			types: validationTypes,
			updateMirrorContext,
			updateName,
			updatePastedContext,
			updateHelpers: {
				fields: updateFields,
				mirror: updateMirrorContext,
				name: updateName,
				paste: updatePastedContext,
				warnings: [respondentWarning]
			}
		};
	},

	computed: {
		...mapGetters('exportTemplateGui', ['data', 'locked', 'occupied']),
		...mapGetters('components/tree-view/export-template', ['localItems']),
		...mapGetters({ userId: 'userId' })
	},

	created () {
		!this.locked ? this.lockTemplate(templateId) : this.goBack();
	},

	destroyed () {
		this.unlockTemplate(templateId);
	},

	methods: {
		...mapActions('exportTemplateGui', ['lockTemplate', 'unlockTemplate', 'saveTemplate']),

		goBack () {
			cardUrl.openCard(COMPONENT_NAME, {
				id: templateId
			});
		},

		onAdd ({ item, path, mergeFields }) {
			if (item.preventAdd) {
				this.wizard = {
					type: item.type,
					show: true,
					item,
					path
				};
				return;
			}

			const newItem = blueprints()[item.type]; // prevents reactive inheritance
			newItem.fields.codeTemplate = this.defaultTemplate;

			if (mergeFields) {
				merge(newItem.fields, item);
				newItem.fields.name = updateName(newItem.fields);
			}

			this.$store.dispatch('components/tree-view/export-template/addItem', {
				item: newItem,
				path
			});
		},

		bulkAdd (items) {
			forEach(items, (item) => {
				this.onAdd({
					path: this.wizard.path,
					mergeFields: true,
					item
				});
			});
		},

		onClose () {
			this.wizard.show = false;
			defer(() => {
				this.buttonRef.focus();
			});
		},

		setButtonRefs (ref) {
			this.buttonRef = ref;
		}
	}
};
</script>
