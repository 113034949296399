<template>
	<div class="shortcut__container">
		<a
			:href="link(item.card)"
			class="shortcut__action"
		>
			<icon :name="item.icon" class-name="shortcut__icon" />
			<span class="shortcut__caption">{{t(item.title)}}</span>
		</a>
	</div>
</template>

<script>
import cardUrl from 'cwcardurl';
import t from 'translate';
import { noop } from 'lodash';

export default {
	props: {
		item: {
			type: Object,
			default: noop
		}
	},
	methods: {
		iconClasses: (name) =>
			['icon', 'icon--default', 'icon-2x', 'shortcut__icon', `icon-${name}`],
		link: (card) => cardUrl.buildUrl(card),
		t
	}
};
</script>
