import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * TreatmentClinician Entity
 * @class TreatmentClinician
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	url: function () {
		return urls.singleClinician.url(this.get('treatment'), this.get('administratorId'));
	},
	eventNamespace: 'treatment-clinician',
	idAttribute: 'administratorId',
	displayName: function () {
		return this.get('displayName');
	}
});
