var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-treatment-program__container",
      class: { "is-active": _vm.isActive }
    },
    [
      _c("div", { staticClass: "edit-treatment-program__container-header" }, [
        _c(
          "div",
          { staticClass: "edit-treatment-program__container-header-column" },
          [
            _c(
              "h4",
              {
                staticClass:
                  "\n\t\t\t\t\tedit-treatment-program__container-title\n\t\t\t\t\tedit-treatment-program__container-title--section\n\t\t\t\t"
              },
              [
                _c(
                  "button",
                  {
                    attrs: {
                      title: _vm.$t("treatmentProgram.section.editProperties")
                    },
                    on: { click: _vm.selectSection }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.$t("treatmentProgram.section.title", {
                            index: _vm.index + 1,
                            name: _vm.section.contentPackage.name
                          })
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "edit-treatment-program__container-header-column" },
          [
            _c(
              "button",
              {
                staticClass: "edit-treatment-program__container-delete",
                attrs: {
                  title: _vm.$t("treatmentProgram.section.deleteSection")
                },
                on: { click: _vm.deleteSection }
              },
              [_c("icon", { attrs: { name: "delete" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }