import CardDialog from 'service/card-dialog/card-dialog';
import t from 'translate';
import store from 'store';
import { clinicianName, respondentName} from 'service/display-name/display-name';

const processUser = (user) => {
	const prefix = user.administrator ? 'administrator' : 'respondent';
	const getName = {
		administrator: clinicianName,
		respondent: respondentName
	}[prefix];
	const prop = (propName) => user[prefix][propName];
	return {
		type: {
			administrator: t('Clinician'),
			respondent: t('Respondent')
		}[prefix],
		name: getName(user[prefix]),
		id: prop(user.administrator ? 'id' : 'respondentId'),
		phone: prop('phone'),
		email: prop('email')
	}
};

const template = ({ user }) => `
	<ul class="abuse-report__details">
		<li class="abuse-report__detail">
			<p class="abuse-report__detail-description">
				<strong>${user.type}</strong> ${t('with ID')} <strong>${user.id}</strong>
			</p>
		</li>
		<li class="abuse-report__detail">
			<p class="abuse-report__detail-name">${t('User name')}</p>
			<p class="abuse-report__detail-description">${user.name}</p>
		</li>
		<li class="abuse-report__detail">
			<p class="abuse-report__detail-name">${t('Phone number')}</p>
			<p class="abuse-report__detail-description">${user.phone}</p>
		</li>
		<li class="abuse-report__detail">
			<p class="abuse-report__detail-name">${t('Email')}</p>
			<p class="abuse-report__detail-description">${user.email}</p>
		</li>
	</ul>
`;

export default ({ report }) => {
	const dialog = new CardDialog({
		title: t('Reported user identity')
	});
	dialog.setLoading();
	store.dispatch('circles/abuseReports/revealIdentity', { report }).then(() => {
		dialog.setLoaded().setContent(template({
			user: processUser(store.state.circles.abuseReports.currentIdentity)
		}));
	});
}
