import * as urls from './urls';
import getResults from '../shared/get-results';
import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';
import { assign } from 'lodash';

/**
 * @class Repository
 */
export default {
	/**
	 * Add new role.
	 *
	 * @function addRole
	 * @param {object} data - Parameters.
	 * @param {string} data.name - Role name.
	 * @param {string} data.description - Role description.
	 * @param {string} data.type - Role type.
	 * @returns {object} - Role.
	 * @example
	 *        repository.addRole({ name: 'name', description: 'desc', type: 'type' });
	 */
	addRole: (data) => POST({ url: urls.roles, data }),

	/**
	 * Add new role set.
	 *
	 * @function addRoleSet
	 * @param {object} data - Parameters.
	 * @param {string} data.name - Role set name.
	 * @param {string} data.description - Role set description.
	 * @returns {object} - Role set.
	 * @example
	 *        repository.addRoleSet({ name: 'name', description: 'desc' });
	 */
	addRoleSet: (data) => POST({ url: urls.roleSets, data }),

	/**
	 * Add a role to a role set.
	 *
	 * @function addRoleSetRole
	 * @param {object} data - Parameters.
	 * @param {number} data.roleId - Id of a role set.
	 * @param {number} data.roleSetId - Id of a role set.
	 * @returns {object} - Role.
	 * @example
	 *        repository.addRoleSet({ roleId: 1, roleSetId: 1 });
	 */
	addRoleSetRole: ({ roleId, roleSetId }) => PUT({
		url: urls.roleSetRole({ roleId, roleSetId }),
		data: { required: 0 }
	}),

	/**
	 * Delete a role.
	 *
	 * @function deleteRole
	 * @param {number} id - Id of a role.
	 * @example
	 *        repository.deleteRole(1);
	 */
	deleteRole: (id) => DELETE({ url: urls.rolesId(id) }),

	/**
	 * Delete a role set.
	 *
	 * @function deleteRoleSet
	 * @param {number} id - Id of a role set.
	 * @example
	 *        repository.deleteRoleSet(1);
	 */
	deleteRoleSet: (id) => DELETE({ url: urls.roleSetsId(id) }),

	/**
	 * Delete binding between a role and a role set.
	 *
	 * @function deleteRoleSetRole
	 * @param {object} data - Parameters.
	 * @param {number} data.roleId - Id of a role set.
	 * @param {number} data.roleSetId - Id of a role set.
	 * @example
	 *        Repository.deleteRoleSetRole({ roleId: 1, roleSetId: 1 });
	 */
	deleteRoleSetRole: ({ roleId, roleSetId }) => {
		const url = urls.roleSetRole({ roleId, roleSetId });
		return DELETE({ url });
	},

	/**
	 * Get all roles.
	 *
	 * @function roles
	 * @returns {Array} - Roles.
	 * @example
	 *        repository.roles();
	 */
	roles: () => {
		const url = urls.roles;
		return getResults(url);
	},

	/**
	 * Get a role set based on its id.
	 *
	 * @function roleSet
	 * @param {number} id - Id of a role set.
	 * @returns {object} - Role set.
	 * @example
	 *        repository.roleSet(1);
	 */
	roleSet: (id) => {
		const url = urls.searchSingleRoleSet(id);
		return getResults(url);
	},

	/**
	 * Get all the Role sets.
	 *
	 * @function roleSets
	 * @returns {Array} - RoleSets.
	 * @example
	 *        Repository.roleSets();
	 */
	roleSets: () => {
		const request = GET({ url: urls.roleSets });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Get a list of roles based on filters.
	 *
	 * @function searchRoles
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - Roles.
	 * @example
	 *        repository.searchRoles({ search: 'test', start: 0, limit: 10, filter: ['test'] });
	 */
	searchRoles: (params) => {
		const url = urls.searchRoles(params);
		return getResults(url);
	},

	/**
	 * Get a list of role sets based on filters.
	 *
	 * @function searchRoleSets
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - Rolesets.
	 * @example
	 *        repository.searchRoleSets({ search: 'test', start: 0, limit: 10 });
	 */
	searchRoleSets: (params) => {
		const url = urls.searchRoleSets(params);
		return getResults(url);
	},

	/**
	 * Update a single role.
	 *
	 * @function updateRole
	 * @param {object} data - Parameters.
	 * @param {number} data.id - Role id.
	 * @param {string} data.name - Role name.
	 * @param {string} data.description - Role description.
	 * @param {string} data.type - Role type.
	 * @returns {object} - Role.
	 * @example
	 *        repository.updateRole({ id: 1, name: 'name', description: 'desc', type: 'type' });
	 */
	updateRole: (data) => PUT({ url: urls.rolesId(data.id), data }),

	/**
	 * Update a single role set.
	 *
	 * @function updateRoleSet
	 * @param {object} data - Parameters.
	 * @param {number} data.id - Role set id.
	 * @param {string} data.name - Role set name.
	 * @param {string} data.description - Role set description.
	 * @returns {object} - Role.
	 * @example
	 *        repository.updateRoleSet({ id: 1, name: 'name', description: 'desc' });
	 */
	updateRoleSet: (data) => PUT({ url: urls.roleSetsId(data.id), data }),

	/**
	 * Update a connection between a role and a role set.
	 *
	 * @function updateRoleSetRole
	 * @param {object} data - Parameters.
	 * @param {number} data.roleId - Role id.
	 * @param {number} data.roleSetId - Role set id.
	 * @param {boolean} data.required - Boolean value to be updated.
	 * @returns {object} - Role set.
	 * @example
	 *        repository.updateRoleSetRole({ roleId: 1, roleSetId: 1, required: true });
	 */
	updateRoleSetRole: (data) => PUT({
		url: urls.roleSetRole(data),
		data: { required: data.required }
	}),

	/**
	 * Single role set data.
	 *
	 * @param {number} id - Role set id.
	 * @returns {object} -  Role set.
	 * @example
	 *        repository.roleSetSingle(1);
	 */
	roleSetSingle: (id) => getResults(urls.roleSetsId(id)),

	/**
	 * @param {string} name - Role name.
	 * @returns {object} -    Promise.
	 * @example
	 * 		repository.roleByName('R1');
	 */
	roleByName: (name) => GET({ url: urls.roleByName(name) }),

	/**
	 * @param {string} name - Roleset name.
	 * @returns {object} -    Promise.
	 * @example
	 * 		repository.rolesetByName('R1');
	 */
	rolesetByName: (name) => GET({ url: urls.rolesetByName(name) })
};
