import MultipleEntities from '../shared/multiple-entities';
import PlanInstance from './entity';
import urls from './urls';

/**
 * PlanInstances Entity
 * @class PlanInstances
 * @extends MultipleEntities
 */
const PlanInstancesEntity = MultipleEntities.extend({
	urls,
	idName: 'workflowInstanceId',
	model: PlanInstance,
	eventNamespace: 'plan-instance',

	canAdd: function (model) {
		return +model.get('respondentId') === +this.ownerId;
	},

	getByRespondentId: function (respondentId) {
		return this.retrieve('byRespondentId', respondentId);
	},

	getByRespondent: function (respondent) {
		const respondentId = respondent.get('respondentId');
		return this.getByRespondentId(respondentId);
	},

	getByTreatmentId: function (treatmentId) {
		const planInstances = this.retrieve('byTreatmentId', treatmentId);
		planInstances.canAdd = function (model) {
			return +model.get('treatment') === +this.ownerId;
		};
		return planInstances;
	}
});

export default new PlanInstancesEntity();
