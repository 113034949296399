var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.classPrefix }, [
    _c(
      "div",
      { class: _vm.previewClassName },
      [
        _c("sidebar-item", {
          attrs: { item: _vm.item, type: _vm.type, clickable: _vm.clickable }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.save($event)
          }
        }
      },
      [
        _c("text-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            disabled: !_vm.canSubmit,
            label: "Label"
          },
          model: {
            value: _vm.item.label,
            callback: function($$v) {
              _vm.$set(_vm.item, "label", $$v)
            },
            expression: "item.label"
          }
        }),
        _vm._v(" "),
        _c("checkbox-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            disabled: !_vm.canSubmit,
            label: "Translate label"
          },
          model: {
            value: _vm.item.translate,
            callback: function($$v) {
              _vm.$set(_vm.item, "translate", $$v)
            },
            expression: "item.translate"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            options: _vm.icons,
            "class-prefix": _vm.classPrefix,
            disabled: !_vm.canSubmit,
            label: "Icon"
          },
          model: {
            value: _vm.item.icon,
            callback: function($$v) {
              _vm.$set(_vm.item, "icon", $$v)
            },
            expression: "item.icon"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "radio-input__field-container" },
          [
            _c("radio-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                "value-name": "card",
                label: "Target card"
              },
              model: {
                value: _vm.item.linkType,
                callback: function($$v) {
                  _vm.$set(_vm.item, "linkType", $$v)
                },
                expression: "item.linkType"
              }
            }),
            _vm._v(" "),
            _c("radio-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                "value-name": "url",
                label: "Target URL"
              },
              model: {
                value: _vm.item.linkType,
                callback: function($$v) {
                  _vm.$set(_vm.item, "linkType", $$v)
                },
                expression: "item.linkType"
              }
            }),
            _vm._v(" "),
            _c("radio-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                "value-name": false,
                label: "No target"
              },
              model: {
                value: _vm.item.linkType,
                callback: function($$v) {
                  _vm.$set(_vm.item, "linkType", $$v)
                },
                expression: "item.linkType"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.item.linkType === "card"
          ? _c("select-input", {
              attrs: {
                options: _vm.cards,
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                label: "Card"
              },
              model: {
                value: _vm.item.target,
                callback: function($$v) {
                  _vm.$set(_vm.item, "target", $$v)
                },
                expression: "item.target"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.item.linkType === "url"
          ? _c("text-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                label: "URL"
              },
              model: {
                value: _vm.item.target,
                callback: function($$v) {
                  _vm.$set(_vm.item, "target", $$v)
                },
                expression: "item.target"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.item.linkType === "url"
          ? _c("checkbox-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                disabled: !_vm.canSubmit,
                label: "Open URL in new window"
              },
              model: {
                value: _vm.item.openInNewWindow,
                callback: function($$v) {
                  _vm.$set(_vm.item, "openInNewWindow", $$v)
                },
                expression: "item.openInNewWindow"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            options: _vm.counterNames,
            searchable: false,
            label: "Counter",
            "get-option-label": _vm.counterLabel,
            disabled: !_vm.canSubmit
          },
          model: {
            value: _vm.item.counter,
            callback: function($$v) {
              _vm.$set(_vm.item, "counter", $$v)
            },
            expression: "item.counter"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            options: _vm.sizes,
            searchable: false,
            disabled: !_vm.canSubmit,
            label: "Size"
          },
          model: {
            value: _vm.item.size,
            callback: function($$v) {
              _vm.$set(_vm.item, "size", $$v)
            },
            expression: "item.size"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            options: _vm.styles,
            searchable: false,
            disabled: !_vm.canSubmit,
            label: "Style"
          },
          model: {
            value: _vm.item.style,
            callback: function($$v) {
              _vm.$set(_vm.item, "style", $$v)
            },
            expression: "item.style"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "class-prefix": _vm.classPrefix,
            options: _vm.checkpoints,
            "get-option-label": _vm.checkpointLabel,
            disabled: !_vm.canSubmit,
            label: "Additional ACL key"
          },
          model: {
            value: _vm.item.acl.checkpoint,
            callback: function($$v) {
              _vm.$set(_vm.item.acl, "checkpoint", $$v)
            },
            expression: "item.acl.checkpoint"
          }
        }),
        _vm._v(" "),
        _vm.item.acl.checkpoint
          ? _c("select-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                options: _vm.accessLevels,
                searchable: false,
                disabled: !_vm.canSubmit,
                label: "ACL Level"
              },
              model: {
                value: _vm.item.acl.op,
                callback: function($$v) {
                  _vm.$set(_vm.item.acl, "op", $$v)
                },
                expression: "item.acl.op"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button-group",
          { attrs: { "class-prefix": _vm.classPrefix } },
          [
            _c("submit-button", {
              attrs: {
                label: "Save",
                "can-submit": _vm.canSubmit,
                "class-prefix": _vm.classPrefix
              }
            }),
            _vm._v(" "),
            _c("plain-button", {
              attrs: {
                label: "Save and close",
                "can-cancel": _vm.canSubmit,
                "class-prefix": _vm.classPrefix,
                "on-click": _vm.saveAndClose
              }
            }),
            _vm._v(" "),
            _c("plain-button", {
              attrs: {
                "on-click": _vm.onCancel,
                "class-prefix": _vm.classPrefix,
                label: "Reset"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }