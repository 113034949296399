var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("li", { staticClass: "treatment-label__element-container" }, [
    _c("div", { staticClass: "treatment-label__label-container" }, [
      _c(
        "label",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "treatment-label__value"
        },
        [_vm._v(_vm._s(_vm.props.label))]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "treatment-label__value-container" }, [
      _c("p", { staticClass: "treatment-label__value" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }