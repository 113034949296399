/**
 * @class Repository
 */
export default {
	/**
	 * Get all ClinicianRoles
	 * @method getClinicianRoles
	 * @return {Object} {{#crossLink "ClinicianRoles"}}{{/crossLink}}
	 * @example
	 *        Repository.getClinicianRoles();
	 */
	getClinicianRoles: function () {
		return this.ClinicianRoles.getAll();
	},

	/**
	 * Get active ClinicianRoles
	 * @method getActiveClinicianRoles
	 * @return {Object} {{#crossLink "ClinicianRoles"}}{{/crossLink}}
	 * @example
	 *        Repository.getActiveClinicianRoles();
	 */
	getActiveClinicianRoles: function () {
		return this.ClinicianRoles.getActive();
	},

	/**
	 * Get all Checkpoints
	 * @method getCheckpoints
	 * @return {Object} {{#crossLink "Checkpoints"}}{{/crossLink}}
	 * @example
	 *        Repository.getCheckpoints();
	 */
	getCheckpoints: function () {
		return this.Checkpoints.getAll();
	},

	/**
	 * Get all CheckpointGroups
	 * @method getCheckpointGroups
	 * @return {Object} {{#crossLink "CheckpointGroups"}}{{/crossLink}}
	 * @example
	 *        Repository.getCheckpointGroups();
	 */
	getCheckpointGroups: function () {
		return this.CheckpointGroups.getAll();
	},

	/**
	 * @method getAccessRoleCheckpoints
	 * @param roleId
	 * @returns {*|Object}
	 */
	getAccessRoleCheckpoints: function (roleId) {
		return this.AccessRoleCheckpoints.getForRole(roleId);
	},

	/**
	 * @method getAccessRoleCheckpointGroups
	 * @param roleId
	 * @returns {*|Object}
	 */
	getAccessRoleCheckpointGroups: function (roleId) {
		return this.AccessRoleCheckpointGroups.getForRole(roleId);
	}
};
