import $ from 'jquery';
import cardurl from 'cwcardurl';
import repository from 'repository';
import t from 'translate';
import Table from 'components/table/table';
import TileView from 'tile-view';
import cwalert from 'cwalert';
import can from 'acl-can';
import { find, noop } from 'lodash';

const redirectToDetails = (assessmentId) => {
	cardurl.openCard(
		'system-settings-assessment-details',
		{ assessmentId }
	);
};

export default TileView.extend({
	title: t('main-menu.Assessments'),
	acl: [{
		checkpoint: 'system.settings.assessments',
		op: 'READ'
	}],

	tileData: () => ({
		assessments: repository.getAssessments()
	}),

	loaded ({ tile }) {
		tile.table = new Table({
			className: 'wide-table',
			parent: tile.el,
			items: tile.assessments.models,
			item: (item) => item.attributes,
			// for the purposes of styling
			click: () => noop,
			columns: [{
				key: 'assessmentName',
				label: t('assessment-management.table-headers.name'),
				render: (name, { item, $td }) => {
					$($td).on('click', () => {
						redirectToDetails(item.assessmentId);
					});
					return `${name} v${item.assessmentVersion}`;
				}
			}, {
				key: 'assessmentCode',
				label: t('assessment-management.table-headers.code'),
				render: (name, { item, $td }) => {
					$($td).on('click', () => {
						redirectToDetails(item.assessmentId);
					});
					return item.assessmentCode;
				}
			}, {
				key: 'assessmentPackageName',
				label: t('assessment-management.table-headers.package'),
				render: (name, { item, $td }) => {
					$($td).on('click', () => {
						redirectToDetails(item.assessmentId);
					});
					return `${name} (v${item.assessmentPackageVersion}.${
						item.assessmentPackageRevision
					})`;
				}
			}, {
				key: 'assessmentNoRespondent',
				label: t('Clinician only'),
				render: (name, { item, $td }) => {
					const model = find(tile.assessments.models, ({ attributes }) =>
						attributes.assessmentId === item.assessmentId);
					const $input = $('<input type="checkbox" />').prop({
						checked: model.get('assessmentNoRespondent'),
						disabled: !can.edit('system.settings.assessments')
					});
					$input.on('click', () => {
						model.set(
							'assessmentNoRespondent',
							!model.get('assessmentNoRespondent')
						);
						model.save().done(() => {
							cwalert.success(t('Changes saved.'));
						}).fail(() => {
							cwalert.error(t('Failed'));
						});
					});
					$($td).html($input);
				}
			}]
		});
	}
});
