<template>
	<div class="treatment-program-properties">
		<h3 class="treatment-program-properties__header">
			{{$t('treatmentProgram.section.properties')}}
		</h3>

		<form
			novalidate
			autocomplete="off"
			class="form-view"
		>
			<text-input
				v-model="section.contentPackage.name"
				:label="$t('general-list.Name')"
				required
				mandatory
				readonly
			/>
		</form>
	</div>
</template>

<script>
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';

export default {
	data: ({ $store }) => ({
		section: $store.getters[TREATMENT_PROGRAM.ACTIVE_ITEM_DATA]
	})
};
</script>
