import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

/**
 * ClinicianRole Entity
 * @class ClinicianRole
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.singleRole.url,
	eventNamespace: 'clinician-role',
	idAttribute: 'administratorRoleId',

	defaults: {
		administratorRoleDescription: '',
		administratorRoleActive: true,
		administratorRoleName: ''
	},

	label: {
		administratorRoleName: t('general-list.Name'),
		administratorRoleActive: t('system-manager.RoleActive')
	}
});


