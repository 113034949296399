import t from 'translate';
import store from 'store';
import appBar from 'app-bar';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import { when } from 'jquery';
import revealIdentity from '../dialogs/reveal-identity';

const card = {
	name: 'circle-abuse-report',
	title: t('Abuse report'),
	url: 'circles/abuse-report/:circleAbuseReportId',

	ctrl: ({ setLoading, setLoaded }, { circleAbuseReportId }) => {
		const initAbuseReport = store.dispatch('circles/abuseReports/initSingle', {
			id: circleAbuseReportId
		});
		const initAbuseStatuses = store.dispatch('circles/abuseReports/statuses/init');

		return when(initAbuseReport, initAbuseStatuses).then(() => {
			const report = store.state.circles.abuseReports.currentReport;
			card.mountButtons({ report, setLoading, setLoaded });
		});
	},

	mountButtons: ({ report, setLoading, setLoaded }) => {
		const post = report.circlePost;
		const user = report.circleUser;
		appBar.clear(card.name);

		card.revealUserButton({ report });

		if (user) {
			if (!user.isBanned) {
				card.banUserButton({ report, setLoading, setLoaded, user });
			} else {
				card.unBanUserButton({ report, setLoading, setLoaded, user });
			}
		}

		if (post && !post.isDeleted) {
			card.deletePostButton({ report, setLoading, setLoaded });
		}
	},

	deletePostButton: ({ report, setLoading, setLoaded }) => {
		appBar.addButton({
			sourceCard: card.name,
			title: t('Remove post'),
			icon: 'post-remove',
			click: () => {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Remove reported post'),
					message: t('Are you sure you want to remove the post?')
				}).then(() => {
					setLoading();
					store.dispatch('circles/abuseReports/deletePost', { report }).then(() => {
						setLoaded();
						cwalert.success(t('Post has been removed'));
						card.mountButtons({ report, setLoading, setLoaded });
					});
				});
			}
		});
	},

	banUserButton: ({ report, setLoading, setLoaded, user }) => {
		appBar.addButton({
			sourceCard: card.name,
			title: t('Ban user'),
			icon: 'ban--user',
			click: () => {
				confirmation({
					icon: 'ban--user',
					warning: true,
					title: t('Ban user'),
					message: t('Are you sure you want to ban the user?')
				}).then(() => {
					setLoading();
					store.dispatch('circles/abuseReports/banCurrentUser', { report }).then(() => {
						setLoaded();
						cwalert.success(t('User "{nickname}" has been banned', {
							nickname: user.nickname
						}));
						card.mountButtons({ report, setLoading, setLoaded });
					});
				});
			}
		});
	},

	unBanUserButton: ({ report, setLoading, setLoaded, user }) => {
		appBar.addButton({
			sourceCard: card.name,
			title: t('Unban user'),
			icon: 'unban--user',
			click: () => {
				confirmation({
					icon: 'unban--user',
					warning: true,
					title: t('Unban user'),
					message: t('Are you sure you want to unban the user?')
				}).then(() => {
					setLoading();
					store.dispatch('circles/abuseReports/unBanCurrentUser', { report }).then(() => {
						setLoaded();
						cwalert.success(t('User "{nickname}" has been unbanned', {
							nickname: user.nickname
						}));
						card.mountButtons({ report, setLoading, setLoaded });
					});
				});
			}
		});
	},

	revealUserButton: ({ report }) => {
		appBar.addButton({
			sourceCard: card.name,
			title: t('Reveal identity of reported user'),
			icon: 'reveal--user',
			click: () => {
				revealIdentity({ report });
			}
		});
	}
};

export { card as abuseReport };
