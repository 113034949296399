import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';
import formLabels from './form-labels';
import { isArray, isString } from 'lodash';

/**
 * ExportTemplateColumn Entity
 * @class ExportTemplateColumn
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'dataExportTemplateColumnId',
	eventNamespace: 'export-template-column',
	urlRoot: urls.column.url,
	label: formLabels.column,

	defaults: {
		dataExportTemplateColumnName: '',
		dataExportTemplateColumnLabel: '',
		dataExportTemplateColumnAssessmentOrder: 'FIRST',
		dataExportTemplateColumnDescription: '',
		fkDataExportTemplateColumnSuperAssessmentId: null,
		fkDataExportTemplateColumnAssessmentId: null,
		fkDataExportTemplateColumnContextId: null,
		dataExportTemplateColumnAttributeType: 'QUESTION',
		dataExportTemplateColumnAttributeOption: ''
	},

	onInitialize () {
		this.currentName = this.getName() || '';

		this.listenTo(this, 'change:dataExportTemplateColumnAttributeType', this.optionChanged);
		this.listenTo(this, 'sync', () => {
			this.currentName = this.getName();
		});
		this.listenToOnce(this, 'sync', (model, columnId) => {
			!this.getId() && this.set('dataExportTemplateColumnId', columnId);
		});
	},

	optionChanged () {
		const isAssessmentRelated = [
			'RESPONDENT',
			'RESPONDENT_META',
			'TREATMENT',
			'TREATMENT_META'
		].indexOf(this.get('dataExportTemplateColumnAttributeType')) === -1;

		if (!isAssessmentRelated) {
			this.set({
				fkDataExportTemplateColumnSuperAssessmentId: null,
				fkDataExportTemplateColumnAssessmentId: null,
				fkDataExportTemplateColumnContextId: null
			});
		}
	},

	setColumnNames (columnNames) {
		this.columnNames = columnNames || [];
		return this;
	},

	getTypeLabel (opt = {}) {
		const valueLabel = !!opt.valueLabel;
		const type = opt.type || this.getType();

		return {
			TREATMENT: [t('Treatment attribute'), t('Attribute Name')],
			TREATMENT_META: [t('Treatment custom attribute'), t('Attribute Name')],
			RESPONDENT: [t('Respondent Attribute'), t('Attribute Name')],
			RESPONDENT_META: [t('Respondent Custom Attribute'), t('Attribute')],
			RESPONDENT_META_HISTORY: [t('Respondent Custom Attribute History'), t('Attribute')],
			ATTRIBUTE: [t('Questionnaire Attribute'), t('Attribute')],
			FLAG: [t('Questionnaire Flag'), t('Code')],
			CALCULATION: [t('Questionnaire Calculation'), t('Code')],
			QUESTION: [t('Questionnaire Question'), t('Code')]
		}[type][+valueLabel];
	},

	nameIsUnique (name) {
		return name === this.currentName || this.columnNames.indexOf(name) === -1;
	},

	generateUniqueName (columns) {
		if (!this.isNew()) {
			return this;
		}

		const generateUniqueName = () => {
			const length = columns.length + 1;

			const findName = (length) => {
				const name = `V${length}`;
				const nameExists = columns.findWhere({
					dataExportTemplateColumnName: name
				});

				if (nameExists) {
					return findName(length + 1);
				}
				return name;
			};
			return findName(length);
		};

		this.set('dataExportTemplateColumnName', generateUniqueName());
		return this;
	},

	validate (attrs) {
		if (attrs.noValidation) {
			return;
		}

		const _isArray = isArray(attrs.dataExportTemplateColumnLabel);
		const _isString = isString(attrs.dataExportTemplateColumnLabel);
		const labelLength = _isArray ?
			attrs.dataExportTemplateColumnLabel[0].length :
			attrs.dataExportTemplateColumnLabel.length;

		const isSingleColumn = _isString || attrs.dataExportTemplateColumnLabel.length === 1;
		const keys = [];
		const msgs = [];

		if (isSingleColumn && labelLength < 2) {
			keys.push('dataExportTemplateColumnLabel');
			msgs.push(t('Column label is too short, try something longer'));
		}

		if (isSingleColumn && labelLength > 120) {
			keys.push('dataExportTemplateColumnLabel');
			msgs.push(t('Column label cannot exceed 120 characters, try something shorter'));
		}

		if (
			isSingleColumn &&
			(
				// eslint-disable-next-line no-useless-escape
				!/^[A-Za-z@][\w#\$\.]{0,59}$/.test(attrs.dataExportTemplateColumnName) ||
				/\.$/.test(attrs.dataExportTemplateColumnName)
			)
		) {
			keys.push('dataExportTemplateColumnName');
			msgs.push(t(`Column name cannot exceed 60 characters, cannot end with full stop (‘.’) and must begin with a letter or the at-sign (‘@’). Subsequent characters may also be digits, letters, octothorpes (‘#’), dollar signs (‘$’), underscores (‘_’), or full stops (‘.’).`));
		}

		if (
			!attrs.dataExportTemplateColumnAttributeOption &&
			attrs.dataExportTemplateColumnAttributeType !== 'TREATMENT_META'
		) {
			keys.push('dataExportTemplateColumnAttributeOption');
			msgs.push(t('This attribute has to be set'));
		}

		if (
			attrs.dataExportTemplateColumnAttributeType === 'TREATMENT_META' &&
			!attrs.customAttributesCount
		) {
			keys.push('treatmentCustomAttributes');
			msgs.push(t('No attributes have been selected'));
		}

		if (!this.nameIsUnique(attrs.dataExportTemplateColumnName)) {
			keys.push('dataExportTemplateColumnName');
			msgs.push(t('Column name has to be unique'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	},

	getTemplateId () {
		return this.get('fkDataExportTemplateColumnTemplateId');
	},

	getName () {
		return this.get('dataExportTemplateColumnName');
	},

	getLabel () {
		return this.get('dataExportTemplateColumnLabel');
	},

	getType () {
		return this.get('dataExportTemplateColumnAttributeType');
	},

	getOrder () {
		return this.get('dataExportTemplateColumnOrder');
	},

	getOption () {
		return this.get('dataExportTemplateColumnAttributeOption');
	},

	setOrder (value) {
		return this.set('dataExportTemplateColumnOrder', value);
	},

	setOption (value, opts) {
		return this.set({
			dataExportTemplateColumnAttributeOption: value
		}, opts);
	}

});

