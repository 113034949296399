import RespondentsNotRepliedTo from './respondents-not-replied-to/tile';
import UnansweredMessages from './unanswered-messages/tile';
import RespondentMessages from './respondent-messages/tile';
import NewMessage from './respondent-new-message/tile';
import TreatmentMessages from './treatment-messages/tile';

export default {
	'respondents-not-replied-to': RespondentsNotRepliedTo,
	'unanswered-messages': UnansweredMessages,
	'respondent-messages': RespondentMessages,
	'respondent-new-message': NewMessage,
	'treatment-messages': TreatmentMessages
};
