var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "consent-information",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.restoreOldVersion($event)
        }
      }
    },
    [
      _c("checkbox-input", {
        attrs: {
          disabled: true,
          "class-prefix": "consent-information",
          "input-class": "checkbox-input",
          label: "consents.allowAnswerClinician"
        },
        model: {
          value: _vm.consent.submittableByAdministrator,
          callback: function($$v) {
            _vm.$set(_vm.consent, "submittableByAdministrator", $$v)
          },
          expression: "consent.submittableByAdministrator"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          options: _vm.versions,
          "item-label": "label",
          label: "consents.versions",
          placeholder: _vm.t("consents.selectVersion"),
          searchable: false,
          clearable: false,
          disabled: !_vm.canEdit
        },
        model: {
          value: _vm.version,
          callback: function($$v) {
            _vm.version = $$v
          },
          expression: "version"
        }
      }),
      _vm._v(" "),
      _c("submit-button", {
        attrs: { "can-submit": !!_vm.version, label: "consents.restore" }
      }),
      _vm._v(" "),
      _vm.previewing
        ? _c("warning-overlay", [
            _c("p", { staticClass: "warning" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.t("consents.showingPreviousVersion")) +
                  "\n\t\t"
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }