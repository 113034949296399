import store from 'store';
import TileView from 'tile-view';
import t from 'translate';
import RoleMapping from './data-integration-role-mapping';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { get } from 'lodash';

export default TileView.extend({
	instant: true,
	title: t('dataIntegration.roleMapping.name'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
		op: READ
	}],
	actions: (tile) => {
		const ruleSetId = tile.cardContext().get('ruleSetId');
		const actions = [['dataIntegration/searchRoleMapping', ruleSetId]];
		const treatmentTypeId = get(
			store.getters['dataIntegration/ruleSets/singleData'],
			'treatmentType.id',
			null
		);

		if (treatmentTypeId) {
			actions.push(['treatmentType/getRoles', { treatmentTypeId }]);
		} else {
			actions.push(['treatmentType/deleteRoles']);
		}
		return actions;
	},
	vueComponent: () => ({ RoleMapping })
});
