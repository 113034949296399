var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table" }, [
    _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.t("Group name")) + "\n\t\t\t")
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.t("Group category")) + "\n\t\t\t")
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.t("Group is common")) + "\n\t\t\t")
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.respondentGroups, function(group) {
        return _c(
          "tr",
          {
            key: group.id,
            staticClass: "clickable-row",
            attrs: { tabindex: "0" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.edit(group)
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar"
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.edit(group)
              }
            }
          },
          [
            _c("td", [
              _c("p", { staticClass: "table-cell-label" }, [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.t("Group name")) + "\n\t\t\t\t"
                )
              ]),
              _vm._v("\n\t\t\t\t" + _vm._s(group.name) + "\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "table-cell-label" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.t("Group category")) +
                    "\n\t\t\t\t"
                )
              ]),
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.categoryLabel(group)) + "\n\t\t\t"
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "table-cell-label" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.t("Group is common")) +
                    "\n\t\t\t\t"
                )
              ]),
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.commonLabel(group)) + "\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "table-cell-label" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("Actions")) + "\n\t\t\t\t")
              ]),
              _vm._v(" "),
              _vm.canRemove
                ? _c("button", {
                    staticClass:
                      "delete-button__action icon icon--delete icon-2x",
                    attrs: { type: "button", tabindex: "0" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.remove(group)
                      }
                    }
                  })
                : _vm._e()
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }