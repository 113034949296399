var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "data-integration__add-container" }, [
      _c(
        "button",
        {
          staticClass: "submit-button__button submit-button__button--submit",
          attrs: {
            disabled: !_vm.availableCodes.length || !_vm.canEdit,
            type: "submit"
          },
          on: { click: _vm.add }
        },
        [_vm._v(_vm._s(_vm.t("dataIntegration.codeVariations.actions.add")))]
      )
    ]),
    _vm._v(" "),
    _vm.codeVariations.length
      ? _c(
          "table",
          { staticClass: "data-integration__code-variations table" },
          [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.translatedHeaders, function(header, i) {
                  return _c("th", { key: i }, [_vm._v(_vm._s(header))])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.codeVariations, function(item) {
                return _c(
                  "tr",
                  { key: item.id },
                  [
                    _vm._l(_vm.fields, function(field, i) {
                      return _c("td", { key: i }, [
                        _c("p", { staticClass: "table-cell-label" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.translatedHeaders[i]) +
                              "\n\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.columnValue(field, item))
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "icon-button__button",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.remove(item)
                            }
                          }
                        },
                        [
                          _c("icon", { attrs: { name: "delete", small: true } })
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              }),
              0
            )
          ]
        )
      : _c("span", [_vm._v(_vm._s(_vm.t("No items")))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }