import t from 'translate';

const card = {
	name: 'system-settings-languages',
	title: t('system-settings.sidebar-button.title.languages'),
	url: 'system/languages'
};

export { card as languages };

