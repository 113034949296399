import add from './add-content-package/tile';
import ClinicianGroups from './content-package-clinician-groups/tile';
import Edit from './edit-content-package/tile';
import Instance from './content-package-instance/tile';
import InstanceElements from './content-package-instance-elements/tile';
import InstanceExercise from './content-package-instance-exercise/tile';
import InstanceList from './content-package-instance-list/tile';
import ContentPackages from './content-packages/tile';
import ContentPackageElements from './content-package-elements/tile';
import contentPackagesCategories from './content-package-categories/tile';
import simpleExercises from './simple-content-package-exercises/tile';
import simpleContentPackages from './simple-content-packages/tile';

export default {
	'add-content-package': add,
	'content-packages': ContentPackages,
	'content-package-elements': ContentPackageElements,
	'edit-content-package': Edit,
	'content-package-clinician-groups': ClinicianGroups,
	'content-package-instance': Instance,
	'content-package-instance-elements': InstanceElements,
	'content-package-instance-exercise': InstanceExercise,
	'content-package-instance-list': InstanceList,
	'simple-content-package-exercises': simpleExercises,
	'simple-content-packages': simpleContentPackages,
	'content-package-categories': contentPackagesCategories
};

