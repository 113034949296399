import { assign, forEach } from 'lodash';
import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import store from 'store';
import { READ } from 'service/acl/access-levels';
import { chooseRoute, formCfgFactory } from '../../shared';

export default TileView.extend({
	title: t('general-list.Respondent'),

	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],

	actions: () => ['systemSettings/init', 'languages/init', 'timezones/init'],

	loaded: ({ tile }) => {
		const formViews = [];

		// create new FormView instance for each custom attribute
		forEach(store.getters['systemSettings/respondent'], (setting) => {
			const cfg = formCfgFactory({ setting });

			chooseRoute([{
				when: () => setting.systemSettingLabel === 'respondent.i18n.defaultLanguage',
				then: () => {
					assign(cfg.fields[0], {
						type: 'select',
						collection: store.state.languages.collection,
						labelField: 'languageLabel',
						keyField: 'languageId'
					});
				}
			}, {
				when: () => setting.systemSettingLabel === 'respondent.i18n.defaultTimezone',
				then: () => {
					assign(cfg.fields[0], {
						type: 'select',
						collection: store.state.timezones.collection,
						labelField: 'timezoneName',
						keyField: 'timezoneName'
					});
				}
			}]);

			formViews.push(formView(cfg).$el);

		});

		tile.$el.append(formViews);
	}
});
