import t from 'translate';
import repository from 'repository';
import List from '../flag-list/tile';

export default List.extend({
	title: t('Unseen flags'),
	columns: ['isRaised', 'date', 'treatment', 'respondent', 'assessment', 'title'],
	render () {
		this.collection = repository.getFlags({
			isSeen: 0,
			notifications: 1
		});
		this.load();
	}
});
