import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { isString } from 'lodash';
import t from 'translate';
import appContext from 'app-context';
import $ from 'jquery';
import datetime from 'datetime';

/**
 * PlanInstance Entity
 * @class PlanInstance
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'workflowInstanceId',
	url: urls.planInstance.url,
	eventNamespace: 'plan-instance',

	setStartDate (date, timezone) {
		this.set(
			'workflowInstanceStartDate',
			datetime(date).toUtcFromTimezone(timezone).toObject()
		);
	},

	getStartDate () {
		return this.get('workflowInstanceStartDate');
	},

	getLabel () {
		return this.get('name') || `${t('untitled')} ${this.getId()}`;
	},

	isEnabled () {
		return this.get('enabled');
	},

	setEnabled (bool) {
		this.set('enabled', bool);
		return this;
	},

	isTerminated () {
		return this.get('status') === 'Terminated';
	},

	canChange () {
		return this.get('canChange');
	},

	getRespondentId () {
		const getFromCollection = () => this.get('respondentId');
		const getFromModel = () => {
			const respondent = this.get('respondent');
			return isString(respondent) ? respondent : respondent.id;
		};

		return getFromCollection() || getFromModel();
	},

	getName () {
		return this.getThisOrThat('workflowInstanceName', 'name');
	},

	getStatus () {
		return this.getThisOrThat('workflowInstanceStatus', 'status');
	},

	getStage () {
		return this.getThisOrThat('workflowInstanceStage', 'stage');
	},

	pause () {
		return this._pauseOrResume('pause');
	},

	resume () {
		return this._pauseOrResume('resume');
	},

	_pauseOrResume (mode) {
		const dff = $.Deferred();
		this._url = this.url;
		this.url = urls.pauseOrResume.url;
		appContext.trigger(`${this.eventNamespace}.${mode}-start`);

		this.save({ mode }).then(() => {
			this.unset('mode');
			this.url = this._url;
			this.setEnabled(mode === 'resume');
			appContext.trigger(`${this.eventNamespace}.${mode}`);
			dff.resolve();
		});

		return dff.promise();
	},

	terminate () {
		const dff = $.Deferred();
		const clone = this.clone();
		clone.url = urls.terminate.url;

		appContext.trigger(`${this.eventNamespace}.terminate-start`);

		clone.save().then(() => {
			appContext.trigger(`${this.eventNamespace}.terminate`);
			dff.resolve();
		});

		return dff.promise();
	}
});

