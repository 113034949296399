<template>
	<div class="consent__clinician-group--container">
		<assign-items
			:can-add="canEdit"
			:can-remove="canEdit"
			:labels="labels"
			:getters="getters"
			:actions="actions"
		/>
	</div>
</template>

<script>
import can from 'acl-can';
import t from 'translate';

export default {
	title: t('Clinician groups with access to this consent'),
	actions: ['clinicianGroups/initAll', 'consent/setConsentClinicianGroups'],
	data: () => ({
		labels: {
			empty: t('There is no clinician group selected at this moment'),
			name: t('Clinician group'),
			removeItem: t('Remove clinician group'),
			removeQuestion: t('Are you sure you wish to remove clinician group?'),
			removeSuccess: t('Clinician group removed successfully'),
			selectItem: t('- select clinician group to add -'),
			addSuccess: t('Clinician group added')
		},
		getters: {
			allItems: 'clinicianGroups/all',
			items: 'consent/clinicianGroups'
		},
		actions: {
			removeItem: 'consent/removeConsentClinicianGroup',
			addItem: 'consent/addConsentClinicianGroup'
		}
	}),
	computed: {
		canEdit: () => can.edit('system.settings.consents')
	}
};
</script>
