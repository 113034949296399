import can from 'acl-can';
import t from 'translate';
import TileView from 'tile-view';

export default TileView.extend({
	title: t('Treatment type'),
	actions: ['treatmentTypes/initForCurrentClinician', 'consent/setConsentTreatmentTypes'],

	Vue: (tile) => ({
		data: () => ({
			consentId: tile.cardContext().get('consentId'),
			labels: {
				empty: t('There are no treatment types selected at this moment'),
				name: t('Treatment Types'),
				removeItem: t('Remove treatment type'),
				removeQuestion: t('Are You sure You want remove treatment type?'),
				removeSuccess: t('Treatment type removed successfully'),
				selectItem: t('Select treatment type'),
				addSuccess: t('Treatment type added')
			},
			getters: {
				allItems: 'treatmentTypes/sortedByName',
				items: 'consent/treatmentTypes'
			},
			actions: {
				removeItem: 'consent/removeConsentTreatmentType',
				addItem: 'consent/addConsentTreatmentType'
			}
		}),
		computed: {
			canEdit: () => can.edit('system.settings.consents')
		},
		template: `
			<div class="consent__treatment-type-container">
				<assign-items
					:can-add="canEdit"
					:can-remove="canEdit"
					:labels="labels"
					:getters="getters"
					:actions="actions" 
				/>
			</div>
		`
	})
});
