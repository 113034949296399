<template>
	<div>
		<select-input
			v-model="item.enterprise"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.enterprise.$error"
			label="dataIntegration.ruleSets.fields.enterprise"
			:mandatory="true"
			:options="enterpriseOptions"
			:placeholder="t('dataIntegration.ruleSets.actions.selectEnterprise')"
			:searchable="false"
			@input="touchEnterprise"
		/>

		<select-input
			v-if="item.enterprise"
			v-model="item.treatmentType"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="invalidTreatmentType"
			label="dataIntegration.ruleSets.fields.treatmentType"
			:mandatory="!!item.enterprise"
			:options="currentTreatmentTypes"
			:placeholder="t('dataIntegration.ruleSets.actions.selectTreatmentType')"
			:searchable="false"
			@input="touchTreatmentType"
		/>

		<text-input
			v-if="excludeEnterprise"
			v-model="enterpriseName"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="true"
			label="dataIntegration.ruleSets.fields.treatmentTypeEnterprise"
		/>

		<select-input
			v-model="codeType"
			:class-prefix="classPrefix"
			item-label="name"
			container-class="select-container"
			label="dataIntegration.ruleSets.fields.codeType"
			:mandatory="true"
			:options="availableCodeTypes"
			:placeholder="t('dataIntegration.ruleSets.actions.selectCodeType')"
			:searchable="false"
		/>

		<select-input
			v-model="selectedCode"
			:class-prefix="classPrefix"
			:disabled="disabledDefaultCode"
			:options="availableCodes"
			:placeholder="t('dataIntegration.ruleSets.actions.selectCode')"
			:searchable="true"
			container-class="select-container"
			item-label="name"
			label="dataIntegration.ruleSets.fields.defaultCode"
			@input="onCodeSelect"
		/>

		<selected-codes :codes="selectedCodes" @delete="removeSelectedCode" />

		<label
			v-translate
			:class="radioLabelClass('defaultAccess')"
		>dataIntegration.ruleSets.fields.allowFull</label>
		<div class="data-integration__radio-row">
			<radio-input
				v-model="item.defaultAccess"
				:value-name="true"
				label="dataIntegration.ruleSets.fields.allowed"
				@input="vuelidate.item.defaultAccess.$touch()"
			/>
		</div>

		<div class="data-integration__radio-row data-integration__radio-row--last">
			<radio-input
				v-model="item.defaultAccess"
				:value-name="false"
				label="dataIntegration.ruleSets.fields.denied"
				@input="vuelidate.item.defaultAccess.$touch()"
			/>
		</div>

		<label
			v-translate
			:class="radioLabelClass('defaultMappedTo')"
		>dataIntegration.ruleSets.fields.roleFull</label>

		<div class="data-integration__radio-row">
			<radio-input
				v-model="item.defaultMappedTo"
				value-name="MAIN_RESPONDENT"
				label="dataIntegration.ruleSets.fields.roleRespondent"
				@input="vuelidate.item.defaultMappedTo.$touch()"
			/>
		</div>

		<div class="data-integration__radio-row data-integration__radio-row--last">
			<radio-input
				v-model="item.defaultMappedTo"
				value-name="ROLE"
				label="dataIntegration.ruleSets.fields.roleRest"
				@input="vuelidate.item.defaultMappedTo.$touch()"
			/>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import { cloneDeep, constant, differenceBy, filter, find, get, map, reject } from 'lodash';
import { CODES } from 'store/data-integration/codes';
import { CODE_TYPES } from 'store/data-integration/code-types';
import { RULE_SETS } from 'store/data-integration/rule-sets';
import selectedCodes from '../components/selected-codes';

export default {
	components: { selectedCodes },
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		defaultRule: {
			type: Boolean,
			default: false
		},
		enterprises: {
			type: Array,
			default: constant([])
		},
		item: {
			type: Object,
			required: true
		},
		treatmentTypes: {
			type: Array,
			default: constant([])
		},
		treatmentTypeOptions: {
			type: Array,
			default: constant([])
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},

	data () {
		return {
			codeType: {},
			currentTreatmentTypes: { ...this.treatmentTypeOptions },
			defaultRuleSetForEnterprise: false,
			enterprise: null,
			excludeEnterprise: false,
			selectedCode: null,
			selectedCodes: []
		};
	},

	computed: {
		codesWithEnterprise: ({ $store }) => $store.getters[CODES.WITH_ENTERPRISE],
		codesWithoutEnterprise: ({ $store }) => $store.getters[CODES.WITHOUT_ENTERPRISE],
		ruleSetsWithEnterprise: ({ $store }) => $store.getters[RULE_SETS.WITH_ENTERPRISE],
		codeTypes: ({ $store }) => $store.getters[CODE_TYPES.SORTED],

		availableCodeTypes: ({ codeTypes, selectedCodes }) => differenceBy(
			codeTypes,
			selectedCodes,
			(codeOrType) => get(codeOrType, 'codeType.id', codeOrType.id)
		),

		codes: ({ codesWithEnterprise, codesWithoutEnterprise, enterprise, item }) => {
			if (!item.enterprise) {
				return [];
			}
			return enterprise ? codesWithEnterprise : codesWithoutEnterprise;
		},

		filteredCodes: ({ codes, codeType }) => filter(codes, ['codeType.id', codeType.id]),

		availableCodes: (
			{ filteredCodes, selectedCodes }
		) => differenceBy(filteredCodes, selectedCodes, 'id'),

		disabledDefaultCode: (
			{ codesWithEnterprise, codeType, updating }
		) => !codeType.id || updating || !codesWithEnterprise.length,

		enterpriseName: ({ enterprise }) => enterprise ? enterprise.name : '',

		enterpriseOptions: ({ enterprises }) => {
			const options = map(filter(enterprises, { enabled: true }), (enterprise) => ({
				label: enterprise.name,
				value: enterprise.id
			}));
			options.unshift({
				label: t('dataIntegration.enterprise.options.noEnterprise'),
				value: 0
			});
			return options;
		},
		invalidTreatmentType: ({ item, vuelidate }) => {
			if (!item.enterprise || !vuelidate.item.treatmentType.$dirty) {
				return false;
			}
			return !item.treatmentType;
		}
	},

	methods: {
		checkCodes (enterpriseId) {
			this.enterprise = find(this.enterprises, { id: enterpriseId });
			this.$store.dispatch(
				'dataIntegration/codes/searchCodesByEnterprise',
				this.enterprise.id
			);
		},

		clearObjects () {
			this.item.treatmentType = null;
			this.item.defaultCodes = [];
			this.selectedCodes = [];
		},

		radioLabelClass (type) {
			const classes = ['data-integration__label--mandatory'];

			if (this.vuelidate.item[type].$error) {
				classes.push('data-integration__label--invalid');
			}
			return classes;
		},

		setTreatmentTypes (enterpriseId) {
			return filter(this.treatmentTypeOptions, (treatmentType) => {
				if (!treatmentType.enterprise) {
					return false;
				}
				return treatmentType.enterprise.id === enterpriseId;
			});
		},

		setTreatmentTypesWithoutEnterprises () {
			const availableOptions = reject(this.treatmentTypeOptions, 'enterprise');

			if (this.defaultRule) {
				availableOptions.shift();
			}
			return availableOptions;
		},
		t,
		touchEnterprise (option) {
			this.vuelidate.item.enterprise.$touch();
			this.clearObjects();

			if (option) {
				this.excludeEnterprise = option.value === 0;
				this.currentTreatmentTypes = option.value === 0 ?
					this.setTreatmentTypesWithoutEnterprises() :
					this.setTreatmentTypes(option.value);
				option.value ?
					this.checkCodes(option.value) :
					this.$store.dispatch('dataIntegration/codes/searchCodesByEnterprise');
				this.defaultRuleSetForEnterprise = !!filter(this.ruleSetsWithEnterprise,
					(ruleSet) => {
						const enterpriseMatched = ruleSet.enterprise.id === option.value;
						return enterpriseMatched && !ruleSet.treatmentType;
					}).length;
				this.updateDefaultTreatmentTypeOption();
				this.$emit('invalidTreatmentType', this.item.enterprise);
			}
		},
		touchTreatmentType () {
			this.vuelidate.item.treatmentType.$touch();
			this.$emit('invalidTreatmentType', !this.item.treatmentType);

			if (!this.item.enterprise.value) {
				this.enterprise = null;

				if (this.item.treatmentType) {
					if (this.item.treatmentType.enterprise) {
						this.checkCodes(+this.item.treatmentType.enterprise.id);
					} else if (!this.codesWithoutEnterprise.length) {
						this.$store.dispatch('dataIntegration/codes/searchCodesByEnterprise');
					}
				} else {
					this.item.defaultCodes = [];
					this.$store.dispatch('dataIntegration/deleteCodesByEnterprise');
				}
			}
		},
		updateDefaultTreatmentTypeOption () {
			if (!this.defaultRuleSetForEnterprise && !this.excludeEnterprise) {
				this.currentTreatmentTypes.unshift({ ...this.treatmentTypeOptions[0] });
			}
		},

		getId: (code) => code.id,

		onCodeSelect () {
			this.selectedCodes.push(cloneDeep(this.selectedCode));
			this.item.defaultCodes.push(this.selectedCode.id);
			this.selectedCode = null;
			this.codeType = {};
		},

		removeSelectedCode (id) {
			this.selectedCodes = reject(this.selectedCodes, { id });
			this.item.defaultCodes = reject(this.item.defaultCodes, (code) => code === id);
		}
	}
};
</script>
