<template>
	<div
		class="edit-treatment-program__container"
		:class="{ 'is-active': isActive }"
	>
		<div class="edit-treatment-program__container-header">
			<div>
				<h4
					class="
						edit-treatment-program__container-title
						edit-treatment-program__container-title--module
					"
				>
					<button
						:title="$t('treatmentProgram.module.editProperties')"
						@click="selectModule"
					>
						{{$t('treatmentProgram.module.title', {
							index: index + 1,
							name: module.name
						})}}
					</button>
				</h4>
			</div>

			<div>
				<button
					class="edit-treatment-program__container-delete"
					:title="$t('treatmentProgram.module.deleteModule')"
					@click="deleteModule"
				>
					<icon name="delete" />
				</button>
			</div>
		</div>

		<slot />
	</div>
</template>

<script>
import cwalert from 'service/cwalert';
import confirmation from 'components/confirmation/confirmation';
import {
	MODULE
} from 'modules/treatment-programs/constants';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';
import Icon from 'components/icon';

export default {
	components: {
		Icon
	},

	props: {
		module: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},

	computed: {
		isActive: ({ module, $store }) => {
			const { type, id } = $store.getters[TREATMENT_PROGRAM.ACTIVE_ITEM];
			return type === MODULE && id === module.id;
		}
	},

	methods: {
		selectModule () {
			this.$store.dispatch(
				TREATMENT_PROGRAM.SET_ACTIVE_ITEM,
				{ type: MODULE, id: this.module.id }
			);
		},

		deleteModule () {
			confirmation({
				icon: 'delete',
				warning: true,
				title: this.$t('treatmentProgram.module.removeConfirmation.title'),
				message: this.$t('treatmentProgram.module.removeConfirmation.message')
			}).then(async () => {
				try {
					await this.$store.dispatch(
						TREATMENT_PROGRAM.DELETE_MODULE,
						{ moduleId: this.module.id }
					);

					cwalert.success(this.$t('general.messages.remove.success'));
				} catch (err) {
					cwalert.error(this.$t('general.messages.changes-not-saved'));
				}
			});
		}
	}
};
</script>
