import appContext from 'app-context';
import can from 'acl-can';
import t from 'translate';
import cwalert from 'cwalert';
import Assignment from 'components/assignment';
import checkForLostAccessUponDeletion from './helpers/check-for-lost-access-upon-deletion';
import systemSettings from 'system-settings';
import repository from 'repository';
import confirmation from 'components/confirmation/confirmation';
import { get, escape, includes, isEmpty } from 'lodash';
import store from 'store';
import TileView from 'tile-view';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';

export default TileView.extend({
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.RESPONDENTS.GROUPS
	}, {
		op: READ,
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS
	}],
	title: t('general-list.Groups'),

	actions: () => ['respondent/init', 'respondentGroups/initForCurrentClinician'],

	tileData: () => ({
		respondentHasRespondentGroups: repository.getRespondentInRespondentGroups(
			store.getters['respondent/respondentId']
		),
		respondentGroups: repository.getRespondentGroups()
	}),

	loaded ({ tile }) {
		const readonly = !can['delete']('administrator.respondents.groups');
		const respondentId = store.getters['respondent/respondentId'];
		const clinicianRespondentGroups = store.state.respondentGroups.collection;
		const respondent = get(store.state, 'respondent.model');

		tile.assignment = new Assignment({
			parent: tile.el,
			readonly,
			allItemsCollection: tile.respondentGroups,
			relationCollection: tile.respondentHasRespondentGroups,
			relationAttributes: {
				respondentId
			},
			itemPrimaryKey: 'respondentGroupId',
			selectorLabel: t('Select group to add'),
			maintainOrder: true,
			selectorRender: (item)  => escape(item.respondentGroupName),
			columns: [{
				key: 'respondentGroupName',
				label: t('Group name'),
				render: (val, { $td }) => {
					$td.text(val);
				}
			}],
			emptyListMessage: t('The respondent has no groups assigned'),

			addFilter (item) {
				const group = clinicianRespondentGroups.findWhere({
					respondentGroupId: item.respondentGroupId
				});

				return !!group || item.respondentGroupIsCommon;
			},

			deleteFilter (item) {
				const group = clinicianRespondentGroups.findWhere({
					respondentGroupId: item.respondentGroupId
				});
				const groupHasAccess = group && group.get('respondentGroupHasAccessToClinician');
				return !!(group && groupHasAccess);
			},
			addAllowed: can.add('administrator.respondents.groups'),

			onAdd (relation, done) {
				const group = clinicianRespondentGroups.findWhere({
					respondentGroupId: relation.get('respondentGroupId')
				});
				const groupHasAccess = group && group.get('respondentGroupHasAccessToClinician');
				const loseAccess = isEmpty(tile.respondentHasRespondentGroups.models) &&
					!groupHasAccess;

				if (loseAccess) {
					confirmation({
						warning: true,
						icon: 'group',
						title: t('Assign respondent group'),
						message: t('Warning! You do not have access to this group ' +
							'If you will press OK, the respondent will get assigned to the group ' +
							'and you will immediately loose access to this respondent')
					}).done(() => {
						tile.accessLost = true;
						done();
					});
				} else {
					done();
				}
			},

			afterAdd (relation) {
				const group = tile.respondentGroups.findWhere({
					respondentGroupId: relation.get('groupId')
				});
				relation.set('group', group.attributes);
				const loseaccess = isEmpty(tile.respondentHasRespondentGroups.models) &&
					!includes(
						clinicianRespondentGroups.pluck('respondentGroupId'),
						`${relation.get('respondentGroupId')}`
					);

				if (tile.accessLost || loseaccess) {
					cwalert.warning(t('admin-portal.RespondentLostAccess'));
					appContext.trigger('respondent.update respondent.lose-access', respondent);
				}
			},

			onDelete (relation, done, reject) {
				const groupIsMandatory = systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY');

				if (groupIsMandatory && (tile.respondentHasRespondentGroups.models.length === 1)) {
					cwalert.warning(t('At least one respondent group must remain'));
					reject();
					return;
				}

				if (checkForLostAccessUponDeletion(
					relation.get('respondentGroupId'),
					tile.respondentHasRespondentGroups,
					clinicianRespondentGroups
				)) {
					confirmation({
						icon: 'unlink',
						warning: true,
						message: t('Warning! Leaving only groups to which you don\'t have ' +
							'access to will cause you to lose access to this respondent ' +
							'Continue?')
					}).done(() => {
						appContext.trigger('respondent.lose-access', respondent);
						done();
						cwalert.warning(
							t('Respondent now belongs to a group which you cannot access!')
						);
					}).fail(reject);

				} else {
					confirmation({
						icon: 'delete',
						warning: true,
						message: t('Are you sure you wish to remove this?')
					}).done(done).fail(reject);
				}
			}
		});
	}
});

