/* eslint-disable prefer-rest-params */
/**
 * Params: model, container?
 *
 * @param params
 * @returns {Row}
 */
import Cell from './cell';
import acl from 'acl';
import cwalert from 'cwalert';
import $ from 'jquery';
import celllabel from 'cell-label';
import t from 'translate';
import button from 'button';
import confirmation from 'components/confirmation/confirmation';
import { forEach, isFunction, find } from 'lodash';

const NestedAttribute = function (params) {
	this.root = params.root;
	this.key = params.key;
};

const Row = function (params = {}) {
	const __inst = this;

	if (!params.model) {
		throw 'Model must be supplied for a Row';
	}
	const __model = params.model;

	const readonly = (typeof params.readonly === 'object') ?
		!acl.checkAccess({
			operation: 'edit',
			aclEntry: __model.get(params.readonly.aclEntryAttributeName)
		}) :
		params.readonly;

	const __tr = document.createElement('tr');
	__tr.__model = __model;

	if (typeof params['row-attributes'] === 'object') {
		forEach (params['row-attributes'], function (v, k) {
			__tr.setAttribute(k, (typeof v === 'function') ?
				v.call(this, __model) :
				__model.get(v));
		});
	}

	if (isFunction(params.onRowClick)) {
		$(__tr)
			.addClass('clickable-row')
			.attr('tabindex', 0)
			.on('click keypress', (event) => {
				if (event.which === 13 || event.type === 'click') {
					if ($(event.target).not('input, button, a').length) {
						params.onRowClick.call(__model, event, __model);
					}
				}
			});
	}

	this.attachTo = function (container, nextSibling) {
		if (nextSibling) {
			container.insertBefore(__tr, nextSibling);

		} else {
			container.appendChild(__tr);
		}

		return this;
	};

	this.isAttached = function () {
		return __tr.parentNode !== undefined;
	};

	this.detach = function () {
		if (__tr.parentNode) {
			__tr.parentNode.removeChild(__tr);
		}

		return this;
	};

	this.getModelId = function () {
		return __model.get(__model.idAttribute);
	};

	this.modelId = this.getModelId();

	this.getTr = function () {
		return __tr;
	};

	/**
	 * If container is specified in the constructor - attach to it.
	 */
	if (params.container) {
		this.attachTo(params.container, params.nextSibling);
	}

	const __mode = {
		readOnly: false,
		edit: false
	};

	const __leftActionsBtn = document.createElement('div');

	const __leftActionsList = document.createElement('div');
	__leftActionsBtn.appendChild(__leftActionsList);

	if (params.actionsCount.right > 1) {
		__leftActionsBtn.className = 'btn-group';

		const __leftActionsLabel = document.createElement('a');
		__leftActionsLabel.className = 'btn dropdown-toggle';
		__leftActionsLabel.setAttribute('data-toggle', 'dropdown');
		__leftActionsLabel.href = '#';
		__leftActionsLabel.innerHTML = '<span class="caret"></span>';
		__leftActionsBtn.appendChild(__leftActionsLabel);

		__leftActionsList.className = 'dropdown-menu';
	}

	if (params.actions.left) {
		const __leftActionsTd = document.createElement('td');
		__leftActionsTd.className = 'actions';
		__tr.appendChild(__leftActionsTd);
		celllabel(__leftActionsTd, t('Actions'));
		__leftActionsTd.appendChild(__leftActionsBtn);
	}

	const modelWrapper = new function () {

		this.get = function (attrName) {
			if (attrName instanceof NestedAttribute) {
				return __model.get(attrName.root)[attrName.key];

			}
			return __model.get(...arguments);
		};

		this.set = function (attrName, value) {
			if (attrName instanceof NestedAttribute) {
				const root = __model.get(attrName.root);
				root[attrName.key] = value;

				return __model.set(attrName.root, root);

			}
			return __model.set(...arguments);

		};

		this.save = function () {
			return __model.save(...arguments);
		};

		this.on = function () {
			return __model.on(...arguments);
		};
	}(__model);

	const __cells = {};
	(function () {
		forEach (params.fields, (item, attrName) => {
			const attributeName = params.rootAttribute ?
				new NestedAttribute({
					root: params.rootAttribute,
					key: attrName
				}) :
				attrName;

			const column = params.columns[attrName] || {};

			__cells[attrName] = new Cell({
				renderer: item.rendererFactory.createRenderer(attributeName, modelWrapper, {
					mode: __mode,
					disableAutoSave: false,
					readonly
				}),
				tr: __tr,
				className: column.className,
				label: column.label
			});
		});
	})();

	const __actionsBtn = document.createElement('div');

	const __actionsList = document.createElement('div');
	__actionsBtn.appendChild(__actionsList);

	if (params.actionsCount.right > 1) {
		__actionsBtn.className = 'btn-group';

		const __actionsLabel = document.createElement('a');
		__actionsLabel.className = 'btn dropdown-toggle';
		__actionsLabel.setAttribute('data-toggle', 'dropdown');
		__actionsLabel.href = '#';
		__actionsLabel.innerHTML = '<span class="caret"></span>';
		__actionsBtn.appendChild(__actionsLabel);

		__actionsList.className = 'dropdown-menu';
	}

	if (params.actions.right) {
		const __actionsTd = document.createElement('td');
		__actionsTd.className = 'actions';
		__tr.appendChild(__actionsTd);
		celllabel(__actionsTd, t('Actions'));
		__actionsTd.appendChild(__actionsBtn);
	}

	const __action = function (params, actionList) {
		const __inst = this;

		const li = document.createElement('span');

		if (!params.disabled) {
			actionList.appendChild(li);
		}

		let a;

		if (params.icon) {
			a = button({
				icon: 'delete',
				tagName: 'button',
				css: 'delete-button__container',
				buttonClass: 'delete-button__action',
				preventCwbutton: true,
				parent: li
			}, () => {
				params.callback.call(__inst, __model);
				return false;
			});
		} else {
			a = document.createElement('a');
			a.className = params.className;
			a.tabIndex = 0;
		}

		li.appendChild(a);

		if (params.title) {
			$(a).attr('title', params.title);
		}

		let href = '#';

		if (typeof params.href === 'function') {
			href = params.href(__model);
			a.href = href;
		}

		if (params.img) {
			const img = document.createElement('img');
			img.src = params.img;
			img.setAttribute('alt', params.title ? params.title : '');

			a.appendChild(img);
			a.setAttribute('role', 'button');

		}

		if (params.label) {
			const label = document.createElement('span');
			label.innerHTML = params.label;
			a.appendChild(label);
		}

		if (params.iconClass) {
			const icon = document.createElement('i'); // boostrap bullshit - using deprecated italic
			// for icon
			icon.className = params.iconClass;
			a.appendChild(icon);
		}

		if (params.className) {
			a.className = params.className;
		}

		if (params.tip) {
			a.title = params.tip;
			a.setAttribute('rel', 'tooltip');
		}

		if (typeof params.callback == 'function' && !$(a).children().length) {
			$(a).click(() => {
				params.callback.call(__inst, __model);

				return false;
			});
		}

		this.disable = function () {
			a.disabled = true;

			return this;
		};

		this.enable = function () {
			a.disabled = false;

			return this;
		};

		if (params.disabled) {
			this.disable();
		}

		if (!params.disabled && params.bind) {
			$('td', __tr).bind(params.bind, (event) => {

				switch (event.target.tagName) {
				case 'INPUT':
				case 'TEXTAREA':
				case 'SELECT':
				case 'OPTION':
				case 'A':
					break;

				default:
					if (typeof params.callback === 'function') {
						params.callback.call(__inst, __model);

					} else {
						// window.router.navigate(href);
						window.location.href = href;
					}
				}
			});
		}
	};

	let __undo = false;
	let __saveAction = null; // to get rid of stupid eclipse warning
	let __undoAction = null; // to get rid of stupid eclipse warning

	if (params.actions.right && (params.actions.right.save)) {
		const saveProps = {
			tip: t('general-list.SaveChanges'),
			className: 'save',
			label: t('general-list.Save'),
			disabled: true,
			callback (model) {
				model.save({}, {
					success () {
						__mode.edit = false;

						// Notification
						cwalert.success(t('general-list.ChangesSaved'));

					},
					error () {
						__mode.edit = false;

						// Notification
						cwalert.failure(t('general-list.ChangesNotSaved'));

					}
				});
				this.disable();

				if (__undoAction) {
					__undoAction.disable();
				}
				__undo = false;
				return false;
			}
		};

		if (typeof params.actions.right.save === 'object') {
			forEach (params.actions.right.save, (item, key) => {
				saveProps[key] = item;
			});
		}

		__saveAction = new __action(saveProps, __actionsList);
	}

	if (params.actions.right && (params.actions.right.undo)) {
		const undoProps = {
			tip: t('general-list.UndoChanges'),
			className: 'undo',
			label: t('general-list.Undo'),
			disabled: true,
			callback () {
				__mode.edit = false;
				__model.set(__undo);
				this.disable();

				if (__saveAction) {
					__saveAction.disable();
				}
				__undo = false;
			}
		};

		if (typeof params.actions.right.undo === 'object') {
			forEach (params.actions.right.undo, (item, key) => {
				undoProps[key] = item;
			});
		}

		__undoAction = new __action(undoProps, __actionsList);
	}

	__model.on('change', () => {
		if (!__undo) {
			__undo = __model.previousAttributes();
			__mode.edit = true;
		}

		if (__saveAction) {
			__saveAction.enable();
		}

		if (__undoAction) {
			__undoAction.enable();
		}
	});

	if (params.actions.right && (params.actions.right.remove)) {
		const myparams = params.actions.right.remove;

		const removeProps = {
			icon: 'delete',
			callback () {
				// 'Before' - hook - if it returns false we do nothing.
				if (myparams.before && !myparams.before(__model)) {
					return false;
				}
				const confirmationSettings = myparams.confirmationConfig ?
					myparams.confirmationConfig :
					{ message: t('general-list.ConfirmRemove') };
				confirmation(confirmationSettings).done(() => {
					__model.destroy({
						success () {
							params.collection.remove(__model);
							cwalert.success(t('general-list.RemovedSuccessfully'));

							if (typeof myparams.after === 'function') {
								myparams.after(__model);
							}
							__inst.detach();
						},
						error () {
							cwalert.failure(t('general-list.RemoveError'));

							// as described in https://stackoverflow.com/questions/16464518/backbone-model-is-destroyed-even-on-service-error
							const wasWronglyRemoved = !find(params.collection.models, { cid: __model.cid });

							if (wasWronglyRemoved) {
								params.collection.add(__model);
							}

							if (typeof myparams.after === 'function') {
								myparams.after(__model);
							}
						}
					});
				});
			}
		};

		if (typeof params.actions.right.remove === 'object') {
			forEach (params.actions.right.remove, (item, key) => {
				removeProps[key] = item;
			});
		}

		removeProps.className = 'remove close';

		if (!removeProps.disabled) {
			new __action(removeProps, __actionsList);
		}
	}

	forEach (params.actions.right, (item, action) => {
		if (item.className === undefined) {
			item.className = action;
		}

		switch (action) {
		case 'save':
		case 'undo':
		case 'remove':
			break;

		default:
			new __action(item, __actionsList);
		}
	});

	forEach (params.actions.left, (item, action) => {
		if (item.className === undefined) {
			item.className = action;
		}

		switch (action) {
		default:
			new __action(item, __leftActionsList);
		}
	});
};

export default Row;

