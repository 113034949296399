import TileView from 'tile-view';
import cwalert from 'cwalert';
import { every, find, map } from 'lodash';
import $ from 'jquery';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';
import Role from 'repo/treatments/role-entity';
import store from 'store';
import Respondent from 'repo/respondents/respondent';
import Clinician from 'repo/clinicians/clinician';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('Roles'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}],

	tileData: () => ({
		respondent: store.getters.respondent,
		treatment: store.getters.treatment,
		treatmentClinicians: repository.getTreatmentClinicians(store.getters.treatment.id),
		treatmentTypeRoles:
			repository.getTreatmentTypeRoles(store.getters.treatment.treatmentType.id),
		treatmentRoles: store.getters['treatment/treatmentRolesCollection']
	}),

	init: ({ tile }) => {
		tile.limit = tile.config().limit || 10;

		tile.listenTo(store.state.treatment.model, 'change:complete', (model, isComplete) => {
			tile.onCompleteChange({ tile, isComplete });
		});
	},

	loaded: ({ tile }) => {
		tile.disable();
		tile.renderValidateMessage(tile);
		tile.onCompleteChange({ tile, isComplete: tile.treatment.complete });
		tile.proceed(tile);
	},

	renderValidateMessage: (tile) => {
		tile.$validateMessage = $('<div />')
			.addClass(`treatment-roles__validate-message
				treatment-roles__validate-message--hide warning`)
			.text(t('Required roles should be set'));
		tile.$el.append(tile.$validateMessage);
	},

	onCompleteChange: ({ tile, isComplete }) => {
		tile.$validateMessage.toggleClass('treatment-roles__validate-message--hide', isComplete);
	},

	proceed: (tile) => {
		if (!tile.treatmentTypeRoles.size()) {
			tile.disable();

		} else {
			tile.enable();
		}

		tile.treatmentTypeRoles.each((treatmentTypeRole) => {
			const form = tile.renderRoleForm({ tile, treatmentTypeRole });
			tile.$el.append(form.$el);
		});

		tile.checkRoles(tile);
	},

	renderRoleForm: ({ tile, treatmentTypeRole }) => {
		const isRespondentRole = treatmentTypeRole.get('type') === 'RESPONDENT';
		const keyName = isRespondentRole ? 'respondent' : 'administrator';
		const model = tile.treatmentRoles.findWhere({
			treatmentTypeRole: treatmentTypeRole.get('id')
		}) || new Role({
			treatment: tile.treatment.id,
			treatmentTypeRole: treatmentTypeRole.getId(),
			administrator: null,
			respondent: null
		});

		const form = formView({
			model,
			preventSave: true,
			saveIndicator: false,
			loader: false,
			fields: [{
				key: keyName,
				type: 'search',
				label: treatmentTypeRole.get('name'),
				mandatory: treatmentTypeRole.get('required'),
				limit: tile.limit,
				sanitizeLabel: false,
				provideResults: (search) => {
					if (isRespondentRole) {
						return repository.searchRespondents({
							search,
							respondentAccountEnabled: true,
							start: 0,
							limit: tile.limit
						});
					}

					return repository.searchClinicians({
						search,
						administratorAccountEnabled: true,
						start: 0,
						limit: tile.limit
					});
				},
				placeholder: isRespondentRole ?
					t('Type respondent name') :
					t('Type clinician name'),

				itemLabel: (user) => isRespondentRole ?
					respondentName(user.toJSON()) :
					clinicianName(user.toJSON()),

				customize: (view) => {
					if (isRespondentRole && model.get('respondent')) {
						const respondent = find(tile.treatmentRoles.toJSON(), {
							respondent: +model.get('respondent')
						});

						if (!respondent.displayName) {
							respondent.displayName = t('<no access to respondent>');
						}

						view.showItem(new Respondent(respondent));

					} else if (model.get('administrator')) {
						const clinician = find(tile.treatmentRoles.toJSON(), {
							administrator: +model.get('administrator')
						});
						view.showItem(new Clinician(clinician));
					}
				},

				onSelectItem: () => {
					model.save().then(() => {
						cwalert.success(t('Role has been set'));
						tile.checkRoles(tile);
						form.validate();
					});
				},

				onClearItem: () => {
					model.destroy().done(() => {
						cwalert.success(t('Role has been successfully removed'));
						const newForm = tile.renderRoleForm({ tile, treatmentTypeRole });
						form.$el.replaceWith(newForm.$el);
						newForm.validate();
						tile.checkRoles(tile);
					}).always(form.setLoaded.bind(form));
				}
			}]
		});

		return form;
	},

	checkRoles: (tile) => {
		if (!tile.treatmentTypeRoles.size()) {
			return;
		}

		const treatmentTypeHasRequiredRoles = map(tile.treatmentTypeRoles.where({
			required: true,
			type: 'RESPONDENT'
		}), 'id');

		// test that every required role has value
		const hasAssociatedRequiredRoles = treatmentTypeHasRequiredRoles.length ?
			every(treatmentTypeHasRequiredRoles, (roleId) => tile.treatmentRoles.findWhere({
				treatmentTypeRole: roleId
			})) :
			true;

		const isReady = hasAssociatedRequiredRoles;
		store.dispatch('treatment/update', { complete: isReady });
	}
});
