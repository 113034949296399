import appBar from 'app-bar';
import can from 'acl-can';
import t from 'translate';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';
import { when } from 'jquery';
import store from 'store';

const card = {
	title: t('Treatment'),
	name: 'treatment',
	url: 'treatment/:treatmentId/',
	autoVariant: true,
	appEvents: {
		'treatment.delete' (treatment) {
			if (+this.cardContext().get('treatmentId') === +treatment.getId()) {
				this.close();
			}
		}
	},

	actions: () => [
		can.read('administrator.respondents.workflowplans') &&
		can.read('administrator.respondents.treatments') &&
		store.dispatch('planInstances/init')
	],

	ctrl: ({ setLoading, setLoaded, populate }, { treatmentId }) => {
		store.dispatch('respondent/resetId');
		when(
			store.dispatch('treatment/init'),
			store.dispatch('treatment/treatmentRoles', treatmentId)
			, ...card.actions()
		).then(() => {

			can.edit('administrator.respondents.treatments') && appBar.addCardLink({
				sourceCard: card.name,
				title: t('Edit treatment'),
				icon: 'edit',
				card: 'edit-treatment',
				query: { treatmentId }
			});

			can.edit('administrator.respondents.workflowplans') &&
			store.getters['planInstances/size'] &&
			appBar.addButton({
				sourceCard: card.name,
				title: t('Terminate all plans belonging to this treatment'),
				icon: 'stop',
				click: () => {
					confirmation({
						icon: 'stop',
						warning: true,
						title: t('Terminate plans'),
						message: t('Terminate all plans that belong to this treatment?')
					}).then(() => {
						setLoading();
						store.dispatch('treatment/terminatePlanInstances').then(() => {
							cwalert.success(t('Plans terminated successfully'));
							setLoaded();
						});
					});
				}
			});

			populate({ data: { treatmentTypeId: store.getters.treatment.treatmentType.id } });
		});
	}
};

export { card as treatment };
