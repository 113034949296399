import cardList from './card-list';
import editCard from './edit-card';
import editTile from './edit-tile';
import tileList from './tile-list/tile';
import cardConfigCheck from './card-config-check/tile';

export default {
	'card-config-check': cardConfigCheck,
	'card-list': cardList,
	'edit-card': editCard,
	'edit-tile': editTile,
	'tile-list': tileList
};
