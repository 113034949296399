import t from 'translate';

const card = {
	title: t('Flags'),
	name: 'system-reports-flags',
	url: 'system-reports/flags',

};

export { card as flags };
