import t from 'translate';
import repository from 'repository';
import appBar from 'app-bar';
import addCircleCategoryDialog from '../dialogs/new-circle-category';
import { SYSTEM } from 'service/acl/checkpoints.json';
import can from 'service/acl/acl-can';

const card = {
	title: t('Circle categories'),
	name: 'system-settings-circle-categories',
	url: 'system/circle-categories',

	controller () {
		const circleCategories = repository.circleCategories();
		this.cardData.set({ circleCategories });

		circleCategories.then(() => {
			this.populate();
		});

		if (can.add(SYSTEM.SETTINGS.CIRCLES)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New circle category'),
				click: addCircleCategoryDialog
			});
		}
	}
};

export { card as circleCategories };
