import TileView from 'tile-view';
import Backbone from 'backbone';
import t from 'translate';
import $ from 'jquery';
import repository from 'repository';
import { split } from 'lodash';
import iconFile from 'components/icon/icon-mapping-file';

export default TileView.extend({
	title: t('assessment.properties.uploadedFiles'),
	acl: {
		op: 'or',
		checkpoints: [{
			checkpoint: 'administrator.respondents.assessmentcontent',
			op: 'READ'
		}, {
			checkpoint: 'administrator.administrators.assessments',
			op: 'ADMINISTRATOR'
		}]
	},

	cardData: () => ['assessmentInstance', 'assessmentInstanceId'],

	tileData () {
		return {
			uploadedFiles: repository.assessmentInstanceFiles(this.assessmentInstanceId)
		};
	},

	loaded () {
		if (!this.uploadedFiles.size()) {
			this.disable();
			return;
		}

		this.enable();
		const $list = $('<ul class="attachments__list" />').appendTo(this.$el);

		this.uploadedFiles.each((file) => {
			const ext = split(file.get('uploadFileName'), ('.')).pop();
			const iconExt = iconFile[ext] ? iconFile[ext] : iconFile['default'];

			const $listItem = $('<li class="attachments__list-item" />').appendTo($list);
			$('<span />').addClass([
				'attachments__attachment-icon',
				'icon',
				'icon--default',
				`icon--file-${iconExt}`
			]).appendTo($listItem);
			$('<a class="attachments__attachment-link" />')
				.html(file.getFileName())
				.attr('href', Backbone.config().backendRootUrl['default'] + file.getDownloadURL())
				.appendTo($listItem);
		});
	}
});

