var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lockout-status" }, [
    _c("div", { staticClass: "lockout-status__text" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.$t("lockout.account-locked")) + "\n\t")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lockout-status__action" },
      [
        _vm.canUnlock
          ? _c("submit-button", {
              attrs: { label: "lockout.unlock" },
              on: {
                click: function($event) {
                  return _vm.$emit("unlock")
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }