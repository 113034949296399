import systemSettings from 'system-settings';
import { renderCounter } from '../../components/counter';
import t from 'translate';
import appContext from 'app-context';
import datetime from 'datetime';
import { isFunction, replace } from 'lodash';
import cardUrl from 'cwcardurl';
import can from 'acl-can';
import { respondentName } from 'service/display-name/display-name';
import sanitize from 'service/sanitize-string/sanitize-string';

const checkbox = ({ id, label }) => ({
	id,
	label
});

const getDate = (date) => date ?
	datetime(replace(date, '+0000', ''))
		.setTimezone(appContext.get('user').get('timezoneName'))
		.toMoment()
		.format(t('date.formats.dateTime')) :
	'';

const columns = () => ({
	respondent: {
		table: {
			label: t('Respondent'),
			key: 'respondent',
			sortBy: 'respondent.displayName',
			className: 'my-treatments__respondent',
			render: (respondent) => can.read('administrator.respondents.respondents') ?
				`<a href="${cardUrl.buildUrl('respondent-status', {
					respondentId: respondent.respondentId
				})}">${respondentName(respondent)}</a>` :
				respondentName(respondent)
		}
	},
	treatment: {
		table: {
			label: t('Treatment'),
			key: 'name',
			className: 'my-treatments__treatment-name'
		}
	},
	treatmentType: {
		table: {
			label: t('Treatment type'),
			className: 'my-treatments__treatment-type',
			key: 'treatmentType',
			sortBy: 'treatmentType.name',
			render: (treatmentType) => sanitize(treatmentType.name)
		}
	},
	status: {
		table: {
			label: t('Status'),
			key: 'customStatus',
			className: 'my-treatments__treatment-status'
		}
	},
	roles: {
		filter: checkbox({
			id: 'filter[requiredRolesMissing]',
			label: t('Required roles missing')
		}),
		table: {
			label: t('Roles'),
			className: 'my-treatments__roles',
			key: 'roles',
			render: (roles) => renderCounter({
				entity: roles,
				current: 'assigned',
				problematic: 'missingRequiredRoles'
			})
		}
	},
	start: {
		table: {
			label: t('Start'),
			className: 'my-treatments__start',
			key: 'startAt',
			render: (startAt) => getDate(startAt)
		}
	},
	end: {
		table: {
			label: t('End'),
			className: 'my-treatments__end',
			key: 'endAt',
			render: (endAt) => getDate(endAt)
		}
	},
	messages: {
		condition: () => systemSettings.getBoolean('ENABLE_MESSAGING_SYSTEM'),
		filter: checkbox({
			id: 'filter[haveUnansweredMessages]',
			label: t('Have unanswered messages')
		}),
		table: {
			label: t('Messages'),
			className: 'my-treatments__messages',
			key: 'messages',
			render: (messages) => renderCounter({
				entity: messages,
				current: 0,
				problematic: 'unanswered'
			})
		}
	},
	decisions: {
		filter: checkbox({
			id: 'filter[haveUnansweredDecisions]',
			label: t('Have unanswered decisions')
		}),
		table: {
			label: t('Decisions'),
			className: 'my-treatments__decisions',
			key: 'decisions',
			render: (decisions) => renderCounter({
				entity: decisions,
				problematic: 'unanswered'
			})
		}
	},
	assessments: {
		filter: checkbox({
			id: 'filter[haveOverdueAssessments]',
			label: t('Have overdue assessments')
		}),
		table: {
			label: t('Assessments'),
			className: 'my-treatments__assessments',
			key: 'assessmentInstances',
			render: (assessmentInstances) => renderCounter({
				entity: assessmentInstances,
				current: 'answered',
				problematic: 'overdue'
			})
		}
	},
	exercises: {
		filter: checkbox({
			id: 'filter[requiredExercisesUndone]',
			label: t('Have unfinished required exercises')
		}),
		table: {
			label: t('Exercises'),
			className: 'my-treatments__exercises',
			key: 'exercises',
			render: (exercises) => renderCounter({
				entity: exercises,
				current: 'completed',
				problematic: 'mandatoryNotCompleted'
			})
		}
	},
	pages: {
		filter: checkbox({
			id: 'filter[requiredPagesUnread]',
			label: t('Have unread required pages')
		}),
		table: {
			label: t('Pages'),
			className: 'my-treatments__pages',
			key: 'pages',
			render: (pages) => renderCounter({
				entity: pages,
				current: 'read',
				problematic: 'mandatoryNotReaded'
			})
		}
	},
	packages: {
		table: {
			label: t('Content packages'),
			className: 'my-treatments__content-packages',
			key: 'contentPackages',
			render: (contentPackages) => renderCounter({
				entity: contentPackages,
				current: 'completed'
			})
		}
	},
	lastLogin: {
		table: {
			label: t('Last login'),
			key: 'respondent',
			sortBy: 'respondent.lastLogin',
			className: 'my-treatments__last-login',
			render: (respondent) => respondent.lastLogin ?
				datetime(replace(respondent.lastLogin, '+0000', ''))
					.setTimezone(appContext.get('user').get('timezoneName'))
					.toMoment()
					.format(t('date.formats.dateTime')) :
				''
		}
	}
});

/**
 * Get definition of a column based on allowance set in `condition` function.
 *
 * @param {object} params -        Parameters.
 * @param {string} params.column - Column name, e.g. 'messages'.
 * @param {string} params.type -   Type of a resource, possible values are `filter` and `table`.
 * @example
 * 		columnDefinition({ column: 'messages', type: 'filter' }))
 */
export default ({ column, type }) => {
	const condition = isFunction(columns()[column].condition) ?
		columns()[column].condition() :
		true;

	return condition && columns()[column][type];
};
