import TileView from 'tile-view';
import $, { when } from 'jquery';
import CWTable from 'components/table/table';
import datetime from 'datetime';
import cardurl from 'cwcardurl';
import t from 'translate';
import repository from 'repository';
import store from 'store';
import { get, split } from 'lodash';

const className = 'decisions-list__entry decisions-list__';

export default TileView.extend({

	title: t('respondent-management.decisions-title'),
	acl: [{
		checkpoint: 'administrator.respondents.manualinputs',
		op: 'READ'
	}],
	className: 'decisions-list',

	actions: ['decisions/init', 'respondent/init'],

	loaded () {
		this.respondent = store.state.respondent.model;
		this.setLoading();
		this.decisions = store.state.decisions.collection;
		this.treatments = repository.respondentTreatments(this.respondent.getId());

		when(this.treatments).then(this.ready.bind(this));
	},

	ready () {
		this.clear().setLoaded();
		const that = this;

		if (!this.decisions.size()) {
			$('<p />')
				.html(t('respondent-profile.tab.noItems'))
				.appendTo(this.$el);
			return;
		}

		this.decisions.comparator = function (model) {
			return model.get('value') === null ? 0 : +model.get('inputId');
		};
		this.decisions.sort();

		const hideRow = function (params) {
			const $td = $(params.row.td);
			const labelClass = `.${split(className + params.rowClass, ' ').join('.')}`;
			const $label = $td.siblings(labelClass);

			$td.add($label).addClass('optional');
		};

		this.table = new CWTable({
			parent: this.el,
			css: 'cwtable',
			items: this.decisions.models,
			sortable: true,
			sortOrder: that.config().tableSort,
			click (item) {
				cardurl.openCard('decision-properties', {
					decisionId: item.get('inputId')
				});
			},

			columns: [{
				label: t('decisions.labels.issueDate'),
				key: 'issueDate',
				css: `${className}issued`,
				render () {
					return datetime(this.item.get('issueDate'))
						.setTimezone(that.respondent.getTimezone())
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				label: t('decisions.labels.treatment'),
				skip: () => this.treatments.size() <= 1,
				css: `${className}treatment`,
				render () {
					const href = cardurl.buildUrl('treatment', {
						treatmentId: this.item.get('treatment')
					});

					return $(`<a href="${href}">${this.item.get('treatmentName')}</a>`)[0];
				}
			}, {
				label: t('decisions.labels.role'),
				css: `${className}role`,
				key: 'role',
				render: (role) => get(role, 'name', '')
			}, {
				label: t('decisions.labels.occupiedBy'),
				key: 'role',
				render: (role) => get(role, 'clinician.displayName', '')
			}, {
				label: t('decisions.labels.plan'),
				css: `${className}assessment-plan`,
				key: 'workflowInstanceName',
				render () {
					return this.item.get('workflowInstanceName');
				}
			}, {
				label: t('decisions.labels.question'),
				css: `${className}title`,
				key: 'message',
				render () {
					return this.item.get('message');
				}

			}, {
				label: t('decisions.labels.response'),
				key: 'value',
				css: `${className}answer`,
				render () {
					if (!this.item.getResponseText().length) {
						hideRow({
							row: this,
							rowClass: 'answer'
						});
					} else {
						return this.item.getResponseText();
					}

				}
			}, {
				label: t('decisions.labels.admin'),
				css: `${className}clinician`,
				key: 'administratorRespondent',
				render () {
					const admin = this.item.get('administratorRespondent');

					if (admin) {
						return admin.displayName;
					}
					hideRow({
						row: this,
						rowClass: 'clinician'
					});

				}
			}, {
				label: t('decisions.labels.responseDate'),
				css: `${className}response-date`,
				key: 'responseDate',
				render () {
					const responseDate = this.item.get('responseDate');

					if (responseDate) {
						return datetime(responseDate)
							.setTimezone(that.respondent.getTimezone())
							.toMoment()
							.format(t('date.formats.dateTime'));
					}
					hideRow({
						row: this,
						rowClass: 'response-date'
					});

				}
			}]
		});
	}
});

