import $ from 'jquery';
import can from 'acl-can';
import StorePackage from '../../../entities/package-store';
import InstallPackage from '../../../entities/package-install';
import cwalert from 'cwalert';
import t from 'translate';
import { get } from 'lodash';

const SYSTEM_PACKAGES_PACKAGES = 'system.packages.packages';

const disable = ($button) => {
	$button.prop('disabled', true).addClass('disabled package__action--disabled');
};

const enable = ($button, canEnable) => {
	if (canEnable) {
		$button.prop('disabled', false).removeClass('disabled');
	}
};

const toggle = ({ $button, canEnable, allowed }) => {
	if (allowed) {
		enable($button, canEnable);
	} else {
		disable($button);
	}
};

const displayAlert = (opts, pckg) => {
	if (opts.installedAlert) {
		cwalert.success(t('Package "{packageName}" installed successfully', {
			packageName: pckg.item.get('identifier').name
		}));
	} else if (opts.updatedAlert) {
		cwalert.success(t('Package updated successfully'));
	}
};

export default (pckg) => {
	const { $button, $actions, $li } = pckg;
	const identifier = pckg.item.get('identifier') || {};
	let canEnable = true;

	const $enabledLabel = $('<label />')
		.html(t('package-manager.packageEnabled.checkbox'))
		.appendTo($actions);

	!can.delete(SYSTEM_PACKAGES_PACKAGES) && $enabledLabel.hide();

	const $actionEnabled = $('<input type="checkbox" />').prependTo($enabledLabel);
	$actionEnabled.on('change', function () {
		const checked = $(this).prop('checked');

		canEnable = checked;
		$li.toggleClass('disabled', !checked);
		new StorePackage({
			uid: pckg.item.get('uid')
		}).save({
			disabled: !checked
		}).done(() => {
			const enabledTxt = t('Package has been enabled');
			const disabledTxt = t('Package has been disabled');
			cwalert.success(checked ? enabledTxt : disabledTxt);
		});
	});

	const updateStatus = (opts = {}) => {
		pckg.setVersionLabel(pckg.item.get('identifier'));
		$actionEnabled.prop('checked', !identifier.disabled);
		switch (identifier.importStatus) {

		case 'NEW':
			$button
				.html(t('system-manager.PackageInstall'))
				.attr('class', 'package__action install-package');

			$actions.hide();
			toggle({ allowed: can.add(SYSTEM_PACKAGES_PACKAGES), $button, canEnable });

			break;

		case 'INSTALLED':
			$button
				.html(t('system-manager.PackageInstalled'))
				.attr('class', 'package__action package__action--installed installed-package');

			$actions.show();
			disable($button);

			displayAlert(opts, pckg);
			break;

		case 'UPDATE':
			$($button)
				.html(t('system-manager.PackageUpdate'))
				.attr('class', 'package__action package__action--update update-package');

			$actions.show();

			toggle({ allowed: can.edit(SYSTEM_PACKAGES_PACKAGES), $button, canEnable });

			break;
		}
	};

	updateStatus();

	$button.on('click', () => {
		disable($button);
		switch (identifier.importStatus) {

		case 'NEW':
		case 'UPDATE':
			$button.html(t('system-manager.PackageInstalling'));
			new InstallPackage({
				storePackageId: identifier.uid
			}).save({}, {
				success () {
					const params = identifier.importStatus === 'NEW' ?
						{
							installedAlert: true,
							updatedAlert: false
						} :
						{
							installedAlert: false,
							updatedAlert: true
						};
					identifier.importStatus = 'INSTALLED';
					updateStatus(params);
				},

				error (model, xhrObj) {
					const errorMessage = get(xhrObj, 'responseJSON.exception.msg');
					cwalert.error(t(
						errorMessage ?
							`backendDictionary.assessmentStore.${errorMessage}` :
							'generic.error'
					));
					updateStatus({
						installedAlert: false,
						updatedAlert: false
					});
					enable($button);
				}
			});
			break;

		case 'INSTALLED':
			break;
		}
	});
};

