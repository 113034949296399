import t from 'translate';
import appBar from 'app-bar';
import can from 'acl-can';
import { when } from 'jquery';
import store from 'store';

const card = {
	title: t('Treatments'),
	name: 'treatment-list',
	url: 'respondent/:respondentId/treatments',

	ctrl: (ctrlProps, { respondentId }) => when(
		store.dispatch('respondent/init', { respondentId }),
		store.dispatch('treatments/initForRespondent', { respondentId })
	).then(() => {
		can.add('administrator.respondents.treatments') && appBar.addCardLink(card.name, {
			card: 'add-treatment',
			title: t('New Treatment'),
			icon: 'add',
			query: { respondentId }
		});
	})
};

export { card as treatmentList };
