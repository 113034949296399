import { assign, cloneDeep, debounce, findIndex, omit } from 'lodash';
import Vue from 'vue';
import t from 'translate';
import formView from 'components/form-view/form-view';
import systemSettings from 'system-settings';
import TileView from 'tile-view';
import cwalert from 'cwalert';
import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import Message from 'repo/messages/message';
import MessageDraft from 'repo/messages/message-draft';
import MessageDraftAttachment from 'repo/messages/message-draft-attachment';
import attachmentsComponent from '../../shared/attachments-component';

const getDraft = () => {
	let draft;
	let draftPromise;
	const drafts = store.state.messages.draftsCollection;

	if (drafts.size()) {
		draft = drafts.at(-1);
		draftPromise = draft.fetch();
	} else {
		draft = new MessageDraft({
			treatment: store.getters['treatment/treatmentId'],
			respondent: store.getters['respondent/respondentId'],
			senderType: 'CLINICIAN',
			administrator: +store.state.user.userId
		});
		draftPromise = draft.patchSave({ content: '' });
	}

	return { draft, draftPromise };
};

export default TileView.extend({
	title: t('New message'),
	acl: [{
		checkpoint: 'administrator.respondents.communication',
		op: 'ADD'
	}],
	SAVE_THRESHOLD: 500,
	features: ['ENABLE_MESSAGING_SYSTEM'],

	tileData: () => getDraft(),

	attachmentFactory: (tile, opts) => new MessageDraftAttachment(assign({
		treatmentId: store.getters.treatment.id,
		respondentId: store.getters.respondent.respondentId,
		messageDraftId: tile.draft.getId()
	}, opts)),

	loaded: ({ tile }) => {
		tile.enableAttachments = systemSettings.getBoolean('ENABLE_ATTACHMENTS');
		tile.attachments = tile.draft.get('attachment') || [];
		const smsText = systemSettings.getString('SMS_FIXEDTEXT');
		const respondentPhone = store.getters.respondent.respondentPhone;
		const smsEnabled = smsText && respondentPhone;
		const attachment = tile.attachmentFactory(tile);
		let draftXhr;

		const smsHint = ({ respondentPhone, smsText }) => {
			if (!respondentPhone) {
				return t('No phone number set for respondent');
			}

			if (!smsText) {
				return t('No default SMS set');
			}

			return t(`Will send the following SMS - {smsText}`, { smsText });
		};

		tile.form = formView({
			name: 'new-message',
			model: tile.draft,
			loader: true,
			listenTo: ['submit'],
			keysNotSubmitted: ['attachmentUpload'],
			preventSave: true,

			onAfterSave: () => {
				const send = () => {
					const messageData = cloneDeep(omit(tile.draft.toJSON(), 'id'));
					const message = new Message(messageData);

					message.save({ attachment: tile.attachments }).then(() => {
						if (draftXhr && draftXhr.readyState !== 4) {
							draftXhr.onreadystatechange = null;
							draftXhr.abort();
						}
						delete tile.draft;
						tile.card().close();
					}).fail(() => {
						cwalert.error(t('Error'));
						tile.form.setLoaded();
					});
				};

				if (systemSettings.getBoolean('CONFIRM_MESSAGE_SENDING')) {
					confirmation({
						title: t('Send message'),
						icon: 'message',
						message: t('Is the message ready to be sent?'),
						confirm: t('Yes, send'),
						cancel: t('No, cancel')
					}).then(send, () => {
						tile.form.setLoaded();
					});

				} else {
					send();
				}
			},

			fields: [{
				key: 'respondent',
				type: 'paragraph',
				label: t('Respondent'),
				value: store.getters['respondent/displayName']
			}, {
				key: 'treatment',
				type: 'paragraph',
				label: t('Related treatment'),
				value: store.getters.treatment.name
			}, {
				key: 'content',
				type: 'textarea',
				label: t('Message text'),
				hint: ' ',
				rows: 10,

				customize: (view) => {
					view.ui.textarea.on('keyup', () => {
						view.setHint(t('Saving draft'));
					});

					view.ui.textarea.on('keyup', debounce(() => {
						if (!tile.draft) {
							return;
						}
						const content = view.ui.textarea[0] ?
							view.ui.textarea[0].value :
							tile.draft.get('content');
						draftXhr = tile.draft.patchSave({
							content,
							notifyBySms: tile.draft.get('notifyBySms')
						});
						draftXhr.then(() => {
							view.setHint(t('Draft saved'));
							setTimeout(() => {
								view.setHint('');
							}, 3000);
						});
					}, tile.SAVE_THRESHOLD));
				}
			}, {
				key: 'notifyBySms',
				type: 'checkbox',
				label: t('Notify by text message as well'),
				disabled: !smsEnabled,
				hint: smsHint({ respondentPhone, smsText })
			}, tile.enableAttachments && {
				key: 'attachment',
				type: 'file',
				label: '',
				multi: true,
				template: () => `
					<div class="form-view__file-container">
						<p class="form-view__file-description-alternative"></p>
					</div>
				`,
				fileModel: attachment,
				constraints: 'message',

				onComplete: () => {
					tile.attachments.push(attachment.toJSON());
				},

				customize: (view) => {
					const attachmentsView = new Vue({
						el: document.createElement('div'),
						components: {
							'attachments-list': attachmentsComponent()
						},
						data: { attachments: tile.attachments },
						methods: {
							remove: (id) => {
								const attachmentToRemove = tile.attachmentFactory(tile, { id });
								const index = findIndex(tile.attachments, { id });
								tile.attachments.splice(index, 1);
								attachmentToRemove.destroy().then(() => {
									cwalert.success(t('Attachment removed'));
								});
							}
						},
						template: `
							<div class="message-attachments__container">
								<attachments-list
									:attachments="attachments"
									:remove="remove"
								/>
							</div>`
					});
					view.$el.prepend(attachmentsView.$el);
					view.$('.form-view__file-description-alternative')
						.html(t('Drag a file here or {spanStart}choose a file to attach{spanEnd}', {
							spanStart: '<span class="form-view__file-pseudolink">',
							spanEnd: '</span>'
						}));
				}
			}],
			buttons: [{
				caption: t('Send'),
				name: 'send',
				type: 'submit'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});
