import t from 'translate';
import appBar from 'app-bar';
import can from 'acl-can';
import store from 'store';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { RESPONDENT } from 'store/respondents/respondent';
import { editGoal, removeGoal, toggleGoalComplete } from '../shared/appbar-buttons';

const card = {
	name: 'goal',
	url: [
		'goal/:goalId',
		'respondent/:respondentId/goal/:goalId'
	],
	title: t('goals.goal'),

	ctrl: ({ actions, close }, { goalId }) => actions([['goal/init', {
		respondentId: store.getters[RESPONDENT.RESPONDENT_ID],
		goalId
	}]]).then(() => {
		const mountButtons = () => {
			appBar.clear(card.name);

			if (!can.edit(CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES)) {
				return;
			}

			appBar.addButton(toggleGoalComplete({ card, done: mountButtons }));
			appBar.addButton(editGoal({
				card,
				goalId,
				respondentId: store.getters[RESPONDENT.RESPONDENT_ID]
			}));

			if (can.remove(CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES)) {
				appBar.addButton(removeGoal({ card, done: close }));
			}
		};

		mountButtons();
	})
};

export { card as Goal };
