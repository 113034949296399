import TileView from 'tile-view';
import t from 'translate';
import datetime from 'service/datetime/datetime';

import { indexOf } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

const prefix = 'dataExportInstance';

export default TileView.extend({
	title: t('Template details'),
	actions: (tile) => [
		['exportTemplate/init', tile.cardContext().get('dataExportInstanceId')],
		['contexts/init']
	],
	acl: [{
		checkpoint: 'system.export.templates',
		op: 'READ'
	}],

	Vue: () => ({
		computed: {
			...mapGetters({ contexts: 'contexts/data' }),
			...mapGetters({ instance: 'exportTemplate/instance' }),
			context () {
				let result = [];

				if (this.contexts.length && this.instance) {
					const instanceContext = this.instance[this.addPrefix('FilterContext')];
					// eslint-disable-next-line lodash/prefer-lodash-method
					result = this.contexts.filter((singleContext) => {
						const curContextRow = singleContext['assessmentInstanceContextId'];
						return indexOf(instanceContext, curContextRow.toString()) !== -1;
					}).map((singleContext) => singleContext['assessmentInstanceContextLabel']);
				}
				return result.length ? result : null;
			}
		},
		methods: {
			...mapActions('exportTemplate', ['destroy']),
			addPrefix: (field) => `${prefix}${field}`,
			handleDate (value) {
				if (!value) {
					return '';
				}
				return datetime(value)
					.setTimezone(this.$store.getters.userTimezone)
					.toMoment()
					.format(t('date.formats.dateTime'));
			},
			yesNo (bool, reverse = false) {
				return {
					true: t('Yes'),
					false: t('No')
				}[reverse ? !bool : bool];
			}
		},
		beforeDestroy () {
			this.destroy();
		},
		template:
			`<section class="template-details__container">
				<ul>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Public</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance[addPrefix('IsPublic')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>API</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance[addPrefix('IsAvailableApi')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Allow override</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance[addPrefix('AllowOverride')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Include unsubmitted</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance[addPrefix('IsSubmittedOnly')], true) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>File format</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('FileFormat')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Created date</label>
						<p
							class="template-details__value--important"
						>{{ handleDate(this.instance[this.addPrefix('CreatedDate')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Last modified</label>
						<p
							class="template-details__value--important"
						>{{ handleDate(this.instance[this.addPrefix('LastModifiedDate')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Filter date range</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('FilterDateRange')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Range Start</label>
						<p
							class="template-details__value--important"
						>{{ handleDate(this.instance[this.addPrefix('FilterDateRangeStart')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Range Stop</label>
						<p
							class="template-details__value--important"
						>{{ handleDate(this.instance[this.addPrefix('FilterDateRangeStop')]) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>FileName</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('Filename')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Size</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('Size')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>CRC</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('Crc')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Status</label>
						<p
							class="template-details__value--important"
						>{{ instance[addPrefix('Status')] }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Contexts</label>
						<p
							class="template-details__value--important"
						>{{ context }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Include production data</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance['patient']) }}</p>
					</li>
					<li class="template-details__element-container">
						<label
							class="template-details__label--important"
							v-translate
						>Include test data</label>
						<p
							class="template-details__value--important"
						>{{ yesNo(instance['testRespondent']) }}</p>
					</li>
				</ul>
			</section>`
	})
});
