<template>
	<div class="health-indicator">
		<p class="health-indicator__phrase-container">
			<span class="health-indicator__phrase">{{phrase}}</span>
		</p>
		<div class="health-indicator__toggle-container">
			<button
				v-if="!healthy"
				class="health-indicator__toggle"
				@click="toggle"
			>{{toggleLabel}}</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { HEALTH_STATUS, TOGGLE } from '../helpers/phrases';

export default Vue.component('health-indicator', {
	props: {
		healthy: {
			type: Boolean,
			required: true
		},
		toggle: {
			type: Function,
			required: true
		},
		toggled: {
			type: Boolean,
			required: true
		},
		count: {
			type: Number,
			required: true
		}
	},

	computed: {
		phrase () {
			return HEALTH_STATUS(this.healthy);
		},

		toggleLabel () {
			return TOGGLE(this.toggled, this.count);
		}
	}
});
</script>
