var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.updating
      ? _c("div", {
          directives: [{ name: "loader-spinner", rawName: "v-loader-spinner" }],
          staticClass: "data-integration--loading"
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("h3", [
          _vm._v(_vm._s(_vm.toUpper("dataIntegration.ruleSets.labels.details")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-integration__rule-sets--container" },
          [
            _c("checkbox-input", {
              attrs: {
                disabled: !_vm.canEdit,
                label: _vm.t("dataIntegration.ruleSets.fields.enable"),
                translate: false,
                "class-prefix": "data-integration",
                "input-class": "checkbox-input",
                "text-class": "checkbox-text"
              },
              model: {
                value: _vm.item.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.item, "enabled", $$v)
                },
                expression: "item.enabled"
              }
            }),
            _vm._v(" "),
            _c("select-input", {
              attrs: {
                disabled: true,
                invalid: _vm.invalid("treatmentType"),
                mandatory: _vm.mandatoryTreatmentType,
                options: _vm.treatmentTypesOptions,
                searchable: false,
                "class-prefix": "data-integration",
                "container-class": "select-container",
                label: "dataIntegration.ruleSets.fields.treatmentType"
              },
              model: {
                value: _vm.treatmentType,
                callback: function($$v) {
                  _vm.treatmentType = $$v
                },
                expression: "treatmentType"
              }
            }),
            _vm._v(" "),
            _c("text-input", {
              attrs: {
                disabled: true,
                "class-prefix": "data-integration",
                label: "dataIntegration.ruleSets.fields.enterprise"
              },
              model: {
                value: _vm.enterpriseName,
                callback: function($$v) {
                  _vm.enterpriseName = $$v
                },
                expression: "enterpriseName"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            _vm._s(_vm.toUpper("dataIntegration.ruleSets.labels.defaults"))
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-integration__rule-sets--container" },
          [
            _c(
              "label",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.radioLabelClass("defaultAccess")
              },
              [_vm._v("dataIntegration.ruleSets.labels.allowLabel")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data-integration__radio-row" },
              [
                _c("radio-input", {
                  attrs: {
                    disabled: !_vm.canEdit,
                    "value-name": true,
                    label: "dataIntegration.ruleSets.fields.allowed"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.item.defaultAccess.$touch()
                    }
                  },
                  model: {
                    value: _vm.item.defaultAccess,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "defaultAccess", $$v)
                    },
                    expression: "item.defaultAccess"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "data-integration__radio-row data-integration__radio-row--last"
              },
              [
                _c("radio-input", {
                  attrs: {
                    disabled: !_vm.canEdit,
                    "value-name": false,
                    label: "dataIntegration.ruleSets.fields.denied"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.item.defaultAccess.$touch()
                    }
                  },
                  model: {
                    value: _vm.item.defaultAccess,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "defaultAccess", $$v)
                    },
                    expression: "item.defaultAccess"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("select-input", {
              attrs: {
                "class-prefix": "data-integration",
                "item-label": "name",
                "container-class": "select-container",
                label: "dataIntegration.ruleSets.fields.codeType",
                options: _vm.availableCodeTypes,
                placeholder: _vm.t(
                  "dataIntegration.ruleSets.actions.selectCodeType"
                ),
                searchable: false
              },
              model: {
                value: _vm.codeType,
                callback: function($$v) {
                  _vm.codeType = $$v
                },
                expression: "codeType"
              }
            }),
            _vm._v(" "),
            _c("select-input", {
              attrs: {
                disabled: !_vm.canEdit || _vm.codesLoading,
                invalid: _vm.invalid("code"),
                mandatory: true,
                options: _vm.availableCodes,
                searchable: true,
                "class-prefix": "data-integration",
                "container-class": "select-container",
                "item-label": "name",
                label: "dataIntegration.ruleSets.labels.mapping"
              },
              on: { input: _vm.onCodeSelect },
              model: {
                value: _vm.selectedCode,
                callback: function($$v) {
                  _vm.selectedCode = $$v
                },
                expression: "selectedCode"
              }
            }),
            _vm._v(" "),
            _c("selected-codes", {
              attrs: { codes: _vm.selectedCodes },
              on: { delete: _vm.removeSelectedCode }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.radioLabelClass("defaultMappedTo")
              },
              [_vm._v("dataIntegration.ruleSets.labels.defaultBelonging")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data-integration__radio-row" },
              [
                _c("radio-input", {
                  attrs: {
                    "value-name": "MAIN_RESPONDENT",
                    label: "dataIntegration.ruleSets.fields.roleRespondent",
                    disabled: !_vm.canEdit
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.item.defaultMappedTo.$touch()
                    }
                  },
                  model: {
                    value: _vm.item.defaultMappedTo,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "defaultMappedTo", $$v)
                    },
                    expression: "item.defaultMappedTo"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "data-integration__radio-row data-integration__radio-row--last"
              },
              [
                _c("radio-input", {
                  attrs: {
                    "value-name": "ROLE",
                    label: "dataIntegration.ruleSets.fields.roleRest",
                    disabled: !_vm.canEdit
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.item.defaultMappedTo.$touch()
                    }
                  },
                  model: {
                    value: _vm.item.defaultMappedTo,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "defaultMappedTo", $$v)
                    },
                    expression: "item.defaultMappedTo"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "data-integration__save-container" },
          [
            _c("submit-button", {
              attrs: {
                label: _vm.t("Update"),
                "can-submit": _vm.allowUpdate,
                "class-prefix": "data-integration"
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }