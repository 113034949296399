import formViewDialog from 'service/form-view-dialog';
import t from 'translate';
import store from 'store';
import appContext from 'app-context';

export default ({ create } = { new: false }) => {
	const formConfig = {
		preventSave: true,
		onAfterSave () {
			this.setLoading();
			const config = JSON.parse(this.model.get('json'));
			store.dispatch('cards/setTileConfig', { config, create }).then(() => {
				this.setLoaded();
				appContext.trigger('dialog:close');
			});

		},
		name: 'name',
		successMessage: t('JSON configuration imported'),
		fields: [{
			key: 'json',
			type: 'textarea',
			label: t('JSON data'),
			mandatory: true,
			focus: true,
			rows: 11,
			validators: {
				validator: (value) => {
					try {
						JSON.parse(value);
					} catch (e) {
						return false;
					}
					return true;
				},
				invalidMessage: t('JSON syntax error')
			}
		}]
	};

	const dialogConfig = {
		title: t('Import JSON configuration')
	};

	formViewDialog({ formConfig, dialogConfig });
}
