import t from 'translate';
import cardurl from 'cwcardurl';
import formViewDialog from 'service/form-view-dialog';
import ContentPackage from 'repo/content-packages/content-package';

export default () => {
	const formConfig = {
		name: 'new-content-package',
		model: new ContentPackage(),
		onAfterSave () {
			cardurl.openCard('edit-content-package', { id: this.model.getId() });
		},
		fields: [{
			key: 'name',
			type: 'text',
			label: t('Name'),
			required: true,
			mandatory: true
		}]
	};

	formViewDialog({
		formConfig,
		dialogConfig: { title: t('New information package') }
	});
};
