import t from 'translate';
import appBar from 'app-bar';
import addGoalCategoryDialog from '../dialogs/new-goal-category';
import repository from 'repository';
import can from 'acl-can';

const card = {
	title: t('Goal categories'),
	name: 'system-settings-goal-categories',
	url: 'system/goal-categories',

	ctrl: ({ cardData }) => {
		const goalCategories = repository.OLDgoalCategories();
		cardData.set({ goalCategories });

		if (can.add('system.settings.goals')) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New goal category'),
				click: addGoalCategoryDialog
			});
		}

		return goalCategories;
	}
};

export { card as goalCategories };
