<template>
	<modal-dialog
		:show="config.show"
		:on-close="config.onClose"
		:loading="config.loading"
	>
		<template #title>
			<span v-translate>{{config.title}}</span>
		</template>

		<template #content>
			<div v-if="warnings.length" class="data-integration__warning-container">
				<div
					v-for="warning in warnings"
					:key="warning"
				>
					<p class="data-integration__warning">
						{{warning}}
					</p>
				</div>
			</div>
			<modal-content
				:class-prefix="config.classPrefix"
				:acl="config.acl"
				:assessment-options="assessmentOptions"
				:codes="codes"
				:code-options="codeOptions"
				:code-type-options="codeTypeOptions"
				:default-rule="defaultRule"
				:edit="config.edit"
				:enterprises="enterprises"
				:enterprise-options="enterpriseOptions"
				:item="config.item"
				:on-close="config.onClose"
				:on-submit="config.onSubmit"
				:portal-options="portalOptions"
				:processing="processing"
				:role-available-options="roleAvailableOptions"
				:role-options="roleOptions"
				:treatment-type-options="treatmentTypeOptions"
				:treatment-types="treatmentTypes"
				:type="config.type"
				:updating="updating"
				:vuelidate="vuelidate"
			/>
		</template>

	</modal-dialog>
</template>

<script>
import modalContent from './modal-content';

export default {
	name: 'DataIntegrationDialog',
	components: { modalContent },
	props: {
		config: {
			type: Object,
			required: true
		},
		assessmentOptions: {
			type: Array,
			default: () => []
		},
		codes: {
			type: Array,
			default: () => []
		},
		codeOptions: {
			type: Array,
			default: () => []
		},
		codeTypeOptions: {
			type: Array,
			default: () => []
		},
		defaultRule: {
			type: Boolean,
			default: false
		},
		enterprises: {
			type: Array,
			default: () => []
		},
		enterpriseOptions: {
			type: Array,
			default: () => []
		},
		portalOptions: {
			type: Array,
			default: () => []
		},
		processing: {
			type: Boolean,
			default: false
		},
		roleAvailableOptions: {
			type: Array,
			default: () => []
		},
		roleOptions: {
			type: Array,
			default: () => []
		},
		treatmentTypeOptions: {
			type: Array,
			default: () => []
		},
		treatmentTypes: {
			type: Array,
			default: () => []
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		},
		warnings: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		displayWarning: false
	}),
	watch: {
		show (newValue) {
			if (!newValue) {
				this.vuelidate.$reset();
			}
		}
	}
};
</script>
