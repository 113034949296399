<template>
	<div>
		<form
			novalidate
			autocomplete="off"
			@submit.prevent="onSubmit"
		>
			<select-input
				v-model="form.adapter"
				:translate="false"
				:label="$t('addAdapterInstance.adapter')"
				:options="adaptersOptions"
				:invalid="$v.form.adapter.$error"
				required
				mandatory
			>
				<template v-if="$v.form.adapter.$error" #validations>
					<p v-if="!$v.form.adapter.required">
						{{$t('general.validation.mandatory')}}
					</p>
				</template>
			</select-input>
			<text-input
				v-model="form.name"
				:translate="false"
				:label="$t('addAdapterInstance.name')"
				:invalid="$v.form.name.$error"
				required
				mandatory
			>
				<template v-if="$v.form.name.$error" #validations>
					<p v-if="!$v.form.name.required">
						{{$t('general.validation.mandatory')}}
					</p>
				</template>
			</text-input>
			<text-input
				v-model="form.description"
				:translate="false"
				:label="$t('addAdapterInstance.description')"
				multiline
			/>

			<button-group align-right>
				<submit-button
					:can-submit="!processing"
					:label="$t('general.buttons.add')"
				/>
			</button-group>
		</form>
	</div>
</template>

<script>
import t from 'translate';
import cardUrl from 'cwcardurl';
import cardControls from 'core/engine/card/services/card-controls';
import { map } from 'lodash';
import cwalert from 'service/cwalert';
import { required } from 'vuelidate/lib/validators';
import { ADAPTER_INSTANCES } from 'store/adapters/adapter-instances';
import { ADAPTERS } from 'store/adapters/adapters';
import { SYSTEM } from 'service/acl/checkpoints';
import { ADD } from 'service/acl/access-levels';

export default {
	title: t('addAdapterInstance.title'),

	acl: [{
		checkpoint: SYSTEM.SETTINGS.ADAPTER_INSTANCES,
		op: ADD
	}],

	actions: [
		ADAPTERS.FETCH
	],

	data: () => ({
		form: {
			adapter: null,
			name: null,
			description: null
		},

		processing: false
	}),

	computed: {
		adaptersOptions: ({ $store }) =>
			map($store.getters[ADAPTERS.DATA], (adapter) => ({
				id: adapter.code,
				label: adapter.code
			})),

		submitData: ({ form }) => ({
			adapter: {
				code: form.adapter.id
			},
			name: form.name,
			description: form.description
		})
	},

	methods: {
		onSubmit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.processing = true;

				this.$store.dispatch(ADAPTER_INSTANCES.CREATE, this.submitData)
					.then((adapterInstance) => {
						this.processing = false;

						cwalert.success(this.$t('general.messages.create.success'));
						cardControls.closeCard({
							go: cardUrl.buildUrl(
								'adapter-instance',
								{ adapterInstanceId: adapterInstance.id }
							)
						});

					})
					.catch(() => {
						this.processing = false;
						cwalert.error(this.$t('general.messages.changes-not-saved'));
					});
			}
		}
	},

	validations: {
		form: {
			adapter: {
				required
			},

			name: {
				required
			}
		}
	}
};
</script>
