import editMixin from './_edit-mixin';

export default {

	name: 'editRespondent',
	mixins: [editMixin],
	props: {
		assessments: {
			type: Array,
			default: () => []
		},
		attributes: {
			type: Array,
			default: () => []
		},
		error: {
			type: String,
			default: ''
		},
		formData: {
			type: Object,
			required: true
		},
		itemLabel: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		mirror: {
			type: Boolean,
			default: false
		},
		respondentCustomAttributeHistory: {
			type: Boolean,
			default: false
		},
		v: {
			type: Object,
			required: true
		}
	},

	computed: {
		attributeDisabled () {
			if (this.mirror) {
				return true;
			}
			return !this.attributes.length && this.loading;
		}
	},

	template: `
		<span>
			<div v-if="respondentCustomAttributeHistory">
				<select-input
					:options="assessments"
					:disabled="mirror"
					classPrefix="tree-edit"
					label="Assessment"
					itemLabel="assessmentName"
					:icon="error"
					:mandatory="true"
					:invalid="v.formData.assessment.$error"
					:placeholder="translate('Select assessment')"
					v-model="formData.assessment"
				/>
			</div>
			<select-input
				:options="attributes"
				classPrefix="tree-edit"
				:label="label"
				:mandatory="true"
				:invalid="v.formData.identity.$error"
				:itemLabel="itemLabel"
				v-model="formData.identity"
				:loading="loading"
				:disabled="attributeDisabled"
				:placeholder="translate('Select attribute')"
			/>
		</span>
		`
};
