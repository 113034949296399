import { warning } from 'service/log/log';
import { forEach } from 'lodash';

export default function (collection, options = {}) {
	const __inst = this;

	const __fetchOptions = options.fetchParams ?
		options.fetchParams : {};

	const __models = {};

	const __eventCallbacks = {
		metadata: [],
		fetch: [],
		empty: []
	};

	this.on = function (eventType, callback) {
		if (typeof __eventCallbacks[eventType] === 'undefined') {
			throw `Invalid eventType ${eventType}`;

		} else {
			__eventCallbacks[eventType].push(callback);
		}

		return this;
	};

	this.off = function (eventType, callback) {
		if (typeof __eventCallbacks[eventType] === 'undefined') {
			throw `Invalid eventType ${eventType}`;

		} else {
			const idx = __eventCallbacks[eventType].indexOf(callback);

			if (idx !== -1) {
				delete __eventCallbacks[eventType][idx];
			}
		}

		return this;
	};

	const __callback = function (eventType, params) {
		forEach(__eventCallbacks[eventType], (item) => {
			if (typeof item === 'function') {
				item.call(__inst, params);
			}
		});
	};

	collection.on('remove', () => {
		if (collection.models.length === 0) {
			__callback('empty');
		}
	});

	const ReloadCallback = function (params) {

		const __reload = function (collection) {
			const result = {};
			const order = [];

			collection.each((model) => {
				const id = model.get(model.idAttribute);

				if (typeof id === 'undefined') {
					warning({
						msg: `Backbone model idAttribute "${model.idAttribute}" not found in the feed.`,
						type: 'FRONTEND ERROR',
						dump: { model }
					});
				}

				order.push(id);

				if (!__models[id]) {
					__models[id] = model;

				} else {
					__models[id].set(model.toJSON());
				}
				result[id] = __models[id];
			});

			if (params && (typeof params.success === 'function')) {
				params.success.call(__inst, {
					data: result,
					order
				});
			}
			__callback('fetch', {
				data: result,
				order
			});
		};

		this.reload = function (collection) {
			__reload(collection);
		};
	};

	this.reload = function () {
		new ReloadCallback().reload(collection);
	};

	const __refresh = function (params) {
		let reload;

		if (options.fetchType) {
			reload = new ReloadCallback(params).reload;
			collection.fetch({
				success (...args) {
					reload.apply(this, args);
				},

				metadata (metadata, rawdata) {
					__callback('metadata', { metadata, rawdata });
				},

				data: __fetchOptions,
				type: options.fetchType // i dont think this param is supported ...
			});

		} else {
			reload = new ReloadCallback(params).reload;
			collection.fetch({
				success (...args) {
					reload.apply(this, args);
				},

				metadata (metadata, rawdata) {
					__callback('metadata', {
						metadata,
						rawdata
					});
				},

				data: __fetchOptions
			});
		}

	};

	this.refresh = function (params) {
		__refresh(params);

		return this;
	};

	let __refreshTimer;
	/*
	 * Enables or disables auto refresh of the table.
	 *
	 * @param interval - Interval in miliseconds. False to disable auto refresh.
	 */
	this.setAutoRefresh = function (interval) {
		clearInterval(__refreshTimer);

		if (interval) {
			__refreshTimer = setInterval(__inst.refresh, interval);
		}

		return this;
	};

	this.setPage = function (page) {
		if (page !== false) {
			__fetchOptions.page = page;

		} else {
			delete __fetchOptions.page;
		}

		return this;
	};

	this.setOrder = function (order) {
		if (order !== false) {
			__fetchOptions.order = order;

		} else {
			delete __fetchOptions.order;
		}

		return this;
	};

	this.setQuery = function (query) {
		if (query !== false) {
			__fetchOptions.query = query;

		} else {
			delete __fetchOptions.query;
		}

		return this;
	};
}
