<template>
	<form
		novalidate
		autocomplete="off"
		class="form-view"
		@submit.prevent="onSubmit"
	>
		<module-form
			ref="module"
			v-model="module"
			@validate="onValidate"
		/>

		<button-group>
			<submit-button
				:can-submit="valid"
				:label="$t('general.buttons.add')"
			/>
		</button-group>
	</form>
</template>

<script>
import cwalert from 'service/cwalert';
import { error } from 'service/log/log';
import ModuleForm from './module-form';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';

export default {
	components: {
		ModuleForm
	},

	data: () => ({
		module: {
			name: ''
		},
		valid: false
	}),

	methods: {
		onValidate (valid) {
			this.valid = valid;
		},

		async onSubmit () {
			try {
				await this.$store.dispatch(
					TREATMENT_PROGRAM.ADD_MODULE,
					{ module: this.module }
				);

				this.$emit('success');

				cwalert.success(this.$t('general.messages.create.success'));
			} catch (err) {
				cwalert.error(this.$t('general.messages.changes-not-saved'));
				error(err);
			}
		}
	}
};
</script>
