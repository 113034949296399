var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-input", {
    attrs: {
      label: _vm.label,
      name: _vm.name,
      "no-drop": _vm.noDrop,
      "empty-search-dropdown": false,
      exceeded: _vm.exceeded,
      async: "",
      loading: _vm.loading,
      options: _vm.list,
      "get-option-label": _vm.clinicianLabel,
      disabled: _vm.disabled,
      "item-label": "clinicianId",
      placeholder: _vm.placeholder,
      required: _vm.required
    },
    on: {
      input: function($event) {
        return _vm.$v.clinician.$touch()
      },
      search: _vm.onInput
    },
    model: {
      value: _vm.clinician,
      callback: function($$v) {
        _vm.clinician = $$v
      },
      expression: "clinician"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }