<template>
	<select-input
		v-model="clinician"
		:label="label"
		:name="name"
		:no-drop="noDrop"
		:empty-search-dropdown="false"
		:exceeded="exceeded"
		async
		:loading="loading"
		:options="list"
		:get-option-label="clinicianLabel"
		:disabled="disabled"
		item-label="clinicianId"
		:placeholder="placeholder"
		:required="required"
		@input="$v.clinician.$touch()"
		@search="onInput"
	/>
</template>

<script>
import { required as requiredValidator } from 'vuelidate/lib/validators';
import { clinicianName } from 'service/display-name/display-name';
import { cloneDeep, debounce, identity, constant } from 'lodash';
import { CLINICIAN_SEARCH_AVAILABLE } from 'store/clinicians/clinician-search-available';
import { CLINICIAN_SEARCH } from 'store/clinicians/clinician-search';
import SelectInput from 'components/form/select-input';
import { BACKEND_PLAN_INSTANCE } from 'modules/plan-instance/constants';

export default {
	components: {
		SelectInput
	},
	props: {
		value: {
			type: [Number, String, Object, Array],
			default: ''
		},

		name: {
			type: String,
			default: constant('clinician')
		},

		searchLimit: {
			type: Number,
			default: 10
		},
		label: {
			type: String,
			default () {
				return this.$t('Clinician');
			}
		},
		placeholder: {
			type: String,
			default () {
				return this.$t('Type clinician name');
			}
		},
		required: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		delay: {
			type: Number,
			default: 500
		},
		respondentId: {
			type: [Number, String],
			default: ''
		},
		treatmentTypeId: {
			type: [Number, String],
			default: ''
		},
		assessmentInstanceId: {
			type: [Number, String],
			default: ''
		},
		planInstanceId: {
			type: [Number, String],
			default: ''
		},
		filterList: {
			type: Function,
			default: identity
		}
	},

	data: () => ({
		loading: false
	}),

	computed: {
		STORE_MODULE: ({ treatmentTypeId }) => treatmentTypeId ?
			CLINICIAN_SEARCH_AVAILABLE :
			CLINICIAN_SEARCH,

		exceeded: ({ $store, STORE_MODULE }) => $store.getters[STORE_MODULE.EXCEEDED],
		limit: ({ $store, STORE_MODULE }) => $store.getters[STORE_MODULE.LIMIT],
		clinicians: ({ $store, STORE_MODULE }) => $store.getters[STORE_MODULE.LIST],
		total: ({ $store, STORE_MODULE }) => $store.getters[STORE_MODULE.TOTAL],

		noDrop: ({ $v, loading }) => !$v.clinician.$dirty || loading,

		clinician: {
			get: ({ value }) => cloneDeep(value),
			set (newVal) {
				this.$emit('input', newVal);
			}
		},

		list: ({ exceeded, exceededLabel, clinicians, total, filterList }) => {
			const list = cloneDeep(clinicians);

			if (exceeded) {
				list.unshift({
					disabled: true,
					clinicianId: 0,
					clinicianUsername: exceededLabel(total - clinicians.length)
				});
			}
			return filterList(list);
		}
	},

	created () {
		this.$store.dispatch(this.STORE_MODULE.SET_LIMIT, this.searchLimit);
	},

	methods: {
		clinicianLabel: (clinician) => clinicianName(clinician, { preventEscape: true }),

		exceededLabel (amount) {
			return this.$t('{count} results omitted, try narrowing down search criteria', {
				count: amount
			});
		},

		onInput (search) {
			if (search.length) {
				this.$v.clinician.$touch();
				this.loading = true;
				debounce(this.getResults, this.delay)(search);

			} else {
				this.$v.clinician.$reset();
				this.$store.dispatch(this.STORE_MODULE.DELETE_LIST);
			}
		},

		async getResults (search) {
			await this.$store.dispatch(this.STORE_MODULE.PROVIDE_RESULTS, {
				respondentId: this.respondentId,
				treatmentTypeId: this.treatmentTypeId,
				assessmentInstance: this.assessmentInstanceId,
				[BACKEND_PLAN_INSTANCE]: this.planInstanceId,
				administratorAccountEnabled: true,
				limit: this.limit,
				search,
				start: 0
			});
			this.loading = false;
		}
	},

	validations () {
		return {
			clinician: {
				required: this.required ? requiredValidator : constant(true)
			}
		};
	}
};
</script>
