<template>
	<div>
		<form
			novalidate
			autocomplete="off"
			@submit.prevent="onSubmit"
		>
			<content-field
				:label="$t('editAdapterInstanceCapability.code')"
			>
				{{adapterCapability.systemAdapterCapabilityCode}}
			</content-field>
			<text-input
				v-model="form.name"
				:translate="false"
				:label="$t('editAdapterInstanceCapability.name')"
			/>
			<text-input
				v-model="form.description"
				:translate="false"
				:label="$t('editAdapterInstanceCapability.description')"
				multiline
			/>

			<checkbox-input
				v-model="form.enabled"
				:translate="false"
				:label="$t('editAdapterInstanceCapability.enabled')"
			/>

			<checkbox-input
				v-model="form.defaultRespondentEnabled"
				:translate="false"
				:label="$t('editAdapterInstanceCapability.defaultRespondentEnabled')"
			/>

			<checkbox-input
				v-model="form.respondentToggleEnabled"
				:translate="false"
				:label="$t('editAdapterInstanceCapability.allowRespondentToggle')"
			/>

			<button-group align-right>
				<submit-button
					:can-submit="!processing"
					:label="$t('general.buttons.save')"
				/>
			</button-group>
		</form>
	</div>
</template>

<script>
import t from 'translate';
import cardUrl from 'cwcardurl';
import cardControls from 'core/engine/card/services/card-controls';
import cwalert from 'service/cwalert';
import { ADAPTER_INSTANCE_CAPABILITY } from 'store/adapters/adapter-instance-capability';
import ContentField from 'components/form/content-field';
import { SYSTEM } from 'service/acl/checkpoints';
import { EDIT } from 'service/acl/access-levels';

export default {
	components: {
		ContentField
	},

	acl: [{
		checkpoint: SYSTEM.SETTINGS.ADAPTER_INSTANCES,
		op: EDIT
	}],

	title: t('editAdapterInstanceCapability.title'),

	actions: ({ $store }) => {
		const { capabilityId } = $store.getters.urlParams;

		return [
			[ADAPTER_INSTANCE_CAPABILITY.FETCH, { capabilityId }]
		];
	},

	data: ({ $store }) => {
		const capability = $store.getters[ADAPTER_INSTANCE_CAPABILITY.DATA];

		return {
			adapterCapability: capability.adapterCapability,

			form: {
				name: capability.name,
				description: capability.description,
				enabled: capability.enabled,
				defaultRespondentEnabled: capability.defaultRespondentEnabled,
				respondentToggleEnabled: capability.respondentToggleEnabled
			},

			processing: false
		};
	},

	methods: {
		onSubmit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.processing = true;

				this.$store.dispatch(ADAPTER_INSTANCE_CAPABILITY.UPDATE, {
					capabilityId: this.$store.getters.urlParams.capabilityId,
					data: this.form
				})
					.then(() => {
						const adapterInstanceId = this.$store.getters.urlParams.adapterInstanceId;

						this.processing = false;
						cwalert.success(this.$t('general.messages.update.success'));
						cardControls.closeCard({
							go: cardUrl.buildUrl(
								'adapter-instance',
								{ adapterInstanceId }
							)
						});
					})
					.catch(() => {
						this.processing = false;

						cwalert.error(this.$t('general.messages.changes-not-saved'));
					});
			}
		}
	},
	validations: {}
};
</script>
