import t from 'translate';
import eventHandlers from 'modules/respondent-management/shared/event-handlers-mixin';
import store from 'store';

const card = {
	name: 'respondent-assessments',
	url: 'respondent/:respondentId/assessments',
	title: t('respondent.assessments'),

	appEvents: {
		'respondent.delete respondent.lose-access' (respondent) {
			eventHandlers.closeCard({
				respondent,
				data: this.cardData,
				close: this.close,
				name: card.name
			});
		}
	},

	ctrl: ({ populate }) => {
		store.dispatch('treatment/resetId');
		populate();
	}
};

export { card as RespondentAssessments };
