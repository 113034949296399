import MultipleEntities from '../shared/multiple-entities';
import Treatment from './entity';
import urls from './urls';
import { assign, keys, omit } from 'lodash';

/**
 * Treatments Entity
 * @class Treatments
 * @extends MultipleEntities
 */
const MyTreatmentsEntity = MultipleEntities.extend({
	model: Treatment,
	urls,
	eventNamespace: 'treatment',

	// separated this from entities, as they have model sorting mechanism {order: 'name'}
	// which prevents my-treatments to display correctly backend sorted data
	search ({ filters, includes = {}, dataSources = [], sortBy = '' }) {
		const transform = (obj) => [
			keys(obj).forEach((key) => {
				if (!obj[key]) {
					delete obj[key];

				} else if (obj[key] === true) {
					obj[key] = 1;
				}
			}),
			obj
		][1];

		// b💩ckend doesn't allow these as includes
		const omitted = ['include[lastLogin]', 'dataSources', 'includes'];
		const initialParams = omit(assign(filters, includes), omitted);
		initialParams.timestamp = new Date().valueOf();

		return this.retrieve('myList', transform(initialParams), dataSources, sortBy);
	}
});

export default new MyTreatmentsEntity();
