/**
 * @class Repository
 */
export default {
	/**
	 * Get all Decisions.
	 *
	 * @function getDecisions
	 * @returns {object} - Decisions Collection.
	 * @example
	 *        repository.getDecisions();
	 */
	getDecisions () {
		return this.Decisions.getAll();
	},

	/**
	 * Get Decision by its id.
	 *
	 * @function decision
	 * @param  {number} decisionId - Decision ID.
	 * @returns {object}           - Decisions Collection.
	 * @example
	 * 		repository.decision(87);
	 */
	decision (decisionId) {
		return this.Decisions.getById(decisionId);
	},
	/**
	 * Get Decisions triggered by a Respondent.
	 *
	 * @function  respondentDecisions
	 * @param  {number} respondentId - Respondent ID.
	 * @returns {object}             - Decisions Collection.
	 * @example
	 * 		repository.respondentDecisions(19);
	 */
	respondentDecisions (respondentId) {
		return this.Decisions.getByRespondentId(respondentId);
	},

	/**
	 * Get a new collection of all Decisions assigned to a Clinician.
	 *
	 * @function getDecisionsForCurrentClinician
	 * @returns {object} - Decisions Collection.
	 * @example
	 *        repository.getDecisionsForCurrentClinician();
	 */
	getDecisionsForCurrentClinician () {
		return this.Decisions.getForCurrentClinician();
	},

	/**
	 * Get Decisions triggered in treatment.
	 *
	 * @function  treatmentDecisions
	 * @param  {number} treatmentId - Treatment ID.
	 * @returns {object}            - Decisions Collection.
	 * @example
	 * 		repository.treatmentDecisions(54);
	 */
	treatmentDecisions (treatmentId) {
		return this.Decisions.getByTreatmentId(treatmentId);
	}
};
