import $ from 'jquery';
import can from 'acl-can';
import cardurl from 'cwcardurl';
import store from 'store';
import t from 'translate';
import Table from 'components/table/table';
import TileView from 'tile-view';
import { get } from 'lodash';

export default TileView.extend({
	title: t('Treatment types'),
	acl: [{
		checkpoint: 'system.settings.treatment-types',
		op: 'read'
	}],

	actions: ['treatmentTypes/initAll'],

	loaded ({ tile }) {
		tile.table = new Table({
			parent: this.el,
			emptyListMessage: t('No treatment types'),
			items: store.getters['treatmentTypes/sortedByName'],
			rowsClickable: can.read('system.settings.treatment-types'),
			click (item) {
				cardurl.openCard('edit-treatment-type', { treatmentTypeId: item.id });
			},
			columns: [{
				label: t('Name'),
				key: 'name'
			}, {
				label: t('Description'),
				key: 'description'
			}, {
				className: 'treatment-type-list__isdefault',
				label: t('Is default'),
				key: 'isDefault',
				render () {
					const $input = $('<input type="checkbox" disabled="disabled">')
						.prop('checked', get(this.item, 'isDefault'));
					$(this.td).html($input);
				}
			}]
		});
	}
});
