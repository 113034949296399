import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import t from 'translate';
import can from 'acl-can';
import store from 'store';

export default TileView.extend({
	title: t('Information package properties'),
	acl: {
		op: 'read',
		checkpoint: 'administrator.respondents.informationpackages'
	},

	actions: () => ['contentPackageInstance/init', 'types/init', 'contexts/init'],

	loaded: ({ tile }) => {
		const cpInstance = store.state.contentPackageInstance.model;
		const types = store.state.types.data;
		const contexts = store.state.contexts.data;

		const cfg = {
			model: cpInstance,
			fields: [{
				label: t('Information package'),
				key: 'name',
				type: 'paragraph'
			}, {
				label: t('Treatment'),
				key: 'treatmentName',
				type: 'paragraph',
				value: cpInstance.get('')
			}, {
				label: t('Description'),
				key: 'informationPackageDescription',
				type: 'paragraph'
			}, {
				label: t('Status'),
				name: 'status',
				key: 'state',
				type: 'paragraph',
				transform: () => cpInstance.status()
			}, {
				label: t('Type'),
				key: 'type',
				type: 'select',
				values: types,
				labelField: 'label',
				setNullLabel: t('-- no type --')
			}, {
				label: t('Context'),
				key: 'context',
				type: 'select',
				values: contexts,
				keyField: 'assessmentInstanceContextId',
				setNullLabel: t('-- no context --'),
				labelField: 'assessmentInstanceContextLabel',
				getVal: (context) => context === 'null' ? null : context
			}, {
				label: t('Start date'),
				key: 'startAt',
				type: 'datetime',
				timezone: store.getters.respondent.respondentTimezoneName
			}],
			readonly: !can.edit('administrator.respondents.informationpackages'),
			keysNotSubmitted: ['data'],
			saveIndicator: true
		};

		tile.form = formView(cfg);
		tile.$el.append(tile.form.$el);
	}
});
