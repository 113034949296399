import celllabel from 'cell-label';
import t from 'translate';
import Backbone from 'backbone';
import $ from 'jquery';
import dom from 'cwdom';
import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import UnansweredMessages from '../../entities/report-unanswered-messages';
import appContext from 'app-context';
import { forEach } from 'lodash';

export default Backbone.View.extend({
	title: t('admin-portal.UnansweredReport'),
	acl: [{
		checkpoint: 'system.reports.unansweredmessages',
		op: 'OPEN'
	}],

	report (unansweredMessages) {

		$(this.table).empty();
		$(this.noItem).empty();

		if (!unansweredMessages.length) {
			this.noItem = dom.createElement({
				parent: this.el,
				tag: 'p',
				id: 'no-unanswered-message',
				role: 'alert',
				innerHTML: t('admin-portal.NoUnansweredMessage')
			});
			return;
		}

		// @TODO(2015-09-29): just rewrite it all
		this.table = dom.createElement({
			tag: 'table',
			id: 'report-table',
			css: 'cwtable  cwtable--modest',
			parent: this.el,
			attrs: {
				tabindex: 0
			}
		});
		const thead = dom.createElement({
			tag: 'thead',
			id: 'report-table-thead',
			parent: this.table
		});
		const thTr = dom.createElement({
			tag: 'tr',
			id: 'report-table-head-tr',
			css: 'report-table-head-tr',
			parent: thead
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('Id'),
			id: 'report-table-head-id',
			css: 'report-table-head-id',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.userName'),
			id: 'report-table-head-username',
			css: 'report-table-head-username',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.FirstName'),
			id: 'report-table-head-firstname',
			css: 'report-table-head-firstname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.LastName'),
			id: 'report-table-head-lastname',
			css: 'report-table-head-lastname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('system-manager.CreatedDate'),
			id: 'report-table-head-date',
			css: 'report-table-head-date',
			parent: thTr
		});
		this.tbody = dom.createElement({
			tag: 'tbody',
			parent: this.table
		});

		const tbody = this.tbody;

		forEach(unansweredMessages, (unansweredMessage) => {
			const tr = dom.createElement({
				tag: 'tr',
				parent: tbody
			});

			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: unansweredMessage.attributes.respondentId
			}), t('Id'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: unansweredMessage.attributes.respondentUsername
			}), t('general-list.userName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: unansweredMessage.attributes.respondentFirstName
			}), t('general-list.FirstName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: unansweredMessage.attributes.respondentLastName
			}), t('general-list.LastName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: datetime(unansweredMessage.attributes.messageCreatedDate)
					.setTimezone(appContext.get('user').get('timezoneName'))
					.toMoment()
					.format(t('date.formats.dateTime'))
			}), t('system-manager.CreatedDate'));

		}, this);
	},

	render () {
		this.renderDescription();

		const tile = this;
		let form = {};
		const reportModel = new (Backbone.Model.extend({
			sync () {
				return new UnansweredMessages([], {
					daySince: this.get('daySince')
				}).fetch({
					success (collection) {
						tile.report(collection.models);
						form.setLoaded();
					}
				});
			}
		}))({
			daySince: 7
		});

		form = formView({
			name: 'system-reports-unanswered-messages',
			model: reportModel,
			listenTo: ['submit'],
			saveIndicator: true,
			fields: [{
				key: 'daySince',
				name: 'daySince',
				label: t('admin-portal.ReportsNumberOfDays', 'Number of days:'),
				type: 'number',
				required: true,
				min: '0'
			}],
			buttons: [{
				caption: t('general-list.Search'),
				type: 'submit',
				name: 'search-submit'
			}]
		});

		this.$el.append(form.$el);
	},

	renderDescription () {
		const $description = $('<p class="text-content" />')
			.html(t('admin-portal.UnansweredMessagesIntroduction'));
		this.$el.prepend($description);
		return this;
	}
});

