import t from 'translate';
import ctrl from 'core/engine/card/ctrl-presets/add-instance';
import { generateUrls } from 'components/agenda-view/helpers';

const card = {
	name: 'add-content-package',
	title: t('Add content package'),
	url: generateUrls('add-content-package'),
	ctrl
};

export { card as addContentPackage };
