import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Message from './message';
import moment from 'moment';

/**
 * Messages Entity
 * @class Messages
 * @extends MultipleEntities
 */
const Messages = new (MultipleEntities.extend({
	model: Message,
	urls,
	eventNamespace: 'message',
	order: (message) => -1 * moment(message.get('sentAt')),

	/**
	 * Get a collection of all Messages for a given treatment and respondent
	 * @method getForTreatment
	 * @param {Object} {treatmentId, respondentId}
	 * @return {Object} new {{#crossLink "Messages"}}{{/crossLink}} Collection
	 * @example
	 * 		repository.Messages.getForTreatment({
	 * 			treatment: 7,
	 * 			respondentId: 19
	 * 		});
	 */
	getForTreatment ({ treatment, respondent }) {
		return this.retrieve('messages', { treatment, respondent });
	},

	/**
	 * Get a collection of all unreplied Messages for current clinician
	 * @method getUnreplied
	 * @return {Object} new {{#crossLink "Messages"}}{{/crossLink}} Collection
	 * @example
	 * 		repository.Messages.getUnreplied();
	 */
	getUnreplied () {
		return this.retrieve('unreplied');
	}
}))();

export { Messages };
