import ClinicianController from './controllers/clinicianController';
import ClinicianRouter from './controllers/clinicianRouter';
import * as cards from './cards/cards';

export default {
	action: () => new ClinicianRouter({
		controller: new ClinicianController()
	}),
	cards
};
