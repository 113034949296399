import Backbone from 'backbone';
import UnansweredAssessment from './report-overdue-assessment';

export default Backbone.Collection.extend({
	model: UnansweredAssessment,

	initialize: function (models, params) {
		this.url = 'backend-commons/administrator/rest/report/unanswered_assessment/' + params.daySince;
	}
});

