import cardUrl from 'cwcardurl';
import columnDefinition from './column-definition';
import dataSource from '../../components/datasource/datasource';
import filterComponent from 'components/filter/filter';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import viewModel from 'service/search/view-model';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { EMPTY_CHAR } from 'lib/chars';
import {
	assign, compact, debounce, filter, find, flow, findIndex, forEach, get, includes, isFunction,
	isUndefined, keys, map, reduce, without
} from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import sanitize from 'service/sanitize-string/sanitize-string';

/**
 * Convert an array of objects to a simple array.
 *
 * @example [{ prop1: true }, { prop2: false }, { prop3: true }] -> ['prop1', 'prop3']
 *
 * @param {Array} columnsList -Array of column objects.
 * @returns {Array} Simplified columns list.
 */
const columns = (columnsList) => reduce(columnsList, (memo, column) => {
	const columnName = keys(column)[0];
	column[columnName] && memo.push(columnName);
	return memo;
}, []);

const searchThreshold = (config = {}) => config.searchThreshold || 200;

export default TileView.extend({
	title: t('My assigned treatments'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}],
	instant: true,
	tileData: ({ tile }) => ({
		viewModel: viewModel(),
		customColumns: () => {
			const cfg = tile.config();
			const customColumns = filter(cfg.columns, { dataSource: true });

			return map(customColumns, (column) => {
				const { label, ref, type } = column;
				const index = findIndex(cfg.dataSources, { ref });

				return {
					label: cfg.translate ? t(label) : label,
					render: (customCalculationColumns) => {
						const data = find(customCalculationColumns, { label: index });

						return !isUndefined(data) ?
							dataSource({
								type,
								data,
								ruleSets: filter(cfg.ruleSets, { ref }),
								values: customCalculationColumns
							})().outerHTML :
							EMPTY_CHAR;
					}
				};
			});
		},

		configuredColumns: () => flow([
			columns,
			(cols) => without(cols, 'dataSource'),
			(cols) => map(cols, (column) => columnDefinition({ column, type: 'table' })),
			compact
		])(tile.config().columns),

		runQuery: debounce(() => {
			tile.setLoading();
			store.dispatch('treatment/myTreatments/search').then(() => {
				tile.setLoaded();
				store.dispatch('filter/setResults', {
					results: store.getters['treatment/myTreatments/results']
				});
			});
		}, searchThreshold(tile.config())),

		initSearchParams: () => {
			const include = {};
			const toBeIncluded = [
				'lastLogin',
				'messages',
				'decisions',
				'assessments',
				'exercises',
				'pages',
				'packages'
			];

			forEach(
				without(columns(tile.config().columns), 'dataSource'),
				(field) => {
					if (includes(toBeIncluded, field)) {
						include[`include[${field}]`] = 1;
					}
				}
			);

			store.dispatch('treatment/myTreatments/initSearchParams', {
				id: tile.title(),
				includes: include,
				dataSources: tile.config().dataSources,
				filters: {
					start: 0,
					limit: 10
				},
				sortBy: ''
			});
		},

		fields: ({ tile }) => {
			const additionalFields = flow([
				columns,
				(cols) => without(cols, 'dataSource'),
				(cols) => map(cols, (column) => columnDefinition({ column, type: 'filter' })),
				compact
			])(tile.config().columns);

			return [{
				key: 'filter[treatmentTypeId]',
				type: 'single-select',
				label: t('Treatment type'),
				values: map(store.getters['treatmentTypes/sortedByName'], (treatmentType) => ({
					id: treatmentType.id,
					label: treatmentType.name
				})),

				onChange: (value) => {
					store.dispatch('treatment/myTreatments/setFilter', {
						'filter[treatmentTypeId]': map(value, (val, key) =>
							val ? key : undefined).join()
					});
					tile.runQuery();
				}
			}, {
				key: 'filter[respondentGroupId]',
				type: 'single-select',
				label: t('Respondent group'),
				values: map(store.state.respondentGroups.data, (respondentGroup) => ({
					id: respondentGroup.respondentGroupId,
					label: respondentGroup.respondentGroupName
				})),

				onChange: (value) => {
					store.dispatch('treatment/myTreatments/setFilter', {
						'filter[respondentGroupId]': map(value, (val, key) =>
							val ? key : undefined).join()
					});
					tile.runQuery();
				}
			}, {
				key: 'filters',
				type: 'multi-select',
				label: t('Additional filters'),
				values: [...additionalFields],

				onChange: (value) => {
					store.dispatch('treatment/myTreatments/setFilter', value);
					tile.runQuery();
				}
			}];
		},

		filterDefaults: (tile) => ({
			onClear: () => {
				store.dispatch('treatment/myTreatments/resetFilter');
				tile.runQuery();
			},

			onApply: () => {
				tile.runQuery();
			},

			searchFields: [{
				key: 'search',
				type: 'search',
				label: t('Find'),
				startValue: store.state.filter.id === tile.title() ?
					get(store.state.filter.values['Find'], 'query') :
					'',
				onChange: (search) => {
					store.dispatch('treatment/myTreatments/setFilter', { search });
					tile.runQuery();
				}
			}]
		})
	}),

	loaded: ({ tile }) => {
		// filter component depends on tile object
		// it has to be done by manually creating a separate Vue instance
		const vm = new Vue({
			el: document.createElement('div'),
			store,
			data: {
				configuredColumns: tile.configuredColumns(tile),
				customColumns: tile.customColumns(tile),
				sortBy: {
					ascending: false,
					key: 'respondent.lastLogin' // default backend sort
				}
			},
			computed: {
				...mapGetters('treatment/myTreatments', ['treatments', 'isMore'])
			},
			created () {
				tile.initSearchParams();
				tile.runQuery();
			},
			methods: {
				renderCell ({ render, key }, treatment) {
					let content = '';

					if (isFunction(render)) {
						content = key ?
							render(treatment[key]) :
							render(treatment.customCalculationColumns);

					} else {
						content = sanitize(treatment[key]);
					}
					return content || EMPTY_CHAR;
				},

				openTreatment: (treatment) => {
					cardUrl.openCard('treatment', {
						respondentId: treatment.respondent.respondentId,
						treatmentId: treatment.id
					});
				},

				sort (item) {
					const key = item.sortBy ? item.sortBy : item.key;

					if (get(this.$data.sortBy, 'key') === key) {
						this.$data.sortBy.ascending = !this.$data.sortBy.ascending;
					} else {
						this.$data.sortBy.key = key;
						this.$data.sortBy.ascending = true;
					}

					this.$store.dispatch('treatment/myTreatments/setSortParam', this.$data.sortBy);
					tile.runQuery();
				},

				sortClass (item) {
					const key = item.sortBy ? item.sortBy : item.key;

					if (this.$data.sortBy.key === key) {
						return this.$data.sortBy.ascending ? 'icon--sort-asc' : 'icon--sort-desc';
					}

					return 'icon--sort';
				},

				loadMore () {
					tile.setLoading();
					this.$store.dispatch('treatment/myTreatments/loadMore').then(() => {
						tile.setLoaded();
					});
				}
			},
			template: `
				<section class="my-treatments">
					<p v-if="!treatments.length" v-translate>No results</p>
					<table v-else class="my-treatments__table">
						<thead>
							<tr>
								<th
									class="table-header--sortable"
									v-for="item in configuredColumns"
									@click="sort(item)"
									:class="item.className"
								>
									<span class="table-header__label">{{item.label}}</span>
									<i class="fa table-header__icon" :class="sortClass(item)"></i>
								</th>
								<th
									v-for="item in customColumns"
									:class="item.className"
								>{{item.label}}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="treatment in treatments"
								:key="treatment.id"
								class="clickable-row my-treatments__row"
								tabindex="0"
								@click="openTreatment(treatment)"
							>
								<template v-for="item in configuredColumns">
									<td :class="item.className">
										<p
											class="table-cell-label"
											:class="item.className"
										>{{item.label}}</p>
										<span v-html="renderCell(item, treatment)" />
									</td>
								</template>
								<template v-for="item in customColumns">
									<td :class="item.className">
										<p
											class="table-cell-label"
											:class="item.className"
										>{{item.label}}</p>
										<span v-html="renderCell(item, treatment)" />
									</td>
								</template>
							</tr>
						</tbody>
					</table>
					<div class="see-more-container">
						<button
							class="flat-button__button"
							v-if="isMore"
							v-translate
							@click="loadMore"
						>More results</button>
					</div>
				</section>

			`
		});

		tile.filter = filterComponent(assign(
			{ id: tile.title() },
			tile.filterDefaults(tile),
			{ filters: tile.fields({ tile }) }
		));
		tile.$el.append(tile.filter.$el);
		tile.$el.append(vm.$el);
	}
});
