import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import can from 'acl-can';
import repository from 'repository';

import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Edit information package'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.CONTENT_PACKAGES,
		op: READ
	}],
	cardData: () => ['contentPackage'],
	tileData: () => ({
		treatmentTypes: repository.treatmentTypes(),
		languages: repository.getEnabledLanguages(),
		categories: repository.contentPackageCategories()
	}),

	loaded: ({ data, tile }) => {
		const fields = [{
			key: 'name',
			type: 'text',
			label: t('Name'),
			required: true,
			mandatory: true
		}, {
			key: 'status',
			type: 'paragraph',
			label: t('Status'),
			transform: () => tile.contentPackage.getStatusLabel()
		}, {
			key: 'description',
			type: 'textarea',
			label: t('Description')
		}, {
			key: 'treatmentType',
			label: t('Restrict to treatment type'),
			setNullLabel: t('-- no treatment --'),
			type: 'select',
			collection: tile.treatmentTypes,
			keyField: 'id',
			labelField: 'name'
		}, {
			key: 'categories',
			type: 'multiselect',
			label: t('Category'),
			collection: tile.categories,
			title: t('content package')
		}];
		const disabled = !can.edit(SYSTEM.SETTINGS.CONTENT_PACKAGES);

		tile.form = formView({
			disabled,
			fields,
			saveIndicator: true,
			listenTo: ['submit'],
			model: data.contentPackage,
			successMessage: t('Information package changes have been saved'),
			onBeforeSave: () => {
				if (data.contentPackage.get('treatmentType') === '') {
					data.contentPackage.set('treatmentType', null);
				}
			},
			buttons: [{
				caption: t('Save'),
				name: 'save',
				type: 'submit',
				disabled
			}]
		});
		tile.$el.html(tile.form.$el);
	}
});
