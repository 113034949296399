import MultipleEntities from '../shared/multiple-entities';
import ElementInstance from './cp-instance-element';
import urls from './urls';

const ContentPackageInstanceElements = MultipleEntities.extend({
	model: ElementInstance,
	urls,
	eventNamespace: 'information-package-instance-element',

	getByContentPackageInstanceId (contentPackageInstanceId) {
		return this.retrieve('listElementInstances', contentPackageInstanceId);
	},

	exercisesWithRoles (params) {
		return this.retrieve('exercisesWithRoles', params);
	}
});

export default new ContentPackageInstanceElements();
