import t from 'translate';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';
import appBar from 'app-bar';
import cardUrl from 'cwcardurl';

const card = {
	title: t('adapterInstances.title'),
	name: 'adapter-instances',
	url: 'system/adapter-instances',

	ctrl: ({ populate }) => {
		can.add(SYSTEM.SETTINGS.ADAPTER_INSTANCES) && appBar.addButton({
			sourceCard: card.name,
			title: t('adapterInstances.add'),
			icon: 'add',
			click: () => cardUrl.openCard('add-adapter-instance')
		});

		populate();
	}
};

export { card as adapterInstances };
