import printPackageTitle from '../../helpers/package-title';
import $ from 'jquery';
import datetime from 'datetime';
import PackageManagement from './package-management';
import t from 'translate';

export default function (item, ul) {
	const $li = $('<li class="store-package" />').appendTo(ul);
	const $content = $('<div class="assessment-package-content" />')
		.html($('<strong class="assessment-package-header" />').text(printPackageTitle(item)))
		.appendTo($li);
	const $publisher = $('<span class="store-publisher" />').appendTo($content);
	const $version = $('<span class="store-version" />').appendTo($content);
	const $publishDate = $('<span class="store-publish-date" />').appendTo($content);
	const $actions = $('<div class="actions" />').appendTo($content);
	const $buttonsWrapper = $('<div class="btn-group action-button" />').appendTo($content);

	return new PackageManagement({
		item,
		$actions,
		$li,
		$button: $('<button tabindex="0"/>').appendTo($buttonsWrapper),
		setVersionLabel ({ version, published, publisher, release }) {
			const versionTxt = `${t('system-manager.PackageVersion')} ${version}.${release}`;
			$version
				.html(`<br />${versionTxt} `)
				.attr('title', `${versionTxt} ${t('system-manager.PackageInstall')}`);

			if (published) {
				const date = datetime(published)
					.toMoment()
					.format(t('date.formats.dateOnly'));
				$publishDate.html(` (${date})`);
			}

			if (publisher) {
				$publisher.html(`<br />${t('system-manager.Publisher')} ${publisher}`);
			}
		}
	});
}
