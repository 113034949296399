import cwalert from 'service/cwalert';
import store from 'store';
import t from 'translate';

export default ({ tile, type, action = null }) => () => {
	cwalert.success(t(`dataIntegration.${type}.messages.deleted`));
	tile.runQuery();

	if (action) {
		store.dispatch(action);
	}
};
