import TileView from 'tile-view';
import ClinicianLabelTile from './clinician-label-tile';

export default TileView.extend({
	instant: true,
	title: '',
	actions: (tile) => [['clinician/init', tile.cardContext().get('clinicianId')]],

	className: 'wide-tile',
	vueComponent: () => ({ ClinicianLabelTile })
});
