<template>
	<form
		class="form-view"
		novalidate
		@submit.prevent="submit"
	>
		<datetime-input
			v-model="form.startDate"
			:label="$t('general-list.StartDate')"
		/>
		<datetime-input
			v-model="form.endDate"
			:label="$t('general-list.EndDate')"
		/>
		<clinician-search
			v-model="form.clinician"
			:required="false"
		/>
		<respondent-search
			v-model="form.respondent"
			:required="false"
		/>
		<select-input
			v-model="form.types"
			:label="$t('system-logs.Type')"
			:options="eventTypes"
			multiple
		/>

		<button-group>
			<submit-button
				:label="$t('Export')"
			/>
		</button-group>
	</form>
</template>

<script>
import { get, map, filter, includes } from 'lodash';
import t from 'translate';
import ClinicianSearch from 'modules/clinician-management/components/clinician-search';
import ButtonGroup from 'components/form/button-group';
import { SYSTEM_EVENT_TYPES } from 'store/system-log/system-event-types';
import { SYSTEM } from 'service/acl/checkpoints';
import { READ } from 'service/acl/access-levels';
import { SYSTEM_EXPORTS } from 'store/system-exports/system-exports';

export default {
	title: t('system-exports-logs.card-title'),

	acl: [{
		checkpoint: SYSTEM.EXPORT.SYSTEM_EVENT_LOGS,
		op: READ
	}],

	components: {
		ClinicianSearch,
		ButtonGroup
	},

	actions: () => ([
		[SYSTEM_EVENT_TYPES.INIT]
	]),

	data: () => ({
		form: {
			startDate: null,
			endDate: null,
			clinician: null,
			respondent: null,
			types: []
		}
	}),

	computed: {
		eventTypes: ({ $store, form }) =>
			filter(
				$store.getters[SYSTEM_EVENT_TYPES.DATA],
				(type) => !includes(map(form.types, 'label'), type.label)
			),

		submitData: ({ form }) => ({
			clinicianId: get(form.clinician, 'administratorId', null),
			respondentId: get(form.respondent, 'respondentId', null),
			fromDateTime: form.startDate,
			toDateTime: form.endDate,
			logType: map(form.types, 'label')
		})
	},

	methods: {
		async submit () {
			const systemExport = await this.$store.dispatch(
				SYSTEM_EXPORTS.EXPORT_LOGS,
				this.submitData
			);
			window.location = systemExport;
		}
	}
};
</script>
