import t from 'translate';
import store from 'store';

const card = {
	title: t('Treatment plans'),
	name: 'treatment-plans',
	url: 'treatment/:treatmentId/plans',

	ctrl: () => store.dispatch('planInstances/init')
};

export { card as treatmentPlans };
