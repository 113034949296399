import t from 'translate';
import can from 'acl-can';
import appBar from 'app-bar';

const card = {
	title: t('Treatment types'),
	name: 'treatment-type',
	url: 'treatment-type/list',
	controller: function () {
		this.populate();

		if (can.add('system.settings.treatment-types')) {
			appBar.addCardLink(card.name, {
				card: 'add-treatment-type',
				title: t('Add treatment type'),
				icon: 'add'
			});
		}
	}
};

export { card as list };
