import Backbone from 'backbone';
import StorePackage from './package-store';
import Url from 'lib/url/url';

export default Backbone.Collection.extend({
	model: StorePackage,

	initialize: function (models, query) {
		this.url = new Url('backend-commons/assessment/rest/package/all', query);
	}
});
