import { messageDraftAttachment } from './urls';
import SingleEntity from '../shared/single-entity';
import fileUrl from 'service/file-url/file-url';

/**
 * MessageDraftAttachment entity
 *
 * @class MessageDraftAttachment
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: messageDraftAttachment.url,
	eventNamespace: 'message-draft-attachment',

	getUrl: function () {
		return fileUrl(this.get('url'));
	}
});
