import TileView from 'tile-view';
import { get } from 'lodash';
import datetime from 'datetime';
import cwalert from 'cwalert';
import t from 'translate';
import { clinicianName } from 'service/display-name/display-name';
import can from 'acl-can';

export default TileView.extend({
	acl: [],
	instant: true,

	Vue: (tile) => ({
		template: `
		<div>
			<div class="object-information__container object-information__content">
				<div class="object-information__element-container">
					<p 
						class="object-information__label-container" 
						v-translate
					>manual-input.properties.Question</p>
					<p class="object-information__value-container">{{decision.message}}</p>
				</div>
			</div>
			
			<div 
				v-if="decision.value" 
				class="object-information__container object-information__content"
			>
				<div class="object-information__element-container">
					<p 
						class="object-information__label-container" 
						v-translate
					>manual-input.properties.Answer</p>
					<p class="object-information__value-container">
						<strong  v-translate>{{answer}}</strong>
					</p>
				</div>
				<div class="object-information__element-container">
					<p 
						class="object-information__label-container"
						v-translate
					>manual-input.properties.responseDate</p>
					<p class="object-information__value-container">{{responseDate}}</p>
				</div>
				<div class="object-information__element-container">
					<p class="object-information__label-container" v-translate>Answered by</p>
					<p class="object-information__value-container">{{answeredBy}}</p>
				</div>
			</div>
			
			<div 
				v-else-if="canAnswer" 
				class="flat-button__buttons-container"
			>
				<button 
					class="flat-button__button flat-button__button--submit"
					@click="saveAnswer('yes')"
					v-translate
				>Yes</button>
				<button 
					class="flat-button__button"
					@click="saveAnswer('no')"
					v-translate
				>No</button>
			</div>
		</div>
		`,

		computed: {
			decision () {
				return get(this.$store.state, 'decision.data');
			},
			canAnswer () {
				return can.add('administrator.respondents.manualinputs');
			},
			answer () {
				return {
					yes: 'manual-inputs.answers.Yes',
					no: 'manual-inputs.answers.No'
				}[this.decision.value];
			},
			responseDate () {
				return datetime(this.decision.responseDate)
					.setTimezone(get(this.$store.getters, 'respondent.respondentTimezoneName'))
					.toMoment()
					.format(t('date.formats.dateTime'));
			},
			answeredBy () {
				return clinicianName(this.decision.administratorRespondent);
			}
		},

		methods: {
			saveAnswer (value) {
				tile.setDataLoading();
				this.$store.dispatch('decision/update', { value }).then(() => {
					tile.setDataLoaded();
					cwalert.success(t('manual-input.properties.saveSuccessful'));
				});
			}
		}
	})
});
