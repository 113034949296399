import { render, staticRenderFns } from "./edit-treatment.vue?vue&type=template&id=17bf7a97&"
import script from "./edit-treatment.vue?vue&type=script&lang=js&"
export * from "./edit-treatment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/hdd1/gitlab-runner/builds/e07c1ccc/1/frontend/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17bf7a97')) {
      api.createRecord('17bf7a97', component.options)
    } else {
      api.reload('17bf7a97', component.options)
    }
    module.hot.accept("./edit-treatment.vue?vue&type=template&id=17bf7a97&", function () {
      api.rerender('17bf7a97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/scripts/modules/export-template/components/edit-treatment.vue"
export default component.exports