import MultipleEntities from '../shared/multiple-entities';
import ReasonTemplate from './reason-template';
import urls from './urls';

/**
 * ReasonTemplates Entity
 * @class ReasonTemplates
 * @extends MultipleEntities
 */
const ReasonTemplates = MultipleEntities.extend({
	model: ReasonTemplate,
	urls,
	eventNamespace: 'reason-template',

	/**
	 * Get all ReasonTemplates
	 * @method getAll
	 * @return {Object} new {{#crossLink "ReasonTemplates"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.ReasonTemplates.getAll();
	 */
	getAll: function () {
		return this.retrieve('reasonTemplates');
	}
});

export default new ReasonTemplates();
