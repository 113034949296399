import { GET, POST, PUT, DELETE } from 'service/ajax/ajax';
import urls from './urls';
import Treatments from './entities';
import TreatmentClinicians from './clinician-entities';
import TreatmentRoles from './role-entities';
import TreatmentActivities from './activity-entities';
import TreatmentAttributes from './attribute-entities';
import MyTreatments from './my-treatments-entities';

/**
 * @class repository
 */
export default {
	/**
	 * Get all available Treatments.
	 *
	 * @function treatments
	 * @returns {object} Treatments.
	 * @example
	 *        repository.treatments();
	 */
	treatments: () => Treatments.getAll(),

	/**
	 * Get all Treatments for a given Respondent, no matter which role.
	 *
	 * @param {Integer|string} respondentId - Respondent Id.
	 * @function respondentTreatments
	 * @returns {object} New Treatments Collection.
	 * @example
	 *        repository.respondentTreatments(19);
	 */
	respondentTreatments: (respondentId) => Treatments.getByRespondentId(respondentId),

	/**
	 * Get treatment for respondent portal.
	 *
	 * @param {number} treatmentId - Treatment Id.
	 * @returns {object} Treatment.
	 * @example
	 *        repository.respondentTreatment(8);
	 */
	respondentTreatment: (treatmentId) => GET({
		url: urls.singleTreatment(treatmentId)
	}),

	/**
	 * Get all Treatments for a given Respondent, with role primary respondent.
	 *
	 * @param {Integer|string} respondentId - Respondent Id.
	 * @function mainRespondentTreatments
	 * @returns {object} New Treatments Collection.
	 * @example
	 *        repository.mainRespondentTreatments(19);
	 */
	mainRespondentTreatments: (respondentId) => Treatments.getByMainRespondentId(respondentId),

	/**
	 * Get Treatment.
	 *
	 * @deprecated
	 * @function getTreatment
	 * @param {number|string} treatmentId - Treatment id.
	 * @returns {object} Treatment.
	 * @example
	 *        repository.getTreatment(8);
	 */
	getTreatment: (treatmentId) => Treatments.getById(treatmentId),

	/**
	 * Get Treatment.
	 *
	 * @function treatment
	 * @param {number|string} treatmentId - Treatment Id.
	 * @returns {object} Treatment.
	 * @example
	 *        repository.treatment(8);
	 */
	treatment: (treatmentId) => Treatments.getById(treatmentId),

	/**
	 * Remove Treatment.
	 *
	 * @function treatment
	 * @param {number|string} treatmentId - Treatment Id.
	 * @example
	 *        repository.removeTreatment(8);
	 */
	removeTreatment: (treatmentId) => DELETE({
		url: urls.removeTreatment.url(treatmentId)
	}),

	/**
	 * Get TreatmentClinicians by their Treatment id.
	 *
	 * @function getTreatmentClinicians
	 * @param {number|string} treatmentId - Treatment Id.
	 * @returns {object} TreatmentClinicians.
	 * @example
	 *        repository.getTreatmentClinicians(2);
	 */
	getTreatmentClinicians: (treatmentId) => TreatmentClinicians.getByTreatmentId(treatmentId),

	/**
	 * Get TreatmentRoles by their Treatment id.
	 *
	 * @function getTreatmentRoles
	 * @param {number|string} treatmentId - Treatment Id.
	 * @returns {object} TreatmentClinicians.
	 * @example
	 *        repository.getTreatmentRoles(2);
	 */
	getTreatmentRoles: (treatmentId) => TreatmentRoles.getByTreatment(treatmentId),

	/**
	 * Get roles assigned to a Treatmeent.
	 *
	 * @param {number|string} treatmentId - Treatment Id.
	 * @param {Array} dataSources - DataSources array.
	 * @returns {object} TreatmentRoles.
	 * @example
	 *        repository.getTreatmentAssignedRoles(2);
	 */
	getTreatmentAssignedRoles: (treatmentId, dataSources) =>
		TreatmentRoles.getAssignedRolesByTreatment(treatmentId, dataSources),

	/**
	 * Get TreatmentWithoutRoles by their Treatment id.
	 *
	 * @function getTreatmentWithoutRoles
	 * @param {number|string} treatmentId - Treatment Id.
	 * @param {Array} dataSources - DataSources array.
	 * @returns {object} TreatmentRoles.
	 * @example
	 *        repository.getTreatmentWithoutRoles(2);
	 */
	getTreatmentUnassignedRoles: (treatmentId, dataSources) =>
		TreatmentRoles.getUnassignedRolesByTreatment(treatmentId, dataSources),

	/**
	 * Get new collection of Treatments which match search params.
	 *
	 * @function searchTreatments
	 * @param {object} params - Search params.
	 * @param {object} opts - Options.
	 * @returns {object} New Treatments Collection.
	 * @example
	 *        repository.searchTreatments({
	 * 			query: 'def',
	 * 			limit: 10,
	 * 			start: 0
	 * 		});
	 */
	searchTreatments: (params, opts) => Treatments.search(params, opts),

	/**
	 * Terminate all plans assigned to a treatment.
	 *
	 * @function terminateTreatmentPlans
	 * @param {number|string} treatmentId - Treatment Id.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.terminateTreatmentPlans(2);
	 */
	terminateTreatmentPlans: (treatmentId) => GET({ url: urls.terminatePlans.url(treatmentId) }),

	/**
	 * Get treatments that currently logged-in clinician is assigned to.
	 *
	 * @function myTreatments
	 * @param {object} params - Filtering parameters.
	 * @returns {object} New Treatments Collection.
	 * @example
	 *        repository.searchMyTreatments({
	 *            filter[respondentGroup]: 1,
	 *            filter[haveOverdueAssessments]: true
	 *        });
	 */
	searchMyTreatments: (params) => MyTreatments.search(params),

	/**
	 * Get treatment activity log.
	 *
	 * @function treatmentActivityLog
	 * @param {object} params - Filtering parameters.
	 * @returns {object} - New Treatment Activities Collection.
	 * @example
	 *        repository.treatmentActivityLog({
	 *        	actor: ['system', 'respondent'],
	 *        	event: ['assessment-reached],
	 *        	start,
	 *        	limit
	 * 		});
	 */
	treatmentActivityLog: (params) => TreatmentActivities.list(params),

	/**
	 * Get attributes values for a treatment.
	 *
	 * @param {string|number} treatmentId - Treatment Id.
	 * @returns {object} TreatmentAttributes.
	 * @example
	 *        repository.treatmentAttributesValues(2);
	 */
	treatmentAttributesValues: (treatmentId) => TreatmentAttributes.getByTreatment(treatmentId),

	/**
	 * Get all Denied Clinicians for the given treatmentId.
	 *
	 * @function getDeniedClinicians
	 * @param {object} params -                     Parameters.
	 * @param {number|string} params.treatmentId -  Treatment ID.
	 * @returns {object} -                          Promise.
	 * @example
	 *        repository.getDeniedClinicians({
				treatmentId: 5
			});
	 */
	treatmentDenied: ({ treatmentId }) => GET ({
		url: urls.deniedCliniciansInTreatment.url(treatmentId)
	}),

	/**
	 * Add Clinician to denied list for the given treatmentId.
	 *
	 * @function addDeniedClinician
	 * @param {object} params -                       Parameters.
	 * @param {number|string} params.treatmentId -    Treatment ID.
 	 * @param {object} params.treatmentAccessDenial - Treatment Access Denial.
	 * @returns {object}                            - Promise.
	 * @example
	 *        repository.addDeniedClinician({
                {
                  "administrator": {
                    "id": 0
                  },
                  "respondent": {
                    "id": 0
                  }
                }
              });
	 */
	addTreatmentDenied: ({ treatmentId, treatmentAccessDenial }) => POST ({
		url: urls.deniedCliniciansInTreatment.url(treatmentId),
		data: treatmentAccessDenial
	}),

	/**
	 * Remove Clinician from denied list for the given treatmentId.
	 *
	 * @function removeDeniedClinician
	 * @param {object} params -                       Parameters.
	 * @param {number|string} params.treatmentId -    Treatment ID.
	 * @param {number|string} params.denialId -       Denial ID.
	 * @returns {object}                            - Promise.
	 * @example
	 *        repository.removeDeniedClinician({
				treatmentId: 1337,
				denialId: 1
			});
	 */
	removeTreatmentDenied: ({ treatmentId, denialId }) => DELETE ({
		url: urls.deniedClinicianInTreatment.url({ denialId, treatmentId })
	}),

	/**
	 * Get consent instances for treatment.
	 *
	 * @function treatmentConsent
	 * @param {object} params -                    Parameters.
	 * @param {number|string} params.treatmentId - Treatment ID.
	 * @returns {object}                         - Promise.
	 * @example
	 *        repository.treatmentConsent({
	 *            treatmentId: 69
	 *        });
	 */
	treatmentConsents: ({ treatmentId }) => GET({
		url: urls.consentsInTreatment.url({ treatmentId })
	}),

	/**
	 * Get list all consent templates available to assign in treatment for administrator.
	 *
	 * @function treatmentConsentTemplates
	 * @param {object} params -                     Parameters.
	 * @param {number|string} params.treatmentId -  Treatment ID.
	 * @param {number|string} params.respondentId - Respondent ID.
	 * @returns {object}                          - Promise.
	 * @example
	 *        repository.treatmentConsents({
	 *            treatmentId: 69,
	 *            respondentId: 19
	 *        });
	 */
	treatmentConsentTemplates: ({ treatmentId, respondentId }) => GET ({
		url: urls.treatmentConsentTemplates.url({ treatmentId, respondentId })
	}),

	/**
	 * Fetch treatment program by ID.
	 *
	 * @param {number} treatmentProgramId - Treatment program ID.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.treatmentProgram(1);
	 */
	treatmentProgram: (treatmentProgramId) => GET({
		url: urls.treatmentProgram(treatmentProgramId)
	}),

	/**
	 * Create treatment program.
	 *
	 * @param {object} treatmentProgram - New treatment program.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.createTreatmentProgram({ name: 'treatment program name' });
	 */
	createTreatmentProgram: (treatmentProgram) => POST({
		url: urls.treatmentPrograms(),
		data: treatmentProgram
	}),

	/**
	 * Update treatment program.
	 *
	 * @param {number} treatmentProgramId - ID of treatment program.
	 * @param {object} treatmentProgram - Updated treatment program.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.updateTreatmentProgram(1, { name: 'treatment program name' });
	 */
	updateTreatmentProgram: (treatmentProgramId, treatmentProgram) => PUT({
		url: urls.treatmentProgram(treatmentProgramId),
		data: treatmentProgram
	}),

	/**
	 * Update treatment program module.
	 *
	 * @param {object} params - Parameters.
	 * @param {number} params.treatmentProgramId - ID of treatment program.
	 * @param {number} params.moduleId - ID of treatment program module.
	 * @param {Array} params.module - Treatment program module.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.updateTreatmentProgramModule({
	 *			 	treatmentProgramId: 1,
	 *				moduleId: 1,
	 *				module: { name: 'module 1' }
	 *		  });
	 */
	updateTreatmentProgramModule: ({ treatmentProgramId, moduleId, module }) => PUT({
		url: urls.treatmentProgramModule(treatmentProgramId, moduleId),
		data: module
	})
};
