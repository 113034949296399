import { get, map } from 'lodash';
import t from 'translate';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import cardUrl from 'cwcardurl';
import datetime from 'datetime';
import store from 'store';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { SUPERVISOR } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { clinicianName } from 'service/display-name/display-name';

export default SearchTileView.extend({
	title: t('Abuse reports'),
	acl: [{
		checkpoint: CLINICIAN.CIRCLES.CIRCLES,
		op: SUPERVISOR
	}],
	features: enableCirclesCheck,

	searchFn: (...args) => {
		const collection = repository.searchAbuseReports(...args);
		collection.then(() => {
			store.dispatch('circles/abuseReports/add',
				{ collection, reports: collection.toJSON() });
		});

		return collection;
	},

	fields: ({ tile }) => [{
		key: 'isResolved',
		type: 'single-select',
		label: t('Report status'),

		values: [{
			id: 'true',
			label: t('Resolved')
		}, {
			id: 'false',
			label: t('Unresolved')
		}],

		onChange: (value) => {
			tile.viewModel.set('isResolved', map(value, (val, key) => {
				if (val === true) {
					return key;
				}
				return null;
			}).toString());
			tile.runQuery();
		}
	}, {
		key: 'circleUser.isBanned',
		type: 'checkbox',
		label: t('Banned'),

		onChange: (value) => {
			tile.viewModel.set('circleUser.isBanned', value ? value : null);
			tile.runQuery();
		}
	}],

	tableCfg: () => ({
		click: (model) => {
			cardUrl.openCard('circle-abuse-report', {
				circleAbuseReportId: model.getId()
			});
		},
		columns: [{
			label: t('Reported user'),
			key: 'circleUser',
			render: (circleUser) => circleUser ? circleUser.nickname : ''
		}, {
			label: t('Reported date'),
			key: 'createdAt',
			render: (date) => datetime(date)
				.setTimezone(store.state.user.timezoneName)
				.toMoment()
				.format(t('date.formats.dateTime'))
		}, {
			label: t('Reported by'),
			key: 'reportedBy',
			render: (reportedBy) => reportedBy ? reportedBy.nickname : ''
		}, {
			label: t('Circle name'),
			key: 'circle',
			render: (circle) => circle ? circle.name : ''
		}, {
			label: t('Owner'),
			key: 'circle',
			render: (circle) => (circle && circle.owner) ? circle.owner.nickname : ''
		}, {
			label: t('Members'),
			key: 'circle',
			render: (circle) => circle ? circle.membersCount : ''
		}, {
			label: t('Status'),
			key: 'status',
			render: (status) => get(status, 'label')
		}, {
			label: t('Last modified by'),
			key: 'lastModifiedByClinician',
			render: (value) => clinicianName(value)
		}]
	})
});
