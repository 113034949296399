var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "shortcut__group-container children-count" },
    _vm._l(_vm.config, function(list, listIndex) {
      return _c("span", { key: listIndex }, [
        _vm.displayGroup(list)
          ? _c(
              "div",
              { staticClass: "shortcut__group-container children-count" },
              [
                _c("h1", { staticClass: "shortcut__group-container-title" }, [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.t(list.groupTitle)) + "\n\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _vm._l(list.shortcuts, function(shortcut, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _vm.displayItem(shortcut.card)
                        ? _c("shortcut-item", { attrs: { item: shortcut } })
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }