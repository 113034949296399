import MultipleEntities from '../shared/multiple-entities';
import RoleCheckpoint from './access-role-checkpoint';
import urls from './urls';

/**
 * RoleCheckpoints Entity
 * @class RoleCheckpoints
 * @extends MultipleEntities
 */
const RoleCheckpoints = MultipleEntities.extend({
	model: RoleCheckpoint,
	urls,
	eventNamespace: 'role-checkpoint',
	idName: 'relationId',

	canAdd: function (model) {
		return +model.get('roleId') === +this.ownerId;
	},

	/**
	 * Get all RoleCheckpoints for the given roleId
	 * @method getForRole
	 * @param {Number|String} roleId
	 * @return {Object} new RoleCheckpoints Collection
	 * @example
	 *        Repository.RoleCheckpoints.getForRole(3);
	 */
	getForRole: function (roleId) {
		return this.retrieve('checkpointsInRole', roleId);
	}

});

export default new RoleCheckpoints();
