var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.save($event)
        }
      }
    },
    [
      _c(
        "fieldset",
        [
          _c(
            "legend",
            { staticClass: "form-view__fieldset__caption template-details" },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.t("Template details")) + "\n\t\t")]
          ),
          _vm._v(" "),
          _c("text-input", {
            attrs: {
              label: _vm.t("Export template name"),
              disabled: !_vm.canEdit
            },
            on: { input: _vm.updateName },
            model: {
              value: _vm.templateData.dataExportTemplateName,
              callback: function($$v) {
                _vm.$set(_vm.templateData, "dataExportTemplateName", $$v)
              },
              expression: "templateData.dataExportTemplateName"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              "container-class": "select-container",
              label: "File format",
              disabled: !_vm.canEdit,
              options: _vm.formats,
              placeholder: _vm.t("Select file format"),
              searchable: false
            },
            model: {
              value: _vm.format,
              callback: function($$v) {
                _vm.format = $$v
              },
              expression: "format"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              "class-prefix": "export-template",
              "container-class": "select-container",
              label: "Template language",
              disabled: !_vm.canEdit,
              options: _vm.languageOptions,
              placeholder: _vm.t("Select language"),
              searchable: false
            },
            model: {
              value: _vm.language,
              callback: function($$v) {
                _vm.language = $$v
              },
              expression: "language"
            }
          }),
          _vm._v(" "),
          _c("checkbox-input", {
            attrs: {
              "class-prefix": "export-template",
              disabled: !_vm.canEdit,
              label: _vm.t("Public"),
              translate: false
            },
            on: { input: _vm.save },
            model: {
              value: _vm.templateData.dataExportTemplateIsPublic,
              callback: function($$v) {
                _vm.$set(_vm.templateData, "dataExportTemplateIsPublic", $$v)
              },
              expression: "templateData.dataExportTemplateIsPublic"
            }
          }),
          _vm._v(" "),
          _c("checkbox-input", {
            attrs: {
              "class-prefix": "export-template",
              disabled: !_vm.canEdit,
              label: _vm.t("Available in API"),
              translate: false
            },
            on: { input: _vm.save },
            model: {
              value: _vm.templateData.dataExportTemplateIsAvailableApi,
              callback: function($$v) {
                _vm.$set(
                  _vm.templateData,
                  "dataExportTemplateIsAvailableApi",
                  $$v
                )
              },
              expression: "templateData.dataExportTemplateIsAvailableApi"
            }
          }),
          _vm._v(" "),
          _c("checkbox-input", {
            attrs: {
              "class-prefix": "export-template",
              disabled: !_vm.canEdit,
              label: _vm.t("Allow override"),
              translate: false
            },
            on: { input: _vm.save },
            model: {
              value: _vm.templateData.dataExportTemplateAllowOverride,
              callback: function($$v) {
                _vm.$set(
                  _vm.templateData,
                  "dataExportTemplateAllowOverride",
                  $$v
                )
              },
              expression: "templateData.dataExportTemplateAllowOverride"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            {
              staticClass:
                "export-template__legend form-view__fieldset__caption template-details"
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.t("Template filters")) + "\n\t\t")]
          ),
          _vm._v(" "),
          _c("checkbox-input", {
            attrs: {
              "class-prefix": "export-template",
              disabled: !_vm.canEdit,
              label: _vm.t("Only submitted assessments"),
              translate: false
            },
            on: { input: _vm.save },
            model: {
              value: _vm.templateData.dataExportTemplateIsSubmittedOnly,
              callback: function($$v) {
                _vm.$set(
                  _vm.templateData,
                  "dataExportTemplateIsSubmittedOnly",
                  $$v
                )
              },
              expression: "templateData.dataExportTemplateIsSubmittedOnly"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              "class-prefix": "export-template",
              "container-class": "select-container",
              label: "Date range",
              disabled: !_vm.canEdit,
              options: _vm.rangeOptions,
              placeholder: _vm.t("Select date range"),
              searchable: false,
              clearable: false
            },
            model: {
              value: _vm.range,
              callback: function($$v) {
                _vm.range = $$v
              },
              expression: "range"
            }
          }),
          _vm._v(" "),
          _vm.range && _vm.range.value === "CUSTOM"
            ? [
                _c("datetime-input", {
                  attrs: { label: "From", type: "date" },
                  model: {
                    value: _vm.rangeStart,
                    callback: function($$v) {
                      _vm.rangeStart = $$v
                    },
                    expression: "rangeStart"
                  }
                }),
                _vm._v(" "),
                _c("datetime-input", {
                  attrs: { label: "To", type: "date" },
                  model: {
                    value: _vm.rangeStop,
                    callback: function($$v) {
                      _vm.rangeStop = $$v
                    },
                    expression: "rangeStop"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c(
            "legend",
            {
              staticClass:
                "export-template__legend form-view__fieldset__caption template-details"
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.t("Required consent options")) +
                  "\n\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              "class-prefix": "export-template",
              "container-class": "select-container",
              label: "Consent Template",
              disabled: !_vm.canEdit,
              options: _vm.consents,
              placeholder: _vm.t("Choose a consent template"),
              searchable: false
            },
            model: {
              value: _vm.consentTemplate,
              callback: function($$v) {
                _vm.consentTemplate = $$v
              },
              expression: "consentTemplate"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              "container-class": "select-container",
              label: "Consent option",
              disabled: !_vm.canEdit || !_vm.consentTemplate,
              options: _vm.consentOptions,
              placeholder: _vm.t("Choose a consent option"),
              searchable: false
            },
            model: {
              value: _vm.consentOption,
              callback: function($$v) {
                _vm.consentOption = $$v
              },
              expression: "consentOption"
            }
          }),
          _vm._v(" "),
          _vm.templateConsents.length
            ? _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.t("consents.name")) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.t("Consent option")) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _vm.canDelete
                      ? _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.t("Delete")) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.templateConsents, function(item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(_vm._s(item.consentName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _vm.canDelete
                        ? _c("td", [
                            _c("button", {
                              staticClass:
                                "icon-button__button icon icon--delete",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.deleteOption(item.id)
                                }
                              }
                            })
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ])
            : _c("div", { staticClass: "export-template__no-option" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.t("No consent option")) + "\n\t\t"
                )
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }