import t from 'translate';

const card = {
	title: t('Template details'),
	name: 'system-exports-templates-details',
	url: 'system-exports/templates-details/id=:id',

	controller (id) {
		this.cardContext().set('dataExportInstanceId', id);
		this.populate();
	}
};

export { card as templatesDetails };
