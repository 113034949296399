import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RespondentField from './respondent-field';

const RespondentFields = MultipleEntities.extend({
	urls,
	idName: 'dataExportTemplateColumnRespondentFieldId',
	eventNamespace: 'export-template-respondent-field',
	model: RespondentField,

	getAll () {
		return this.retrieve('respondentFields');
	}
});

export default new RespondentFields();
