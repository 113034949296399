import { View } from 'backbone';
import Vue from 'vue';
import store from 'store';
import t from 'translate';
import can from 'acl-can';

export default View.extend({
	clear () {
		this.$el.empty();
		return this;
	},

	render () {
		const vm = new Vue({
			el: document.createElement('div'),
			store,
			data: () => ({
				canAdd: can.edit('system.workflows.workflows'),
				canRemove: can.edit('system.workflows.workflows'),
				fields: ['name', 'code'],
				labels: {
					empty: t('-editor.trigger.expired-assessments.no-assessment-selected'),
					name: t('plan-editor.trigger.expired-assessments.column.assessment-name'),
					code: t('plan-editor.trigger.expired-assessments.column.assessment-code'),
					removeItem: t('Remove assessment'),
					removeQuestion: t('Are you sure you wish to remove assessment?'),
					removeSuccess: t('Assessment removed successfully'),
					selectItem: t('plan-editor.trigger.expired-assessments.add-prompt'),
					addSuccess: t('Assessment added')
				},
				getters: {
					allItems: 'assessments/enabled',
					items: 'plan/expiredAssessments'
				},
				actions: {
					removeItem: 'plan/removeExpiredAssessment',
					addItem: 'plan/addExpiredAssessment'
				}
			}),
			template: `
				<assign-items 
					:can-remove="canRemove"
					:fields="fields"
					:labels="labels"
					:getters="getters"
					:actions="actions"
				/>
			`
		});

		this.$el.append(vm.$el);
	}
});
