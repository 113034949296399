var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.card
    ? _c("div", { staticClass: "setup-card" }, [
        _c("section", { staticClass: "setup-card__label" }, [
          _c("h3", { staticClass: "setup-card__subheading" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.t("Portal")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "setup-card__heading" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.t(_vm.portalName)) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c(
            "h3",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "setup-card__subheading"
            },
            [_vm._v("\n\t\t\tCard\n\t\t")]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "setup-card__heading" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.card.name) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "setup-card__subheading" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.t("Features")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "setup-card__heading" },
            _vm._l(_vm.card.features, function(feature) {
              return _c("span", { key: feature }, [_vm._v(_vm._s(feature))])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "setup-card__variant" }, [
          _c(
            "h2",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "setup-card__heading"
            },
            [_vm._v("\n\t\t\tVariant\n\t\t")]
          ),
          _vm._v(" "),
          _vm.card.variants
            ? _c("section", [
                _c(
                  "div",
                  { staticClass: "variant" },
                  [
                    _c("label", { staticClass: "variant__label-container" }, [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ],
                          staticClass: "variant__label-text"
                        },
                        [_vm._v("Variant name")]
                      ),
                      _vm._v(" "),
                      _vm.editVariantName
                        ? _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.changeVariantName($event)
                                }
                              }
                            },
                            [
                              _c("input", {
                                ref: "variantName",
                                staticClass: "variant__text",
                                attrs: { type: "text" },
                                domProps: { value: _vm.variantName }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "variant__button--save",
                                  attrs: { type: "submit" }
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "check", small: true }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.editVariant($event)
                                }
                              }
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedVariant,
                                      expression: "selectedVariant"
                                    }
                                  ],
                                  staticClass: "variant__select",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedVariant = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "null" } }, [
                                    _vm._v(_vm._s(_vm.card.variantName))
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.card.alternativeCards, function(
                                    variant,
                                    index
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: index }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(variant.name) +
                                            "\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "variant__button--edit",
                                  attrs: {
                                    type: "submit",
                                    title: _vm.t("Change variant name")
                                  }
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "edit", small: true }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.selectedVariant !== null
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "variant__button--remove",
                                      attrs: {
                                        type: "button",
                                        title: _vm.t("Remove variant")
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeVariant($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "delete", small: true }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                    ]),
                    _vm._v(" "),
                    _vm.selectedVariant !== null
                      ? _c(
                          "h3",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass: "variant__criteria"
                          },
                          [_vm._v("Variant selection criteria")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.criteria, function(value, criterion) {
                      return _c(
                        "div",
                        { key: criterion, staticClass: "criterion" },
                        [
                          _c(
                            "label",
                            { staticClass: "criterion__label-container" },
                            [
                              _c(
                                "p",
                                { staticClass: "criterion__label-text" },
                                [_vm._v(_vm._s(criterion))]
                              ),
                              _vm._v(" "),
                              _vm.notNone(criterion)
                                ? _c("input", {
                                    staticClass: "criterion__text",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.toString(value) },
                                    on: {
                                      input: function($event) {
                                        return _vm.setCriterion(
                                          criterion,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isTreatmentType
                            ? _c("div", [
                                _c(
                                  "label",
                                  { staticClass: "criterion__label-container" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "translate",
                                            rawName: "v-translate"
                                          }
                                        ],
                                        staticClass: "criterion__label-text"
                                      },
                                      [_vm._v("Available treatment types")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selectedTT,
                                            expression: "selectedTT"
                                          }
                                        ],
                                        staticClass: "criterion__select",
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.selectedTT = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.availableTreatmentTypes,
                                          function(tt) {
                                            return _c(
                                              "option",
                                              {
                                                key: tt.id,
                                                domProps: { value: tt.id }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(tt.name) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.selectedTT
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "criterion__button--add",
                                            on: { click: _vm.addTT }
                                          },
                                          [
                                            _c("icon", {
                                              attrs: {
                                                name: "plus",
                                                small: true
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "translate",
                                        rawName: "v-translate"
                                      }
                                    ],
                                    staticClass: "criterion__label-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tSelected treatment types\n\t\t\t\t\t\t"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "criterion__list" },
                                  _vm._l(_vm.selectedTreatmentTypes, function(
                                    tt
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        key: tt.id,
                                        staticClass: "criterion__list-item"
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(tt.name) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "criterion__button--remove",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeTT(tt.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("icon", {
                                              attrs: {
                                                name: "delete",
                                                small: true
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            : _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "variant__button--add",
                    attrs: { type: "submit" },
                    on: { click: _vm.cloneVariant }
                  },
                  [
                    _c("icon", { attrs: { name: "plus", small: true } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [_vm._v("Clone existing configuration into variant")]
                    )
                  ],
                  1
                )
              ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "setup-card__properties" }, [
          _c(
            "h2",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "setup-card__heading"
            },
            [_vm._v("\n\t\t\tProperties\n\t\t")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "enabled" }, [
            _c("div", { staticClass: "enabled__label-container--checkbox" }, [
              _c(
                "p",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "enabled__label-text"
                },
                [_vm._v("\n\t\t\t\t\tEnabled\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enabled,
                      expression: "enabled"
                    }
                  ],
                  staticClass: "enabled__checkbox",
                  attrs: { type: "checkbox", disabled: !_vm.allowEdit },
                  domProps: {
                    checked: Array.isArray(_vm.enabled)
                      ? _vm._i(_vm.enabled, null) > -1
                      : _vm.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enabled = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass: "enabled__value-description"
                  },
                  [_vm._v("Yes")]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "greedy" }, [
            _c("div", { staticClass: "greedy__label-container--checkbox" }, [
              _c(
                "p",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "greedy__label-text"
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t100% wide tiles on two-column layout\n\t\t\t\t"
                  )
                ]
              ),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.greedy,
                      expression: "greedy"
                    }
                  ],
                  staticClass: "greedy__checkbox",
                  attrs: { type: "checkbox", disabled: !_vm.allowEdit },
                  domProps: {
                    checked: Array.isArray(_vm.greedy)
                      ? _vm._i(_vm.greedy, null) > -1
                      : _vm.greedy
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.greedy,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.greedy = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.greedy = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.greedy = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass: "greedy__value-description"
                  },
                  [_vm._v("Yes")]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "translate" }, [
            _c("div", { staticClass: "translate__label-container--checkbox" }, [
              _c(
                "p",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "translate__label-text"
                },
                [_vm._v("\n\t\t\t\t\tTranslate variant names\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.translate,
                      expression: "translate"
                    }
                  ],
                  staticClass: "translate__checkbox",
                  attrs: { type: "checkbox", disabled: !_vm.allowEdit },
                  domProps: {
                    checked: Array.isArray(_vm.translate)
                      ? _vm._i(_vm.translate, null) > -1
                      : _vm.translate
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.translate,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.translate = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.translate = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.translate = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass: "translate__value-description"
                  },
                  [_vm._v("Yes")]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "setup-card__custom-properties" }, [
          _c(
            "h2",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "setup-card__heading"
            },
            [_vm._v("\n\t\t\tCustom properties\n\t\t")]
          ),
          _vm._v(" "),
          _vm.customPropsExist
            ? _c(
                "section",
                _vm._l(_vm.customProps, function(prop) {
                  return _c(
                    "div",
                    { key: prop.name },
                    [
                      _c("edit-object", {
                        attrs: {
                          "obj-val": prop.value,
                          "obj-key": prop.name,
                          type: prop.type,
                          "on-change": _vm.onCustomPropChange,
                          readonly: !_vm.allowEdit
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "setup-card__help-button icon-button__button",
                          on: {
                            click: function($event) {
                              return _vm.toggleHelp(prop.name)
                            }
                          }
                        },
                        [
                          _c("icon", {
                            attrs: { name: "question-mark", small: true }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.help[prop.name]
                        ? _c(
                            "p",
                            { staticClass: "setup-card__help-text" },
                            [
                              _c("vue-showdown", [
                                _vm._v(_vm._s(prop.description))
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "p",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [
                  _vm._v(
                    "\n\t\t\tNo custom properties available for this card\n\t\t"
                  )
                ]
              )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }