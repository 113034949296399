import assessmentInstanceList from './assessment-instance-flags/tile';
import listTile from './flag-list/tile';
import lowerFlag from './lower-flag/tile';
import miniCounter from './flag-mini-counter/tile';
import newFlagsList from './new-flags/tile';
import newFlagsMiniCounter from './mini-flags-counter/tile';
import properties from './flag-properties/tile';
import raisedFlagsList from './raised-flags-list/tile';
import reasonTemplates from './reason-templates/tile';
import respondentFlagsList from './respondent-flags-list/tile';
import respondentMini from './mini-respondent-flags/tile';
import treatmentFlags from './treatment-flags/tile';
import unseenFlagsList from './unseen-flags-list/tile';

export default {
	'assessment-instance-flags': assessmentInstanceList,
	'flag-list': listTile,
	'flag-mini-counter': miniCounter,
	'flag-properties': properties,
	'lower-flag': lowerFlag,
	'mini-flags-counter': newFlagsMiniCounter,
	'mini-respondent-flags': respondentMini,
	'new-flags': newFlagsList,
	'raised-flags-list': raisedFlagsList,
	'reason-templates': reasonTemplates,
	'respondent-flags-list': respondentFlagsList,
	'treatment-flags': treatmentFlags,
	'unseen-flags-list': unseenFlagsList
};
