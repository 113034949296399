<template>
	<div>
		<span :class="className('name')">{{itemName}}</span>
		<span :class="className('actions')">
			<button
				:class="className('action')"
				@click="solve(item)"
			>{{t('cardConfig.healthCheck.attemptToSolve')}}</button>
			<button
				:class="className('info-toggle')"
				@click="toggleInfo"
			>{{t('cardConfig.healthCheck.info')}}</button>
		</span>
		<p v-show="infoToggled" :class="className('info')" v-html="info" />
	</div>
</template>

<script>
import Vue from 'vue';
import t from 'service/lang/translate';
import { isObject, isString, isNull } from 'lodash';

export default Vue.component('check-item', {
	props: {
		item: {
			required: true,
			validator: (prop) => isString(prop) || isObject(prop) || isNull(prop)
		},
		nameAttr: {
			type: String,
			default: 'name'
		},
		classPrefix: {
			type: String,
			required: true
		},
		info: {
			type: String,
			required: true
		},
		solve: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		infoToggled: false
	}),
	computed: {
		itemName () {
			if (isObject(this.item)) {
				return this.item[this.nameAttr] || t('(none)');
			}
			return this.item || t('(none)');
		}
	},
	methods: {
		t,
		className (name) {
			return `${this.classPrefix}__${name}`;
		},

		toggleInfo () {
			this.infoToggled = !this.infoToggled;
		}
	}
});
</script>

