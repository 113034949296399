var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-view",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c("datetime-input", {
        attrs: { label: _vm.$t("general-list.StartDate") },
        model: {
          value: _vm.form.startDate,
          callback: function($$v) {
            _vm.$set(_vm.form, "startDate", $$v)
          },
          expression: "form.startDate"
        }
      }),
      _vm._v(" "),
      _c("datetime-input", {
        attrs: { label: _vm.$t("general-list.EndDate") },
        model: {
          value: _vm.form.endDate,
          callback: function($$v) {
            _vm.$set(_vm.form, "endDate", $$v)
          },
          expression: "form.endDate"
        }
      }),
      _vm._v(" "),
      _c("clinician-search", {
        attrs: { required: false },
        model: {
          value: _vm.form.clinician,
          callback: function($$v) {
            _vm.$set(_vm.form, "clinician", $$v)
          },
          expression: "form.clinician"
        }
      }),
      _vm._v(" "),
      _c("respondent-search", {
        attrs: { required: false },
        model: {
          value: _vm.form.respondent,
          callback: function($$v) {
            _vm.$set(_vm.form, "respondent", $$v)
          },
          expression: "form.respondent"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          label: _vm.$t("system-logs.Type"),
          options: _vm.eventTypes,
          multiple: ""
        },
        model: {
          value: _vm.form.types,
          callback: function($$v) {
            _vm.$set(_vm.form, "types", $$v)
          },
          expression: "form.types"
        }
      }),
      _vm._v(" "),
      _c(
        "button-group",
        [_c("submit-button", { attrs: { label: _vm.$t("Export") } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }