import TileView from 'tile-view';
import t from 'translate';
import ClinicianAttributeDefinition from 'repo/clinicians/attribute-definition';
import formView from 'components/form-view/form-view';

export default TileView.extend({
	acl: [{
		checkpoint: 'system.settings.administratorattributes',
		op: 'ADD'
	}],
	title: t('Add clinician attribute'),

	tileData: () => ({
		clinicianAttribute: new ClinicianAttributeDefinition()
	}),

	loaded: ({ tile }) => {
		tile.form = formView({
			name: 'clinician-attributes-add',
			model: tile.clinicianAttribute,
			listenTo: ['submit'],
			onAfterSave: () => {
				tile.card().close();
				delete tile.clinicianAttribute;
			},

			fields: [{
				key: 'administratorAttributeMetaLabel',
				type: 'text',
				mandatory: true,
				label: t('Label')
			}, {
				key: 'administratorAttributeMetaReadonly',
				type: 'checkbox',
				label: t('Readonly')
			}, {
				key: 'administratorAttributeMetaRequired',
				type: 'checkbox',
				label: t('Required')
			}, {
				key: 'administratorAttributeMetaType',
				type: 'select',
				label: t('Type'),
				values: [{
					CHECKBOX: t('Checkbox')
				}, {
					STRING: t('String')
				}, {
					TEXTAREA: t('Textarea')
				}, {
					NUMERIC: t('Numeric')
				}, {
					DATE: t('Date')
				}, {
					DROPDOWN: t('Dropdown')
				}]
			}, {
				key: 'administratorAttributeMetaOrder',
				type: 'number',
				mandatory: true,
				label: t('Order')
			}, {
				key: 'administratorAttributeMetaParameters',
				type: 'text',
				label: t('Parameters')
			}, {
				key: 'administratorAttributeMetaShow',
				type: 'checkbox',
				label: t('Visible')
			}],

			buttons: [{
				caption: t('Add'),
				type: 'submit',
				name: 'clinician-attribute-submit',
				role: 'submit'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});

