var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "consent-submit" }, [
    _c("h1", { staticClass: "consent-submit__title" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.consent.name) + "\n\t")
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("div", {
          staticClass: "consent-submit__content",
          domProps: { innerHTML: _vm._s(_vm.consent.content) }
        }),
        _vm._v(" "),
        _vm.multipleRespondentConsent
          ? _c("select-input", {
              attrs: {
                options: _vm.respondents,
                label: "consents.submitRespondentLabel",
                "get-option-label": _vm.respondentLabel
              },
              model: {
                value: _vm.respondent,
                callback: function($$v) {
                  _vm.respondent = $$v
                },
                expression: "respondent"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.options, function(option) {
          return _c(
            "div",
            { key: option.id, staticClass: "consent-submit__option" },
            [
              _c("p", {
                staticClass: "consent-submit__option-content",
                domProps: { innerHTML: _vm._s(option.content) }
              }),
              _vm._v(" "),
              _c("checkbox-input", {
                attrs: {
                  "input-class": "consent-submit__checkbox",
                  "text-class": "consent-submit__statement",
                  label: option.statement || "",
                  translate: false
                },
                model: {
                  value: _vm.answer(option).answer,
                  callback: function($$v) {
                    _vm.$set(_vm.answer(option), "answer", $$v)
                  },
                  expression: "answer(option).answer"
                }
              })
            ],
            1
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "consent-submit__button-container" },
          [
            _c("submit-button", {
              attrs: { label: "Submit", "can-submit": _vm.canSubmit }
            })
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }