import t from 'translate';
import can from 'acl-can';
import appBar from 'app-bar';
import repository from 'repository';
import addDialog from '../dialogs/add-content-package';

const card = {
	title: t('Information packages'),
	name: 'content-packages',
	url: 'content-packages',

	ctrl: ({ cardConfig, cardData }) => {

		if (can.add('system.settings.informationpackages')) {
			appBar.addButton(card.name, {
				title: t('Add information package'),
				icon: 'add',
				click () {
					addDialog();
				}
			});
		}

		const contentPackageCategories = repository.contentPackageCategories();

		cardData.set({ limit: cardConfig.limit || 10, contentPackageCategories });

		return contentPackageCategories;
	}
};

export { card as List };
