import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Revision from './revision';

const PlanRevisions = MultipleEntities.extend({
	urls,
	model: Revision,
	eventNamespace: 'plan-revision',
	idName: 'workflowHistoryId',

	getForPlan (planId) {
		return this.retrieve('revisions', planId);
	}
});

export default new PlanRevisions();
