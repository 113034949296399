import Backbone from 'backbone';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import Url from 'lib/url/url';
import can from 'acl-can';
import t from 'translate';

export default Backbone.View.extend({

	title: t('admin-portal.ExportRespondentGroups'),
	acl: [{
		checkpoint: 'system.export.respondentgroups',
		op: 'OPEN'
	}],

	render: function () {

		this.$el.append(
			$('<h2/>').html(
				t('system-manger.FilterExport')
			)
		);

		var M = Backbone.Model.extend({
			defaults: {
				anonymous: true
			},
			sync: function () {
				var dfd = $.Deferred();
				var url = new Url('backend-commons/export/rest/respondentGroup/all?' + $.param(this.attributes), null, {
					querySeparator: '',
					prependBackendRoot: 'system'
				});
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		var cfg = {
			name: 'system-export-respondentgroups',
			model: new M(),
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: false,
			fields: [{
				key: 'anonymous',
				type: 'checkbox',
				label: t('Unidentified respondent'),
				disabled: !can.supervise('system.export.respondentgroups')
			}],

			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
