import t from 'translate';
import store from 'store';
import appBar from 'app-bar';
import addAbuseReportStatusDialog from '../dialogs/add-new-status';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('Circle abuse report statuses'),
	name: 'system-settings-circle-abuse-report-statuses',
	url: 'system/circles-abuse-report-statuses',

	ctrl: () => store.dispatch('circles/abuseReports/statuses/init').then(() => {
		if (can.add(SYSTEM.SETTINGS.CIRCLE_ABUSE_REPORT_STATUS)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New abuse status'),
				click: addAbuseReportStatusDialog
			});
		}
	})
};

export { card as AbuseReportStatuses };
