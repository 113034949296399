import appUrl from 'service/app-url/app-url';
import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import $ from 'jquery';
import { split } from 'lodash';
import iconFile from 'components/icon/icon-mapping-file';

export default TileView.extend({

	title: t('assessment.properties.Attachments'),
	acl: [{
		checkpoint: 'administrator.respondents.assessmentcontent',
		op: 'read'
	}],

	cardData: () => ['assessmentInstance', 'mediaReferences', 'assessmentInstanceId'],
	tileData () {
		return {
			attachments: repository.assessmentInstanceAttachments(this.assessmentInstanceId)
		};
	},

	loaded () {
		if (this.attachments.size()) {
			this.renderList();

		} else {
			this.renderNotice();
		}
	},

	renderList () {
		const $list = $('<ul class="attachments__list" />').appendTo(this.$el);

		this.attachments.each((attachment) => {
			const ext = split(attachment.get('filename'), ('.')).pop();
			const iconExt = iconFile[ext] ? iconFile[ext] : iconFile['default'];

			const $listItem = $('<li class="attachments__list-item" />').appendTo($list);
			const filePath = this.mediaReferences.getFileUrl(attachment.get('filename'));
			const attachmentUrl = `${filePath}?respondentId=${
				this.assessmentInstance.getRespondentId()
			}`;
			$('<span />').addClass([
				'attachments__attachment-icon',
				'icon',
				'icon--default',
				`icon--file-${iconExt}`
			]).appendTo($listItem);
			$(`<a class="attachments__attachment-link" />`)
				.html(attachment.get('filename'))
				.attr('href', appUrl(attachmentUrl))
				.appendTo($listItem);
		});
	},

	renderNotice () {
		$('<p />')
			.html(t('assessment.properties.attachments.no-attachments'))
			.appendTo(this.$el);
	}
});

