import SingleEntity from '../shared/single-entity';
import urls from './urls';
import $ from 'jquery';
import Backbone from 'backbone';
import { forEach, keys } from 'lodash';

/**
 * Plan Entity
 * @class Plan
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.plan.url,
	idAttribute: 'workflowId',
	eventNamespace: 'plan',

	defaults: {
		details: {
			name: '',
			description: '',
			workflowTrigger: '',
			enabled: 0,
			allowManual: 0,
			author: 0
		},
		diagram: {},
		png: ''
	},

	isActive () {
		return this.get('enabled') === '1' && this.get('allowManual') === '1';
	},

	// copy keys from 'details' prop with a 'details:' prefix
	normalize () {
		const modelKeys = keys(this.get('details'));

		forEach(modelKeys, (key) => {
			const newKeyName = `details:${key}`;
			this.set(newKeyName, this.get('details')[key]);
		});
	},

	// move 'details:' key prefixed values back to 'details' prop
	// this one should be called before saving the model or universe will break
	denormalize () {
		const modelKeys = keys(this.attributes);

		forEach(modelKeys, (key) => {
			const nameParts = key.split(':');
			let detailsKey = '';

			if (nameParts.length === 2) {
				detailsKey = nameParts[1];
				this.get('details')[detailsKey] = this.get(key);
			}
		});
	},

	getLabel () {
		if (this.get('name')) {
			return this.get('name');
		}
		return (this.get('details') && this.get('details').name) ?
			this.get('details').name :
			`Untitled ${this.getId()}`;
	},

	getDiagram () {
		return this.get('diagram');
	},

	copy () {
		return $.post(Backbone.config().backendRootUrl['default'] + urls.copy.url.call(this));
	}
});
