export default {
	mandatory: ['identity'],
	additional: ['assessment', 'assessmentOrder'],
	additionalTypes: [
		'assessmentAttribute',
		'assessmentCalculation',
		'assessmentFlag',
		'assessmentQuestion'
	]
};
