var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFirstStage
    ? _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.goNext($event)
            }
          }
        },
        [
          _c(
            "h3",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "modal-dialog__selection-title"
            },
            [_vm._v("Assessment information")]
          ),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              options: _vm.assessments,
              label: "Assessment",
              "item-label": "assessmentName",
              mandatory: true
            },
            model: {
              value: _vm.assessment,
              callback: function($$v) {
                _vm.assessment = $$v
              },
              expression: "assessment"
            }
          }),
          _vm._v(" "),
          _c(
            "button-group",
            [
              _vm.loading
                ? _c("div", {
                    directives: [
                      { name: "loader-spinner", rawName: "v-loader-spinner" }
                    ],
                    staticClass: "buttons-container__loader"
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("submit-button", {
                attrs: {
                  label: "Next",
                  "can-submit": _vm.assessment && !_vm.loading
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            [
              _vm.attributes.length
                ? _c("select-list", {
                    attrs: {
                      "loop-key": "id",
                      type: "attributes",
                      "item-label": "label",
                      title: "Select attributes",
                      collection: _vm.attributes
                    },
                    on: { select: _vm.onSelect }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.calculations.length
                ? _c("select-list", {
                    attrs: {
                      "loop-key": "assessmentCalculationId",
                      type: "calculations",
                      title: "Select calculations",
                      collection: _vm.calculations
                    },
                    on: { select: _vm.onSelect }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.flags.length
                ? _c("select-list", {
                    attrs: {
                      "loop-key": "assessmentFlagId",
                      type: "flags",
                      title: "Select flags",
                      collection: _vm.flags
                    },
                    on: { select: _vm.onSelect }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.questions.length
                ? _c("select-list", {
                    attrs: {
                      "loop-key": "assessmentQuestionId",
                      type: "questions",
                      title: "Select questions",
                      collection: _vm.questions
                    },
                    on: { select: _vm.onSelect }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button-group",
            [
              _c("plain-button", {
                attrs: { "on-click": _vm.goBack, label: "Back" }
              }),
              _vm._v(" "),
              _c("submit-button", { attrs: { label: "Add" } })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }