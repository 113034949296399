import addConsentInstance from './add-consent-instance';
import consentClinicianGroups from './consent-clinician-groups';
import consentClinicianGroupPreview from './consent-clinician-groups-preview';
import consentInformationPreview from './consent-information-preview';
import consentInstances from './consent-instances';
import consentOptions from './consent-options/tile';
import consentPreview from './consent-preview';
import consents from './consents/tile';
import consentTreatmentType from './consent-treatment-types/tile';
import editConsent from './edit-consent/tile';
import editConsentOption from './edit-consent-option/tile';
import newlyModifiedConsents from './newly-modified-consents';
import simpleConsents from './simple-consents/index';
import submitConsent from './submit-consent';
import viewConsentInstance from './view-consent-instance';

export default {
	'add-consent-instance': addConsentInstance,
	consents,
	'consent-clinician-groups': consentClinicianGroups,
	'consent-clinician-groups-preview': consentClinicianGroupPreview,
	'consent-information-preview': consentInformationPreview,
	'consent-instances': consentInstances,
	'consent-options': consentOptions,
	'consent-preview': consentPreview,
	'consent-treatment-types': consentTreatmentType,
	'edit-consent': editConsent,
	'edit-consent-option': editConsentOption,
	'newly-modified-consents': newlyModifiedConsents,
	'simple-consents': simpleConsents,
	'submit-consent': submitConsent,
	'view-consent-instance': viewConsentInstance
};
