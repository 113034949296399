import { render, staticRenderFns } from "./program-editor.vue?vue&type=template&id=dd0a2f50&"
import script from "./program-editor.vue?vue&type=script&lang=js&"
export * from "./program-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/hdd1/gitlab-runner/builds/e07c1ccc/1/frontend/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd0a2f50')) {
      api.createRecord('dd0a2f50', component.options)
    } else {
      api.reload('dd0a2f50', component.options)
    }
    module.hot.accept("./program-editor.vue?vue&type=template&id=dd0a2f50&", function () {
      api.rerender('dd0a2f50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/scripts/modules/treatment-programs/tiles/edit-treatment-program/editors/program-editor.vue"
export default component.exports