var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("text-input", {
        attrs: {
          translate: false,
          label: _vm.$t("treatmentProgram.name"),
          required: "",
          mandatory: "",
          invalid: _vm.$v.form.name.$error
        },
        on: { input: _vm.onInput },
        scopedSlots: _vm._u(
          [
            _vm.$v.form.name.$error
              ? {
                  key: "validations",
                  fn: function() {
                    return [
                      !_vm.$v.form.name.required
                        ? _c("p", [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.$t("general.validation.mandatory")) +
                                "\n\t\t\t"
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.form.name,
          callback: function($$v) {
            _vm.$set(
              _vm.form,
              "name",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "form.name"
        }
      }),
      _vm._v(" "),
      _vm.editing
        ? [
            _c("text-input", {
              attrs: {
                translate: false,
                label: _vm.$t("treatmentProgram.description")
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.form.description,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "description",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "form.description"
              }
            }),
            _vm._v(" "),
            _c("media-search", {
              attrs: {
                label: _vm.$t("treatmentProgram.cover"),
                "mime-types": _vm.allowedCoverTypes
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.form.cover,
                callback: function($$v) {
                  _vm.$set(_vm.form, "cover", $$v)
                },
                expression: "form.cover"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }