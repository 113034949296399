import Vue from 'vue';
import t from 'translate';
import cardUrl from 'cwcardurl';
import $ from 'jquery';
import store from 'store';
import { mapGetters } from 'vuex';
import showField from 'service/show-field/index';

export default ({ fields }) => new Vue({
	el: $('<div />')[0],
	store,
	computed: {
		...mapGetters('messages', ['respondents']),

		show: () => showField({
			config: fields,
			field: {
				respondentUsername: true,
				key: 'respondentUsername'
			}
		})
	},

	methods: {
		url: (respondent) => cardUrl.buildUrl('respondent-messages', {
			respondentId: respondent.respondentId,
			treatmentId: 0
		})
	},
	template: `
		<ul class="respondent__list">
			<li
				v-for="respondent in respondents"
				class="respondent__list-item"
			>
				<a
					class="respondent__item-container respondent__action"
					:href="url(respondent)"
				>
					<p class="
						respondent__item-value
						respondent__item-value--main
						respondent__fullname-container
					">
						<span class="respondent__fullname">{{ respondent.displayName }}</span>
					</p>

					<template v-if="show.respondentUsername">
						<p class="respondent__item-label respondent__username-label">
							${t('Username')}
						</p>
						<p class="respondent__item-value respondent__username">
							{{ respondent.respondentUsername }}
						</p>
					</template>

					<p class="respondent__item-label respondent__messages-count-label">
						${t('Unanswered messages')}
					</p>
					<p class="respondent__item-value respondent__messages-count">
						{{ respondent.messagesCount }}
					</p>
				</a>
			</li>
		</ul>
	`
});
