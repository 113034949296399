import appBar from 'app-bar';
import confirmation from 'components/confirmation/confirmation';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';

const card = {
	title: t('Edit consent option'),
	name: 'edit-consent-option',
	url: [
		'consent-template/:consentId/consent-option/:optionId',
		'consent-template/:consentId/consent-option/new-option'
	],

	ctrl: ({ populate }, { consentId, optionId }) => {
		store.dispatch('consent/initConsentOptions');

		if (optionId) {
			store.dispatch('consent/initConsentOption');
			appBar.addButton({
				sourceCard: card.name,
				icon: 'delete',
				title: t('Delete consent option'),
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Remove consent option'),
						message: t('consents.removeOption?')
					}).done(() => {
						store.dispatch('consent/removeConsentOption', optionId)
							.then(() => {
								cwalert.success(t('consents.removedOption'));
								cardUrl.openCard(`consent-template/${consentId}/edit`);
							});
					});
				}
			});
		} else {
			store.dispatch('consent/initNewConsentOption');
		}
		populate();
	}
};
export { card as editConsentOption };
