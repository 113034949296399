/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import Dropdown from './Dropdown';

export default function (options = {}) {
	const rendererOptions = options.rendererOptions ?
		options.rendererOptions :
		{};

	if (options.displayValue) {
		rendererOptions.displayValue = options.displayValue;
	}

	if (options.disableAutoSave) {
		rendererOptions.disableAutoSave = options.disableAutoSave;
	}

	this.createRenderer = function (attributeName, model, opts) {
		if (options.disableAutoSave === undefined) {
			rendererOptions.disableAutoSave = opts.disableAutoSave;
		}

		if (opts.readonly) {
			rendererOptions.readonly = opts.readonly;
		}

		return new Dropdown(attributeName, model, rendererOptions, opts.mode);
	};
}

