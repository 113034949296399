import components from './components';
import { mapActions, mapGetters } from 'vuex';
import { get, includes, isFunction } from 'lodash';
import t from 'translate';
import {
	EMPTY_CONTEXT,
	updateValues,
	updateFields
} from 'modules/export-template/helpers/update';
import { updateName } from 'modules/export-template/helpers/update-name';

// components are callbacks for dynamic component <component :is="callback()" />
// prevents sharing the same dynamic component between 2 items of same type
// e.g. 2 different assessment questions will use the same edit component without re-render (bad)

export default (name = 'default') => ({
	data () {
		return {
			components: {
				...components
			},
			overwriteLabel: t('Write item code manually')
		};
	},

	computed: {
		...mapGetters({
			editItem: `components/tree-view/${name}/editItem`,
			mirrors: `components/tree-view/${name}/mirrors`,
			parentContext: `components/tree-view/${name}/parentContext`,
			parentContextId: `components/tree-view/${name}/parentContextId`,
			rootMirrors: `components/tree-view/${name}/rootMirrors`
		}),
		isComponent () {
			const component = this.components[this.editItem.type];
			return isFunction(component) ? component() : component;
		},

		label () {
			if (this.editItem.type == 'folder' || this.editItem.root) {
				return this.setTemplateLabel(this.editItem);
			}
			return 'Edit column';
		},

		mirror () {
			return includes(this.mirrors, get(this.editItem, 'path', ''));
		},

		mirrorRoot () {
			return includes(this.rootMirrors, get(this.editItem, 'path', ''));
		},

		parentContextExcluded () {
			return this.parentContextId === EMPTY_CONTEXT;
		}
	},

	methods: {
		...mapActions(`components/tree-view/${name}`, [
			'setModified',
			'setWarning',
			'syncMirrors',
			'toggleEdit',
			'uncheckAll',
			'updateUnavailable'
		]),
		...mapActions(`exportTemplateGui`, ['saveItem']),

		onSave ({ item, fields }) {
			this.toggleEdit({ item });
			this.saveItem({ item, fields });
			this.setModified(true);
			this.setWarning({ item });
			this.uncheckAll();

			if (!item.root) {
				this.syncMirrors({ path: item.path, updateName, updateValues, updateFields });
			}

			if (item.unavailable) {
				this.updateUnavailable();
			}
		},

		setTemplateLabel (item) {
			if (item.root) {
				return 'Template syntax';
			} else if (item.mirror) {
				return 'Edit mirrored group';
			}
			return 'Edit group';
		}
	},

	template: `
		<div class="tree-edit">
			<div v-if="editItem">
				<h2 class="tile__title" v-translate>{{ label }}</h2>
				<component
					:is="isComponent"
					:item="editItem"
					:mirror="mirror"
					:mirrorRoot="mirrorRoot"
					:overwrite-label="overwriteLabel"
					:parent-context="parentContext"
					:parent-context-excluded="parentContextExcluded"
					@save="onSave"
				/>
			</div>
		</div>
	`
});
