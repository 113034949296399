var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consent__container" },
    [
      _c("div", { staticClass: "consent-header" }, [
        _c("h2", [_vm._v(_vm._s(_vm.consentInstance.name))])
      ]),
      _vm._v(" "),
      _c("div", {
        domProps: { innerHTML: _vm._s(_vm.consentInstance.content) }
      }),
      _vm._v(" "),
      _vm._l(_vm.options, function(option) {
        return _c(
          "ul",
          { key: option.id, staticClass: "consent-option__list" },
          [
            _c("li", [
              _c("p", {
                staticClass: "consent-option__content",
                domProps: { innerHTML: _vm._s(option.content) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "consent-option-statement" },
                [
                  _c("icon", {
                    class: ["statement__answer", _vm.className(option.status)],
                    attrs: { name: _vm.iconName(option.status) }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "consent-option-statement__content" },
                    [_vm._v('"' + _vm._s(option.statement) + '"')]
                  )
                ],
                1
              ),
              _vm._v(" "),
              option.answers.length > 1
                ? _c(
                    "div",
                    _vm._l(_vm.answers(option), function(answer) {
                      return _c(
                        "span",
                        {
                          key: answer.respondent.id,
                          class: [
                            "answer-respondent",
                            _vm.respondentClass(answer)
                          ]
                        },
                        [
                          _c("icon", { attrs: { name: "check", small: true } }),
                          _vm._v(" "),
                          _c("icon", { attrs: { name: "times", small: true } }),
                          _vm._v(" "),
                          _c("icon", { attrs: { name: "minus", small: true } }),
                          _vm._v(" "),
                          _vm.submittedByClinician(answer)
                            ? [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "translate",
                                        rawName: "v-translate"
                                      }
                                    ]
                                  },
                                  [_vm._v("consents.clinicianOnBehalf")]
                                ),
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(answer.respondent.displayName) +
                                    "\n\t\t\t\t\t\t"
                                ),
                                !_vm.previousAnswer(answer)
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t(" +
                                          _vm._s(answer.respondentRoleName) +
                                          ")\n\t\t\t\t\t\t"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            : [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(answer.respondent.displayName) +
                                    "\n\t\t\t\t\t\t"
                                ),
                                !_vm.previousAnswer(answer)
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t(" +
                                          _vm._s(answer.respondentRoleName) +
                                          ")\n\t\t\t\t\t\t"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }