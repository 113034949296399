import Flags from './flags';
import ReasonTemplates from './reason-templates';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all Flags
	 * @param {Object} params
	 * @method getFlags
	 * @return {Object} new {{#crossLink "Flags"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getFlags();
	 */
	getFlags: (params) => Flags.getList(params),

	/**
	 * Get flag by Id
	 * @method getFlags
	 * @return {Object} new {{#crossLink "Flag"}}{{/crossLink}} Model
	 * @example
	 *        Repository.flag(9);
	 */
	flag: (id) => Flags.getById(id),

	/**
	 * Get Flags by an assessment
	 * @method  getAssesmentInstanceFlags
	 * @param  {Number|String} assessmentInstanceId
	 * @return {Object} new {{#crossLink "Flags"}}{{/crossLink}} Collection
	 */
	getAssessmentInstanceFlags: (assessmentInstanceId) =>
		Flags.getByAssessmentInstanceId(assessmentInstanceId),

	/**
	 * Get Flags triggered by a Respondent
	 * @method  respondentFlags
	 * @param  {Number|String} respondentId
	 * @return {Object} new {{#crossLink "Flags"}}{{/crossLink}} Collection
	 */
	respondentFlags: (respondentId) => Flags.getByRespondentId(respondentId),

	/**
	 * Get all ReasonTemplates
	 * @method getReasonTemplates
	 * @return {Object} new {{#crossLink "ReasonTemplates"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getReasonTemplates();
	 */
	getReasonTemplates: () => ReasonTemplates.getAll(),

	/**
	 * Get Flags triggered in treatment
	 * @method  treatmentFlags
	 * @param  {Number|String} treatmentId
	 * @return {Object} new {{#crossLink "Flags"}}{{/crossLink}} Collection
	 */
	treatmentFlags: (treatmentId) => Flags.getByTreatmentId(treatmentId)
};
