import repository, { Clinicians } from 'repository';
import t from 'translate';
import $ from 'jquery';
import datetime from 'datetime';
import store from 'store';
import can from 'acl-can';
import cwalert from 'cwalert';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import categoryTag from 'components/category-tag/category-tag';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import { assign, compact, escape, map } from 'lodash';

const ACL_CHECKPOINT = 'system.settings.contentmanagement';

export default SearchTileView.extend({
	acl: [{
		op: 'READ',
		checkpoint: 'system.settings.contentmanagement'
	}],
	title: t('Pages'),

	searchFn: (...args) => repository.searchPages(...args),
	fields: ({ tile }) => {
		const ids = (value) => compact(map(value, (item, key) => item && key));

		return [{
			key: 'category',
			type: 'multi-select',
			label: t('Category'),

			values: map(store.getters['pages/categories/list'], (category) => assign({}, category, {
				id: category.id,
				label: category.name
			})),

			onChange: (value) => {
				tile.viewModel.set('category', ids(value));
				tile.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry page-list__entry page-list__';
		const mainClassName = 'search-results-list__entry--main';

		return {
			click: (page) => {
				cardUrl.openCard('edit-page', { pageId: page.getId() });
			},
			rowsClickable: can.edit(ACL_CHECKPOINT),
			columns: [{
				key: 'name',
				label: t('Name'),
				className: `${mainClassName} ${className}name`,
				render: (value, { item, td }) => {
					const name = escape(item.get('name'));
					$(td).append(name).append(categoryTag(item.get('category')));
				}
			}, {
				label: t('Last modified'),
				className: `${className}last-modified`,
				render: (value, { item }) => {
					if (!item.get('modifiedAt')) {
						return t('Not specified');
					}

					return datetime(item.get('modifiedAt'))
						.setTimezone(store.state.user.timezoneName)
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				label: t('Modified by'),
				className: `${className}modified-by`,
				render: (value, { item }) => {
					const clinician = new Clinicians.model(item.get('modifiedBy'));
					return clinician.displayName ? clinician.displayName() : t('Not specified');
				}
			}],
			deleteFilter: () => can.remove(ACL_CHECKPOINT),
			actions: {
				delete: (page, actions) => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete page'),
						message: t('Delete page "{page}" ?', { page: page.get('name') })
					}).then(() => {
						const pageName = page.get('name');
						// so called soft delete
						page.save({ deleted: true }).then(() => {
							this.results.remove(page);
							cwalert.success(t(
								'Page "{pageName}" successfully removed',
								{ pageName }
							));

							// re-run the search, hacky way to notify filters that results have
							// changed
							this.runQuery();
						}, () => {
							cwalert.error(t('Unable to remove page "{pageName}"', { pageName }));
						});

					}, () => {
						actions.cancel();
					});

					return false;
				}
			}
		};
	}
});
