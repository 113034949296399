<script>
import { get, flatten, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import selectList from '../components/select-list';

const stages = ['identity', 'selection'];

export default {
	name: 'TreatmentWizard',
	components: { selectList },
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			treatmentType: null,
			stage: stages[0],
			loading: false,
			selected: {
				attributes: [],
				customAttributes: []
			}
		};
	},
	computed: {
		...mapGetters({
			treatmentTypes: 'exportTemplate/sortedByNameIdStringified',
			attributes: 'treatment/attributes/translatedList',
			customAttributes: 'treatmentTypes/attributes'
		}),

		isFirstStage () {
			return this.stage === stages[0];
		}
	},

	watch: {
		stage (val) {
			this.$emit('stage-change', val);
		}
	},

	created () {
		this.$emit('stage-change', stages[0]);
	},

	methods: {
		...mapActions({
			getAttributes: 'treatment/attributes/init',
			getCustomAttributes: 'treatmentTypes/initAttributes'
		}),

		onSelect ({ type, selected }) {
			this.selected[type] = selected;
		},

		goNext () {
			this.loading = true;
			const treatmentTypeId = get(this.treatmentType, 'id');

			Promise.all([
				this.getAttributes(),
				this.getCustomAttributes({ treatmentTypeId })
			]).then(() => {
				this.loading = false;
				this.stage = stages[1];
			});
		},

		goBack () {
			this.treatmentType = null;
			this.stage = stages[0];
		},

		submit () {
			const columns = map(this.selected, (selected, type) =>
				map(selected, (item) => ({
					attributes: {
						identity: get(item, 'id'),
						type: 'treatmentAttribute',
						identifierLabel: get(item, 'label')
					},
					customAttributes: {
						identity: get(item, 'id'),
						type: 'treatmentCustomAttribute',
						identifierLabel: get(item, 'label'),
						treatmentType: get(this.treatmentType, 'id')
					}
				}[type])));

			this.$emit('submit', flatten(columns));
		}
	}
};
</script>

<template>
	<form v-if="isFirstStage" @submit.prevent="goNext">
		<h3
			v-translate
			class="modal-dialog__selection-title"
		>Treatment information</h3>
		<select-input
			v-model="treatmentType"
			:options="treatmentTypes"
			label="Treatment type"
			item-label="name"
			:mandatory="true"
		/>
		<button-group>
			<div
				v-if="loading"
				v-loader-spinner
				class="buttons-container__loader"
			/>
			<submit-button label="Next" :can-submit="treatmentType && !loading" />
		</button-group>
	</form>
	<form v-else @submit.prevent="submit">
		<div>
			<select-list
				v-if="attributes.length"
				loop-key="id"
				type="attributes"
				item-label="label"
				title="Select attributes"
				:collection="attributes"
				@select="onSelect"
			/>
			<select-list
				v-if="customAttributes.length"
				loop-key="id"
				type="customAttributes"
				item-label="label"
				title="Select custom attributes"
				:collection="customAttributes"
				@select="onSelect"
			/>
		</div>
		<button-group>
			<plain-button :on-click="goBack" label="Back" />
			<submit-button label="Add" />
		</button-group>
	</form>
</template>
