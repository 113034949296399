import t from 'translate';
import MiniCounter from '../flag-mini-counter/tile';
import store from 'store';

export default MiniCounter.extend({
	caption: t('Flag(s)'),
	targetCard: 'flags',
	actions: () => [['notifications/init', { type: 'flags' }, { instant: true }]],
	count: () => store.getters['notifications/flags']
});
