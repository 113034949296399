import Backbone from 'backbone';
import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';

/**
 * PlanTriggers Entity
 * @class PlanTriggers
 * @extends MultipleEntities
 */
const PlanTriggersEntity = MultipleEntities.extend({
	urls,
	// model is not used anywhere, kinda dummy model actually
	model: Backbone.Model.extend({
		idAttribute: 'workflowTriggerId',
		eventNamespace: 'plan-trigger'
	}),
	idName: 'workflowTriggerId',
	eventNamespace: 'plan-trigger',

	/**
	 * Get all PlanTriggers
	 * @method getAll
	 * @return {Object} new {{#crossLink "PlanTriggers"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.PlanTriggers.getAll();
	 */
	getAll: function () {
		return this.retrieve('triggers');
	}
});

export default new PlanTriggersEntity();

