import Backbone from 'backbone';
import $ from 'jquery';
import celllabel from 'cell-label';
import dom from 'cwdom';
import datetime from 'datetime';
import OverdueAssessments from '../../entities/report-overdue-assessments';
import formView from 'components/form-view/form-view';
import t from 'translate';
import appContext from 'app-context';
import { forEach } from 'lodash';

export default Backbone.View.extend({
	title: t('admin-portal.UnansweredAssessmentsReport'),
	acl: [{
		checkpoint: 'system.reports.expiredassessments',
		op: 'OPEN'
	}],

	report (assessments) {
		// @TODO(2015-09-29): just rewrite it all
		$(this.table).empty();
		$(this.noItem).empty();

		if (!assessments.length) {
			this.noItem = dom.createElement({
				tag: 'p',
				id: 'no-overdue-assessment',
				role: 'alert',
				innerHTML: t('admin-portal.NoOverdueAssessment'),
				parent: this.el
			});
			return;
		}

		this.table = dom.createElement({
			tag: 'table',
			id: 'report-table',
			css: 'cwtable cwtable--modest',
			parent: this.el,
			attrs: {
				tabindex: 0
			}
		});
		const thead = dom.createElement({
			tag: 'thead',
			id: 'report-table-thead',
			parent: this.table
		});
		const thTr = dom.createElement({
			tag: 'tr',
			id: 'report-table-head-tr',
			css: 'report-table-head-tr',
			parent: thead
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.AssessmentName'),
			id: 'report-table-head-assessment',
			css: 'report-table-head-assessment',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.userName'),
			id: 'report-table-head-username',
			css: 'report-table-head-username',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.FirstName'),
			id: 'report-table-head-firstname',
			css: 'report-table-head-firstname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.LastName'),
			id: 'report-table-head-lastname',
			css: 'report-table-head-lastname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('system-manager.StartDate'),
			id: 'report-table-head-start-date',
			css: 'report-table-head-start-date',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('system-manager.EndDate'),
			id: 'report-table-head-end-date',
			css: 'report-table-head-end-date',
			parent: thTr
		});
		this.tbody = dom.createElement({
			tag: 'tbody',
			parent: this.table
		});

		const tbody = this.tbody;

		forEach(assessments, (UnansweredAssessment) => {
			const tr = dom.createElement({
				tag: 'tr',
				parent: tbody
			});

			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.assessmentName
			}), t('general-list.AssessmentName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.respondentUsername
			}), t('general-list.userName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.respondentFirstName
			}), t('general-list.FirstName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.respondentLastName
			}), t('general-list.LastName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.assessmentInstanceStartDate ?
					datetime(UnansweredAssessment.attributes.assessmentInstanceStartDate)
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime')) : t('Not specified')
			}), t('general-list.LastName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: UnansweredAssessment.attributes.assessmentInstanceEndDate ?
					datetime(UnansweredAssessment.attributes.assessmentInstanceEndDate)
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime')) : t('Not specified')
			}), t('system-manager.EndDate'));
		}, this);
	},

	render () {
		this.renderDescription();

		const tile = this;
		let form = {};
		const reportModel = new (Backbone.Model.extend({
			sync () {
				return new OverdueAssessments([], {
					daySince: this.get('daySince')
				}).fetch({
					success (collection) {
						tile.report(collection.models);
						form.setLoaded();
					}
				});
			}
		}))({
			daySince: 7
		});

		form = formView({
			name: 'system-reports-inactive-respondents',
			model: reportModel,
			saveIndicator: true,
			listenTo: ['submit'],
			fields: [{
				key: 'daySince',
				name: 'daySince',
				label: t('admin-portal.ReportsNumberOfDays'),
				type: 'number',
				required: true,
				min: '0'
			}],
			buttons: [{
				caption: t('general-list.Search'),
				type: 'submit',
				name: 'search-submit'
			}]
		});

		this.$el.append(form.$el);
	},

	renderDescription () {
		const $description = $('<p class="text-content" />')
			.html(t('admin-portal.UnansweredAssessmentsIntroduction'));
		this.$el.prepend($description);
		return this;
	}

});
