import MultipleEntities from '../shared/multiple-entities';
import ClinicianRole from './access-role-entity';
import urls from './urls';

/**
 * ClinicianRoles Entity
 * @class ClinicianRoles
 * @extends MultipleEntities
 */
const ClinicianRolesEntity = MultipleEntities.extend({
	model: ClinicianRole,
	urls,
	eventNamespace: 'clinician-role',
	idName: 'administratorRoleId',
	order: 'administratorRoleName',

	/**
	 * Get all ClinicianRoles
	 * @method getAll
	 * @return {Object} new ClinicianRoles Collection
	 * @example
	 *        Repository.ClinicianRoles.getAll();
	 */
	getAll: function () {
		return this.retrieve('listRoles');
	},

	/**
	 * Get active ClinicianRoles
	 * @method getActive
	 * @return {Object} new ClinicianRoles Collection
	 * @example
	 *        Repository.ClinicianRoles.getActive();
	 */
	getActive: function () {
		return this.retrieve('listActiveRoles');
	}

});

export default new ClinicianRolesEntity();
