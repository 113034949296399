import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import ExportInstance from './export-instance';

const ExportInstances = MultipleEntities.extend({
	urls,
	model: ExportInstance,
	idName: 'dataExportInstanceId',
	eventNamespace: 'export-instance',

	getAll () {
		return this.retrieve('instances');
	}
});

export default new ExportInstances();
