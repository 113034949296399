var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-view",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _vm.show
        ? _c("select-input", {
            attrs: {
              options: _vm.availableContexts,
              placeholder: _vm.translate("Select context"),
              "item-value": "assessmentInstanceContextId",
              "class-prefix": "tree-edit",
              label: "Set context for template",
              "item-label": "assessmentInstanceContextLabel"
            },
            model: {
              value: _vm.formData.assessmentContext,
              callback: function($$v) {
                _vm.$set(_vm.formData, "assessmentContext", $$v)
              },
              expression: "formData.assessmentContext"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show
        ? _c("select-input", {
            attrs: {
              options: _vm.formOptions.assessmentOrder,
              placeholder: _vm.translate("Select order"),
              "class-prefix": "tree-edit",
              label: "Assessment order"
            },
            model: {
              value: _vm.formData.assessmentOrder,
              callback: function($$v) {
                _vm.$set(_vm.formData, "assessmentOrder", $$v)
              },
              expression: "formData.assessmentOrder"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("text-input", {
        attrs: { "class-prefix": "tree-edit", label: "Code string" },
        model: {
          value: _vm.formData.codeTemplate,
          callback: function($$v) {
            _vm.$set(_vm.formData, "codeTemplate", $$v)
          },
          expression: "formData.codeTemplate"
        }
      }),
      _vm._v(" "),
      _vm.invalidCode
        ? _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "invalid-msg tree-edit__invalid"
            },
            [_vm._v("Code template invalid")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button-group",
        [
          _c("submit-button", {
            attrs: { label: "Save", "can-submit": !_vm.invalidCode }
          }),
          _vm._v(" "),
          _c("plain-button", {
            attrs: { "on-click": _vm.reset, label: "Reset" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }