import cardurl from 'cwcardurl';
import datetime from 'datetime';
import store from 'store';
import t from 'translate';
import Table from 'components/table/table';
import TileView from 'tile-view';
import { assign, map, isNull, toLower, reduce, keys } from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

/**
 * Convert an array of objects to a simple array.
 *
 * @example [{ prop1: true }, { prop2: false }, { prop3: true }] -> ['prop1', 'prop3']
 *
 * @param {Array} columnsList -Array of column objects.
 * @returns {Array} Simplified columns list.
 */
const columns = (columnsList) => reduce(columnsList, (memo, column) => {
	const columnName = keys(column)[0];
	column[columnName] && memo.push(columnName);
	return memo;
}, []);

export default TileView.extend({
	title: t('Treatment assessments'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}, {
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: READ
	}],

	actions: () => ['assessmentInstances/init'],

	loaded ({ tile }) {
		tile.clear();
		assign(tile, {
			respondent: store.getters.respondent,
			assessments: store.state.assessmentInstances.collection
		});

		const renderDate = (date) => isNull(date) ?
			EMPTY_CHAR :
			datetime(date)
				.setTimezone(store.getters['respondent/timezone'])
				.toMoment()
				.format(t('date.formats.dateTime'));

		const columnDefinition = {
			firstSubmitAt: {
				key: 'firstSubmitAt',
				label: t('First submit date'),
				render: (date) => renderDate(date)
			},
			lastSubmitAt: {
				key: 'lastSubmitAt',
				label: t('Last submit date'),
				render: (date) => renderDate(date)
			},
			lastModifiedAt: {
				key: 'lastModifiedAt',
				label: t('Last modified date'),
				render: (date) => renderDate(date)
			}
		};

		tile.table = new Table({
			parent: tile.el,
			collection: tile.assessments,
			emptyListMessage: t('No assessments'),
			sortable: true,
			sortOrder: tile.config().tableSort,
			className: 'table--long-text',
			click: (item) => {
				cardurl.openCard('assessment-properties', {
					assessmentInstanceId: item.get('assessmentInstanceId')
				});
			},
			columns: [{
				key: 'assessmentInstanceId',
				label: t('Assessment'),
				render: (assessmentInstanceId, { item }) => item.get('assessmentTitle')
			}, {
				key: 'roleName',
				label: t('Role')
			}, {
				key: 'respondentDisplayName',
				label: t('Respondent')
			}, {
				key: 'assessmentInstanceStartDate',
				label: t('Start date'),
				render: renderDate
			}, {
				key: 'assessmentInstanceEndDate',
				label: t('End date'),
				render: renderDate
			}, {
				key: 'status',
				label: t('Status'),
				render: (status) => isNull(status) ? EMPTY_CHAR : t(`assessment.${toLower(status)}`)
			},

			...map(columns(this.config().columns), (column) => columnDefinition[column])
			]
		});
	}
});
