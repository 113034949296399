import { isFunction } from 'lodash';

export default function (worker, context) {
	let currentTask;

	const Task = function (items, args) {
		args.unshift(undefined);
		this.cancel = false;
		const task = this;

		const intervalId = setInterval(() => {
			if ((items.length === 0) || task.cancel) {
				clearInterval(intervalId);

				if (currentTask === task) {
					currentTask = undefined;
				}

				if (isFunction(task.done)) {
					task.done.call(context);
				}
				return;
			}

			args[0] = items.shift();
			worker.apply(context, args);
		}, 0);
	};

	this.consume = function (items) {
		const arg = [];
		for (let i = 1; i < arguments.length; i++) {
			arg.push(arguments[i]);
		}
		currentTask = new Task(items, arg);

		return this;
	};

	this.done = function (callback) {
		if (currentTask) {
			currentTask.done = callback;
		}

		return this;
	};

	this.cancel = function () {
		if (currentTask) {
			currentTask.cancel = true;
		}

		return this;
	};
}

