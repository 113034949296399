var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "role-set-roles__container role-set-roles--mandatory" },
        [
          _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "role-set-roles__label"
            },
            [_vm._v("\n\t\t\t\tName\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.roleSetForm.name,
                expression: "roleSetForm.name"
              }
            ],
            staticClass: "role-set-roles__input",
            attrs: {
              type: "text",
              disabled: _vm.updatingRoleSet || !_vm.canEdit
            },
            domProps: { value: _vm.roleSetForm.name },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.roleSetForm, "name", $event.target.value)
                },
                function($event) {
                  return _vm.$v.roleSetForm.name.$touch()
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.roleSetForm.name.$invalid && !_vm.$v.roleSetForm.name.$pending
            ? [
                !_vm.$v.roleSetForm.name.required
                  ? _c("mandatory-warning", { attrs: { name: "name" } })
                  : !_vm.$v.roleSetForm.name.unique
                  ? _c("mandatory-warning", { attrs: { name: "name" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.$t("roleSets.error.roleSetNameIsNotUnique")
                          ) +
                          "\n\t\t\t\t"
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "role-set-roles__container" }, [
        _c(
          "p",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            staticClass: "role-set-roles__label"
          },
          [_vm._v("Description")]
        ),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.roleSetForm.description,
              expression: "roleSetForm.description"
            }
          ],
          staticClass: "role-set-roles__textarea",
          attrs: { disabled: _vm.updatingRoleSet || !_vm.canEdit },
          domProps: { value: _vm.roleSetForm.description },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.roleSetForm, "description", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "role-set-roles__container" }, [
        _c(
          "button",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            staticClass: "role-set-roles__button--save",
            attrs: {
              disabled: _vm.invalidName || _vm.updatingRoleSet || !_vm.canEdit
            },
            on: { click: _vm.updateMainData }
          },
          [_vm._v("Save")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "role-set-roles__container--select",
        class: { "role-set-roles__container--not-initialised": !_vm.roles }
      },
      [
        _c(
          "p",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            staticClass: "role-set-roles__label"
          },
          [_vm._v("\n\t\t\tSelect role\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "role-set-roles__select" },
          [
            _c("v-select", {
              attrs: {
                disabled: _vm.updatingRole || !_vm.canEdit,
                options: _vm.filteredList,
                placeholder: _vm.selectPlaceholder,
                value: _vm.selected,
                label: "name"
              },
              on: { input: _vm.addRole },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("selectInput.noResults")) +
                          "\n\t\t\t\t"
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm.canEdit
      ? _c("div", { staticClass: "role-set-roles__button-container" }, [
          _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "role-set-roles__label"
            },
            [_vm._v("The role you are looking for does not exist?")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "role-set-roles__button",
              on: {
                click: function($event) {
                  return _vm.addItem()
                }
              }
            },
            [_vm._v("Create a new role")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table__container" }, [
      !_vm.roles
        ? _c("div", {
            directives: [
              { name: "loader-spinner", rawName: "v-loader-spinner" }
            ],
            staticClass: "role-set-roles__loading-table"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.roles && _vm.roles.length
        ? _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n\t\t\t\t\t\tRoles\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n\t\t\t\t\t\tDescription\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n\t\t\t\t\t\tRequired\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n\t\t\t\t\t\tDelete\n\t\t\t\t\t")]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.roles, function(item) {
                return _c(
                  "tr",
                  {
                    key: item.role.id,
                    class: { "clickable-row": _vm.canEdit }
                  },
                  [
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onItemClick(item.role.id)
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "table-cell-label" }, [
                          _vm._v("\n\t\t\t\t\t\t\tRoles\n\t\t\t\t\t\t")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.role.name) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onItemClick(item.role.id)
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "table-cell-label" }, [
                          _vm._v("\n\t\t\t\t\t\t\tDescription\n\t\t\t\t\t\t")
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.role.description) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v("\n\t\t\t\t\t\t\tRequired\n\t\t\t\t\t\t")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.required,
                            expression: "item.required"
                          }
                        ],
                        attrs: { disabled: !_vm.canEdit, type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(item.required)
                            ? _vm._i(item.required, null) > -1
                            : item.required
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = item.required,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      item,
                                      "required",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      item,
                                      "required",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(item, "required", $$c)
                              }
                            },
                            function($event) {
                              return _vm.update(item.role.id, item.required)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v("\n\t\t\t\t\t\t\tDelete\n\t\t\t\t\t\t")
                      ]),
                      _vm._v(" "),
                      _vm.canDelete
                        ? _c("button", {
                            staticClass:
                              "icon icon--delete role-set-roles__button-delete",
                            attrs: { title: _vm.removeLabel },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteRole(item.role.id, item.id)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.roles && !_vm.roles.length
        ? _c(
            "p",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [_vm._v("\n\t\t\tNo matches found\n\t\t")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }