import TileView from 'tile-view';
import respondentRelations from '../../components/respondent-relations';
import t from 'translate';
import store from 'store';

export default TileView.extend({
	title: t('Existing relations'),
	actions: () => [
		['respondentRelations/getRespondentRelations', store.getters['respondent/respondentId']]
	],
	acl: [{
		checkpoint: 'administrator.respondents.relations',
		op: 'read'
	}],

	Vue: () => ({
		components: { respondentRelations },
		data: {
			id: parseInt(store.getters['respondent/respondentId'], 10)
		},
		template: `<respondent-relations :id="id" />`
	})
});
