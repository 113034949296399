import PlanInstances from './entities';

import urls from './urls';
import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';
import { assign } from 'lodash';

export default {
	/**
	 * Get a new collection of all PlanInstances assigned to a Respondent.
	 *
	 * @function respondentPlanInstances
	 * @param {number} respondentId - Respondent ID.
	 * @returns {object}            - Collection.
	 * @example
	 *        Repository.respondentPlanInstances(19);
	 */
	respondentPlanInstances: (respondentId) => PlanInstances.getByRespondentId(respondentId),

	/**
	 * Get PlanInstance by id.
	 *
	 * @function planInstance
	 * @param {number} planInstanceId - Plan instance ID.
	 * @returns {object}              - Model.
	 * @example
	 *        Repository.planInstance(7);
	 */
	planInstance: (planInstanceId) => PlanInstances.getById(planInstanceId),

	/**
	 * Get a new collection of all PlanInstances assigned to a Respondent.
	 *
	 * @function treatmentPlanInstances
	 * @param  {number} treatmentId - Treatment ID.
	 * @returns {object}            - Collection.
	 * @example
	 *        Repository.treatmentPlanInstances(19);
	 */
	treatmentPlanInstances: (treatmentId) => PlanInstances.getByTreatmentId(treatmentId),

	/**
	 * Get RoleSets by plan instance id.
	 *
	 * @function planInstanceRoleSets
	 * @param {number} planId - Plan instance id.
	 * @returns {Array}       - RoleSet.
	 * @example
	 *        repository.planInstanceRoleSets(1);
	 */
	planInstanceRoleSets: (planId) => {
		const url = urls.planInstanceRoleSets(planId);
		const request = GET({ url });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Add a single PlanInstanceRoleSet.
	 *
	 * @function addPlanInstanceRoleSet
	 * @param {object} data        - Parameters.
	 * @param {number} data.id     - Id of a role set.
	 * @param {number} data.planId - Id of a plan instance.
	 * @example
	 *        repository.addPlanInstanceRoleSet({id: 1, pland: 1});
	 */
	addPlanInstanceRoleSet: (data) => POST({
		url: urls.planInstanceRoleSet(data)
	}),

	/**
	 * Delete a single PlanInstanceRoleSet.
	 *
	 * @function deletePlanInstanceRoleSet
	 * @param {object} data         - Parameters.
	 * @param {number} data.id      - Id of a role set.
	 * @param {number} data.tplanId - Id of a plan instance.
	 * @example
	 *        repository.deletePlanInstanceRoleSet({id: 1, planId: 1});
	 */
	deletePlanInstanceRoleSet: (data) => DELETE({
		url: urls.planInstanceRoleSet(data)
	}),

	/**
	 * @function pausePlanInstance
	 * @param {number} id - Plan Instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.pausePlanInstance(1337);
	 */
	pausePlanInstance: (id) => PUT({
		url: urls.pauseOrResume.url(id),
		data: { mode: 'pause' }
	}),

	/**
	 * @function resumePlanInstance
	 * @param {number} id - Plan Instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.resumePlanInstance(1337);
	 */
	resumePlanInstance: (id) => PUT({
		url: urls.pauseOrResume.url(id),
		data: { mode: 'resume' }
	}),

	/**
	 * @function terminatePlanInstance
	 * @param {number} id - Plan Instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.terminatePlanInstance(1337);
	 */
	terminatePlanInstance: (id) => PUT({
		url: urls.terminate.url(id)
	}),

	/**
	 * @function deletePlanInstance
	 * @param {number} id - Plan Instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.deletePlanInstance(1337);
	 */
	deletePlanInstance: (id) => DELETE({
		url: urls.single(id)
	})
};
