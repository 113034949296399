import MultipleEntities from '../shared/multiple-entities';
import TreatmentActivity from './activity-entity';
import urls from './urls';

/**
 * TreatmentActivities Entity
 * @class TreatmentActivities
 * @extends MultipleEntities
 */
const TreatmentActivities = MultipleEntities.extend({
	model: TreatmentActivity,
	urls,
	eventNamespace: 'treatment-activity',

	list: function (params) {
		return this.retrieve('treatmentActivities', params);
	}
});

export default new TreatmentActivities();
