var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", {
        staticClass: "consent-preview__header",
        domProps: { innerHTML: _vm._s(_vm.name) }
      }),
      _vm._v(" "),
      _c("consent-content", {
        attrs: { options: _vm.options },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
              ]
            },
            proxy: true
          },
          {
            key: "optionContent",
            fn: function(slotProps) {
              return [
                _c("div", {
                  domProps: { innerHTML: _vm._s(slotProps.content) }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }