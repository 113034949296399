var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "content-field",
          { attrs: { label: _vm.$t("editAdapterInstanceSetting.code") } },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.adapterSetting.code) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceSetting.name")
          },
          model: {
            value: _vm.form.name,
            callback: function($$v) {
              _vm.$set(_vm.form, "name", $$v)
            },
            expression: "form.name"
          }
        }),
        _vm._v(" "),
        _vm.adapterSetting.type !== "BOOLEAN"
          ? _c("text-input", {
              attrs: {
                translate: false,
                label: _vm.$t("editAdapterInstanceSetting.value"),
                invalid: _vm.$v.form.value.$error,
                required: _vm.adapterSetting.required,
                mandatory: _vm.adapterSetting.required,
                numeric: _vm.adapterSetting.type === "INTEGER"
              },
              scopedSlots: _vm._u(
                [
                  _vm.$v.form.value.$error
                    ? {
                        key: "validations",
                        fn: function() {
                          return [
                            !_vm.$v.form.value.required
                              ? _c("p", [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("general.validation.mandatory")
                                      ) +
                                      "\n\t\t\t\t"
                                  )
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              ),
              model: {
                value: _vm.form.value,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "form.value"
              }
            })
          : _c("checkbox-input", {
              attrs: {
                translate: false,
                label: _vm.$t("editAdapterInstanceSetting.value"),
                invalid: _vm.$v.form.value.$error,
                required: _vm.adapterSetting.required,
                mandatory: _vm.adapterSetting.required
              },
              scopedSlots: _vm._u(
                [
                  _vm.$v.form.value.$error
                    ? {
                        key: "validations",
                        fn: function() {
                          return [
                            !_vm.$v.form.value.required
                              ? _c("p", [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("general.validation.mandatory")
                                      ) +
                                      "\n\t\t\t\t"
                                  )
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              ),
              model: {
                value: _vm.form.value,
                callback: function($$v) {
                  _vm.$set(_vm.form, "value", $$v)
                },
                expression: "form.value"
              }
            }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceSetting.description"),
            multiline: ""
          },
          model: {
            value: _vm.form.description,
            callback: function($$v) {
              _vm.$set(_vm.form, "description", $$v)
            },
            expression: "form.description"
          }
        }),
        _vm._v(" "),
        _c(
          "button-group",
          { attrs: { "align-right": "" } },
          [
            _c("submit-button", {
              attrs: {
                "can-submit": !_vm.processing,
                label: _vm.$t("general.buttons.save")
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }