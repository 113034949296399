import can from 'acl-can';
import cardUrl from 'cwcardurl';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';

export default TileView.extend({
	title: t('Consent options'),
	actions: ['consent/initConsentOptions'],

	Vue: (tile) => ({
		data: () => ({
			consentId: tile.cardContext().get('consentId'),
			labels: {
				empty: t('There is no consent options'),
				name: t('Consent options'),
				editItem: t('Edit consent option'),
				editQuestion: t('Are you sure you want edit this consent option?'),
				removeItem: t('Remove consent option'),
				removeQuestion: t('Are you sure you want remove this consent option?'),
				removeSuccess: t('Consent option removed successfully')
			},
			getters: {
				allItems: '',
				items: 'consent/options'
			},
			actions: {
				addItem: '',
				removeItem: 'consent/removeConsentOption'
			}
		}),
		computed: {
			canAdd: () => can.edit('system.settings.consents')
		},
		methods: {
			newConsentOption () {
				const consentId = store.state.cardData.consentId;
				cardUrl.openCard('edit-consent-option', { consentId });
			}
		},
		template: `
			<div class="consent__consent-option-container">
				<assign-items
					:use-search="false"
					:can-edit="true"
					:labels="labels"
					:getters="getters"
					:actions="actions" 
				/>
				<div class="consent-button__option-container">
					<button
						:disabled="!canAdd"
						class="consents-dialog__button consents-dialog__button--submit"
						@click="newConsentOption"
						v-translate
					>New consent option</button>
				</div>
			</div>
		`
	})
});
