import t from 'translate';
import CardDialog from 'service/card-dialog/card-dialog';
import Vue from 'vue';
import store from 'store';
import AddModule from '../components/add-module';
import appContext from 'service/app-context';

export default () => {
	const dialog = new Vue({
		store,
		el: document.createElement('div'),
		...AddModule
	});

	dialog.$on('success', () => {
		appContext.trigger('dialog:cancel');
	});

	dialog.$on('cancel', () => {
		appContext.trigger('dialog:cancel');
	});

	return new CardDialog({
		preventFocusTrap: true,
		title: t('treatmentProgram.module.add'),
		content: dialog.$el
	});
};
