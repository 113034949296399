<template>
	<div>
		<p
			v-if="repeated"
			v-translate
			class="data-integration__repeated-warning"
		>dataIntegration.dataMapping.messages.repeated</p>

		<select-input
			v-model="item.superAssessment"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.superAssessment.$error"
			label="dataIntegration.dataMapping.fields.assessment"
			:mandatory="true"
			:options="assessmentOptions"
			:placeholder="t('dataIntegration.dataMapping.actions.selectAssessment')"
			:searchable="false"
			@input="vuelidate.item.superAssessment.$touch()"
		/>

		<select-input
			v-model="item.submitPortal"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.submitPortal.$error"
			label="dataIntegration.dataMapping.fields.portal"
			:mandatory="true"
			:options="portalOptions"
			:placeholder="t('dataIntegration.dataMapping.actions.selectPortal')"
			:searchable="false"
			@input="vuelidate.item.submitPortal.$touch()"
		/>

		<select-input
			v-model="codeType"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			label="dataIntegration.dataMapping.fields.codeType"
			:mandatory="true"
			:options="codeTypes"
			:placeholder="t('dataIntegration.dataMapping.actions.selectCodeType')"
			:searchable="false"
		/>

		<select-input
			v-model="item.code"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="!codeType"
			label="dataIntegration.dataMapping.fields.code"
			:mandatory="true"
			:options="codes"
			:placeholder="t('dataIntegration.dataMapping.actions.selectCode')"
			:searchable="false"
			@input="vuelidate.item.code.$touch()"
		/>

		<radio-input
			v-model="item.access"
			:value-name="true"
			label="dataIntegration.ruleSets.fields.allowed"
			class-prefix="data-integration"
			@input="vuelidate.item.access.$touch()"
		/>
		<radio-input
			v-model="item.access"
			:value-name="false"
			label="dataIntegration.dataMapping.fields.denied"
			class-prefix="data-integration"
			@input="vuelidate.item.access.$touch()"
		/>
	</div>
</template>

<script>
import t from 'translate';
import { mapGetters } from 'vuex';
import { filter, find, get, map, toUpper } from 'lodash';

export default {
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		item: {
			type: Object,
			required: true
		},
		assessmentOptions: {
			type: Array,
			default: () => []
		},
		codeOptions: {
			type: Array,
			default: () => []
		},
		codeTypeOptions: {
			type: Array,
			default: () => []
		},
		portalOptions: {
			type: Array,
			default: () => []
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},
	data () {
		const codeTypes = map(this.codeTypeOptions, (codeType) => ({
			id: codeType.id,
			label: codeType.code
		}));

		return {
			codeTypes,
			repeated: false,
			codeType: find(
				codeTypes,
				{ label: get(this.item, 'code.codeType.code') }
			) || null
		};
	},

	computed: {
		...mapGetters({
			dataMappingList: 'dataIntegration/dataMappingList'
		}),

		codes: ({ codeOptions, codeType }) => filter(
			codeOptions,
			['codeType.id', get(codeType, 'id')]
		)
	},
	watch: {
		'item.superAssessment' () {
			this.checkIfUnique();
		},
		'item.submitPortal' () {
			this.checkIfUnique();
		},
		codeType () {
			this.item.code = null;
		}
	},
	methods: {
		checkIfUnique () {
			this.repeated = !!filter(this.dataMappingList, (dataMapping) => {
				const assessment = this.item.superAssessment;
				const repeatedAssessment = assessment ?
					+dataMapping.superAssessment.id === +assessment.assessmentSuperAssessmentId :
					false;
				const repeatedPortal = this.item.submitPortal ?
					toUpper(dataMapping.submitPortal) === this.item.submitPortal.value :
					false;
				return repeatedAssessment && repeatedPortal;
			}).length;
			this.$emit('repeated', this.repeated);
		},
		t
	}
};
</script>
