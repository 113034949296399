import ShortcutTile from 'components/mini-tiles/shortcut';
import t from 'translate';
import store from 'store';

const cardParams = () => {
	if (store.getters.treatment) {
		return { treatmentId: store.getters.treatment.id };
	}
};

export default ShortcutTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],
	targetCard: () => ['connected-apps', cardParams()],
	content: t('Connected Apps')
});
