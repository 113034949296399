import t from 'translate';
import repository from 'repository';
import appBar from 'app-bar';
import addContentPackageCategoryDialog from '../dialogs/new-content-package-category';
import can from 'acl-can';

const card = {
	title: t('Content package categories'),
	name: 'content-package-categories',
	url: 'content-package-categories',

	ctrl: ({ cardData }) => {
		const contentPackageCategories = repository.contentPackageCategories();
		cardData.set({ contentPackageCategories });

		if (can.add('system.settings.informationpackages')) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New content package category'),
				click: addContentPackageCategoryDialog
			});
		}

		return contentPackageCategories;
	}
};

export { card as contentPackageCategories };
