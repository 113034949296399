import { assign, get } from 'lodash';
import TileView from 'tile-view';
import Table from 'components/table/table';
import cardurl from 'cwcardurl';
import t from 'translate';
import { respondentName } from 'service/display-name/display-name';
import datetime from 'datetime';
import store from 'store';

export default TileView.extend({
	title: t('Flags'),
	acl: [{
		checkpoint: 'administrator.respondents.flags',
		op: 'READ'
	}, {
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	actions: ['flags/init'],

	loaded: ({ tile }) => {
		assign(tile, {
			respondent: store.getters.respondent,
			treatment: store.getters.treatment,
			flags: get(store.state, 'flags.collection')
		});

		tile.table = new Table({
			parent: tile.el,
			collection: tile.flags,
			emptyListMessage: t('No flags'),
			sortable: true,
			sortOrder: tile.config().tableSort,
			click: (item) => {
				cardurl.openCard('flag', { flagId: item.getId() });
			},
			columns: [{
				key: 'date',
				label: t('Date'),
				render: (date, { item }) => {
					const dateObject = item.get('created');
					let dateString = t('Not specified');

					if (dateObject) {
						dateString = datetime(dateObject)
							.setTimezone(store.getters.userTimezone)
							.toMoment()
							.format(t('date.formats.dateTime'));
					}

					return dateString;
				}
			}, {
				key: 'assessmentFlagName',
				label: t('Name'),
				render: (flagName, { item }) => item.get('assessmentFlag').assessmentFlagName
			}, {
				key: 'assessmentFlagCode',
				label: t('Code'),
				render: (flagCode, { item }) => item.get('assessmentFlag').assessmentFlagCode
			}, {
				key: 'role',
				label: t('Role'),
				render: (role) => get(role, 'name')
			}, {
				key: 'respondent',
				label: t('Respondent'),
				render: (respondent) => respondentName(respondent)
			}]
		});
	}
});

