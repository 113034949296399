import TileView from 'tile-view';
import can from 'acl-can';
import t from 'translate';
import cardurl from 'cwcardurl';
import Table from 'components/table/table';
import repository from 'repository';
import $ from 'jquery';

export default TileView.extend({
	acl: [{
		checkpoint: 'system.settings.remotescoring',
		op: 'OPEN'
	}],
	title: t('system-settings.sidebar.RemoteScoring'),

	tileData: () => ({ remoteScoringList: repository.getRemoteScoringAssessments() }),

	loaded: ({ tile }) => {
		tile.table = new Table({
			css: 'wide-table',
			parent: tile.el,
			emptyListMessage: t('No assessments with remote scoring available'),
			collection: tile.remoteScoringList,
			readonly: !can.edit('system.settings.remotescoring'),

			columns: {
				assessmentCode: {
					label: t('remotescoring-management.tableheader.AssessmentCode'),
					key: 'assessmentCode'
				},
				assessmentQueueTopic: {
					label: t('remotescoring-management.tableheader.QueueTopic'),
					key: 'assessmentQueueTopic'
				},
				assessmentRemoteScoringEnabled: {
					className: 'remote-scoring-list__enabled',
					label: t('remotescoring-management.tableheader.ScoreRemotely'),
					key: 'assessmentRemoteScoringEnabled',
					render: (checked) =>
						$(`<input type="checkbox" disabled ${checked && 'checked'} />`)[0]
				}
			},

			click: (item) => {
				cardurl.openCard('system-settings-remote-scoring-edit', {
					assessmentId: item.get('assessmentId')
				});
				return false;
			}
		});
	}
});

