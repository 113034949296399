import t from 'translate';

const card = {
	title: t('Export respondent groups'),
	name: 'system-exports-respondent-groups',
	url: 'system-exports/respondent-groups'

};

export { card as respondentGroups };
