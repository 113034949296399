import TileView from 'tile-view';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import { get } from 'lodash';
import can from 'acl-can';

export default TileView.extend({
	title: t('Media categories'),
	acl: [{
		op: 'READ',
		checkpoint: 'system.settings.media'
	}],
	instant: true,

	Vue: () => ({
		data: () => ({
			maxSize: 64
		}),

		template:
			`<div>
				<p v-if="!categories.length">${t('Media categories list is empty')}</p>
				<ul>
					<li
						v-for="category in categories.models"
						:key="category.getId()"
						@deleteComponent="deleteComponent(category)"
					>
						<inline-edit
							:model="category"
							:onSave="onSave"
							:onDelete="onDelete"
							:maxSize="maxSize"
							:allowEdit="allowEdit"
							:allowDelete="allowDelete"
						/>
					</li>
				</ul>
			</div>`,

		computed: {
			categories () {
				return get(this.$store.state, 'media.categories.collection');
			},
			allowEdit () {
				return can.edit('system.settings.media');
			},

			allowDelete () {
				return can.delete('system.settings.media');
			}
		},

		methods: {
			deleteComponent (category) {
				this.categories.splice(this.categories.indexOf(category), 1);
			},

			onSave: ({ model, data }) => {
				model.set('name', data.name);

				return model.save().then(() => {
					cwalert.success(t('Success'));
				}).fail(() => {
					cwalert.error(t('Error'));
				});
			},

			onDelete: ({ model, data }) =>
				confirmation({
					title: t('Remove item'),
					icon: 'category',
					warning: true,
					message: t('Are you sure you want to remove category {category}?',
						{ category: data.name })
				}).then(() => model.destroy().then(() => {
					cwalert.success(t('Category has been deleted'));
				}, () => {
					cwalert.error(t('Error'));
				}))
		}
	})
});
