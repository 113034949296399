var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-variant" }, [
    _c("h3", [
      _vm._v("\n\t\t" + _vm._s(_vm.$t("menuComposer.variant.title")) + "\n\t")
    ]),
    _vm._v(" "),
    _vm.variantOptions.length > 1
      ? _c(
          "div",
          { staticClass: "sidebar-variant__section" },
          [
            _c("div", { staticClass: "sidebar-variant__selected" }, [
              _vm.editingVariantName
                ? _c(
                    "form",
                    {
                      staticClass: "sidebar-variant__form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.changeVariantName(_vm.tempVariantName)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sidebar-variant__select sidebar-variant__select--variant"
                        },
                        [
                          _c("text-input", {
                            attrs: {
                              translate: false,
                              label: _vm.$t("menuComposer.variant.variantName")
                            },
                            model: {
                              value: _vm.tempVariantName,
                              callback: function($$v) {
                                _vm.tempVariantName = $$v
                              },
                              expression: "tempVariantName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "sidebar-variant__actions" }, [
                        _c(
                          "button",
                          {
                            staticClass: "sidebar-variant__button",
                            attrs: {
                              type: "submit",
                              "aria-label": _vm.$t("general-list.Save")
                            }
                          },
                          [_c("icon", { attrs: { name: "check", small: "" } })],
                          1
                        )
                      ])
                    ]
                  )
                : _c(
                    "form",
                    {
                      staticClass: "sidebar-variant__form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.editVariant($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sidebar-variant__select sidebar-variant__select--variant"
                        },
                        [
                          _c("select-input", {
                            attrs: {
                              "item-label": "name",
                              translate: false,
                              label: _vm.$t("menuComposer.variant.variantName"),
                              options: _vm.variantOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.selectedVariant,
                              callback: function($$v) {
                                _vm.selectedVariant = $$v
                              },
                              expression: "selectedVariant"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isDefaultVariant
                        ? _c(
                            "div",
                            { staticClass: "sidebar-variant__actions" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "sidebar-variant__button",
                                  attrs: {
                                    type: "submit",
                                    title: _vm.$t(
                                      "menuComposer.variant.changeVariantName"
                                    )
                                  }
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "edit", small: "" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "sidebar-variant__button",
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t(
                                      "menuComposer.variant.removeVariant"
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeVariant(
                                        _vm.selectedVariant.name
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "delete", small: "" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
            ]),
            _vm._v(" "),
            !_vm.isDefaultVariant
              ? _c("variant-criteria", {
                  attrs: {
                    type: _vm.type,
                    "selected-variant": _vm.selectedVariant
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sidebar-variant__section" },
      [
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("menuComposer.variant.newVariantName")
          },
          model: {
            value: _vm.newVariantName,
            callback: function($$v) {
              _vm.newVariantName = $$v
            },
            expression: "newVariantName"
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { type: "submit", disabled: !_vm.newVariantName },
            on: {
              click: function($event) {
                return _vm.cloneVariant(_vm.newVariantName)
              }
            }
          },
          [
            _c("icon", { attrs: { name: "plus", small: "" } }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("menuComposer.variant.clone")) +
                  "\n\t\t\t"
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }