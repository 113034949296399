var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "menu-composer" },
    [
      _c(
        "div",
        { staticClass: "type" },
        [
          _c("radio-input", {
            attrs: {
              "class-prefix": "type",
              "value-name": "clinician",
              label: "Clinician Portal"
            },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          }),
          _vm._v(" "),
          _c("radio-input", {
            attrs: {
              "class-prefix": "type",
              "value-name": "respondent",
              label: "Respondent Portal"
            },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("variant-edit", { attrs: { type: _vm.type } }),
      _vm._v(" "),
      _c("h2", [_vm._v("Menu items")]),
      _vm._v(" "),
      _c("table", { staticClass: "table menu-composer__table" }, [
        _c("thead", [
          _c("tr", [
            _c("th"),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.labels.label))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.labels.destination))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.labels.style))]),
            _vm._v(" "),
            _c("th")
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "tr",
              {
                key: index,
                staticClass: "clickable-row",
                attrs: { tabindex: "0" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.editItem(index)
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.editItem(index)
                  }
                }
              },
              [
                _c(
                  "td",
                  { staticClass: "menu-composer__item--order" },
                  [
                    index > 0 && _vm.allowEdit
                      ? _c("button-up", {
                          attrs: { "on-click": _vm.moveUp(index) }
                        })
                      : _vm._e(),
                    _c("br"),
                    _vm._v(" "),
                    index < _vm.items.length - 1 && _vm.allowEdit
                      ? _c("button-down", {
                          attrs: { "on-click": _vm.moveDown(index) }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "menu-composer__item menu-composer__item--label"
                  },
                  [
                    _c("p", { staticClass: "table-cell-label" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.labels.label) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.label))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "menu-composer__item menu-composer__item--destination"
                  },
                  [
                    _c("p", { staticClass: "table-cell-label" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.labels.destination) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.target))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "menu-composer__item menu-composer__item--style"
                  },
                  [
                    _c("p", { staticClass: "table-cell-label" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.labels.style) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.style))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "menu-composer__item menu-composer__item--remove"
                  },
                  [
                    _vm.allowDelete
                      ? _c("button-remove", {
                          attrs: {
                            "on-click": _vm.remove({ item: item, index: index })
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }