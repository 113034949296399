import MultipleEntities from '../shared/multiple-entities';
import Role from './role-entity';
import urls from './urls';
import { isEmpty } from 'lodash';

/**
 * TreatmentRoles Entity
 * @class TreatmentRoles
 * @extends MultipleEntities
 */
const TreatmentRoles = MultipleEntities.extend({
	model: Role,
	urls,
	eventNamespace: 'treatment-role',

	canAdd: function (model) {
		return +model.get('treatment') === +this.ownerId;
	},

	/**
	 * Get all treatment roles
	 * @method getByTreatment
	 * @return {Object} new RolesEntity Collection
	 * @example
	 *        Repository.TreatmentRoles.getByTreatment(1);
	 */
	getByTreatment: function (treatmentId) {
		return this.retrieve('listRoles', treatmentId);
	},

	/**
	 * Get all treatment assigned roles
	 * @method getNoRolesByTreatment
	 * @return {Object} new RolesEntity Collection
	 * @example
	 *        Repository.TreatmentRoles.getNotAssignedRolesByTreatment(1);
	 */

	getAssignedRolesByTreatment: function (treatmentId, dataSources) {
		return this.retrieve('listWithRoles', treatmentId, dataSources);
	},

	/**
	 * Get all treatment unassigned roles
	 * @method getNoRolesByTreatment
	 * @return {Object} new RolesEntity Collection
	 * @example
	 *        Repository.TreatmentRoles.getNotAssignedRolesByTreatment(1);
	 */
	getUnassignedRolesByTreatment: function (treatmentId, dataSources) {
		const collection = this.retrieve('listWithoutRoles', treatmentId, dataSources);

		// parsing response object due to backend inconsistency
		collection.parse = function (response) {
			if (!isEmpty(response)) {
				response.respondent.type = 'RESPONDENT';
				response.clinician.type = 'CLINICIAN';
				response.respondent.stats = response.respondent;
				response.clinician.stats = response.clinician;
				return [response.respondent, response.clinician];
			}
		};

		return collection;
	}
});

export default new TreatmentRoles();
