import Backbone from 'backbone';

const prefix = 'backend-commons/dataexport/template';

export default {
	templates: {
		url: 'backend-commons/dataexport/template/all'
	},

	readyTemplates: {
		url: 'backend-commons/dataexport/template/all?ready=true'
	},

	template: {
		url: 'backend-commons/dataexport/template/'
	},

	columns: {
		url: (exportTemplateId) => `backend-commons/dataexport/templatecolumn/bytemplate/${exportTemplateId}`
	},

	column: {
		url: 'backend-commons/dataexport/templatecolumn/'
	},

	assessmentFields: {
		url: 'backend-commons/dataexport/templatecolumn/field?fields=assessment'
	},

	respondentFields: {
		url: 'backend-commons/dataexport/templatecolumn/field?fields=respondent'
	},

	treatmentFields: {
		url: 'backend-commons/dataexport/templatecolumn/field?fields=treatment'
	},

	treatmentTypeAttributes: {
		url: (columnId) => `backend-commons/dataexport/templatecolumn/${columnId}/treatment-type-attribute/`
	},

	treatmentTypeAttribute: {
		url () {
			return `backend-commons/dataexport/templatecolumn/${this.get('columnId')}/treatment-type-attribute/${this.getId() || ''}`;
		}
	},

	instances: {
		url: 'backend-commons/dataexport/instance/all'
	},

	instance: {
		url: 'backend-commons/dataexport/instance/'
	},

	instanceDownload: {
		url: (exportInstanceId) => `${Backbone.config().backendRootUrl['default']}backend-commons/dataexport/instance/content/${exportInstanceId}`
	},

	clinicianGroups: (templateId) => `${prefix}/${templateId}/clinician-group/`,

	clinicianUpdate: (data) => {
		const suffix = !isNaN(data.id) ? `/${data.id}` : `/`;
		return `${prefix}/${data.templateId}/clinician-group${suffix}`;
	},

	treatmentUpdate: (data) => {
		const suffix = !isNaN(data.id) ? `/${data.id}` : `/`;
		return `${prefix}/${data.templateId}/treatment-type${suffix}`;
	},

	availableTreatmentTypes: (templateId) =>
		`backend-commons/dataexport/template/${templateId}/treatment-type/available/`,

	treatmentTypes: (templateId) => `${prefix}/${templateId}/treatment-type/`,

	lockTemplate: (templateId) => `backend-commons/dataexport/template/${templateId}/lock/`,

	unlockTemplate: (templateId) => `backend-commons/dataexport/template/${templateId}/unlock/`,

	exportTemplate: (templateId) => `backend-commons/dataexport/template/${templateId}/content/`,

	templateData: (templateId) => `${prefix}/${templateId}`,

	consentOption: ({ id, optionId }) => `${prefix}/${id}/consent-option/${optionId}`,

	consentOptions: (id) => `${prefix}/${id}/consent-option/`
};
