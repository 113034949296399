import t from 'translate';
import { when } from 'jquery';
import repository from 'repository';

const card = {
	name: 'overdue-assessments-list',
	url: 'overdue-assessments',
	title: t('Overdue Assessments'),

	ctrl: ({ cardConfig, cardData }) => {
		const contexts = repository.getContexts();
		const types = repository.getAssessmentTypes();

		return when(contexts, types).then(() => {
			cardData.set({
				limit: cardConfig.limit,
				contexts,
				types
			});
		});
	}
};

export { card as OverdueAssessmentsList };
