var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tile-list" }, [
    _vm.allowEdit
      ? _c(
          "button",
          { staticClass: "tile-list__add-tile", on: { click: _vm.addTile } },
          [_vm._v(_vm._s(_vm.t("cardConfig.tileList.addBlankTile")))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.allowEdit
      ? _c(
          "button",
          { staticClass: "tile-list__add-tile", on: { click: _vm.importTile } },
          [_vm._v(_vm._s(_vm.t("cardConfig.tileList.importTile")))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "tile-list__list-container" }, [
      _c("table", { staticClass: "tile-list__list" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th", { attrs: { scope: "col" } }),
              _vm._v(" "),
              _vm._l(_vm.fields, function(field, index) {
                return _c(
                  "th",
                  {
                    key: index,
                    staticClass: "tile-list__list-title",
                    class: "tile-list__list-title--" + field.name,
                    attrs: { scope: "col" }
                  },
                  [_vm._v(_vm._s(_vm.label[field.name]))]
                )
              }),
              _vm._v(" "),
              _c("th", { attrs: { scope: "col" } })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.tiles, function(tile, index) {
            return _c(
              "tr",
              {
                key: index,
                staticClass: "tile-list__list-row",
                attrs: { tabindex: "0" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.editTile(tile, index)
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.editTile(tile, index)
                  }
                }
              },
              [
                _c(
                  "td",
                  {
                    staticClass:
                      "tile-list__list-item tile-list__list-item--order"
                  },
                  [
                    _vm.canMoveUp(index)
                      ? _c("button-up", {
                          attrs: { "on-click": _vm.moveUp(index) }
                        })
                      : _vm._e(),
                    _c("br"),
                    _vm._v(" "),
                    _vm.canMoveDown(index)
                      ? _c("button-down", {
                          attrs: { "on-click": _vm.moveDown(index) }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.fields, function(field, fieldIndex) {
                  return _c(
                    "td",
                    {
                      key: fieldIndex,
                      staticClass: "tile-list__list-title",
                      class: "tile-list__list-item--" + field.name
                    },
                    [
                      _c("p", { staticClass: "tile-list__list-value" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.fieldValue(tile, field)) +
                            "\n\t\t\t\t\t\t"
                        )
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "tile-list__list-item tile-list__list-item--remove"
                  },
                  [
                    _vm.allowDelete
                      ? _c("button-remove", {
                          attrs: {
                            disabled: _vm.removingTile,
                            "on-click": _vm.remove(tile, index)
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            )
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _vm.allowEdit
      ? _c(
          "button",
          { staticClass: "tile-list__add-tile", on: { click: _vm.addTile } },
          [_vm._v(_vm._s(_vm.t("cardConfig.tileList.addBlankTile")))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.allowEdit
      ? _c(
          "button",
          { staticClass: "tile-list__add-tile", on: { click: _vm.importTile } },
          [_vm._v(_vm._s(_vm.t("cardConfig.tileList.importTile")))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }