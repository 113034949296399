import MultipleEntities from '../shared/multiple-entities';
import MessageDraft from './message-draft';
import urls from './urls';

/**
 * MessageDrafts Entity
 * @class MessageDrafts
 * @extends MultipleEntities
 */
const MessageDrafts = new (MultipleEntities.extend({
	model: MessageDraft,
	urls,
	eventNamespace: 'message-draft',

	/**
	 * Get a collection of all MessageDrafts for a given treatment and respondent
	 * @method getForTreatment
	 * @param {Object} params
	 * @param {number} params.treatment
	 * @param {number} params.respondent
	 * @return {Object} new {{#crossLink "MessageDrafts"}}{{/crossLink}} Collection
	 * @example
	 * 		repository.MessageDrafts.getForTreatment({
	 * 			treatment: 7,
	 * 			respondent: 19
	 * 		});
	 */
	getForTreatment: function ({ treatment, respondent }) {
		return this.retrieve('messageDrafts', { treatment, respondent });
	}
}))();

export { MessageDrafts };
