import SingleEntity from '../shared/single-entity';
import urls from './urls';
import fileUrl from 'service/file-url/file-url';

/**
 * File
 * @class File
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'file',
	urlRoot: urls.single.url,

	getUrl: function () {
		return fileUrl(this.get('url'));
	}
});
