import TileView from 'tile-view';
import t from 'translate';
import CardConfigCheckTile from './card-config-check-tile';

export default TileView.extend({
	title: t('cardConfig.healthCheck.title'),
	instant: true,
	acl: [{
		checkpoint: 'system.settings.settings',
		op: 'READ'
	}],

	vueComponent: () => ({ CardConfigCheckTile })
});
