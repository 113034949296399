import TileView from 'tile-view';
import t from 'translate';
import can from 'acl-can';
import Respondent from 'repo/respondents/respondent';
import formView from 'components/form-view/form-view';
import datetime from 'datetime';
import store from 'store';
import { clone, forEach, get, isObject, isString, split } from 'lodash';
import systemSettings from 'service/system-settings/system-settings';
import showField from 'service/show-field/index';

export default TileView.extend({
	title: t('admin-portal.Personalia'),
	acl: [{
		checkpoint: 'administrator.respondents.respondents',
		op: 'READ'
	}],

	actions: ['respondent/init', 'languages/init', 'timezones/init'],

	loaded: ({ tile }) => {
		const respondentModel = new Respondent(store.getters.respondent);

		const isPersonaliaReadonly = !can.edit('administrator.respondents.respondents');

		const form = formView({
			name: 'respondent-personalia',
			model: respondentModel,
			saveIndicator: true,
			successMessage: t('general-list.ChangesSaved'),
			autocomplete: false,
			disabled: isPersonaliaReadonly,
			errorMessage (model) {
				const result = model.responseJSON;

				if (!!result.exception && !!result.exception.msg) {
					// [pats] this is terrible, maybe we should use exception code
					// dictionary or something like that
					const msg = result.exception.msg ? t(result.exception.msg) : '';
					return `${t('general-list.ChangesNotSaved')} ${msg}`;
				}
				return '';
			},
			beforeSave () {
				const attrs = {};

				if (tile.cardContext().get('attributes')) {
					forEach(tile.cardContext().get('attributes').models, (attr) => {
						attrs[
							attr.get('fk_respondent_attribute_respondent_attribute_meta_id')
						] = attr.get('respondentAttributeValue');
					});
				}

				/* The same date, in midnight UTC */
				const respondentBirthdate = this.model.get('respondentBirthdate');

				if (isString(respondentBirthdate) && respondentBirthdate.length) {
					const date = datetime(respondentBirthdate).toObject();
					this.model.set('respondentBirthdate', date, {
						silent: true
					});

				} else if (!isObject(respondentBirthdate)) {
					this.model.set('respondentBirthdate', null, {
						silent: true
					});
				}

				this.model.set({
					attributes: attrs
				}, {
					silent: true
				});
			},
			onAfterSave () {
				const updatedRespondent = clone(respondentModel.get('attributes'));

				if (isObject(updatedRespondent.attributes)) {
					delete updatedRespondent.attributes;
				}

				// if it's not the respondent-edit card this field can be missing
				if (tile.cardContext().get('respondent')) {
					tile.cardContext().get('respondent').set(updatedRespondent);
				}
			},
			fields: [{
				key: 'respondentFirstName',
				type: 'text'
			}, {
				key: 'respondentLastName',
				type: 'text'
			}, showField({
				config: get(tile.config(), 'fields', {}),
				field: {
					key: 'respondentUsername',
					type: 'paragraph',
					hint: t('general-list.userNameHint')
				}
			}), {
				key: 'respondentLanguageId',
				type: 'select',
				values: store.getters['languages/available']
			}, {
				key: 'respondentPassword',
				type: 'password'
			}, {
				label: t('PhoneAndCode'),
				key: 'respondentPhone',
				type: 'tel'
			}, {
				key: 'respondentEmail',
				type: 'email',
				validators: 'email'
			}, {
				key: 'respondentGender',
				type: 'select',
				values: [
					{ 0: t('general-list.Female') },
					{ 1: t('general-list.Male') },
					{ 2: t('general-list.Unknown') }
				]
			}, {
				key: 'respondentBirthdate',
				type: 'date'
			}, {
				key: 'respondentAddress',
				type: 'text'
			}, {
				key: 'respondentTimezoneName',
				type: 'select',
				values: store.getters['timezones/list']
			}, {
				label: t('Authentication level'),
				key: 'authenticationLevel',
				type: 'select',
				disabled: (
					!systemSettings.getBoolean('RESPONDENT_SECURITY_ENABLE_AUTH_LVL_SELECT') ||
					isPersonaliaReadonly
				),
				values: split(
					get(
						store.state,
						`user.backend-config['respondent.security.available-auth-levels']`,
						[]
					),
					','
				),
				setNullLabel: t('Default level ({level})', {
					level: systemSettings.getString('RESPONDENT_SECURITY_DEFAULT_AUTH_LVL')
				})
			}, {
				key: 'respondentCommunicationDisabled',
				type: 'checkbox'
			}, {
				key: 'respondentAccountEnabled',
				type: 'checkbox'
			}, {
				key: 'respondentTestAccount',
				type: 'checkbox'
			}]
		});

		tile.$el.append(form.$el);
	}
});

