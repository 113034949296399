import allow from '../../helpers/allow';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import change from '../../helpers/change';
import enabled from '../../helpers/enabled';
import onSuccessHandler from '../../helpers/on-success-handler';
import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import store from 'store';
import t from 'translate';
import $ from 'jquery';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { assign, get, map, toLower } from 'lodash';
import categoryTag from 'components/category-tag/category-tag';

const FIELD_TYPE = 'single-select';

const removalCfg = {
	action: 'dataIntegration/ruleSets/delete',
	question: 'dataIntegration.ruleSets.actions.delete',
	title: 'dataIntegration.ruleSets.actions.remove'
};

const transformValues = (list) => map(list, (type) => assign(
	type,
	{ label: type.name }
));

export default SearchTileView.extend({
	instant: true,
	title: t('dataIntegration.ruleSets.name'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
		op: READ
	}],

	searchFn (params) {
		const result = store.dispatch(
			'dataIntegration/ruleSets/search',
			assign({ name: 'ruleSet' }, params)
		);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},

	fields: ({ tile }) => [{
		key: 'treatmentType.id',
		type: FIELD_TYPE,
		label: t('dataIntegration.ruleSets.fields.treatmentType'),

		values: map(store.getters['dataIntegration/treatmentTypesFilterList'], (treatmentType) =>
			assign({}, treatmentType, { id: treatmentType.id, label: treatmentType.name })),

		onChange: (value) => change({ field: 'treatmentType.id', tile, value })
	}, {
		key: 'enterprise.id',
		type: FIELD_TYPE,
		label: t('dataIntegration.ruleSets.fields.enterprise'),

		values: [
			{ id: null, label: t('dataIntegration.enterprise.options.noEnterprise') },
			...map(
				store.getters['dataIntegration/enterprises/sorted'],
				(enterprise) => assign(
					{},
					enterprise,
					{ id: enterprise.id, label: enterprise.name }
				)
			)
		],

		onChange: (value) => change({ field: 'enterprise.id', tile, value })
	}, {
		key: 'defaultCodes',
		type: FIELD_TYPE,
		label: t('dataIntegration.ruleSets.fields.code'),

		values: map(store.getters['dataIntegration/codes/enabled'], (code) =>
			assign({}, code, { id: code.id, label: code.name })),

		onChange: (value) =>
			change({ field: 'defaultCodes', tile, value })

	}, {
		key: 'defaultAccess',
		type: FIELD_TYPE,
		label: t('dataIntegration.ruleSets.fields.allow'),
		values: transformValues(allow),

		onChange: (value) => change({ field: 'defaultAccess', tile, value })
	}, {
		key: 'enabled',
		type: FIELD_TYPE,
		label: t('dataIntegration.ruleSets.fields.enabled'),
		values: transformValues(enabled),

		onChange: (value) => change({ field: 'enabled', tile, value })
	}],

	tableCfg ({ tile }) {
		const className = 'data-integration__rule-sets';
		const config = {
			store: {
				items: () => store.getters['dataIntegration/ruleSets/list'],
				count: 'dataIntegration/ruleSets/count',
				mutation: 'dataIntegration/ruleSets/setList',
				total: 'dataIntegration/ruleSets/total'
			},
			click: (ruleSet) => {
				cardUrl.openCard('data-integration-rule-set-edit', {
					ruleSetId: ruleSet.id
				});
			},
			columns: [{
				label: t('dataIntegration.ruleSets.fields.treatmentType'),
				key: 'treatmentType',
				css: `${className}__treatmentType`,
				render: (treatmentType) => treatmentType ?
					treatmentType.name :
					t('dataIntegration.ruleSets.options.default')
			}, {
				label: t('dataIntegration.ruleSets.fields.enterprise'),
				key: 'enterprise',
				css: `${className}__enterprise`,
				render: (enterprise) => get(
					enterprise,
					'name',
					t('dataIntegration.enterprise.options.noEnterprise')
				)
			}, {
				label: t('dataIntegration.ruleSets.fields.allow'),
				key: 'defaultAccess',
				css: `${className}__allow`,
				render: (allowed) => {
					const allowClass = allowed ?
						`${className}--allowed` :
						`${className}--denied`;
					const content = allowed ?
						t('dataIntegration.ruleSets.fields.allowed') :
						t('dataIntegration.ruleSets.fields.denied');
					return $(`<span class="${allowClass}">${content}</span>`)[0];
				}
			}, {
				label: t('dataIntegration.ruleSets.fields.code'),
				key: 'defaultCodes',
				css: `${className}__code`,
				render: (defaultCodes) => categoryTag(defaultCodes, {
					iconClassName: 'icon--codes'
				})
			}, {
				label: t('dataIntegration.ruleSets.fields.role'),
				key: 'defaultMappedTo',
				css: `${className}__role`,
				render: (role) => t(`dataIntegration.ruleSets.labels.${toLower(role)}`)
			}, {
				label: t('dataIntegration.ruleSets.fields.enabled'),
				key: 'enabled',
				css: `${className}__enabled`,
				render: (enabledBool) => enabledBool ? t('yes') : t('no')
			}]
		};

		if (can.remove(SYSTEM.SETTINGS.DATA_INTEGRATION_RULES)) {
			config.columns.push({
				label: t('Delete'),
				render () {
					searchTileDelete({
						data: removalCfg,
						item: this.item,
						onSuccess: onSuccessHandler({
							tile,
							type: 'ruleSets',
							action: 'dataIntegration/ruleSets/withTreatmentTypes'
						}),
						td: this.td,
						tile
					});
				}
			});
		}
		return config;

	}
});
