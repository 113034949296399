import cardurl from 'cwcardurl';
import ClinicianRole from 'repo/access-roles/access-role-entity';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';

export default function () {
	var clinicianRole = new ClinicianRole();

	var formConfig = {
		name: 'add-role',
		model: clinicianRole,
		successMessage: t('general-list.AddedSuccessfully'),

		onAfterSave: function () {
			cardurl.openCard('system-access-management-role', {
				roleId: clinicianRole.get('administratorRoleId')
			});
		},

		fields: [{
			key: 'administratorRoleName',
			type: 'text',
			mandatory: true
		}]
	};

	var dialogConfig = {
		title: t('New management role')
	};

	formViewDialog({
		formConfig: formConfig,
		dialogConfig: dialogConfig
	});

};
