import Backbone, { View, Model } from 'backbone';
import formView from 'components/form-view/form-view';
import t from 'translate';
import repository from 'repository';
import can from 'acl-can';
import { Deferred } from 'jquery';

export default View.extend({

	title: t('Export treatments'),
	acl: [{
		checkpoint: 'system.export.treatments',
		op: 'OPEN'
	}],

	appEvents: 'treatment-type.create treatment-type.update treatment-type.delete',

	render () {
		this.setLoading();
		this.treatmentTypes = repository.treatmentTypes();
		this.treatmentTypes.done(this.renderForm.bind(this)).always(this.setLoaded.bind(this));
	},

	renderForm () {
		const DummyModel = Model.extend({
			defaults: {
				anonymous: true,
				treatmentType: null
			},
			sync () {
				const dfd = Deferred();
				const url = [
					Backbone.getBackendRoot()['default'],
					'backend-commons/treatment/export?anonymous=' + this.get('anonymous'),
					'&treatment-type=' + (this.get('treatmentType') || '')
				].join('');
				window.location = url;
				dfd.resolve();

				return dfd.promise();
			}
		});

		const cfg = {
			name: 'system-export-administrators',
			model: new DummyModel(),
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: true,
			fieldsets: [{
				name: 'filter',
				caption: t('Filter export'),
				fields: ['anonymous', 'treatmentType'],
				buttons: ['system-export-submit']
			}],

			fields: [{
				key: 'anonymous',
				type: 'checkbox',
				label: t('Unidentified respondent'),
				disabled: !can.supervise('system.export.treatments')
			}, {
				key: 'treatmentType',
				type: 'select',
				label: t('Treatment type'),
				description: t('Select a treatment type…'),
				collection: this.treatmentTypes
			}],

			buttons: [{
				caption: t('Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});

