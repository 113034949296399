import celllabel from 'cell-label';
import t from 'translate';
import { View, Model } from 'backbone';
import { forEach } from 'lodash';
import dom from 'cwdom';
import datetime from 'datetime';
import $, { when } from 'jquery';
import InactiveRespondents from '../../entities/report-inactivities';
import formView from 'components/form-view/form-view';
import appContext from 'app-context';
import repository from 'repository';

export default View.extend({

	title: t('admin-portal.InactivityReport'),
	acl: [{
		checkpoint: 'system.reports.inactiverespondents',
		op: 'OPEN'
	}],

	render () {
		this.setLoading();
		this.treatmentTypes = repository.treatmentTypes();
		this.respondentGroups = repository.respondentGroupsForCurrentClinician();

		when(this.treatmentTypes, this.respondentGroups)
			.done(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	loaded () {
		const tile = this;
		let form;
		//TODO we should create&use something like FormSearch which will extend in proper way
		// FormView and will specialized in searching
		const reportModel = new (Model.extend({
			sync () {
				return (new InactiveRespondents([], {
					from: this.get('from'),
					to: this.get('to'),
					treatmentType: this.get('treatmentType'),
					respondentGroup: this.get('respondentGroup')
				})).fetch({
					success (collection) {
						tile.report(collection.models);
						form.setLoaded();
					}
				});
			}
		}))({ from: 7 });

		form = formView({
			name: 'system-reports-inactive-respondents',
			model: reportModel,
			listenTo: ['submit'],
			loader: true,
			fields: [{
				key: 'from',
				name: 'from',
				label: t('Days from'),
				type: 'number',
				min: '0'
			}, {
				key: 'to',
				name: 'to',
				label: t('Days to'),
				type: 'number',
				min: '0'
			}, {
				key: 'treatmentType',
				name: 'treatmentType',
				setNullLabel: t('Select treatment type…'),
				label: t('Treatment type'),
				type: 'select',
				collection: this.treatmentTypes
			}, {
				key: 'respondentGroup',
				name: 'respondentGroup',
				setNullLabel: t('-- no group --'),
				label: t('Respondent groups'),
				type: 'select',
				collection: this.respondentGroups,
				keyField: 'respondentGroupId',
				labelField: 'respondentGroupName'
			}],
			buttons: [{
				caption: t('general-list.Search'),
				type: 'submit',
				name: 'search-submit'
			}]
		});

		this.$el.append(form.$el);
	},

	report (models) {
		// @TODO(2015-09-29): just rewrite it all
		$(this.table).empty();
		$(this.noItem).empty();

		if (!models.length) {
			this.noItem = dom.createElement({
				tag: 'p',
				id: 'no-inactive-message',
				role: 'alert',
				innerHTML: t('Inactive respondents list is empty'),
				parent: this.el
			});
			return;
		}

		this.table = dom.createElement({
			tag: 'table',
			id: 'report-table',
			css: 'cwtable  cwtable--modest',
			parent: this.el,
			attrs: {
				tabindex: 0
			}
		});
		const thead = dom.createElement({
			tag: 'thead',
			id: 'report-table-thead',
			parent: this.table
		});
		const thTr = dom.createElement({
			tag: 'tr',
			id: 'report-table-head-tr',
			css: 'report-table-head-tr',
			parent: thead
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('Id'),
			id: 'report-table-head-id',
			css: 'report-table-head-id',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.userName'),
			id: 'report-table-head-username',
			css: 'report-table-head-username',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.FirstName'),
			id: 'report-table-head-firstname',
			css: 'report-table-head-firstname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.LastName'),
			id: 'report-table-head-lastname',
			css: 'report-table-head-lastname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.LastLoggedIn'),
			id: 'report-table-head-lastlogin',
			css: 'report-table-head-lastlogin',
			parent: thTr
		});
		this.tbody = dom.createElement({
			tag: 'tbody',
			parent: this.table
		});

		const tbody = this.tbody;

		forEach(models, (inactiveRespondent) => {
			const tr = dom.createElement({
				tag: 'tr',
				parent: tbody
			});

			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: inactiveRespondent.attributes.respondentId
			}), t('Id'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: inactiveRespondent.attributes.respondentUsername
			}), t('general-list.userName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: inactiveRespondent.attributes.respondentFirstName
			}), t('general-list.FirstName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: inactiveRespondent.attributes.respondentLastName
			}), t('general-list.LastName'));

			if (inactiveRespondent.attributes.respondentLastLogin === null) {
				celllabel(dom.createElement({
					tag: 'td',
					parent: tr,
					innerHTML: t('system-report.NeverLoggedIn', 'Never')
				}), t('general-list.LastLoggedIn'));

			} else {
				celllabel(dom.createElement({
					tag: 'td',
					parent: tr,
					innerHTML: inactiveRespondent.attributes.respondentLastLogin ?
						datetime(inactiveRespondent.attributes.respondentLastLogin)
							.setTimezone(appContext.get('user').get('timezoneName'))
							.toMoment()
							.format(t('date.formats.dateTime')) : 'Not specified'
				}), t('general-list.LastLoggedIn'));
			}
		});
	}
});

