<template>
	<div>
		<select-input
			v-model="item.code"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.code.$error"
			label="dataIntegration.codeVariations.fields.name"
			:mandatory="true"
			:options="codeOptions"
			:placeholder="t('dataIntegration.codeVariations.actions.select')"
			:searchable="false"
			@input="vuelidate.item.code.$touch()"
		/>
		<div :class="{'data-integration__container-inactive': !item.code }">
			<text-input
				v-model="codeValue"
				:disabled="true"
				:label="t('dataIntegration.codeVariations.fields.code')"
			/>
			<text-input
				v-model="codeTypeNameValue"
				:disabled="true"
				label="dataIntegration.codeVariations.fields.codeType.name"
			/>
			<text-input
				v-model="codeTypeCodeValue"
				:disabled="true"
				label="dataIntegration.codeVariations.fields.codeType.code"
			/>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import { find, get } from 'lodash';

export default {
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		codes: {
			type: Array,
			default: () => []
		},
		codeOptions: {
			type: Array,
			default: () => []
		},
		item: {
			type: Object,
			required: true
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},

	computed: {
		codeDetails () {
			return this.item.code ? find(this.codes, { id: this.item.code.value }) : null;
		},
		codeValue () {
			return this.codeDetails ? this.codeDetails.code : '';
		},
		codeTypeCodeValue () {
			return get(this.codeDetails, 'code');
		},
		codeTypeNameValue () {
			return get(this.codeDetails, 'name');
		}
	},

	methods: { t }
};
</script>
