var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "treatment-program-properties" }, [
    _c("h3", { staticClass: "treatment-program-properties__header" }, [
      _vm._v(
        "\n\t\t" +
          _vm._s(_vm.$t("treatmentProgram.section.properties")) +
          "\n\t"
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form-view",
        attrs: { novalidate: "", autocomplete: "off" }
      },
      [
        _c("text-input", {
          attrs: {
            label: _vm.$t("general-list.Name"),
            required: "",
            mandatory: "",
            readonly: ""
          },
          model: {
            value: _vm.section.contentPackage.name,
            callback: function($$v) {
              _vm.$set(_vm.section.contentPackage, "name", $$v)
            },
            expression: "section.contentPackage.name"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }