<template>
	<div class="config-check">
		<section class="config-check__section">
			<h3>
				{{t('cardConfig.healthCheck.cards')}}
			</h3>
			<health-indicator
				:healthy="healthyCards"
				:toggle="toggleCards"
				:toggled="showCards"
				:count="size('wrongPortalCards') + size('mismatchedCards') + emptyCards.length"
			/>
			<div v-if="!healthyCards" v-show="showCards">
				<check-items
					v-for="(type, i) in types"
					:key="i + 'mismatched'"
					:items="mismatchedCards[type.name]"
					:description="type.label.mismatched"
					:info="info('mismatchedCard')"
					:solve="solve({ issue: 'mismatchedCard', portal: type.name })"
				/>

				<check-items
					v-for="(type, i) in types"
					:key="i + 'wrongPortal'"
					:items="wrongPortalCards[type.name]"
					:description="type.label.wrongPortal"
					:info="info('wrongPortalCard')"
					:solve="solve({ issue: 'wrongPortalCard', portal: type.name })"
				/>

				<check-items
					:items="emptyCards"
					description="cardConfig.healthCheck.emptyCards"
					:info="emptyCardInfo"
					:solve="solve({ issue: 'emptyCard' })"
				/>
			</div>
		</section>

		<section class="config-check__section">
			<h3>
				{{t('cardConfig.healthCheck.tiles')}}
			</h3>
			<health-indicator
				:healthy="healthyTiles"
				:toggle="toggleTiles"
				:toggled="showTiles"
				:count="mismatchedTiles.length"
			/>
			<div v-if="!healthyTiles" v-show="showTiles">
				<check-items
					:items="mismatchedTiles"
					:info="info('mismatchedTile')"
					:solve="solve({ issue: 'mismatchedTile' })"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckItems from './components/check-items';
import HealthIndicator from './components/health-indicator';
import { info, solve } from './helpers';
import { size, sum } from 'lodash';
import * as phrases from './helpers/phrases';

export default {
	components: { CheckItems, HealthIndicator },
	data: () => ({
		showCards: false,
		showTiles: false,
		types: [{
			name: 'clinician',
			label: phrases.CLINICIAN_LABELS()
		}, {
			name: 'respondent',
			label: phrases.RESPONDENT_LABELS()
		}, {
			name: 'assessment',
			label: phrases.ASSESSMENT_LABELS()
		}, {
			name: 'atomic',
			label: phrases.ATOMIC_LABELS()
		}]
	}),

	computed: {
		...mapGetters({
			mismatchedCards: 'cards/mismatchedCards',
			mismatchedTiles: 'cards/mismatchedTiles',
			emptyCards: 'cards/emptyCards',
			wrongPortalCards: 'cards/wrongPortalCards'
		}),

		healthyCards () {
			return !this.size('wrongPortalCards') &&
				!this.size('mismatchedCards') &&
				!this.emptyCards.length;
		},

		healthyTiles () {
			return !this.mismatchedTiles.length;
		}
	},

	methods: {
		size (type) {
			return sum([
				size(this[type].clinician),
				size(this[type].respondent),
				size(this[type].assessment),
				size(this[type].atomic)
			]);
		},

		toggleCards () {
			this.showCards = !this.showCards;
		},

		toggleTiles () {
			this.showTiles = !this.showTiles;
		},

		solve: (type) => solve.bind(null, type),

		info: (type) => (item) => info({ type, item }),

		emptyCardInfo: (card) => phrases.EMPTY_CARD_INFO(card)
	}
};
</script>
