import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';
import cwalert from 'cwalert';
import appContext from 'app-context';

export default TileView.extend({
	title: t('Lower flag'),
	acl: [{
		checkpoint: 'administrator.respondents.flags',
		op: 'EDIT'
	}],

	cardData: () => ['flagId'],
	tileData: ({ tile }) => ({
		flag: repository.flag(tile.flagId)
	}),

	loaded ({ tile }) {
		const { showUnraiseComment, requireUnraiseComment } = tile.config();

		this.form = formView({
			name: 'flag-lower',
			model: this.flag,
			preventSave: true,
			listenTo: ['submit'],
			onAfterSave: () => {
				this.setLoading();

				return this.flag.lower()
					.then(() => this.flag.fetch())
					.then(() => {
						this.setLoaded();
						appContext.trigger('flag.update', this.flag);
						cwalert.success(t('Flag has been lowered'));
						this.card().close();
					}, () => {
						cwalert.error(t('Unable to lower flag'));
						this.setLoaded();
					});
			},
			fields: [{
				key: 'assessmentFlag',
				type: 'paragraph',
				label: t('Name'),
				transform: (value) => value.assessmentFlagName
			}, {
				key: 'unraiseReason',
				type: 'select',
				label: t('Unraise reason'),
				collection: repository.getReasonTemplates(),
				mandatory: true,
				setNullLabel: t('Choose reason')
			}, {
				key: 'unraiseComment',
				type: 'textarea',
				label: t('Unraise comment'),
				mandatory: requireUnraiseComment,
				show: {
					condition: () => showUnraiseComment
				}
			}],
			buttons: [{
				caption: t('Lower'),
				type: 'submit',
				name: 'lower-submit',
				role: 'submit'
			}]
		});

		this.$el.html(this.form.$el);
	}
});
