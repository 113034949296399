<template>
	<div>
		<form
			novalidate
			autocomplete="off"
			@submit.prevent="onSubmit"
		>
			<content-field
				:label="$t('editAdapterInstanceSetting.code')"
			>
				{{adapterSetting.code}}
			</content-field>
			<text-input
				v-model="form.name"
				:translate="false"
				:label="$t('editAdapterInstanceSetting.name')"
			/>
			<text-input
				v-if="adapterSetting.type !== 'BOOLEAN'"
				v-model.trim="form.value"
				:translate="false"
				:label="$t('editAdapterInstanceSetting.value')"
				:invalid="$v.form.value.$error"
				:required="adapterSetting.required"
				:mandatory="adapterSetting.required"
				:numeric="adapterSetting.type === 'INTEGER'"
			>
				<template v-if="$v.form.value.$error" #validations>
					<p v-if="!$v.form.value.required">
						{{$t('general.validation.mandatory')}}
					</p>
				</template>
			</text-input>
			<checkbox-input
				v-else
				v-model="form.value"
				:translate="false"
				:label="$t('editAdapterInstanceSetting.value')"
				:invalid="$v.form.value.$error"
				:required="adapterSetting.required"
				:mandatory="adapterSetting.required"
			>
				<template v-if="$v.form.value.$error" #validations>
					<p v-if="!$v.form.value.required">
						{{$t('general.validation.mandatory')}}
					</p>
				</template>
			</checkbox-input>
			<text-input
				v-model="form.description"
				:translate="false"
				:label="$t('editAdapterInstanceSetting.description')"
				multiline
			/>

			<button-group align-right>
				<submit-button
					:can-submit="!processing"
					:label="$t('general.buttons.save')"
				/>
			</button-group>
		</form>
	</div>
</template>

<script>
import { constant } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import t from 'translate';
import cardUrl from 'cwcardurl';
import cardControls from 'core/engine/card/services/card-controls';
import cwalert from 'service/cwalert';
import { ADAPTER_INSTANCE_SETTING } from 'store/adapters/adapter-instance-setting';
import ContentField from 'components/form/content-field';
import { SYSTEM } from 'service/acl/checkpoints';
import { EDIT } from 'service/acl/access-levels';

export default {
	components: {
		ContentField
	},

	acl: [{
		checkpoint: SYSTEM.SETTINGS.ADAPTER_INSTANCES,
		op: EDIT
	}],

	title: t('editAdapterInstanceSetting.title'),

	actions: ({ $store }) => {
		const { settingId } = $store.getters.urlParams;

		return [
			[ADAPTER_INSTANCE_SETTING.FETCH, { settingId }]
		];
	},

	data: ({ $store }) => {
		const setting = $store.getters[ADAPTER_INSTANCE_SETTING.DATA];

		const value = setting.adapterSetting.type === 'BOOLEAN' ?
			setting.value === 'true' :
			setting.value;

		return {
			adapterSetting: setting.adapterSetting,
			form: {
				name: setting.name,
				description: setting.description,
				value
			},

			processing: false
		};
	},

	computed: {
		submitData: ({ form }) => ({
			...form,
			value: form.value.toString()
		})
	},

	methods: {
		onSubmit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.processing = true;

				this.$store.dispatch(ADAPTER_INSTANCE_SETTING.UPDATE, {
					settingId: this.$store.getters.urlParams.settingId,
					data: this.submitData
				})
					.then(() => {
						const adapterInstanceId = this.$store.getters.urlParams.adapterInstanceId;

						this.processing = false;
						cwalert.success(this.$t('general.messages.update.success'));
						cardControls.closeCard({
							go: cardUrl.buildUrl(
								'adapter-instance',
								{ adapterInstanceId }
							)
						});
					})
					.catch(() => {
						this.processing = false;

						cwalert.error(this.$t('general.messages.changes-not-saved'));
					});
			}
		}
	},
	validations () {
		return {
			form: {
				value: {
					required: this.adapterSetting.required ? required : constant(true)
				}
			}
		};
	}
};
</script>
