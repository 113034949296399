import t from 'translate';
import appBar from 'app-bar';
import cardUrl from 'cwcardurl';
import can from 'acl-can';
import cwalert from 'cwalert';
import Backbone from 'backbone';
import repository from 'repository';
import confirmation from 'components/confirmation/confirmation';
import { when } from 'jquery';
import store from 'store';
import { assign, clone, forEach } from 'lodash';
import { CLINICIAN } from 'service/acl/checkpoints.json';

const documentOwner =  (cardData) => {
	const assessmentInstance = store.state.assessmentInstance.model;
	let theDocument = clone(cardData.get('theDocument'));

	if (!theDocument) {
		theDocument = new Backbone.Model({
			ownerClinicianId: assessmentInstance.get('ownerAdministratorId'),
			displayName: assessmentInstance.get('ownerAdministratorDisplayName'),
			requestParams: {
				assessmentInstance: assessmentInstance.getId()
			}
		});
		theDocument.on('change:ownerClinicianId', () => {
			if (!theDocument.get('ownerClinicianId')) {
				return;
			}

			assessmentInstance
				.save({ ownerAdministratorId: theDocument.get('ownerClinicianId') },
					{ silent: true })
				.then(() => cwalert.success(t('Changes saved')));
		});
		cardData.set('document', theDocument);
	}

	theDocument.set({
		isFinalized: assessmentInstance.isSubmitted()
	});

	return theDocument;
};

const openAssessmentCardName = (assessmentInstance) => assessmentInstance.isClinicianOnly() ?
	'clinician-assessment-runtime' :
	'respondent-assessment-runtime';

const card = {
	name: 'assessment-properties',
	url: 'assessment/:assessmentInstanceId',
	title: t('assessment__properties.name'),

	ctrl: ({ cardData, close, populate, setLoaded, setLoading }, { assessmentInstanceId }) => {

		if (!can.read(CLINICIAN.RESPONDENTS.RESPONDENTS)) {
			return;
		}

		store.dispatch('respondent/resetId');
		store.dispatch('treatment/resetId');

		assign(card, { assessmentInstanceId, cardData, close, populate, setLoaded, setLoading });

		card.loadData();
	},

	loadData: () => {
		card.setLoading();
		const mediaReferences = repository.getMediaReferences(card.assessmentInstanceId);
		when(
			store.dispatch('clearCurrentCard'),
			store.dispatch('assessmentInstance/init'),
			mediaReferences
		).then(() => {
			// backwards compatibility
			card.assessmentInstance = store.state.assessmentInstance.model;
			card.cardData.set({
				assessmentInstance: store.state.assessmentInstance.model,
				mediaReferences,
				respondent: store.state.respondent.model,
				treatment: store.state.treatment.model,
				theDocument: documentOwner(card.cardData)
			});

			card.populate();
			card.mountButtons();
		});
	},

	mountButtons () {
		const assessmentInstance = store.state.assessmentInstance.model;
		const closed = assessmentInstance.isClosed();
		const submitted = assessmentInstance.isSubmitted();
		const started = assessmentInstance.isStarted();
		const canDelete = {
			delivered: can.administrate(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS),
			notDelivered: can.remove(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS)
		}[assessmentInstance.get('deliveredDate') ? 'delivered' : 'notDelivered'];

		const allowances = {
			openButtonCfg: () => can.read(CLINICIAN.RESPONDENTS.ASSESSMENTCONTENT),
			eraseButtonCfg: () => can.add(CLINICIAN.RESPONDENTS.ASSESSMENTCONTENT) &&
				started &&
				!submitted,
			closeButtonCfg: () => !closed &&
				!submitted &&
				can.edit(CLINICIAN.RESPONDENTS.ASSESSMENTCONTENT),
			unlockButtonCfg: () => submitted && can.edit(CLINICIAN.RESPONDENTS.UNLOCKASSESSMENT),
			deleteButtonCfg: () => canDelete
		};

		appBar.clear(card.name);
		forEach(allowances, (isConditionMet, config) => {
			isConditionMet() && appBar.addButton(card.name, card[config]());
		});
	},

	openButtonCfg: () => ({
		title: t('Open assessment'),
		icon: 'open-folder',
		click: () => cardUrl.openCard(openAssessmentCardName(card.assessmentInstance), {
			id: card.assessmentInstance.getId()
		})
	}),

	eraseButtonCfg: () => ({
		title: t('Erase assessment responses'),
		icon: 'eraser',
		click: () => confirmation({
			warning: true,
			icon: 'eraser',
			title: t('Erase assessment responses'),
			message: t('Are you sure you wish to erase assessment responses?')
		}).then(() => {
			card.setLoading();
			card.assessmentInstance.eraseResponses().then(() => {
				cwalert.success(t('Assessment responses was erased'));
				card.loadData();
			});
		})
	}),

	closeButtonCfg: () => ({
		title: t('Close assessment'),
		icon: 'lock',
		click: () => {
			card.setLoading();
			repository.lockAssessmentInstance({
				id: card.assessmentInstance.getId(),

				success: () => {
					cwalert.success(t('Assessment is now closed'));
					card.assessmentInstance.fetch().then(() => {
						card.loadData();
					});

					return true;
				}
			});
		}
	}),

	unlockButtonCfg: () => ({
		title: t('Unlock assessment'),
		icon: 'unlock',
		click: () => {
			card.setLoading();

			card.assessmentInstance.set({
				delivered: false,
				assessmentInstanceStatus: {
					assessmentInstanceClosed: false,
					hasStarted: true,
					isSubmitted: false
				}
			});
			card.assessmentInstance.save().then(() => {
				card.loadData();
				cwalert.success(t('Assessment is now unlocked'));
			});
		}
	}),

	deleteButtonCfg: () => ({
		title: t('Delete assessment'),
		icon: 'delete',
		click () {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Delete assessment'),
				message: t('assessment.confirm-deletion')
			}).then(() => {
				card.setLoading();
				card.assessmentInstance.destroy().then(() => {
					cwalert.success(t('assessment-properties.actions.delete-success'));
					card.close();
				}, () => {
					cwalert.error(t('assessment.error.deleting'));
					card.setLoaded();
				});
			});
		}
	})
};

export { card as AssessmentInstanceProperties };
