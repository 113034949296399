<template>
	<section class="wide-tile plan-instance-label__content">
		<ul class="plan-instance-label__container">
			<li class="plan-instance-label__element-container important">
				<p class="plan-instance-label__label--important">
					{{t('Plan')}}
				</p>
				<p class="plan-instance-label__value--important">
					{{planInstance.name}}
				</p>
			</li>
			<li class="plan-instance-label__element-container">
				<p class="plan-instance-label__label--important">
					{{t('workflow.properties.Status')}}
				</p>
				<p class="plan-instance-label__value--important">
					{{displayStatus()}}
				</p>
			</li>
			<li class="plan-instance-label__element-container">
				<datetime-input
					v-model="date"
					label="workflow.properties.StartDate"
					class-prefix="plan-instance"
					:disabled="disabledDate"
					:readonly="!planInstance.canChange"
					@on-close="updateDate"
				/>
			</li>
		</ul>
	</section>
</template>
<script>
import can from 'acl-can';
import datetime from 'service/datetime/datetime';
import t from 'translate';
import { includes } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

const allowStartDateChangeStatuses = ['Not started'];

export default {
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.respondents.workflowplans'
	}],

	actions: () => ['planInstance/init'],

	data: () => ({
		date: null
	}),

	computed: {
		...mapGetters({
			planInstance: 'planInstance/data',
			respondent: 'respondent'
		}),
		datetimePolyfill: () => !Modernizr.inputtypes['datetime-local'],
		disabledDate: ({ canChangeStartDate }) =>
			!can.edit('administrator.respondents.workflowplans') || !canChangeStartDate,
		canChangeStartDate: ({ planInstance }) =>
			includes(allowStartDateChangeStatuses, planInstance.status)
	},

	mounted () {
		this.date = this.getStartDate(this.planInstance.workflowInstanceStartDate);
	},

	methods: {
		...mapActions({ save: 'planInstance/save' }),
		displayStatus () {
			return t(`workflow.properties.status.${this.planInstance.status}`);
		},

		getStartDate (date) {

			if (!date || !this.respondent) {
				return null;
			}
			const startDate = datetime(date);
			return startDate.setTimezone(this.respondent.respondentTimezoneName)
				.toMoment()
				.format('YYYY-MM-DDTHH:mm');
		},
		updateDate () {
			if (
				this.date.length &&
				this.getStartDate(this.planInstance.workflowInstanceStartDate) !== this.date
			) {
				const payload = {
					changes: {
						workflowInstanceStartDate: datetime(this.date)
							.toUtcFromTimezone(this.respondent.respondentTimezoneName)
							.toObject()
					},
					timezone: this.respondent.respondentTimezoneId
				};
				this.save(payload);
			}
		}
	}
};
</script>
