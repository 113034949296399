import TileView from 'tile-view';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';
import t from 'translate';
import repository from 'repository';
import editGroup from '../../dialogs/edit-clinician-group';

export default TileView.extend({
	title: t('Clinician groups'),
	acl: [{
		checkpoint: 'system.settings.administratorgroups',
		op: 'OPEN'
	}],

	tileData: () => ({
		clinicianGroups: repository.getClinicianGroups(),
		respondentGroups: repository.getRespondentGroups()
	}),

	loaded: ({ tile }) => {
		tile.table = new datamodel.grid.Table({
			collection: tile.clinicianGroups,
			readonly: !can.edit('system.settings.administratorgroups'),
			columns: {
				administratorGroupName: {
					label: t('Group name'),
					rendererFactory: new datamodel.widgets.LabelFactory()
				},
				administratorGroupCategory: {
					label: t('Group category'),
					rendererFactory: new datamodel.widgets.LabelFactory()
				}
			},
			actions: {
				remove: {
					disabled: !can['delete']('system.settings.administratorgroups')
				}
			},
			onRowClick () {
				if (can.read('system.settings.administratorgroups')) {
					editGroup(this); // `this` points to group model
				}
			},

			container: tile.el
		});
	}
});
