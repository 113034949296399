import TileView from 'tile-view';
import t from 'translate';
import respondentsComponent from './respondents-component';

export default TileView.extend({
	title: t('Respondents not replied to'),
	features: ['ENABLE_MESSAGING_SYSTEM'],
	acl: [{
		checkpoint: 'administrator.respondents.communication',
		op: 'read'
	}, {
		checkpoint: 'administrator.respondents.respondents',
		op: 'read'
	}],
	instant: true,

	loaded: ({ tile }) => {
		tile.$el.html(respondentsComponent({
			fields: tile.config().fields
		}).$el);
	}
});
