import Vue from 'vue';
import $ from 'jquery';
import { EMPTY_CHAR } from 'lib/chars';
import { assign, isNull, isNumber, isUndefined } from 'lodash';

const counter = ({ total, current, problematic }) => {
	const CLASSNAME = 'treatment-counter';
	const vue = new Vue({
		el: $('<span />')[0],
		data: { total, current, problematic },

		computed: {
			completeClass: () => !problematic && (isUndefined(current) || current === total) ?
				` ${CLASSNAME}--complete` :
				'',
			isProblematic: () => isNumber(problematic) && problematic > 0,
			hasCurrent: () => isNumber(current)
		},
		template: `
			<span v-once class="${CLASSNAME}" :class="completeClass">
				<span class="${CLASSNAME}__problematic" v-if="isProblematic">({{problematic}})</span>
				<span class="${CLASSNAME}__current" v-if="hasCurrent">{{current}} / </span>
				<span class="${CLASSNAME}__total">{{total}}</span>
			</span>
		`
	});
	return vue.$el;
};

/*
 * Helper function for rendering a counter
 * @param entity {Object} entity which holds numbers to be displayed
 * @param total {string} name of property that is responsible of total count
 * @param current {string} name of property that is responsible of current count
 * @param problematic {string} name of property that is responsible of problematic count
 */
const renderCounter = ({ entity, total = 'total', current, problematic }) => counter({
	total: (entity && entity[total]) || EMPTY_CHAR,
	current: (entity && entity[total]) ? entity[current] : '',
	problematic: entity && entity[problematic]
}).outerHTML;

const renderHelper = ({ stats, item }, { criteria }) => {
	const emptyRoleCheck = (role) => isNull(role.get('treatmentRoleId'));

	if (emptyRoleCheck(item)) {
		return isNull(stats) ? $('<span> </span>')[0] : '';

	} else if (stats) {
		const params = {
			total: stats[criteria.total],
			current: stats[criteria.current]
		};

		!isUndefined(criteria.problematic) && assign(params, {
			problematic: stats[criteria.problematic]
		});

		return counter(params);

	}

	return $('<span> </span>')[0];
};

export { counter, renderCounter, renderHelper };
