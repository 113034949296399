import StoreCard from './cards/store-card';
import StorePackageList from './tiles/store-package-list/tile';
import StorePackageListFilters from './tiles/store-package-list-filters/tile';

export default {
	cards: [StoreCard],
	tiles: {
		'store-package-list': StorePackageList,
		'store-package-list-filters': StorePackageListFilters
	}
};
