export const deprecatedCards = {
	'add-activity': false,
	'chat': 'video-chat',
	'edit-information-package': 'edit-content-package',
	'export-template-add': false,
	'export-template-add-column': 'edit-export-template',
	'export-template-edit-column': false,
	'information-package-instance-element': 'content-package-instance-element',
	'information-package-instance-exercise': 'content-package-instance-exercise',
	'information-package-instance-list': 'content-package-instance-list',
	'information-package-properties': 'content-package-properties',
	'report': false,
	'respondent-goals': 'goals',
	'system-reports-all': false,
	'system-settings-treatment-activities': false,
	'system-settings-circle-abuse-reports': 'circle-abuse-reports'
};
