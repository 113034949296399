import t from 'translate';

const card = {
	title: t('consents.available'),
	name: 'consent-instances',
	url: [
		'treatment/:treatmentId/consents',
		'respondent/:respondentId/consents'
	]
};

export { card as consentInstances };
