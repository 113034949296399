import t from 'translate';
import Backbone from 'backbone';
import cardurl from 'cwcardurl';
import { isNull } from 'lodash';

const card = {
	title: t('store.card-title'),
	name: 'store',
	url: [
		'store',
		'store/query=:query',
		'store/query=(:query),installed=:installed,update=:update'
	],

	controller: function (query, installedOnly, updateAvailable) {
		this.cardContext().set('filtered-packages', new Backbone.Collection());
		this.cardContext().set('all-packages', new Backbone.Collection());


		this.cardContext().set('filters', new Backbone.Model({
			'show-all': !query && !installedOnly && !updateAvailable,
			'query': isNull(query) ? '' : decodeURIComponent(query),
			'installed-only': installedOnly === '1',
			'update-available': updateAvailable === '1'
		}));

		this.cardContext().get('filters').on('change', function (filters) {
			this.navigate(cardurl.buildUrl(card.name, {
				query: encodeURIComponent(filters.get('query')),
				installed: filters.get('installed-only') ? 1 : 0,
				update: filters.get('update-available') ? 1 : 0
			}));
		}, this);

		this.populate();
	}
};

export default card;
