import t from 'translate';
import { generateUrls } from 'components/agenda-view/helpers';

const card = {
	name: 'add-goal',
	url: generateUrls('add-goal'),
	title: t('goals.addGoalTitle')
};

export { card as AddGoal };
