'use strict';

import t from 'translate';
import cardurl from 'cwcardurl';
import ExportTemplate from 'repo/exports/export-template';
import formViewDialog from 'service/form-view-dialog';

export default () => {
	var exportTemplate = new ExportTemplate();

	var formConfig = {
		name: 'add-export-template',
		model: exportTemplate,
		successMessage: t('Successfully created export template'),
		errorMessage: t('Error creating export template'),
		onAfterSave: function () {
			cardurl.openCard('export-template', {
				id: exportTemplate.getId()
			});
		},
		fields: [{
			key: 'dataExportTemplateName',
			label: t('Name'),
			type: 'text',
			mandatory: true,
			focus: true
		}]
	};

	var dialogConfig = {
		title: t('Add export template')
	};

	formViewDialog({
		formConfig: formConfig,
		dialogConfig: dialogConfig
	});
};
