import * as urls from './urls';
import { GET, POST, DELETE, PUT } from 'service/ajax/ajax';
import { assign, keys, forEach } from 'lodash';

export default {
	/**
	 * Get list of all consents.
	 *
	 * @function consentsList
	 * @returns {Object}                             - Promise.
	 * @example
	 *        repository.consentsList();
	 */
	consentsList: () => GET ({ url: urls.consents() }),

	/**
	 * Get list of consents.
	 *
	 * @function searchConsent
	 * @param {Object} params                        - Filtering Parameters.
	 * @returns {Object}                             - Promise.
	 * @example
	 *        repository.searchConsent({
				query: 'consent',
				start: 0
				limit: 10
			});
	 */
	searchConsent: ({ filters }) => {
		const transform = (obj) => {
			forEach(keys(obj), (key) => {
				if (!obj[key]) {
					delete obj[key];

				} else if (obj[key] === true) {
					obj[key] = 1;
				}
			});
			return obj;
		};

		return GET({
			cache: true,
			url: urls.searchConsent({
				filters: transform(assign({}, filters))
			})
		});
	},

	/**
	 * Get consents.
	 *
	 * @function getConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.getConsent({
				consentId:
			});
	 */
	getConsent: ({ consentId }) => GET({ url: urls.consent({ consentId }) }),

	/**
	 * Remove consent.
	 *
	 * @function removeConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.updateConsent({
				consentId: 5
			});
	 */
	removeConsent: ({ consentId }) => DELETE({ url: urls.consent({ consentId }) }),

	/**
	 * Get consents versions.
	 *
	 * @function getConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.getConsent({
				consentId: 8
			});
	 */
	getConsentVersions: ({ consentId }) => GET({ url: urls.consentVersions({ consentId }) }),

	/**
	 * Restore consents versions.
	 *
	 * @function restoreConsent
	 * @param {Object} params                      - Parameters.
	 * @param {number|string} params.consentId     - Consent ID.
	 * @param {number|string} params.versionNumber - Consent ID.
	 * @returns {Object}                           - Promise.
	 * @example
	 *        repository.restoreConsent({
				consentId: 5,
				versionNumber: 2
			});
	 */
	restoreConsent: ({ consentId, versionNumber }) => PUT({
		url: urls.restoreConsent({ consentId, versionNumber })
	}),

	/**
	 * Get specific consent versions.
	 *
	 * @function consentVersion
	 * @param {Object} params                      - Parameters.
	 * @param {number|string} params.consentId     - Consent ID.
	 * @param {number|string} params.versionNumber - Consent ID.
	 * @returns {Object}                           - Promise.
	 * @example
	 *        repository.consentVersions({
	 *          consentId: 6,
				versionNumber: latest
			});
	 */
	consentVersion: ({ consentId, versionNumber }) => GET({
		url: urls.specificConsentVersion({ consentId, versionNumber })
	}),

	/**
	 * Add consent.
	 *
	 * @function updateConsent
	 * @param {Object} data                    - Parameters.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.addConsent(data);
	 */
	addConsent: (data) => POST({
		url: urls.consent(),
		data
	}),

	/**
	 * Add copy of existing consent.
	 *
	 * @function updateConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.addConsent({
				id: 5,
				name: 'name',
				status: 'published',
				content: 'John Doe is awesome',
				submittableByAdministrator: true,
				requireAllResponsible: false,
				combinedAnswers: true
			});
	 */
	copyConsent: ({ consentId, name, version }) => {
		const data = { name };
		return POST({ url: urls.consentCopy({ consentId, version }), data });
	},

	/**
	 * Update consent.
	 *
	 * @function updateConsent
	 * @param {Object} data                                               - Parameters.
	 * @param {number} data.id                                            - Consent ID.
	 * @param {string} data.name                                          - Consent name.
	 * @param {string} data.status                                        - Consent status.
	 * @param {string} data.content                                       - Consent introduction.
	 * @param {boolean} data.submittableByAdministrator                   - Updated Boolean value.
	 * @param {boolean} data.requireAllResponsible                        - Updated Boolean value.
	 * @param {boolean} data.combinedAnswers                              - Updated Boolean value.
	 * @returns {Object}                                                  - Promise.
	 * @example
	 *        repository.updateConsent({
				id: 5,
				name: 'name',
				status: 'published',
				content: 'John Doe is awesome',
				submittableByAdministrator: true,
				requireAllResponsible: false,
				combinedAnswers: true
			});
	 */
	updateConsent: (data) => PUT({ url: urls.consent(data), data }),

	/**
	 * Change consent status to PUBLISH.
	 *
	 * @function publishConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.publishConsent({
				consentId: 5
			});
	 */
	publishConsent: ({ consentId }) => PUT({ url: urls.publishConsent({ consentId }) }),

	/**
	 * Change consent status from DEACTIVATED to previous status (PUBLISH or UNPUBLISHED CHANGES).
	 *
	 * @function activateConsent
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.activateConsent({
				consentId: 69
			});
	 */
	activateConsent: ({ consentId }) => PUT({ url: urls.activateConsent({ consentId }) }),

	/**
	 * Get list of consent options.
	 *
	 * @function consentOptions
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.setConsentOptions({
				consentId: 5
			});
	 */
	consentOptions: ({ consentId }) => GET({ url: urls.consentOptions({ consentId }) }),

	/**
	 * Get option from consent.
	 *
	 * @function consentOption
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.setConsentOptions({
				consentId: 5
			});
	 */
	consentOption: ({ consentId, optionId }) => GET({
		url: urls.consentOption({ consentId, optionId })
	}),

	/**
	 * Add option to consent.
	 *
	 * @function addConsentOption
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.addConsentOption({
				consentId: 5,
				optionId: 3
			});
	 */
	addConsentOption: ({ consentId, name, content, statement, order }) => {
		const data  = { name, content, statement, order };
		return POST({ url: urls.consentOptions({ consentId }), data  });
	},

	/**
	 * Update option in consent.
	 *
	 * @function updateConsentOption
	 * @param {Object} data                    - Parameters.
	 * @param {number} data.id                 - Option ID.
	 * @param {string} data.name               - Option name.
	 * @param {string} data.content            - Option content.
	 * @param {number} data.content           - Consent introduction.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.addConsentOption({
				consentId: 5,
				optionId: 3
			});
	 */
	updateConsentOption: (data) => PUT({
		url: urls.consentOption(data), data
	}),

	/**
	 * Remove option from consent.
	 *
	 * @function removeConsentOption
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @param {number|string} params.optionId  - Option ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.removeConsentOption({
				consentId: 5,
				optionId: 3
			});
	 */
	removeConsentOption: ({ consentId, optionId }) => DELETE({
		url: urls.consentOption({ consentId, optionId })
	}),

	/**
	 * Get list of Clinician Groups.
	 *
	 * @function consentClinicianGroups
	 * @param {Object} params                  - Parameters.
	 * @param {number|string} params.consentId - Consent ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.setConsentClinicianGroups({
				consentId: 5
			});
	 */
	consentClinicianGroups: ({ consentId }) => GET({
		url: urls.administratorGroups({ consentId })
	}),

	/**
	 * Add Clinician Group to consent.
	 *
	 * @function addConsentClinicianGroup
	 * @param {Object} params                             - Parameters.
	 * @param {number|string} params.consentId            - Consent ID.
	 * @param {number|string} params.administratorGroupId - Administrator Group ID.
	 * @returns {Object}                                  - Promise.
	 * @example
	 *        repository.addConsentClinicianGroups({
				consentId: 5,
				administratorGroupId: 10
			});
	 */
	addConsentClinicianGroup: ({ consentId, administratorGroupId }) => POST({
		url: urls.administratorGroup({ consentId, administratorGroupId })
	}),

	/**
	 * Remove Clinician Group from consent.
	 *
	 * @function removeConsentClinicianGroup
	 * @param {Object} params                             - Parameters.
	 * @param {number|string} params.consentId            - Consent ID.
	 * @param {number|string} params.administratorGroupId - Administrator Group ID.
	 * @returns {Object}                                  - Promise.
	 * @example
	 *        repository.removeConsentClinicianGroups({
				consentId: 5,
				administratorGroupId: 10
			});
	 */
	removeConsentClinicianGroup: ({ consentId, administratorGroupId }) => DELETE({
		url: urls.administratorGroup({ consentId, administratorGroupId })
	}),

	/**
	 * Get list od Treatment Types for consent.
	 *
	 * @function consentTreatmentTypes
	 * @param {Object} params                   - Parameters.
	 * @param {number|string} params.consentId  - Consent ID.
	 * @returns {Object}                        - Promise.
	 * @example
	 *        repository.setConsentTreatmentTypes({
				consentId: 5
			});
	 */
	consentTreatmentTypes: ({ consentId }) => GET({
		url: urls.treatmentTypes({ consentId })
	}),

	/**
	 * Add treatment type to consent.
	 *
	 * @function addConsentTreatmentTypes
	 * @param {Object} params                         - Parameters.
	 * @param {number|string} params.consentId        - Consent ID.
	 * @param {number|string} params.treatmentTypeId  - Treatment Type ID.
	 * @returns {Object}                              - Promise.
	 * @example
	 *        repository.addConsentTreatmentTypes({
				consentId: 5,
				treatmentTypeId: 69
			});
	 */
	addConsentTreatmentTypes: ({ consentId, treatmentTypeId }) => POST({
		url: urls.treatmentType({ consentId, treatmentTypeId })
	}),

	/**
	 * Remove treatment type from consent.
	 *
	 * @function removeConsentTreatmentTypes
	 * @param {Object} params                         - Parameters.
	 * @param {number|string} params.consentId        - Consent ID.
	 * @param {number|string} params.treatmentTypeId  - Treatment Type ID.
	 * @returns {Object}                              - Promise.
	 * @example
	 *        repository.removeConsentTreatmentTypes({
				consentId: 5,
				treatmentTypeId: 69
			});
	 */
	removeConsentTreatmentTypes: ({ consentId, treatmentTypeId }) => DELETE({
		url: urls.treatmentType({ consentId, treatmentTypeId })
	}),

	/**
	 * Get list of newly modified consents.
	 *
	 * @function newlyModifiedConsents
	 * @param {Object} params                         - Parameters.
	 * @param {number|string} params.clinicianId      - Clinician ID.
	 * @returns {Object}  - Promise.
	 * @example
	 *        repository.newlyModifiedConsents({
	 *            clinicianId: 69
	 *        });
	 */
	newlyModifiedConsents: ({ clinicianId, value }) => GET ({
		url: urls.newlyModifiedConsents({ clinicianId, value })
	}),

	/**
	 * Get count of newly modified consents.
	 *
	 * @function newlyModifiedConsentsCount
	 * @param {Object} params                         - Parameters.
	 * @param {number|string} params.clinicianId      - Clinician ID.
	 * @returns {Object}  - Promise.
	 * @example
	 *        repository.newlyModifiedConsents({
	 *            clinicianId: 69
	 *        });
	 */
	newlyModifiedConsentsCount: ({ clinicianId }) => GET({
		url: urls.newlyModifiedConsentsCount({ clinicianId })
	}),

	/**
	 * Add consent instance.
	 *
	 * @function addConsentInstance
	 * @param {Object} data                    - Parameters.
	 * @returns {Object}                       - Promise.
	 * @example
	 *        repository.addConsentInstance(data);
	 */
	addConsentInstance: (data) => POST({
		url: urls.consentInstance(),
		data
	}),

	/**
	 * Remove consent  instance.
	 *
	 * @function removeConsent
	 * @param {Object} params                          - Parameters.
	 * @param {number|string} params.consentInstanceId - Consent ID.
	 * @returns {Object}                               - Promise.
	 * @example
	 *        repository.removeConsentInstance({
				consentInstanceId: 5
			});
	 */
	removeConsentInstance: ({ consentInstanceId }) => DELETE({
		url: urls.clinicianConsentInstance (consentInstanceId)
	}),

	/**
	 * @function respondentConsentInstances
	 * @param { number} respondentId - Respondent ID.
	 * @returns {Object}             - Promise.
	 * @example
	 * 		repository.respondentConsentInstances(19);
	 */
	respondentConsentInstances: (respondentId) => GET({
		url: urls.respondentConsents(respondentId)
	}),

	/**
	 * @function treatmentIdConsentInstances
	 * @param { number} treatmentId - Respondent ID.
	 * @returns {Object}             - Promise.
	 * @example
	 * 		repository.treatmentIdConsentInstances(54);
	 */
	treatmentConsentInstances: (treatmentId) => GET({ url: urls.treatmentConsents(treatmentId) }),

	/**
	 * @function clinicianConsentInstance
	 * @param {number} consentInstanceId - Consent instance ID.
	 * @returns {Object}                 - Promise.
	 * @example
	 * 		repository.clinicianConsentInstance(14);
	 */
	clinicianConsentInstance: ({ consentInstanceId }) => GET({
		url: urls.clinicianConsentInstance(consentInstanceId)
	}),

	/**
	 * @function consentInstanceOptions
	 * @param {number} consentInstanceId - Consent instance ID.
	 * @returns {Object}                 - Promise.
	 * @example
	 * 		repository.consentInstanceOptions(69);
	 */
	consentInstanceOptions: ({ consentInstanceId }) => GET({
		url: urls.consentInstanceOptions(consentInstanceId)
	}),

	/**
	 * @function saveConsentInstance
	 * @param {Object} consentInstance - Consent instance.
	 * @returns {Object}               - Promise.
	 * @example
	 * 		repository.saveConsentInstance({ consentInstance, data });
	 */
	saveConsentInstance: ({ consentInstanceId, data }) => PUT({
		url: urls.saveConsentInstance(consentInstanceId),
		data
	})
};
