<script>
import t from 'service/lang/translate';
import mixin from './_mixin';
import { treatmentValidation } from './_validation-mixin';
import { mapActions, mapGetters } from 'vuex';
import { get, transform, assign } from 'lodash';
import { EXPORT_TEMPLATE } from '../../../store/export-template/export-template';

export default {
	name: 'TreatmentCustomAttribute',
	mixins: [mixin, treatmentValidation],

	data: () => ({
		defaultType: t('Treatment custom attribute'),
		formData: {
			treatmentType: null
		},
		identifierField: 'type',
		ids: {
			identity: 'id'
		}
	}),

	computed: {
		...mapGetters({
			treatmentTypes: EXPORT_TEMPLATE.TREATMENTS,
			attributes: 'treatmentTypes/attributes'
		})
	},

	watch: {
		'formData.treatmentType' (treatmentType) {
			if (get(treatmentType, 'id')) {
				this.loading = true;
				this.resetAttributes();
				this.getAttributes({
					treatmentTypeId: treatmentType.id
				}).then(() => {
					const customAttribute = get(this.item.fields, 'identity');

					if (!this.formOptions.identity && customAttribute) {
						assign(this.formData, this.valueToEntity({
							entities: this.attributes,
							key: 'identity',
							value: customAttribute
						}));
					}

					this.loading = false;
				});
			}

			this.formData.identity = null;
		}
	},

	methods: {
		...mapActions({
			getAttributes: 'treatmentTypes/initAttributes',
			resetAttributes: 'treatmentTypes/resetAttributes'
		}),

		initIdentity () {
			// retransform values to entities and set them to proper data models
			assign(this.formData, transform(this.item.fields, (memo, value, key) =>
				assign(memo, {
					treatmentType: this.valueToEntity({
						entities: this.treatmentTypes,
						key,
						value: +value
					})
				}[key])));
		}
	}
};
</script>
