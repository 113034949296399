var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items
    ? _c("section", { class: _vm.className("section") }, [
        _vm.items.length
          ? _c("p", { class: _vm.className("description") }, [
              _vm._v(
                "(" +
                  _vm._s(_vm.items.length) +
                  ") " +
                  _vm._s(_vm.t(_vm.description))
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { class: _vm.className("list") },
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "li",
              { key: i, class: _vm.className("entry") },
              [
                _c("check-item", {
                  attrs: {
                    item: item,
                    "class-prefix": _vm.classPrefix,
                    info: _vm.info(item),
                    solve: _vm.solve
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }