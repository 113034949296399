import t from 'translate';
import mixin from './_mixin';
import { respondentCustomAttributeHistory } from './_validation-mixin';
import { mapGetters } from 'vuex';

export default () => ({
	name: 'respondent-custom-attribute-history',
	mixins: [mixin, respondentCustomAttributeHistory],

	data: () => ({
		defaultType: t('Respondent custom attribute history'),
		identifierField: 'respondentAttributeMetaLabel',
		ids: {
			identity: 'respondentAttributeMetaId'
		}
	}),

	computed: {
		...mapGetters({
			assessments: 'exportTemplate/sortedAssessments',
			attributes: 'respondent/attributes/customHistory'
		}),

		columnLabel () {
			return this.formData.assessment ? this.formData.assessment.assessmentCode : '';
		}
	}
});
