<template>
	<edit-treatment-type
		action="add"
		:close-card="closeCard"
	/>
</template>

<script>
import { get } from 'lodash';
import t from 'translate';
import EditTreatmentType from '../shared/edit-treatment-type';
import { ADD } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	instant: true,
	title: t('Add treatment type'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.TREATMENT_TYPES,
		op: ADD
	}],

	actions: (tile) => {
		const config = tile.config();

		return [
			['treatmentType/getEnterpriseList', { limit: get(config, 'limit', 10) }]
		];
	},

	components: { EditTreatmentType }
};
</script>
