<template>
	<div>
		<h3>
			{{$t('menuComposer.variantCriteria.title')}}
		</h3>

		<select-input
			:value="newCriterion"
			:translate="false"
			:label="$t('menuComposer.variantCriteria.addCriterion')"
			:options="availableCriteria"
			:disabled="!availableCriteria.length"
			@input="addCriterion"
		/>
		<div
			v-for="(value, criterion) in selectedVariant.when"
			:key="criterion"
			class="sidebar-variant__criterion"
		>
			<component
				:is="criterionComponent(criterion)"
				:criteria="currentCriteria"
				@update="updateCriteria"
				@remove="removeCriterion(criterion)"
			/>
		</div>
	</div>
</template>

<script>
import cwalert from 'cwalert';
import { get, has, filter, omit, map } from 'lodash';
import criteria from './criteria/criteria';

export default {
	props: {
		selectedVariant: {
			type: Object,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},

	data: () => ({
		newCriterion: null
	}),

	computed: {
		allCriteria: () => criteria(),
		currentCriteria: ({ selectedVariant }) => get(selectedVariant, 'when', {}),
		availableCriteria: ({ allCriteria, currentCriteria }) => filter(
			map(allCriteria, (criterion, key) => ({
				label: criterion.label,
				value: key
			})),
			(criterion) => !has(
				currentCriteria,
				criterion.value
			)
		)
	},

	methods: {
		criterionComponent (criterion) {
			return this.allCriteria[criterion].component;
		},

		storePath (actionName) {
			return `engine/${this.type}Sidebar/${actionName}`;
		},

		addCriterion (criterion) {
			const criteria = this.currentCriteria;

			criteria[criterion.value] = [];

			this.updateCriteria(criteria);
		},

		removeCriterion (criterion) {
			this.updateCriteria(omit(this.currentCriteria, [criterion]));
		},

		async updateCriteria (criteria) {
			await this.$store.dispatch(this.storePath('updateCriteria'), {
				criteria
			});

			cwalert.success(this.$t('general-list.ChangesSaved'));
		}
	}
};
</script>
