var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCustom
    ? _c(
        "div",
        [
          _c("select-input", {
            attrs: {
              disabled: _vm.mirror,
              icon: _vm.error,
              invalid: _vm.v.formData.treatmentType.$error,
              mandatory: true,
              options: _vm.treatmentTypes,
              placeholder: _vm.translate("Select treatment type"),
              "class-prefix": "tree-edit",
              "item-label": "name",
              label: "Treatment type"
            },
            model: {
              value: _vm.formData.treatmentType,
              callback: function($$v) {
                _vm.$set(_vm.formData, "treatmentType", $$v)
              },
              expression: "formData.treatmentType"
            }
          }),
          _vm._v(" "),
          !_vm.formData.treatmentType
            ? _c("p", { staticClass: "invalid-msg tree-edit__invalid" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "No treatment type is selected for the export template"
                    )
                  )
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              disabled: !_vm.formData.treatmentType || _vm.loading,
              invalid: _vm.v.formData.identity.$error,
              loading: _vm.loading,
              mandatory: true,
              options: _vm.attributes,
              placeholder: _vm.translate("Select attribute"),
              "class-prefix": "tree-edit",
              "item-label": "label",
              label: "Treatment attribute"
            },
            model: {
              value: _vm.formData.identity,
              callback: function($$v) {
                _vm.$set(_vm.formData, "identity", $$v)
              },
              expression: "formData.identity"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("select-input", {
            attrs: {
              disabled: _vm.mirror,
              invalid: _vm.v.formData.identity.$error,
              mandatory: true,
              options: _vm.attributes,
              placeholder: _vm.translate("Select attribute"),
              "class-prefix": "tree-edit",
              label: "Treatment attribute"
            },
            model: {
              value: _vm.formData.identity,
              callback: function($$v) {
                _vm.$set(_vm.formData, "identity", $$v)
              },
              expression: "formData.identity"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }