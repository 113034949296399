import MultipleEntities from '../shared/multiple-entities';
import ClinicianAttributeDefinition from './attribute-definition';
import urls from './urls';

// Disclaimer: the name is clearly ugly. It's on purpose. B💩ckend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.

const ClinicianAttributeDefinitions = MultipleEntities.extend({
	model: ClinicianAttributeDefinition,
	urls,
	eventNamespace: 'clinician-attribute-definition',
	idName: 'administratorAttributeMetaId',

	getAll () {
		return this.retrieve('listAttributeDefinitions');
	},

	getVisible () {
		return this.where({ administratorAttributeMetaShow: true });
	}
});

export default new ClinicianAttributeDefinitions();
