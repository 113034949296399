import $ from 'jquery';
import Modernizr from 'modernizr';

const isOneColumnLayout = () => {
	$('body').append(`<span class="test-1column"></span>`);
	const result = $(`.test-1column`).css('display') !== 'none';
	$(`.test-1column`).remove();
	return result;
};

export default () => !Modernizr.isios && !isOneColumnLayout();
