import t from 'translate';
import mixin from './_mixin';
import { assessmentValidation } from './_validation-mixin';
import { mapActions, mapGetters } from 'vuex';
import { get, assign } from 'lodash';

export default () => ({
	name: 'assessment-flag',
	mixins: [mixin, assessmentValidation],

	data: () => ({
		defaultType: t('Assessment flag'),

		ids: {
			identity: 'code'
		}
	}),

	computed: {
		...mapGetters({
			assessments: 'exportTemplate/sortedAssessments',
			contexts: 'contexts/sortedWithEmpty',
			flags: 'assessment/flags'
		}),

		columnLabel () {
			return this.formData.assessment ? this.formData.assessment.assessmentCode : '';
		},

		identifierLabel () {
			return this.formData.identity ? this.formData.identity.code : '';
		}
	},

	methods: {
		...mapActions('assessment', ['getFlags', 'resetFlags'])
	},

	watch: {
		'formData.assessment' (assessment) {
			if (get(assessment, 'assessmentId')) {
				this.loading = true;
				this.resetFlags();
				this.getFlags({
					assessmentId: assessment.assessmentId
				}).then(() => {
					const assessmentFlag = get(this.item.fields, 'identity');

					if (!this.formOptions.identity && assessmentFlag) {
						assign(this.formData, this.valueToEntity({
							entities: this.flags,
							key: 'identity',
							value: assessmentFlag
						}));
					}

					this.loading = false;
				});
			}

			this.formData.identity = null;
		}
	},

	beforeDestroy () {
		this.resetFlags();
	}
});
