var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "consent-instances__search" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            staticClass: "consent-instances__search-input",
            attrs: { tabindex: "0", type: "text" },
            domProps: { value: _vm.search },
            on: {
              keyup: _vm.somethingSelected,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "consent-instances__search-clear",
              attrs: {
                tabindex: "0",
                disabled: !_vm.isSomethingSelected,
                type: "reset"
              },
              on: {
                click: function($event) {
                  return _vm.clearSearch()
                }
              }
            },
            [_vm._v("Clear all")]
          ),
          _vm._v(" "),
          !_vm.searchValue
            ? _c("icon", {
                attrs: {
                  "class-name": "consent-instances__search-icon-search",
                  name: "search",
                  small: true
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("consent-instances", {
        attrs: {
          items: _vm.consentInstances,
          fields: _vm.fields,
          "on-click": _vm.open,
          "class-prefix": _vm.classPrefix
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(ref) {
              var label = ref.label
              return [_vm._v("\n\t\t\t" + _vm._s(label) + "\n\t\t")]
            }
          },
          {
            key: "cell",
            fn: function(ref) {
              var item = ref.item
              var fieldKey = ref.fieldKey
              return [
                _vm.labelVisible({ item: item, key: fieldKey })
                  ? _c("p", { staticClass: "table-cell-label" }, [
                      _vm._v(_vm._s(_vm.label(fieldKey)))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                fieldKey === "status"
                  ? _c(
                      "span",
                      [
                        _c("icon", {
                          attrs: {
                            name: _vm.iconName(item.status),
                            title: _vm.description(item)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: _vm.elementClass("status-title") },
                          [_vm._v(_vm._s(_vm.title(item)))]
                        )
                      ],
                      1
                    )
                  : fieldKey === "deleteAction" && _vm.allowDelete(item)
                  ? _c(
                      "button",
                      {
                        class: _vm.elementClass("remove-action"),
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.remove(item)
                          }
                        }
                      },
                      [_c("icon", { attrs: { name: "delete" } })],
                      1
                    )
                  : _c("span", [_vm._v(_vm._s(item[fieldKey]))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }