import $ from 'jquery';
import Backbone from 'backbone';
import t from 'translate';
import repository from 'repository';
import cardUrl from 'cwcardurl';
import Table from 'components/table/table';
import datetime from 'datetime';
import cardControls from 'core/engine/card/services/card-controls';


export default Backbone.View.extend({
	title: t('Exercises'),
	acl: {
		checkpoint: 'administrator.respondents.informationpackages',
		op: 'read'
	},
	appEvents: {
		'information-package-instance-element.update': 'loaded'
	},

	render () {
		const instanceId = this.cardContext().get('instance');
		const elementId = this.cardContext().get('id');
		const respondentId = this.cardContext().get('respondent');

		this.respondent = repository.respondent(respondentId);
		this.instanceElement = repository.ContentPackageInstanceElements.getById(elementId, {
			instance: { id: instanceId }
		});

		this.setLoading();

		$.when(this.instanceElement, this.respondent)
			.done(() => this.loaded(this))
			.catch(() => cardControls.closeCard())
			.always(() => this.setLoaded());
	},

	loaded (tile) {
		const dateFormat = t('date.formats.dateTime');

		this.table = new Table({
			parent: tile.el,
			emptyListMessage: t('No exercise instances'),
			items: tile.instanceElement.get('exercises'),
			click: (item) => {
				cardUrl.openCard('assessment-properties', {
					assessmentInstanceId: item.id
				});
			},
			columns: [{
				label: t('Name'),
				key: 'assessmentName',
				render: () => tile.instanceElement.get('exercise').name
			}, {
				label: t('Start date'),
				key: 'startAt',
				render: (date) => datetime(date)
					.setTimezone(tile.respondent.getTimezone())
					.toMoment()
					.format(dateFormat)
			}, {
				label: t('End date'),
				key: 'endAt',
				render: (date) => datetime(date)
					.setTimezone(tile.respondent.getTimezone())
					.toMoment()
					.format(dateFormat)
			}, {
				label: t('Delivered date'),
				key: 'modifiedAt',
				render (date) {
					return !!date && this.item.isSubmitted ?
						datetime(date)
							.setTimezone(tile.respondent.getTimezone())
							.toMoment()
							.format(dateFormat) :
						'';
				}
			}]
		});
	}
});
