import t from 'translate';
import store from 'store';
import appBar from 'app-bar';
import can from 'acl-can';
import { when } from 'jquery';
import cloneVariantDialog from '../dialogs/clone-variant';
import resetConfigDialog from '../dialogs/reset-config';

const card = {
	name: 'edit-card',
	url: 'card/:type/:cardName/',
	title: t('Card setup'),

	ctrl: ({ setLoaded, setLoading }, { type, cardName }) => when(
		store.dispatch('treatmentTypes/initAll'),
		store.dispatch('cards/init', { type })
	)
		.then(() => store.dispatch('cards/setCurrentCard', cardName))
		.then(() => {
			const currentCard = store.getters['cards/currentCard'];

			if (currentCard.customized && can.edit('system.settings.settings')) {
				appBar.addButton({
					sourceCard: card.name,
					icon: 'eraser',
					title: t('Reset card configuration'),
					click: () => {
						resetConfigDialog({
							setLoaded,
							setLoading,
							onSuccess: () => store.dispatch('cards/resetCurrentCard'),
							title: t('Reset card configuration'),
							message: t(
								'Are you sure you want to reset configuration of this card?'
							),
							successMessage: t('Card configuration has been reset')
						});
					}
				});
			}

			if (can.edit('system.settings.settings')) {
				appBar.addButton({
					sourceCard: card.name,
					icon: 'copy',
					title: t('Clone card variant'),
					click: cloneVariantDialog
				});
			}
		})
};

export { card as EditCard };
