import MultipleEntities from '../shared/multiple-entities';
import SystemEventLog from 'repo/system-event-logs/entity';
import urls from './urls';

/**
 * SystemEventsLogs Entity
 * @class SystemEventLogs
 * @extends MultipleEntities
 */
const SystemEventsLogs = MultipleEntities.extend({
	model: SystemEventLog,
	urls,
	eventNamespace: 'system-event-log',

	/**
	 * Get all system event log entries
	 *
	 * @method getAll
	 * @return {Object} new {{#crossLink "SystemEventLogs"}}{{/crossLink}} Collection
	 */
	getAll: function () {
		return this.retrieve('systemEventLogs');
	},

	/**
	 * Get new collection of SystemEventLogs which match search query
	 * @method search
	 * @param {Object} params search params
	 * @return {Object} new SystemEventLogs Collection
	 * @example
	 *        Repository.SystemEventLogs.search({ status: 6 });
	 */
	search: function (params) {
		return this.retrieve('search', params);
	}
});

export default new SystemEventsLogs();

