import t from 'translate';
import TileView from 'tile-view';
import TreatmentLabelTile from './treatment-label-tile';

export default TileView.extend({
	instant: true,
	title: t('Treatment'),
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	actions: () => ['treatment/init'],
	vueComponent: () => ({ TreatmentLabelTile })
});
