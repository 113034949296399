import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import datamodel from 'components/old-components/datamodel/datamodel';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import can from 'service/acl/acl-can';

export default TileView.extend({
	title: t('Circle abuse report statuses'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.CIRCLE_ABUSE_REPORT_STATUS,
		op: READ
	}],
	features: enableCirclesCheck,
	instant: true,

	appEvents: {
		'circle-abuse-report-status.create' () {
			this.clear();
			this.loaded({ tile: this });
		}
	},

	loaded: ({ tile }) => {
		tile.table = new datamodel.grid.Table({
			collection: store.state.circles.abuseReports.statuses.collection,
			columns: {
				label: {
					label: t('Label'),
					rendererFactory: new datamodel.widgets.TextFactory()
				},
				order: {
					label: t('Order'),
					rendererFactory: new datamodel.widgets.TextFactory()
				}
			},
			container: tile.el,
			actions: {
				remove: {
					disabled: !can.delete(SYSTEM.SETTINGS.CIRCLE_ABUSE_REPORT_STATUS)
				}
			},
			readonly: !can.edit(SYSTEM.SETTINGS.CIRCLE_ABUSE_REPORT_STATUS)
		});
	}
});
