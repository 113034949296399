import MultipleEntities from '../shared/multiple-entities';
import ClinicianInClinicianGroup from './clinician-in-clinician-group';
import urls from './urls';

const ClinicianInClinicianGroups = MultipleEntities.extend({
	model: ClinicianInClinicianGroup,
	urls,
	eventNamespace: 'clinician-in-clinician-group',
	idName: 'relationId',

	canAdd (model) {
		return +model.get('administratorId') === +this.ownerId;
	},

	getForClinician (clinicianId) {
		return this.retrieve('clinicianInGroups', clinicianId);
	}

});

export default new ClinicianInClinicianGroups();
