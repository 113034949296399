<template>
	<div>
		<select-input
			v-model="item.srcRole"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.srcRole.$error"
			label="dataIntegration.roleMapping.fields.role"
			:mandatory="true"
			:options="roleAvailableOptions"
			:placeholder="t('dataIntegration.roleMapping.actions.selectRole')"
			:searchable="false"
			@input="vuelidate.item.srcRole.$touch()"
		/>
		<select-input
			v-model="item.dstRole"
			:class-prefix="classPrefix"
			container-class="select-container"
			:disabled="updating"
			:invalid="vuelidate.item.dstRole.$error"
			label="dataIntegration.roleMapping.fields.roleMapped"
			:mandatory="true"
			:options="roleOptions"
			:placeholder="t('dataIntegration.roleMapping.actions.selectRoleMapped')"
			:searchable="false"
			@input="vuelidate.item.dstRole.$touch()"
		/>
		<radio-input
			v-model="item.access"
			:value-name="true"
			label="dataIntegration.ruleSets.fields.allowed"
			class-prefix="data-integration"
			@input="vuelidate.item.access.$touch()"
		/>
		<radio-input
			v-model="item.access"
			:value-name="false"
			label="dataIntegration.dataMapping.fields.denied"
			class-prefix="data-integration"
			@input="vuelidate.item.access.$touch()"
		/>
	</div>
</template>

<script>
import t from 'translate';

export default {
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		item: {
			type: Object,
			required: true
		},
		roleAvailableOptions: {
			type: Array,
			default: () => []
		},
		roleOptions: {
			type: Array,
			default: () => []
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},
	methods: { t }
};
</script>
