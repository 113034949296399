import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import store from 'store';

export default CounterMiniTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],
	caption: t('Active Treatment(s)'),
	actions: ['treatments/initForRespondent'],
	count: () => store.getters['treatments/enabled'].length,
	total: () => store.state.treatments.data.length,
	targetCard: () => ['treatment-list', {
		respondentId: store.getters['respondent/respondentId']
	}]
});
