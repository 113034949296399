import t from 'translate';
import AttachmentsButton from 'components/attachments-button/attachments-button';
import appBar from 'app-bar';
import repository from 'repository';
import { when } from 'jquery';

const card = {
	name: 'clinician-assessment-runtime',
	url: 'clinician-assessment/:id/open',
	title: t('assessment'),

	controller (id) {
		import(
			/* webpackChunkName: "cp-clinician-ar-instance" */
			`../../entities/assessment-runtime-instance`
		).then((module) => {
			const AssessmentContentModel = module.default;

			const assessmentInstance = repository.assessmentInstance(id);
			const assessmentContent = new AssessmentContentModel({
				assessmentInstanceId: id
			});

			this.cardData.set({ assessmentInstance, 'assessment-model': assessmentContent });

			when(assessmentInstance, assessmentContent.fetch()).then(() => {
				this.populate();

				if (assessmentContent.get('instructionFiles').length) {
					const attachmentsButton = new AttachmentsButton({ model: assessmentContent });
					appBar.add(card.name, attachmentsButton, { order: 1 });
				}
			});
		});
	}
};

export default card;
