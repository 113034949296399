var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { class: _vm.className("name") }, [
      _vm._v(_vm._s(_vm.itemName))
    ]),
    _vm._v(" "),
    _c("span", { class: _vm.className("actions") }, [
      _c(
        "button",
        {
          class: _vm.className("action"),
          on: {
            click: function($event) {
              return _vm.solve(_vm.item)
            }
          }
        },
        [_vm._v(_vm._s(_vm.t("cardConfig.healthCheck.attemptToSolve")))]
      ),
      _vm._v(" "),
      _c(
        "button",
        { class: _vm.className("info-toggle"), on: { click: _vm.toggleInfo } },
        [_vm._v(_vm._s(_vm.t("cardConfig.healthCheck.info")))]
      )
    ]),
    _vm._v(" "),
    _c("p", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.infoToggled,
          expression: "infoToggled"
        }
      ],
      class: _vm.className("info"),
      domProps: { innerHTML: _vm._s(_vm.info) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }