import t from 'translate';
import store from 'store';

const card = {
	title: t('Add media / document'),
	name: 'add-media',
	url: 'media/add',
	ctrl: () => store.dispatch('media/categories/init')
};

export { card as Add };
