import TileView from 'tile-view';
import t from 'translate';
import datetime from 'datetime';
import Table from 'components/table/table';
import cardUrl from 'cwcardurl';
import store from 'store';
import $ from 'jquery';
import { get, filter, map } from 'lodash';

// render a 'checkbox' item based on property passed to CWTable `render` function
const checkbox = (checked, { td }) => {
	const $input = $('<input type="checkbox" />').prop({
		disabled: true,
		checked
	});
	$(td).html($input);
};

export default TileView.extend({
	title: t('Treatments'),

	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	actions: () => ['respondent/init', 'treatments/initForRespondent'],

	loaded: ({ tile }) => {
		const timezone = store.getters.respondent.respondentTimezoneName;
		const renderDate = (date) => date ?
			datetime(date).setTimezone(timezone).toMoment().format(t('date.formats.dateTime')) :
			'';

		tile.table = new Table({
			className: 'treatment-list__table',
			parent: tile.el,
			store: {
				items: () => get(store.state, 'treatments.data')
			},
			sortable: true,
			sortOrder: tile.config().tableSort,
			preventAriaLive: true,
			click: (treatment) => {
				cardUrl.openCard('treatment', {
					respondentId: store.getters.respondent.respondentId,
					treatmentId: treatment.id
				});
			},
			columns: [{
				label: t('Treatment name'),
				className: 'treatment-list__treatment-name',
				key: 'name'
			}, {
				label: t('Treatment Type'),
				className: 'treatment-list__treatment-type',
				key: 'treatmentType',
				render: (treatmentType) => treatmentType.name
			}, {
				label: t('Status'),
				className: 'treatment-list__treatment-status',
				key: 'customStatus'
			}, {
				label: t('Start'),
				className: 'treatment-list__start',
				key: 'startAt',
				render: renderDate
			}, {
				label: t('End'),
				className: 'treatment-list__end',
				key: 'endAt',
				render: renderDate
			}, {
				label: t('Primary'),
				className: 'treatment-list__is-main-respondent',
				key: 'isMainRespondent',
				render: checkbox
			}, {
				label: t('Role'),
				className: 'treatment-list__role',
				key: 'treatmentRoles',
				render: (treatmentRoles) =>
					map(filter(treatmentRoles, (role) =>
						store.getters.respondent.respondentId === get(role, 'respondent.id')),
					(role) => get(role, 'treatmentTypeRole.name')).join()
			}, {
				label: t('Enabled'),
				className: 'treatment-list__enabled',
				key: 'enabled',
				render: checkbox
			}, {
				label: t('Complete'),
				className: 'treatment-list__complete',
				key: 'complete',
				render: checkbox
			}]
		});
	}
});
