import urls from './urls';
import { POST } from 'service/ajax/ajax';

export default {
	/**
	 * Get new collection of SystemEventLogs which match search query.
	 *
	 * @function searchSystemEventLogs
	 * @param {object} params - Search params.
	 * @returns {object} New SystemEventLogs Collection.
	 * @example
	 *        Repository.searchSystemEventLogs({ status: 6 });
	 */
	searchSystemEventLogs (params) {
		return this.SystemEventLogs.search(params);
	},

	/**
	 * Get all system event log statuses.
	 *
	 * @function getSystemEventsStatuses
	 * @returns {object} New {{#crossLink "SystemEventsStatuses"}}{{/crossLink}} Collection.
	 */

	getSystemEventsStatuses () {
		return this.SystemEventsStatuses.getAll();
	},

	/**
	 * Get all system event log types.
	 *
	 * @function getSystemEventsTypes
	 * @returns {object} New {{#crossLink "SystemEventsTypes"}}{{/crossLink}} Collection.
	 * @example
	 * 		repository.getSystemEventsTypes()
	 */
	getSystemEventsTypes () {
		return this.SystemEventsTypes.getAll();
	},

	/**
	 *
	 * @param {object} data - Export Data.
	 * @returns {object} Promise.
	 * @example
	 *        repository.exportSystemLogs(data);
	 */
	exportSystemLogs: (data) => POST({
		url: urls.exportLogs(),
		data
	})
};

