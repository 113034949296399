import can from 'acl-can';
import cardurl from 'cwcardurl';
import $ from 'jquery';

export default function (respondent, opts = {}) {
	const targetCard = opts.targetCard || 'respondent-status';
	const canAccess = can.read('administrator.respondents.respondents');
	const tagName = canAccess ? 'a' : 'span';

	const href = cardurl.buildUrl(targetCard, { respondentId: respondent.respondentId });
	const $link = $(`<${tagName} />`).text(respondent.respondentDisplayName);
	canAccess && $link.attr('href', href);

	return $link;
}
