<template>
	<table v-if="codes.length" class="table assign-items__table data-integration__selected-codes">
		<thead>
			<tr>
				<th>{{t('dataIntegration.ruleSets.fields.code')}}</th>
				<th>{{t('dataIntegration.ruleSets.fields.codeType')}}</th>
				<th />
			</tr>
		</thead>
		<tbody>
			<tr v-for="code in codes" :key="code.id">
				<td>
					<p class="table-cell-label">
						{{t('dataIntegration.ruleSets.fields.code')}}
					</p>
					{{code.name}}
				</td>
				<td>
					<p class="table-cell-label">
						{{t('dataIntegration.ruleSets.fields.codeType')}}
					</p>
					{{code.codeType.name}}
				</td>
				<td>
					<button
						class="icon-button__button icon icon--delete"
						@click="$emit('delete', code.id)"
					/>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { constant } from 'lodash';
import t from 'service/lang/translate';

export default {
	props: {
		codes: {
			type: Array,
			default: constant([])
		}
	},

	methods: { t }
};
</script>
