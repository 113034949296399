import repository from 'repository';
import SystemLogEventCodes from '../entities/log-event-codes';
import { Model } from 'backbone';
import { when } from 'jquery';
import t from 'translate';
import can from 'acl-can';

const card = {
	title: t('system-log.card-title'),
	name: 'system-log',
	url: 'system-logs',

	ctrl: ({ cardConfig, cardData }) => {
		const types = repository.getSystemEventsTypes();
		const statuses = repository.getSystemEventsStatuses();
		const codes = new SystemLogEventCodes();
		const codesPromise = can.read('system.log.log') ? codes.fetch() : {};

		cardData.set({
			limit: cardConfig.limit,
			types,
			statuses,
			codes
		});

		return when(types, statuses, codesPromise).then(() => {
			cardData.set('filters', new Model());
		});
	}
};

export default card;

