import t from 'translate';
import repository from 'repository';
import store from 'store';
import { when } from 'jquery';

const card = {
	title: t('Edit media / document'),
	name: 'edit-media',
	url: 'media/:mediaId/edit',

	ctrl: ({ cardData }, { mediaId }) => {
		const mediaModel = repository.mediaModel(mediaId);
		cardData.set({ mediaModel });
		return when(mediaModel, store.dispatch('media/categories/init'));
	}
};

export { card as Edit };
