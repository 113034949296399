import t from 'translate';
import appBar from 'app-bar';

const card = {
	title: t('system.exports.exportTemplates'),
	name: 'system-exports-templates',
	url: 'system-exports/templates',

	controller () {
		this.populate();
		appBar.addCardLink(card.name, {
			card: 'system-exports-templates-add',
			title: t('Create new export'),
			icon: 'add'
		});
	}
};

export { card as exportTemplates };
