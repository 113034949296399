import Vue from 'vue';
import store from 'store';
import t from 'translate';
import { assign, defer } from 'lodash';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import formViewDialog from 'service/form-view-dialog';
import appContext from 'app-context';
import cwalert from 'cwalert';
import { Model } from 'backbone';
import emailCheck from 'lib/email-check';
import systemSettings from 'system-settings';

const isEmailEnabled = () => systemSettings.getBoolean('ENABLE_ASSESSMENT_SEND_CODE_EMAIL');

export default (data) => new Vue({
	el: document.createElement('div'),
	store,
	data: assign({}, {
		loading: false,
		mainContainerClass: '',
		containerClass: '',
		labelClass: '',
		valueClass: '',
		removeButtonClass: '',
		generateButtonClass: '',
		sendButtonClass: '',
		loaderClass: ''
	}, data),

	computed: {
		code: () => store.getters['assessmentInstance/assessmentPortalCode'],
		canEdit: () =>
			can.edit('administrator.respondents.assessmentplans') &&
			can.add('administrator.respondents.assessments_codes') &&
			!store.getters['assessmentInstance/completed']
	},

	methods: {
		generateCode () {
			this.loading = true;
			store.dispatch('assessmentInstance/generateAssessmentPortalCode').then(() => {
				this.loading = false;
			});
		},

		removeCode () {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove Assessment Portal code'),
				message: t('Are you sure you wish to remove Assessment Portal code?')
			}).done(() => {
				this.loading = true;
				store.dispatch('assessmentInstance/removeAssessmentPortalCode').then(() => {
					this.loading = false;
				});
			});
		},

		sendCode () {
			const validate = (formView, formModel) => {
				const { useSms, useEmail, email, phoneNumber } = formModel.toJSON();

				if (
					(!useSms && !useEmail) ||
					(useSms && !phoneNumber) ||
					(useEmail && !email)
				) {
					formView.disableSubmit();

				} else {
					formView.enableSubmit();
				}
			};

			const SendDataModel = Model.extend({
				validate (attrs) {
					if (attrs.email && !emailCheck(attrs.email)) {
						return {
							key: ['email'],
							msg: [t('This email address seems to be invalid. Please recheck.')]
						};
					}

					return null;
				}
			});

			formViewDialog({
				captions: {
					submit: t('Send'),
					cancel: t('Close')
				},
				dialogConfig: { title: t('Send Assessment Portal access code') },
				formConfig: {
					classNames: ['generate-assessment-code'],
					preventSave: true,
					model: new SendDataModel({
						useSms: true,
						phoneNumber: store.getters.respondent.respondentPhone,
						...(isEmailEnabled() && {
							useEmail: true,
							email: store.getters.respondent.respondentEmail
						})
					}),

					onAfterSave (model) {
						if (!model.isValid()) {
							return;
						}
						this.setLoading();
						const { useSms, phoneNumber, useEmail, email } = model.toJSON();

						const data = {
							phoneNumber: useSms ? phoneNumber : null,
							email: useEmail ? email : null
						};
						store.dispatch('assessmentInstance/sendAssessmentPortalCode', data)
							.then(() => {
								appContext.trigger('dialog:close');
								cwalert.success(t('Assessment Portal access code has been sent'));
							});
					},
					fields: [{
						key: 'useSms',
						type: 'checkbox',
						valueDescriptions: [t('Send SMS')]
					}, {
						key: 'phoneNumber',
						type: 'tel',
						label: t('Phone number')
					}, {
						key: 'useEmail',
						type: 'checkbox',
						show: {
							condition: () => isEmailEnabled()
						},
						valueDescriptions: [t('Send E-mail')],

						customize: (view, formView, formModel) => {
							defer(() => {
								validate(formView, formModel);
							});

							formView.listenTo(formModel, 'change', () => {
								validate(formView, formModel);
							});
						}
					}, {
						key: 'email',
						type: 'email',
						show: {
							condition: () => isEmailEnabled()
						},
						label: t('E-mail address')
					}]
				}
			});
		}
	},
	template: `
		<div class="assessment-portal-code" :class="mainContainerClass">
			<div class="assessment-portal-code__container" :class="containerClass">
				<p
					class="assessment-portal-code__label"
					:class="labelClass"
					v-translate
				>Assessment code</p>
				<div
					class="assessment-portal-code__loader"
					:class="loaderClass"
					v-show="loading"
					v-loader-spinner
				/>
				<p v-if="code" class="assessment-portal-code__value" :class="valueClass">
					{{code}}
					<button
						v-if="canEdit"
						class="
							assessment-portal-code__action
							assessment-portal-code__remove
							icon--delete"
						@click.prevent="removeCode"
						:class="removeButtonClass"
						:disabled="loading"
						title="${t('Remove code')}"
					></button>
					<button
						v-if="canEdit"
						class="assessment-portal-code__action assessment-portal-code__send"
						@click.prevent="sendCode"
						:class="sendButtonClass"
						:disabled="loading || !canEdit"
						v-translate
					>Send code</button>
				</p>

				<button
					v-else
					@click.prevent="generateCode"
					class="assessment-portal-code__action assessment-portal-code__generate"
					:class="generateButtonClass"
					:disabled="loading || !canEdit"
					v-translate
				>Generate code</button>
			</div>
		</div>`
});
