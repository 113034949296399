import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Report from './entity';

/**
 * Reports Entity
 * @class Reports
 * @extends MultipleEntities
 */

const Reports = MultipleEntities.extend({
	urls,
	model: Report,
	idName: 'assessmentReportId',
	eventNamespace: 'report',

	/**
	 * Get all Reports
	 * @method getAll
	 * @return {Object} new {{#crossLink "Reports"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Reports.getAll();
	 */
	getAll: function () {
		return this.retrieve('allReports');
	},

	/**
	 * Get a new sorted collection of all getByData
	 * @method getByData
	 * @param  {Object} data
	 * @return {Object} new {{#crossLink "Reports"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Reports.getByData({ assessmentId: 32 });
	 */
	getByData: function (data) {
		return this.retrieve('reportsByData', data);
	}
});

export default new Reports();
