import SingleStatus from 'repo/circles/abuse-report-status';
import t from 'translate';
import appContext from 'app-context';
import cwalert from 'cwalert';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';

export default function () {
	const statusModel = new SingleStatus({
		order: store.state.circles.abuseReports.statuses.current.length
	});

	const formConfig = {
		preventSave: true,
		onAfterSave: () => store.dispatch('circles/abuseReports/statuses/create', { status: statusModel.toJSON() }).then(() => {
			appContext.trigger('dialog:close');
			cwalert.success(t('Success'));
		}),
		name: 'name',
		model: statusModel,
		successMessage: t('Successfully added'),
		fields: [{
			key: 'label',
			type: 'text',
			label: t('Label'),
			mandatory: true,
			focus: true,
			max: 64
		}, {
			key: 'order',
			label: t('Order'),
			type: 'number',
			mandatory: true
		}]
	};

	const dialogConfig = {
		title: t('New abuse report status')
	};

	formViewDialog({ formConfig, dialogConfig });
}
