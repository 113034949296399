var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "div",
        [
          _vm.attributes.length
            ? _c("select-list", {
                attrs: {
                  "loop-key": "dataExportTemplateColumnRespondentFieldId",
                  type: "attributes",
                  "item-label": "dataExportTemplateColumnRespondentFieldLabel",
                  title: "Select attributes",
                  collection: _vm.attributes
                },
                on: { select: _vm.onSelect }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.customAttributes.length
            ? _c("select-list", {
                attrs: {
                  "loop-key": "respondentAttributeMetaId",
                  type: "customAttributes",
                  "item-label": "respondentAttributeMetaLabel",
                  title: "Select custom attributes",
                  collection: _vm.customAttributes
                },
                on: { select: _vm.onSelect }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.customAttributesHistory.length
            ? _c("select-list", {
                attrs: {
                  "loop-key": "respondentAttributeMetaId",
                  type: "customAttributesHistory",
                  "item-label": "respondentAttributeMetaLabel",
                  title: "Select custom attributes history",
                  collection: _vm.customAttributesHistory
                },
                on: { select: _vm.onSelect }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("button-group", [_c("submit-button", { attrs: { label: "Add" } })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }