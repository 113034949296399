import { get } from 'lodash';
import { respondentName } from 'service/display-name/display-name';
import t from 'service/lang/translate';
import { EMPTY_CHAR } from 'lib/chars';

export default ({ person, includeClinician = false }) => {
	const name = respondentName(person, { preventEscape: true }) || EMPTY_CHAR;
	const relationName = get(person, 'relationRoleName') ?
		`${person.relationRoleName} (${name})` :
		name;

	return includeClinician && get(person, 'submittedByAdministrator') ?
		`${t('consents.clinicianOnBehalf')} ${relationName}` :
		relationName;
};
