import { noop } from 'lodash';
import CardDialog from 'service/card-dialog/card-dialog';
import Vue from 'vue';
import store from 'store';
import t from 'translate';
import EditRole from '../components/edit-role';

export default ({ role, action, successMsg, title, done = noop }) => {
	const dialog = new Vue({
		el: document.createElement('div'),
		store,
		render: (h) => h(
			EditRole,
			{
				props: {
					action,
					done,
					role,
					successMsg
				}
			}
		)
	});

	return new CardDialog({
		preventFocusTrap: true,
		title: t(title),
		content: dialog.$el
	});
};
