import MultipleEntities from '../shared/multiple-entities';
import SingleEntity from '../shared/single-entity';
import urls from './urls';


/**
 * SystemEventsStatuses Entity
 * @class SystemEventLogs
 * @extends MultipleEntities
 */
const SystemEventsStatuses = MultipleEntities.extend({
	model: SingleEntity.extend({
		eventNamespace: 'system-event-status'
	}),
	urls,
	eventNamespace: 'system-event-status',

	// backend returns two arrays in one object, one of them being `status`
	parse: (response) => response.status,

	/**
	 * Get all system event log statuses
	 *
	 * @method getAll
	 * @return {Object} new {{#crossLink "SystemEventsStatuses"}}{{/crossLink}} Collection
	 */
	getAll: function () {
		return this.retrieve('codes');
	}
});

export default new SystemEventsStatuses();

