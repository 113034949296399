import $ from 'jquery';
import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';

export default TileView.extend({
	title: t('View media / document'),
	acl: {
		op: 'read',
		checkpoint: 'administrator.respondents.informationpackages'
	},

	init () {
		this.mediaModel = repository.mediaModel(this.cardContext().get('id'));
	},

	onRender () {
		$.when(this.mediaModel)
			.then(this.getFile.bind(this))
			.then(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	getFile () {
		this.file = repository.getFile(this.mediaModel.get('file').id);
		return this.file;
	},

	loaded () {
		const formCfg = {
			name: 'view-media',
			model: this.mediaModel,
			readonly: true,

			fields: [{
				key: 'file',
				type: 'file',
				label: t('File'),
				fileModel: this.file,
				constraints: 'media'
			}, {
				key: 'title',
				type: 'paragraph',
				label: t('Title')
			}, {
				key: 'description',
				type: 'paragraph',
				label: t('Description')
			}, {
				key: 'alias',
				type: 'paragraph',
				label: t('Permanent link')
			}]
		};

		this.$el.html(formView(formCfg).$el);
	}

});
