import { has } from 'lodash';
import store from 'store';
import $ from 'jquery';
import t from 'translate';

/*
 * If current treatment is not ready, hide form and show info about it.
 * Used in add-{something} old backbone tiles -- safe to remove with those.
 */
export default (tile) => {
	const isCompleteTreatment = () =>
		has(store.getters.urlParams, 'treatmentId') ? store.getters['treatment/ready'] : true;

	const $incompleteLabel = $(`
			<p
				class="incomplete-treatment"
			>${t('treatment.labels.unavailable')}</p>
		`);

	tile.$el.append(isCompleteTreatment() ? tile.form.$el : $incompleteLabel);
	tile.handleComplete({ tile });
};
