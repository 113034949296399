var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-view",
      attrs: { novalidate: "", autocomplete: "off" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("select-input", {
        attrs: {
          name: "contentPackage",
          required: "",
          mandatory: "",
          label: _vm.$t("treatmentProgram.section.contentPackage"),
          options: _vm.contentPackages,
          "get-option-label": function(ref) {
            var name = ref.name

            return name
          }
        },
        model: {
          value: _vm.section.contentPackage,
          callback: function($$v) {
            _vm.$set(_vm.section, "contentPackage", $$v)
          },
          expression: "section.contentPackage"
        }
      }),
      _vm._v(" "),
      _c(
        "button-group",
        [
          _c("submit-button", {
            attrs: {
              "can-submit": !_vm.$v.$invalid,
              label: _vm.$t("general.buttons.add")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }