var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "roles-dialog",
        class: { "roles-dialog__loading-form": _vm.loading },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("text-input", {
          attrs: {
            mandatory: true,
            required: true,
            translate: false,
            label: _vm.$t("Name"),
            invalid:
              _vm.$v.name.$dirty &&
              _vm.$v.name.$invalid &&
              !_vm.$v.name.$pending,
            "class-prefix": "roles-dialog"
          },
          on: {
            input: function($event) {
              return _vm.$v.name.$touch()
            }
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _vm._v(" "),
        _vm.$v.name.$dirty && _vm.$v.name.$invalid && !_vm.$v.name.$pending
          ? [
              !_vm.$v.name.required
                ? _c("mandatory-warning", { attrs: { name: "name" } })
                : !_vm.$v.name.unique
                ? _c("mandatory-warning", { attrs: { name: "name" } }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.roleSet
                            ? _vm.$t("roleSets.error.roleSetNameIsNotUnique")
                            : _vm.$t("roles.error.roleNameIsNotUnique")
                        ) +
                        "\n\t\t\t"
                    )
                  ])
                : _vm._e()
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.roleSet
          ? _c(
              "div",
              {
                staticClass:
                  "\n\t\t\t\t\troles-dialog__field-container\n\t\t\t\t\troles-dialog__field-container--radio\n\t\t\t\t\troles-dialog--mandatory\n\t\t\t\t"
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "roles-dialog__label-text roles-dialog__mandatory_field"
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("Type")) + "\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "roles-dialog__labels-group-container" },
                  [
                    _c("radio-input", {
                      attrs: {
                        label: _vm.$t("Respondent"),
                        "class-prefix": "roles-dialog",
                        "value-name": "RESPONDENT"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    }),
                    _vm._v(" "),
                    _c("radio-input", {
                      attrs: {
                        label: _vm.$t("Clinician"),
                        "class-prefix": "roles-dialog",
                        "value-name": "CLINICIAN"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            multiline: true,
            translate: false,
            label: _vm.$t("Description"),
            "class-prefix": "roles-dialog"
          },
          model: {
            value: _vm.description,
            callback: function($$v) {
              _vm.description = $$v
            },
            expression: "description"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "roles-dialog__buttons-container" }, [
          _c(
            "div",
            {
              staticClass:
                "roles-dialog__field-container roles-dialog__field-container--button"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "roles-dialog__button roles-dialog__button--submit",
                  attrs: { disabled: _vm.$v.$invalid, type: "submit" }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "roles-dialog__field-container roles-dialog__field-container--button"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "roles-dialog__button roles-dialog__button--button",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.onCancel()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ]
          )
        ])
      ],
      2
    ),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          {
            directives: [
              { name: "loader-spinner", rawName: "v-loader-spinner" }
            ],
            staticClass: "roles-dialog roles-dialog__loader"
          },
          [_vm._v(_vm._s(_vm.processing))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }