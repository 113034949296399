import SingleEntity from '../shared/single-entity';
import urls from './urls';
import Backbone from 'backbone';
import t from 'translate';
import datetime from 'datetime';
import formLabels from './form-labels';
import Columns from './columns';

export default SingleEntity.extend({
	idAttribute: 'dataExportTemplateId',
	eventNamespace: 'export-template',
	urlRoot: urls.template.url,
	label: formLabels.template,

	defaults: {
		dataExportTemplateName: '',
		dataExportTemplateFilterDateRange: 'ALL'
	},

	getColumns () {
		return Columns.getFromTemplate(this.getId());
	},

	_get (key) {
		return this.get(`dataExportTemplate${key}`);
	},

	setId (id) {
		return this.set(this.idAttribute, id);
	},

	getName () {
		return this._get('Name');
	},

	getCreatedDate (formatted) {
		return formatted ? datetime(this._get('CreatedDate')) : this._get('CreatedDate');
	},

	getDownloadUrl () {
		return `${Backbone.getBackendRoot()['default']}backend-commons/dataexport/template/sample/${this.getId()}`;
	},

	validate (attrs) {
		const checkDate = attrs.dataExportTemplateFilterDateRange === 'CUSTOM';
		const startDate = datetime(attrs.dataExportTemplateFilterDateRangeStart).toMoment();
		const endDate = datetime(attrs.dataExportTemplateFilterDateRangeStop).toMoment();
		const keys = [];
		const msgs = [];

		if (attrs.dataExportTemplateName.length < 2) {
			keys.push('dataExportTemplateName');
			msgs.push(t('The export template name is too short, try something longer.'));
		}

		if (checkDate && startDate > endDate) {
			keys.push('dataExportTemplateFilterDateRangeStart');
			keys.push('dataExportTemplateFilterDateRangeStop');
			msgs.push(t('Start date cannot be higher than end date.'));
			msgs.push(t('End date cannot be lower than start date.'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	}
});

