import $ from 'jquery';
import Backbone from 'backbone';
import InactiveRespondent from './report-inactivity';
import { pickBy, identity } from 'lodash';

export default Backbone.Collection.extend({
	model: InactiveRespondent,

	initialize (models, parameters) {
		const params = parameters ? $.param(pickBy(parameters, identity)) : '';
		this.url = `backend-commons/administrator/rest/report/inactivity/?${params}`;
	}
});
