import SingleEntity from '../shared/single-entity';

/**
 * TreatmentActivity Entity
 * @class TreatmentActivity
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'treatment-activity'
});
