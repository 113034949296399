/* eslint-disable camelcase */
import cardUrl from 'cwcardurl';
import cwalert from '../../../../service/cwalert';
import Editor from '@tinymce/tinymce-vue';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { isUndefined, get } from 'lodash';

export default TileView.extend({
	title: t('Edit consent option'),
	instant: true,
	loaded: ({ tile }) => {
		const el = document.createElement('div');
		tile.$el.append(el);
		const view = {
			el,
			components: {
				editor: Editor
			},
			store,
			data: {
				opts: {
					theme_url: 'tinymce/themes/silver/theme.min.js',
					skin_url: 'tinymce/skins/ui/oxide',
					toolbar: 'bold italic underline',
					menubar: false,
					height: 200,
					content_css: [
						'main.css',
						'styles/custom.css'
					]
				},
				updatingOption: false
			},
			computed: {
				...mapGetters({ option: 'consent/option' }),

				invalidName () {
					const name = this.$v.option.name;
					return name.$dirty && name.$invalid;
				},
				optionStatementValidation () {

					if (this.option.statement === '') {
						return true;
					}
				},
				editContext () {
					return !isUndefined(get(store.getters.urlParams, 'optionId'));
				},
				canSave () {
					return this.invalidName ||
					!this.option.name ||
					!this.option.statement;
				}
			},

			methods: {
				t,
				...mapActions('consent', ['updateConsentOption']),
				updateOptionInfo () {
					this.$v.$touch();
					this.updatingOption = true;
					this.updateConsentOption(this.option).then(() => {
						cwalert.success(t('Option has been updated'));
						this.updatingOption = false;
						const consentId = store.state.cardData.consentId;
						cardUrl.openCard('edit-consent', {
							consentId
						});
					});
				},
				addConsentOption () {
					this.$v.$touch();
					this.updatingOption = true;
					const consentId = store.state.cardData.consentId;
					const data = {
						name: this.option.name,
						content: this.option.content,
						statement: this.option.statement,
						order: this.option.order || 0,
						consentId
					};
					store.dispatch('consent/addConsentOption', data).then(() => {
						cwalert.success(t('Option has been created'));
						this.updatingOption = false;
						cardUrl.openCard('edit-consent', {
							consentId
						});
					});
				}
			},
			validations: {
				option: {
					name: { required },
					statement: { required }
				}
			},
			template: `
				<div>
					<div class="consent__field-container consent-option--mandatory">
						<text-input
							:disabled="updatingOption"
							v-model="option.name"
							class-prefix="consent-option"
							:mandatory="true"
							:label="t('Name')"
							:invalid="$v.option.name.$invalid"
						>
							<template #validations>
								<p v-if="!$v.option.name.required" v-translate>
									consents.mandatoryFieldWarning
								</p>
							</template>
						</text-input>
					</div>
					<div class="text-input__field-container">
						<label
							class="consent-option consent-option__label-container">
							<p
								class="
									consent-option__label-text
									consent-option__name"
								v-translate
							>Content</p>
							<editor
								:init="opts"
								:disabled="updatingOption"
								v-model="option.content"
							/>
						</label>
					</div>
					<div class="consent__field-container consent-option--mandatory">
						<text-input
							:disabled="updatingOption"
							v-model="option.statement"
							class-prefix="consent-option"
							:mandatory="true"
							:label="t('Option text')"
							:invalid="$v.option.statement.$invalid"
						>
							<template #validations>
								<p v-if="!$v.option.statement.required" v-translate>
									consents.mandatoryFieldWarning
								</p>
							</template>
							<template #hints>
								<p v-translate>consents.option.hintPreviewMode</p>
								<p v-translate>consents.option.hintStatement</p>
							</template>
						</text-input>
					</div>
					<div>
						<label
							class="consent-option consent-option__label-container"
						>
							<p
								class="
									consent-option__label-text
									consent-option__name
								"
								v-translate
							>Order</p>
							<input type="number"
								min="0"
								:disabled="updatingOption"
								v-model="option.order"
								class="consent-option__input"
							/>
						</label>
					</div>
					<div class="consent-option__button-container">
						<button
							v-if="editContext"
							:disabled="canSave"
							class="consent-option__button consent-option__button--submit"
							@click="updateOptionInfo"
							v-translate
						>Save</button>
						<button
							v-if="!editContext"
							:disabled="canSave"
							class="consent-option__button consent-option__button--submit"
							@click="addConsentOption"
							v-translate
						>Save</button>
					</div>
				</div>
			`
		};
		import('tinymce').then((tinyMCE) => {
			window.tinymce = tinyMCE.default;
			tile.vue = new Vue(view);
		});
	}
});
