import TileView from 'tile-view';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import can from 'acl-can';

export default TileView.extend({
	title: t('Content package categories'),
	acl: [{
		op: 'READ',
		checkpoint: 'system.settings.informationpackages'
	}],

	cardData: () => ['contentPackageCategories'],

	Vue: (tile) => ({
		data: {
			categories: tile.contentPackageCategories,
			maxSize: 64
		},

		computed: {
			allowEdit: () => can.edit('system.settings.informationpackages'),
			allowDelete: () => can.delete('system.settings.informationpackages')
		},

		methods: {
			deleteComponent (category) {
				this.categories.splice(this.categories.indexOf(category), 1);
			},

			onSave: ({ model, data }) => {
				model.set('name', data.name);
				return model.save().then(() => {
					cwalert.success(t('Success'));
				}).fail(() => {
					cwalert.error(t('Error'));
				});
			},

			onDelete: ({ model, data }) =>
				confirmation({
					title: t('Remove item'),
					icon: 'category',
					warning: true,
					message: t('Are you sure you want to remove category {category}?', {
						category: data.name
					})
				}).then(() => model.destroy().then(() => {
					cwalert.success(t('Category has been deleted'));
				}, () => {
					cwalert.error(t('Error'));
				}))
		},

		template:
			`<div>
				<p v-if="!categories.length">${t('Content package categories list is empty')}</p>
				<ul>
					<li
						v-for="category in categories.models"
						:key="category.getId()"
						v-on:deleteComponent="deleteComponent(category)"
					>
						<inline-edit
							:model="category"
							:onSave="onSave"
							:onDelete="onDelete"
							:maxSize="maxSize"
							:allowEdit="allowEdit"
							:allowDelete="allowDelete"
						/>
					</li>
				</ul>
			</div>`
	})
});
