<template>
	<form @submit.prevent="save">
		<fieldset>
			<legend class="form-view__fieldset__caption template-details">
				{{t('Template details')}}
			</legend>

			<text-input
				v-model="templateData.dataExportTemplateName"
				:label="t('Export template name')"
				:disabled="!canEdit"
				@input="updateName"
			/>

			<select-input
				v-model="format"
				container-class="select-container"
				label="File format"
				:disabled="!canEdit"
				:options="formats"
				:placeholder="t('Select file format')"
				:searchable="false"
			/>

			<select-input
				v-model="language"
				class-prefix="export-template"
				container-class="select-container"
				label="Template language"
				:disabled="!canEdit"
				:options="languageOptions"
				:placeholder="t('Select language')"
				:searchable="false"
			/>

			<checkbox-input
				v-model="templateData.dataExportTemplateIsPublic"
				class-prefix="export-template"
				:disabled="!canEdit"
				:label="t('Public')"
				:translate="false"
				@input="save"
			/>

			<checkbox-input
				v-model="templateData.dataExportTemplateIsAvailableApi"
				class-prefix="export-template"
				:disabled="!canEdit"
				:label="t('Available in API')"
				:translate="false"
				@input="save"
			/>

			<checkbox-input
				v-model="templateData.dataExportTemplateAllowOverride"
				class-prefix="export-template"
				:disabled="!canEdit"
				:label="t('Allow override')"
				:translate="false"
				@input="save"
			/>
		</fieldset>

		<fieldset>
			<legend class="export-template__legend form-view__fieldset__caption template-details">
				{{t('Template filters')}}
			</legend>

			<checkbox-input
				v-model="templateData.dataExportTemplateIsSubmittedOnly"
				class-prefix="export-template"
				:disabled="!canEdit"
				:label="t('Only submitted assessments')"
				:translate="false"
				@input="save"
			/>

			<select-input
				v-model="range"
				class-prefix="export-template"
				container-class="select-container"
				label="Date range"
				:disabled="!canEdit"
				:options="rangeOptions"
				:placeholder="t('Select date range')"
				:searchable="false"
				:clearable="false"
			/>

			<template v-if="range && range.value === 'CUSTOM'">
				<datetime-input
					v-model="rangeStart"
					label="From"
					type="date"
				/>

				<datetime-input
					v-model="rangeStop"
					label="To"
					type="date"
				/>
			</template>
		</fieldset>

		<fieldset>
			<legend class="export-template__legend form-view__fieldset__caption template-details">
				{{t('Required consent options')}}
			</legend>

			<select-input
				v-model="consentTemplate"
				class-prefix="export-template"
				container-class="select-container"
				label="Consent Template"
				:disabled="!canEdit"
				:options="consents"
				:placeholder="t('Choose a consent template')"
				:searchable="false"
			/>

			<select-input
				v-model="consentOption"
				container-class="select-container"
				label="Consent option"
				:disabled="!canEdit || !consentTemplate"
				:options="consentOptions"
				:placeholder="t('Choose a consent option')"
				:searchable="false"
			/>

			<table v-if="templateConsents.length" class="table">
				<thead>
					<tr>
						<th scope="col">
							{{t('consents.name')}}
						</th>
						<th scope="col">
							{{t('Consent option')}}
						</th>
						<th v-if="canDelete" scope="col">
							{{t('Delete')}}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in templateConsents"
						:key="item.id"
					>
						<td>{{item.consentName}}</td>
						<td>{{item.name}}</td>
						<td v-if="canDelete">
							<button
								class="icon-button__button icon icon--delete"
								@click.prevent="deleteOption(item.id)"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-else class="export-template__no-option">
				{{t('No consent option')}}
			</div>
		</fieldset>
	</form>
</template>

<script>
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'service/cwalert';
import t from 'translate';
import { filter, find, get, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import datetime from 'service/datetime/datetime';

let templateId;

export default {
	title: t('export-template-management.editExportTemplate'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.EXPORT_TEMPLATES,
		op: READ
	}],
	actions: (tile) => {
		templateId = tile.cardContext().get('id');

		return [
			['exportTemplateGui/initSingle', templateId],
			['exportTemplateGui/getConsents', templateId],
			['exportTemplate/getTreatmentTypes', templateId],
			['consents/init']
		];
	},
	data () {
		return {
			consentId: null,
			currentConsentOptionId: null,
			formats: [{
				label: 'CSV',
				value: 'CSV'
			}, {
				label: 'SPSS',
				value: 'SPSS'
			}],
			languages: this.$store.getters['languages/available'],
			rangeOptions: [{
				label: t('All'),
				value: 'ALL'
			}, {
				label: t('Last week'),
				value: 'WEEK'
			}, {
				label: t('Last month'),
				value: 'MONTH'
			}, {
				label: t('Last quarter'),
				value: 'QUARTER'
			}, {
				label: t('Last year'),
				value: 'YEAR'
			}, {
				label: t('Range…'),
				value: 'CUSTOM'
			}],
			templateId,
			updating: false
		};
	},
	computed: {
		...mapGetters('exportTemplateGui', ['templateData']),
		...mapGetters('exportTemplate', ['sortedByName']),
		...mapGetters('consents', ['allConsents']),
		...mapGetters('consent', ['options']),
		...mapGetters('exportTemplateGui', ['templateConsents']),

		canDelete: ({ updating }) =>  can.remove(SYSTEM.SETTINGS.EXPORT_TEMPLATES) && !updating,

		canEdit: ({ updating }) => can.edit(SYSTEM.SETTINGS.EXPORT_TEMPLATES) && !updating,

		consentOptions: ({ options, templateConsents, optionList }) => {
			const availableOptions = filter(options, (option) =>
				!find(templateConsents, { id: option.id }));
			return optionList(availableOptions);
		},

		consents: ({ optionList, allConsents }) => optionList(allConsents),

		consentTemplate: {
			get: ({ consentId, consents, currentOptionId }) => consentId ?
				find(consents, { value: currentOptionId }) :
				null,

			set (newConsent) {
				this.currentOptionId = this.getValue(newConsent);
				this.getOptions(newConsent);
			}
		},

		consentOption: {
			get: ({ currentConsentOptionId, consentOptions }) => currentConsentOptionId ?
				find(consentOptions, { value: currentConsentOptionId }) :
				null,

			set (newConsentOption) {
				this.currentConsentOptionId = this.getValue(newConsentOption);
				this.saveConsentData();
			}
		},

		format: {
			get: ({ templateData, formats }) => templateData ?
				find(formats, { label: templateData.dataExportTemplateFileFormat }) :
				null,

			set (newFormat) {
				this.templateData.dataExportTemplateFileFormat = this.getValue(newFormat);
				this.save();
			}
		},

		language: {
			get: ({ templateData, languageOptions }) => templateData ?
				find(languageOptions, { value: templateData.languageId }) :
				null,

			set (newLanguage) {
				this.templateData.languageId = this.getValue(newLanguage);
				this.save();
			}
		},

		range: {
			get: ({ templateData, rangeOptions }) => templateData ?
				find(
					rangeOptions,
					{ value: templateData.dataExportTemplateFilterDateRange }
				) :
				null,

			set (newRange) {
				this.templateData.dataExportTemplateFilterDateRange = this.getValue(newRange);
				this.save();
			}
		},

		rangeStart: {
			get: ({ templateData }) => templateData ?
				datetime(templateData.dataExportTemplateFilterDateRangeStart)
					.toMoment()
					.format('YYYY-MM-DD') :
				null,

			set (newVal) {
				this.templateData.dataExportTemplateFilterDateRangeStart = datetime(
					newVal
				).toObject();
				this.save();
			}
		},

		rangeStop: {
			get: ({ templateData }) => templateData ?
				datetime(templateData.dataExportTemplateFilterDateRangeStop)
					.toMoment()
					.format('YYYY-MM-DD') :
				null,

			set (newVal) {
				this.templateData.dataExportTemplateFilterDateRangeStop = datetime(
					newVal
				).toObject();
				this.save();
			}
		},

		languageOptions: ({ languages }) => map(languages, (language) => {
			const key = Object.getOwnPropertyNames(language)[0];
			return {
				label: language[key],
				value: key
			};
		})
	},
	methods: {
		...mapActions('consent', ['initConsentOptions']),
		...mapActions('exportTemplateGui', ['updateSingle']),
		...mapActions('exportTemplateGui', ['saveConsent']),
		t,

		deleteOption (id) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: this.t('Delete consent option'),
				message: this.t('consents.removeOption?')
			}).then(() => {
				this.$store.dispatch('exportTemplateGui/deleteQuestion', {
					id: templateId,
					optionId: id
				}).then(() => {
					this.$store.dispatch('exportTemplateGui/getConsents', templateId);
					cwalert.success(this.t('consents.removedOption'));
				});
			});
		},

		getOptions (option) {
			if (!option) {
				this.consentId = null;
				this.currentConsentOptionId = null;

			} else {
				this.consentId = option.value;
				this.initConsentOptions(option.value);
			}
		},

		getValue: (value) => get(value, 'value', null),

		saveConsentData () {
			if (!this.updating) {
				this.updating = true;
				this.saveConsent({
					id: this.templateId,
					optionId: this.currentConsentOptionId
				}).then(() => {
					this.$store.dispatch('exportTemplateGui/initSingle', templateId);
					cwalert.success(this.t('consents.savedOption'));
					this.updating = false;
				});
			}
		},

		optionList: (list) => map(list, (option) => ({
			label: option.name,
			value: option.id
		})),

		save () {
			this.updating = true;
			this.updateSingle({ data: this.templateData, templateId: this.templateId })
				.then(() => {
					cwalert.success(this.t('Success'));
					this.updating = false;
				});
		},

		updateName () {
			const currentName = this.templateData.dataExportTemplateName;
			setTimeout(() => {
				// only when user stopped typing
				if (currentName === this.templateData.dataExportTemplateName) {
					this.save();
				}
			}, 1000);
		}
	}
};
</script>
