import t from 'translate';
import repository from 'repository';
import appBar from 'app-bar';
import addPageCategoryDialog from '../dialogs/new-page-category';
import can from 'acl-can';

const card = {
	title: t('Page categories'),
	name: 'system-settings-page-categories',
	url: 'system/page-categories',

	ctrl: ({ cardData }) => {
		const pageCategories = repository.getPageCategories();
		cardData.set({ pageCategories });

		if (can.add('system.settings.contentmanagement')) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New page category'),
				click: addPageCategoryDialog
			});
		}

		return pageCategories;
	}
};

export { card as pageCategories };
