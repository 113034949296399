import TileView from 'tile-view';
import t from 'translate';
import store from 'store';
import { SimpleList, itemTemplate } from 'components/simple-list/simple-list';

export default TileView.extend({
	title: t('Available packages'),

	acl: [{
		checkpoint: 'administrator.respondents.informationpackages',
		op: 'read'
	}],

	actions: () => ['contentPackageInstances/init'],

	loaded: ({ tile }) => {
		const contentPackageInstances = store.getters['contentPackageInstances/active'];
		const role = (item) => itemTemplate({
			config: tile.config().items,
			name: 'role',
			value: item.roleName,
			className: 'item-description__role item-description--small'
		});
		const context = (item) => itemTemplate({
			config: tile.config().items,
			name: 'context',
			value: item.contextName,
			className: `
					item-description__context 
					item-description--highlight 
					item-description--small
				`
		});

		const renderDescription = (item) => `${role(item)} ${context(item)}`;

		tile.list = new SimpleList({
			el: tile.el,
			items: contentPackageInstances,
			urlParams: (item) => (['content-package-properties', {
				contentPackageInstanceId: item.id
			}]),

			className: {
				positive: (item) => item.isCompleted,
				neutral: (item) => !item.isCompleted
			},

			classNameDesc: {
				positive: () => t('Package is completed'),
				neutral: () => t('Package is not completed')
			},

			content: (item) => `
				<span class="fa simple-list__item-icon icon--content-package"></span>
				<div class="simple-list__item-description">
					<h3 class="item-description__title">${item.name}</h3>
					${renderDescription(item)}
				</div>`
		});

		tile.$el.html(tile.list.$el);
	}
});
