var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "treatment-label__content" },
    [
      _c(
        "ul",
        { staticClass: "treatment-label__container" },
        [
          _c(
            "treatment-label-item",
            { attrs: { label: "Respondent" } },
            [_c("user-link", { attrs: { respondent: _vm.respondent } })],
            1
          ),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "Treatment type" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.get(_vm.treatment, "treatmentType.name", "")) +
                "\n\t\t"
            )
          ]),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "Treatment name" } }, [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.get(_vm.treatment, "name", "")) + "\n\t\t"
            )
          ]),
          _vm._v(" "),
          _vm.canSeeStatus
            ? _c("treatment-label-item", { attrs: { label: "Status" } }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.get(_vm.treatment, "customStatus", "")) +
                    "\n\t\t"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "Enabled" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.booleanLabel("enabled")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "Start date" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.date("startAt")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "End date" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.date("endAt")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c("treatment-label-item", { attrs: { label: "Is complete" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.booleanLabel("complete")) + "\n\t\t")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.respondent
        ? _c("timezone-warning", { attrs: { respondent: _vm.respondent } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }