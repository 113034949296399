<template>
	<div>
		<form
			:class="{'roles-dialog__loading-form': loading}"
			class="roles-dialog"
			@submit.prevent="onSubmit"
		>
			<text-input
				v-model="name"
				:mandatory="true"
				:required="true"
				:translate="false"
				:label="$t('Name')"
				:invalid="$v.name.$dirty && $v.name.$invalid && !$v.name.$pending"
				class-prefix="roles-dialog"
				@input="$v.name.$touch()"
			/>

			<template v-if="$v.name.$dirty && $v.name.$invalid && !$v.name.$pending">
				<mandatory-warning v-if="!$v.name.required" name="name" />
				<mandatory-warning v-else-if="!$v.name.unique" name="name">
					{{roleSet ?
						$t('roleSets.error.roleSetNameIsNotUnique') :
						$t('roles.error.roleNameIsNotUnique')}}
				</mandatory-warning>
			</template>

			<div
				v-if="!roleSet"
				class="
						roles-dialog__field-container
						roles-dialog__field-container--radio
						roles-dialog--mandatory
					"
			>
				<p class="roles-dialog__label-text roles-dialog__mandatory_field">
					{{$t('Type')}}
				</p>

				<div class="roles-dialog__labels-group-container">
					<radio-input
						v-model="type"
						:label="$t('Respondent')"
						class-prefix="roles-dialog"
						value-name="RESPONDENT"
					/>
					<radio-input
						v-model="type"
						:label="$t('Clinician')"
						class-prefix="roles-dialog"
						value-name="CLINICIAN"
					/>
				</div>
			</div>

			<text-input
				v-model="description"
				:multiline="true"
				:translate="false"
				:label="$t('Description')"
				class-prefix="roles-dialog"
			/>

			<div class="roles-dialog__buttons-container">
				<div
					class="roles-dialog__field-container roles-dialog__field-container--button"
				>
					<button
						:disabled="$v.$invalid"
						class="roles-dialog__button roles-dialog__button--submit"
						type="submit"
					>{{$t('Save')}}</button>
				</div>

				<div
					class="roles-dialog__field-container roles-dialog__field-container--button"
				>
					<button
						class="roles-dialog__button roles-dialog__button--button"
						type="button"
						@click="onCancel()"
					>{{$t('Cancel')}}</button>
				</div>
			</div>
		</form>

		<div
			v-if="loading"
			v-loader-spinner
			class="roles-dialog roles-dialog__loader"
		>{{processing}}</div>
	</div>
</template>

<script>
import MandatoryWarning from './mandatory-warning';
import RadioInput from 'components/form/radio-input';
import TextInput from 'components/form/text-input';
import t from 'service/lang/translate';
import { closeDialog } from 'service/form-view-dialog';
import cwalert from 'service/cwalert';
import { required } from 'vuelidate/lib/validators';
import { get } from 'lodash';
import checkRoleAvailability from '../util/check-role-availability';
import checkRolesetAvailability from '../util/check-roleset-availability';

export default {
	components: { MandatoryWarning, RadioInput, TextInput },

	props: {
		role: {
			type: Object,
			required: true
		},
		action: {
			type: String,
			required: true
		},
		successMsg: {
			type: String,
			required: true
		},
		done: {
			type: Function,
			required: true
		}
	},

	data () {
		return {
			loading: false,
			description: get(this.role, 'description', ''),
			name: get(this.role, 'name', ''),
			type: get(this.role, 'type', 'RESPONDENT')
		};
	},

	computed: {
		id: ({ role }) => get(role, 'id'),
		new: ({ id }) => !id,
		roleSet: ({ role }) => role.roleSet,
		processing: () => t('Processing data')
	},

	methods: {
		closeDialog (action, message) {
			this.setLoading(false);

			if (message) {
				closeDialog();
				this[action]();
				cwalert.success(t(message));
			}
		},

		onCancel () {
			closeDialog();
		},

		setLoading (bool) {
			this.loading = bool;
		},

		validate () {
			if (this.$v.name.$invalid) {
				this.$v.name.$touch();
				return false;
			}

			if (!this.roleSet && this.$v.type.$invalid) {
				this.$v.type.$touch();
				return false;
			}

			return true;
		},

		onSubmit () {
			if (!this.validate()) {
				return false;
			}
			this.setLoading(true);
			const data = {
				description: this.description,
				name: this.name,
				type: !this.roleSet ? this.type : null
			};
			data.id = !this.new ? this.id : null;
			this.$store.dispatch(this.action, data).then((response) => {
				cwalert.success(this.successMsg);

				if (this.done) {
					this.done(response.id);
				}
				closeDialog();
			});
		}
	},

	validations: {
		name: {
			required,

			async unique (value) {
				if (value === this.role.name) {
					return true;
				}

				if (this.roleSet) {
					return await checkRolesetAvailability(value);
				}

				return await checkRoleAvailability(value);
			}
		},

		type: {
			required (value) {
				if (this.roleSet) {
					return true;
				}

				return value.length;
			}
		}
	}
};
</script>
