import mixin from './_mixin';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

export default (modalCfg) => new Vue({
	mixins: [mixin(modalCfg)],
	data: {
		type: modalCfg.type,
		updating: false
	},
	computed: {
		roleAvailableOptions () {
			return this.selectOptions(modalCfg.rolesAvailable);
		},
		roleOptions () {
			return this.selectOptions(modalCfg.roles);
		}
	},
	created () {
		if (modalCfg.action === 'updateRoleMapping') {
			this.config.item.role = {
				label: this.config.item.role.name,
				value: this.config.item.role.id
			};
			this.config.item.roleMapped = {
				label: this.config.item.roleMapped.name,
				value: this.config.item.roleMapped.id
			};
		}
	},
	template: `
		<div>
			<standard-dialog
				:config="config"
				:processing="processing"
				:roleAvailableOptions="roleAvailableOptions"
				:roleOptions="roleOptions"
				:type="type"
				:updating="updating"
				:vuelidate="$v"
				:warnings="warnings"
			></standard-dialog>
		</div>
		`,
	validations: {
		item: {
			access: {
				required
			},
			dstRole: {
				required
			},
			srcRole: {
				required
			}
		}
	}
});
