var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "abuse-report" }, [
    _c("section", { staticClass: "abuse-report__section" }, [
      _c("h2", { staticClass: "abuse-report__header" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.t("Reported abuse details")) + "\n\t\t")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "abuse-report__details" }, [
        _c(
          "li",
          { staticClass: "abuse-report__detail" },
          [
            _c("p", { staticClass: "abuse-report__detail-name" }, [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.t("Reported user")) + "\n\t\t\t\t"
              )
            ]),
            _vm._v(" "),
            _vm.currentReport.circleUser
              ? _c("avatar-nickname", {
                  attrs: { user: _vm.currentReport.circleUser }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "abuse-report__detail" }, [
          _c("p", { staticClass: "abuse-report__detail-name" }, [
            _vm._v(
              "\n\t\t\t\t\t" + _vm._s(_vm.t("Reported date")) + "\n\t\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "abuse-report__detail-description" }, [
            _c("time", { attrs: { datetime: _vm.currentReport.reportedAt } }, [
              _vm._v(
                "\n\t\t\t\t\t\t" + _vm._s(_vm.reportedDate) + "\n\t\t\t\t\t"
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "abuse-report__detail" },
          [
            _c("p", { staticClass: "abuse-report__detail-name" }, [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.t("Reported by")) + "\n\t\t\t\t"
              )
            ]),
            _vm._v(" "),
            _vm.currentReport.reportedBy
              ? _c("avatar-nickname", {
                  attrs: { user: _vm.currentReport.reportedBy }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "abuse-report__detail" }, [
          _c("p", { staticClass: "abuse-report__detail-name" }, [
            _vm._v(
              "\n\t\t\t\t\t" +
                _vm._s(_vm.t("Message that was reported")) +
                "\n\t\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("p", {
            staticClass: "abuse-report__detail-description",
            domProps: { innerHTML: _vm._s(_vm.escapedContent) }
          })
        ]),
        _vm._v(" "),
        _vm.contentChanged || _vm.postDeleted
          ? _c("li", { staticClass: "abuse-report__detail" }, [
              _c("p", { staticClass: "abuse-report__detail-name" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.t("Actual post content")) +
                    "\n\t\t\t\t"
                )
              ]),
              _vm._v(" "),
              _vm.postDeleted
                ? _c(
                    "p",
                    {
                      staticClass:
                        "\n\t\t\t\t\t\tabuse-report__detail-description\n\t\t\t\t\t\tabuse-report__detail-description--informative\n\t\t\t\t\t"
                    },
                    [_vm._v(_vm._s(_vm.t("Post has been removed")))]
                  )
                : _vm.contentChanged
                ? _c("p", {
                    staticClass: "abuse-report__detail-description",
                    domProps: { innerHTML: _vm._s(_vm.escapedPostContent) }
                  })
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.currentReport.circlePost
          ? _c("li", { staticClass: "abuse-report__detail" }, [
              _c("p", { staticClass: "abuse-report__detail-name" }, [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.t("Posted date")) + "\n\t\t\t\t"
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "abuse-report__detail-description" }, [
                _c(
                  "time",
                  {
                    attrs: { datetime: _vm.currentReport.circlePost.createdAt }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(_vm.postedDate) + "\n\t\t\t\t\t"
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "abuse-report__section" }, [
      _c("h2", { staticClass: "abuse-report__header" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.t("Circle details")) + "\n\t\t")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "abuse-report__details" }, [
        _c(
          "li",
          { staticClass: "abuse-report__detail" },
          [
            _c("p", { staticClass: "abuse-report__detail-name" }, [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.t("Circle owner")) + "\n\t\t\t\t"
              )
            ]),
            _vm._v(" "),
            _vm.currentReport.circle && _vm.currentReport.circle.owner
              ? _c("avatar-nickname", {
                  attrs: { user: _vm.currentReport.circle.owner }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "abuse-report__detail" }, [
          _c("p", { staticClass: "abuse-report__detail-name" }, [
            _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("Circle name")) + "\n\t\t\t\t")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "abuse-report__detail-description" }, [
            _vm._v(
              "\n\t\t\t\t\t" +
                _vm._s(
                  _vm.currentReport.circle ? _vm.currentReport.circle.name : ""
                ) +
                "\n\t\t\t\t"
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "abuse-report__detail" }, [
          _c("p", { staticClass: "abuse-report__detail-name" }, [
            _vm._v(
              "\n\t\t\t\t\t" +
                _vm._s(_vm.t("Circle description")) +
                "\n\t\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "abuse-report__detail-description" }, [
            _vm._v(
              "\n\t\t\t\t\t" +
                _vm._s(
                  _vm.currentReport.circle
                    ? _vm.currentReport.circle.description
                    : ""
                ) +
                "\n\t\t\t\t"
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }