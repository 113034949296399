import { View } from 'backbone';
import cardurl from 'cwcardurl';
import Table from 'components/table/table';
import t from 'translate';
import datetime from 'datetime';
import repository from 'repository';
import icon from 'service/icon';
import store from 'store';
import { assign, filter, get, isFunction, isEmpty, isObject, map } from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { respondentName } from 'service/display-name/display-name';

const getConfig = (context) => isFunction(context.config) && isObject(context.config()) ?
	context.config() :
	{};

export default View.extend({
	title: t('Flags'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.FLAGS,
		op: READ
	}],
	init () {
		this.customizeTile();
	},
	timezone: 'userTimezone',
	// @TODO method should be removed in near future, two reasons
	// 1. we should decide that tile is configurable or not and prepare acceptable pattern for this
	// 2. we should prepare some patterns for filtering/sorting data returned by repository and
	// keeping them in sync after adding new models etc.
	customizeTile () {
		const config = getConfig(this);
		const variant = get(this, 'variant', config.variant);
		// map url filter params e.g. isSeen, isRaised, newOnly
		const params = assign({}, get(this, 'filter', config.filter));

		// map id as specified entity e.g. respondent, so we can show flags only for specified
		// respondent
		if (!!variant && this.cardContext().get('id')) {
			params[variant] = this.cardContext().get('id');
		}

		if (!isEmpty(params)) {
			this.collection = repository.getFlags(params);
		}

		// override default columns
		if (config.columns) {
			this.columns = filter(
				config.columns,
				(column) => !!this.columnsDefinition()[column]
			);
		}

		this.determineTitle(config);
	},

	determineTitle (config) {
		// override default tile title
		if (config.title) {
			this.setTitle(t(config.title));
		}
	},

	render () {
		if (this.collection) {
			this.collection = repository.getFlags();
		}
		this.load();
	},

	load () {
		this.setLoading();
		this.collection.then(() => {
			this.loaded();
			this.setLoaded();
		});
	},

	columnsDefinition () {
		return {
			isRaised: {
				label: t('Lowered'),
				css: 'flags-list__lowered',
				key: 'isRaised',
				render: (isRaised) => isRaised ?
					EMPTY_CHAR :
					icon('lowered', {
						small: true,
						title: t('Flag has been lowered'),
						modifier: 'success'
					})
			},
			isSeen: {
				label: t('Seen'),
				css: 'flags-list__seen',
				key: 'isSeen',
				render: (isSeen) => isSeen ?
					icon('seen', {
						small: true,
						title: t('Flag has been seen'),
						modifier: 'success'
					}) :
					EMPTY_CHAR
			},
			date: {
				label: t('Date'),
				css: 'flags-list__date',
				key: 'created',
				render: (created) => created ?
					datetime(created)
						.setTimezone(store.getters[this.timezone])
						.toMoment()
						.format(t('date.formats.dateTime')) :
					t('Not specified')
			},
			title: {
				label: t('Flag'),
				css: 'flags-list__title',
				key: 'assessmentFlag',
				render: (assessmentFlag) => assessmentFlag.assessmentFlagName
			},
			assessment: {
				label: t('Assessment'),
				css: 'flags-list__assessment',
				key: 'assessment',
				render: (assessment) => assessment.assessmentName
			},
			treatment: {
				label: t('Treatment'),
				css: 'flags-list__treatment',
				key: 'treatment',
				render: (treatment) => treatment.name
			},
			respondent: {
				label: t('Respondent'),
				css: 'flags-list__respondent',
				key: 'respondent',
				render: respondentName
			}
		};
	},
	columns: ['isRaised', 'isSeen', 'title', 'date', 'respondent', 'assessment'],
	getColumns () {
		return map(this.columns, (column) => this.columnsDefinition()[column]);
	},
	loaded (timezoneGetter = null) {
		if (timezoneGetter) {
			this.timezone = timezoneGetter;
		}
		this.$el.empty();
		this.table = new Table({
			parent: this.el,
			collection: this.collection,
			className: 'flag-list__table table--long-text',
			sortable: true,
			sortOrder: this.config().tableSort,
			click (item) {
				cardurl.openCard('flag', { flagId: item.getId() });
			},
			columns: this.getColumns(),

			extractText: ({ content }) => content
		});
	}
});
