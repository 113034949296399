/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import cwalert from 'cwalert';
import t from 'translate';
import $ from 'jquery';
import { warn } from 'service/log/log';

export default function (attributeName, model, options = {}, mode = {}) {
	const __checkbox = document.createElement('input');
	__checkbox.setAttribute('type', 'checkbox');

	if (options.readonly) {
		$(__checkbox).attr('disabled', 'disabled');
	}
	__checkbox.setAttribute('name', attributeName);

	if (options.label) {
		__checkbox.setAttribute('aria-label', model.get(options.label));
	}

	const __uncheckedValue = (options.uncheckedValue ? options.uncheckedValue : false);
	const __checkedValue = (options.checkedValue ? options.checkedValue : true);

	let __isChecked = false;

	$(__checkbox).on('change', function () {
		__isChecked = this.checked;

		model.set(attributeName, !this.checked ?
			__uncheckedValue :
			__checkedValue
		);

		if (!options.disableAutoSave) {
			model.save({}, {

				success () {
					cwalert.custom(t('general-list.ChangesSaved'), 'success');
				},

				error () {
					cwalert.custom(t('general-list.ChangesNotSaved'), 'error');
				}
			});
		}
	});

	const __refresh = function () {
		if (model.get(attributeName) === undefined) {
			model.set(attributeName, false);
			return;
		}

		if (mode.edit) {
			if (
				model.get(attributeName) !== __checkedValue &&
				model.get(attributeName) !== __uncheckedValue
			) {
				model.set(
					attributeName,
					__isChecked ? __checkedValue : __uncheckedValue,
					{ silent: true }
				);
			}

		}

		if (model.get(attributeName) === __checkedValue) {
			__checkbox.checked = true;
			__isChecked = true;

		} else if (model.get(attributeName) === __uncheckedValue) {
			__checkbox.checked = false;
			__isChecked = false;

		} else if (model.get(attributeName) === null) {
			__checkbox.checked = false;
			__isChecked = false;

		} else {
			warn('invalid value', model.get(attributeName));
		}
	};

	model.on(`change:${attributeName}`, __refresh);

	__refresh();

	this.getDOMNode = function () {
		return __checkbox;
	};
}
