<script>
import { get, flatten, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import selectList from '../components/select-list';

const stages = ['identity', 'selection'];

export default {
	name: 'RespondentWizard',
	components: { selectList },
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			loading: false,
			selected: {
				attributes: [],
				customAttributes: [],
				customAttributesHistory: []
			}
		};
	},
	computed: {
		...mapGetters({
			attributes: 'respondent/attributes/translatedList',
			customAttributes: 'respondent/attributes/custom',
			customAttributesHistory: 'respondent/attributes/customHistory'
		})
	},

	created () {
		this.goNext();
	},

	methods: {
		...mapActions({
			getAttributes: 'respondent/attributes/init',
			getCustomAttributes: 'respondent/attributes/initCustom'
		}),

		onSelect ({ type, selected }) {
			this.selected[type] = selected;
		},

		goNext () {
			this.$emit('stage-change', stages[1]);
			this.loading = true;

			Promise.all([
				this.getAttributes(),
				this.getCustomAttributes()
			]).then(() => {
				this.loading = false;
			});
		},

		submit () {
			const defaultProps = ({ respondentAttributeMetaId, respondentAttributeMetaLabel }) => ({
				identity: respondentAttributeMetaId,
				identifierLabel: respondentAttributeMetaLabel
			});
			const columns = map(this.selected, (selected, type) =>
				map(selected, (item) => ({
					attributes: {
						type: 'respondentAttribute',
						identity: get(item, 'dataExportTemplateColumnRespondentFieldId'),
						identifierLabel: get(item, 'dataExportTemplateColumnRespondentFieldName')
					},
					customAttributes: {
						type: 'respondentCustomAttribute',
						...defaultProps(item)
					},
					customAttributesHistory: {
						type: 'respondentCustomAttributeHistory',
						...defaultProps(item)
					}
				}[type])));

			this.$emit('submit', flatten(columns));
		}
	}
};
</script>

<template>
	<form @submit.prevent="submit">
		<div>
			<select-list
				v-if="attributes.length"
				loop-key="dataExportTemplateColumnRespondentFieldId"
				type="attributes"
				item-label="dataExportTemplateColumnRespondentFieldLabel"
				title="Select attributes"
				:collection="attributes"
				@select="onSelect"
			/>
			<select-list
				v-if="customAttributes.length"
				loop-key="respondentAttributeMetaId"
				type="customAttributes"
				item-label="respondentAttributeMetaLabel"
				title="Select custom attributes"
				:collection="customAttributes"
				@select="onSelect"
			/>
			<select-list
				v-if="customAttributesHistory.length"
				loop-key="respondentAttributeMetaId"
				type="customAttributesHistory"
				item-label="respondentAttributeMetaLabel"
				title="Select custom attributes history"
				:collection="customAttributesHistory"
				@select="onSelect"
			/>
		</div>
		<button-group>
			<submit-button label="Add" />
		</button-group>
	</form>
</template>
