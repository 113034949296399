import t from 'translate';
import { get } from 'lodash';

const mismatchedCard = (card) => t(
	'cardConfig.healthCheck.cardRenameInfo',
	{ card: get(card, 'card-name', '[EMPTY_CARD]') }
);

const wrongPortalCard = (card) => t(
	'cardConfig.healthCheck.cardRemoveInfo',
	{ card: get(card, 'card-name', '[EMPTY_CARD]') }
);

const mismatchedTile = (tileName) => t(
	'cardConfig.healthCheck.tileRenameInfo',
	{ tile: tileName }
);

export const info = ({ type, item }) => {
	const description = {
		mismatchedCard,
		wrongPortalCard,
		mismatchedTile
	}[type];
	return description(item);
};
