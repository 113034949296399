import t from 'translate';
import store from 'store';

const card = {
	name: 'respondent-flags',
	url: 'respondent-flags/:respondentId',
	title: t('respondent.flags'),

	appEvents: {
		'respondent.delete respondent.lose-access': 'closeCard'
	},

	ctrl: () => {
		store.dispatch('treatment/resetId');
		return store.dispatch('flags/init');
	},

	closeCard (respondent) {
		const currentRespondentId = +this.cardContext().get('respondentId');
		const removedRespondentId = +respondent.getId();

		if (currentRespondentId === removedRespondentId) {
			this.close();
		}
	}
};

export { card as respondent };
