<template>
	<p
		v-if="!isCompleteTreatment"
		v-translate
		class="incomplete-treatment"
	>treatment.labels.unavailable</p>
	<form v-else class="add-consent-instance__container" @submit.prevent="save">
		<respondent-search
			v-if="noContext"
			v-model="respondent"
		/>
		<select-input
			v-if="!inTreatmentContext"
			v-model="treatment"
			:options="treatments"
			item-label="id"
			:get-option-label="treatmentLabel"
			label="Treatment"
			:disabled="!respondentSelected"
			:required="true"
			@input="$v.treatment.$touch()"
		/>
		<select-input
			v-if="roles.length"
			v-model="role"
			:options="roles"
			:get-option-label="getRoleLabel"
			label="Role"
			item-label="roleId"
			:required="true"
		/>
		<select-input
			v-model="consent"
			:options="availableConsents"
			item-label="name"
			label="Consent Template"
			:disabled="!consentInputEnabled"
			:required="true"
			@input="$v.consent.$touch()"
		/>
		<datetime-input
			v-model="startAt"
			label="Start date"
			class="datetime-input"
			class-prefix="clinician-group"
			:required="true"
			@input="$v.startAt.$touch()"
		/>
		<button-group class-prefix="add-consent-instance">
			<submit-button :can-submit="canSubmit" label="consents.addInstance" />
		</button-group>
	</form>
</template>

<script>
import cwalert from 'service/cwalert';
import store from 'store';
import systemSettings from 'system-settings';
import t from 'translate';
import { DateTime } from 'luxon';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { camelCase, get, has, split, isNull, isEmpty } from 'lodash';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/treatment';
import redirectOnSave from 'modules/general/shared/redirect-on-save';
import { ADD } from 'service/acl/access-levels';

const DATETIME_OPTS = { includeOffset: false, suppressSeconds: true };

export default {
	title: t('Add consent'),
	acl: [{
		checkpoint: 'administrator.respondents.consents',
		op: ADD
	}],
	actions: () => [
		['respondentSearch/setLimit', 10]
	],

	data: ({ urlParams }) => {
		const [hour, minute] = split(systemSettings.getString('DEFAULT_ASSESSMENT_TIME'), ':');

		return {
			consent: null,
			treatment: {},
			role: null,
			respondent: {},
			startAt: DateTime.fromISO(
				get(
					urlParams,
					'datetime',
					DateTime.fromObject({ hour, minute, second: 0 }).toISO(DATETIME_OPTS)
				)
			).toISO(DATETIME_OPTS),
			loading: false,
			placeholder: ''
		};
	},

	computed: {
		...mapGetters({
			availableConsents: 'treatment/consents',
			roles: 'treatment/respondentRoles',
			isTreatmentReady: 'treatment/ready',
			treatments: 'treatments/ready',
			urlParams: 'urlParams'
		}),

		inRespondentContext: ({ urlParams }) => has(urlParams, 'respondentId'),
		inTreatmentContext: ({ urlParams }) => has(urlParams, 'treatmentId'),
		noContext: ({ inTreatmentContext, inRespondentContext }) =>
			!inTreatmentContext && !inRespondentContext,

		isCompleteTreatment: ({ inTreatmentContext, isTreatmentReady }) =>
			inTreatmentContext ? isTreatmentReady : true,

		respondentSelected: ({ respondent, inRespondentContext }) =>
			inRespondentContext || has(respondent, 'respondentId'),

		treatmentSelected: ({ treatment, inTreatmentContext }) =>
			inTreatmentContext || has(treatment, 'id'),

		consentInputEnabled: ({ treatmentSelected, roles, role }) =>
			roles.length ? role : treatmentSelected,

		canSubmit: ({ $v, role, roles }) => !isEmpty(roles) ? (role && !$v.$invalid) : !$v.$invalid
	},

	watch: {
		treatment (val) {
			this.role = null;
			this.consent = null;

			if (!this.inTreatmentContext && isNull(val)) {
				this.$store.dispatch('treatment/reset');
				return;
			}
			this.retrieveConsentTemplates({
				treatmentId: get(val, 'id'),
				respondentId: this.inRespondentContext ?
					store.getters[RESPONDENT.RESPONDENT_ID] :
					get(this.respondent, 'respondentId')
			});
		},

		respondent () {
			const respondentId = get(this.respondent, 'respondentId');
			this.treatment = null;
			store.dispatch('treatments/initForRespondent', { respondentId });
		},

		role (val) {
			if (isNull(val)) {
				this.consent = null;
				return;
			}

			const treatmentId = get(this.urlParams, 'treatmentId') ||
				store.getters[TREATMENT.ID];
			const respondentId = get(this.role, 'respondent.id');
			respondentId && treatmentId && store.dispatch(TREATMENT.RETRIEVE_CONSENT_TEMPLATES, {
				treatmentId,
				respondentId
			});
		}
	},

	created () {
		if (this.$store.getters[RESPONDENT.RESPONDENT_ID] && this.$store.getters[TREATMENT.ID]) {
			this.retrieveConsentTemplates({
				treatmentId: this.$store.getters[TREATMENT.ID],
				respondentId: this.$store.getters[RESPONDENT.RESPONDENT_ID]
			});
		}
	},

	methods: {
		...mapActions('consent-instance', ['addConsentInstance']),

		retrieveConsentTemplates ({ treatmentId, respondentId }) {
			this.tile.setLoading();
			this.consent = null;
			store.dispatch('treatment/initWithoutRespondent', { treatmentId }).then(() => {
				this.tile.setLoaded();

				if (isEmpty(this.roles)) {
					store.dispatch(
						TREATMENT.RETRIEVE_CONSENT_TEMPLATES,
						{ treatmentId, respondentId }
					);
				}
			});
		},

		treatmentLabel: (option) => option.name,

		getRoleLabel: (option) => {
			let name = `${option.roleName} `;

			if (option.respondent) {
				name += option.respondent.isAccessible ?
					`[${respondentName(option.respondent)}]` :
					t('<no access to respondent>');

			} else if (option.clinician) {
				name += `[${clinicianName(option.clinician)}]`;
			}
			return name;
		},

		save () {
			const data = {
				consent: {
					id: this.consent.id
				},
				treatment: {
					id: get(
						this.treatment, 'id',
						get(this.urlParams, 'treatmentId')
					)
				},
				respondent: {
					id: get(
						this.respondent,
						'id',
						get(
							this.role,
							'respondent.id',
							this.$store.getters[RESPONDENT.RESPONDENT_ID]
						)
					)
				},
				startAt: this.startAt
			};
			this.setDataLoading();
			store.dispatch('consentInstance/addConsentInstance', data).then(
				(res) => {
					this.setDataLoaded();
					cwalert.success(t('consents.addSuccess'));

					redirectOnSave({
						config: get(this.tile.config(), 'redirectOnSave', {}),
						fallback: ['consent-instance', { consentInstanceId: res.id }]
					});
				},
				(res) => {
					this.setDataLoaded();
					cwalert.error([
						t('consents.error.add'),
						t(`consents.error.${camelCase(res.responseJSON.exception.msg)}`)
					].join(' '));
				}
			);
		}
	},

	validations () {
		const validations = {
			consent: { required },
			startAt: { required }
		};

		if (!this.inTreatmentContext) {
			validations.treatment = { required };
		}

		return validations;
	}
};
</script>
