import celllabel from 'cell-label';
import { View } from 'backbone';
import { forEach } from 'lodash';
import datetime from 'datetime';
import dom from 'cwdom';
import Flags from '../../entities/report-flags';
import $ from 'jquery';
import formView from 'components/form-view/form-view';
import moment from 'moment';
import t from 'translate';
import appContext from 'app-context';

export default View.extend({
	title: t('admin-portal.FlagReports'),
	acl: [{
		checkpoint: 'system.reports.flags',
		op: 'OPEN'
	}],

	render () {
		this.renderDescription();

		const flagModel = new Flags({
			fromDate: moment().startOf('day').subtract(7, 'days'),
			toDate: moment().startOf('day')
		});

		const form = formView({
			name: 'system-reports-flags',
			model: flagModel,
			listenTo: ['submit'],
			successMessage: false,
			saveIndicator: true,
			onAfterSave: this.renderReport.bind(this),
			beforeSave () {
				this.model.set('fromDate', datetime(this.model.get('fromDate')).toObject(), {
					silent: true
				});
				this.model.set('toDate', datetime(this.model.get('toDate')).toObject(), {
					silent: true
				});
			},
			fields: [{
				key: 'fromDate',
				name: 'fromDate',
				label: t('general-list.From'),
				type: 'date'
			}, {
				key: 'toDate',
				name: 'toDate',
				label: t('general-list.To'),
				type: 'date'
			}],
			buttons: [{
				caption: t('general-list.Search'),
				type: 'submit',
				name: 'search-submit'
			}]
		});

		this.$el.append(form.$el);
	},

	renderReport (response) {
		// @TODO(2015-09-29): just rewrite it all
		const flags = response.flags;
		$(this.table).empty();
		$(this.noItems).empty();

		if (flags.length === 0) {
			this.noItems = dom.createElement({
				parent: this.el,
				tag: 'p',
				id: 'no-flags-message',
				role: 'alert',
				content: [t('admin-portal.NoFlagsMessage')]
			});
			return;
		}

		this.table = dom.createElement({
			tag: 'table',
			id: 'report-table',
			css: 'cwtable  cwtable--modest',
			parent: this.el,
			attrs: {
				tabindex: 0
			}
		});
		const thead = dom.createElement({
			tag: 'thead',
			id: 'report-table-thead',
			parent: this.table
		});
		const thTr = dom.createElement({
			tag: 'tr',
			id: 'report-table-head-tr',
			css: 'report-table-head-tr',
			parent: thead
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('admin-portal.FlagName'),
			id: 'report-table-head-flagname',
			css: 'report-table-head-flagname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.AssessmentName'),
			id: 'report-table-head-username',
			css: 'report-table-head-username',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.FirstName'),
			id: 'report-table-head-firstname',
			css: 'report-table-head-firstname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.LastName'),
			id: 'report-table-head-lastname',
			css: 'report-table-head-lastname',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.userName'),
			id: 'report-table-head-username',
			css: 'report-table-head-username',
			parent: thTr
		});
		dom.createElement({
			tag: 'th',
			innerHTML: t('general-list.Created'),
			id: 'report-table-head-date',
			css: 'report-table-head-date',
			parent: thTr
		});
		this.tbody = dom.createElement({
			tag: 'tbody',
			parent: this.table
		});

		const tbody = this.tbody;

		forEach(flags, (flag) => {
			const tr = dom.createElement({
				tag: 'tr',
				parent: tbody
			});

			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.assessmentFlagName
			}), t('admin-portal.FlagName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.assessmentName
			}), t('general-list.FirstName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.respondentFirstName
			}), t('general-list.FirstName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.respondentLastName
			}), t('general-list.LastName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.respondentUsername
			}), t('general-list.userName'));
			celllabel(dom.createElement({
				tag: 'td',
				parent: tr,
				innerHTML: flag.assessmentInstanceLastModified ?
					datetime({
						unixtime: flag.assessmentInstanceLastModified
					})
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.utc()
						.format(t('date.formats.dateTime')) : t('Not specified')
			}), t('general-list.Created', 'Created'));
		});
	},

	renderDescription () {
		const $description = $('<p class="text-content" />')
			.html(t('admin-portal.FlagReportsIntroduction'));
		this.$el.prepend($description);
		return this;
	}
});

