import { closeDialog } from 'service/form-view-dialog';
import CardDialog from 'service/card-dialog/card-dialog';
import t from 'translate';
import Vue from 'vue';
import store from 'store';
import cardUrl from 'cwcardurl';
import { required } from 'vuelidate/lib/validators';
import can from 'acl-can';

export default ({ state }) => {
	const dialog = new Vue({
		el: document.createElement('div'),
		store,
		data: {
			classPrefix: 'new-menu-item-dialog',
			state,
			label: ''
		},

		computed: {
			invalidLabel () {
				return this.$v.label.$dirty && this.$v.label.$invalid;
			},

			canSubmit () {
				return !this.$v.label.$invalid && can.add('system.settings.settings');
			}
		},

		methods: {
			addItem () {
				const type = this.state.type;
				this.$store.dispatch(
					`engine/${type}Sidebar/add`,
					{ label: this.label }
				).then(() => {
					const currentVariant = this.$store.getters[
						`engine/${type}Sidebar/currentVariant`
					];

					closeDialog();
					cardUrl.openCard('menu-composer-edit-item', {
						type,
						variant: currentVariant.name,
						index: this.$store.getters[`engine/${type}Sidebar/currentCount`] - 1
					});
				});
			},

			onCancel () {
				closeDialog();
			}
		},
		template: `
		<form :class="classPrefix" @submit.prevent="addItem">
			<text-input
				v-model="label"
				label="Label"
				:class-prefix="classPrefix"
				:required="true"
			/>

			<button-group :class-prefix="classPrefix">
				<submit-button
					label="Add"
					:can-submit="canSubmit"
					:class-prefix="classPrefix"
				/>
				<plain-button
					:on-click="onCancel"
					:class-prefix="classPrefix"
				/>
			</button-group>
		</form>
		`,
		validations: {
			label: {
				required
			}
		}
	});

	return new CardDialog({
		preventFocusTrap: true,
		title: t('Add a menu item'),
		content: dialog.$el
	});
};
