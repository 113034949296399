import Label from './Label';
import LabelFactory from './LabelFactory';
import Checkbox from './Checkbox';
import CheckboxFactory from './CheckboxFactory';
import Text from './Text';
import TextFactory from './TextFactory';
import TextMedium from './TextMedium';
import TextMediumFactory from './TextMediumFactory';
import Dropdown from './Dropdown';
import DropdownFactory from './DropdownFactory';
import TextSmall from './TextSmall';
import TextSmallFactory from './TextSmallFactory';

const widgets = {
	Label,
	LabelFactory,
	Checkbox,
	CheckboxFactory,
	TextFactory,
	Text,
	TextMedium,
	TextMediumFactory,
	Dropdown,
	DropdownFactory,
	TextSmall,
	TextSmallFactory
};

export default widgets;
