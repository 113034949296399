import TileView from 'tile-view';
import t from 'translate';
import messagesComponent from '../../shared/messages-component';
import cardUrl from 'cwcardurl';

export default TileView.extend({
	title: t('Unanswered messages'),
	features: ['ENABLE_MESSAGING_SYSTEM'],
	acl: [{
		checkpoint: 'administrator.respondents.communication',
		op: 'READ'
	}],
	instant: true,

	loaded: ({ tile }) => {
		const msgList = messagesComponent({
			provideUrl: (message) => cardUrl.buildUrl('respondent-messages', {
				respondentId: message.respondent.respondentId,
				treatmentId: message.treatment.id
			})
		});
		tile.$el.html(msgList.$el);
	}
});
