import repository from 'repository';
import store from 'store';
import t from 'translate';

const card = {
	title: t('Edit export template'),
	name: 'edit-export-template',
	url: 'export-template/:exportTemplateId/edit',

	ctrl: ({ cardData }, { exportTemplateId }) => {
		const exportTemplate = repository.getExportTemplate(exportTemplateId);
		const assessments = store.dispatch(
			'exportTemplate/getAvailableAssessments', exportTemplateId
		);
		const treatmentTypes = store.dispatch(
			'exportTemplate/getTreatmentTypes', exportTemplateId
		);

		return Promise.all([assessments, treatmentTypes, exportTemplate]).then(() => {
			cardData.set({
				exportTemplateUrl: exportTemplate.getDownloadUrl().toString()
			});
		});
	}
};

export { card as AddColumn };
