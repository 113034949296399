var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consent__clinician-group--container" },
    [
      _c("assign-items", {
        attrs: {
          "can-add": _vm.canEdit,
          "can-remove": _vm.canEdit,
          labels: _vm.labels,
          getters: _vm.getters,
          actions: _vm.actions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }