import SearchTileView from 'core/engine/tile/views/search-tile-view';
import t from 'translate';
import repository from 'repository';
import datetime from 'datetime';
import appContext from 'app-context';
import $ from 'jquery';
import can from 'acl-can';
import sanitize from 'service/sanitize-string/sanitize-string';
import cardUrl from 'cwcardurl';
import store from 'store';
import { assign, compact, escape, map, replace } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default SearchTileView.extend({
	title: t('System logs'),
	acl: [{
		checkpoint: SYSTEM.LOG.LOG,
		op: READ
	}],

	cardData: () => ['statuses', 'types'],

	searchFn: repository.searchSystemEventLogs.bind(repository),

	fields () {
		const ids = (value) => compact(
			map(value, (item, key) => item && key)
		);

		return [{
			key: 'from',
			type: 'datetime',
			label: t('Start date'),

			onChange: (isoDateString) => {
				this.viewModel.set(
					'from',
					isoDateString ?
						datetime(isoDateString)
							.useTimezone(store.state.user.timezoneName)
							.toMoment().unix() :
						null
				);
				this.runQuery();
			}
		}, {
			key: 'to',
			type: 'datetime',
			label: t('End date'),

			onChange: (isoDateString) => {
				this.viewModel.set(
					'to',
					isoDateString ?
						datetime(isoDateString)
							.useTimezone(store.state.user.timezoneName)
							.toMoment().unix() :
						null
				);
				this.runQuery();
			}
		}, {
			key: 'status',
			type: 'single-select',
			label: t('Status'),

			values: this.statuses.toJSON(),

			onChange: (value) => {
				this.viewModel.set('status', ids(value).toString());
				this.runQuery();
			}
		}, {
			key: 'type',
			type: 'single-select',
			label: t('Type'),

			values: map(this.types.toJSON(), (type) => assign({}, type, {
				id: type.label,
				label: replace(type.label, /_/g, ' ')
			})),

			onChange: (value) => {
				this.viewModel.set('type', ids(value).toString());
				this.runQuery();
			}
		}, {
			key: 'respondent',
			type: 'text-input',
			label: t('Respondent'),
			onChange: (respondent) => {
				this.viewModel.set({ respondent });
				this.runQuery();
			}
		}, {
			key: 'clinician',
			type: 'text-input',
			label: t('Clinician'),
			onChange: (clinician) => {
				this.viewModel.set({ clinician });
				this.runQuery();
			}
		}, {
			key: 'message',
			type: 'text-input',
			label: t('Text'),
			onChange: (message) => {
				this.viewModel.set({ message });
				this.runQuery();
			}
		}];
	},

	filterCfg: () => ({
		searchFields: [],
		provideResultsCount: (results) => t('{current} records displayed', {
			current: results.size(),
			total: results.total
		})
	}),

	tableCfg () {
		const tableCfg = {
			emptyListMessage: t('No log entries found.'),
			css: 'system-log__table',
			columns: [{
				key: 'timestamp',
				label: t('Timestamp'),
				render: (timestamp) =>
					datetime(timestamp)
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime'))
			}, {
				key: 'administrator',
				label: t('Clinician'),
				render (admin) {
					if (!admin) {
						return;
					}
					let $clinician;

					if (can.read('system.administrators.administrators')) {
						$clinician = $('<a />').attr({
							href: cardUrl.buildUrl('clinician-profile', {
								id: admin.id
							})
						});

					} else {
						$clinician = $('<span />');
					}

					$clinician.html(
						`${sanitize(admin.displayName)} (#${admin.id})`
					).appendTo(this.td);
				}
			}, {
				key: 'message',
				label: t('Message'),
				css: 'system-log__message',
				render (msg) {
					$(this.td).html(escape(msg));
				}
			}, {
				key: 'respondent',
				label: t('Respondent'),
				render (respondent) {
					if (!respondent) {
						return;
					}
					let $respondent;
					const read = can.read('administrator.respondents.respondents') &&
						!!respondent.isAccessible;

					if (read) {
						$respondent = $('<a />').attr({
							href: cardUrl.buildUrl('respondent-status', {
								respondentId: respondent.id
							})
						});

					} else {
						$respondent = $('<span />');
					}
					$respondent.html(
						`${sanitize(respondent.displayName)} (#${respondent.id})`
					).appendTo(this.td);
				}
			}, {
				key: 'statusId',
				label: t('Status'),
				render: (statusId) => this.statuses.get(statusId).get('label')
			}, {
				key: 'typeId',
				label: t('Type'),
				render: (typeId) => replace(typeId, /_/g, ' ')
			}]
		};

		return tableCfg;
	}
});
