import mixin from './_mixin';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { assign, map } from 'lodash';

export default (modalCfg) => new Vue({
	mixins: [mixin(modalCfg)],
	data: {
		codeTypeOptions: modalCfg.codeTypeOptions,
		portalOptions: modalCfg.portalOptions,
		type: modalCfg.type,
		updating: false
	},
	computed: {
		assessmentOptions: () => map(modalCfg.assessments, (single) =>
			assign(single, {
				label: single.assessmentCode,
				value: single.assessmentSuperAssessmentId
			})),

		codeOptions () {
			return this.selectOptions(modalCfg.codes, 'code');
		}
	},
	template: `
		<div>
			<standard-dialog
				:config="config"
				:assessment-options="assessmentOptions"
				:code-options="codeOptions"
				:code-type-options="codeTypeOptions"
				:portal-options="portalOptions"
				:processing="processing"
				:type="type"
				:updating="updating"
				:vuelidate="$v"
				:warnings="warnings"
			/>
		</div>
		`,
	validations: {
		item: {
			access: {
				required
			},
			code: {
				required
			},
			superAssessment: {
				required
			},
			submitPortal: {
				required
			}
		}
	}
});
