import TreatmentAttributes from './attribute-entities';
import SingleEntity from '../shared/single-entity';
import urls from './urls';
import moment from 'moment';
import t from 'translate';
import ClinicianTreatments from './clinician-entities';

/**
 * Treatment Entity
 * @class Treatment
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.single.url,
	eventNamespace: 'treatment',
	defaults: {
		startAt: null,
		endAt: null,
		status: 'ACTIVE',
		enabled: true
	},

	validate: function (attrs) {
		const startDate = attrs.startAt && moment(attrs.startAt);
		const endDate = attrs.endAt && moment(attrs.endAt);
		const keys = [];
		const msgs = [];

		if (startDate && endDate && startDate > endDate) {
			keys.push('startAt');
			keys.push('endAt');
			msgs.push(t('Start date cannot be higher than end date.'));
			msgs.push(t('End date cannot be lower than start date.'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	},
	/**
	 * @method getClinicians
	 * @return {Object} {{#crossLink "TreatmentClinicians"}}{{/crossLink}}
	 */
	getClinicians: function () {
		return ClinicianTreatments.getByTreatmentId(this.getId());
	},

	/**
	 * @method getAttributes
	 * @return {Object} {{#crossLink "TreatmentAttributes"}}{{/crossLink}}
	 */
	getAttributes: function () {
		return TreatmentAttributes.getByTreatment(this.getId());
	}
});

