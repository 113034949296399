import TileView from 'tile-view';
import t from 'translate';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';

export default TileView.extend({
	className: '',
	title: t('Flag reason templates'),
	acl: [{
		checkpoint: 'system.settings.flag-unraise-reasons',
		op: 'READ'
	}],
	appEvents: {
		'reason-template.create' () {
			this.refreshTable({ tile: this });
		}
	},

	cardData: () => ['reasonTemplates'],

	loaded: ({ tile, data }) => {
		tile.table = new datamodel.grid.Table({
			collection: data.reasonTemplates,
			readonly: !can.edit(tile.acl[0].checkpoint),
			columns: {
				name: {
					label: t('Name'),
					rendererFactory: new datamodel.widgets.TextFactory({
						noempty: true
					})
				},
				reasonOrder: {
					label: t('Order'),
					rendererFactory: new datamodel.widgets.TextFactory()
				}
			},
			actions: {
				remove: {
					disabled: !can['delete']('system.settings.flag-unraise-reasons')
				}
			},
			container: tile.el
		});
	},

	refreshTable: ({ tile }) => {
		tile.table.refresh();
	}
});
