import t from 'translate';

const card = {
	name: 'unseen-assessments-list',
	url: 'unseen-assessments',
	title: t('Unread assessments'),

	ctrl: ({ populate, cardConfig, cardData }) => {
		cardData.set({
			limit: cardConfig.limit
		});
		populate();
	}
};

export { card as UnseenAssessments };
