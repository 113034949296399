import can from 'acl-can';
import addRoleDialog from '../dialogs/new-system-access-role';
import appbar from 'app-bar';
import t from 'translate';

const card = {
	title: t('system-access-management.card-title'),
	name: 'system-access-management',
	url: 'system/access',

	controller () {
		this.populate();

		if (can.add('system.settings.administratorroles')) {
			appbar.addButton({
				sourceCard: card.name,
				icon: 'add',
				click: addRoleDialog,
				title: t('New management role')
			});
		}
	}
};

export { card as accessManagement };
