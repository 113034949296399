import $ from 'jquery';
import TileView from 'tile-view';
import repository from 'repository';
import t from 'translate';
import Table from 'components/table/table';
import cwalert from 'cwalert';
import can from 'acl-can';

export default TileView.extend({
	title: t('system-manager.ReportManagementList'),
	acl: [{
		checkpoint: 'system.settings.reports',
		op: 'READ'
	}],

	init () {
		this.reports = repository.getReports();
	},

	onRender () {
		this.load(this.reports);
	},

	loaded () {
		this.table = new Table({
			parent: this.el,
			collection: this.reports,
			css: 'report-list__table',
			columns: [{
				key: 'assessmentReportName',
				label: t('system-manager.assessmentReportName')
			}, {
				key: 'assessmentReportCode',
				label: t('system-manager.assessmentReportCode')
			}, {
				key: 'assessmentReportVersion',
				label: t('system-manager.assessmentReportVersion')
			}, {
				key: 'assessmentPackageName',
				label: t('Package Name')
			}, {
				key: 'assessmentPackageVersion',
				label: t('Package Version')
			}, {
				key: 'assessmentPackageRevision',
				label: t('Package Revision')
			}, {
				key: 'assessmentReportNoRespondent',
				label: t('Clinican only'),
				className: 'report-list__table-item--centered',
				render () {
					const $input = $('<input type="checkbox" />').prop({
						checked: this.item.get('assessmentReportNoRespondent'),
						disabled: !can.edit('system.settings.reports')
					});
					$input.on('click', () => {
						this.item.set(
							'assessmentReportNoRespondent',
							!this.item.get('assessmentReportNoRespondent')
						);
						this.item.save().done(() => {
							cwalert.success(t('Changes saved.'));
						}).fail(() => {
							cwalert.error(t('Failed'));
						});
					});
					$(this.td).html($input);
				}
			}]
		});
	}
});
