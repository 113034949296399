import t from 'translate';
import store from 'store';

const card = {
	name: 'system-settings-assessment-portal',
	title: t('System settings - Assessment Portal'),
	url: 'system/assessment-portal',
	ctrl: () => store.dispatch('systemSettings/init')
};

export { card as assessmentPortalSettings };
