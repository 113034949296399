import appBar from 'app-bar';
import t from 'translate';
import cwalert from 'cwalert';
import repository from 'repository';
import can from 'acl-can';
import eventHandlers from '../shared/event-handlers-mixin';
import confirmation from 'components/confirmation/confirmation';
import store from 'store';

const card = {
	name: 'respondent-edit',
	url: 'respondent/:respondentId/edit',
	title: t('respondent.edit'),

	appEvents: {
		'respondent.delete respondent.lose-access' (respondent) {
			eventHandlers.closeCard({
				respondent,
				data: this.cardData,
				close: this.close,
				name: card.name
			});
		}
	},

	ctrl: ({ cardData, populate, actions }, { respondentId }) => {
		const onAttributesLoaded = (attributes) => {
			cardData.set({ attributes });
			populate();
			actions(['respondent/init']).then(() => {
				const respondent = store.state.respondent.model;
				cardData.set({ respondent });

				respondent.canBeDeleted() && card.mountDeleteButton(respondent);
			});
		};

		if (can.read('administrator.respondents.attributes')) {
			repository.getRespondentAttributeValues(respondentId).done(onAttributesLoaded);

		} else {
			onAttributesLoaded();
		}
	},

	mountDeleteButton: (respondent) => {
		const confirmMsg = t('-respondent.account.delete-confirmation', {
			respondent: respondent.displayName()
		});

		appBar.addButton(card.name, {
			title: t('Delete respondent'),
			icon: 'delete',
			click () {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Delete respondent'),
					message: confirmMsg
				}).done(() => {
					card.deleteRespondent(respondent);
				});

				return false;
			}
		});
	},

	deleteRespondent (respondent) {
		respondent.destroy({
			success () {
				cwalert.success(t('general-list.RemoveSuccessfully'));
			},

			error () {
				cwalert.failure(t('general-list.RemoveError'));
			}
		});
	}
};

export { card as RespondentEdit };
