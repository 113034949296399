/* eslint-disable camelcase */
import $, { when } from 'jquery';
import appContext from 'app-context';
import repository from 'repository';
import t from 'translate';
import cardUrl from 'cwcardurl';
import { get, map } from 'lodash';
require.context(
	'file-loader?name=[path][name].[ext]&context=node_modules/tinymce-i18n!tinymce-i18n/langs',
	true,
	/.*/
);
const $editorContainer = $('<div id="editor__container"></div>');

const init = async ({ autospawnAssessmentList, page, pageLinks, tinyMCE }) => {
	const langCode = {
		da: 'da',
		de: 'de',
		ee: 'en',
		en: 'en_GB',
		es: 'es',
		is: 'is_IS',
		nb: 'nb_NO',
		pl: 'pl',
		sv: 'sv_SE'
	}[appContext.get('user').get('userLanguageCode')];

	await tinyMCE.init({
		selector: `#${$editorContainer.attr('id')}`,
		content_css: [
			'main.css',
			'styles/custom.css'
		],
		menubar: 'edit insert view format table tools',
		plugins: ['mediaanddocuments'],
		external_plugins: {
			advlist: 'tinymce/plugins/advlist/plugin.min.js',
			anchor: 'tinymce/plugins/anchor/plugin.min.js',
			autolink: 'tinymce/plugins/autolink/plugin.min.js',
			charmap: 'tinymce/plugins/charmap/plugin.min.js',
			code: 'tinymce/plugins/code/plugin.min.js',
			contextmenu: 'tinymce/plugins/contextmenu/plugin.min.js',
			fullscreen: 'tinymce/plugins/fullscreen/plugin.min.js',
			image: 'tinymce/plugins/image/plugin.min.js',
			insertdatetime: 'tinymce/plugins/insertdatetime/plugin.min.js',
			link: 'tinymce/plugins/link/plugin.min.js',
			lists: 'tinymce/plugins/lists/plugin.min.js',
			media: 'tinymce/plugins/media/plugin.min.js',
			paste: 'tinymce/plugins/paste/plugin.min.js',
			preview: 'tinymce/plugins/preview/plugin.min.js',
			print: 'tinymce/plugins/print/plugin.min.js',
			searchreplace: 'tinymce/plugins/searchreplace/plugin.min.js',
			table: 'tinymce/plugins/table/plugin.min.js',
			visualblocks: 'tinymce/plugins/visualblocks/plugin.min.js'
		},
		height: 500,
		mobile: {
			resize: true,
			toolbar_mode: 'floating'
		},
		toolbar: `fullscreen | insertfile undo redo | styleselect | bold italic |
				alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
				table link image media | mediaanddocuments | autospawn`,
		language: langCode,
		theme_url: 'tinymce/themes/silver/theme.min.js',
		skin_url: 'tinymce/skins/ui/oxide',
		media_live_embeds: true,
		image_advtab: true,
		link_list: pageLinks,

		setup: (editor) => {
			editor.on('init', () => {
				if (page) {
					editor.show();
					editor.setContent(page.get('content') || '');
				}
			});

			editor.on('change', () => {
				page.set('content', editor.getContent());
			});

			const autospawnMenuItems = [];

			autospawnAssessmentList.each((model) => {
				autospawnMenuItems.push({
					text: model.get('assessmentName'),
					type: 'menuitem',
					onAction: () => {
						const link = `<a href="#autospawn,${
							model.get('assessmentAutospawnIdentifier')
						}">${model.get('assessmentName')}</a>`;
						editor.insertContent(link);
					}
				});
			});

			editor.ui.registry.addMenuButton('autospawn', {
				text: t('Autospawn'),
				fetch: (callback) => {
					callback(autospawnMenuItems);
				}
			});
		}
	});

};

export default (page) => (view, formView) => {
	formView.setLoading();
	const autospawnAssessmentList = repository.getAutospawnAssessments();
	const pages = repository.getPages();
	$editorContainer.attr('id', `editor__container-${new Date().valueOf()}`);
	view.ui.input.replaceWith($editorContainer);

	when(
		import(
			/* webpackChunkName: "tinymce" */
			'tinymce'
		),
		import(
			/* webpackChunkName: "tinymce-media" */
			`modules/pages/tiles/edit-page/tinymce-plugins/media-and-documents/media-and-documents`
		),
		autospawnAssessmentList,
		pages
	).then(async (tinyMCE) => {
		const pageLinks = map(pages.toJSON(), (item) => ({
			title: get(item, 'alias'),
			value: cardUrl.buildUrl('page', { id: item.id })
		}));
		await init({ autospawnAssessmentList, page, pageLinks, tinyMCE: tinyMCE.default });
		formView.setLoaded();
		view.on('remove', () => {
			tinyMCE.remove($editorContainer.attr('id'));
		});
	});
};
