<template>
	<form
		novalidate
		autocomplete="off"
		class="form-view"
		@submit.prevent="onSubmit"
	>
		<treatment-program-form
			v-model="treatmentProgram"
			@validate="onValidate"
		/>

		<button-group>
			<submit-button
				:can-submit="valid"
				:label="$t('general.buttons.add')"
			/>
		</button-group>
	</form>
</template>

<script>
import t from 'translate';
import cardUrl from 'cwcardurl';
import cardControls from 'core/engine/card/services/card-controls';
import cwalert from 'service/cwalert';
import { SYSTEM } from 'service/acl/checkpoints';
import { ADD } from 'service/acl/access-levels';
import { error } from 'service/log/log';
import TreatmentProgramForm from '../../components/treatment-program-form';
import { TREATMENT_PROGRAMS } from 'store/treatments/treatment-programs';

export default {
	instant: true,
	title: t('treatmentProgram.add'),

	acl: [{
		checkpoint: SYSTEM.SETTINGS.TREATMENT_PROGRAMS,
		op: ADD
	}],

	components: {
		TreatmentProgramForm
	},

	data: () => ({
		treatmentProgram: {
			name: ''
		},
		valid: false
	}),

	methods: {
		onValidate (valid) {
			this.valid = valid;
		},

		async onSubmit () {
			try {
				const newTreatmentProgram = await this.$store.dispatch(
					TREATMENT_PROGRAMS.CREATE,
					this.treatmentProgram
				);

				cwalert.success(this.$t('general.messages.create.success'));

				cardControls.closeCard({
					go: cardUrl.buildUrl(
						'edit-treatment-program',
						{ treatmentProgramId: newTreatmentProgram.id }
					)
				});

			} catch (err) {
				cwalert.error(this.$t('general.messages.changes-not-saved'));
				error(err);
			}
		}
	}
};
</script>
