<template>
	<div :class="className">
		<p class="content-field__text">
			{{label}}
		</p>
		<p class="content-field__value">
			<slot />
		</p>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},

		multiline: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		className: ({ multiline }) => ({
			'content-field': true,
			'content-field--multiline': multiline
		})
	}
};
</script>
