import TileView from 'tile-view';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import t from 'translate';
import vSelect from 'vue-select';

import { differenceBy } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Role sets'),
	acl: [], // to be provided in the future

	actions: (tile) => {
		const treatmentTypeId = tile.cardContext().get('treatmentTypeId');
		return [
			['roleSets/searchAll'],
			['treatmentType/getRoleSets', treatmentTypeId]
		];
	},

	Vue: () => ({
		data: {
			selected: null,
			canEdit: can.edit(SYSTEM.SETTINGS.TREATMENT_TYPES)
		},
		components: { vSelect },
		computed: {
			...mapGetters({ roleSets: 'treatmentType/roleSets' }),
			...mapGetters({ list: 'roleSets/list' }),
			filteredList () {
				if (this.roleSets) {
					return differenceBy(this.list, this.roleSets, 'id');
				}
			},
			removeLabel: () => t('Remove role'),
			selectPlaceholder: () => t('Select a role set or type a name')
		},
		methods: {
			...mapActions('treatmentType', ['addRoleSet', 'deleteRoleSet']),
			add (obj = null) {
				if (obj) {
					this.addRoleSet({ id: obj.id }).then(() => {
						cwalert.success(t('Role set has been saved'));
					});
				}
			},
			deleteItem (id) {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Remove role set'),
					message: t('Are you sure you want to delete this role set?')
				}).done(() => {
					this.deleteRoleSet(id).then(() => {
						cwalert.success(t('Role set has been deleted'));
					});
				});
			}
		},
		template:
		`<div>
			<p v-translate class="treatment-role-sets__label">Add a role set</p>
			<div class="treatment-role-sets__select">
				<v-select
					@input="add"
					:options="filteredList"
					:placeholder="selectPlaceholder"
					:value="selected"
					:disabled="!canEdit"
					label="name"
				>
					<template #no-options>
						{{$t('selectInput.noResults')}}
					</template>
				</v-select>
			</div>
			<div class="table__container">
				<table v-if="roleSets.length" class="table">
					<thead>
						<tr>
							<th v-translate>Name</th>
							<th v-translate>Description</th>
							<th v-if="canEdit" v-translate>Delete</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in roleSets">
							<td>
								<p class="table-cell-label">Name</p>
								{{ item.name }}
							</td>
							<td>
								<p class="table-cell-label">Description</p>
								{{ item.description }}
							</td>
							<td v-if="canEdit">
								<p class="table-cell-label">Delete</p>
								<button
									@click.stop="deleteItem(item.id)"
									:title="removeLabel"
									class="icon icon--delete treatment-role-sets__delete-button"
								></button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>`
	})
});
