import t from 'translate';
import appBar from 'app-bar';
import store from 'store';

const card = {
	title: t('Consent preview'),
	name: 'consent-preview',
	url: 'consent-template/:consentId/preview',

	ctrl: ({ populate }) => {
		store.subscribe((mutation) => {
			if (mutation.type === 'consent/set') {
				appBar.clear(card.name);
			}
		});
		populate();
	}
};

export { card as previewConsent };
