import $ from 'jquery';
import TileView from 'tile-view';
import can from 'acl-can';
import repository from 'repository';
import datamodel from 'components/old-components/datamodel/datamodel';
import t from 'translate';
import { removeAttributesConfig } from '../../shared';

export default TileView.extend({
	acl: [{
		checkpoint: 'system.settings.administratorattributes',
		op: 'OPEN'
	}],
	className: '',
	title: t('system-manager.AdministratorAttributes'),

	init () {
		this.attributesDefinition = repository.getClinicianAttributeDefinitions();
	},

	onRender () {
		this.attributesDefinition
			.done(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	loaded () {
		const attributesList = new datamodel.grid.Table({
			collection: this.attributesDefinition,
			readonly: !can.edit('system.settings.administratorattributes'),
			columns: {
				administratorAttributeMetaLabel: {
					label: t('system-manager.respondentAttributeMetaLabel'),
					rendererFactory: new datamodel.widgets.TextMediumFactory({
						required: true
					})
				},
				administratorAttributeMetaReadonly: {
					className: 'attributes-list__readonly',
					label: t('system-manager.respondentAttributeMetaReadonly'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				administratorAttributeMetaRequired: {
					className: 'attributes-list__required',
					label: t('system-manager.respondentAttributeMetaRequired'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},

				administratorAttributeMetaType: {
					label: t('system-manager.respondentAttributeMetaType'),
					rendererFactory: new datamodel.widgets.DropdownFactory({
						displayValue: new datamodel.displayvalue.DisplayValue({
							CHECKBOX: t('Checkbox'),
							STRING: t('String'),
							TEXTAREA: t('Textarea'),
							NUMERIC: t('Numeric'),
							DATE: t('Date'),
							DROPDOWN: t('Dropdown')
						})
					})
				},

				administratorAttributeMetaOrder: {
					label: t('system-manager.respondentAttributeMetaOrder'),
					rendererFactory: new datamodel.widgets.TextSmallFactory()
				},
				administratorAttributeMetaParameters: {
					label: t('system-manager.respondentAttributeMetaParameters'),
					rendererFactory: new datamodel.widgets.TextMediumFactory()
				},
				administratorAttributeMetaShow: {
					className: 'attributes-list__metashow',
					label: t('system-manager.respondentAttributeMetaShow'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				}
			},
			actions: {
				remove: {
					disabled: !can['delete']('system.settings.administratorattributes'),
					confirmationConfig: removeAttributesConfig()
				}
			},
			container: this.el
		});
		const $table = $(attributesList.getTableNode());
		$table.addClass('wide-table');
	}
});
