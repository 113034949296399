import appBar from 'app-bar';
import store from 'store';
import t from 'translate';
import { when } from 'jquery';

const card = {
	name: 'my-treatments',
	url: 'my-treatments',
	title: t('My assigned treatments'),

	ctrl: () => {
		appBar.addCardLink({
			card: 'add-treatment',
			icon: 'add',
			sourceCard: card.name,
			title: t('Add a treatment')
		});

		return when(
			store.dispatch('treatmentTypes/initForCurrentClinician'),
			store.dispatch('respondentGroups/initForCurrentClinician')
		);
	}
};

export { card as myTreatments };
