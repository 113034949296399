import t from 'service/lang/translate';
import datetime from 'service/datetime/datetime';
import appContext from 'service/app-context';
import $respondentLink from '../util/respondent-link/respondent-link';
import store from 'store';

export default () => ({
	deadline: {
		label: t('Deadline'),
		css: 'assessment-list__deadline',
		render: (value, { item }) => datetime(item.get('assessmentInstanceEndDate'))
			.setTimezone(appContext.get('user').get('timezoneName'))
			.toMoment()
			.format(t('date.formats.dateTime'))

	},
	treatment: {
		label: t('Treatment'),
		css: 'assessment-list__treatment',
		key: 'treatmentName'
	},
	respondent: {
		label: t('Respondent'),
		css: 'assessment-list__respondent',
		render: (value, { item, td }) => {
			$respondentLink(item.attributes).appendTo(td);
		}
	},
	title: {
		label: t('Title'),
		css: 'assessment-list__title',
		key: 'assessmentTitle'
	},
	delivery: {
		label: t('Delivery time'),
		css: 'assessment-list__delivery',
		render: (value, { item }) => datetime(item.get('assessmentInstanceDeliveredDate'))
			.setTimezone(store.getters['userTimezone'])
			.toMoment()
			.format(t('date.formats.dateTime'))

	}
});
