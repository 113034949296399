const roleSet = 'backend-commons/role-set/';
const planInstanceRoleSet = `backend-commons/workflow/rest/workflow`;

const urlRoot = 'backend-commons/workflowinstance/rest/workflowinstance/';

export default {
	planInstance: {
		url () {
			const suffix = this.isNew() ? 'initialize/' : this.getId();
			return urlRoot + suffix;
		}
	},
	terminate: {
		url (id) {
			return `${urlRoot}terminate/${id || this.getId()}`;
		}
	},
	pauseOrResume: {
		url (id) {
			return `${urlRoot}mode/${id || this.getId()}`;
		}
	},
	allPlanInstances: {
		url: `${urlRoot}all`
	},
	byRespondentId: {
		url: (respondentId) => `${urlRoot}by-respondent/${respondentId}`
	},
	byTreatmentId: {
		url: (treatmentId) => `${urlRoot}all?treatment=${treatmentId}`
	},

	planInstanceRoleSet: ({ id, planId }) => `${planInstanceRoleSet}/${planId}/role-set/${id}`,

	planInstanceRoleSets: (planInstance) => `${planInstanceRoleSet}/${planInstance}/role-set/`,

	roleSets: roleSet,

	single: (id) => `${urlRoot}${id}`
};
