import $ from 'jquery';
import ShortcutItem from './shortcut-item';
import executeIfFunction from 'execute-if-function';
import { forEach } from 'lodash';

/**
 * @class ShortcutBuilder
 * Builder for sets of shortcut items
 * @author Waldemar
 * @param {shortcutGroup[]} groups groups configuration
 *
 * @namespace shortcutGroup
 * @property {string} groupTitle
 * @property {shorcut[]} shortcuts shortcuts configuration
 *
 * @namespace shortcut
 * @property {String} title shortcut title
 * @property {String|Object} card card name | card configuration
 * @property {String} icon icon name
 */
const ShortcutBuilder = function (groups) {
	const classPrefix = 'shortcut__';
	const groupContainerClass = `${classPrefix}group-container`;
	const singleGroupContainerClass = `${groupContainerClass}--single`;
	const groupContainerTitleClass = `${groupContainerClass}-title`;

	this.groups = [];
	this.$groups = [];

	const Group = function (title) {
		this.title = executeIfFunction(title);
		this.children = [];
		this.$el = $();

		this.addShortcut = (shortcut) => {
			this.children.push(shortcut);
			return this;
		};

		this.addShortcuts = (shortcuts) => {
			forEach(shortcuts, (shortcut) => {
				this.addShortcut(shortcut);
			});
			return this;
		};

		this.render = () => {
			if (!this.children.length) {
				return;
			}
			const $title = $('<h1 />').addClass(groupContainerTitleClass).html(this.title);
			const $shortcuts = this.renderChildren();

			this.$el = $('<section />').addClass(groupContainerClass);
			this.title.length > 0 && this.$el.append($title);
			this.$el.append($shortcuts).addClass(`children-count-${$shortcuts.length}`);
		};

		this.renderChildren = () => {
			const $shortcuts = [];
			forEach(this.children, (child) => {
				const shortcut = new ShortcutItem(child);
				shortcut.accessible && $shortcuts.push(shortcut.$el);
			});
			return $shortcuts;
		};

		return this;
	};

	this.setGroup = (title) => {
		const group = new Group(title);
		this.groups.push(group);
		return group;
	};

	forEach(groups, (group) => {
		const currentGroup = this.setGroup(group.groupTitle);
		currentGroup.addShortcuts(group.shortcuts).render();
		this.$groups.push(currentGroup.$el);
	});

	this.$groups.length === 1 && this.$groups[0].addClass(singleGroupContainerClass);

	return this.$groups;
};

export default (groups) => new ShortcutBuilder(groups);
