var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.updating
      ? _c("div", {
          directives: [{ name: "loader-spinner", rawName: "v-loader-spinner" }],
          staticClass: "data-integration--loading"
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("checkbox-input", {
          attrs: {
            "class-prefix": "data-integration",
            "input-class": "checkbox-input",
            "text-class": "checkbox-text",
            disabled: !_vm.canEdit,
            label: _vm.t("dataIntegration.codes.fields.enableCode"),
            translate: false
          },
          model: {
            value: _vm.item.enabled,
            callback: function($$v) {
              _vm.$set(_vm.item, "enabled", $$v)
            },
            expression: "item.enabled"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            "class-prefix": "data-integration",
            label: "dataIntegration.codes.fields.friendly",
            disabled: !_vm.canEdit,
            invalid: _vm.invalid({ field: "name" }),
            mandatory: true,
            required: true
          },
          model: {
            value: _vm.item.name,
            callback: function($$v) {
              _vm.$set(_vm.item, "name", $$v)
            },
            expression: "item.name"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            "class-prefix": "data-integration",
            label: _vm.t("dataIntegration.codes.fields.code"),
            disabled: !_vm.canEdit,
            invalid: _vm.invalid({ field: "code" }),
            mandatory: true,
            required: true
          },
          model: {
            value: _vm.item.code,
            callback: function($$v) {
              _vm.$set(_vm.item, "code", $$v)
            },
            expression: "item.code"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "container-class": "select-container",
            "class-prefix": "data-integration",
            label: "dataIntegration.codes.fields.type",
            disabled: !_vm.canEdit,
            invalid: _vm.invalid({ field: "codeType", object: true }),
            mandatory: true,
            options: _vm.codeTypes,
            searchable: false
          },
          model: {
            value: _vm.codeTypeOption,
            callback: function($$v) {
              _vm.codeTypeOption = $$v
            },
            expression: "codeTypeOption"
          }
        }),
        _vm._v(" "),
        _c("select-input", {
          attrs: {
            "container-class": "select-container",
            "class-prefix": "data-integration",
            label: "dataIntegration.codes.fields.enterprise",
            placeholder: _vm.t("dataIntegration.enterprise.actions.select"),
            disabled: !_vm.canEdit,
            options: _vm.enterprises,
            searchable: false
          },
          model: {
            value: _vm.enterpriseOption,
            callback: function($$v) {
              _vm.enterpriseOption = $$v
            },
            expression: "enterpriseOption"
          }
        }),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "data-integration__save-container" },
          [
            _c("submit-button", {
              attrs: {
                label: _vm.t("Update"),
                "can-submit": _vm.allowUpdate,
                "class-prefix": "data-integration"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }