import $ from 'jquery';
import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import can from 'acl-can';
import Table from 'components/table/table';
import icon from 'service/icon';
import elementDialog from '../../dialogs/edit-content-package-element';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';

export default TileView.extend({
	title: t('Information package elements'),
	acl: [{
		checkpoint: 'system.settings.informationpackages',
		op: 'read'
	}],

	cardData: () => ['contentPackage'],
	tileData: ({ tile }) => ({
		elements: repository.getContentPackageElements(tile.contentPackage.getId())
	}),

	loaded: ({ data, tile }) => {
		// long translate keys placeholder, consider making proper key based translation for CP
		const messages = {
			confirm: 'Are you sure you want to delete this information package element?',
			success: 'Information package element has been deleted',
			error: 'Unable to delete information package element'
		};

		tile.table = new Table({
			className: 'cwtable--hyphenated',
			parent: tile.el,
			emptyListMessage: t('No information package elements'),
			collection: tile.elements,
			rowsClickable: can.edit('system.settings.informationpackages'),
			click: (item) => {
				elementDialog(data.contentPackage, item, tile.config().limit);
			},
			columns: {
				name: {
					label: t('Alias'),
					key: 'alias'
				},
				type: {
					label: t('Type'),
					key: 'type',
					render () {
						$(this.td).html(this.item.getTypeLabel());
					}
				},
				optional: {
					label: t('Is optional'),
					key: 'optional',
					render () {
						const $input = $('<input type="checkbox" />').prop({
							checked: this.item.get('optional'),
							disabled: true
						});
						$(this.td).html($input);
					}
				},
				order: {
					label: t('Order'),
					key: 'order'
				},
				optionDelete: {
					label: t('Delete'),
					key: 'optionDelete',
					render () {
						if (!can.edit('system.settings.informationpackages')) {
							return;
						}

						const $delete = $('<button class="icon-button__button"></button>')
							.html(icon('delete', {
								title: t('Remove a package element'),
								small: true
							}));
						$(this.td).html($delete);

						$delete.click(() => {
							confirmation({
								icon: 'delete',
								warning: true,
								title: t('Remove a package element'),
								message: t(messages.confirm)
							}).done(() => {
								this.item.destroy().then(() => {
									cwalert.success(t(messages.success));
								}, () => {
									cwalert.error(t(messages.error));
								});
							});
							return false;
						});

					}
				}
			}
		});
	}
});

