import {
	assign,
	find,
	forEach,
	get,
	includes,
	map,
	reduce,
	reject,
	split
} from 'lodash';
import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';
import Treatment from 'repo/treatments/entity';
import store from 'store';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import datetime from 'service/datetime/datetime';
import redirectOnSave from 'modules/general/shared/redirect-on-save';

const adjustTz = ({ date, respondent, timezone }) => datetime(date)
	.setTimezone(timezone)
	.toUtcFromTimezone(respondent.respondentTimezoneName)
	.toMoment()
	.toISOString();

export default TileView.extend({
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'ADD'
	}],
	title: t('Add a treatment'),

	actions: ['respondent/init', 'treatmentTypes/initForCurrentClinician'],

	loaded: ({ tile }) => {
		assign(tile, {
			respondent: store.getters.respondent,
			treatment: new Treatment({ isMainRespondent: true }),
			treatmentTypes: store.getters['treatmentTypes/sortedByName'],
			limit: tile.config().limit || 10
		});

		tile.setData({ tile });
		tile.attachListeners({ tile });

		tile.renderTile({ tile });
	},

	attachListeners: ({ tile }) => {
		tile.listenTo(tile.treatment, 'change:treatmentType', (model, treatmentTypeId) => {
			tile.form.close();

			const treatmentType = find(tile.treatmentTypes, { id: treatmentTypeId });
			tile.treatment.set('name', treatmentType.name);
			tile.renderTile({ tile, treatmentTypeId });
		});

		tile.listenTo(tile.treatment, 'change:respondent', (model, respondentId) => {
			tile.setData({ tile, respondentId });
		});
	},

	setData ({ tile }) {
		const respondentId = get(tile, 'respondent.respondentId');

		if (!respondentId) {
			return;
		}

		tile.treatment.set('respondent', respondentId);
		assign(tile, { treatments: repository.mainRespondentTreatments(respondentId) });
	},

	renderTile: ({ tile, treatmentTypeId } = {}) => {
		tile.setLoading();

		tile.timezone = tile.respondent ?
			tile.respondent.respondentTimezoneName :
			'UTC';

		if (treatmentTypeId) {
			store.dispatch('treatmentTypes/initAttributes', { treatmentTypeId }).then(() => {
				tile.treatmentTypeAttributes = store.getters['treatmentTypes/attributes'];
				tile.renderForm({ tile });
			});

		} else {
			tile.renderForm({ tile });
		}
	},

	fields: (tile) => [!tile.respondent && {
		key: 'respondent',
		type: 'search',
		label: t('Respondent'),
		mandatory: true,
		limit: tile.limit,
		provideResults: (search) => repository.searchRespondents({
			search,
			respondentAccountEnabled: true,
			start: 0,
			limit: tile.limit
		}),
		placeholder: t('Type respondent name'),
		itemLabel: (model) => respondentName(model.toJSON()),
		onSelectItem: (model) => {
			tile.selectedRespondent = model;
		},
		onClearItem: () => {
			tile.selectedRespondent = null;
		},

		customize: (view) => {
			if (tile.selectedRespondent) {
				view.showItem(tile.selectedRespondent);

			} else if (store.getters.respondent) {
				view.showItem(store.state.respondent.model);
			}
		}
	}, {
		key: 'treatmentType',
		label: t('Treatment type'),
		description: t('Select a treatment type'),
		mandatory: true,
		type: 'select',
		values: tile.treatmentTypes,
		customize: (view) => {
			tile.customizeTreatmentTypeField({ tile, view });
		},
		// backend returns treatmentTypeId as number while form returns it as string.
		// To avoid reloading the tile after saving the model, treatmentTypeId needs to be
		// stored as a number
		getVal: (treatmentTypeId) => +treatmentTypeId
	}, {
		key: 'name',
		label: t('Treatment name'),
		mandatory: true,
		type: 'text'
	}, {
		key: 'administrators',
		type: 'search',
		label: t('Assigned clinician'),
		limit: tile.limit,
		provideResults: (search) => repository.searchClinicians({
			search,
			start: 0,
			limit: tile.limit
		}),
		placeholder: t('Type clinician name'),
		itemLabel: (model) => clinicianName(model.toJSON()),
		onSelectItem: (model) => {
			tile.selectedClinician = model;
		},
		onClearItem: () => {
			tile.selectedClinician = null;
		},

		customize: (view) => {
			tile.selectedClinician && view.showItem(tile.selectedClinician);
		}
	}, {
		key: 'startAt',
		label: t('Start date'),
		type: 'datetime',
		timezone: tile.timezone,
		hint: t('When left empty, start date will equal creation date')
	}, {
		key: 'endAt',
		label: t('End date'),
		timezone: tile.timezone,
		type: 'datetime'
	}],

	renderForm: ({ tile }) => {
		tile.setLoaded();
		tile.form = null;
		let keysNotSubmitted = [];

		const fields = tile.fields(tile);

		if (tile.treatmentTypeAttributes) {
			keysNotSubmitted = [];
			forEach(tile.treatmentTypeAttributes, (attribute) => {
				if (!attribute.isVisible) {
					return;
				}
				const field = tile.getAttributeField(attribute);
				keysNotSubmitted.push(field.key);
				fields.push(field);
			});
		}

		tile.form = formView({
			name: 'add-treatment',
			model: tile.treatment,
			listenTo: ['submit'],
			successMessage: t('Successfully created treatment'),
			errorMessage: t('Error creating treatment'),
			loader: true,
			keysNotSubmitted,
			beforeSave: () => {
				tile.stopListening();

				if (get(
					tile.selectedRespondent,
					'attributes.respondentTimezoneName',
					tile.timezone
				) !== tile.timezone) {
					if (tile.treatment.get('startAt')) {
						tile.treatment.set({
							startAt: adjustTz({
								date: tile.treatment.get('startAt'),
								respondent: tile.selectedRespondent.toJSON(),
								timezone: tile.timezone
							})
						}, {
							silent: true
						});
					}

					if (tile.treatment.get('endAt')) {
						tile.treatment.set({
							endAt: adjustTz({
								date: tile.treatment.get('endAt'),
								respondent: tile.selectedRespondent.toJSON(),
								timezone: tile.timezone
							})
						}, {
							silent: true
						});
					}
				}

				+tile.treatment.get('administrators') && tile.treatment.set('administrators', [{
					administratorId: +tile.treatment.get('administrators')
				}]);
			},
			onAfterSave () {
				const values = this.keyCache;
				const treatmentId = tile.treatment.getId();
				const respondentId = tile.treatment.get('respondent').id;

				return store.dispatch('treatmentTypes/saveAttributes', { treatmentId, values })
					.then(() => {
						redirectOnSave({
							config: get(tile.config(), 'redirectOnSave', {}),
							fallback: [
								'treatment',
								{ respondentId, treatmentId }
							],
							otherwise: () => {
								tile.form.disableSubmit();

								this.model.on('change', () => {
									tile.form.enableSubmit();
								});
							}
						});
					});
			},
			fields,
			buttons: [{
				caption: t('Add'),
				type: 'submit',
				name: 'add-treatment-submit',
				role: 'submit'
			}]
		});
		tile.$el.html(tile.form.$el);
	},

	getAttributeField: (attribute) => {
		const field = {
			STRING: { type: 'text' },
			TEXTAREA: { type: 'textarea' },
			CHECKBOX: { type: 'checkbox' },
			NUMERIC: { type: 'number' },
			DATE: { type: 'date' },
			DROPDOWN: {
				type: 'select',
				description: t('Select a value'),
				values: () => split(attribute.parameters, /;/)
			}
		}[attribute.type];

		return assign(field, {
			key: attribute.id,
			label: attribute.label,
			mandatory: attribute.isRequired,
			readonly: attribute.isReadonly
		});
	},

	customizeTreatmentTypeField: ({ tile, view }) => {
		if (!tile.treatment.get('treatmentType') || !tile.treatments) {
			return;
		}
		const treatmentType = +tile.treatment.get('treatmentType');
		const currentTypes = reject(tile.treatments.toJSON(), { id: tile.treatment.getId() });
		const currentTypeIds = map(currentTypes, 'id');

		if (includes(currentTypeIds, treatmentType)) {
			const siblingCount = reduce(
				currentTypeIds,
				(memo, typeId) => +tile.treatment.get('treatmentType') === typeId ?
					memo + 1 :
					memo,
				0
			);

			const hint = siblingCount === 1 ?
				t('Please note, that there is already 1 active treatment of this type') :
				t(`Please note, that there are already {number} active treatments of this type`, {
					number: siblingCount
				});

			view.model.set('hint', hint);

		} else {
			view.model.unset('hint');
		}
	}
});
