import cwalert from 'cwalert';
import Backbone from 'backbone';
import cardUrl from 'cwcardurl';
import appBar from 'app-bar';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import can from 'acl-can';
import planeditorSupport from '../shared/planeditor-support';
import { isUndefined, replace } from 'lodash';
import { SYSTEM } from 'service/acl/checkpoints.json';
import store from 'store';

let tab = null;

const card = {
	title: t('Plan'),
	name: 'plan',
	url: ['plan/:planId'],

	ctrl: async ({ cardData, close, setLoading, setLoaded }, { planId }) => {
		await store.dispatch('plan/init', { planId });

		const planModel = store.state.plan.model;

		cardData.set({ plan: planModel });

		if (can.read(SYSTEM.PLANS.PLANS) && planeditorSupport()) {
			appBar.addButton({
				sourceCard: card.name,
				title: t('Edit plan'),
				icon: 'edit',
				click: () => {
					const url = replace(cardUrl.buildUrl(Backbone.config().planEditorAppUrl, {
						id: planId
					}), /^#/, '');
					tab = window.open(url, 'plan-editor');
					tab.focus();
				}
			});
		}

		appBar.addButton({
			sourceCard: card.name,
			title: t('Plan simulator'),
			icon: 'play',
			click: () => {
				if (tab) {
					tab.postMessage('', tab.origin);
				}
				cardUrl.openCard('plan-simulator', { planId });
			}
		});

		if (can.edit(SYSTEM.PLANS.PLANS)) {
			appBar.addButton({
				sourceCard: card.name,
				title: t('Copy plan'),
				icon: 'copy',
				click: () => {
					setLoading();

					planModel.copy().then((data) => {
						cardUrl.openCard(card.name, {
							planId: data.details.workflowId
						});
						setLoaded();
					});
				}
			});
		}

		if (can.delete(SYSTEM.PLANS.PLANS)) {
			appBar.addButton({
				sourceCard: card.name,
				title: t('Delete plan'),
				icon: 'delete',
				click: async () => {
					try {
						await confirmation({
							icon: 'delete',
							warning: true,
							title: t('Delete plan'),
							message: t('system-manager.DeleteWorkflowPrompt')
						});
						setLoading();
						await store.dispatch('plan/destroy', { planId });
						cwalert.success(t('Assessment plan successfully deleted'));
						close();
					} catch (err) {
						if (!isUndefined(err)) {
							cwalert.error(t('Assessment plan cant be deleted'));
						}
					} finally {
						setLoaded();
					}
				}
			});
		}
	}
};

export { card as Plan };
