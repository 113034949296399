import $ from 'jquery';
import TileView from 'tile-view';
import t from 'translate';
import can from 'acl-can';
import repository from 'repository';
import datamodel from 'components/old-components/datamodel/datamodel';
import { removeAttributesConfig } from '../../shared';

export default TileView.extend({
	title: t('system-manager.RespondentAttributes'),
	acl: [{
		checkpoint: 'system.settings.respondentattributes',
		op: 'OPEN'
	}],

	init () {
		this.attributes = repository.getRespondentAttributeDefinitions();
	},

	onRender () {
		this.load(this.attributes);
	},

	loaded () {

		const attributesList = new datamodel.grid.Table({
			collection: this.attributes,
			readonly: !can.edit('system.settings.respondentattributes'),
			columns: {
				respondentAttributeMetaLabel: {
					label: t('system-manager.respondentAttributeMetaLabel'),
					rendererFactory: new datamodel.widgets.TextMediumFactory({
						required: true
					})
				},
				respondentAttributeMetaReadonly: {
					className: 'attributes-list__readonly',
					label: t('system-manager.respondentAttributeMetaReadonly'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				respondentAttributeMetaRequired: {
					className: 'attributes-list__required',
					label: t('system-manager.respondentAttributeMetaRequired'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				respondentAttributeMetaType: {
					label: t('system-manager.respondentAttributeMetaType'),
					rendererFactory: new datamodel.widgets.DropdownFactory({
						displayValue: new datamodel.displayvalue.DisplayValue({
							CHECKBOX: t('custom-attributes.types.Checkbox'),
							STRING: t('custom-attributes.types.String'),
							TEXTAREA: t('custom-attributes.types.Textarea'),
							NUMERIC: t('custom-attributes.types.Numeric'),
							DATE: t('custom-attributes.types.Date'),
							DROPDOWN: t('custom-attributes.types.Dropdown')
						})
					})
				},
				respondentAttributeMetaOrder: {
					label: t('system-manager.respondentAttributeMetaOrder'),
					rendererFactory: new datamodel.widgets.TextSmallFactory()
				},
				respondentAttributeMetaParameters: {
					label: t('system-manager.respondentAttributeMetaParameters'),
					rendererFactory: new datamodel.widgets.TextMediumFactory()
				},
				respondentAttributeMetaShow: {
					className: 'attributes-list__metashow',
					label: t('system-manager.respondentAttributeMetaShow'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				respondentAttributeMetaKeepHistory: {
					className: 'attributes-list__keephistory',
					label: t('system-manager.respondentAttributeMetaKeepHistory'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				}
			},
			actions: {
				remove: {
					disabled: !can['delete']('system.settings.respondentattributes'),
					confirmationConfig: removeAttributesConfig()
				}
			},
			container: this.el
		});

		const $table = $(attributesList.getTableNode());
		$table.addClass('wide-table');
	}
});
