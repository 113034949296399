import { forEach, includes, toLower, toUpper } from 'lodash';
import { View } from 'backbone';
import $ from 'jquery';
import Table from 'components/table/table';
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

const DATA_FILTER = 'data-filter';

const className = (suffix = '') => [
	'workflow-simulator',
	'workflow-simulator-log',
	`workflow-simulator-log-${suffix}`
].join(' ');

export default View.extend({
	title: t('plans.simulator-log.tile-title'),
	acl: [{
		checkpoint: SYSTEM.PLANS.PLANS,
		op: READ
	}],

	getIcon (icon) {
		return $('<img />').attr({
			src: `images/assessmentplan/${toLower(icon)}.svg`,
			type: 'image/svg+xml'
		});
	},

	getEventType: (code) => +!includes([
		'Assessment-delivery',
		'Assessment-expired',
		'Consent-delivery',
		'package-completed',
		'Decision-answered-positive',
		'Decision-answered-negative',
		'Error'
	], code),

	render () {
		const tile = this;
		tile.$el.empty();

		const filters = [
			'start', 'end', 'end-branch', 'terminate', 'split', 'merge', 'repeat',
			'assessment', 'assessment-delivery', 'assessment-expired', 'evaluate-status',
			'evaluate-data', 'evaluate-data-positive', 'evaluate-data-negative', 'set-variable',
			'modify-respondent', 'stage', 'wait', 'adjust',
			'notification', 'decision', 'decision-answered-positive', 'decision-answered-negative',
			'cleanup', 'package-enable', 'package-disable', 'package-completed',
			'treatment-status', 'consent', 'consent-delivery', 'error'
		];

		tile.menu = $('<div>').addClass('workflow-simulator workflow-simulator-log ' +
			'workflow-simulator-log-menu');

		forEach(filters, (filter) => {
			const button = $('<button>').attr({
				'title': toUpper(t(filter)),
				'tabindex': 0,
				'data-filter': toUpper(filter)
			}).addClass('active')
				.html(tile.getIcon(filter)).on('click', function () {
					$(this).toggleClass('active');
					$(`tr[${DATA_FILTER}=${$(this).attr(DATA_FILTER)}]`, tile.el)
						.toggle($(this).hasClass('active'));
				});
			tile.menu.append(button);
		});

		tile.$el.append(tile.menu);
		tile.$el.append('<br>');

		tile.logentries = tile.cardContext().get('simulator-log');

		tile.table = new Table({
			parent: tile.el,
			collection: tile.logentries,
			emptyListMessage: t('Simulator not started'),
			refreshInterval: 25,

			columns: {
				icon: {
					label: t('Internal event'),
					render (dummyVal, { item, $td, $tr }) {
						const code = item.get('code');
						$tr.attr(DATA_FILTER, toUpper(`${code}`));

						// Check for menu button visibility
						const visible = $(
							`button[${DATA_FILTER}="${toUpper(code)}"]`
						).hasClass('active');
						$tr.toggle(visible);

						if (!tile.getEventType(code)) {
							return;
						}
						$td.addClass(className('icon')).append(tile.getIcon(code));
					}
				},
				description: {
					label: t('Description'),
					render (dummyVal, { item, $td }) {
						if (!tile.getEventType(item.get('code'))) {
							return;
						}
						$td.addClass(className('description')).html(item.get('description'));
					}
				},
				timestamp: {
					label: t('Simulator time'),
					render (dummyVal, { $td, item }) {
						$td.addClass(className('timestamp')).html(item.getTimestamp(true));
					}
				},
				icon2: {
					label: t('External event'),
					render (dummyVal, { $td, item }) {
						if (tile.getEventType(item.get('code'))) {
							return;
						}
						$td.addClass(className('icon')).html(tile.getIcon(item.get('code')));
					}
				},
				desc2: {
					label: t('Description'),
					render (dummyVal, { $td, item }) {
						if (tile.getEventType(item.get('code'))) {
							return;
						}
						$td.addClass(className('description')).html(item.get('description'));
					}
				}
			}
		});
	}

});
