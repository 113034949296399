// class mapping for file icons

export default {
	archive: 'archive',
	audio: 'audio',
	blank: 'blank',
	categories: 'categories',
	doc: 'doc',
	docx: 'doc',
	gif: 'gif',
	image: 'image',
	jpg: 'jpg',
	jpeg: 'jpeg',
	pdf: 'pdf',
	png: 'png',
	ppt: 'ppt',
	pptx: 'ppt',
	txt: 'txt',
	xls: 'xls',
	xlsx: 'xls',
	video: 'video',
	default: 'blank'
};
