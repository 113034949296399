import t from 'translate';
import eventHandlers from '../../respondent-management/shared/event-handlers-mixin';
import store from 'store';

const card = {
	name: 'respondent-decisions',
	url: 'respondent/:respondentId/decisions',
	title: t('Respondent Decisions'),

	appEvents: {
		'respondent.delete respondent.lose-access': eventHandlers.closeCard
	},

	ctrl: ({ populate }) => {
		store.dispatch('treatment/resetId');
		populate();
	}
};

export { card as RespondentDecisions };
