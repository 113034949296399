import TileView from 'tile-view';
import { assign, forEach } from 'lodash';
import repository from 'repository';
import AttributeValue from 'repo/respondents/attribute-value';
import formView from 'components/form-view/form-view';
import can from 'acl-can';
import t from 'translate';
import store from 'store';

export default TileView.extend({
	title: t('respondent-management.respondent-attributes-title'),
	acl: [{
		checkpoint: 'administrator.respondents.attributes',
		op: 'READ'
	}],

	tileData: () => ({
		attributes: repository.getRespondentAttributeDefinitions(),
		attributeValues:
			repository.getRespondentAttributeValues(store.getters['respondent/respondentId'])
	}),

	loaded () {
		if (!this.attributes || !this.attributes.getVisible().length) {
			this.disable();
			return;
		}
		this.enable();
		const respAttrs = this.attributeValues;
		const isAttributesReadonly = !can.edit('administrator.respondents.attributes');
		const formViews = [];

		// create new formView instance for each custom attribute
		forEach(this.attributes.getVisible(), (attribute) => {

			let attributeValue = respAttrs.find((attributeVal) => {
				const attributeMetaId =
					attributeVal.get('fk_respondent_attribute_respondent_attribute_meta_id');
				return attributeMetaId === attribute.get('respondentAttributeMetaId');
			});

			const input = this.getInputByAttributeType(attribute);

			attributeValue = attributeValue || new AttributeValue({
				// eslint-disable-next-line camelcase
				fk_respondent_attribute_respondent_id: +this.cardContext().get('respondentId'),
				// eslint-disable-next-line camelcase
				fk_respondent_attribute_respondent_attribute_meta_id:
					+attribute.get('respondentAttributeMetaId'),
				fkRespondentAttributeRespondentAttributeMeta: attribute.toJSON()
			});

			const cfg = {
				classNames: ['no-fieldset-margin'],
				model: attributeValue,
				hideMandatory: true,
				fields: [{
					key: 'respondentAttributeValue',
					label: attribute.get('respondentAttributeMetaLabel'),
					mandatory:
					input.type !== 'checkbox' &&
					attribute.get('respondentAttributeMetaRequired'),
					readonly:
					isAttributesReadonly ||
					attribute.get('respondentAttributeMetaReadonly'),
					type: input.type,
					description: input.description
				}]
			};

			if (input.type === 'text' || input.type === 'textarea') {
				cfg.max = 255; // current DB limit
			}

			assign(cfg.fields[0], input.config);
			formViews.push(formView(cfg).$el);
		}, this);

		this.$el.append(formViews);
	},

	getInputByAttributeType (attribute) {
		return {
			STRING: {
				type: 'text'
			},
			TEXTAREA: {
				type: 'textarea',
				config: {
					rows: attribute.get('respondentAttributeMetaParameters')
				}
			},
			CHECKBOX: {
				type: 'checkbox'
			},
			NUMERIC: {
				type: 'number'
			},
			DATE: {
				type: 'date'
			},
			DROPDOWN: {
				type: 'select',
				description: t('Select a value'),
				config: {
					values (attrVal) {
						const meta = attrVal.get('fkRespondentAttributeRespondentAttributeMeta');
						const vals = meta.respondentAttributeMetaParameters.split(/;/);
						return vals;
					}
				}
			}
		}[attribute.get('respondentAttributeMetaType')];
	}
});

