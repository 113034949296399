import $ from 'jquery';
import { noop } from 'lodash';
import { compile } from 'handlebars';
import TileView from 'tile-view';
import t from 'translate';
import addAttributeDialog from '../../dialogs/add-attribute';
import datamodel from 'components/old-components/datamodel/datamodel';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('Attributes'),
	acl: {
		checkpoint: SYSTEM.SETTINGS.TREATMENT_TYPES,
		op: READ
	},

	appEvents: {
		'treatment-type-attribute.create' () {
			this.attributesList.refresh();
		}
	},

	events: {
		'click .tt-attrs__add-button': 'onAddButtonClick'
	},

	template: compile(`
		<div class="tt-attrs__add-button-container flat-button__buttons-container">
			{{#ifCan 'add' 'system.settings.treatment-types'}}
			<button class="tt-attrs__add-button flat-button__button--submit">
				{{t 'Add an attribute'}}
			</button>
			{{/ifCan}}
		</div>
		<div class="tt-attrs__list-container"></div>
	`),

	init () {
		this.treatmentTypeAttributes = this.cardContext().get('attributes');
	},

	onRender () {
		this.$el.html(this.template());
		// eslint-disable-next-line lodash/prefer-lodash-method
		this.$listContainer = this.$el.find('tt-attrs__list-container');

		$.when(this.treatmentTypeAttributes)
			.done(this.onLoaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	onLoaded () {
		this.renderList();
	},

	onAddButtonClick () {
		addAttributeDialog({
			treatmentTypeId: this.cardContext().get('treatmentTypeId')
		});
		return false;
	},

	renderList () {
		this.attributesList = new datamodel.grid.Table({
			collection: this.treatmentTypeAttributes,
			readonly: !can.edit('system.settings.treatment-types'),
			columns: {
				label: {
					label: t('Label'),
					rendererFactory: new datamodel.widgets.TextMediumFactory({
						required: true
					})
				},
				isRequired: {
					label: t('Required'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				isReadonly: {
					label: t('Readonly'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				isVisible: {
					label: t('Visible'),
					rendererFactory: new datamodel.widgets.CheckboxFactory()
				},
				type: {
					label: t('Type'),
					rendererFactory: new datamodel.widgets.DropdownFactory({
						displayValue: new datamodel.displayvalue.DisplayValue({
							CHECKBOX: t('Checkbox'),
							STRING: t('String'),
							TEXTAREA: t('Textarea'),
							NUMERIC: t('Numeric'),
							DATE: t('Date'),
							DROPDOWN: t('Dropdown')
						})
					})
				},
				order: {
					label: t('Order'),
					rendererFactory: new datamodel.widgets.TextSmallFactory()
				},
				parameters: {
					label: t('Parameters'),
					rendererFactory: new datamodel.widgets.TextMediumFactory()
				}
			},

			actions: {
				remove: {
					disabled: !can.add('system.settings.treatment-types')
				}
			},

			// eslint-disable-next-line lodash/prefer-lodash-method
			container: this.$el.find('.tt-attrs__list-container')[0],
			onLoad: noop
		});

		const $table = $(this.attributesList.getTableNode());
		$table.addClass('wide-table');

		return this;
	}
});
