import Backbone from 'backbone';

export default Backbone.Model.extend({
	idAttribute: 'id',
	initialize: function () {
		this.set(this.idAttribute, 1);
	},
	url: 'backend-commons/log/rest/log/statusType/all',

	getStatuses: function () {
		return new Backbone.Collection(this.get('status'));
	},

	getTypes: function () {
		return new Backbone.Collection(this.get('type'));
	}
});

