import { Collection, Model } from 'backbone';
import TileView from 'tile-view';
import $, { when } from 'jquery';
import { assign, forEach, keys, map, split } from 'lodash';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import Clinician from 'repo/clinicians/clinician';
import formView from 'components/form-view/form-view';
import t from 'translate';
import repository from 'repository';
import systemSettings from 'system-settings';
import can from 'acl-can';
import Assignment from 'components/assignment';
import sanitize from 'service/sanitize-string/sanitize-string';

export default TileView.extend({
	title: t('admin-portal.AddNewAdmin'),
	acl: [{
		checkpoint: 'system.administrators.administrators',
		op: 'CREATE'
	}],

	init () {
		assign(this, {
			clinician: new Clinician(),
			languages: repository.getEnabledLanguages(),
			timezones: repository.getTimezones(),
			attributeDefinitions: repository.getClinicianAttributeDefinitions(),
			roles: repository.getClinicianRoles(),
			roleRelations: new (Collection.extend({
				model: Model.extend({
					save (args, callbacks) {
						callbacks.success(this);
					}
				})
			}))()
		});

		this.listenTo(this.roleRelations, 'add remove reset', () => {
			if (!this.roleRelations.size()) {
				this.clinician.set('accessRoles', undefined);
			}

			this.clinician.set(
				'accessRoles',
				// eslint-disable-next-line lodash/prefer-invoke-map
				map(this.roleRelations.models, (role) => role.get('administratorRoleId'))
			);
		});
	},

	onRender () {
		when(this.languages, this.timezones, this.attributeDefinitions, this.roles)
			.done(() => this.loaded())
			.always(() => this.setLoaded());
	},

	loaded () {
		const card = this.card();

		const cfg = {
			name: 'edit-clinician',
			model: this.clinician,
			listenTo: ['submit'],
			autocomplete: false,
			beforeSave: () => {
				// Provide `attributes` property so backend can set custom
				// attributes. Data consistency +3!
				this.clinician.attributes.attributes = {};
				const keysList = keys(this.clinician.toJSON());
				forEach(keysList, (keyName) => {
					// eslint-disable-next-line no-useless-escape
					if (!/attr\:/.test(keyName)) {
						return;
					}
					const attrId = +split(keyName, ':')[1];
					this.clinician.attributes.attributes[attrId] = this.clinician.get(keyName);
				});
			},

			onAfterSave: () => {
				// Remove `attributes` property and remove custom
				// attributes from entity. Data consistency +8!
				if (this.clinician.attributes.hasOwnProperty('attributes')) {
					forEach(this.clinician.attributes.attributes, (attrVal, attrKey) => {
						this.clinician.unset(`attr:${attrKey}`);
					});
					delete this.clinician.attributes.attributes;
				}

				card.close();
				cardUrl.openCard('clinician-profile', {
					id: this.clinician.getId()
				});

				cwalert.success(t('clinician.account-creation.success'));
			},

			fieldsets: [{
				name: 'clinician',
				caption: t('respondent-list.PersonalData'),
				fields: [
					'administratorFirstName',
					'administratorLastName',
					'administratorUsername',
					'administratorPassword',
					'administratorLanguageId',
					'administratorPhone',
					'administratorEmail',
					'administratorTimezoneName',
					'administratorAccountEnabled',
					'administratorTestAccount'
				]
			}, {
				name: 'attributes',
				caption: t('Clinician attributes'),
				fields: []
			}, can.read('system.administrators.roles') && {
				name: 'roles',
				caption: t('Clinician roles'),
				fields: ['accessRoles']
			},
			{
				name: 'buttons',
				buttons: ['add', 'reset']
			}],

			fields: [{
				key: 'administratorFirstName',
				type: 'text'
			}, {
				key: 'administratorLastName',
				type: 'text'
			}, {
				key: 'administratorUsername',
				type: 'username',
				variant: 'clinician',
				hint: t('general-list.userNameHint'),
				mandatory: true
			}, {
				key: 'administratorPassword',
				type: 'password',
				mandatory: true
			}, {
				key: 'administratorLanguageId',
				type: 'select',
				collection: this.languages,
				labelField: 'languageLabel',
				label: t('Language'),
				keyField: 'languageId'
			}, {
				key: 'administratorPhone',
				type: 'text'
			}, {
				key: 'administratorEmail',
				type: 'email',
				mandatory: false
			}, {
				key: 'administratorTimezoneName',
				type: 'select',
				collection: this.timezones,
				labelField: 'timezoneName',
				keyField: 'timezoneName',
				label: t('Timezone')
			}, {
				key: 'administratorAccountEnabled',
				type: 'checkbox'
			}, {
				key: 'administratorTestAccount',
				type: 'checkbox'
			}, {
				key: 'accessRoles',
				type: 'paragraph',
				mandatory: systemSettings.getBoolean('MANDATORY_CLINICIAN_ROLES'),
				multiline: true,
				label: t('Assigned roles'),
				value: [],
				customize: (view, formView, formModel) => {
					/**
					 * TODO (2016-01-11): remove assignment, replace it a
					 * new form component designed for a proper multiselect.
					 */

					// do not render changes in `accessRoles` key
					view.model.stopListening(formModel);
					view.stopListening(view);
					view.stopListening(view.model);
					view.stopListening(formModel);
					view.setLoading();
					this.roles.done(view.setLoaded.bind(view));

					view.assignment = new Assignment({
						// eslint-disable-next-line lodash/prefer-lodash-method
						parent: view.$el.find('.form-view__paragraph-value')[0],
						mute: true,
						allItemsCollection: this.roles,
						relationCollection: this.roleRelations,
						relationAttributes: {
							administratorId: 0
						},
						itemPrimaryKey: 'administratorRoleId',
						selectorLabel: t('Select role to add'),
						selectorRender: (role) => role.administratorRoleName,
						addFilter: (role) => role.administratorRoleActive,
						multiple: systemSettings.getBoolean('CLINICIAN_MULTIPLE_ACCESS_ROLES'),
						columns: [{
							key: 'administratorRoleName',
							label: t('Role name'),
							render (roleName) {
								const role = this.item;
								const $td = $(this.td);
								let additionalInfo = '';

								if (role.administratorRoleActive === false) {
									$td.addClass('inactive-role__row-container');
									additionalInfo = `<small class="inactive-role__text">${
										t('role inactive')}</small>`;
								}
								$td.html(sanitize(roleName) + additionalInfo);
							}
						}],
						emptyListMessage: t('The clinician has no roles assigned')
					});
				}
			}],
			buttons: [{
				caption: t('Add'),
				name: 'add',
				type: 'submit'
			}, {
				caption: t('Reset'),
				name: 'reset',
				type: 'reset'
			}]
		};

		const attributes = this.attributeDefinitions.where({
			administratorAttributeMetaShow: true,
			administratorAttributeMetaReadonly: false
		});

		if (!attributes.length) {
			cfg.fieldsets.splice(1, 1);
		}

		forEach(attributes, (attribute) => {
			const keyName = `attr:${attribute.getId()}`;
			const input = this.getInputByAttributeType(attribute);

			const inputCfg = {
				key: keyName,
				label: attribute.getLabel(),
				type: input.type,
				description: input.description,
				readonly: attribute.isReadOnly(),
				mandatory: input.type !== 'checkbox' && attribute.isRequired()
			};

			if (input.type === 'textarea' || input.type === 'string') {
				inputCfg.max = 255; // Current DB limit
			}

			assign(inputCfg, input.config);

			if (attribute.getParameters().length) {
				inputCfg.values = split(attribute.getParameters(), /;/);
			}
			cfg.fields.push(inputCfg);
			cfg.fieldsets[1].fields.push(keyName);
		});

		this.form = formView(cfg);
		this.$el.append(this.form.$el);
	},

	getInputByAttributeType (attributeDefinition) {
		return {
			STRING: {
				type: 'text'
			},
			TEXTAREA: {
				type: 'textarea',
				config: {
					rows: attributeDefinition.getParameters()
				}
			},
			CHECKBOX: {
				type: 'checkbox'
			},
			NUMERIC: {
				type: 'number'
			},
			DATE: {
				type: 'date'
			},
			DROPDOWN: {
				type: 'select',
				description: t('Select a value')
			}
		}[attributeDefinition.getType()];
	}
});
