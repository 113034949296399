import MultipleEntities from '../shared/multiple-entities';
import ClinicianGroupRespondentGroup from './clinician-group-respondent-group';
import urls from './urls';
import { map } from 'lodash';

const ClinicianGroupRespondentGroups = MultipleEntities.extend({
	model: ClinicianGroupRespondentGroup,
	urls,
	eventNamespace: 'clinician-group-respondent-group',
	idName: 'relationId',

	events: {
		add () {
			// models stored on the server don't have `clinicianGroup`
			// attribute which is needed to deduct correct url for deletion
			this.each((model) => {
				model.set('clinicianGroup', +this.ownerId);
			});
		}
	},

	canAdd (model) {
		return +model.get('clinicianGroup') === +this.ownerId;
	},

	getAll (groupId) {
		return this.retrieve('groupHasAccessToRespondentGroups', groupId);
	},

	parse: (response) => map(response, (element) => {
		element.respondentGroupId = element.group.respondentGroupId;
		return element;
	})
});

export default new ClinicianGroupRespondentGroups();
