<template>
	<section class="sidebar-preview">
		<sidebar-items :items="items" :type="state.type" :clickable="clickable" />
	</section>
</template>

<script>
import SidebarItems from 'core/engine/sidebar/components/sidebar-items';
import t from 'service/lang/translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	title: t('Preview'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.SETTINGS,
		op: READ
	}],
	instant: true,
	actions: ['engine/respondentSidebar/init', 'engine/clinicianSidebar/init'],

	components: { SidebarItems },
	data: ({ tile }) => ({
		state: tile.cardContext().get('state')
	}),
	computed: {
		items: ({ $store, state }) => $store.getters[`engine/${state.type}Sidebar/menuItems`],
		clickable: ({ state }) => state.type === 'clinician'
	}
};
</script>
