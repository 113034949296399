<template>
	<div class="sidebar-variant">
		<h3>
			{{$t('menuComposer.variant.title')}}
		</h3>
		<div v-if="variantOptions.length > 1" class="sidebar-variant__section">
			<div class="sidebar-variant__selected">
				<form
					v-if="editingVariantName"
					class="sidebar-variant__form"
					@submit.prevent="changeVariantName(tempVariantName)"
				>
					<div class="sidebar-variant__select sidebar-variant__select--variant">
						<text-input
							v-model="tempVariantName"
							:translate="false"
							:label="$t('menuComposer.variant.variantName')"
						/>
					</div>
					<div class="sidebar-variant__actions">
						<button
							class="sidebar-variant__button"
							type="submit"
							:aria-label="$t('general-list.Save')"
						>
							<icon name="check" small />
						</button>
					</div>
				</form>
				<form
					v-else
					class="sidebar-variant__form"
					@submit.prevent="editVariant"
				>
					<div class="sidebar-variant__select sidebar-variant__select--variant">
						<select-input
							v-model="selectedVariant"
							item-label="name"
							:translate="false"
							:label="$t('menuComposer.variant.variantName')"
							:options="variantOptions"
							:clearable="false"
						/>
					</div>
					<div v-if="!isDefaultVariant" class="sidebar-variant__actions">
						<button
							class="sidebar-variant__button"
							type="submit"
							:title="$t('menuComposer.variant.changeVariantName')"
						>
							<icon name="edit" small />
						</button>
						<button
							class="sidebar-variant__button"
							type="button"
							:title="$t('menuComposer.variant.removeVariant')"
							@click.prevent="removeVariant(selectedVariant.name)"
						>
							<icon name="delete" small />
						</button>
					</div>
				</form>
			</div>

			<variant-criteria
				v-if="!isDefaultVariant"
				:type="type"
				:selected-variant="selectedVariant"
			/>
		</div>

		<div class="sidebar-variant__section">
			<text-input
				v-model="newVariantName"
				:translate="false"
				:label="$t('menuComposer.variant.newVariantName')"
			/>
			<button
				type="submit"
				:disabled="!newVariantName"
				@click="cloneVariant(newVariantName)"
			>
				<icon name="plus" small />
				<span>
					{{$t('menuComposer.variant.clone')}}
				</span>
			</button>
		</div>
	</div>
</template>

<script>
import {
	filter,
	map,
	cloneDeep
} from 'lodash';
import cwalert from 'cwalert';
import VariantCriteria from './variant-criteria';
import { defaultVariantName } from 'store/engine/sidebar-meta';

export default {
	components: {
		VariantCriteria
	},

	props: {
		type: {
			type: String,
			required: true
		}
	},

	data: () => ({
		editingVariantName: false,
		newVariantName: null,
		tempVariantName: null
	}),

	computed: {
		isDefaultVariant: ({ selectedVariant }) =>
			selectedVariant.name === defaultVariantName,

		selectedVariant: {
			get: ({ $store, storePath }) => cloneDeep($store.getters[storePath('currentVariant')]),
			set (variant) {
				this.setVariant(variant.name);
			}
		},

		variantOptions: ({ $store, storePath }) => map(
			$store.getters[storePath('config')],
			(variant) => ({
				name: variant.name,
				value: variant.name
			})
		)
	},

	methods: {
		storePath (actionName) {
			return `engine/${this.type}Sidebar/${actionName}`;
		},

		async setVariant (variantName) {
			await this.$store.dispatch(this.storePath('setVariant'), variantName);
		},

		async cloneVariant (variantName) {
			if (filter(this.variantOptions, ['name', variantName]).length) {
				cwalert.error(this.$t('menuComposer.variant.variantExists'));
				return;
			}

			await this.$store.dispatch(this.storePath('cloneVariant'), variantName);
			cwalert.success(this.$t('general-list.ChangesSaved'));
			this.newVariantName = null;

			this.setVariant(variantName);
		},

		async removeVariant (variantName) {
			await this.$store.dispatch(this.storePath('removeVariant'), variantName);
			cwalert.success(this.$t('general-list.ChangesSaved'));
			this.setVariant(defaultVariantName);
		},

		async changeVariantName (variantName) {
			await this.$store.dispatch(this.storePath('changeVariantName'), {
				variantName
			});

			cwalert.success(this.$t('general-list.ChangesSaved'));
			this.editingVariantName = false;
		},

		editVariant () {
			this.editingVariantName = true;
			this.tempVariantName = this.selectedVariant.name;
		}
	}
};
</script>
