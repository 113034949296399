<template>
	<div class="clinician-assigned-roles time-restricted-access">
		<form class="form-view" :novalidate="omitValidation" @submit.prevent="submit">
			<div
				class="form-view__field-container form-view__field-container--text"
				:class="isInvalid"
			>
				<select-input
					v-model="formData.role"
					:options="roles"
					:disabled="!canAssign"
					item-label="name"
					:label="$t('Select clinician access role')"
				/>
			</div>
			<div class="form__date-range">
				<datetime-input
					v-model="formData.validFrom"
					:label="$t('Start date')"
					class="datetime-input"
					class-prefix="clinician-role"
				/>
				<span class="date-separator">―</span>
				<datetime-input
					v-model="formData.validTo"
					:label="$t('End date')"
					class="datetime-input"
					class-prefix="clinician-role"
				/>
			</div>
			<submit-button :label="$t('Save')" />
		</form>
		<table v-if="assignedRoles.length" class="table">
			<thead>
				<tr>
					<th>{{$t('Role name')}}</th>
					<th>{{$t('Valid')}}</th>
					<th>{{$t('Valid from')}}</th>
					<th>{{$t('Valid until')}}</th>
					<th v-if="canRemove" />
				</tr>
			</thead>
			<tbody>
				<tr v-for="(role, index) in assignedRoles" :key="index">
					<td>
						<p class="table-cell-label">
							{{$t('Role name')}}
						</p>
						{{handleEmpty(role.name)}}
					</td>
					<td>
						<p class="table-cell-label">
							{{$t('Valid')}}
						</p>

						<icon
							name="circle-join"
							:class="roleValid(role).icon"
							:title="roleValid(role).title"
						/>
					</td>
					<td>
						<p class="table-cell-label">
							{{$t('Valid from')}}
						</p>
						{{handleDate(role.validFrom)}}
					</td>
					<td>
						<p class="table-cell-label">
							{{$t('Valid until')}}
						</p>
						{{handleDate(role.validTo)}}
					</td>
					<td v-if="canRemove" class="table__row-actions">
						<div class="delete-button__container">
							<button
								tabindex="0"
								class="delete-button__action icon icon--delete icon-2x"
								@click="removeRole(role, index)"
							/>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<p v-else>
			{{$t('The clinician has no roles assigned')}}
		</p>
	</div>
</template>

<script>
import t from 'translate';
import cwalert from 'cwalert';
import acl from 'service/acl/acl';
import moment from 'moment';
import Modernizr from 'modernizr';
import confirmation from 'components/confirmation/confirmation';
import { required } from 'vuelidate/lib/validators';
import { isNull, forOwn } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import Icon from 'components/icon';
import { handleDate, handleEmpty } from '../../helpers';
import { fromUtc } from 'service/datetime/datetime';

export default {
	title: t('Assigned clinician roles'),
	acl: [{
		checkpoint: SYSTEM.CLINICIANS.ROLES,
		op: READ
	}],
	actions: ({ $store }) => [
		['clinician/init', $store.getters.urlParams.id],
		['clinicianRoles/initAll'],
		['clinician/initAssignedRoles', {
			clinicianId: $store.getters.urlParams.id
		}]
	],

	components: { Icon },

	data: () => ({
		formData: {
			role: null,
			validFrom: null,
			validTo: null
		}
	}),

	validations: { formData: { role: { required } } },

	computed: {
		clinician: ({ $store }) => $store.getters['clinician/clinician'],
		assignedRoles: ({ $store }) => $store.getters['clinician/assignedRoles'],
		roles: ({ $store }) => $store.getters['clinicianRoles/all'],
		timezone: ({ clinician }) => clinician.timezoneName,

		isMe: ({ $store }) => $store.getters.isMe({ id: $store.getters['urlParams'].clinicianId }),

		canAssign () {
			return acl.checkAccess({
				checkpoint: SYSTEM.CLINICIANS.ROLES,
				op: this.isMe ? 'SUPERVISOR' : 'ADD'
			});
		},

		canRemove () {
			return acl.checkAccess({
				checkpoint: SYSTEM.CLINICIANS.ROLES,
				op: this.isMe ? 'SUPERVISOR' : 'DELETE'
			});
		},

		isInvalid () {
			return this.$v.formData.role.$error && 'invalid';
		},

		omitValidation () {
			return Modernizr.isios;
		}
	},

	methods: {
		handleEmpty,
		handleDate (date) {
			return handleDate({ date, timezone: this.timezone });
		},
		submit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.setLoading();
				this.$store.dispatch('clinician/assignRole', {
					data: this.formData,
					timezone: this.timezone
				}).then(() => {
					cwalert.success(this.$t('Clinician role has been added'));
					this.setLoaded();
					this.$v.$reset();

					forOwn(this.formData, (val, key) => {
						this.formData[key] = null;
					});
				}, () => {
					cwalert.error(this.$t('Error'));
					this.setLoaded();
				});
			}
		},

		removeRole (role, index) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: this.$t('Remove clinician role'),
				message: this.$t('Are you sure you wish to remove clinician role?')
			}).then(() => {
				this.setLoading();
				this.$store.dispatch('clinician/removeAssignedRole', { role, index })
					.then(() => {
						cwalert.success(this.$t('Clinician role removed successfully'));
						this.setLoaded();
					}, () => {
						cwalert.error(this.$t('Error'));
						this.setLoaded();
					});
			});
		},

		roleValid ({ validFrom, validTo }) {
			const isValid = {
				true: {
					icon: 'icon--circle-join',
					title: this.$t('Valid')
				},
				false: {
					icon: 'icon--error table-cell__icon--invalid',
					title: this.$t('Invalid')
				}
			};

			const now = moment().tz(this.timezone);
			const rangeStart = validFrom ?
				fromUtc({ date: validFrom, timezone: this.timezone }) :
				null;
			const rangeEnd = validTo ?
				fromUtc({ date: validTo, timezone: this.timezone }) :
				null;

			return isValid[this.inRange(now, rangeStart, rangeEnd)];
		},

		inRange: (datetime, rangeStart, rangeEnd) => {
			if (isNull(rangeEnd) && isNull(rangeStart)) {
				return true;
			} else if (isNull(rangeStart)) {
				return datetime < moment(rangeEnd);
			} else if (isNull(rangeEnd)) {
				return datetime > moment(rangeStart);
			}

			return datetime.isBetween(rangeStart, rangeEnd);
		}
	}
};
</script>
