import t from 'translate';
import appContext from 'app-context';
import urls from './urls';
import SingleEntity from '../shared/single-entity';
import types from '../content-packages/element-types';
import moment from 'moment';

export default SingleEntity.extend({
	eventNamespace: 'information-package-instance-element',
	urlRoot: urls.singleElement.url,

	inProgress: 'IN_PROGRESS',
	completed: 'COMPLETED',
	notStarted: 'NOT_STARTED',

	getStateLabel () {
		return {
			NOT_STARTED: t('Not started'),
			IN_PROGRESS: t('In progress'),
			COMPLETED: t('Completed')
		}[this.getState()];
	},

	getState () {
		return this.get('state');
	},

	getTypeLabel () {
		return types[this.get('type')];
	},

	isAssessment () {
		return this.get('type') === 'ASSESSMENT';
	},

	isExercise () {
		return this.get('type') === 'EXERCISE';
	},

	isPage () {
		return this.get('type') === 'PAGE';
	},

	isMedia () {
		return this.get('type') === 'MEDIA';
	},

	isLink () {
		return this.get('type') === 'LINK';
	},

	isCompleted () {
		return this.getState() === this.completed;
	},

	isInProgress () {
		return this.getState() === this.inProgress;
	},

	markAsCompleted () {
		this.set({
			state: this.completed,
			completedAt: moment()
		});
		appContext.trigger('information-package-instance-element.__update', this);
	}
});
