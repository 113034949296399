import appContext from 'app-context';
import $ from 'jquery';
import cwalert from 'cwalert';
import appBar from 'app-bar';
import t from 'translate';
import Clinician from 'repo/clinicians/clinician';
import confirmation from 'components/confirmation/confirmation';
import { clinicianName } from 'service/display-name/display-name';

const card = {
	title: t('clinician.profile'),
	name: 'clinician-profile',
	url: [
		'clinician/:clinicianId/',
		'clinician/:id/'
	],

	controller (clinicianId) {
		// clinicianId should be set automatically and therefore needs to be rewritten in the future
		this.cardContext().set('clinicianId', this.cardContext().get('id'));
		const inst = this;
		const isYou = clinicianId === appContext.getUserId();
		const clinician = new Clinician({
			administratorId: clinicianId
		});

		$.when(clinician.fetch()).then(function () {
			this.populate();

			if (!isYou && clinician.canBeDeleted()) {

				appBar.addButton(card.name, {
					title: t('Delete clinician'),
					icon: 'delete',
					click: () => {
						const message = t('Do you really want to delete the clinician {clinician}?', {
							clinician: clinicianName(clinician.toJSON())
						});
						confirmation({
							icon: 'delete',
							warning: true,
							title: t('Delete clinician'),
							message: message
						}).done(function () {
							clinician.destroy()
								.done(function () {
									cwalert.success(t('general-list.RemoveSuccessfully'));
									inst.close();
								})
								.fail(cwalert.failure.bind(cwalert, t('general-list.RemoveError')));
						});
					}
				});
			}
		}.bind(this));
	}
};

export { card as ClinicianProfile };
