var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "maintenance-mode__container", class: _vm.containerClass },
    [
      _c("h3", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.maintenanceMode.active
        ? _c("div", [
            _c(
              "span",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v("dataIntegration.maintenanceMode.from ")]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(": " + _vm._s(_vm.lockDate))]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm.labelactivate) + " : " + _vm._s(_vm.displayName)
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canUpdate
        ? _c(
            "button",
            {
              staticClass:
                "\n\t\t\tform-view__button\n\t\t\tform-view__button--button\n\t\t\tdata-integration__maintenance-mode-button",
              on: { click: _vm.update }
            },
            [_vm._v(_vm._s(_vm.buttonName))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }