var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "health-indicator" }, [
    _c("p", { staticClass: "health-indicator__phrase-container" }, [
      _c("span", { staticClass: "health-indicator__phrase" }, [
        _vm._v(_vm._s(_vm.phrase))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "health-indicator__toggle-container" }, [
      !_vm.healthy
        ? _c(
            "button",
            {
              staticClass: "health-indicator__toggle",
              on: { click: _vm.toggle }
            },
            [_vm._v(_vm._s(_vm.toggleLabel))]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }