import t from 'translate';
import appBar from 'app-bar';
import can from 'acl-can';
import newMenuItem from '../dialogs/new-menu-item';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';

const saved = {
	type: 'clinician'
};

const card = {
	name: 'menu-composer',
	title: t('Menu composer'),
	url: 'menu-composer',

	ctrl: ({ populate, cardData, setLoading, setLoaded }) => {
		cardData.set('state', { type: saved.type });
		const type = () => cardData.get('state').type;
		cardData.listenTo(cardData, 'change:state', () => {
			saved.type = cardData.get('state').type;
		});

		const renderEraseButton = () => {
			appBar.addButton(card.name, {
				icon: 'eraser',
				title: t('Reset'),
				click: () => {
					confirmation({
						icon: 'eraser',
						warning: true,
						title: t('Reset menu'),
						message: t(`Are you sure you want to reset the menu for {portal}?`, {
							portal: {
								clinician: t('Clinician Portal'),
								respondent: t('Respondent Portal')
							}[type()]
						})
					}).then(() => {
						setLoading();
						return store.dispatch(`engine/${type()}Sidebar/reset`);
					}).then(() => {
						setLoaded();
						cwalert.success(t('Menu has been reset'));
					});
				}
			});
		};

		const renderAddButton = () => {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('Add'),
				click: () => {
					newMenuItem({ state: cardData.get('state') });
				}
			});
		};

		if (can.edit('system.settings.settings')) {
			renderEraseButton();
		}

		// Not a bug, decision within defect 11680
		if (can.edit('system.settings.settings')) {
			renderAddButton();
		}

		populate();
	}
};

export { card as menuComposer };
