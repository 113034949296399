import urls from './urls';
import getResults from '../shared/get-results';
import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';

/**
 * @class Repository
 */
export default {
	/**
	 * Add new code.
	 *
	 * @function addCode
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.addCode(data);
	 */
	addCode: (data) => POST({ url: urls.codes, data }),

	/**
	 * Add new codeType.
	 *
	 * @function addCodeType
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.addCode(data);
	 */
	addCodeType: (data) => POST({ url: urls.codeTypes, data }),

	/**
	 * Add new codeVariation.
	 *
	 * @function addCodeVariation
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.addCode(data);
	 */
	addCodeVariation: (data) => PUT({ url: urls.codeVariation(data), data }),

	/**
	 * Add new data mapping.
	 *
	 * @function addDataMapping
	 * @param {object} data - Parameters.
	 * @param {number} data.mappingId - Mapping id.
	 * @param {number} data.ruleSetId - Rule set id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.addDataMapping(data);
	 */
	addDataMapping: (data) => POST({ url: urls.dataMapping({ id: data.ruleSet }), data }),

	/**
	 * Add new enterprise.
	 *
	 * @function addEnterprise
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.addEnterprise(data);
	 */
	addEnterprise: (data) => POST({ url: urls.enterprises, data }),

	/**
	 * Add new role mapping.
	 *
	 * @function addRoleMapping
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.addRoleMapping(data);
	 */
	addRoleMapping: (data) => POST({
		url: urls.roleMapping({ cardId: data.ruleSet }),
		data
	}),

	/**
	 * Add new ruleset.
	 *
	 * @function addRuleSet
	 * @param {object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.addRuleSet(data);
	 */
	addRuleSet: (data) => POST({ url: urls.ruleSets, data }),

	/**
	 * Check if there is already defined default rule set.
	 *
	 * @function checkDefaultRuleSet
	 * @returns {object} - RuleSet.
	 * @example
	 *        repository.checkDefaultRuleSet();
	 */
	checkDefaultRuleSet: () => GET({ url: urls.ruleSetsDefault, separator: '&' }),

	/**
	 * Delete a code.
	 *
	 * @function deleteCode
	 * @param {number} id - Id of a code.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteCode(1);
	 */
	deleteCode: (id) => DELETE({ url: urls.code(id) }),

	/**
	 * Delete a codeType.
	 *
	 * @function deleteCodeType
	 * @param {number} id - Id of a codeType.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteCodeType(1);
	 */
	deleteCodeType: (id) => DELETE({ url: urls.codeType(id) }),

	/**
	 * Delete a codeVariation.
	 *
	 * @function deleteCodeVariation
	 * @param {object} params - Id of a code and a codeVariation.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteCodeVariation(1);
	 */
	deleteCodeVariation: (params) => DELETE({ url: urls.codeVariation(params) }),

	/**
	 * Delete a data mapping.
	 *
	 * @function deleteDataMapping
	 * @param {object} data - Id of a data mapping.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteDataMapping(1);
	 */
	deleteDataMapping: (data) =>
		DELETE({ url: urls.dataMapping({ id: data.ruleSet.id, mappingId: data.mappingId }) }),

	/**
	 * Delete an enterprise.
	 *
	 * @function deleteEnterprise
	 * @param {number} id - Id of an enterprise.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteEnterprise(1);
	 */
	deleteEnterprise: (id) => DELETE({ url: urls.enterprise(id) }),

	/**
	 * Delete a role mapping.
	 *
	 * @function deleteRoleMapping
	 * @param {object} data - Id of a rule set and a role mapping.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteRoleMapping(1);
	 */
	deleteRoleMapping: (data) =>
		DELETE({ url: urls.roleMapping(data) }),

	/**
	 * Delete a ruleSet.
	 *
	 * @function deleteRuleSet
	 * @param {number} id - Id of a ruleSet.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.deleteRuleSet(1);
	 */
	deleteRuleSet: (id) => DELETE({ url: urls.ruleSet(id) }),

	/**
	 * Get a list of codes based on filters.
	 *
	 * @function searchCodes
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - Codes.
	 * @example
	 *        repository.searchCodes({ search: 'test', start: 0, limit: 10, filter: ['test'] });
	 */
	searchCodes: (params) => {
		const url = params ? urls.searchCodes(params) : urls.codes;
		return getResults(url, '&');
	},

	/**
	 * Get a list of enabled codes based.
	 *
	 * @function searchEnabledCodes
	 * @returns {Array} - Codes.
	 * @example
	 *        repository.searchEnabledCodes();
	 */
	searchEnabledCodes: () => {
		const url = urls.searchCodes({ enabled: '1' });
		return getResults(url, '&');
	},

	/**
	 * Get a list of codes that have no enterprise.
	 *
	 * @function emptyEntepriseCodes
	 * @returns {Array} - Codes.
	 * @example
	 *        repository.emptyEntepriseCodes();
	 */
	emptyEntepriseCodes: () =>
		GET({ url: urls.codesByEmptyEnterprise, separator: '&' }),

	/**
	 * Get a list of codes based on a single enterprise.
	 *
	 * @function searchCodesByEnterprise
	 * @param {number} enterpriseId - Parameters.
	 * @returns {Array} - Codes.
	 * @example
	 *        repository.searchCodesByEnterprise(1);
	 */
	searchCodesByEnterprise: (enterpriseId) =>
		GET({ url: urls.codesByEnterprise(enterpriseId), separator: '&' }),

	/**
	 * Get a list of codesTypes based on filters.
	 *
	 * @function searchCodesTypes
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - CodeTypes.
	 * @example
	 *        repository.searchCodeTypes({ search: 'test', start: 0, limit: 10, filter: ['test'] });
	 */
	searchCodeTypes: (params) => {
		const url = params ? urls.searchCodeTypes(params) : urls.codeTypes;
		return getResults(url);
	},

	/**
	 * Get a list of codeVariations based on code ID.
	 *
	 * @function dataIntegrationCodeVariations
	 * @param {number} codeId - ID of a single code.
	 * @returns {Array} - DataMapping.
	 * @example
	 * 		repository.dataIntegrationCodeVariations(1);
	 */
	dataIntegrationCodeVariations: (codeId) => GET({ url: urls.codeVariation(codeId) }),

	/**
	 * Get a single code.
	 *
	 * @function dataIntegrationCode
	 * @param {number} codeId - ID of a single code.
	 * @returns {object} - Code.
	 * @example
	 *        repository.dataIntegrationCode(1);
	 */
	dataIntegrationCode: (codeId) => GET({ url: urls.code(codeId) }),

	/**
	 * Get a single rule set.
	 *
	 * @function dataIntegrationRuleSet
	 * @param {number} ruleSetId - ID of a single rule set.
	 * @returns {object} - Rule set.
	 * @example
	 *        repository.dataIntegrationRuleSet(1);
	 */
	dataIntegrationRuleSet: (ruleSetId) => GET({ url: urls.ruleSet(ruleSetId) }),

	/**
	 * Get a list of data mappings based on a rule set id.
	 *
	 * @function dataMapping
	 * @param {number} ruleSetId - ID of a single data mapping.
	 * @returns {Array} - DataMapping.
	 * @example
	 *        repository.dataMapping(1);
	 */
	dataMapping: (ruleSetId) => GET({ url: urls.dataMapping({ id: ruleSetId }) }),

	/**
	 * Get a list of all data mappings.
	 *
	 * @function dataMappingAll
	 * @returns {Array} - DataMapping.
	 * @example
	 *        repository.dataMappingAll();
	 */
	dataMappingAll: () => GET({ url: urls.dataMappings }),

	/**
	 * Get a list of enterprises based on filters.
	 *
	 * @function searchEnterprises
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - Enterprises.
	 * @example
	 * 		repository.searchEnterprises(
	 * 			{ search: 'test', start: 0, limit: 10, filter: ['test'] }
	 * 		);
	 */
	searchEnterprises: (params) => getResults(urls.searchEnterprises(params), '&'),

	/**
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - Enterprises.
	 * @example
	 * 		repository.searchEnterprisesForTreatmentType(
	 * 			{ search: 'test', start: 0, limit: 10, filter: ['test'] }
	 * 		);
	 */
	searchEnterprisesForTreatmentType: (params) => getResults(
		urls.searchEnterprisesForTreatmentType(params),
		'&'
	),

	/**
	 * Get maintenance mode details.
	 *
	 * @function maintenanceMode
	 * @returns {object} - Maintenance mode data.
	 * @example
	 * 		repository.maintenanceMode(1);
	 */
	maintenanceMode: () => {
		const url = urls.maintenanceMode;
		return getResults(url);
	},

	/**
	 * Get a list of role mappings based on a rule set id.
	 *
	 * @function roleMapping
	 * @param {number} roleMappingId - ID of a single rule set.
	 * @returns {Array} - DataMapping.
	 * @example
	 *        repository.roleMapping(1);
	 */
	roleMapping: (roleMappingId) => GET({ url: urls.roleMapping({ cardId: roleMappingId }) }),

	/**
	 * Get a list of ruleSest based on filters.
	 *
	 * @function searchRuleSets
	 * @param {object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - RuleSets.
	 * @example
	 *        repository.searchRuleSets({ search: 'test', start: 0, limit: 10, filter: ['test'] });
	 */
	searchRuleSets: (params) => {
		const url = urls.searchRuleSets(params);
		return getResults(url);
	},

	/**
	 * Get a list of rule sets based on a single treatment type.
	 *
	 * @function searchRuleSetsByTreatmentType
	 * @param {number} treatmentTypeId - Parameters.
	 * @returns {Array} - Rule sets.
	 * @example
	 *        repository.searchRuleSetsByTreatmentType(1);
	 */
	searchRuleSetsByTreatmentType: (treatmentTypeId) =>
		GET({ url: urls.ruleSetsByTreatmentType(treatmentTypeId), separator: '&' }),

	/**
	 * Update a code.
	 *
	 * @function updateCode
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Code id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateCode(
	 * 			{ id: 1, ... }
	 * 		);
	 */
	updateCode: (data) => PUT({ url: urls.code(data.id), data }),

	/**
	 * Update a codeType.
	 *
	 * @function updateCodeType
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Code type id.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.updateCodeType({ id: 1, ... });
	 */
	updateCodeType: (data) => PUT({ url: urls.codeType(data.id), data }),

	/**
	 * Update a codeVariation.
	 *
	 * @function updateCodeVariation
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Code variation id.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.updateCodeVariation({ id: 1, ... });
	 */
	updateCodeVariation: (data) => PUT({ url: urls.codeVariation(data.id), data }),

	/**
	 * Update a data mapping.
	 *
	 * @function updateDataMapping
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Data mapping id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateDataMapping({ id: 1, ... });
	 */
	updateDataMapping: (data) =>
		PUT({ url: urls.dataMapping({ id: data.ruleSet, mappingId: data.id }), data }),

	/**
	 * Update a enterprise.
	 *
	 * @function updateEnterprise
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Enterprise id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateEnterprise({ id: 1, ... });
	 */
	updateEnterprise: (data) => PUT({ url: urls.enterprise(data.id), data }),

	/**
	 * Update maintenance mode.
	 *
	 * @function updateMaintenanceMode
	 * @param {code object} data - Parameters.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateMaintenanceMode(data);
	 */
	updateMaintenanceMode: (data) => PUT({
		url: urls.updateMaintenanceMode(data.action),
		user: data.userId
	}),

	/**
	 * Update a role mapping.
	 *
	 * @function updateRoleMapping
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - Role mapping id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateRoleMapping({ id: 1, ... });
	 */
	updateRoleMapping: (data) =>
		PUT({ url: urls.roleMapping({
			id: data.id,
			cardId: data.ruleSet
		}),
		data }),

	/**
	 * Update a ruleset.
	 *
	 * @function updateRuleSet
	 * @param {code object} data - Parameters.
	 * @param {number} data.id - RuleSet mapping id.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateEnterprise({ id: 1, ... });
	 */
	updateRuleSet: (data) => PUT({ url: urls.ruleSet(data.id), data }),

	/**
	 * Get a list of rule sets that have enterprise.
	 *
	 * @function getRuleSetsWithEnterprise
	 * @returns {Array} - Rule sets.
	 * @example
	 *        repository.getRuleSetsWithEnterprise();
	 */
	getRuleSetsWithEnterprise: () =>
		GET({ url: urls.ruleSetsWithEnterprise, separator: '&' }),

	/**
	 * Get a list of rule sets that have treatment types.
	 *
	 * @function getRuleSetsWithTreatmentTypes
	 * @returns {Array} - Rule sets.
	 * @example
	 *        repository.getRuleSetsWithTreatmentTypes();
	 */
	getRuleSetsWithTreatmentTypes: () =>
		GET({ url: urls.ruleSetsWithTreatmentType, separator: '&' }),

	/**
	 * Get a list of treatment types that include enterprise property.
	 *
	 * @function getTreatmentTypesWithEnterprises
	 * @returns {Array} - Treatment types.
	 * @example
	 *        repository.getTreatmentTypesWithEnterprises();
	 */
	getTreatmentTypesWithEnterprises: () =>
		GET({ url: urls.treatmentTypes, separator: '&' })

};
