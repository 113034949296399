import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * ReasonTemplate Entity
 * @class ReasonTemplate
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'reason-template',
	urlRoot: urls.reasonTemplates.url,
	defaults: {
		order: 0
	}
});
