import t from 'translate';

const card = {
	name: 'edit-goal',
	url: [
		'goal/:goalId/edit',
		'respondent/:respondentId/goal/:goalId/edit'
	],
	title: t('goals.editGoalTitle')
};

export { card as EditGoal };
