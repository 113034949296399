import t from 'service/lang/translate';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import cardUrl from 'cwcardurl';
import datetime from 'datetime';
import { get, map, unescape } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import showField from 'service/show-field';

export default SearchTileView.extend({

	title: t('Respondent Search'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS,
		op: READ
	}],

	searchFn: (...args) => repository.searchRespondents(...args),

	fields () {
		return [{
			key: 'respondentAccountEnabled',
			type: 'single-select',
			label: t('Account status'),

			values: [{
				id: 'true',
				label: t('Enabled')
			}, {
				id: 'false',
				label: t('Disabled')
			}],

			onChange: (value) => {
				this.viewModel.set('respondentAccountEnabled', map(value, (val, key) => {
					if (val === true) {
						return key;
					}
				}).toString());
				this.runQuery();
			}
		}, {
			key: 'respondentTestAccount',
			type: 'single-select',
			label: t('Account type'),

			values: [{
				id: 'true',
				label: t('Test')
			}, {
				id: 'false',
				label: t('Live')
			}],

			onChange: (value) => {
				this.viewModel.set('respondentTestAccount', map(value, (val, key) => {
					if (val === true) {
						return key;
					}
				}).toString());
				this.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry ' +
			'respondents-list__entry respondents-list__respondent-';
		const mainClassName = 'search-results-list__entry--main';
		const target = this.cardContext().get('target');

		return {
			click: (model) => {
				cardUrl.openCard(target, {
					respondentId: model.getId()
				});
			},
			columns: [{
				label: t('Full name'),
				css: `${className}full-name ${mainClassName}`,
				render: (value, { item }) => unescape(item.displayName())
			}, showField({
				config: get(this.config(), 'fields', {}),
				matchBy: 'key',
				field: {
					label: t('User name'),
					key: 'respondentUsername',
					css: `${className}username`,
					render: (val, { $td }) => {
						$td.text(val);
					}
				}
			}), {
				label: t('Date of birth'),
				key: 'respondentBirthdate',
				css: `${className}birthdate`,
				render: (date) => date ?
					datetime(date).toMoment().format(t('date.formats.dateOnly')) :
					''
			}, {
				label: t('Account type'),
				key: 'respondentTestAccount',
				css: `${className}is-disabled`,
				render: (test) => test ? t('Test') : t('Live')
			}, {
				label: t('Account status'),
				key: 'respondentAccountEnabled',
				css: `${className}is-test-account`,
				render: (enabled) => enabled ? t('Enabled') : t('Disabled')
			}]
		};
	}
});
