import { isFunction, clone, forEach, isUndefined, keys, toLower } from 'lodash';
import Table from 'components/table/table';
import cwalert from 'cwalert';
import $ from 'jquery';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import { Collection } from 'backbone';

export default function ({
	addAllowed,
	addFilter,
	afterAdd,
	afterDelete,
	allItems,
	allItemsCollection,
	beforeAdd,
	beforeDelete,
	columns,
	deleteAllowed,
	deleteFilter,
	emptyListMessage,
	itemPrimaryKey,
	label,
	maintainOrder = false,
	mute,
	multiple = true,
	name,
	onAdd,
	onDelete,
	parent,
	readonly,
	relationAttributes,
	relationCollection,
	relationItems,
	selectorLabel,
	selectorRender
}) {
	let theList;

	const $container = $('<div class="assignment" />').appendTo(parent);
	const $selectContainer = $('<div class="assignment__select-container" />');
	const $select = $('<select class="assignment__select" />');

	if (label) {
		const $label = $(`
			<label class="assignment__label">
				<span class="assignment__label-text">${label}</span>
			</label>`).appendTo($selectContainer);
		$select.appendTo($label);

	} else {
		$select.appendTo($selectContainer);
	}

	const disableOption = (opt) => {
		$(opt).remove();
	};

	const enableOption = (opt) => {
		$select.append(opt);
		$('option:selected', $select).prop('selected', false);
		$select.trigger('blur');

		// pats: I'm really sorry for this messy code but ... I'm too little for this genius
		// component
		if (maintainOrder) {
			const lowerCaseSort = (a, b) => toLower(a.text) < toLower(b.text) ? -1 : 1;
			// sort alphabetical
			$select.html(
				$('option', $select).sort((a, b) =>
					toLower(a.text) === toLower(b.text) ?
						0 :
						lowerCaseSort(a, b)
					)
			);

			// move option "Select value ..." (sic!) to top
			$('option.assignment-selector-label', $select).insertBefore($('option:eq(0)', $select));
		}
	};

	const itemsById = {};
	const items = [];
	const _allItems = allItems || allItemsCollection.toJSON();

	forEach(_allItems, (item) => {
		items.push(itemsById[item[itemPrimaryKey]] = clone(item));
	});

	const selected = {};

	const relCollection = relationItems ? new Collection(relationItems) : relationCollection;

	relCollection.each((model) => {
		selected[model.get(itemPrimaryKey)] = model;
	});

	if (addAllowed || (isUndefined(addAllowed) && !readonly)) {
		$container.append($selectContainer);
	}

	if (name) {
		$select[0].name = name;

	} else {
		$select[0].name = itemPrimaryKey;
	}

	const handleMultiple = ($selector, collection) => {
		const toggle = () => {
			$selector.prop('disabled', collection.size() > 0);
		};

		if (!multiple) {
			toggle();
			collection.on('add remove', toggle);
		}
	};

	handleMultiple($select, relCollection);

	const addOption = (selectorLabel, callback) => {
		const $opt = $('<option />').text(selectorLabel).appendTo($select);

		if (isFunction(callback)) {
			callback($opt[0]);
		}
	};

	// micro controllers are everywhere??? aaa WTF ....
	addOption(selectorLabel, (opt) => {
		$(opt).addClass('assignment-selector-label');
	});

	forEach(items, (item) => {
		if (selected[item[itemPrimaryKey]]) {
			item._relation = selected[item[itemPrimaryKey]];
		}

		if (!addFilter || addFilter(item)) {
			addOption(selectorRender(item), (opt) => {
				item._option = opt;
				opt.item = item;

				if (selected[item[itemPrimaryKey]]) {
					disableOption(opt);
				}
			});
		}
	});

	const addItem = (item) => {
		let attrs;

		if (isFunction(relationAttributes)) {
			attrs = relationAttributes.call(this, item);

		} else {
			attrs = clone(relationAttributes);
		}

		attrs[itemPrimaryKey] = item[itemPrimaryKey];

		let relation = new relCollection.model(attrs);

		const saveRelation = function ({ relationObj } = {}) {
			if (!relationItems) {
				relation.save({}, {
					success (model) {
						mute || cwalert.success(t('Successfully added'));
						relation = new relCollection.model(model.attributes);
						item._relation = relation;
						relCollection.add(relation);
						theList.setItems(relCollection.models);

						if (afterAdd) {
							afterAdd(relation);
						}
					},
					error () {
						mute || cwalert.error(t('Error adding item'));
						relCollection.remove(relation);
						theList.setItems(relCollection.models);
						enableOption(item._option);
					}
				});
			} else {
				mute || cwalert.success(t('Successfully added'));
				relation = new relCollection.model(relationObj);
				item._relation = relation;
				relCollection.add(relation);

				if (afterAdd) {
					afterAdd(relation);
				}
			}

			disableOption(item._option);
			item._option.item = item;
		};

		if (beforeAdd && !beforeAdd(relation)) {
			return;
		}

		if (isFunction(onAdd)) {
			onAdd(relation, saveRelation);

		} else {
			saveRelation();
		}
	};

	$select.on('change', function () {
		const item = this.options[this.selectedIndex].item;

		if (item) {
			addItem(item);
			$(this).trigger('blur');
			this.selectedIndex = 0;
		}
	});

	let actions = {};

	if (deleteAllowed || (isUndefined(deleteAllowed) && !readonly)) {
		actions = {
			delete: (item, cb) => {
				const relation = item._relation;

				if (beforeDelete && !beforeDelete(relation)) {
					cb.cancel();
					return;
				}

				const deleteRelation = function () {
					relCollection.remove(relation);
					enableOption(item._option);

					if (!relationItems) {
						// sometimes a relation property knows nothing about its relation connection
						// and then the problem with destroying occurs - so a little check here
						// may be small chance of regression
						isUndefined(relation.get(keys(relationAttributes)[0])) &&
						relation.set(relationAttributes);
						theList.setItems(relCollection.models);

						relation.destroy({
							success () {
								mute || cwalert.success(t('Successfully removed'));

								if (isFunction(afterDelete)) {
									afterDelete(relation);
								}
							},
							error () {
								mute || cwalert.failure(t('Error removing item'));
								disableOption(item._option);
								relCollection.add(relation);
								theList.setItems(relCollection.models);
							}
						});

					} else {
						mute || cwalert.success(t('Successfully removed'));

						if (isFunction(afterDelete)) {
							afterDelete(relation);
						}
					}

				};

				if (isFunction(onDelete)) {
					onDelete(relation, deleteRelation, () => {
						cb.cancel();
					});

				} else {
					confirmation({
						title: t('Remove'),
						icon: 'unlink',
						message: t('Are you sure you wish to remove this?'),
						warning: true
					}).done(deleteRelation).fail(() => {
						cb.cancel();
					});
				}
			}
		};
	}

	theList = new Table({
		parent: $container[0],
		columns,
		css: '',
		items: relCollection.models,
		rowload (relation, renderer) {
			renderer.replaceData(itemsById[relation.get ?
				relation.get(itemPrimaryKey) :
				relation[itemPrimaryKey]
			]);
			renderer.render();
		},
		deleteFilter,
		emptyListMessage,
		actions
	});

	this.destroy = function () {
		$select.off('change');
		theList = null;
		$container.remove();
	};

	this.relationCollection = relCollection;
	this.table = theList;
}
