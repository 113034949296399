import { View, Model } from 'backbone';
import $ from 'jquery';
import formView from 'components/form-view/form-view';
import Url from 'lib/url/url';
import t from 'translate';
import acl from 'service/acl/acl';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { reduce } from 'lodash';
import { READ, EDIT, SUPERVISOR } from 'service/acl/access-levels';
const dateRanges = {
	key: 'dateRange',
	type: 'select',
	values: [{
		label: t('system-manager.DatePast'),
		value: 'past'
	}, {
		label: t('system-manager.Datefuture'),
		value: 'future'
	}, {
		label: t('system-manager.DateAll'),
		value: 'all'
	}],
	labelField: 'label',
	keyField: 'value',
	label: t('system-manager.DateRange')
};

const checkboxes = [
	{
		key: 'anonymous',
		label: 'Unidentified respondent',
		acl: {
			checkpoint: SYSTEM.EXPORT.ASSESSMENT_STATUS,
			op: SUPERVISOR
		}
	},
	{
		key: 'anonymousOwner',
		label: 'Unidentified owner',
		acl: {
			checkpoint: SYSTEM.EXPORT.MODIFY_ANONYMOUS_OWNER,
			op: EDIT
		}
	},
	{ key: 'submitted', label: 'system-manager.Submitted' },
	{ key: 'patient', label: 'system-manager.ProductionData' },
	{ key: 'testRespondent', label: 'system-manager.TestData' }
];

const setCheckboxes = (list) => reduce(list, (result, child) => {
	result.push({
		key: child.key,
		type: 'checkbox',
		label: t(child.label),
		readonly: child.acl ? !acl.checkAccess(child.acl) : false
	});
	return result;
}, []);

export default View.extend({
	title: t('admin-portal.ExportAssessmentStatus'),
	acl: [{
		checkpoint: SYSTEM.EXPORT.ASSESSMENT_STATUS,
		op: READ
	}],

	render () {
		this.$el.append(
			$('<h2/>').html(
				t('system-manger.FilterExport')
			)
		);

		const M = Model.extend({
			defaults: {
				anonymous: true,
				anonymousOwner: true,
				submitted: false,
				testRespondent: false,
				patient: true,
				dateRange: 'past'

			},
			sync () {
				const dfd = $.Deferred();
				// eslint-disable-next-line max-len
				const url = new Url(`backend-commons/export/rest/assessmentInstanceStatus/all?${$.param(this.attributes)}`, null, {
					querySeparator: '',
					prependBackendRoot: 'system'
				});
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		const cfg = {
			name: 'system-export-assessmentstatus',
			model: new M(),
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: false,
			fields: [...setCheckboxes(checkboxes), dateRanges],
			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
