import t from 'translate';
import can from 'acl-can';
import appBar from 'app-bar';
import store from 'store';

const card = {
	title: t('Pages'),
	name: 'pages',
	url: 'pages',

	ctrl: ({ cardConfig, cardData }) => {
		if (can.add('system.settings.contentmanagement')) {
			appBar.addCardLink({
				sourceCard: card.name,
				title: t('Add page'),
				icon: 'add',
				card: 'add-page'
			});
		}

		const limit = cardConfig.limit;
		cardData.set({ limit });

		return store.dispatch('pages/categories/init');
	}
};

export { card as List };
