import t from 'translate';
import mixin from './_mixin';
import { validation } from './_validation-mixin';
import { mapGetters } from 'vuex';

export default () => ({
	name: 'treatment-attribute',
	mixins: [mixin, validation],

	data: () => ({
		defaultType: t('Treatment attribute'),
		identifierField: 'name',
		ids: {
			identity: 'id'
		}
	}),

	computed: {
		...mapGetters({
			attributes: 'treatment/attributes/translatedList'
		})
	}
});
