<template>
	<div :class="containerClass">
		<form @submit.prevent="onSubmit">
			<div
				v-if="processing"
				v-loader-spinner
				class="data-integration__loader"
			/>

			<edit-code
				v-if="code"
				:enabled="enabled"
				:item="item"
				:type="type"
				:vuelidate="vuelidate"
			/>

			<edit-enterprise
				v-if="type === 'enterprise'"
				:enabled="enabled"
				:item="item"
				:vuelidate="vuelidate"
			/>

			<select-input
				v-if="type === 'code'"
				v-model="item.codeType"
				:class-prefix="classPrefix"
				container-class="select-container"
				:disabled="updating"
				:invalid="vuelidate.item.codeType.$error"
				label="dataIntegration.codes.fields.type"
				:mandatory="true"
				:options="codeTypeOptions"
				:placeholder="t('dataIntegration.codes.actions.select')"
				:searchable="false"
				@input="vuelidate.item.codeType.$touch()"
			/>

			<select-input
				v-if="type === 'code'"
				v-model="item.enterprise"
				:class-prefix="classPrefix"
				container-class="select-container"
				:disabled="updating"
				label="dataIntegration.codes.fields.enterprise"
				:options="codeEnterprises"
				:placeholder="t('dataIntegration.enterprise.actions.select')"
				:searchable="false"
			/>

			<add-code-variation
				v-if="type === 'codeVariation'"
				:class-prefix="classPrefix"
				:codes="codes"
				:code-options="codeOptions"
				:item="item"
				:updating="updating"
				:vuelidate="vuelidate"
			/>

			<add-rule-set
				v-if="type === 'ruleSet'"
				:class-prefix="classPrefix"
				:code-options="codeOptions"
				:default-rule="defaultRule"
				:enterprises="enterprises"
				:item="item"
				:treatment-type-options="treatmentTypeOptions"
				:treatment-types="treatmentTypes"
				:updating="updating"
				:vuelidate="vuelidate"
				@invalidTreatmentType="setInvalidTreatmentType"
			/>

			<edit-data-mapping
				v-if="type === 'dataMapping'"
				:item="item"
				:assessment-options="assessmentOptions"
				:code-options="codeOptions"
				:code-type-options="codeTypeOptions"
				:portal-options="portalOptions"
				:updating="updating"
				:vuelidate="vuelidate"
				@repeated="toggleRepeated"
			/>

			<edit-role-mapping
				v-if="type === 'roleMapping'"
				:item="item"
				:role-available-options="roleAvailableOptions"
				:role-options="roleOptions"
				:updating="updating"
				:vuelidate="vuelidate"
			/>

			<checkbox-input
				v-if="canBeEnabled"
				v-model="item.enabled"
				:label="t('Enabled')"
				input-class="checkbox-input"
				text-class="checkbox-text"
			/>

			<button-group>
				<submit-button
					:can-submit="allowSubmit"
					:label="t('Save')"
				/>
				<plain-button :on-click="onClose" />
			</button-group>
		</form>
	</div>
</template>

<script>
import addCodeVariation from './add-code-variation';
import addRuleSet from './add-rule-set';
import can from 'acl-can';
import editCode from './edit-code';
import editDataMapping from './edit-data-mapping';
import editEnterprise from './edit-enterprise';
import editRoleMapping from './edit-role-mapping';
import t from 'translate';
import { includes, filter, map } from 'lodash';

export default {
	components: {
		addCodeVariation,
		addRuleSet,
		editCode,
		editDataMapping,
		editEnterprise,
		editRoleMapping
	},

	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		acl: {
			type: String,
			default: ''
		},
		assessmentOptions: {
			type: Array,
			default: () => []
		},
		codes: {
			type: Array,
			default: () => []
		},
		codeOptions: {
			type: Array,
			default: () => []
		},
		codeTypeOptions: {
			type: Array,
			default: () => []
		},
		defaultRule: {
			type: Boolean,
			default: false
		},
		edit: {
			type: Boolean,
			default: false
		},
		enterprises: {
			type: Array,
			default: () => []
		},
		enterpriseOptions: {
			type: Array,
			default: () => []
		},
		item: {
			type: Object,
			required: true
		},
		onClose: {
			type: Function,
			required: true
		},
		onSubmit: {
			type: Function,
			required: true
		},
		portalOptions: {
			type: Array,
			default: () => []
		},
		processing: {
			type: Boolean,
			default: false
		},
		roleAvailableOptions: {
			type: Array,
			default: () => []
		},
		roleOptions: {
			type: Array,
			default: () => []
		},
		treatmentTypeOptions: {
			type: Array,
			default: () => []
		},
		treatmentTypes: {
			type: Array,
			default: () => []
		},
		type: {
			type: String,
			default: ''
		},
		updating: {
			type: Boolean,
			default: false
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		invalidTreatmentType: false,
		repeated: false
	}),

	computed: {
		allowSubmit: ({ acl, aclCheck, invalidTreatmentType, repeated, vuelidate }) => {
			const readOnly = acl.length ? !aclCheck(acl) : false;
			const invalid = vuelidate.item.$invalid;
			return !repeated && !readOnly && !invalid && !invalidTreatmentType;
		},

		code () {
			return this.type === 'code' || this.type === 'codeType';
		},

		codeEnterprises () {
			const options = map(
				filter(this.enterpriseOptions, { enabled: true }), (enterprise) => ({
					label: enterprise.name,
					value: enterprise.id
				})
			);
			const label = t('dataIntegration.enterprise.options.noEnterprise');
			options.unshift({ label, value: 0 });
			return options;
		},

		containerClass () {
			return this.processing ? 'data-integration--processing' : '';
		},

		defaultTreatmentType () {
			return !!(this.item.treatmentType && this.item.treatmentType.value === '');
		},

		enabled: ({ processing, acl, aclCheck }) => {
			if (processing) {
				return false;
			}
			return acl.length ? aclCheck(acl) : true;
		},

		canBeEnabled: ({ type }) => !includes(['roleMapping', 'dataMapping', 'codeVariation'], type)
	},

	methods: {
		aclCheck (checkpoint) {
			return this.edit ? can.edit(checkpoint) : can.add(checkpoint);
		},

		toggleRepeated (bool) {
			this.repeated = bool;
		},

		setInvalidTreatmentType (bool) {
			this.invalidTreatmentType = bool;
		},
		t
	}
};
</script>
