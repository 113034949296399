var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("text-input", {
        attrs: {
          disabled: _vm.disabled,
          invalid: _vm.invalidName,
          label: _vm.$t("Name"),
          mandatory: true
        },
        on: { input: _vm.updateName },
        model: {
          value: _vm.data.name,
          callback: function($$v) {
            _vm.$set(_vm.data, "name", $$v)
          },
          expression: "data.name"
        }
      }),
      _vm._v(" "),
      _c("media-search", {
        attrs: {
          label: _vm.$t("Cover image"),
          "mime-types": _vm.allowedCoverTypes
        },
        on: { input: _vm.onUpdate },
        model: {
          value: _vm.data.cover,
          callback: function($$v) {
            _vm.$set(_vm.data, "cover", $$v)
          },
          expression: "data.cover"
        }
      }),
      _vm._v(" "),
      _c("text-input", {
        attrs: {
          disabled: _vm.disabled,
          label: _vm.$t("Description"),
          multiline: true
        },
        on: { input: _vm.onUpdate },
        model: {
          value: _vm.data.description,
          callback: function($$v) {
            _vm.$set(_vm.data, "description", $$v)
          },
          expression: "data.description"
        }
      }),
      _vm._v(" "),
      _c("checkbox-input", {
        attrs: {
          disabled: _vm.disabled,
          label: _vm.$t("Is default"),
          "input-class": "checkbox-input",
          "text-class": "checkbox-text"
        },
        on: { input: _vm.onUpdate },
        model: {
          value: _vm.data.isDefault,
          callback: function($$v) {
            _vm.$set(_vm.data, "isDefault", $$v)
          },
          expression: "data.isDefault"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "container-class": "select-container",
          "class-prefix": "data-integration",
          label: "dataIntegration.enterprise.name",
          disabled: _vm.disabledEnterprise,
          mandatory: true,
          options: _vm.enterprises,
          placeholder: _vm.$t("dataIntegration.enterprise.actions.select"),
          searchable: false
        },
        on: { input: _vm.onEnterpriseUpdate },
        model: {
          value: _vm.enterprise,
          callback: function($$v) {
            _vm.enterprise = $$v
          },
          expression: "enterprise"
        }
      }),
      _vm._v(" "),
      _vm.addAction
        ? _c(
            "button-group",
            [
              _c("submit-button", {
                attrs: {
                  "can-submit": !_vm.$v.data.$invalid,
                  label: _vm.$t("Save")
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }