<template>
	<section class="shortcut__group-container children-count">
		<span
			v-for="(list, listIndex) in config"
			:key="listIndex"
		>
			<div
				v-if="displayGroup(list)"
				class="shortcut__group-container children-count"
			>
				<h1 class="shortcut__group-container-title">
					{{t(list.groupTitle)}}
				</h1>
				<div
					v-for="(shortcut, i) in list.shortcuts"
					:key="i"
				>
					<shortcut-item
						v-if="displayItem(shortcut.card)"
						:item="shortcut"
					/>
				</div>
			</div>
		</span>
	</section>
</template>

<script>
import canAccess from 'core/engine/card/services/can-access';
import shortcutItem from './shortcut-item';
import t from 'translate';
import { reduce } from 'lodash';

export default {
	components: { shortcutItem },
	props: {
		config: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		displayGroup (list) {
			return reduce(list.shortcuts, (result, child) =>
				canAccess(child.card) ? true : result, false);
		},
		displayItem: (card) => canAccess(card),
		t
	}
};
</script>
