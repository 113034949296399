import t from 'translate';
import store from 'store';

const card = {
	name: 'circle-abuse-reports',
	title: t('Abuse reports'),
	url: 'circles/abuse-reports/',

	ctrl: ({ cardConfig, cardData }) => {
		const limit = cardConfig.limit || 10;

		cardData.set({ limit });

		return store.dispatch('circles/abuseReports/init', { limit });
	}
};

export { card as abuseReports };
