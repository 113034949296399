import appBar from 'app-bar';
import can from 'acl-can';
import store from 'store';
import t from 'translate';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { ACTIVITY } from 'store/goals/activity';
import { RESPONDENT } from 'store/respondents/respondent';
import { removeActivity, toggleActivityComplete } from '../shared/appbar-buttons';

const card = {
	name: 'edit-activity',
	url: [
		'respondent/:respondentId/goal/:goalId/activity/:activityId',
		'goal/:goalId/activity/:activityId'
	],
	title: t('goals.editActivityTitle'),

	ctrl: ({ actions, close }, { activityId, goalId }) => actions([
		[ACTIVITY.INIT, {
			activityId,
			goalId,
			respondentId: store.getters[RESPONDENT.RESPONDENT_ID]
		}]
	]).then(() => {
		const mountButtons = () => {
			appBar.clear(card.name);

			if (!can.edit(CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES)) {
				return;
			}
			const activity = store.getters[ACTIVITY.ITEM];

			appBar.addButton(toggleActivityComplete({ card, activity, done: mountButtons }));

			if (can.remove(CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES)) {
				appBar.addButton(removeActivity({ card, goalId, done: close }));
			}
		};

		mountButtons();
	})
};

export { card as EditActivity };
