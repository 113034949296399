import {
	filter, find, isUndefined, keys, map, reduce, without, findIndex, toLower, unescape
} from 'lodash';
import $ from 'jquery';
import t from 'translate';
import Table from 'components/table/table';
import TileView from 'tile-view';
import { renderHelper } from '../../components/counter';
import dataSource from '../../components/datasource/datasource';
import repository from 'repository';
import store from 'store';
import { clinicianName, respondentName } from 'service/display-name/display-name';

const setTreatmentData = (tile) => ({
	treatmentRoles: repository.getTreatmentAssignedRoles(
		store.getters['treatment/treatmentId'], tile.config().dataSources
	),
	treatmentNoRoles: repository.getTreatmentUnassignedRoles(
		store.getters['treatment/treatmentId'], tile.config().dataSources
	)
});

export default TileView.extend({
	title: t('Treatment overview'),
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	tileData: ({ tile }) => {
		store.subscribeAction((action) => {
			if (action.type === 'treatment/update') {
				tile.setLoading();
				$.when(setTreatmentData(tile)).then(() => {
					tile.setLoaded();
				});
			}
		});
		return setTreatmentData(tile);
	},

	customColumns: (tile, { withoutRoles }) => {
		const cfg = tile.config();
		const customColumns = filter(cfg.columns, { dataSource: true });

		return map(customColumns, (column) => {
			const ref = column.ref;
			const index = findIndex(cfg.dataSources, { ref });

			if (withoutRoles) {
				return {
					key: 'type',
					label: cfg.translate ? t(column.label) : column.label,
					className: 'sorter-false',
					render: (emptyValue, { item }) => {
						const data = find(item.get('customCalculationColumns'), { label: index });
						return !isUndefined(data) ?
							dataSource({
								type: column.type,
								data,
								ruleSets: filter(cfg.ruleSets, { ref }),
								values: item.get('customCalculationColumns')
							})() :
							'';
					}
				};
			}

			return {
				label: cfg.translate ? t(column.label) : column.label,
				render: (emptyValue, { item }) => {
					const data = find(item.get('customCalculationColumns'), { label: index });
					return !isUndefined(data) ?
						dataSource({
							type: column.type,
							data,
							ruleSets: filter(cfg.ruleSets, { ref }),
							values: item.get('customCalculationColumns')
						})() :
						'';
				}
			};
		});
	},

	loaded: ({ tile }) => {
		const columnsFromConfig = () => without(reduce(tile.config().columns, (memo, column) => {
			const columnName = keys(column)[0];
			column[columnName] && memo.push(columnName);
			return memo;
		}, []), 'dataSource');
		const columnsHashmap = {
			decisions: {
				key: 'stats',
				label: t('Decisions'),
				className: 'treatment-overview__decisions',
				render: (stats, { item }) => stats && renderHelper({
					stats: stats.decisions, item
				}, { criteria: {
					total: 'total',
					current: 'answered',
					problematic: 'unanswered'
				} })
			},
			assessments: {
				key: 'stats',
				label: t('Assessments'),
				className: 'treatment-overview__assessments',
				render: (stats, { item }) => stats && renderHelper({
					stats: stats.assessments,
					item
				}, {
					criteria: {
						total: 'total',
						current: 'answered',
						problematic: 'overdue'
					}
				})
			},
			exercises: {
				key: 'stats',
				label: t('Exercises'),
				className: 'treatment-overview__exercises',
				render: (stats, { item }) => stats && renderHelper({
					stats: stats.exercises,
					item
				}, {
					criteria: {
						total: 'total',
						current: 'completed',
						problematic: 'mandatoryNotCompleted'
					}
				})
			},
			packages: {
				key: 'stats',
				label: t('Packages'),
				className: 'treatment-overview__content-packages',
				render: (stats, { item }) => stats && renderHelper({
					stats: stats.packages,
					item
				}, {
					criteria: {
						total: 'total',
						current: 'completed'
					}
				})
			},
			pages: {
				key: 'stats',
				label: t('Pages'),
				className: 'treatment-overview__pages',
				render: (stats, { item }) => stats && renderHelper({
					stats: stats.pages,
					item
				}, {
					criteria: {
						total: 'total',
						current: 'read',
						problematic: 'mandatoryNotRead'
					}
				})
			}
		};

		const columnsToMerge = () => map(columnsFromConfig(), (column) => columnsHashmap[column]);

		tile.tableAssignedRoles = !!tile.treatmentRoles.length && new Table({
			parent: tile.el,
			sortable: true,
			sortOrder: tile.config().tableSort,
			collection: tile.treatmentRoles,
			columns: [{
				key: 'treatmentTypeRoleName',
				label: t('Role'),
				className: 'treatment-overview__role-name',
				render: (name, { item, tr }) => {
					item.get('roleRequired') && $(tr).addClass('mandatory');

					return name;
				}
			}, {
				key: 'displayName',
				label: t('Role occupied by'),
				className: 'treatment-overview__role-occupied',
				render: (
					displayName, { item }
				) =>  item.get('treatmentTypeRoleType') === 'RESPONDENT' ?
					unescape(respondentName(item.toJSON())) :
					unescape(clinicianName(item.toJSON()))
			}, ...columnsToMerge(), ...tile.customColumns(tile, { withoutRoles: false })]
		});

		tile.tableNotAssignedRoles = !!tile.treatmentNoRoles.length && new Table({
			parent: tile.el,
			collection: tile.treatmentNoRoles,
			columns: [{
				key: 'type',
				label: t('Role'),
				render: (item) => item && ({
					respondent: t('Not assigned to any respondent role'),
					clinician: t('Not assigned to any clinician role')
				}[toLower(item)])
			},
			...columnsToMerge(),
			...tile.customColumns(tile, { withoutRoles: true })
			]
		});

		if (!tile.treatmentRoles.length && !tile.treatmentNoRoles.length) {
			tile.$el.html(t('No treatment roles'));
		}
	}
});
