const micro = {
	width: 1,
	height: 'auto',
	micro: true
};

export const deprecatedTiles = {
	'exercises-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini respondent exercises',
		containerConfig: micro,
		tileConfig: {
			title: 'Exercises',
			translate: true,
			counterAlias: 'respondentExercises',
			link: {
				card: 'exercises'
			}
		}
	},
	'export-template': {
		tileName: 'edit-export-template'
	},
	'export-template-edit': {
		tileName: 'export-template'
	},
	'export-template-new': false,
	'chat': {
		tileName: 'video-chat'
	},
	'edit-information-package': {
		tileName: 'edit-content-package'
	},
	'information-package-clinician-groups': {
		tileName: 'content-package-clinician-groups'
	},
	'information-package-instance-exercise': {
		tileName: 'content-package-instance-exercise'
	},
	'information-package-instance-list': {
		tileName: 'content-package-instance-list'
	},
	'links-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini respondent links',
		containerConfig: micro,
		tileConfig: {
			title: 'Media and documents',
			translate: true,
			counterAlias: 'respondentMedia',
			link: {
				card: 'links'
			}
		}
	},
	'media-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini respondent media',
		containerConfig: micro,
		tileConfig: {
			title: 'Media and documents',
			translate: true,
			counterAlias: 'respondentMedia',
			link: {
				card: 'media'
			}
		}
	},
	'mini-assessment-list': {
		tileName: 'mini-shortcut',
		customTitle: 'new assessments',
		containerConfig: micro,
		tileConfig: {
			title: 'Assessment(s)',
			translate: true,
			counterAlias: 'respondentAssessments',
			link: {
				card: 'assessment-list'
			}
		}
	},
	'mini-information-package-instances': {
		tileName: 'mini-content-package-instances'
	},
	'mini-treatment-information-packages': {
		tileName: 'mini-treatment-content-packages'
	},
	'mini-add-assessment': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Add content package',
			translate: true,
			link: {
				card: 'add-assessment'
			}
		}
	},
	'mini-add-content-package': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Add content package',
			translate: true,
			link: {
				card: 'add-content-package'
			}
		}
	},
	'mini-add-plan': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Add a plan',
			translate: true,
			link: {
				card: 'add-plan'
			}
		}
	},
	'mini-respondent-goals-list': {
		tileName: 'goals',
		tileConfig: {
			agendaSettings: {
				initialCompressed: true,
				initialScope: 'month',
				translate: false,
				limitByRange: true
			}
		}
	},
	'mini-treatment-decisions': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini treatment decisions',
		containerConfig: micro,
		tileConfig: {
			title: 'Decisions',
			translate: true,
			counterAlias: 'treatmentDecisions',
			link: {
				card: 'treatment-decisions'
			}
		}
	},
	'respondent-goal-list': {
		tileName: 'goals',
		tileConfig: {
			agendaSettings: {
				initialCompressed: true,
				initialScope: 'month',
				translate: false,
				limitByRange: true
			}
		}
	},
	'mini-new-assessments': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'New assessment',
			translate: true,
			counterAlias: 'assessments',
			link: {
				card: 'clinician-assessments-list'
			},
			warning: 3
		}
	},
	'mini-video-chat': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Video chat',
			translate: true,
			link: {
				card: 'video-chat'
			}
		}
	},
	'simple-information-package-exercises': {
		tileName: 'simple-content-package-exercises'
	},
	'mini-treatment-messages': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Message(s)',
			translate: true,
			counterAlias: 'treatmentMsg',
			link: {
				card: 'treatment-messages'
			}
		}
	},
	'mini-treatment-assessments': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Assessments',
			translate: true,
			counterAlias: 'assessmentsNotSubmited',
			link: {
				card: 'treatment-assessments'
			}
		}
	},
	'mini-treatment-flags': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Raised flag(s)',
			translate: true,
			link: {
				card: 'raised-flags'
			},
			counterAlias: 'raisedFlags'
		}
	},
	'mini-treatment-content-packages': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Content packages',
			translate: true,
			link: {
				card: 'content-package-instance-list'
			},
			counterAlias: 'contentPackages'
		}
	},
	'mini-content-package-instances': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Content packages',
			translate: true,
			link: {
				card: 'content-package-instance-list'
			},
			counterAlias: 'contentPackages'
		}
	},
	'mini-reports': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'Report(s)',
			translate: true,
			counterAlias: 'reportsListFromTileConfig',
			link: {
				card: 'reports-list'
			}
		}
	},
	'mini-latest-assessment': {
		tileName: 'mini-shortcut',
		containerConfig: micro,
		tileConfig: {
			title: 'New assessment(s)',
			translate: true,
			counterAlias: 'respondentAssessments',
			link: {
				card: 'latest-assessment'
			}
		}
	},
	'pages-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini respondent pages',
		containerConfig: micro,
		tileConfig: {
			title: 'Pages',
			translate: true,
			counterAlias: 'respondentPages',
			link: {
				card: 'pages'
			}
		}
	},
	'mini-overdue-assessments': {
		tileName: 'mini-shortcut',
		customTitle: 'Overdue assessments',
		containerConfig: micro,
		tileConfig: {
			title: 'Overdue assessment(s)',
			translate: true,
			counterAlias: 'overdueAssessments',
			link: {
				card: 'overdue-assessments-list'
			}
		}
	},
	'respondent-timeline': {
		tileName: 'respondent-assessments',
		mandatory: true
	},
	'clinician-access-roles-list': {
		tileName: 'clinician-access-roles'
	},
	'information-package-instance-elements': {
		tileName: 'content-package-instance-elements'
	},
	'information-package-instance': {
		tileName: 'content-package-instance'
	},
	'mini-unseen-assessments': {
		tileName: 'mini-shortcut',
		customTitle: 'Unread assessments',
		containerConfig: micro,
		tileConfig: {
			title: 'Unread assessments',
			translate: true,
			counterAlias: 'unseenAssessments',
			alarm: 50,
			warning: 3,
			ACL: {
				checkpoint: 'administrator.respondents.respondents',
				op: 'read'
			},
			link: {
				card: 'unseen-assessments-list'
			}
		}
	},
	'mini-new-decisions': {
		tileName: 'mini-shortcut',
		customTitle: 'New decisions',
		containerConfig: micro,
		tileConfig: {
			title: 'New decision(s)',
			translate: true,
			link: {
				card: 'decisions-list'
			},
			counterAlias: 'decisions'
		}
	},
	'raised-flags-mini-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Raised flags',
		containerConfig: micro,
		tileConfig: {
			title: 'Raised flag(s)',
			translate: true,
			link: {
				card: 'raised-flags'
			},
			counterAlias: 'raisedFlags'
		}
	},
	'unseen-flags-mini-counter': {
		tileName: 'mini-shortcut',
		customTitle: 'Unseen flags',
		containerConfig: micro,
		tileConfig: {
			title: 'Unseen flag(s)',
			translate: true,
			link: {
				card: 'unseen-flags'
			},
			counterAlias: 'unseenFlags'
		}
	},
	'goals-list': {
		tileName: 'goals',
		mandatory: true,
		tileConfig: {
			agendaSettings: {
				initialCompressed: false,
				initialScope: 'month',
				translate: false,
				limitByRange: false
			},
			goalTimeScope: 'future',
			goalLabels: [
				'completedGoalActivities',
				'uncompletedGoalActivities',
				'goalDeadline',
				'goalCategories'
			],
			goalActions: [
				'editGoal',
				'addGoalActivity',
				'toggleGoalStatus'
			],
			activityTimeScope: 'future',
			activityLabels: [
				'activityStartDate',
				'activityEndDate'
			],
			activityActions: [
				'editGoalActivity',
				'toggleActivityStatus'
			]
		}
	},
	'mini-goals-list': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini respondent goals',
		containerConfig: micro,
		tileConfig: {
			title: 'goals.goals',
			translate: true,
			counterAlias: 'respondentGoals',
			link: {
				card: 'goals'
			}
		}
	},
	'mini-new-messages': {
		tileName: 'mini-shortcut',
		customTitle: 'new messages',
		containerConfig: micro,
		tileConfig: {
			title: 'Messages',
			translate: true,
			link: {
				card: 'messages'
			},
			counterAlias: 'respondentMessages'
		}
	},
	'mini-respondent-plans': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini plans',
		containerConfig: micro,
		tileConfig: {
			title: 'Plan(s)',
			translate: true,
			counterAlias: 'plans',
			link: {
				card: 'respondent-plans'
			}
		}
	},
	'mini-treatment-plans': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini treatment plans',
		containerConfig: micro,
		tileConfig: {
			title: 'Plans',
			translate: true,
			counterAlias: 'plans',
			link: {
				card: 'treatment-plans'
			}
		}
	},
	'mini-my-treatments': {
		tileName: 'mini-shortcut',
		customTitle: 'My treatments',
		containerConfig: micro,
		tileConfig: {
			title: 'My assigned treatments',
			translate: true,
			link: {
				card: 'my-treatments'
			}
		}
	},
	'mini-add-treatment': {
		tileName: 'mini-shortcut',
		customTitle: 'Add treatment',
		containerConfig: micro,
		tileConfig: {
			title: 'Add a treatment',
			translate: true,
			link: {
				card: 'add-treatment'
			}
		}
	},
	'mini-respondent-messages': {
		tileName: 'mini-shortcut',
		customTitle: 'Mini messages',
		containerConfig: micro,
		tileConfig: {
			title: 'Message(s)',
			translate: true,
			counterAlias: 'respondentMessages',
			link: {
				card: 'respondent-messages'
			}
		}
	},
	'system-settings-circle-abuse-reports': {
		tileName: 'circle-abuse-reports'
	}
};
