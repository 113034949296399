import TileView from 'tile-view';
import store from 'store';

import cardUrl from 'cwcardurl';
import { map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import quickSearch from 'components/quick-search/quick-search';
import t from 'translate';

export default TileView.extend({
	title: t('Treatment search'),
	actions: (tile) => [['treatmentSearch/setLimit', tile.config().limit || 10]],
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'read'
	}],

	Vue: () => ({
		data: {
			loading: false,
			placeholder: '',
			url: ''
		},
		components: {
			quickSearch
		},
		computed: {
			...mapGetters({ exceeded: 'treatmentSearch/exceeded' }),
			...mapGetters({ limit: 'treatmentSearch/limit' }),
			...mapGetters({ total: 'treatmentSearch/total' }),
			list () {
				let list = store.getters['treatmentSearch/list'];

				if (list.length) {
					list = this.provideListData(list);
				}
				this.loading = false;
				return list;
			}
		},
		methods: {
			...mapActions('treatmentSearch', ['provideResults', 'setSearchList']),
			clearResults () {
				this.setSearchList([]);
			},
			getResults (search) {
				this.loading = true;
				const params = this.provideParams(search);
				this.provideResults(params);
				this.url = this.provideMoreUrl(search);
			},
			provideListData: (list) => map(list, (item) => ({
				rows: [
					{ class: 'treatment-name', name: item.name },
					{ class: 'respondent-name', name: item.respondent.displayName }
				],
				url: cardUrl.buildUrl('treatment', { treatmentId: item.id })
			})),
			provideMoreUrl: (search) => cardUrl.buildUrl('search-treatments', { search }),
			provideParams (query) {
				return {
					query,
					start: 0,
					limit: this.limit
				};
			},
			submit: (search) => cardUrl.openCard('search-treatments', { search })
		},
		created () {
			this.url = this.provideMoreUrl('');
		},
		template:
		`<quick-search
			:exceeded="exceeded"
			:getResults="getResults"
			:list="list"
			:loading="loading"
			:placeholder="placeholder"
			:total="total"
			:url="url"
			:clear="clearResults"
			:submit="submit"
			:label="$t('Treatment search')"
		/>
		`
	})
});
