import appBar from 'app-bar';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import systemSettings from 'system-settings';
import t from 'translate';
import { Model } from 'backbone';

const card = {
	name: 'plan-properties',
	url: [
		'plan-instance/:planInstanceId'
	],
	title: t('Plan Properties'),
	appEvents: {
		'plan-instance.terminate plan-instance.delete': 'close',
		'plan-instance.terminate-start' () {
			this.setLoading();
			this.close();
		}
	},

	ctrl: (ctrlProps, { planInstanceId }) =>
		store.dispatch('planInstance/initPure', { planInstanceId }).then(() => {
			const planInstance = store.state.planInstance.model;
			const { cardData } = ctrlProps;

			// plan instance model is fully populated after first fetch which
			// means it has to be fetched in order to get a full data
			// Thank you b💩ckend!
			return planInstance.fetch().then(() => {
				cardData.set({
					planInstanceId,
					planInstance,
					respondentId: store.getters['respondent/respondentId']
				});
				card.owner({ ctrlProps, planInstance });
				card.loaded({ ctrlProps, planInstance });
			});
		}),

	loaded: ({ ctrlProps, planInstance }) => {
		appBar.clear(card.name);
		const { setLoading, close } = ctrlProps;

		const buttonsCfg = {
			true: {
				title: ['pause', 'Pause plan'],
				action: planInstance.pause.bind(planInstance),
				successMsg: 'Plan paused',
				errorMsg: 'Unable to pause plan'
			},
			false: {
				title: ['play', 'Start plan'],
				action: planInstance.resume.bind(planInstance),
				successMsg: 'Plan resumed',
				errorMsg: 'Unable to resume plan'
			},
			terminate: {
				title: ['stop', 'Terminate plan'],
				action: planInstance.terminate.bind(planInstance),
				successMsg: 'Plan terminated successfully',
				errorMsg: 'Unable to terminate plan',
				confirmation: t('Terminating the plan is irreversible, do you wish to proceed?'),
				confirmationWarning: true,
				confirmationIcon: 'stop'
			}
		};
		const pauseOrResume = buttonsCfg[!!planInstance.isEnabled()];
		const canDelete = can.remove('administrator.respondents.workflowplans');
		const notStarted = planInstance.get('status') !== 'Not started';
		const notTerminated = planInstance.get('status') !== 'Terminated';

		const addButton = (cfg) => {
			appBar.addButton(card.name, {
				title: t(cfg.title[1]),
				icon: cfg.title[0],
				click (opts) {
					const confirmed = () => {
						opts.view.setLoading();
						cfg.action().then(() => {
							cwalert.success(t(cfg.successMsg));
							card.loaded({ ctrlProps, planInstance });
						});
					};

					cfg.confirmation ? confirmation({
						title: t(cfg.title[1]),
						message: cfg.confirmation,
						warning: cfg.confirmationWarning,
						icon: cfg.confirmationIcon
					}).then(confirmed) : confirmed();

					return false;
				}
			});
		};

		const addDeleteButton = () => {

			const deletePlanInstance = () => {
				setLoading();
				planInstance.destroy().then(() => {
					cwalert.success(t('Plan deleted successfully'));
					close();
				}, () => {
					cwalert.error(t('Unable to delete plan'));
				});
			};

			appBar.addButton(card.name, {
				title: t('Delete plan'),
				icon: 'delete',
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete plan'),
						message: t('Are you sure you wish to delete this assessment plan?')
					}).done(() => {
						deletePlanInstance();
					});
				}
			});
		};

		if (notStarted && notTerminated && can.edit('administrator.respondents.workflowplans')) {
			systemSettings.getBoolean('ADMIN_WORKFLOW_PAUSE_ENABLE') && addButton(pauseOrResume);
			addButton(buttonsCfg.terminate);
		}

		canDelete && addDeleteButton();
	},

	owner: ({ ctrlProps, planInstance }) => {
		const documentModel = new Model();
		const { cardData } = ctrlProps;
		documentModel.set({
			workflowInstanceId: planInstance.getId(),
			ownerClinicianId: planInstance.get('ownerAdministratorId'),
			isFinalized: planInstance.isTerminated(),
			requestParams: {
				workflowInstance: planInstance.getId()
			}
		});

		documentModel.on('change:ownerClinicianId', () => {
			if (documentModel.get('ownerClinicianId')) {
				planInstance.set('ownerAdministratorId', documentModel.get('ownerClinicianId'));
				planInstance.save().done(() => {
					cwalert.success(t('Changes saved.'));
				});
			}
		});
		cardData.set('document', documentModel);
	}
};

export { card as PlanProperties };

