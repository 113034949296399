import t from 'translate';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import cardUrl from 'cwcardurl';
import can from 'acl-can';
import { assign, compact, map } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';

export default SearchTileView.extend({

	title: t('Clinicians'),
	acl: [{
		checkpoint: SYSTEM.CLINICIANS.CLINICIANS,
		op: READ
	}],

	cardData: () => ['roles', 'groups'],

	searchFn: repository.searchClinicians.bind(repository),

	fields () {
		const ids = (value) => compact(map(value, (item, key) => item && key));

		return [{
			key: 'administratorRoles',
			type: 'single-select',
			label: t('Role'),

			values: map(this.roles.toJSON(), (role) => assign({}, role, {
				id: role.administratorRoleId,
				label: role.administratorRoleName
			})),

			onChange: (value) => {
				this.viewModel.set('administratorRoles', ids(value).toString());
				this.runQuery();
			}
		}, can.read('system.administrators.groups') && {
			key: 'administratorGroups',
			type: 'single-select',
			label: t('Group'),

			values: map(this.groups.toJSON(), (group) => assign({}, group, {
				id: group.administratorGroupId,
				label: group.administratorGroupName
			})),

			onChange: (value) => {
				this.viewModel.set('administratorGroups', ids(value).toString());
				this.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry ' +
			'clinicians-list__entry clinicians-list__clinician-';
		const mainClassName = 'search-results-list__entry--main';

		return {
			click (model) {
				cardUrl.openCard('clinician-profile', {
					id: model.getId()
				});
			},
			columns: [{
				label: t('Full name'),
				css: `${className}full-name ${mainClassName}`,
				render: (val, { $td, item }) => {
					$td.html(item.displayName());
				}
			}, {
				label: t('User name'),
				key: 'administratorUsername',
				css: `${className}username`,
				render: (val, { $td }) => {
					$td.text(val);
				}
			}, {
				label: t('Email'),
				key: 'administratorEmail',
				css: `${className}email`
			}, {
				label: t('Phone'),
				key: 'administratorPhone',
				css: `${className}phone`
			}, {
				label: t('Account disabled'),
				key: 'administratorAccountEnabled',
				css: `${className}is-disabled`,
				render: (enabled) => enabled ? t('no') : t('yes')
			}, {
				label: t('Test account'),
				key: 'administratorTestAccount',
				css: `${className}is-test-account`,
				render: (test) => test ? t('yes') : t('no')
			}]
		};
	}
});
