import can from 'acl-can';
import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import { isUndefined } from 'lodash';

const card = {
	title: t('Respondent Search'),
	name: 'search-respondents',
	url: 'respondent/search/?q=(:search)',

	ctrl ({ populate, cardConfig, cardData }, { search }) {
		can.add('administrator.respondents.respondents') && appBar.addCardLink(card.name, {
			card: 'new-respondent-account',
			icon: 'add',
			title: t('create.respondent')
		});

		// see #8808
		!isUndefined(search) && store.dispatch('setSearchQuery', {
			cardName: this.name,
			query: search
		});

		cardData.set({ hideResults: cardConfig.startEmpty });

		populate();
	}
};

export { card as SearchRespondent };
