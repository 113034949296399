<template>
	<form
		novalidate
		autocomplete="off"
		class="form-view"
		@submit.prevent="onSubmit"
	>
		<select-input
			v-model="section.contentPackage"
			name="contentPackage"
			required
			mandatory
			:label="$t('treatmentProgram.section.contentPackage')"
			:options="contentPackages"
			:get-option-label="({ name }) => name"
		/>

		<button-group>
			<submit-button
				:can-submit="!$v.$invalid"
				:label="$t('general.buttons.add')"
			/>
		</button-group>
	</form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import cwalert from 'service/cwalert';
import { error } from 'service/log/log';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';
import { CONTENT_PACKAGES } from 'store/content-packages/content-packages';

export default {
	props: {
		moduleId: {
			type: Number,
			required: true
		}
	},

	data: () => ({
		section: {
			contentPackage: null
		}
	}),

	computed: {
		contentPackages: ({ $store }) =>
			$store.getters[CONTENT_PACKAGES.NOT_UNPUBLISHED]
	},

	methods: {
		async onSubmit () {
			try {
				await this.$store.dispatch(
					TREATMENT_PROGRAM.ADD_SECTION,
					{
						moduleId: this.moduleId,
						section: this.section
					}
				);

				this.$emit('success');

				cwalert.success(this.$t('general.messages.create.success'));
			} catch (err) {
				cwalert.error(this.$t('general.messages.changes-not-saved'));
				error(err);
			}
		}
	},

	validations: {
		section: {
			contentPackage: {
				required
			}
		}
	}
};
</script>
