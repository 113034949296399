import TileView from 'tile-view';
import t from 'translate';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';
import store from 'store';

export default TileView.extend({
	title: t('Assessment types'),
	acl: [{
		checkpoint: 'system.settings.assessment-types',
		op: 'READ'
	}],
	features: ['ASSESSMENT_TYPES'],
	tileData: () => ({
		types: store.state.types.collection
	}),

	loaded: ({ tile }) => {
		tile.table = new datamodel.grid.Table({
			collection: tile.types,
			readonly: !can.edit('system.settings.assessment-types'),
			columns: {
				label: {
					label: t('Label'),
					rendererFactory: new datamodel.widgets.TextFactory({
						noempty: true
					})
				},
				order: {
					label: t('Order'),
					rendererFactory: new datamodel.widgets.TextFactory({
						noempty: true
					})
				}
			},
			actions: {
				remove: {
					disabled: !can.remove('system.settings.assessment-types')
				}
			},
			container: tile.el
		});
	}
});

