var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("consent-instances", {
    attrs: {
      items: _vm.consentInstances,
      fields: _vm.fields,
      "on-click": _vm.open,
      "class-prefix": _vm.classPrefix
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function(ref) {
          var label = ref.label
          return [_vm._v("\n\t\t" + _vm._s(label) + "\n\t")]
        }
      },
      {
        key: "cell",
        fn: function(ref) {
          var fieldKey = ref.fieldKey
          var item = ref.item
          return [
            _vm.labelVisible({ item: item, key: fieldKey })
              ? _c("p", { staticClass: "table-cell-label" }, [
                  _vm._v(_vm._s(_vm.label(fieldKey)))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              [
                fieldKey === "consentName"
                  ? _c("icon", {
                      attrs: {
                        name: _vm.iconName(item),
                        title: _vm.description(item)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                fieldKey === "deleteAction" && _vm.allowDelete(item)
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "icon-button__button consent-instances__icon",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.remove(item)
                          }
                        }
                      },
                      [_c("icon", { attrs: { name: "delete", small: true } })],
                      1
                    )
                  : _c("span", [_vm._v(_vm._s(item[fieldKey]))])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }