import t from 'translate';
import store from 'store';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';
import appBar from 'service/app-bar/app-bar';
import resetConfigDialog from '../dialogs/reset-config';

const card = {
	name: 'card-list',
	url: 'card-list',
	title: t('cardConfig.cardList.title'),

	ctrl: ({ setLoaded, setLoading }) => store.dispatch('cards/init')
		.then(() => store.dispatch('cards/setCurrentCard', ''))
		.then(() => {
			if (can.administrate(SYSTEM.SETTINGS.SETTINGS)) {
				appBar.addButton({
					sourceCard: card.name,
					icon: 'eraser',
					title: t('Reset card configuration for this portal'),
					click: () => {
						resetConfigDialog({
							setLoaded,
							setLoading,
							onSuccess: () => store.dispatch('cards/reset'),
							title: t('Reset card configuration for this portal'),
							message: t('Are you sure you want to reset this portal?'),
							successMessage: t('Card configuration has been reset')
						});
					}
				});
			}
		})
};

export { card as CardList };
