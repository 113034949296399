var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.containerClass }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _vm.processing
          ? _c("div", {
              directives: [
                { name: "loader-spinner", rawName: "v-loader-spinner" }
              ],
              staticClass: "data-integration__loader"
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.code
          ? _c("edit-code", {
              attrs: {
                enabled: _vm.enabled,
                item: _vm.item,
                type: _vm.type,
                vuelidate: _vm.vuelidate
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "enterprise"
          ? _c("edit-enterprise", {
              attrs: {
                enabled: _vm.enabled,
                item: _vm.item,
                vuelidate: _vm.vuelidate
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "code"
          ? _c("select-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                "container-class": "select-container",
                disabled: _vm.updating,
                invalid: _vm.vuelidate.item.codeType.$error,
                label: "dataIntegration.codes.fields.type",
                mandatory: true,
                options: _vm.codeTypeOptions,
                placeholder: _vm.t("dataIntegration.codes.actions.select"),
                searchable: false
              },
              on: {
                input: function($event) {
                  return _vm.vuelidate.item.codeType.$touch()
                }
              },
              model: {
                value: _vm.item.codeType,
                callback: function($$v) {
                  _vm.$set(_vm.item, "codeType", $$v)
                },
                expression: "item.codeType"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "code"
          ? _c("select-input", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                "container-class": "select-container",
                disabled: _vm.updating,
                label: "dataIntegration.codes.fields.enterprise",
                options: _vm.codeEnterprises,
                placeholder: _vm.t("dataIntegration.enterprise.actions.select"),
                searchable: false
              },
              model: {
                value: _vm.item.enterprise,
                callback: function($$v) {
                  _vm.$set(_vm.item, "enterprise", $$v)
                },
                expression: "item.enterprise"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "codeVariation"
          ? _c("add-code-variation", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                codes: _vm.codes,
                "code-options": _vm.codeOptions,
                item: _vm.item,
                updating: _vm.updating,
                vuelidate: _vm.vuelidate
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "ruleSet"
          ? _c("add-rule-set", {
              attrs: {
                "class-prefix": _vm.classPrefix,
                "code-options": _vm.codeOptions,
                "default-rule": _vm.defaultRule,
                enterprises: _vm.enterprises,
                item: _vm.item,
                "treatment-type-options": _vm.treatmentTypeOptions,
                "treatment-types": _vm.treatmentTypes,
                updating: _vm.updating,
                vuelidate: _vm.vuelidate
              },
              on: { invalidTreatmentType: _vm.setInvalidTreatmentType }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "dataMapping"
          ? _c("edit-data-mapping", {
              attrs: {
                item: _vm.item,
                "assessment-options": _vm.assessmentOptions,
                "code-options": _vm.codeOptions,
                "code-type-options": _vm.codeTypeOptions,
                "portal-options": _vm.portalOptions,
                updating: _vm.updating,
                vuelidate: _vm.vuelidate
              },
              on: { repeated: _vm.toggleRepeated }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "roleMapping"
          ? _c("edit-role-mapping", {
              attrs: {
                item: _vm.item,
                "role-available-options": _vm.roleAvailableOptions,
                "role-options": _vm.roleOptions,
                updating: _vm.updating,
                vuelidate: _vm.vuelidate
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.canBeEnabled
          ? _c("checkbox-input", {
              attrs: {
                label: _vm.t("Enabled"),
                "input-class": "checkbox-input",
                "text-class": "checkbox-text"
              },
              model: {
                value: _vm.item.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.item, "enabled", $$v)
                },
                expression: "item.enabled"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button-group",
          [
            _c("submit-button", {
              attrs: { "can-submit": _vm.allowSubmit, label: _vm.t("Save") }
            }),
            _vm._v(" "),
            _c("plain-button", { attrs: { "on-click": _vm.onClose } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }