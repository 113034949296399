import TileView from 'tile-view';
import { assign, find, forEach, get, isNull, isUndefined, replace, upperFirst } from 'lodash';
import { sprintf } from 'sprintf';
import { EMPTY_CHAR } from 'lib/chars';
import store from 'store';
import cardUrl from 'cwcardurl';

const prop = (propName, respondent) => respondent && ({
	[propName]: !isUndefined(respondent[propName]) ?
		respondent[propName] :
		respondent[`respondent${upperFirst(propName)}`]
});

const props = (propNames, respondent) => {
	const obj = {};

	forEach(propNames, (propName) => {
		assign(obj, prop(propName, respondent));
	});
	return obj;
};

const ALLOWED_PROPS = [
	'id', 'address', 'displayName', 'email', 'firstName', 'lastName', 'phone', 'username'
];

export default TileView.extend({
	actions: (tile) => {
		if (!isUndefined(get(tile.config(), 'roleId'))) {
			return ['treatment/init'];
		}

		return ['respondent/init'];
	},

	loaded: ({ tile }) => {
		const treatmentTypeRoleId = get(tile.config(), 'roleId');

		if (treatmentTypeRoleId) {
			const found = find(
				store.getters['treatment/assignedRoles'],
				{ treatmentTypeRoleId }
			);

			if (!get(found, 'respondent')) {
				tile.disable();
				return;
			}

			tile.setLoading();
			store.dispatch(
				'treatment/fillRespondentDataAtRole',
				{ roleId: found.roleId }
			).then(() => {
				tile.setLoaded();
			});
		}
	},

	Vue: (tile) => ({
		data: {
			cfg: tile.config()
		},
		components: {
			container: {
				template: `
					<a
						v-if="link"
						:href="link"
						class="mini-value-tile__link mini-value-tile__container"
					>
						<slot></slot>
					</a>
					<div v-else class="mini-value-tile__container"><slot></slot></div>
				`,
				props: {
					link: {
						type: String,
						required: true
					}
				}
			}
		},
		template: `
			<div class="mini-value-tile" :class="className">
				<container :link="link">
					<div class="mini-value-tile__text-container">
						<p class="mini-value-tile__title">{{title}}</p>
						<p v-if="subtitle" class="mini-value-tile__subtitle">
							<span>{{subtitle}}</span>
						</p>
					</div>
				</container>
			</div>
		`,
		computed: {
			className () {
				return {
					'mini-value-tile--with-link': !!this.link.length
				};
			},
			title () {
				return this.sprintf('title');
			},
			subtitle () {
				return this.sprintf('subtitle');
			},
			rawValue () {
				const value = get(
					this.respondent,
					this.cfg.respondentField,
					EMPTY_CHAR
				);
				return !isNull(value) ? value : EMPTY_CHAR;

			},

			respondent () {
				const treatmentTypeRoleId = get(tile.config(), 'roleId');
				const respondent = treatmentTypeRoleId ?
					get(
						find(
							store.getters['treatment/assignedRoles'],
							{ treatmentTypeRoleId }
						),
						'respondent'
					) :
					this.$store.getters['respondent/data'];
				return props(ALLOWED_PROPS, respondent);
			},

			link () {
				if (!this.cfg.openCard) {
					return '';
				}
				const params = this.cfg.cardParams || {
					treatmentId: this.$store.getters['treatment/treatmentId'],
					respondentId: this.respondent.id
				};

				return cardUrl.buildUrl(this.cfg.openCard, params);
			}
		},

		methods: {
			sprintf (field) {
				return this.nanToEmpty(sprintf(this.cfg[field], this.rawValue));
			},
			nanToEmpty: (value) => replace(value, 'NaN', EMPTY_CHAR)
		}
	})
});
