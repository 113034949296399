import CirclesView from './circles-list/tile';
import CirclesInfoBox from './circles-info';
import CircleCreate from './circle-create/tile';
import CircleJoin from './circle-join/tile';
import Circle from './circle/tile';
import CircleEdit from './circle-edit/tile';
import CircleMembers from './circle-members/index';
import MiniCirclesShortcut from './mini-circles-shortcut/tile';
import CirclesProfile from './circles-profile/tile';
import CircleCategories from './system-settings-circle-categories/tile';
import AbuseReportDetails from './circle-abuse-report-details';
import AbuseReportStatuses from './system-settings-circle-abuse-report-statuses/tile';
import AbuseReportHandling from './circle-abuse-report-handling/tile';
import AbuseReports from './circle-abuse-reports/tile';
import CircleJoinRequests from './circle-join-requests/tile';
import CirclesBanned from './circles-banned/tile';

export default {
	'circles-list': CirclesView,
	'circles-info': CirclesInfoBox,
	'circle-create': CircleCreate,
	'circle-join': CircleJoin,
	'circle': Circle,
	'circle-edit': CircleEdit,
	'circle-members': CircleMembers,
	'mini-circles-shortcut': MiniCirclesShortcut,
	'circles-profile': CirclesProfile,
	'system-settings-circle-categories': CircleCategories,
	'circle-abuse-report-details': AbuseReportDetails,
	'system-settings-circle-abuse-report-statuses': AbuseReportStatuses,
	'circle-abuse-report-handling': AbuseReportHandling,
	'circle-abuse-reports': AbuseReports,
	'circle-join-requests': CircleJoinRequests,
	'circles-banned': CirclesBanned
};
