<template>
	<select-input
		v-model="mediaItem"
		:label="label"
		:name="name"
		:no-drop="noDrop"
		:empty-search-dropdown="false"
		:exceeded="exceeded"
		:async="true"
		:loading="loading"
		:options="list"
		:get-option-label="mediaLabel"
		item-label="id"
		:placeholder="$t('Search media library')"
		:required="isRequired"
		:mandatory="isRequired"
		:translate="false"
		@input="$v.mediaItem.$touch()"
		@search="onInput"
	/>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, debounce, constant, join } from 'lodash';
import { MEDIA_SEARCH } from 'store/media/media-search';
import SelectInput from 'components/form/select-input';

export default {
	components: {
		SelectInput
	},
	props: {
		value: {
			type: [Number, String, Object, Array],
			default: ''
		},

		name: {
			type: String,
			default: constant('media')
		},

		label: {
			type: String,
			default () {
				return this.$t('Media');
			}
		},

		mimeTypes: {
			type: Array,
			default: null
		},

		searchLimit: {
			type: Number,
			default: 10
		},
		delay: {
			type: Number,
			default: 500
		},
		isRequired: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		loading: false
	}),

	computed: {
		...mapGetters({
			exceeded: MEDIA_SEARCH.EXCEEDED,
			limit: MEDIA_SEARCH.LIMIT,
			mediaItems: MEDIA_SEARCH.LIST,
			total: MEDIA_SEARCH.TOTAL
		}),

		noDrop: ({ $v, loading }) => !$v.mediaItem.$dirty || loading,

		mediaItem: {
			get: ({ value }) => cloneDeep(value),
			set (newVal) {
				this.$emit('input', newVal);
			}
		},

		list: ({ exceeded, exceededLabel, mediaItems, total }) => {
			const list = mediaItems;

			if (exceeded) {
				list.unshift({
					disabled: true,
					id: 0,
					title: exceededLabel(total - mediaItems.length)
				});
			}
			return list;
		}
	},

	created () {
		this.$store.dispatch(MEDIA_SEARCH.SET_LIMIT, this.searchLimit);
	},

	methods: {
		...mapActions(MEDIA_SEARCH, [
			MEDIA_SEARCH.SET_SEARCH_LIST
		]),

		mediaLabel: (mediaItem) => mediaItem.title,

		exceededLabel (amount) {
			return this.$t('{count} results omitted, try narrowing down search criteria', {
				count: amount
			});
		},

		onInput (search) {
			if (search.length) {
				this.$v.mediaItem.$touch();
				debounce(this.getResults, this.delay)(search);

			} else {
				this.$v.mediaItem.$reset();
				this.$store.dispatch(MEDIA_SEARCH.DELETE_LIST);
			}
		},

		async getResults (search) {
			this.loading = true;
			await this.$store.dispatch(MEDIA_SEARCH.PROVIDE_RESULTS, {
				limit: this.limit,
				deleted: false,
				mimeTypes: join(this.mimeTypes, ','),
				search,
				start: 0
			});
			this.loading = false;
		}
	},

	validations: () => ({
		mediaItem: { required }
	})
};
</script>
