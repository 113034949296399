import t from 'translate';

const card = {
	name: 'system-settings-reports',
	title: t('system-settings.sidebar-button.title.reports'),
	url: 'system/reports'
};

export { card as reports };

