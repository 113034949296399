import { Collection, Model } from 'backbone';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import CWTable from 'components/table/table';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import { clinicianName } from 'service/display-name/display-name';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('Clinicians assigned to this treatment'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_ASSIGNED,
		op: READ
	}],

	tileData: () => ({
		treatment: store.getters.treatment,
		treatmentClinicians: repository.getTreatmentClinicians(store.getters.treatment.id),
		clinicians: new (Collection.extend({ modelId: (attrs) => attrs.administratorId }))
	}),

	loaded: ({ tile }) => {
		tile.limit = tile.config().limit || 10;
		tile.listenTo(tile.clinicians, 'add', (clinician) => {
			tile.treatmentClinicians.create({
				respondentId: store.getters.respondent.respondentId,
				administratorId: `${clinician.get('administratorId')}`
			}, {
				// `Backbone.Collection.create` doesn't return a promise, yeeaaahhh!
				success: () => {
					// eslint-disable-next-line max-len
					cwalert.success(t(`Clinician {clinicianName} has been assigned to treatment {treatmentName}`, {
						clinicianName: clinicianName(clinician.toJSON()),
						treatmentName: tile.treatment.name
					}));
				}
			});
		});

		tile.listenTo(tile.clinicians, 'remove', (clinician) => {
			const relation = tile.treatmentClinicians.findWhere({
				administratorId: `${clinician.get('administratorId')}`
			});
			relation.set('treatment', tile.treatment.id);
			relation.destroy().then(() => {
				// eslint-disable-next-line max-len
				cwalert.success(t(`Clinician {clinicianName} has been revoked from treatment {treatmentName}`, {
					clinicianName: clinicianName(clinician.toJSON()),
					treatmentName: tile.treatment.name
				}));
			});
		});

		tile.clinicians.reset(tile.treatmentClinicians.toJSON());

		tile.renderForm(tile);
		tile.renderTable(tile);
	},

	renderForm: (tile) => {
		const dummyModel = new Model();

		tile.form = formView({
			name: 'respondent-clinicians',
			model: dummyModel,
			preventSave: true,
			loader: false,
			disabled: !can.add(CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_ASSIGNED),

			fields: [{
				key: 'clinicianId',
				type: 'search',
				label: t('Clinician'),
				limit: tile.limit,
				provideResults: (query) => repository.searchClinicians({
					search: query,
					administratorAccountEnabled: true,
					start: 0,
					limit: tile.limit,
					treatment: tile.treatment.id
				}),
				placeholder: t('Type clinician name'),
				itemLabel: (clinician) => clinicianName(clinician.toJSON()),
				onSelectItem (clinician, view) {
					if (tile.clinicians.get(clinician.get('administratorId'))) {
						// eslint-disable-next-line max-len
						cwalert.notice(t(`Clinician {clinicianName} is already assigned to treatment {treatmentName}`, {
							clinicianName: clinicianName(clinician.toJSON()),
							treatmentName: tile.treatment.name
						}));

					} else {
						tile.clinicians.add(clinician);
					}
					view.clear();
				}
			}]
		});
		tile.$el.append(tile.form.$el);
	},

	renderTable: (tile) => {
		const className = [
			'search-results-list__entry',
			'clinicians-list__entry',
			'clinicians-list__clinician-'
		].join(' ');
		const mainClassName = 'search-results-list__entry--main';

		const tableCfg = {
			parent: tile.el,
			collection: tile.clinicians,
			emptyListMessage: t('The treatment has no direct clinicians assigned'),

			columns: [{
				label: t('Full name'),
				css: `${className}full-name ${mainClassName}`,
				render: (name, { item }) => clinicianName(item.toJSON())
			}],
			actions: {}
		};

		if (can.delete(CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_ASSIGNED)) {
			tableCfg.actions['delete'] = (clinician, { cancel }) => {
				confirmation({
					title: t('Remove'),
					icon: 'unlink',
					// eslint-disable-next-line max-len
					message: t(`Are you sure you wish to revoke clinician {clinicianName} from treatment {treatmentName}?`, {
						clinicianName: clinicianName(clinician.toJSON()),
						treatmentName: tile.treatment.name
					}),
					warning: true
				}).then(() => {
					tile.clinicians.remove(clinician);
				}, () => {
					cancel();
				});
			};
		}

		tile.table = new CWTable(tableCfg);
	}
});
