import mixin from '../dialogs/_mixin';
import Vue from 'vue';

export default ({ modalCfg, validations }) => new Vue({
	mixins: [mixin(modalCfg)],

	template: `
		<standard-dialog
			:config="config"
			:processing="processing"
			:vuelidate="$v"
			:warnings="warnings"
		/>
		`,

	validations
});
