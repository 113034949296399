import SingleEntity from '../shared/single-entity';
import urls from './urls';
import types from './element-types';

export default SingleEntity.extend({
	urlRoot: urls.singleElement.url,
	eventNamespace: 'information-package-element',
	defaults: {
		order: 0
	},
	validate (attrs) {
		if (!attrs.informationPackage) {
			const msg = 'Information package element should have property informationPackage';
			return msg;
		}
	},

	getTypeLabel () {
		return types[this.get('type')];
	}
});

