import Marionette from 'marionette';
import tileManager from 'tileManager';
import t from 'translate';
import appTitle from 'service/app-title';
import myProfileTile from 'modules/profile/tiles/my-profile/tile';
import myAttributesTile from 'modules/profile/tiles/my-attributes/tile';
import tiles from '../tiles/tiles';

export default Marionette.Object.extend({
	initialize () {
		appTitle.setAppName(t('main-menu.AdminPortal'));

		tileManager.registerTiles({
			...tiles,
			'my-profile': myProfileTile,
			'my-attributes': myAttributesTile
		});
	}
});
