import Backbone from 'backbone';
import settings from 'system-settings';

const respondentExternal = Backbone.Model.extend({
	defaults: function () {
		return {
			'respondentFirstName': '',
			'respondentLastName': '',
			'respondentUsername': null,
			'respondentPassword': '',
			'respondentPhone': null,
			'respondentEmail': null,
			'respondentGender': 2,
			'respondentBirthdate': null,
			'respondentAddress': '',
			'respondentCommunicationDisabled': false,
			'respondentAccountTest': false,
			'respondentAccountEnabled': true,
			'respondentLanguageId': settings.getInteger('DEFAULT_RESPONDENT_LANGUAGE'),
			'respondentTimezoneName': settings.getString('DEFAULT_RESPONDENT_TIMEZONE')
		};
	},
	idAttribute: 'respondentId',
	url: function () {
		return 'backend-commons/demographics/rest/demographics/';
	}
});

export default respondentExternal;

