import { map } from 'lodash';
import t from 'translate';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import cardUrl from 'cwcardurl';

export default SearchTileView.extend({

	title: t('Plan search'),
	acl: [{
		checkpoint: 'system.workflows.workflows',
		op: 'READ'
	}],

	searchFn: (...args) => repository.searchPlans(...args),

	fields () {
		return [{
			key: 'workflowEnabled',
			type: 'single-select',
			label: t('Status'),

			values: [{
				id: 'true',
				label: t('Enabled')
			}, {
				id: 'false',
				label: t('Disabled')
			}],

			onChange: (value) => {
				this.viewModel.set('workflowEnabled', map(value, (val, key) => {
					if (val === true) {
						return key;
					}
				}).toString());
				this.runQuery();
			}
		}, {
			key: 'workflowAllowManual',
			type: 'single-select',
			label: t('Manual assignment'),

			values: [{
				id: 'true',
				label: t('Allowed')
			}, {
				id: 'false',
				label: t('Disallowed')
			}],

			onChange: (value) => {
				this.viewModel.set('workflowAllowManual', map(value, (val, key) => {
					if (val === true) {
						return key;
					}
				}).toString());
				this.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry plans-list__entry plans-list__plan-';
		const mainClassName = 'search-results-list__entry--main';
		this.triggers = this.cardContext().get('triggers');

		const tableCfg = {
			click (model) {
				cardUrl.openCard('plan', {
					planId: model.getId()
				});
			},
			columns: [{
				label: t('Name'),
				key: 'name',
				css: `${className}name ${mainClassName}`
			}, {
				label: t('Enabled'),
				key: 'enabled',
				css: `${className}enabled`,
				render: (enabled) => enabled ? t('yes') : t('no')
			}, {
				label: t('Manual'),
				key: 'allowManual',
				css: `${className}manual`,
				render: (manual) => manual ? t('yes') : t('no')
			}, {
				label: t('Trigger'),
				key: 'workflowTrigger',
				css: `${className}trigger`,
				render: (triggerId) => {
					if (!triggerId) {
						return '';
					}
					return this.triggers.get(triggerId).get('workflowTriggerName');
				}
			}]
		};

		return tableCfg;
	}
});

