import { param } from 'jquery';

const urlPrefix = `backend-commons/consent`;
const urlPrefixClinician = `backend-commons/administrator`;

const activateConsent = ({ consentId }) => `${urlPrefix}/${consentId}/activate/`;

const consents = () => `${urlPrefix}/`;

const consent = ({ consentId } = { consentId: '' }) => `${urlPrefix}/${consentId}`;

const searchConsent = ({ filters }) => `${urlPrefix}/?${decodeURIComponent(param(filters))}`;

const consentCopy = ({ consentId, version } = { consentId: '' }) =>
	`${urlPrefix}/${consentId}?version=${version}`;

const consentVersions = ({ consentId } = { consentId: '' }) => `${urlPrefix}/${consentId}/version/`;

const publishConsent = ({ consentId }) => `${urlPrefix}/${consentId}/publish/`;

const consentOptions = ({ consentId }) => `${urlPrefix}/${consentId}/option/`;

const consentOption = ({ consentId, optionId }) => `${urlPrefix}/${consentId}/option/${optionId}`;

const administratorGroups = ({ consentId }) => `${urlPrefix}/${consentId}/administrator-group/`;

const administratorGroup = ({ consentId, administratorGroupId }) =>
	`${urlPrefix}/${consentId}/administrator-group/${administratorGroupId}`;

const treatmentTypes = ({ consentId }) => `${urlPrefix}/${consentId}/treatment-type/`;

const treatmentType = ({ consentId, treatmentTypeId }) =>
	`${urlPrefix}/${consentId}/treatment-type/${treatmentTypeId}`;

const specificConsentVersion = ({ consentId, versionNumber }) =>
	`${urlPrefix}/${consentId}/version/${versionNumber}`;

const restoreConsent = ({ consentId, versionNumber }) =>
	`${urlPrefix}/${consentId}/version/${versionNumber}/restore/`;

const consentInstanceUrl = `backend-commons/consent-instance`;

const consentInstance = () => `${consentInstanceUrl}/`;

const treatmentConsents = (treatmentId) =>
	`backend-commons/treatment/${treatmentId}/consent-instance/`;

const respondentConsents = (respondentId) =>
	`backend-commons/respondent/${respondentId}/consent-instance/`;

const clinicianConsentInstance = (consentInstanceId) =>
	`backend-commons/consent-instance/${consentInstanceId}`;

const consentInstanceOptions = (consentInstanceId) =>
	`backend-commons/consent-instance/${consentInstanceId}/options/`;

const saveConsentInstance = (consentInstanceId) =>
	`backend-commons/consent-instance/${consentInstanceId}/submit/`;

const newlyModifiedConsents = ({ clinicianId, value }) =>
	`${urlPrefixClinician}/${clinicianId}/newly-modified-consent-instance/?search=${value}&`;

const newlyModifiedConsentsCount = ({ clinicianId }) =>
	`${urlPrefixClinician}/${clinicianId}/newly-modified-consent-instance/count/`;

export {
	activateConsent,
	administratorGroup,
	administratorGroups,
	consent,
	consentCopy,
	consentInstance,
	consentInstanceOptions,
	consentOption,
	consentOptions,
	consents,
	consentVersions,
	clinicianConsentInstance,
	newlyModifiedConsents,
	newlyModifiedConsentsCount,
	publishConsent,
	respondentConsents,
	restoreConsent,
	saveConsentInstance,
	searchConsent,
	specificConsentVersion,
	treatmentConsents,
	treatmentType,
	treatmentTypes
};
