<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="consent__container">
		<div class="consent-header">
			<h2>{{consentInstance.name}}</h2>
		</div>
		<div v-html="consentInstance.content" />

		<ul
			v-for="option in options"
			:key="option.id"
			class="consent-option__list"
		>
			<li>
				<p
					class="consent-option__content"
					v-html="option.content"
				/>

				<div class="consent-option-statement">
					<icon
						:name="iconName(option.status)"
						:class="['statement__answer', className(option.status)]"
					/>
					<span
						class="consent-option-statement__content"
					>"{{option.statement}}"</span>
				</div>

				<div v-if="option.answers.length > 1">
					<span
						v-for="answer in answers(option)"
						:key="answer.respondent.id"
						:class="['answer-respondent', respondentClass(answer)]"
					>
						<icon name="check" :small="true" />
						<icon name="times" :small="true" />
						<icon name="minus" :small="true" />

						<template v-if="submittedByClinician(answer)">
							<span v-translate>consents.clinicianOnBehalf</span>
							{{answer.respondent.displayName}}
							<span v-if="!previousAnswer(answer)">
								({{answer.respondentRoleName}})
							</span>
						</template>

						<template v-else>
							{{answer.respondent.displayName}}
							<span v-if="!previousAnswer(answer)">
								({{answer.respondentRoleName}})
							</span>
						</template>
					</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { get, isNull, reject } from 'lodash';
import { GIVEN, NOT_GIVEN, NOT_SUBMITTED } from '../../shared/statuses';

export default {
	title: '',
	acl: [],
	instant: true,
	actions: ({ $store }) => {
		if ($store.getters['consentInstance/consentInstance']) {
			return [];
		}

		return [
			['consentInstance/init', {
				consentInstanceId: get($store.getters.urlParams, 'consentInstanceId')
			}],
			['consentInstance/initOptions', {
				consentInstanceId: get($store.getters.urlParams, 'consentInstanceId')
			}]
		];
	},

	computed: {
		consentInstance: ({ $store }) => $store.getters['consentInstance/consentInstance'],
		options: ({ $store }) => $store.getters['consentInstance/options']
	},

	methods: {
		iconName: (status) => ({
			[GIVEN]: 'check-circle',
			[NOT_GIVEN]: 'times-circle',
			[NOT_SUBMITTED]: 'minus-circle'
		}[status] || null),

		className: (status) => ({
			[GIVEN]: 'statement__answer--given',
			[NOT_GIVEN]: 'statement__answer--not-given',
			[NOT_SUBMITTED]: 'statement__answer--not-submitted'
		}[status] || null),

		answerStatus: (answer) => {
			if (isNull(answer.answer)) {
				return 'answer-respondent--not-submitted';
			}
			return answer.answer ?
				'answer-respondent--given' :
				'answer-respondent--not-given';
		},

		inRole: (answer) => answer.isPreviousRespondent ? 'answer-respondent--previous' : '',
		respondentClass (answer) {
			return [this.answerStatus(answer), this.inRole(answer)];
		},
		submittedByClinician: (answer) => answer.submittedByAdministrator,
		previousAnswer: (answer) => answer.isPreviousRespondent,
		answers: (option) => reject(option.answers, (answer) => isNull(answer.answer))
	}
};
</script>
