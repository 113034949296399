<template>
	<section :class="classPrefix">
		<div :class="previewClassName">
			<sidebar-item :item="item" :type="type" :clickable="clickable" />
		</div>
		<form @submit.prevent="save">
			<text-input
				v-model="item.label"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="Label"
			/>
			<checkbox-input
				v-model="item.translate"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="Translate label"
			/>
			<select-input
				v-model="item.icon"
				:options="icons"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="Icon"
			/>
			<div class="radio-input__field-container">
				<radio-input
					v-model="item.linkType"
					:class-prefix="classPrefix"
					:disabled="!canSubmit"
					value-name="card"
					label="Target card"
				/>
				<radio-input
					v-model="item.linkType"
					:class-prefix="classPrefix"
					:disabled="!canSubmit"
					value-name="url"
					label="Target URL"
				/>
				<radio-input
					v-model="item.linkType"
					:class-prefix="classPrefix"
					:disabled="!canSubmit"
					:value-name="false"
					label="No target"
				/>
			</div>
			<select-input
				v-if="item.linkType === 'card'"
				v-model="item.target"
				:options="cards"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="Card"
			/>
			<text-input
				v-if="item.linkType === 'url'"
				v-model="item.target"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="URL"
			/>
			<checkbox-input
				v-if="item.linkType === 'url'"
				v-model="item.openInNewWindow"
				:class-prefix="classPrefix"
				:disabled="!canSubmit"
				label="Open URL in new window"
			/>
			<select-input
				v-model="item.counter"
				:class-prefix="classPrefix"
				:options="counterNames"
				:searchable="false"
				label="Counter"
				:get-option-label="counterLabel"
				:disabled="!canSubmit"
			/>
			<select-input
				v-model="item.size"
				:class-prefix="classPrefix"
				:options="sizes"
				:searchable="false"
				:disabled="!canSubmit"
				label="Size"
			/>
			<select-input
				v-model="item.style"
				:class-prefix="classPrefix"
				:options="styles"
				:searchable="false"
				:disabled="!canSubmit"
				label="Style"
			/>
			<select-input
				v-model="item.acl.checkpoint"
				:class-prefix="classPrefix"
				:options="checkpoints"
				:get-option-label="checkpointLabel"
				:disabled="!canSubmit"
				label="Additional ACL key"
			/>
			<select-input
				v-if="item.acl.checkpoint"
				v-model="item.acl.op"
				:class-prefix="classPrefix"
				:options="accessLevels"
				:searchable="false"
				:disabled="!canSubmit"
				label="ACL Level"
			/>
			<button-group :class-prefix="classPrefix">
				<submit-button
					label="Save"
					:can-submit="canSubmit"
					:class-prefix="classPrefix"
				/>
				<plain-button
					label="Save and close"
					:can-cancel="canSubmit"
					:class-prefix="classPrefix"
					:on-click="saveAndClose"
				/>
				<plain-button
					:on-click="onCancel"
					:class-prefix="classPrefix"
					label="Reset"
				/>
			</button-group>
		</form>
	</section>
</template>

<script>
import SidebarItem from 'core/engine/sidebar/components/sidebar-item';
import { cloneDeep, forEach, invoke, isObject, keys, pick } from 'lodash';
import t from 'service/lang/translate';
import counterDefinitions from 'core/engine/sidebar/counters';
import can from 'acl-can';
import icons from 'components/icon/icon-mapping-default';
import cwalert from 'cwalert';
import {
	NONE, READ, ADD, EDIT, DELETE, SUPERVISOR, ADMINISTRATOR
} from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	title: t('Edit menu item'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.SETTINGS,
		op: READ
	}],
	actions: ({ $store }) => {
		const { type, variant } = $store.getters.urlParams;

		return [
			[`engine/${type}Sidebar/init`, { variant }],
			`engine/${type}Sidebar/initCards`,
			`accessRoles/init`
		];
	},
	components: { SidebarItem },
	data () {
		return {
			...pick(this.$store.getters.urlParams, ['type', 'index']),
			counters: counterDefinitions[this.$store.getters.urlParams.type],
			classPrefix: 'edit-menu-item',
			sizes: ['default', 'smaller', 'bigger'],
			styles: ['default', 'highlighted', 'profile'],
			accessLevels: [NONE, READ, ADD, EDIT, DELETE, SUPERVISOR, ADMINISTRATOR]
		};
	},

	computed: {
		previewClassName: ({ classPrefix }) => `${classPrefix}__preview`,
		canSubmit: () => can.edit('system.settings.settings'),
		item: {
			get: (
				{ $store, index, type }
			) => $store.getters[`engine/${type}Sidebar/currentMenuItems`][index],
			set: (item) => item
		},
		cards: ({ $store, type }) => $store.getters[`engine/${type}Sidebar/cardNames`],
		checkpoints: ({ $store }) => $store.getters['accessRoles/names'],
		icons: () => keys(icons).sort(),
		counterNames: ({ counters }) => keys(counters).sort(),
		clickable: ({ type }) => type === 'clinician'
	},

	watch: {
		'item.linkType' () {
			this.item.target = '';
			this.item.openInNewWindow = false;
		}
	},
	created () {
		this.$data.cachedItem = cloneDeep(this.item);
	},

	methods: {
		checkpointLabel (checkpoint) {
			return t(`acl.checkpoints.${checkpoint}`);
		},

		counterLabel (counter) {
			return invoke(this.counters[counter], 'label');
		},

		onCancel () {
			forEach(keys(this.item), (key) => {
				if (isObject(this.item[key])) {
					this.item[key] = { ...this.$data.cachedItem[key] };

				} else {
					this.item[key] = this.$data.cachedItem[key];
				}
			});
		},

		save () {
			return this.$store.dispatch(`engine/${this.type}Sidebar/update`, this.item).then(() => {
				cwalert.success(t('Menu item has been saved'));
			});
		},

		saveAndClose () {
			this.save().then(() => {
				this.tile.card().close();
			});
		}
	}
};
</script>
