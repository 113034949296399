import MultipleEntities from '../shared/multiple-entities';
import RoleCheckpointGroup from './access-role-checkpoint-group';
import urls from './urls';

/**
 * RoleCheckpointGroups Entity
 * @class RoleCheckpointGroups
 * @extends MultipleEntities
 */
const RoleCheckpointGroups = MultipleEntities.extend({
	model: RoleCheckpointGroup,
	urls,
	eventNamespace: 'role-checkpoint-group',
	idName: 'relationId',

	canAdd: function (model) {
		return +model.get('roleId') === +this.ownerId;
	},

	/**
	 * Get all RoleCheckpointGroups for the given roleId
	 * @method getForRole
	 * @param {Number|String} roleId
	 * @return {Object} new RoleCheckpointGroups Collection
	 * @example
	 *        Repository.RoleCheckpointGroups.getForRole(3);
	 */
	getForRole: function (roleId) {
		return this.retrieve('checkpointGroupsInRole', roleId);
	}

});

export default new RoleCheckpointGroups();
