import Backbone from 'backbone';

export default Backbone.Model.extend({
	idAttribute: 'uid',

	url: function () {
		return !this.isNew() ?
		'backend-commons/assessment/rest/package/' + this.get(this.idAttribute) :
			'backend-commons/assessment/rest/package/';
	}
});
