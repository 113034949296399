import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import datetime from 'datetime';
import appContext from 'app-context';
import store from 'store';
import { clinicianName } from 'service/display-name/display-name';

const adjustDate = (value) => datetime(value)
	.setTimezone(store.getters.respondent.respondentTimezoneName)
	.toMoment()
	.format(t('date.formats.dateTime'));

export default TileView.extend({
	title: t('Flag properties'),
	acl: [{
		checkpoint: 'administrator.respondents.flags',
		op: 'READ'
	}],

	actions: ['flag/init'],

	loaded () {
		this.flag = store.state.flag.model;

		if (!this.flag.get('isSeen')) {
			this.flag.markAsSeen().done(() => {
				appContext.trigger('flag.update', this.flag);
			});
		}
		let fields = [{
			key: 'assessment',
			type: 'paragraph',
			label: t('Assessment'),
			transform: (value) => value.assessmentName
		}, {
			key: 'assessmentFlag',
			type: 'paragraph',
			label: t('Name'),
			transform: (value) => value.assessmentFlagName
		}, {
			key: 'created',
			type: 'paragraph',
			label: t('Created'),
			transform: (value) => adjustDate(value)
		}];

		if (!this.flag.get('isRaised')) {
			fields = fields.concat([{
				key: 'unraiseReasonName',
				type: 'paragraph',
				label: t('Unraise reason')
			}, {
				key: 'unraiseAuthor',
				type: 'paragraph',
				label: t('Unraise author'),
				transform: (value) => clinicianName(value)
			}, {
				key: 'unraiseDate',
				type: 'paragraph',
				label: t('Unraise date'),
				transform: (value) => adjustDate(value)
			}, {
				key: 'unraiseComment',
				type: 'paragraph',
				label: t('Unraise comment'),
				transform: (value) => value || ''
			}]);
		}

		this.form = formView({
			name: 'flag-properties',
			model: this.flag,
			fields
		});
		this.$el.html(this.form.$el);
	}
});
