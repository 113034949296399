import $ from 'jquery';
import { View } from 'backbone';
import t from 'translate';
import appUrl from 'service/app-url/app-url';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN, SYSTEM } from 'service/acl/checkpoints';

export default View.extend({
	className: 'plan-instance-screenshot__content',
	title: t('plan-properties.screenshot-title'),
	acl: {
		op: 'or',
		checkpoints: [{
			op: READ,
			checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS
		}, {
			op: READ,
			checkpoint: SYSTEM.PLANS.PLANS
		}]
	},

	render () {
		const cardContext = this.cardContext();
		let imageUrl;

		if (cardContext.get('planInstance')) {
			imageUrl = cardContext.get('planInstance').get('image');

		} else if (cardContext.get('plan')) {
			imageUrl = cardContext.get('plan').get('details').workflowImage;
		}

		if (imageUrl && imageUrl.length) {
			$('<img />').attr('src', `${appUrl(imageUrl)}?_=${Date.now()}`).appendTo(this.el);
		}
	}
});

