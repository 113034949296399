import TileView from 'tile-view';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import t from 'translate';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { SYSTEM } from 'service/acl/checkpoints';
import { READ } from 'service/acl/access-levels';

const getId = (tile) => tile.cardContext().get('relationTypeId');

export default TileView.extend({
	title: t('Loading\u2026'),
	actions: (tile) => {
		const id = tile.cardContext().get('relationTypeId');
		return [
			['relationTypes/getSingleRelationType', id]
		];
	},
	acl: [{
		checkpoint: SYSTEM.SETTINGS.RELATION_TYPES,
		op: READ
	}],

	loaded: ({ tile }) => {
		const id = tile.cardContext().get('relationTypeId');
		const title = id === '0' ? t('New relation type') : t('Relation type`s details');
		tile.setTitle(title);
	},

	Vue: (tile) => ({
		data: {
			action: getId(tile) === '0' ? 'addRelation' : 'updateRelation',
			ageLimit: true,
			defaultOption: null,
			id: getId(tile),
			submitted: false,
			successMsg: getId(tile) === '0' ?
				'Relation has been saved' :
				'Relation has been updated',
			updating: false
		},
		computed: {
			...mapGetters({ data: 'relationTypes/relation' }),
			...mapGetters({ roleRelations: 'relationTypes/roleRelations' }),
			allowUpdate () {
				return can.edit(SYSTEM.SETTINGS.RELATION_TYPES) && !this.$v.data.$invalid &&
					!this.updating;
			},
			consentLabel () {
				return t('Respondent 1') + t('authorisation') + t('Respondent 2');
			},
			currentOption () {
				return !this.data.relationType ? this.roleRelations[0] : this.data.relationType;
			},
			instructionLabelEnd () {
				const child = this.data.respondent2RoleName;
				return t('{child} permanently', { child });
			},
			instructionLabelStart () {
				return t('is authorised to give consent on behalf of the');
			},
			loading () {

				if (this.id === '0') {
					return false;
				}
				return !this.data.name;
			},
			submitButton () {
				return this.action === 'addRelation' ? t('Save') : t('Update');
			},
			typeEqual () {
				return this.data.relationType.value === 'EQUAL';
			}
		},
		methods: {
			...mapActions('relationTypes', ['addRelation', 'updateRelation']),
			instructionStart (respondent) {
				return t('{respondent} is the', { respondent });
			},
			instructionEnd (respondent) {
				return t('of {respondent}', { respondent });
			},
			invalidName () {
				return this.submitted && this.$v.data.name.$invalid;
			},
			invalidRespondent1RoleName () {
				return this.submitted && this.$v.data.respondent1RoleName.$invalid;
			},
			invalidRespondent2RoleName () {
				return this.submitted && this.$v.data.respondent2RoleName.$invalid;
			},
			invalidRole () {
				return this.submitted && this.$v.data.relationType.$invalid;
			},
			onSubmit () {
				this.submitted = true;

				if (this.$v.data.$invalid) {
					cwalert.failure(t('Fill in the required fields'));
					return false;
				}
				const currentData = { ...this.data, relationType: this.data.relationType.value };
				this.updating = true;
				this[this.action](currentData).then(() => {
					cwalert.success(t(this.successMsg));

					if (this.action === 'addRelation') {
						cardUrl.openCard('system/relation-types');
					} else {
						this.updating = false;
					}
				});
			},
			updateOption (e) {
				this.data.relationType = e.target.value;
			}
		},
		watch: {
			'data.relationType' (newVal) {
				if (newVal.value === 'EQUAL') {
					this.data.substituted = false;
				}
			},
			'data.substituted' (newValue) {
				if (!newValue) {
					this.data.permanent = false;
				}
			}
		},
		template:
		`<div>
			<div
				v-if="loading"
				v-loader-spinner class="relation-types--loading"
			></div>
			<form @submit.prevent="onSubmit">
				<fieldset class="relation-types__container">
					<text-input
						v-model="data.name"
						class-prefix="relation-types"
						label="Name"
						:disabled="updating"
						:invalid="invalidName()"
						:mandatory="true"
						:required="true"
					/>
				</fieldset>
				<fieldset class="relation-types__container">
					<legend class="relation-types__header tile__title" v-translate>Rules</legend>
					<div class="relation-types__row-lowered">
						<text-input
							v-model="data.respondent1RoleName"
							class-additional="rules-left"
							class-prefix="relation-types"
							label="Respondent 1"
							:disabled="updating"
							:invalid="invalidRespondent1RoleName()"
							:mandatory="true"
							:required="true"
						/>
						<span class="relation-types__icon-container">
							<icon name="switch-circle" />
						</span>
						<select-input
							v-model="data.relationType"
							containerClass="select-container"
							class-prefix="relation-types"
							label=""
							:disabled="updating"
							:invalid="invalidRole()"
							:options="roleRelations"
							:searchable="false"
							:showLabel="false"
						/>
						<text-input
							v-model="data.respondent2RoleName"
							class-additional="rules-right"
							class-prefix="relation-types"
							label="Respondent 2"
							:disabled="updating"
							:invalid="invalidRespondent2RoleName()"
							:mandatory="true"
							:required="true"
						/>
					</div>
					<div class="relation-types__checkbox-row">
						<checkbox-input
							v-model="data.substituted"
							class-prefix="relation-types"
							inputClass="checkbox-input"
							textClass="checkbox-text"
							:disabled="updating || typeEqual"
							:label="consentLabel"
							:translate="false"
						/>
						<checkbox-input
							v-model="data.permanent"
							class-prefix="relation-types"
							label="permanency"
							inputClass="checkbox-input"
							textClass="checkbox-text"
							:disabled="updating || !data.substituted || typeEqual"
						/>
					</div>
					<div v-if="data.respondent1RoleName && data.respondent2RoleName"
						class="relation-types__instruction-container relation-types__label">
						<p v-translate>This means</p>
						<p class="relation-types__instruction-row">
							{{ instructionStart('Respondent 1') }}
							<strong>{{ data.respondent1RoleName }}</strong>
							{{ instructionEnd('Respondent 2') }}
						</p>
						<p class="relation-types__instruction-row">
							{{ instructionStart('Respondent 2') }}
							<strong>{{ data.respondent2RoleName }}</strong>
							{{ instructionEnd('Respondent 1') }}
						</p>
						<p v-if="data.permanent"
							class="relation-types__instruction-row">
							<span v-translate>The</span>
							<strong>{{ this.data.respondent1RoleName }}</strong>
							{{ instructionLabelStart }}
							<strong>{{ instructionLabelEnd }}</strong>
						</p>
					</div>
				</fieldset>
				<section class="relation-types__container">
					<h1 class="relation-types__header tile__title" v-translate>Description</h1>
					<textarea
						v-model="data.description"
						:disabled="updating"
						class="form-view__input form-view__textarea"
						rows="5"></textarea>
				</section>
				<section class="relation-types__save-container">
					<submit-button
						:label="submitButton"
						:can-submit="allowUpdate"
						class-prefix="relation-types"
					/>
				</section>
			</form>
		</div>`,
		validations: {
			data: {
				name: { required },
				relationType: { required },
				respondent1RoleName: { required },
				respondent2RoleName: { required }
			}
		}
	})
});
