<template>
	<table class="table">
		<thead>
			<tr>
				<th scope="col">
					{{t('Group name')}}
				</th>
				<th scope="col">
					{{t('Group category')}}
				</th>
				<th scope="col">
					{{t('Group is common')}}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="group in respondentGroups"
				:key="group.id"
				class="clickable-row"
				tabindex="0"
				@click.stop="edit(group)"
				@keyup.prevent.enter.space="edit(group)"
			>
				<td>
					<p class="table-cell-label">
						{{t('Group name')}}
					</p>
					{{group.name}}
				</td>
				<td>
					<p class="table-cell-label">
						{{t('Group category')}}
					</p>
					{{categoryLabel(group)}}
				</td>
				<td>
					<p class="table-cell-label">
						{{t('Group is common')}}
					</p>
					{{commonLabel(group)}}
				</td>
				<td>
					<p class="table-cell-label">
						{{t('Actions')}}
					</p>
					<button
						v-if="canRemove"
						type="button"
						class="delete-button__action icon icon--delete icon-2x"
						tabindex="0"
						@click.stop="remove(group)"
					/>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import can from 'service/acl/acl-can';
import t from 'service/lang/translate';
import { EMPTY_CHAR } from 'lib/chars';
import editDialog from '../../dialogs/edit-respondent-group';
import confirmation from 'components/confirmation/confirmation';
import { escape } from 'lodash';
import cwalert from 'service/cwalert';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';

export default {
	title: t('system-manager.RespondentGroups'),
	actions: ['respondentGroups/initAll'],
	acl: [{
		checkpoint: SYSTEM.SETTINGS.RESPONDENTGROUPS,
		op: READ
	}],

	data: () => ({
		canRemove: can.remove(SYSTEM.SETTINGS.RESPONDENTGROUPS)
	}),

	computed: {
		respondentGroups: ({ $store }) => $store.getters['respondentGroups/all']
	},

	methods: {
		commonLabel: (group) => group.common ? t('Yes') : EMPTY_CHAR,

		categoryLabel: (group) => group.category || EMPTY_CHAR,

		edit (group) {
			group.category = group.category || '';
			group.common = group.common || false;
			editDialog(group);
		},

		remove (group) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove respondent group'),
				message: t(`Are you sure you want to remove {group}?`, {
					group: escape(group.name)
				})
			}).done(() => {
				this.$store.dispatch('respondentGroups/remove', group.id).then(() => {
					cwalert.success(t(
						'Respondent group {group} has been removed',
						{ group: group.name }
					));
				}, () => {
					cwalert.warning(t(
						'Respondent group {group} can\'t be removed',
						{ group: group.name }
					));
				});
			});
		}
	}
};
</script>
