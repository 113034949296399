import TileView from 'tile-view';
import t from 'translate';
import DataMapping from './data-integration-data-mapping';
import store from 'store';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	instant: true,
	title: t('dataIntegration.dataMapping.name'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
		op: READ
	}],
	actions: () => {
		const actions = [['dataIntegration/codes/searchEnabledCodes']];

		if (!store.getters['dataIntegration/assessmentList'].length) {
			actions.push(['dataIntegration/getAssessments']);
		}
		return actions;
	},
	vueComponent: () => ({ DataMapping })
});
