/**
 * @class Repository
 */
export default {

	/**
	 * @method getFile
	 * @param {String|Number} id
	 * @return {Object} new {{#crossLink "{File"}}{{/crossLink}} Model
	 * @example
	 *        Repository.getFile(3);
	 */
	getFile: function (id) {
		return this.Files.getById(id);
	}
};
