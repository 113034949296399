import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import store from 'store';

export default CounterMiniTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.flags',
		op: 'READ'
	}],
	caption: t('Flag(s)'),
	actions: () => ['flags/init'],
	count: () => store.getters['flags/raised'].length,
	total: () => store.getters['flags/size'],
	targetCard: () => ['respondent-flags', {
		respondentId: store.getters['respondent/respondentId']
	}]
});

