import t from 'translate';
import settings from 'system-settings';
import store from 'store';

const card = {
	title: t('New message'),
	name: 'respondent-new-message',
	url: 'respondent/:respondentId/treatment/:treatmentId/messages/new',

	ctrl: ({ cardData, close }, { treatmentId }) => {
		if (!settings.getBoolean('ENABLE_MESSAGING_SYSTEM')) {
			close();
			return;
		}
		cardData.set({ isNew: true });
		return store.dispatch('treatment/init', { treatmentId })
			.then(() => store.dispatch('messages/initDrafts'));
	}
};

export { card as NewMessage };
