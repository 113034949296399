<template>
	<section class="treatment-label__content">
		<ul class="treatment-label__container">
			<treatment-label-item label="Respondent">
				<user-link :respondent="respondent" />
			</treatment-label-item>

			<treatment-label-item label="Treatment type">
				{{get(treatment, 'treatmentType.name', '')}}
			</treatment-label-item>

			<treatment-label-item label="Treatment name">
				{{get(treatment, 'name', '')}}
			</treatment-label-item>

			<treatment-label-item v-if="canSeeStatus" label="Status">
				{{get(treatment, 'customStatus', '')}}
			</treatment-label-item>

			<treatment-label-item label="Enabled">
				{{booleanLabel('enabled')}}
			</treatment-label-item>

			<treatment-label-item label="Start date">
				{{date('startAt')}}
			</treatment-label-item>

			<treatment-label-item label="End date">
				{{date('endAt')}}
			</treatment-label-item>

			<treatment-label-item label="Is complete">
				{{booleanLabel('complete')}}
			</treatment-label-item>
		</ul>
		<timezone-warning v-if="respondent" :respondent="respondent" />
	</section>
</template>

<script>
import t from 'translate';
import can from 'acl-can';
import datetime from 'datetime';
import systemSettings from 'system-settings';
import TimezoneWarning from 'components/timezone-warning';
import { UserLink } from 'components/user-link/user-link';
import TreatmentLabelItem from './treatment-label-item';
import { get } from 'lodash';

export default {
	components: {
		TimezoneWarning,
		UserLink,
		TreatmentLabelItem
	},
	computed: {
		respondent: ({ $store }) => $store.getters['respondent'],
		timezone: ({ $store }) => $store.getters['respondent/timezone'],
		treatment: ({ $store }) => $store.getters['treatment'],
		canSeeStatus: () => systemSettings.getBoolean('ENABLE_TREATMENT_STATUS') &&
			can.read('administrator.respondents.treatment-status')
	},
	methods: {
		booleanLabel (property) {
			return get(this.treatment, property) ? t('Yes') : t('No');
		},
		date (field) {
			return get(this.treatment, field) && this.timezone ?
				datetime(this.treatment[field])
					.setTimezone(this.timezone)
					.toMoment()
					.format(t('date.formats.dateTime')) :
				'';
		}
	}
};
</script>
