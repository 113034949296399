'use strict';

import t from 'translate';

const card = {
	name: 'decisions-list',
	url: 'decisions',
	title: t('Decisions')
};

export {card as DecisionsList};
