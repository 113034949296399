import t from 'translate';
import store from 'store';
import appBar from 'app-bar';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import can from 'acl-can';
import importJsonConfig from '../dialogs/import-json-config';

const card = {
	name: 'edit-tile',
	url: 'card/:type/:cardName?variant=:variant/:tileIndex/:tileName/edit',
	title: t('Edit tile'),

	ctrl: ({ close, setLoaded, setLoading }, { type, cardName, variant, tileIndex, tileName }) =>
		store.dispatch('cards/init', { type })
		.then(() => store.dispatch('cards/setCurrentCard', cardName))
		.then(() => store.dispatch('cards/setVariant', variant))
		.then(() => store.dispatch('cards/setCurrentTile', { tileName, tileIndex }))
		.then(() => {
			if (can.edit('system.settings.settings')) {
				appBar.addButton(card.name, {
					title: t('Import JSON configuration'),
					icon: 'import',
					click: () => {
						importJsonConfig();
					}
				});
			}

			if (can.remove('system.settings.settings')) {
				appBar.addButton(card.name, {
					title: t('Remove tile'),
					icon: 'delete',
					click: () => {
						confirmation({
							icon: 'delete',
							warning: true,
							title: t('Remove tile'),
							message: t('Are you sure?')
						}).then(() => {
							setLoading();
							return store.dispatch('cards/removeTile');
						}).then(() => {
							close();
							cwalert.success(t('Tile {tileName} has been removed', { tileName }));
							setLoaded();
						});
					}
				});
			}
		})
};

export { card as EditTile };
