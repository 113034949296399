import appBar from 'app-bar';
import t from 'translate';
import can from 'acl-can';

const card = {
	name: 'system-settings-clinician-attributes',
	title: t('System Settings - Clinician attributes'),
	url: 'system/clinician/attributes',

	controller () {
		this.populate();

		if (can.add('system.settings.administratorattributes')) {
			appBar.addCardLink(card.name, {
				card: 'system-settings-clinician-attributes-add',
				title: t('Add clinician attribute'),
				icon: 'add'
			});
		}
	}
};

export { card as clinicianAttributes };
