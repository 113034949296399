import appBar from 'app-bar';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import { get } from 'lodash';

const consentACL = 'system.settings.consents';
const canActivate = (status) => status === 'DEACTIVATED' && can.edit(consentACL);
const canPublish = (status) => status !== 'PUBLISHED' && can.edit(consentACL);

const addButton = ({ allowed, icon, message, title, action }) => {
	if (allowed) {
		appBar.addButton({
			sourceCard: 'edit-consent',
			card: 'consents',
			icon,
			title: t(title),
			click: () => {
				confirmation({
					icon,
					warning: true,
					title: t(title),
					message: t(message)
				}).done(() => {
					action();
				});
			}
		});
	}
};

const card = {
	title: t('Edit consent'),
	name: 'edit-consent',
	url: 'consent-template/:consentId/edit',

	ctrl: ({ populate }, { consentId }) => {
		store.subscribe((mutation, state) => {
			if (mutation.type === 'consent/set') {
				appBar.clear(card.name);
				const status = get(state.consent, 'data.status');
				appBar.addCardLink({
					sourceCard: card.name,
					card: 'consent-preview',
					icon: 'show',
					title: t('Preview consent'),
					query: { consentId }
				});

				if (canActivate(status)) {
					addButton({
						allowed: true,
						icon: 'arrow-down-circle',
						message: 'Are you sure you want to activate this consent?',
						title: 'Activate consent',
						action: () => {
							store.dispatch('consent/activateConsent', consentId).then(() => {
								cwalert.success(t('Consent has been activated'));
								cardUrl.openCard('consents');
							});
						}
					});
				} else {
					addButton({
						allowed: canPublish(status),
						icon: 'arrow-up-circle',
						message: 'Are you sure you want to publish this consent?',
						title: 'Publish consent',
						action: () => {
							if (store.getters['consent/options'].length) {
								store.dispatch('consent/publishConsent', consentId)
									.then(() => {
										cwalert.success(t('Consent has been published'));
										cardUrl.openCard('consents');
									});
							} else {
								cwalert.error(t('Consent not published'));
							}
						}
					});
					const phrases = status === 'DRAFT' ?
						{
							message: 'Are you sure you want to delete this consent?',
							success: 'Consent has been deleted',
							title: 'Delete consent'
						} :
						{
							message: 'Are you sure you want to deactivate this consent?',
							success: 'Consent has been deactivated',
							title: 'Deactivate consent'
						};

					addButton({
						allowed: can.delete(consentACL),
						icon: 'delete',
						message: phrases.message,
						title: phrases.title,
						action: () => {
							store.dispatch('consent/removeConsent', consentId).then(() => {
								cwalert.success(t(phrases.success));
								cardUrl.openCard('consents');
							});
						}
					});
				}
			}

		});
		populate();
	}
};

export { card as editConsent };
