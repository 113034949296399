import TileView from 'tile-view';
import Table from 'components/table/table';
import cardurl from 'cwcardurl';
import datetime from 'datetime';
import t from 'translate';
import store from 'store';

export default TileView.extend({
	title: t('Plans'),
	acl: [{
		checkpoint: 'administrator.respondents.workflowplans',
		op: 'READ'
	}, {
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],

	tileData: () => ({
		plans: store.state.planInstances.collection,
		respondent: store.getters.respondent
	}),

	loaded: ({ tile }) => {
		tile.table = new Table({
			collection: tile.plans,
			emptyListMessage: t('No plans'),
			click: (item) => {
				cardurl.openCard('plan-properties', { planInstanceId: item.getId() });
			},
			parent: tile.el,
			sortable: true,
			sortOrder: tile.config().tableSort,
			columns: [{
				key: 'name',
				label: t('Plan name')
			}, {
				key: 'workflowInstanceStartDate',
				label: t('Start date'),
				render: (startDate) => datetime(startDate)
					.setTimezone(tile.respondent.respondentTimezoneName)
					.toMoment()
					.format(t('date.formats.dateTime'))
			}, {
				key: 'status',
				label: t('Status'),
				render: (status) => t(`workflow__properties__status__${status}`)
			}]
		});
	}
});

