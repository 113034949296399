<template>
	<div v-if="card" class="setup-card">
		<section class="setup-card__label">
			<h3 class="setup-card__subheading">
				{{t('Portal')}}
			</h3>
			<h2 class="setup-card__heading">
				{{t(portalName)}}
			</h2>
			<h3 v-translate class="setup-card__subheading">
				Card
			</h3>
			<h2 class="setup-card__heading">
				{{card.name}}
			</h2>
			<h3 class="setup-card__subheading">
				{{t('Features')}}
			</h3>
			<h2 class="setup-card__heading">
				<span
					v-for="feature in card.features"
					:key="feature"
				>{{feature}}</span>
			</h2>
		</section>

		<section class="setup-card__variant">
			<h2 v-translate class="setup-card__heading">
				Variant
			</h2>
			<section v-if="card.variants">
				<div class="variant">
					<label class="variant__label-container">
						<p v-translate class="variant__label-text">Variant name</p>
						<form v-if="editVariantName" @submit.prevent="changeVariantName">
							<input
								ref="variantName"
								class="variant__text"
								type="text"
								:value="variantName"
							/>
							<button class="variant__button--save" type="submit">
								<icon name="check" :small="true" />
							</button>
						</form>
						<form v-else @submit.prevent="editVariant">
							<select v-model="selectedVariant" class="variant__select">
								<option value="null">{{card.variantName}}</option>
								<option
									v-for="(variant, index) in card.alternativeCards"
									:key="index"
									:value="index"
								>{{variant.name}}
								</option>
							</select>
							<button
								class="variant__button--edit"
								type="submit"
								:title="t('Change variant name')"
							>
								<icon name="edit" :small="true" />
							</button>
							<button
								v-if="selectedVariant !== null"
								class="variant__button--remove"
								type="button"
								:title="t('Remove variant')"
								@click.prevent="removeVariant"
							>
								<icon name="delete" :small="true" />
							</button>
						</form>

					</label>

					<h3
						v-if="selectedVariant !== null"
						v-translate
						class="variant__criteria"
					>Variant selection criteria</h3>
					<div v-for="(value, criterion) in criteria" :key="criterion" class="criterion">
						<label class="criterion__label-container">
							<p class="criterion__label-text">{{criterion}}</p>
							<input
								v-if="notNone(criterion)"
								type="text"
								class="criterion__text"
								:value="toString(value)"
								@input="setCriterion(criterion, $event)"
							/>
						</label>
						<div v-if="isTreatmentType">
							<label class="criterion__label-container">
								<p
									v-translate
									class="criterion__label-text"
								>Available treatment types</p>
								<select v-model="selectedTT" class="criterion__select">
									<option value="" />
									<option
										v-for="tt in availableTreatmentTypes"
										:key="tt.id"
										:value="tt.id"
									>{{tt.name}}
									</option>
								</select>
								<button
									v-if="selectedTT"
									class="criterion__button--add"
									@click="addTT"
								>
									<icon name="plus" :small="true" />
								</button>
							</label>

							<p v-translate class="criterion__label-text">
								Selected treatment types
							</p>
							<ul class="criterion__list">
								<li
									v-for="tt in selectedTreatmentTypes"
									:key="tt.id"
									class="criterion__list-item"
								>
									{{tt.name}}
									<button
										class="criterion__button--remove"
										@click="removeTT(tt.id)"
									>
										<icon name="delete" :small="true" />
									</button>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</section>
			<div v-else>
				<button
					class="variant__button--add"
					type="submit"
					@click="cloneVariant"
				>
					<icon name="plus" :small="true" />
					<span v-translate>Clone existing configuration into variant</span>
				</button>
			</div>
		</section>

		<section class="setup-card__properties">
			<h2 v-translate class="setup-card__heading">
				Properties
			</h2>
			<div class="enabled">
				<div class="enabled__label-container--checkbox">
					<p v-translate class="enabled__label-text">
						Enabled
					</p>
					<label>
						<input
							v-model="enabled"
							type="checkbox"
							class="enabled__checkbox"
							:disabled="!allowEdit"
						/>
						<span v-translate class="enabled__value-description">Yes</span>
					</label>
				</div>
			</div>

			<div class="greedy">
				<div class="greedy__label-container--checkbox">
					<p v-translate class="greedy__label-text">
						100% wide tiles on two-column layout
					</p>
					<label>
						<input
							v-model="greedy"
							type="checkbox"
							class="greedy__checkbox"
							:disabled="!allowEdit"
						/>
						<span v-translate class="greedy__value-description">Yes</span>
					</label>
				</div>
			</div>

			<div class="translate">
				<div class="translate__label-container--checkbox">
					<p v-translate class="translate__label-text">
						Translate variant names
					</p>
					<label>
						<input
							v-model="translate"
							type="checkbox"
							class="translate__checkbox"
							:disabled="!allowEdit"
						/>
						<span v-translate class="translate__value-description">Yes</span>
					</label>
				</div>
			</div>
		</section>

		<section class="setup-card__custom-properties">
			<h2 v-translate class="setup-card__heading">
				Custom properties
			</h2>
			<section v-if="customPropsExist">
				<div v-for="prop in customProps" :key="prop.name">
					<edit-object
						:obj-val="prop.value"
						:obj-key="prop.name"
						:type="prop.type"
						:on-change="onCustomPropChange"
						:readonly="!allowEdit"
					/>
					<button
						class="setup-card__help-button icon-button__button"
						@click="toggleHelp(prop.name)"
					>
						<icon name="question-mark" :small="true" />
					</button>

					<p v-if="help[prop.name]" class="setup-card__help-text">
						<vue-showdown>{{prop.description}}</vue-showdown>
					</p>
				</div>
			</section>
			<p v-else v-translate>
				No custom properties available for this card
			</p>
		</section>
	</div>
</template>

<script>
import { clone, filter, get, includes, isUndefined, map, size } from 'lodash';
import can from 'acl-can';
import Vue from 'vue';
import { VueShowdown } from 'vue-showdown';
import cloneVariantDialog from '../../dialogs/clone-variant';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import t from 'service/lang/translate';
import { READ } from 'service/acl/access-levels';
import { NONE, TREATMENT_TYPE_ID } from 'store/engine/variant-criteria';

export default {
	title: t('Card setup'),
	instant: true,
	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],
	components: { VueShowdown },

	data: () => ({
		help: {},
		selectedTT: '',
		editVariantName: false
	}),

	computed: {
		portalName: ({ $store }) => ({
			assessment: 'Assessment Portal',
			atomic: 'Atomic Portal',
			clinician: 'Clinician Portal',
			respondent: 'Respondent Portal'
		}[$store.state.cards.selected]),
		card: ({ $store }) => $store.getters['cards/currentCard'],
		allowEdit: () => can.edit('system.settings.settings'),
		enabled: {
			get: ({ card }) => !card.disabled,
			set (bool) {
				this.$store.dispatch('cards/setEnabled', bool);
			}
		},

		greedy: {
			get: ({ card }) => get(card, 'greedyTiles[0]') === 2,
			set (bool) {
				this.$store.dispatch('cards/setGreedy', bool);
			}
		},

		translate: {
			get: ({ card }) => !!card.translate,
			set (bool) {
				this.$store.dispatch('cards/setTranslate', bool);
			}
		},

		selectedVariant: {
			get: ({ $store }) => $store.state.cards.variantIndex,
			set (index) {
				this.$store.dispatch('cards/setVariant', index);
			}
		},

		variantName: ({ card, selectedVariant }) => selectedVariant ?
			card.alternativeCards[selectedVariant].name :
			card.variantName,

		criteria: ({ card, selectedVariant }) => selectedVariant !== null ?
			get(card.alternativeCards, `[${selectedVariant}].when`) :
			{},

		customProps: ({ $store, card }) => map(
			$store.getters['cards/currentCardOptions'],
			(option) => ({
				name: option.name,
				value: card[option.name],
				type: option.type,
				description: option.description
			})
		),

		customPropsExist: ({ customProps }) => !!size(customProps),
		isTreatmentType: ({ criteria }) => !isUndefined(criteria[TREATMENT_TYPE_ID]),
		treatmentTypes: ({ $store }) => $store.getters['treatmentTypes/sortedByName'],
		availableTreatmentTypes: ({ criteria, treatmentTypes }) => filter(
			treatmentTypes,
			(tt) => !includes(criteria[TREATMENT_TYPE_ID], tt.id)
		),

		selectedTreatmentTypes: ({ criteria, treatmentTypes }) => filter(
			treatmentTypes,
			(tt) => includes(criteria[TREATMENT_TYPE_ID], tt.id)
		)
	},

	methods: {
		setCriterion (criterion, e) {
			let value;

			/**
			 * Value may not be a valid JSON so:
			 * 1. Do not throw errors on every keyup event (hence try/catch)
			 * 2. Only correct JSON is passed to the store
			 */
			try {
				value = JSON.parse(e.target.value);

			} catch (error) {
				value = false;
			}
			value && this.$store.dispatch('cards/setCriterion', { [criterion]: value });
		},

		toString (value) {
			return JSON.stringify(value);
		},

		onCustomPropChange ({ path, value }) {
			this.$store.dispatch('cards/setCustomProp', { key: path, value });
		},

		toggleHelp (propName) {
			Vue.set(this.help, propName, !this.help[propName]);
		},

		addTT () {
			if (this.selectedTT) {
				const treatmentTypeId = clone(this.criteria.treatmentTypeId);
				treatmentTypeId.push(this.selectedTT);
				this.$store.dispatch('cards/setCriterion', { treatmentTypeId });
			}
		},

		removeTT (id) {
			const index = this.criteria.treatmentTypeId.indexOf(id);

			if (index > -1) {
				const treatmentTypeId = clone(this.criteria.treatmentTypeId);
				treatmentTypeId.splice(index, 1);
				this.$store.dispatch('cards/setCriterion', { treatmentTypeId });
			}
		},

		editVariant () {
			this.editVariantName = true;
		},

		changeVariantName () {
			const name = this.$refs.variantName.value;
			this.$store.dispatch('cards/setVariantName', { name });
			this.editVariantName = false;
		},

		removeVariant () {
			const name = this.$store.getters['cards/selectedVariant'].name;

			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove variant'),
				message: t(`Are you sure you want to remove variant <b>{name}</b>?`, { name })
			})
				.then(() => this.$store.dispatch('cards/removeCurrentVariant'))
				.then(() => {
					cwalert.success(t('Variant {name} has been removed', { name }));
				});
		},

		cloneVariant: cloneVariantDialog,

		notNone: (criterion) => criterion !== NONE
	}
};
</script>
