import t from 'translate';

const card = {
	title: t('Export clinician - respondent groups mapping'),
	name: 'system-exports-clinician-to-respondent-groups-mapping',
	url: 'system-exports/clinician-to-respondent-groups-mapping'

};

export { card as clinicianToRespondentGroups };
