<template>
	<component :is="propertiesComponent" :key="itemId" />
</template>

<script>
import ProgramProperties from './properties/program-properties';
import ModuleProperties from './properties/module-properties';
import SectionProperties from './properties/section-properties';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';
import {
	PROGRAM,
	MODULE,
	SECTION
} from '../../constants';

export default {
	components: {
		ProgramProperties,
		ModuleProperties,
		SectionProperties
	},

	computed: {
		itemId: ({ $store }) => $store.getters[TREATMENT_PROGRAM.ACTIVE_ITEM].id,
		propertiesComponent: ({ $store }) => (
			{
				[PROGRAM]: ProgramProperties,
				[MODULE]: ModuleProperties,
				[SECTION]: SectionProperties
			}[$store.getters[TREATMENT_PROGRAM.ACTIVE_ITEM].type]
		)
	}
};
</script>
