import TileView from 'tile-view';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';
import formView from 'components/form-view/form-view';
import t from 'translate';
import repository from 'repository';
import AccessRoleCheckpointGroup from 'repo/access-roles/access-role-checkpoint-group';
import { assign, flow, map, merge, sortBy } from 'lodash';

export default TileView.extend({
	title: t('system-access-management.miscellaneous-access'),
	acl: [{
		checkpoint: 'system.settings.administratorroles',
		op: 'READ'
	}],

	cardData: () => ['roleId'],

	tileData: ({ tile }) => {
		const accessGroup = new AccessRoleCheckpointGroup({ administratorRoleId: +tile.roleId });
		return ({
			checkpointGroups: repository.getCheckpointGroups(),
			accessRoleCheckpointGroup: accessGroup,
			accessRoleCheckpointGroups: repository.getAccessRoleCheckpointGroups(tile.roleId)
		});
	},

	loaded () {
		this.renderForm().renderTable();
	},

	renderForm: function () {
		var canEdit = can.edit('system.settings.administratorroles');
		var tile = this;
		var formCfg = {
			name: 'access-role-misc',
			listenTo: ['submit'],
			model: this.accessRoleCheckpointGroup,

			onAfterSave: function () {
				tile.accessRoleCheckpointGroup.set('relationId', null);
				tile.table.refresh();
			},

			fields: [{
				key: 'administratorRoleAccessCategoryId',
				label: t('Group name'),
				type: 'select',
				description: t('Select a group…'),
				required: true,
				readonly: !canEdit,

				values: flow([
					() => map(this.checkpointGroups.toJSON(), (checkpoint) => assign(
						checkpoint,
						{
							[checkpoint.administratorRoleAccessCategoryId]:
								t(`acl.groups.${checkpoint.administratorRoleAccessCategoryName}`),
							name:
								t(`acl.groups.${checkpoint.administratorRoleAccessCategoryName}`)
						}
					)),
					(checkpoints) => sortBy(checkpoints, ['name'])
				])
			}, {
				key: 'accessLevelId',
				label: t('settings.roles-management.AccessLevel'),
				type: 'select',
				description: t('Select an access level…'),
				readonly: !canEdit,
				required: true,
				values: [{
					1: 'NONE'
				}, {
					3: 'READONLY'
				}, {
					4: 'ADD'
				}, {
					5: 'EDIT'
				}, {
					6: 'DELETE'
				}, {
					7: 'SUPERVISOR'
				}, {
					8: 'ADMINISTRATOR'
				}]
			}],
			buttons: [{
				caption: t('Add'),
				name: 'add',
				type: 'submit',
				disabled: !canEdit
			}]
		};

		this.$el.append(formView(formCfg).$el);
		return this;
	},

	renderTable: function () {
		const models = sortBy(this.accessRoleCheckpointGroups.models, (model) => {
			const name = t(`acl.groups.${model.get('group').roleAccessCategory.name}`);
			return name.length ? name : model.get('group').roleAccessCategory.name;
		});
		this.table = new datamodel.grid.Table({
			collection: merge(this.accessRoleCheckpointGroups, { models }),
			rootAttribute: 'group', /* <- points to attribute in model that should be used as model
									   (YYYYEEEEESSSS!) */
			noFetch: true,
			columns: {
				roleAccessCategory: {
					label: t('Group name'),
					rendererFactory: new datamodel.widgets.LabelFactory({
						valueProcessor: function (group) {
							var translated = t('acl.groups.' + group.name);
							return translated.length ? translated : group.name;
						}
					})
				},
				accessLevel: {
					label: t('settings.roles-management.AccessLevel'),
					rendererFactory: new datamodel.widgets.LabelFactory({
						valueProcessor: function (level) {
							return t('acl.levels.' + level.label);
						}
					})
				}
			},
			actions: {
				remove: {
					disabled: !can.edit('system.settings.administratorroles'),
					label: ''
				}
			},
			container: this.el
		});

		return this;
	}
});
