import { assign } from 'lodash';

// alphabetical order
import accessRoles from './access-roles/methods';
import adapters from 'repo/adapters/methods';
import agenda from './agenda/methods';
import apps from './apps/methods';
import assessmentInstances from './assessment-instances/methods';
import assessments from './assessments/methods';
import cardConfig from './card-config/methods';
import circles from './circles/methods';
import clinicians from './clinicians/methods';
import consents from './consents/methods';
import contentPackageInstances from './content-package-instances/methods';
import contentPackages from './content-packages/methods';
import cube from './cube/methods';
import dataIntegration from './data-integration/methods';
import decisions from './decisions/methods';
import exports from './exports/methods';
import files from './files/methods';
import flags from './flags/flags-methods';
import general from './general/methods';
import goals from './goals/methods';
import languages from './languages/methods';
import links from './links/methods';
import media from './media/methods';
import messages from './messages/methods';
import notications from './notifications/methods';
import pages from './pages/methods';
import planInstances from './plan-instances/methods';
import plans from './plans/methods';
import relationTypes from './relation-types/methods';
import reports from './reports/methods';
import respondents from './respondents/methods';
import roles from './roles/methods';
import sidebar from './sidebar/methods';
import systemEventLogs from './system-event-logs/methods';
import systemSettings from './system-settings/methods';
import timezones from './timezones/methods';
import treatments from './treatments/methods';
import treatmentTypes from './treatment-types/methods';
import user from './user/methods';

export default assign(
	{},
	accessRoles,
	adapters,
	agenda,
	apps,
	assessmentInstances,
	assessments,
	cardConfig,
	circles,
	clinicians,
	consents,
	contentPackageInstances,
	contentPackages,
	cube,
	dataIntegration,
	decisions,
	exports,
	files,
	flags,
	general,
	goals,
	languages,
	links,
	media,
	messages,
	notications,
	pages,
	planInstances,
	plans,
	relationTypes,
	reports,
	respondents,
	roles,
	sidebar,
	systemEventLogs,
	systemSettings,
	timezones,
	treatments,
	treatmentTypes,
	user
);
