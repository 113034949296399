import t from 'translate';
import store from 'store';
import { get } from 'lodash';

const card = {
	title: t('decision.properties'),
	name: 'decision-properties',
	url: 'decision-properties/:decisionId',

	appEvents: {
		'plan-instance.delete' (planInstance) {
			if (+get(store.state.decision.data, 'planInstanceId') === +planInstance.getId()) {
				this.close();
			}
		}
	},

	ctrl: (context, { decisionId }) => store.dispatch('decision/init', { decisionId })
};

export { card as DecisionProperties };

