import store from 'store';
import t from 'translate';

const card = {
	title: t('Add treatment type'),
	name: 'add-treatment-type',
	url: 'treatment-type/add',
	ctrl: () => store.dispatch('treatmentType/reset')
};

export { card as add };
