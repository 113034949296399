import $ from 'jquery';
import TileView from 'tile-view';
import can from 'acl-can';
import t from 'translate';
import formView from 'components/form-view/form-view';
import repository from 'repository';

export default TileView.extend({
	acl: [{
		checkpoint: 'system.settings.remotescoring',
		op: 'READ'
	}],
	title: '',

	cardData: () => ['assessmentId'],

	tileData: ({ tile }) => ({
		properties: repository.getRemoteScoringProperties(tile.assessmentId),
		general: repository.RemoteScoringAssessments.getById(tile.assessmentId)
	}),

	loaded: ({ tile }) => {
		const $h1 = $('<h1/>').html(t('remotescoring-management.properties.Title', {
			name: tile.general.get('assessmentCode')
		}));
		const $h2 = $('<h2 />').addClass('form-view__fieldset__caption').html(
			t('remote-scoring.properties.GeneralGroup')
		);
		tile.$el.append($h1, $h2);

		const cfg = {
			name: 'edit-remote-scoring-assessment',
			model: tile.general,
			readonly: !can.edit('system.settings.remotescoring'),
			classNames: ['no-fieldset-margin'],
			fields: [{
				key: 'assessmentQueueTopic',
				type: 'text',
				label: t('remote-scoring.properties.QueueTopic')
			}, {
				key: 'assessmentRemoteScoringEnabled',
				type: 'checkbox',
				label: t('remotescoring-management.tableheader.ScoreRemotely')
			}]
		};

		tile.$el.append(formView(cfg).$el);

		if (!tile.properties.size()) {
			return;
		}

		tile.$el.append(
			$('<h2 />').addClass('form-view__fieldset__caption').html(
				t('remote-scoring.properties.PropertiesGroup')
			)
		);
		const formViews = [];

		// create new FormView instance for each custom attribute
		tile.properties.each((property) => {
			const cfg = {
				readonly: !can.edit('system.settings.remotescoring'),
				classNames: ['no-fieldset-margin'],
				model: property,
				fields: [{
					key: 'propertyDefault',
					type: 'textarea',
					label: property.get('propertyName'),
					hint: property.get('propertyDescription') || ' '
				}]
			};

			formViews.push(formView(cfg).$el);
		});

		tile.$el.append(formViews);
	}
});

