import Checkbox from './Checkbox';

export default function (options = {}) {
	const rendererOptions = options.rendererOptions ?
		options.rendererOptions :
		{};

	if (options.displayValue) {
		rendererOptions.displayValue = options.displayValue;
	}

	if (options.disableAutoSave) {
		rendererOptions.disableAutoSave = options.disableAutoSave;
	}

	this.createRenderer = function (attributeName, model, opts) {
		if (options.disableAutoSave === undefined) {
			rendererOptions.disableAutoSave = opts.disableAutoSave;
		}

		if (opts.readonly) {
			rendererOptions.readonly = opts.readonly;
		}

		return new Checkbox(attributeName, model, rendererOptions, opts.mode);
	};
}
