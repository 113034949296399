import { intersection, map, without } from 'lodash';

export default (groupId, respondentToGroupRelations, administratorRespondentGroups) => {

	const clinicianGroups = administratorRespondentGroups.filter(
		(clinicianGroup) => clinicianGroup.get('respondentGroupHasAccessToClinician')
	);
	const respondentGroupIds = map(
		respondentToGroupRelations.pluck('group'),
		(group) => +group.respondentGroupId
	);
	const clinicianGroupIds = map(clinicianGroups, (group) => +group.get('respondentGroupId'));

	/* respondent groups remaining after the group removal */
	const remainingGroups = without(respondentGroupIds, +groupId);

	/* No groups added to respondent, means it will be visible for all */
	if (remainingGroups.length === 0) {
		return false;
	}

	/* Clinician groups are different then respondent groups, no common group */
	if (intersection(remainingGroups, clinicianGroupIds).length === 0) {
		return true;
	}

	/* No problem, admin can access remaining groups */
	return false;
};
