import confirmation from 'components/confirmation/confirmation';
import cwalert from 'service/cwalert';

export default ({ setLoaded, setLoading, onSuccess, title, message, successMessage }) => {
	confirmation({
		icon: 'eraser',
		warning: true,
		title,
		message
	})
		.then(() => {
			setLoading();
			return onSuccess();
		})
		.then(() => {
			cwalert.success(successMessage);
			setLoaded();
		});
};
