<template>
	<div>
		<div v-if="canAdd">
			<h2 class="respondent-relation__label">
				{{t('Create a new relation')}}
			</h2>
			<p class="form-view__hint">
				{{t('Set a new relation')}}
			</p>
		</div>

		<p v-else v-translate class="form-view__hint">
			{{t('Not authorised to add a relation')}}
		</p>

		<div v-if="options.length" class="respondent-relation__select-container">
			<form @submit.prevent="onSubmit">
				<select-input
					v-if="canAdd"
					v-model="relation"
					:options="options"
					container-class="respondent-relation"
					class-prefix="respondent-relation"
					label="Choose relation type"
					:mandatory="true"
					:required="true"
					@input="triggerChange"
				/>

				<fieldset v-if="relation" class="respondent-relation__respondent-container">
					<label
						class="respondent-relation__respondent-left"
					>{{respondent1.role}}</label>
					<a :class="iconClasses" @click.prevent="toggle" />
					<label
						class="respondent-relation__respondent-right"
					>{{respondent2.role}}</label>
				</fieldset>

				<fieldset v-if="relation" class="respondent-relation__input-container">
					<text-input
						v-model="setFirst().name"
						class-additional="input-left"
						class-prefix="respondent-relation"
						:disabled="true"
						label=""
						:show-label="false"
					/>
					<div class="respondent-relation__input-right">
						<quick-search
							:clear="clearResults"
							:excluded-id="respondent1.id"
							:get-results="getResults"
							:on-click="setRespondent"
							:list="list"
							:loading="loading"
							url=""
							:urls="false"
							:label="$t('Respondent search')"
						/>
					</div>
					<submit-button
						label="Save"
						:can-submit="canSubmit"
						class-prefix="respondent-relation"
						container-class="button-container"
					/>
				</fieldset>
			</form>
		</div>
		<header class="respondent-relation__label-table">
			{{t('Existing relations')}}
		</header>
		<respondent-relations :id="id" />
	</div>
</template>

<script>
import quickSearch from 'components/quick-search/quick-search';
import respondentRelations from '../../components/respondent-relations';
import { mapActions, mapGetters } from 'vuex';
import can from 'service/acl/acl-can';
import { assign, find, map } from 'lodash';
import cwalert from 'service/cwalert';
import t from 'service/lang/translate';
import { respondentName } from 'service/display-name/display-name';
import { required } from 'vuelidate/lib/validators';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';

export default {
	title: t('Respondent`s relations'),
	actions: (tile) => {
		const id = tile.cardContext().get('respondentId');
		return [
			['respondentRelations/getAllRelations'],
			['respondentRelations/getRespondentRelations', id],
			['respondentSearch/setLimit', tile.config().limit || 10]
		];
	},
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.RELATIONS,
		op: READ
	}],

	components: {
		quickSearch,
		respondentRelations
	},

	data () {
		const id = parseInt(this.tile.cardContext().get('respondentId'), 10);
		return {
			canSubmit: false,
			id,
			loading: false,
			relation: null,
			respondent1: { id },
			respondent2: {},
			switched: false
		};
	},

	computed: {
		...mapGetters({
			allRelations: 'respondentRelations/allRelations',
			limit: 'respondentSearch/limit',
			respondent: 'respondent/data',
			respondentList: 'respondentSearch/list',
			respondentRelations: 'respondentRelations/relations'
		}),
		canAdd: () => can.add(CLINICIAN.RESPONDENTS.RELATIONS),
		iconClasses: () => [
			'icon',
			'respondent-relation__icon',
			'icon--default',
			'icon--switch-circle',
			'icon-2x'
		],

		list: ({ respondentList, provideListData }) => respondentList.length ?
			provideListData(respondentList) :
			respondentList,

		options: ({ allRelations }) => map(allRelations, (relation) => {
			relation.label = relation.name;
			return relation;
		})
	},
	methods: {
		...mapActions('respondentRelations', ['addRelationAndRefresh']),
		...mapActions('respondentSearch', ['deleteList', 'provideResults', 'setSearchList']),

		clearResults () {
			this.setSearchList([]);
			this.canSubmit = false;
			this.respondent1 = this.switched ?
				{ role: this.respondent1.role } :
				this.respondent1;
			this.respondent2 = !this.switched ?
				{ role: this.respondent2.role } :
				this.respondent2;
		},

		getResults (search) {
			this.loading = true;
			this.provideResults(this.provideParams(search)).then(() => {
				this.loading = false;
			});
		},

		onSubmit () {
			if (this.valid()) {
				const data = {
					respondentRelationType: { id: this.relation.id },
					respondent1: { id: this.respondent1.id },
					respondent2: { id: this.respondent2.id }
				};
				this.addRelationAndRefresh(data).then(() => {
					cwalert.success(t('Relation has been saved'));
					this.reset();
				});
			}
		},

		provideListData: (list) => map(list, (item) => ({
			rows: [{
				id: item.respondentId,
				name: respondentName(item, { preventEscape: true })
			}]
		})),

		provideParams (search) {
			return { search, start: 0, limit: this.limit };
		},

		reset () {
			this.relation = null;
			this.respondent1 = { id: this.id };
			this.respondent2 = {};
			this.switched = false;
		},

		setFirst () {
			return !this.switched ? this.respondent1 : this.respondent2;
		},

		setRespondent (respondent) {
			const relatedRespondent = this.switched ? 'respondent1' : 'respondent2';
			const alreadyAssigned = find(this.respondentRelations, (relation) =>
				+relation[relatedRespondent].id === respondent.id);

			if (alreadyAssigned) {
				cwalert.failure(t('Relation already assigned'));
				return false;
			}
			assign(this[relatedRespondent], {
				id: respondent.id,
				name: respondent.name
			});
			this.deleteList();
			this.canSubmit = this.valid();
			return true;
		},

		toggle () {
			this.switched = !this.switched;
			const currentFirst = { ...this.respondent1 };
			const currentSecond = { ...this.respondent2 };
			this.respondent1 = currentSecond;
			this.respondent2 = currentFirst;
		},

		triggerChange (val) {
			if (val) {
				const firstRespondent = !this.switched ? this.respondent1 : this.respondent2;
				const secondRespondent = !this.switched ? this.respondent2 : this.respondent1;
				firstRespondent.name = this.respondent.displayName;
				firstRespondent.role = val.respondent1RoleName;
				secondRespondent.role = val.respondent2RoleName;
			}
			this.canSubmit = this.valid();
		},

		valid () {
			return Boolean(!this.$v.$invalid && this.respondent1.id && this.respondent2.id);
		}
	},
	validations: {
		relation: {
			id: { required }
		}
	}
};
</script>
