import apiMode from './data-integration-api-mode';
import codeEdit from './data-integration-code-edit';
import codes from './data-integration-codes/tile';
import codeTypes from './data-integration-code-types/tile';
import codeVariations from './data-integration-code-variations';
import dataMapping from './data-integration-data-mapping/tile';
import enterprises from './data-integration-enterprises/tile';
import integrationRoleMapping from './data-integration-role-mapping/tile';
import integrationRuleSetEdit from './data-integration-rule-set-edit';
import integrationRuleSets from './data-integration-rule-sets/tile';

export default {
	'data-integration-api-mode': apiMode,
	'data-integration-code-edit': codeEdit,
	'data-integration-codes': codes,
	'data-integration-code-types': codeTypes,
	'data-integration-code-variations': codeVariations,
	'data-integration-data-mapping': dataMapping,
	'data-integration-enterprises': enterprises,
	'data-integration-role-mapping': integrationRoleMapping,
	'data-integration-rule-set-edit': integrationRuleSetEdit,
	'data-integration-rule-sets': integrationRuleSets
};
