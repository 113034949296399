var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-treatment-program" }, [
    _c("div", { staticClass: "edit-treatment-program__layout" }, [
      _c("div", [_c("program-editor")], 1),
      _vm._v(" "),
      _c("div", [_c("properties-panel")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }