import cardurl from 'cwcardurl';
import cwalert from 'cwalert';
import t from 'translate';
import repository from 'repository';

const card = {
	name: 'open-respondent',
	url: 'open-respondent/:username/:tabName',

	ctrl: ({ close }, { username, tabName }) => {
		const routes = {
			personalia: 'respondent-edit',
			calendar: 'respondent-status',
			timeline: 'respondent-assessments',
			plans: 'respondent-plans',
			decisions: 'respondent-decisions',
			flags: 'respondent-flags',
			messages: 'respondent-messages'
		};
		const cardName = routes[tabName];

		if (!cardName) {
			cwalert.error(t('Invalid tab.'));
			return;
		}

		const respondentNotFound = () => {
			cwalert.error(t('Respondent not found.'));
			close();
			cardurl.openCard('search-respondents', {
				destination: cardName,
				search: ''
			});
		};
		const respondents = repository.getRespondentByUsername(username);

		respondents.then(() => {
			if (respondents.size()) {
				close();
				cardurl.openCard(cardName, { respondentId: respondents.shift().getId() });

			} else {
				respondentNotFound();
			}
		}, () => {
			respondentNotFound();
		});
	}
};

export { card as OpenRespondent };
