import Backbone from 'backbone';
import t from 'translate';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';
import repository from 'repository';

export default Backbone.View.extend({
	className: '',
	title: t('system-manager.ContextManagement'),
	acl: [{
		checkpoint: 'system.settings.contexts',
		op: 'READ'
	}],

	appEvents: {
		'assessment-context.create': 'refreshTable'
	},

	render () {
		this.setLoading();
		this.contexts = repository.getContexts();

		this.contexts.done(this.loaded.bind(this)).always(this.setLoaded.bind(this));
	},

	loaded () {
		this.table = new datamodel.grid.Table({
			collection: this.contexts,
			readonly: !can.edit('system.settings.contexts'),
			columns: {
				assessmentInstanceContextLabel: {
					label: t('system-manager.ContextLabel'),
					rendererFactory: new datamodel.widgets.TextFactory()
				},
				assessmentInstanceContextOrder: {
					label: t('system-manager.settings.context.Order'),
					rendererFactory: new datamodel.widgets.TextFactory()
				}
			},
			actions: {
				remove: {
					disabled: !can['delete']('system.settings.contexts')
				}
			},

			container: this.el
		});
	},

	refreshTable () {
		this.table.refresh();
	}
});

