import ClinicianAccessRoles from './clinician-access-roles';
import ClinicianGroups from './clinician-groups';
import ClinicianLabel from './clinician-label/tile';
import ClinicianLockout from './clinician-lockout';
import ClinicianProfile from './clinician-profile/tile';
import DocumentOwner from './document-owner/tile';
import NewClinicianAccount from './new-clinician-account/tile';
import SearchClinicians from './search-clinicians/tile';

export default {
	'clinician-access-roles': ClinicianAccessRoles,
	'clinician-groups': ClinicianGroups,
	'clinician-label': ClinicianLabel,
	'clinician-lockout': ClinicianLockout,
	'clinician-profile': ClinicianProfile,
	'document-owner': DocumentOwner,
	'new-clinician-account': NewClinicianAccount,
	'search-clinicians': SearchClinicians
};
