import { closeDialog } from 'service/form-view-dialog';
import CardDialog from 'service/card-dialog/card-dialog';
import t from 'translate';
import Vue from 'vue';
import store from 'store';
import cardUrl from 'cwcardurl';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default () => {
	const dialog = new Vue({
		el: document.createElement('div'),
		components: {
			'v-select': vSelect
		},
		store,
		data: {
			name: undefined,
			selectedTile: undefined
		},

		computed: {
			...mapGetters({
				availableTiles: 'cards/availableTiles'
			}),

			disabled () {
				return !this.selectedTile;
			}
		},

		methods: {
			addTile () {
				this.$store.dispatch('cards/createTile', this.selectedTile.name).then(() => {
					closeDialog();
					cardUrl.openCard('edit-tile', {
						type: this.$store.state.cards.selected,
						cardName: this.$store.getters['cards/currentCard'].name,
						variant: this.$store.state.cards.variantIndex,
						tileIndex: this.$store.state.cards.tiles.currentList.length - 1,
						tileName: this.selectedTile.name
					});
				});
				closeDialog();
			},

			onCancel () {
				closeDialog();
			}
		},

		template: `
		<form class="add-tile-dialog" @submit.prevent="addTile">
			<div class="tile-list">
				<label class="tile-list__label-container">
					<p class="tile-list__label-text" v-translate>Tile</p>
				</label>
				<v-select
					:options="availableTiles"
					v-model="selectedTile"
					label="name"
				>
					<template #no-options>
						{{$t('selectInput.noResults')}}
					</template>
				</v-select>
			</div>

			<div class="description" v-if="selectedTile">
				<p class="description__label-text" v-translate>Description</p>
				<p class="description__paragraph">{{selectedTile.description}}</p>
			</div>

			<div class="submit">
				<button
					class="submit__button--submit"
					type="submit"
					:disabled="disabled"
					v-translate
				>Save</button>
			</div>

			<div class="cancel">
				<button
					class="cancel__button"
					type="button"
					@click.stop.prevent="onCancel"
					v-translate
				>Cancel</button>
			</div>
		</form>
		`
	});

	return new CardDialog({
		preventFocusTrap: true,
		title: t('Add a blank tile'),
		content: dialog.$el,
		name: 'add-tile'
	});
};
