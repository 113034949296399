var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-dialog", {
    attrs: {
      show: _vm.config.show,
      "on-close": _vm.config.onClose,
      loading: _vm.config.loading
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "span",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v(_vm._s(_vm.config.title))]
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _vm.warnings.length
              ? _c(
                  "div",
                  { staticClass: "data-integration__warning-container" },
                  _vm._l(_vm.warnings, function(warning) {
                    return _c("div", { key: warning }, [
                      _c("p", { staticClass: "data-integration__warning" }, [
                        _vm._v("\n\t\t\t\t\t" + _vm._s(warning) + "\n\t\t\t\t")
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("modal-content", {
              attrs: {
                "class-prefix": _vm.config.classPrefix,
                acl: _vm.config.acl,
                "assessment-options": _vm.assessmentOptions,
                codes: _vm.codes,
                "code-options": _vm.codeOptions,
                "code-type-options": _vm.codeTypeOptions,
                "default-rule": _vm.defaultRule,
                edit: _vm.config.edit,
                enterprises: _vm.enterprises,
                "enterprise-options": _vm.enterpriseOptions,
                item: _vm.config.item,
                "on-close": _vm.config.onClose,
                "on-submit": _vm.config.onSubmit,
                "portal-options": _vm.portalOptions,
                processing: _vm.processing,
                "role-available-options": _vm.roleAvailableOptions,
                "role-options": _vm.roleOptions,
                "treatment-type-options": _vm.treatmentTypeOptions,
                "treatment-types": _vm.treatmentTypes,
                type: _vm.config.type,
                updating: _vm.updating,
                vuelidate: _vm.vuelidate
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }