import t from 'translate';
import can from 'acl-can';
import cardurl from 'cwcardurl';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import categoryTag from 'components/category-tag/category-tag';
import $ from 'jquery';
import { assign, chain, map } from 'lodash';
import sanitize from 'service/sanitize-string/sanitize-string';

export default SearchTileView.extend({
	title: t('Content packages'),
	acl: [{
		checkpoint: 'system.settings.informationpackages',
		op: 'read'
	}],
	cardData: () => ['contentPackageCategories'],
	searchFn: (...args) => repository.searchContentPackages(...args),
	fields: ({ tile }) => {
		// eslint-disable-next-line lodash/chaining
		const ids = (value) => chain(value)
			.map((item, key) => item && key)
			.compact()
			.value();

		return [{
			key: 'category.id',
			type: 'multi-select',
			label: t('Category'),

			values: map(tile.contentPackageCategories.toJSON(), (category) => assign({}, category, {
				id: category.id,
				label: category.name
			})),

			onChange: (value) => {
				tile.viewModel.set('category.id', ids(value));
				tile.runQuery();
			}
		}];
	},

	tableCfg: () => ({
		emptyListMessage: t('No content packages'),
		rowsClickable: can.read('system.settings.informationpackages'),
		click: (item) => {
			cardurl.openCard('edit-content-package', { id: item.id });
		},
		columns: [{
			label: t('Name'),
			key: 'name',
			render: (value, { item, td }) => {
				$(td)
					.append(sanitize(item.get('name')))
					.append(categoryTag(item.get('categories')));
			}
		}, {
			label: t('Description'),
			key: 'description'
		}, {
			label: t('Status'),
			key: 'status',
			render: (status, { item }) => item.getStatusLabel()
		}]
	})
});

