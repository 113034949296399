<template>
	<section class="clinician-label__content">
		<ul class="clinician-label__container">
			<section class="clinician-label__element-container important">
				<li class="clinician-label__label-container">
					<p v-translate class="clinician-label__value">
						Clinician
					</p>
				</li>
				<li class="clinician-label__value-container">
					<p class="clinician-label__value">
						<a :href="link">{{displayName}}</a>
					</p>
				</li>
			</section>
			<section class="clinician-label__element-container important">
				<li class="clinician-label__label-container">
					<p v-translate class="clinician-label__value">
						Username
					</p>
				</li>
				<li class="clinician-label__value-container">
					<p class="clinician-label__value">
						{{clinician.username}}
					</p>
				</li>
			</section>
		</ul>
		<timezone-warning person-type="clinician" :clinician="clinician" />
	</section>
</template>

<script>
import cardUrl from 'cwcardurl';
import TimezoneWarning from 'components/timezone-warning';
import { clinicianName } from 'service/display-name/display-name';

export default {
	components: { TimezoneWarning },
	computed: {
		clinician: ({ $store }) => $store.getters['clinician/clinician'],
		displayName: ({ clinician }) => clinicianName(clinician),
		link: ({ clinician }) => cardUrl.buildUrl('clinician-profile', {
			clinicianId: clinician.id
		})
	}
};
</script>
