<template>
	<div class="mini-create-assessment-tile">
		<div
			class="mini-create-assessment-tile__link mini-create-assessment-tile__container"
			tabindex="0"
			@click="createAssessmentInstance"
			@keyup.prevent.enter.space="createAssessmentInstance"
		>
			<p class="mini-create-assessment-tile__title">
				{{title}}
			</p>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import cardUrl from 'cwcardurl';

export default {
	data: ({ tile }) => ({
		config: tile.config()
	}),
	computed: {
		title: ({ config }) => {
			if (!config.title) {
				return t('Create assessment -- {code}', { code: config.assessmentCode });
			}
			return config.translate ? t(config.title) : config.title;
		}
	},

	methods: {
		createAssessmentInstance () {
			this.setDataLoading();
			this.$store.dispatch(
				'assessmentInstance/createByCode',
				this.config
			).then((assessmentInstance) => {
				this.setDataLoaded();
				cardUrl.openCard(
					'respondent-assessment-runtime',
					{ id: assessmentInstance.assessmentInstanceId }
				);
			});
		}
	}
};
</script>
