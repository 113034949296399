import LinksList from './links-list/tile';
import EditLink from './edit-link/tile';
import ViewLink from './view-link/tile';
import LinkCategories from './system-settings-link-categories/tile';

export default {
	'links-list': LinksList,
	'edit-link': EditLink,
	'view-link': ViewLink,
	'system-settings-link-categories': LinkCategories
};
