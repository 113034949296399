import t from 'translate';
import SingleEntity from '../shared/single-entity';
import urls from './urls';
import appContext from 'app-context';

export default SingleEntity.extend({
	urlRoot: urls.single.url,
	eventNamespace: 'information-package',
	STATUS_UNPUBLISHED: 'UNPUBLISHED',
	STATUS_UNPUBLISHED_CHANGES: 'UNPUBLISHED_CHANGES',
	STATUS_PUBLISHED: 'PUBLISHED',

	appEvents: {
		// eslint-disable-next-line max-len
		'information-package-element.update information-package-element.create information-package-element.delete' (model) {
			if (this.getId() === model.get('informationPackage') && this.isPublished()) {
				this.set('status', this.STATUS_UNPUBLISHED_CHANGES);
				appContext.trigger('information-package.__update', this);
			}
		}
	},

	getStatusLabel () {
		return {
			[this.STATUS_PUBLISHED]: t('Published'),
			[this.STATUS_UNPUBLISHED]: t('Unpublished'),
			[this.STATUS_UNPUBLISHED_CHANGES]: t('Unpublished changes')
		}[this.get('status')];
	},

	isPublished () {
		return this.get('status') === this.STATUS_PUBLISHED;
	},

	isUnpublished () {
		return this.get('status') === this.STATUS_UNPUBLISHED;
	}
});

