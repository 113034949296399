import MultipleEntities from '../shared/multiple-entities';
import TreatmentTypeAttribute from './attribute-entity';
import urls from './urls';

/**
 * TreatmentAttributes Entity
 * @class TreatmentAttributes
 * @extends MultipleEntities
 */
const TreatmentAttributesEntity = MultipleEntities.extend({
	model: TreatmentTypeAttribute,
	urls,
	eventNamespace: 'treatment-attribute',

	canAdd: function (model) {
		return +model.get('treatment') === +this.ownerId;
	},
	/**
	 * Get TreatmentAttributes by treatment
	 * @method getByTreatment
	 * @return {Object} new TreatmentAttributes Collection
	 * @example
	 *        Repository.TreatmentAttributes.getByTreatment(1);
	 */
	getByTreatment: function (treatmentId) {
		return this.retrieve('listAttributes', treatmentId);
	}

});

export default new TreatmentAttributesEntity();
