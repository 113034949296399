import can from 'acl-can';
import appbar from 'app-bar';
import t from 'translate';
import newAssessmentContext from '../dialogs/new-assessment-context';

const card = {
	name: 'system-settings-assessment-contexts',
	title: t('Assessment contexts'),
	url: 'system/assessment-contexts',

	controller () {
		this.populate();

		can.add('system.settings.contexts') && appbar.addButton(card.name, {
			icon: 'add',
			click: newAssessmentContext,
			title: t('New assessment context')
		});
	}

};

export { card as contexts };
