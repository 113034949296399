<template>
	<form class="form-view" @submit.prevent="submit">
		<text-input
			v-model="formData.name"
			class-prefix="tree-edit"
			label="Group name"
		/>
		<text-input
			v-if="item.mirror"
			v-model="item.mirrorName"
			:disabled="true"
			class-prefix="tree-edit"
			label="Mirror of"
		/>
		<select-input
			v-model="formData.assessmentContext"
			:disabled="!allowMirrorUpdate"
			:options="availableContexts"
			:placeholder="translate('Select context')"
			class-prefix="tree-edit"
			label="Set context for group"
			item-label="assessmentInstanceContextLabel"
		/>
		<select-input
			v-model="formData.assessmentOrder"
			:disabled="!allowMirrorUpdate"
			:options="formOptions.assessmentOrder"
			:placeholder="translate('Select order')"
			class-prefix="tree-edit"
			label="Assessment order"
		/>
		<text-input
			v-model="groupCode"
			:disabled="!allowMirrorUpdate"
			classPrefix="tree-edit"
			label="Code string"
		/>
		<p
			v-if="invalidCode"
			v-translate
			class="invalid-msg tree-edit__invalid"
		>Code template invalid</p>
		<button-group>
			<plain-button
				v-if="item.mirror"
				:on-click="confirmBreak"
				label="Break connection"
			/>
			<submit-button
				label="Save"
				:can-submit="!invalidCode"
			/>
			<plain-button
				:on-click="init"
				label="Reset"
			/>
		</button-group>
	</form>
</template>

<script>
import mixin from './_mixin';
import editMixin from './_edit-mixin';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import t from 'translate';
import { assessmentValidation } from './_validation-mixin';
import { mapActions, mapGetters } from 'vuex';
import { transform, assign } from 'lodash';

export default {
	name: 'Group',
	mixins: [mixin, assessmentValidation, editMixin],

	data: () => ({
		formData: {
			name: null,
			assessmentContext: null,
			assessmentOrder: null,
			codeTemplate: null,
			contextLabel: null,
			customCode: false
		}
	}),

	computed: {
		...mapGetters({
			contexts: 'contexts/sortedWithEmpty'
		}),

		groupCode: {
			get () {
				return this.formData.customCode ? this.formData.codeTemplate : '';
			},
			// change value when customName set to true
			set (value) {
				this.formData.codeTemplate = value.length ?
					value :
					this.item.fields.parentCodeTemplate;
				this.formData.customCode = !!value.length;
			}
		}
	},

	watch: {
		'formData.assessmentContext' (newVal, oldVal) {
			if (!newVal && oldVal) {
				const label = this.parentContext || '';
				this.formData.contextLabel = label;
				this.item.fields.contextLabel = label;
			} else if (newVal) {
				this.formData.contextLabel = newVal.assessmentInstanceContextLabel;
			}
		}
	},

	methods: {
		...mapActions('components/tree-view/export-template', ['breakConnection']),

		init () {
			// retransform values to entities and set them to proper data models
			assign(this.formData, transform(this.item.fields, (memo, value, key) =>
				assign(memo, {
					assessmentOrder: this.valueToEntity({
						entities: this.formOptions.assessmentOrder,
						key,
						value
					}),
					assessmentContext: this.valueToEntity({
						entities: this.availableContexts,
						key,
						value
					}),
					codeTemplate: {
						codeTemplate: value
					},
					contextLabel: {
						contextLabel: value
					},
					customCode: {
						customCode: value
					},
					name: {
						name: value
					}
				}[key])));
		},

		confirmBreak () {
			confirmation({
				icon: 'mirror',
				warning: true,
				title: t('Break the connection'),
				message: t('Are you sure you want to break the connection')
			}).done(() => {
				this.breakConnection(this.item.path).then(() => {
					cwalert.success(t('Connection broken'));
				});
			});
		},

		submit () {
			const fields = assign({
				name: this.formData.name,
				contextLabel: this.context(this.formData) || this.item.fields.contextLabel
			}, {
				customCode: this.formData.customCode
			});
			this.$emit('save', {
				item: this.item,
				fields: assign({}, fields, this.entitiesToValues())
			});
		}
	}
};
</script>
