import t from 'translate';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import repository from 'repository';
import cardUrl from 'cwcardurl';
import datetime from 'datetime';
import store from 'store';
import userLink from 'components/user-link/user-link';
import { find, get, isUndefined, map } from 'lodash';
import systemSettings from 'system-settings';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import { EMPTY_CHAR } from '../../../../lib/chars';

const renderDate = (date) => date ?
	datetime(date)
		.setTimezone(store.state.user.timezoneName)
		.toMoment()
		.format(t('date.formats.dateTime')) :
	'';

const INC_DISABLED = 'includeDisabled';
const INC_ATTRS = 'includeCurrentAttributes';

export default SearchTileView.extend({
	title: t('Treatment search'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}],

	searchFn (params) {
		// b💩ckend needs `0`, any other falsy value will fail (obviously)
		params[INC_DISABLED] = params[INC_DISABLED] || 0;

		// b💩ckend handles `query` param name, not `search`
		if (!isUndefined(params.search)) {
			params.query = params.search;
			delete params.search;
		}

		if (find(this.config().columns, { entity: 'treatmentTypeAttribute' })) {
			params[INC_ATTRS] = 1;
		}

		return repository.searchTreatments(params, { omit: [INC_DISABLED, INC_ATTRS] });
	},

	fields: ({ tile }) => {
		tile.viewModel.set(INC_DISABLED, 0);

		return [{
			key: 'treatmentType.id',
			type: 'single-select',
			label: t('Treatment type'),
			values: map(store.getters['treatmentTypes/sortedByName'],
				(treatmentType) => ({
					id: treatmentType.id,
					label: treatmentType.name
				})),

			onChange: (value) => {
				tile.viewModel.set(
					'treatmentType.id',
					map(value, (val, key) => val ? key : undefined)
				);
				tile.runQuery();
			}
		}, {
			key: 'respondent.respondentGroup.id',
			type: 'single-select',
			label: t('Respondent group'),
			values: map(store.getters['respondentGroups/forCurrentClinician'],
				(respondentGroup) => ({
					id: respondentGroup.id,
					label: respondentGroup.name
				})),

			onChange: (value) => {
				tile.viewModel.set(
					'respondent.respondentGroup.id',
					map(value, (val, key) => val ? key : undefined)
				);
				tile.runQuery();
			}
		}, {
			key: INC_DISABLED,
			type: 'checkbox',
			label: t('Show disabled treatments'),
			startValue: 0,

			onChange: (value) => {
				tile.viewModel.set(INC_DISABLED, +value);
				tile.runQuery();
			}
		}];
	},

	tableCfg: ({ tile }) => {
		const className = `
			search-results-list__entry
			treatments-list__entry
			treatments-list__treatment-
		`;
		const mainClassName = 'search-results-list__entry--main';
		let columns = [];

		const defaultColumns = [{
			label: t('Respondent'),
			key: 'respondent',
			css: `${className}respondent`,
			render: (respondent) => userLink({ respondent }).$el
		}, {
			label: t('Treatment name'),
			key: 'name',
			css: `${className}name ${mainClassName}`
		}, {
			label: t('Status'),
			key: 'customStatus',
			css: `${className}status ${mainClassName}`,
			skip: () => !systemSettings.getBoolean('ENABLE_TREATMENT_STATUS')
		}, {
			label: t('Treatment type'),
			key: 'treatmentType',
			css: `${className}treatment-type`,
			render: (treatmentType) => treatmentType.name
		}, {
			label: t('Enabled'),
			key: 'enabled',
			css: `${className}enabled`,
			render: (enabled) => enabled ? t('yes') : t('no')
		}, {
			label: t('Start'),
			key: 'startAt',
			css: `${className}start`,
			render: renderDate
		}, {
			label: t('End'),
			key: 'endAt',
			css: `${className}end`,
			render: renderDate
		}];

		if (tile.config().columns) {
			columns = map(tile.config().columns, (col) => {
				if (col.entity === 'treatment') {
					return find(defaultColumns, { key: col.key });

				} else if (col.entity === 'treatmentTypeAttribute') {
					return {
						label: tile.t(col.label),
						css: `${className}treatment-type-attribute`,
						render: (fakeValue, { item }) => get(
							find(
								item.get('currentAttributes'),
								(attr) => +attr.typeAttribute === +col.key
							),
							'value',
							EMPTY_CHAR
						)
					};
				}
			});

		} else {
			columns = defaultColumns;
		}

		return {
			click: (model) => {
				cardUrl.openCard('treatment', {
					treatmentId: model.getId(),
					respondentId: model.get('respondent').id
				});
			},
			columns
		};
	}
});
