import appBar from 'app-bar';
import can from 'acl-can';
import t from 'translate';
import cwalert from 'cwalert';
import repository from 'repository';
import elementDialog from '../dialogs/edit-content-package-element';
import confirmation from 'components/confirmation/confirmation';

const card = {
	title: t('Change information package'),
	name: 'edit-content-package',
	url: 'content-package/:id/edit',
	ctrl: ({ cardData, close }, { id }) => {
		const contentPackage = repository.getContentPackageById(id);

		cardData.set({ contentPackage });

		return contentPackage.then(() => {
			let publishBtnView;

			// button visibility depends on information package status
			// if we change element in a information package we should be able to publish package
			contentPackage.on('change:status', () => {
				publishBtnView &&
				publishBtnView[contentPackage.isPublished() ? 'hide' : 'show']();
			});

			if (can.supervise('system.settings.informationpackages')) {
				publishBtnView = appBar.addButton(card.name, {
					title: t('Publish information package'),
					icon: 'upload',
					click () {
						confirmation({
							icon: 'publish',
							title: t('Publish information package'),
							message: t('Are you sure you want to publish the information package?')
						}).done(() => {
							this.setLoading();
							repository.publishContentPackage(contentPackage).then(() => {
								cwalert.success(t('Information package has been published'));
								close();
							});
						});
					},
					hide: contentPackage.isPublished()
				});
			}

			if (can.edit('system.settings.informationpackages')) {
				appBar.addButton(card.name, {
					title: t('Add information package element'),
					icon: 'add',
					click () {
						elementDialog(contentPackage);
					}
				});
			}

			if (can['delete']('system.settings.informationpackages')) {
				appBar.addButton(card.name, {
					title: t('Delete information package'),
					icon: 'delete',
					click () {
						confirmation({
							icon: 'delete',
							warning: true,
							title: t('Delete information package'),
							message: t('Are you sure you want to delete the information package?')
						}).done(() => {
							this.setLoading();
							contentPackage.destroy().done(() => {
								cwalert.success(t('Information package has been removed'));
								close();
							});
						});

						return false;
					}
				});
			}
		});
	}
};

export { card as Edit };
