/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import Label from './Label';

export default function (options = {}) {
	const rendererOptions = options.rendererOptions ?
		options.rendererOptions :
		{};

	if (options.displayValue) {
		rendererOptions.displayValue = options.displayValue;
	}

	if (typeof options.valueProcessor === 'function') {
		rendererOptions.valueProcessor = options.valueProcessor;
	}

	this.createRenderer = function (attributeName, model, opts) {
		if (options.disableAutoSave === undefined) {
			rendererOptions.disableAutoSave = opts.disableAutoSave;
		}

		return new Label(attributeName, model, rendererOptions, opts.mode);
	};
};
