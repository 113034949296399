var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          invalid: _vm.vuelidate.item.code.$error,
          label: "dataIntegration.codeVariations.fields.name",
          mandatory: true,
          options: _vm.codeOptions,
          placeholder: _vm.t("dataIntegration.codeVariations.actions.select"),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.code.$touch()
          }
        },
        model: {
          value: _vm.item.code,
          callback: function($$v) {
            _vm.$set(_vm.item, "code", $$v)
          },
          expression: "item.code"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: { "data-integration__container-inactive": !_vm.item.code } },
        [
          _c("text-input", {
            attrs: {
              disabled: true,
              label: _vm.t("dataIntegration.codeVariations.fields.code")
            },
            model: {
              value: _vm.codeValue,
              callback: function($$v) {
                _vm.codeValue = $$v
              },
              expression: "codeValue"
            }
          }),
          _vm._v(" "),
          _c("text-input", {
            attrs: {
              disabled: true,
              label: "dataIntegration.codeVariations.fields.codeType.name"
            },
            model: {
              value: _vm.codeTypeNameValue,
              callback: function($$v) {
                _vm.codeTypeNameValue = $$v
              },
              expression: "codeTypeNameValue"
            }
          }),
          _vm._v(" "),
          _c("text-input", {
            attrs: {
              disabled: true,
              label: "dataIntegration.codeVariations.fields.codeType.code"
            },
            model: {
              value: _vm.codeTypeCodeValue,
              callback: function($$v) {
                _vm.codeTypeCodeValue = $$v
              },
              expression: "codeTypeCodeValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }