import t from 'translate';
import store from 'store';
import Messages from '../../shared/messages-tile';
import { constant } from 'lodash';

export default Messages.extend({
	sender: constant('RESPONDENT'),
	features: ['ENABLE_MESSAGING_SYSTEM'],
	actions: () => {
		const { treatmentId, respondentId } = store.getters.urlParams;

		return [['treatment/getMessages', { treatmentId, respondentId }]];
	},
	acl: [{
		checkpoint: 'administrator.respondents.communication',
		op: 'READ'
	}],

	formConfig: ({ tile }) => !tile.cardContext().get('isNew') && ({
		name: 'treatment',
		model: tile.cardContext(),
		preventSave: true,
		fields: [{
			type: 'paragraph',
			key: 'treatmentId',
			label: t('Treatment'),
			value: store.getters.treatment.name
		}]
	})
});
