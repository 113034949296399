var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.codes.length
    ? _c(
        "table",
        {
          staticClass:
            "table assign-items__table data-integration__selected-codes"
        },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(_vm._s(_vm.t("dataIntegration.ruleSets.fields.code")))
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.t("dataIntegration.ruleSets.fields.codeType"))
                )
              ]),
              _vm._v(" "),
              _c("th")
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.codes, function(code) {
              return _c("tr", { key: code.id }, [
                _c("td", [
                  _c("p", { staticClass: "table-cell-label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.t("dataIntegration.ruleSets.fields.code")) +
                        "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v("\n\t\t\t\t" + _vm._s(code.name) + "\n\t\t\t")
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "table-cell-label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.t("dataIntegration.ruleSets.fields.codeType")
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v("\n\t\t\t\t" + _vm._s(code.codeType.name) + "\n\t\t\t")
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("button", {
                    staticClass: "icon-button__button icon icon--delete",
                    on: {
                      click: function($event) {
                        return _vm.$emit("delete", code.id)
                      }
                    }
                  })
                ])
              ])
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }