import store from 'store';
import t from 'translate';
import { isUndefined } from 'lodash';
import { when } from 'jquery';

const card = {
	title: t('Treatment search'),
	name: 'search-treatments',
	url: 'treatment/search/?q=(:search)',

	ctrl ({ cardConfig, cardData }, { search }) {
		!isUndefined(search) && store.dispatch('setSearchQuery', {
			cardName: this.name,
			query: search
		});

		cardData.set({ hideResults: cardConfig.startEmpty });

		return when(
			store.dispatch('treatmentTypes/initForCurrentClinician'),
			store.dispatch('respondentGroups/initForCurrentClinician')
		);
	}
};

export { card as searchTreatments };
