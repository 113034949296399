import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import Url from 'lib/url/url';
import can from 'acl-can';
import t from 'translate';
import repository from 'repository';
import { Model, Collection } from 'backbone';
import { DateTime } from 'luxon';
import { join } from 'lodash';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

const formatDate = (str) => DateTime.fromISO(str).toISO({
	suppressMilliseconds: true,
	includeOffset: false
});

const CHANGE_DATE_RANGE = 'change:dateRange';

export default TileView.extend({
	title: t('admin-portal.ExportAssessmentData'),
	acl: [{
		checkpoint: SYSTEM.EXPORT.ASSESSMENT_DATA,
		op: READ
	}],

	init () {
		this.assessments = repository.getAssessments();
	},

	onRender () {
		this.assessments.done(this.loaded.bind(this)).always(this.setLoaded.bind(this));
	},

	loaded () {
		const assessments = new Collection(this.assessments.where({
			assessmentExportAllowed: true
		}));

		if (!assessments.length) {

			this.$el.append(
				$('<h2/>').html(
					t('There are no exportable assessments available')
				)
			);
			return;
		}

		this.$el.append(
			$('<h2/>').html(t('system-manger.FilterExport'))
		);

		const DownloadModel = Model.extend({
			defaults: {
				assessmentId: assessments.first().get('assessmentId'),
				incomplete: false,
				calculation: true,
				anonymous: true,
				anonymousOwner: true,
				flag: false,
				testRespondent: false,
				patient: true,
				dateRange: 'ALL'
			},
			sync (method, model) {
				const { dateRange, dateRangeStart, dateRangeStop } = model.attributes;
				const dateParams = dateRange === 'CUSTOM' ?
					{
						dateRangeStart: formatDate(dateRangeStart),
						dateRangeStop: formatDate(dateRangeStop)
					} :
					{};

				model
					.unset('dateRangeStart', { silent: true })
					.unset('dateRangeStop', { silent: true });

				const dfd = $.Deferred();
				const url = new Url(
					join([
						'backend-commons/export/rest/assessmentInstanceData/all?',
						$.param(this.attributes),
						'&',
						decodeURIComponent($.param(dateParams))
					], ''),
					null,
					{
						querySeparator: '',
						prependBackendRoot: 'system'
					}
				);
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		const downloadModel = new DownloadModel();

		const cfg = {
			name: 'system-export-assessmentdata',
			model: downloadModel,
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: false,
			onAfterSave () {
				this.model.set('dateRange', 'ALL');
			},
			fields: [{
				key: 'assessmentId',
				label: t('system-manager.Assessment'),
				type: 'select',
				collection: assessments,
				labelField: 'assessmentName',
				keyField: 'assessmentId'
			}, {
				key: 'dateRange',
				name: 'date-range',
				label: t('Date range'),
				type: 'select',
				values: [{
					ALL: t('All')
				}, {
					WEEK: t('Last week')
				}, {
					MONTH: t('Last month')
				}, {
					QUARTER: t('Last quarter')
				}, {
					YEAR: t('Last year')
				}, {
					CUSTOM: t('Range…')
				}],
				customize: (view) => {
					view.listenTo(downloadModel, CHANGE_DATE_RANGE, () => {
						if (downloadModel.get('dateRange') === 'CUSTOM') {
							downloadModel.set({
								dateRangeStart: DateTime.local().toISODate(),
								dateRangeStop: DateTime.local().plus({ weeks: 2 }).toISODate()
							});
						}
					});
				}
			}, {
				key: 'dateRangeStart',
				name: 'from',
				label: t('From'),
				type: 'date',
				show: {
					event: CHANGE_DATE_RANGE,
					condition: (model) => model.get('dateRange') === 'CUSTOM'
				}
			}, {
				key: 'dateRangeStop',
				name: 'to',
				label: t('To'),
				type: 'date',
				show: {
					event: CHANGE_DATE_RANGE,
					condition: (model) => model.get('dateRange') === 'CUSTOM'
				}
			}, {
				key: 'anonymous',
				type: 'checkbox',
				label: t('Unidentified respondent'),
				readonly: !can.supervise(SYSTEM.EXPORT.ASSESSMENT_DATA)
			}, {
				key: 'anonymousOwner',
				type: 'checkbox',
				label: t('Unidentified owner'),
				readonly: !can.edit(SYSTEM.EXPORT.MODIFY_ANONYMOUS_OWNER)
			}, {
				key: 'incomplete',
				type: 'checkbox',
				label: t('system-manager.Incomplete')
			}, {
				key: 'calculation',
				type: 'checkbox',
				label: t('system-manager.Calculation')
			}, {
				key: 'flag',
				type: 'checkbox',
				label: t('system-manager.Flag')
			}, {
				key: 'patient',
				type: 'checkbox',
				label: t('system-manager.ProductionData')
			}, {
				key: 'testRespondent',
				type: 'checkbox',
				label: t('system-manager.TestData')
			}],

			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
