<template>
	<div class="edit-treatment-program">
		<div class="edit-treatment-program__layout">
			<div>
				<program-editor />
			</div>
			<div>
				<properties-panel />
			</div>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints';
import { EDIT } from 'service/acl/access-levels';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';
import { CONTENT_PACKAGES } from 'store/content-packages/content-packages';

import PropertiesPanel from './properties-panel';
import ProgramEditor from './editors/program-editor';

export default {
	title: t('treatmentProgram.edit'),

	acl: [{
		checkpoint: SYSTEM.SETTINGS.TREATMENT_PROGRAMS,
		op: EDIT
	}],

	actions: () => ([
		TREATMENT_PROGRAM.INIT,
		CONTENT_PACKAGES.INIT_AVAILABLE
	]),

	components: {
		PropertiesPanel,
		ProgramEditor
	}
};
</script>
