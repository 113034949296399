import $ from 'jquery';

export default {
	systemEventLogs: {
		url: 'backend-commons/log/rest/log'
	},
	systemEventLog: {
		url: 'backend-commons/log/rest/log'
	},
	search: {
		url: (params) => `backend-commons/log/rest/log/query?${$.param(params)}`
	},
	codes: {
		url: 'backend-commons/log/rest/log/statusType/all'
	},
	exportLogs: () => `backend-commons/export/system-events/`
};
