import MultipleEntities from '../shared/multiple-entities';
import ClinicianGroup from './clinician-group';
import urls from './urls';

const ClinicianGroupsEntity = MultipleEntities.extend({
	model: ClinicianGroup,
	urls,
	eventNamespace: 'clinician-group',
	idName: 'administratorGroupId',

	getAll () {
		return this.retrieve('listGroups');
	}
});

export default new ClinicianGroupsEntity();
