import MultipleEntities from '../shared/multiple-entities';
import Element from './element';
import urls from './urls';

const ContentPackageElements = MultipleEntities.extend({
	model: Element,
	urls,
	eventNamespace: 'information-package-element',
	order: 'order',
	canAdd (model) {
		return +model.get('informationPackage') === +this.ownerId;
	},

	getAll (packageId) {
		return this.retrieve('listAllElements', packageId);
	}
});

export default new ContentPackageElements();
