var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clinician-assigned-groups time-restricted-access" },
    [
      _c(
        "form",
        {
          staticClass: "form-view",
          attrs: { novalidate: _vm.omitValidation },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "form-view__field-container form-view__field-container--text",
              class: _vm.isInvalid
            },
            [
              _c("select-input", {
                attrs: {
                  options: _vm.groups,
                  disabled: !_vm.canAssign,
                  "item-label": "name",
                  label: _vm.$t("- select clinician group to add -")
                },
                model: {
                  value: _vm.formData.group,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "group", $$v)
                  },
                  expression: "formData.group"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form__date-range" },
            [
              _c("datetime-input", {
                staticClass: "datetime-input",
                attrs: {
                  label: _vm.$t("Start date"),
                  "class-prefix": "clinician-group"
                },
                model: {
                  value: _vm.formData.validFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "validFrom", $$v)
                  },
                  expression: "formData.validFrom"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "date-separator" }, [_vm._v("―")]),
              _vm._v(" "),
              _c("datetime-input", {
                staticClass: "datetime-input",
                attrs: {
                  label: _vm.$t("End date"),
                  "class-prefix": "clinician-group"
                },
                model: {
                  value: _vm.formData.validTo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "validTo", $$v)
                  },
                  expression: "formData.validTo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("submit-button", { attrs: { label: _vm.$t("Save") } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "table__container" }, [
        _vm.assignedGroups.length
          ? _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("Group name")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Group category")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Valid")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Valid from")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Valid until")))]),
                  _vm._v(" "),
                  _vm.canRemove ? _c("th") : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.assignedGroups, function(group, index) {
                  return _c("tr", { key: group.id }, [
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("Group name")) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.handleEmpty(group.name)) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("Group category")) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.handleEmpty(group.category)) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("p", { staticClass: "table-cell-label" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("Valid")) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("icon", {
                          class: _vm.groupValid(group).icon,
                          attrs: {
                            name: "check-circle",
                            title: _vm.groupValid(group).title
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("Valid from")) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.handleDate(group.validFrom)) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "table-cell-label" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("Valid until")) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.handleDate(group.validTo)) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _vm.canRemove
                      ? _c("td", { staticClass: "table__row-actions" }, [
                          _c(
                            "div",
                            { staticClass: "delete-button__container" },
                            [
                              _c("button", {
                                staticClass:
                                  "delete-button__action icon icon--delete icon-2x",
                                attrs: { tabindex: "0" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeGroup(group, index)
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            ])
          : _c("p", [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.$t("The clinician has no groups assigned")) +
                  "\n\t\t"
              )
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }