import t from 'translate';
import store from 'store';

const card = {
	title: t('Add a treatment'),
	name: 'add-treatment',
	url: [
		'respondent/:respondentId/add-treatment',
		'add-treatment'
	],

	ctrl: ({ populate }, { respondentId }) =>  {
		if (!respondentId) {
			store.dispatch('treatment/resetId');
			store.dispatch('respondent/resetId');
			populate();

		} else {
			store.dispatch('respondent/init', { respondentId }).then(() => {
				populate();
			});
		}
	}
};

export { card as addTreatment };
