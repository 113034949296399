<template>
	<div class="tile-list">
		<button
			v-if="allowEdit"
			class="tile-list__add-tile"
			@click="addTile"
		>{{t('cardConfig.tileList.addBlankTile')}}</button>
		<button
			v-if="allowEdit"
			class="tile-list__add-tile"
			@click="importTile"
		>{{t('cardConfig.tileList.importTile')}}</button>
		<section class="tile-list__list-container">
			<table class="tile-list__list">
				<thead>
					<tr>
						<th scope="col" />
						<th
							v-for="(field, index) in fields"
							:key="index"
							class="tile-list__list-title"
							:class="'tile-list__list-title--' + field.name"
							scope="col"
						>{{label[field.name]}}</th>
						<th scope="col" />
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(tile, index) in tiles"
						:key="index"
						class="tile-list__list-row"
						tabindex="0"
						@click.stop="editTile(tile, index)"
						@keyup.prevent.enter.space="editTile(tile, index)"
					>
						<td class="tile-list__list-item tile-list__list-item--order">
							<button-up
								v-if="canMoveUp(index)"
								:on-click="moveUp(index)"
							/><br />
							<button-down
								v-if="canMoveDown(index)"
								:on-click="moveDown(index)"
							/>
						</td>

						<td
							v-for="(field, fieldIndex) in fields"
							:key="fieldIndex"
							class="tile-list__list-title"
							:class="'tile-list__list-item--' + field.name"
						>
							<p class="tile-list__list-value">
								{{fieldValue(tile, field)}}
							</p>
						</td>

						<td class="tile-list__list-item tile-list__list-item--remove">
							<button-remove
								v-if="allowDelete"
								:disabled="removingTile"
								:on-click="remove(tile, index)"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
		<button
			v-if="allowEdit"
			class="tile-list__add-tile"
			@click="addTile"
		>{{t('cardConfig.tileList.addBlankTile')}}</button>
		<button
			v-if="allowEdit"
			class="tile-list__add-tile"
			@click="importTile"
		>{{t('cardConfig.tileList.importTile')}}</button>
	</div>
</template>

<script>
import t from 'translate';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import addTile from './add-tile-dialog';
import { get, isNumber, isString } from 'lodash';
import can from 'acl-can';
import importJsonConfig from '../../dialogs/import-json-config';
import { EMPTY_CHAR } from 'lib/chars';

export default {
	data: () => ({
		removingTile: false,
		label: {
			name: t('Name'),
			title: t('Title'),
			width: t('Width'),
			height: t('Height'),
			column: t('Column'),
			micro: t('Micro'),
			collapse: t('Collapse'),
			tileConfig: t('Tile config.')
		},
		fields: [{
			name: 'name',
			value: 'tileName'
		}, {
			name: 'title',
			value: 'customTitle',
			presentValue: (value) => value || EMPTY_CHAR
		}, {
			name: 'width',
			value: 'containerConfig.width',
			presentValue: (value) => value || EMPTY_CHAR
		}, {
			name: 'height',
			value: 'containerConfig.height',
			presentValue: (value) => value || EMPTY_CHAR
		}, {
			name: 'column',
			value: 'containerConfig.column'
		}, {
			name: 'micro',
			value: 'containerConfig.micro'
		}, {
			name: 'collapse',
			value: 'containerConfig.collapse',
			presentValue: (value) => ({
				true: t('Yes'),
				false: t('No')
			})[!!value]
		}, {
			name: 'tileConfig',
			value: 'tileConfig'
		}]
	}),

	computed: {
		tiles: ({ $store }) => $store.getters['cards/tiles/list'],
		allowEdit: () => can.edit('system.settings.settings'),
		allowDelete: () => can.delete('system.settings.settings')
	},

	methods: {
		addTile,
		fieldValue (tile, field) {
			const rawValue = get(tile, field.value);
			const yesNo = (val) => val ? t('Yes') : t('No');
			const value = field.presentValue ? field.presentValue(rawValue) : rawValue;

			return isString(value) || isNumber(value) ? value : yesNo(value);
		},
		editTile (tile, tileIndex) {
			let variant = this.$store.state.cards.variantIndex;

			if (!variant) {
				variant = String(variant);
			}
			cardUrl.openCard('edit-tile', {
				type: this.$store.state.cards.selected,
				cardName: this.$store.getters['cards/currentCard'].name,
				variant,
				tileIndex: String(tileIndex),
				tileName: tile.tileName
			});
		},

		importTile () {
			importJsonConfig({ create: true });
		},

		moveUp (index) {
			return () => {
				this.$store.dispatch('cards/moveTileUp', index);
			};
		},

		moveDown (index) {
			return () => {
				this.$store.dispatch('cards/moveTileDown', index);
			};
		},

		canMoveUp (index) {
			return index > 0 && this.allowEdit;
		},

		canMoveDown (index) {
			return index < this.tiles.length - 1 && this.allowEdit;
		},

		remove (tile, index) {
			return () => {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Remove tile'),
					message: t('cardConfig.tileList.removeConfirmation', {
						tileName: tile.tileName,
						cardName: this.$store.getters['cards/currentCard'].name
					})
				})
					.then(() => {
						this.removingTile = true;
						return this.$store.dispatch('cards/removeTile', index);
					})
					.then(() => {
						this.removingTile = false;
						cwalert.success(t('cardConfig.tileList.removeSuccess', {
							tileName: tile.tileName
						}));
					});
			};
		}
	}
};
</script>
