import mixin from './_mixin';
import t from 'translate';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { concat, find, filter, map } from 'lodash';

export default (modalCfg) => new Vue({
	mixins: [mixin(modalCfg)],
	data: {
		updating: false
	},
	computed: {
		...mapGetters({
			codes: 'dataIntegration/dataMappingList',
			defaultRuleSet: 'dataIntegration/ruleSets/default',
			enterprises: 'dataIntegration/enterprises/list',
			ruleSetsWithTreatmentTypes: 'dataIntegration/ruleSets/withTreatmentTypes',
			treatmentTypes: 'dataIntegration/treatmentTypes'
		}),
		availableTreatmentTypes () {
			return filter(this.treatmentTypes, (treatmentType) =>
				!find(this.ruleSetsWithTreatmentTypes, (ruleSet) =>
					ruleSet.treatmentType && ruleSet.treatmentType.id === treatmentType.id));
		},
		defaultRule () {
			return !!this.defaultRuleSet.length;
		},
		treatmentTypeOptions () {
			const availableOptions = map(this.availableTreatmentTypes, (single) => ({
				enterprise: single.enterprise,
				label: single.name,
				value: single.id
			}));
			return concat(
				[{ label: t('dataIntegration.ruleSets.options.default'), value: null }],
				availableOptions
			);
		}
	},
	template: `
		<div>
			<standard-dialog
				:config="config"
				:defaultRule="defaultRule"
				:enterprises="enterprises"
				:processing="processing"
				:treatment-types="treatmentTypes"
				:treatmentTypeOptions="treatmentTypeOptions"
				:updating="updating"
				:vuelidate="$v"
				:warnings="warnings"
			/>
		</div>
		`,
	validations: () => ({
		item: {
			defaultAccess: { required },
			defaultCodes: { required },
			defaultMappedTo: { required },
			enterprise: { required },
			treatmentType: {}
		}
	})
});
