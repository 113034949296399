import { Model, View } from 'backbone';
import $ from 'jquery';
import settings from 'system-settings';
import formView from 'components/form-view/form-view';
import t from 'translate';
import moment from 'moment';
import { assign, toUpper } from 'lodash';

const boolOptions = [{
	YES: t('Yes')
}, {
	NO: t('No')
}];

const timeOptions =  [{
	DAYS: t('Days')
}, {
	HOURS: t('Hours')
}, {
	MINUTES: t('Minutes')
}];

const setBoolOptions = () => [...boolOptions];
const setTimeOptions = () => [...timeOptions];

export default View.extend({

	initialize (params) {
		assign(this, params);
		this.render();
	},

	refresh ({ plan, simulator }) {
		this.plan = plan;
		this.simulator = simulator;
	},

	render () {
		const inst = this;

		this.$el.append($('<h1 />').html(t('Workflows.SimulatorSettings')));

		let assessmentDeadlineUnit = settings.getString('DEFAULT_ASSESSMENT_DEADLINE_UNIT');

		const settingsobj = {
			assessmentDelivery: 'YES',
			assessmentDeliveryTime: 5,
			assessmentDeliveryUnit: 'DAYS',
			assessmentDeadlineTime: settings.getInteger('DEFAULT_ASSESSMENT_DEADLINE'),
			assessmentDeadlineUnit: toUpper(assessmentDeadlineUnit),
			assessmentDataDefaultBranch: 'YES',
			consentDelivery: 'YES',
			consentDeliveryTime: 5,
			consentDeliveryUnit: 'DAYS',
			decision: 'YES',
			decisionTime: 5,
			decisionUnit: 'DAYS',
			decisionDefaultBranch: 'YES',
			executionTime: moment().seconds(0).milliseconds(0),
			automatic: true
		};

		const settingsModel = new Model(settingsobj);

		const getSettings = function (automatic) {
			const settingsobj = $.extend(true, {}, settingsModel.attributes);

			settingsobj.assessmentDelivery = settingsModel.get('assessmentDelivery') === 'YES';
			settingsobj.consentDelivery = settingsModel.get('consentDelivery') === 'YES';
			settingsobj.decision = settingsModel.get('decision') === 'YES';
			settingsobj.automatic = automatic;

			return settingsobj;
		};

		assessmentDeadlineUnit = settingsModel.get('assessmentDeadlineUnit');
		switch (settingsModel.get('assessmentDeadlineUnit')) {
		case 'HOURS':
			assessmentDeadlineUnit = t('Hours');
			break;
		case 'MINUTES':
			assessmentDeadlineUnit = t('Minutes');
			break;
		default:
			assessmentDeadlineUnit = t('Days');
		}

		const cfg = {
			name: 'edit-simulator-settings',
			model: settingsModel,
			listenTo: [],
			loader: false,
			preventSave: true,
			fields: [{
				key: 'executionTime',
				label: t('Execution time'),
				type: 'datetime'
			}, {
				key: 'assessmentDelivery',
				label: t('Deliver assessments?'),
				type: 'radio',
				values: setBoolOptions()
			}, {
				key: 'assessmentDeliveryTime',
				label: t('Assessment delivery time'),
				type: 'number'
			}, {
				key: 'assessmentDeliveryUnit',
				label: t('Assessment delivery unit'),
				type: 'select',
				values: setTimeOptions()
			}, {
				key: 'assessmentDeadline',
				label: t('Assessment deadline'),
				type: 'paragraph',
				value: `${settingsModel.get('assessmentDeadlineTime')} ${assessmentDeadlineUnit}`
			}, {
				key: 'consentDelivery',
				label: t('Deliver consents'),
				type: 'radio',
				values: setBoolOptions()
			}, {
				key: 'consentDeliveryTime',
				label: t('Consent delivery time'),
				type: 'number'
			}, {
				key: 'consentDeliveryUnit',
				label: t('Consent delivery unit'),
				type: 'select',
				values: setTimeOptions()
			}, {
				key: 'decision',
				label: t('Make decision?'),
				type: 'radio',
				values: setBoolOptions()
			}, {
				key: 'decisionTime',
				label: t('Decision time'),
				type: 'number'
			}, {
				key: 'decisionUnit',
				label: t('Decision unit'),
				type: 'select',
				values: setTimeOptions()
			}, {
				key: 'decisionDefaultBranch',
				label: t('Default decision branch'),
				type: 'radio',
				values: setBoolOptions()
			}, {
				key: 'assessmentDataDefaultBranch',
				label: t('Default assessment data branch'),
				type: 'radio',
				values: setBoolOptions()
			}],

			buttons: [{
				caption: t('Workflows.SimulatorAutomatic'),
				name: 'automatic',
				type: 'submit',
				action () {
					inst.simulator.reset();
					inst.simulator.set('settings', getSettings(true));
					inst.simulator.play(getSettings(true));
				}
			}, {
				caption: t('Workflows.SimulatorStart'),
				name: 'manual',
				type: 'submit',
				action () {
					inst.simulator.reset();
					inst.simulator.set('settings', getSettings(false));
					inst.simulator.play(getSettings(false));
				}
			}, {
				caption: t('Reset'),
				name: 'reset',
				type: 'button',
				action () {
					inst.simulator.reset();
				}
			}]

		};

		this.$el.append(formView(cfg).$el);
	}
});

