import MultipleEntities from '../shared/multiple-entities';
import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * SystemEventsTypes Entity
 * @class SystemEventLogs
 * @extends MultipleEntities
 */
const SystemEventsTypes = MultipleEntities.extend({
	model: SingleEntity.extend({
		eventNamespace: 'system-event-type'
	}),
	urls,
	eventNamespace: 'system-event-type',

	// backend returns two arrays in one object, one of them being `status`
	parse: (response) => response.type,

	/**
	 * Get all system event log types
	 *
	 * @method getAll
	 * @return {Object} new {{#crossLink "SystemEventsTypes"}}{{/crossLink}} Collection
	 */
	getAll: function () {
		return this.retrieve('codes');
	}
});

export default new SystemEventsTypes();

