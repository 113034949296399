import mediaList from './media-list/tile';
import editMedia from './edit-media/tile';
import viewMedia from './view-media/tile';
import mediaCategories from './system-settings-media-categories/tile';

export default {
	'media-list': mediaList,
	'edit-media': editMedia,
	'view-media': viewMedia,
	'system-settings-media-categories': mediaCategories
};
