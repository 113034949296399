import { when } from 'jquery';
import appBar from 'app-bar';
import can from 'acl-can';
import t from 'translate';
import cwalert from 'cwalert';
import repository from 'repository';
import confirmation from 'components/confirmation/confirmation';
import store from 'store';
import { get } from 'lodash';

const card = {
	title: t('Change treatment type'),
	name: 'edit-treatment-type',
	url: 'treatment-type/:treatmentTypeId/edit',

	ctrl: ({ cardData, close }, { treatmentTypeId }) => {
		const roles = repository.getTreatmentTypeRoles(treatmentTypeId);
		const attributes = repository.treatmentTypeAttributes(treatmentTypeId);
		cardData.set({ roles, attributes });

		return when(
			roles,
			store.dispatch('treatmentType/init', { treatmentTypeId }),
			store.dispatch('treatmentType/getClinicianGroups', { treatmentTypeId }),
			attributes
		).then(() => {
			cardData.set({
				treatmentTypeClinicianGroups:
					store.getters['treatmentType/clinicianGroupsColection']
			});
			const canRemove = can.remove('system.settings.treatment-types');

			if (canRemove && !store.getters['treatmentType/data'].isDefault) {
				appBar.addButton(card.name, {
					title: t('Delete treatment type'),
					icon: 'delete',
					click: () => {
						confirmation({
							icon: 'delete',
							warning: true,
							title: t('Delete treatment type'),
							message: t('You are about to delete the treatment type?')
						}).done(() => {
							store.dispatch('treatmentType/deleteTreatmentType', treatmentTypeId)
								.then(() => {
									cwalert.success(t('Treatment type has been removed'));
									close({ go: 'treatment-type/list' });
									store.dispatch('treatmentTypes/initAll');
								}, ({ responseText }) => {
									const errMsg = get(
										JSON.parse(responseText),
										'errors.id',
										'Error'
									);
									cwalert.error(t(`backendDictionary.errors.${
										errMsg
									}`));
								});
						});

						return false;
					}
				});
			}
		});
	}
};

export { card as edit };
