import assessmentAttribute from './assessment-attribute';
import assessmentCalculation from './assessment-calculation';
import assessmentFlag from './assessment-flag';
import assessmentQuestion from './assessment-question';
import exportTemplate from './export-template';
import respondentAttribute from './respondent-attribute';
import respondentCustomAttribute from './respondent-custom-attribute';
import respondentCustomAttributeHistory from './respondent-custom-attribute-history';
import treatmentAttribute from './treatment-attribute';
import treatmentCustomAttribute from './treatment-custom-attribute';
import group from './group';

export default {
	assessmentAttribute,
	assessmentCalculation,
	assessmentFlag,
	assessmentQuestion,
	exportTemplate,
	respondentAttribute,
	respondentCustomAttribute,
	respondentCustomAttributeHistory,
	treatmentAttribute,
	treatmentCustomAttribute,
	folder: group
};
