import cardUrl from 'cwcardurl';
import t from 'translate';
import repository from 'repository';
import can from 'acl-can';
import systemSettings from 'system-settings';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import listFields from '../../shared/list-fields';

export default SearchTileView.extend({
	title: t('Unseen assessments'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: READ
	}, {
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS,
		op: READ
	}, {
		checkpoint: CLINICIAN.CLINICIANS.ASSESSMENTS,
		op: READ
	}],

	cardData: () => ['limit'],

	searchFn: repository.getUnseenAssessments.bind(repository),

	tableCfg () {
		const { deadline, treatment, respondent, title, delivery } = listFields();
		return {
			className: 'table--long-text',
			click: (assessmentInstance) => {
				cardUrl.openCard('assessment-properties', {
					assessmentInstanceId: assessmentInstance.getId()
				});
			},

			rowsClickable: can.read(CLINICIAN.RESPONDENTS.RESPONDENTS) &&
				can.read(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS),

			columns: [
				deadline,
				treatment,
				respondent,
				title,
				{
					label: t('Type'),
					css: 'assessment-list__type',
					skip: () => !systemSettings.getBoolean('ASSESSMENT_TYPES'),
					key: 'typeLabel'
				},
				{
					label: t('Context'),
					css: 'assessment-list__context',
					key: 'assessmentInstanceContextLabel'
				},
				delivery
			]
		};
	}
});
