import appBar from 'app-bar';
import roleDialog from '../dialogs/edit-role-wrapper';
import t from 'translate';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	title: t('Roles'),
	name: 'roles',
	url: 'roles',

	ctrl: ({ populate }) =>  {
		if (can.add(SYSTEM.SETTINGS.TREATMENT_ROLES)) {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'add',
				title: t('Add role'),
				click: () => {
					roleDialog({
						role: {},
						action: 'roles/addRole',
						successMsg: t('Role has been set'),
						title: 'New role',
						done: populate
					});
				}
			});
		}
		populate();
	}
};

export { card as roles };
