import Backbone from 'backbone';
import formView from 'components/form-view/form-view';
import t from 'translate';
import { assign, toLower, reduce } from 'lodash';
import $ from 'jquery';
import datetime from 'datetime';
import repository from 'repository';

const PlayerView = Backbone.View.extend({

	initialize (params) {
		assign(this, params);

		this.$el.empty();
		this.$dialog = $('<div />')
			.addClass('workflow-simulator')
			.attr('id', 'simulator-dialog')
			.appendTo(this.$el);
		this.render();
	},

	render () {
		const inst = this;
		const tile = inst.tile;

		// needed for correct autosubmit check with multiple assessment plans
		let simulationStart = false;
		let manual = false;

		this.listenTo(this.simulator.getAssessments(), 'add', function (simAssessment) {
			const autoSubmit = reduce(this.simulator.get('plan').get('diagram'), (acc, curr) => {
				if (
					curr.properties &&
					curr.properties.autoSubmit &&
					curr.properties.assessment === simAssessment.get('assessmentId')
				) {
					return acc + +curr.properties.autoSubmit;
				}
				return acc;
			}, 0);

			if (!simulationStart) {
				simulationStart = true;
				manual = !inst.simulator.get('settings').automatic;
			}

			if (manual) {
				inst.simulator.get('settings').automatic = !!autoSubmit;
			}

			if (inst.simulator.get('settings').automatic) {
				return;
			}

			this.$dialog.empty().append(
				this.getAssessment({
					simAssessment
				}).$el
			);
			tile.showModal(this.$dialog);
		});

		this.listenTo(this.simulator.getConsents(), 'add', function (simConsent) {
			if (!inst.simulator.get('settings').automatic) {
				this.$dialog.empty().append(
					this.getConsent({ simConsent }).$el
				);
				tile.showModal(this.$dialog);
			}
		});

		this.listenTo(this.simulator.getDecisions(), 'add', function (simDecision) {
			if (inst.simulator.get('settings').automatic) {
				return;
			}

			this.$dialog.empty().append(
				this.getDecision({
					simDecision
				}).$el
			);
			tile.showModal(this.$dialog);
		});

		this.listenTo(this.simulator.getDataBranches(), 'add', function (simDatabranch) {
			if (inst.simulator.get('settings').automatic) {
				return;
			}
			this.$dialog.empty().append(
				this.getDataBranch({
					simDatabranch
				}).$el
			);
			tile.showModal(this.$dialog);
		});
	},

	getHeader (type, text) {
		return [
			$('<div />')
				.css({
					width: '36pt',
					margin: '0 auto',
					position: 'relative',
					float: 'left'
				})
				.append($('<img />').attr({
					src: `images/assessmentplan/${type}.svg`,
					type: 'image/svg+xml'
				}).addClass('workflow-simulator-player__icon')),
			$('<h2 />').css('margin-left', '40pt').html(text)
		];
	},

	getDecision (params) {
		const inst = this;
		const simDecision = params.simDecision;

		const View = Backbone.View.extend({
			className: 'workflow-simulator-player workflow-simulator-player-decision',

			initialize () {
				this.render();
			},

			render () {
				const decisionTime = inst.simulator.get('settings').decisionTime;
				const issueDate = datetime(simDecision.getIssueDate())
					.toMoment()
					.add(+decisionTime, toLower(inst.simulator.get('settings').decisionUnit));

				let decisionDate = issueDate.toDate();

				this.$el.empty().append(
					inst.getHeader('decision',
						t('Workflows.SimulatorDecision', {
							num: inst.simulator.getDecisions().length
						}))
				);
				this.$el.append($('<p />').html(simDecision.get('text')));

				inst.dummy = new Backbone.Model({
					decision: inst.simulator.get('settings').decision,
					decisionTime: issueDate.valueOf(),
					decisionBranch: inst.simulator.get('settings').decisionDefaultBranch
				});

				inst.dummy.on('change:decisionTime', () => {
					decisionDate = datetime(inst.dummy.get('decisionTime')).toDate();
				});

				const makeDecision = function (continueRun) {
					if (+inst.dummy.get('decision')) {
						inst.simulator.makeDecision(
							simDecision,
							inst.dummy.get('decisionBranch'),
							decisionDate
						);
					}
					inst.tile.closeModal();
					const settings = inst.simulator.get('settings');
					settings.automatic = continueRun;
					inst.simulator.set('settings', settings);
					inst.simulator.continueRun(continueRun);
				};

				const cfg = {
					name: 'edit-simulator-decision',
					model: inst.dummy,
					listenTo: ['submit'],
					loader: false,
					preventSave: true,
					fields: [{
						key: 'decision',
						label: t('Make decision?'),
						type: 'radio',
						values: [{
							1: t('Yes')
						}, {
							0: t('No')
						}]
					}, {
						key: 'decisionTime',
						label: t('Decision time'),
						type: 'datetime'
					}, {
						key: 'decisionBranch',
						label: t('Decision'),
						type: 'radio',
						values: [{
							YES: t('Yes')
						}, {
							NO: t('No')
						}]
					}],

					buttons: [{
						caption: t('Workflows.SimulatorContinueAutomatic'),
						name: 'automatic',
						type: 'submit',
						action () {
							makeDecision(true);
						}
					}, {
						caption: t('Workflows.SimulatorPlayContinueManual'),
						name: 'manual',
						type: 'submit',
						action () {
							makeDecision(false);
						}
					}]
				};

				this.$el.append(formView(cfg).$el);
			}
		});
		return new View();
	},

	getConsent (params) {
		const inst = this;
		const simConsent = params.simConsent;

		const View = Backbone.View.extend({
			className: 'workflow-simulator-player workflow-simulator-player-consent',
			initialize () {
				this.render();
			},
			render () {
				let deliveryDate = inst.getDeliveryDate(simConsent);
				this.$el.empty().append(
					inst.getHeader('consent', t('Workflows.SimulatorConsentAssigned'))
				);
				inst.appendContent({
					el: this.$el,
					assignLabel: 'Workflows.SimulatorConsentAssignAtDate',
					name: simConsent.get('consentName'),
					startDate: datetime(simConsent.getStartDate()).toMoment()
				});
				inst.setDummy({ inst, deliveryDate, field: 'consentDelivery' });

				inst.dummy.on('change:deliveryTime', () => {
					deliveryDate = new Date(inst.dummy.get('deliveryTime'));
				});
				const submit = function (continueRun) {
					if (+inst.dummy.get('delivery')) {
						inst.simulator.submitConsent(simConsent, deliveryDate);
					}
					inst.proceedSubmit(continueRun);
				};
				const cfg = inst.setConfig({ inst, label: 'Deliver consent?', submit });
				this.$el.append(formView(cfg).$el);
			}
		});
		return new View();
	},

	appendContent: ({ assignLabel, el, name, startDate }) => {
		el.append($('<h5>').html(name));
		el.append($('<span>').html(
			t(assignLabel, { date: startDate.format(t('date.formats.dateTime')) })
		));
	},

	getDeliveryDate (sim) {
		const deliveryTime = this.simulator.get('settings').assessmentDeliveryTime;
		const deliveryUnit = this.simulator.get('settings').assessmentDeliveryUnit;
		const startDate = datetime(sim.getStartDate()).toMoment();
		return startDate.add(+deliveryTime, toLower(deliveryUnit));
	},

	proceedSubmit (continueRun) {
		this.tile.closeModal();
		const settings = this.simulator.get('settings');
		settings.automatic = continueRun;
		this.simulator.set('settings', settings);
		this.simulator.continueRun(continueRun);
	},

	setConfig: ({ inst, label, submit }) => ({
		name: 'edit-simulator-assessment',
		model: inst.dummy,
		listenTo: ['submit'],
		loader: false,
		preventSave: true,
		fields: [{
			key: 'delivery',
			label: t(label),
			type: 'radio',
			values: [{
				1: t('Yes')
			}, {
				0: t('No')
			}]
		}, {
			key: 'deliveryTime',
			label: t('Delivery time'),
			type: 'datetime'
		}],

		buttons: [{
			caption: t('Workflows.SimulatorContinueAutomatic'),
			name: 'automatic',
			type: 'submit',
			action () {
				submit(true);
			}
		}, {
			caption: t('Workflows.SimulatorPlayContinueManual'),
			name: 'manual',
			type: 'submit',
			action () {
				submit(false);
			}
		}]
	}),

	setDummy: ({ inst, deliveryDate, field }) => {
		inst.dummy = new Backbone.Model({
			delivery: inst.simulator.get('settings')[field],
			deliveryTime: deliveryDate.valueOf()
		});
	},

	getAssessment (params) {
		const inst = this;
		const simAssessment = params.simAssessment;
		const assessment = inst.assessments.findWhere({
			assessmentId: simAssessment.get('assessmentId')
		});

		const View = Backbone.View.extend({
			className: 'workflow-simulator-player workflow-simulator-player-assessment',

			initialize () {
				this.render();
			},

			render () {
				let deliveryDate = inst.getDeliveryDate(simAssessment);

				this.$el.empty().append(
					inst.getHeader('assessment',
						t('Workflows.SimulatorAssessmentAssigned', {
							num: inst.simulator.getAssessments().length
						}))
				);
				inst.appendContent({
					el: this.$el,
					assignLabel: 'Workflows.SimulatorAssessmentAssignAtDate',
					name: assessment.get('assessmentName'),
					startDate: datetime(simAssessment.getStartDate()).toMoment()
				});

				inst.setDummy({ inst, deliveryDate, field: 'assessmentDelivery' });

				inst.dummy.on('change:deliveryTime', () => {
					deliveryDate = new Date(inst.dummy.get('deliveryTime'));
				});

				const submit = function (continueRun) {
					if (+inst.dummy.get('delivery')) {
						inst.simulator.submitAssessment(simAssessment, deliveryDate);
					}
					inst.proceedSubmit(continueRun);
				};

				const cfg = inst.setConfig({ inst, label: 'Deliver assessment?', submit });

				this.$el.append(formView(cfg).$el);

			}
		});
		return new View();
	},

	getDataBranch (params) {
		const inst = this;
		const simDatabranch = params.simDatabranch;

		const View = Backbone.View.extend({
			className: 'workflow-simulator-player workflow-simulator-player-databranch',
			initialize () {
				this.render();
			},
			render () {
				const assessment = inst.assessments.findWhere({
					assessmentId: simDatabranch.get('assessment')
				});

				this.$el.empty()
					.append(
						inst.getHeader('evaluate-data',
							t('Workflows.SimulatorDataBranch', {
								num: inst.simulator.getDataBranches().length
							}))
					).append($('<h4 />').html(assessment.get('assessmentName')));

				const $description = $('<span />');
				let heading = '';

				if (simDatabranch.getType() === 'FLAG') {
					$description.html(`${simDatabranch.get('flag')}: ` + `raised?`);
					heading = t('Flag');
				} else if (simDatabranch.getType() === 'CALCULATION') {
					$description.html(simDatabranch.get('calculation'));
					heading = t('Calculation');
				} else {
					$description.html(simDatabranch.get('question'));
					heading = t('Question');
					const questions = repository.getAssessmentQuestions(
						simDatabranch.get('assessment')
					);
					questions.done(() => {
						const q = questions.findWhere({
							assessmentQuestionCode: simDatabranch.get('question')
						});
						$description.html(`${simDatabranch.get('question')} : ${q.getName()}`);

					});
				}

				this.$el.append($('<h5 />').html(heading));
				this.$el.append($('<p />').append($description));

				inst.dummy = new Backbone.Model({
					dataBranch: inst.simulator.get('settings').assessmentDataDefaultBranch
				});

				const selectBranch = function (continueRun) {
					const settings = inst.simulator.get('settings');
					settings.automatic = continueRun;
					inst.simulator.set('settings', settings);
					inst.simulator.selectBranch(simDatabranch, inst.dummy.get('dataBranch'));
					inst.tile.closeModal();
					inst.simulator.continueRun(continueRun);
				};

				const cfg = {
					name: 'edit-simulator-databranch',
					model: inst.dummy,
					listenTo: ['submit'],
					loader: false,
					preventSave: true,
					fields: [{
						key: 'dataBranch',
						label: t('Data branch'),
						type: 'radio',
						values: [{
							YES: t('Yes')
						}, {
							NO: t('No')
						}]
					}],

					buttons: [{
						caption: t('Workflows.SimulatorContinueAutomatic'),
						name: 'automatic',
						type: 'submit',
						action () {
							selectBranch(true);
						}
					}, {
						caption: t('Workflows.SimulatorStart'),
						name: 'manual',
						type: 'submit',
						action () {
							selectBranch(false);
						}
					}]
				};

				this.$el.append(formView(cfg).$el);

			}

		});
		return new View();
	}
});

export default PlayerView;
