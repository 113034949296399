import MultipleEntities from '../shared/multiple-entities';
import Clinician from './clinician';
import urls from './urls';

const CliniciansEntity = MultipleEntities.extend({
	model: Clinician,
	urls,
	idName: 'administratorId',
	eventNamespace: 'clinician',

	appEvents: {
		'clinician.create clinician.delete clinician.update' () {
			// clear all search results
			delete this.searchClinicians;
		}
	},

	getAll () {
		return this.retrieve('allClinicians');
	},

	getByRespondentId (respondentId) {
		return this.retrieve('cliniciansByRespondentId', respondentId);
	},

	search (params) {
		return this.retrieve('searchClinicians', params);
	}
});

export default new CliniciansEntity();
