import { trim } from 'lodash';
import ClinicianGroup from 'repo/clinicians/clinician-group';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import handleResponseErrors from 'modules/general/shared/handle-response-errors';

export default function () {
	const formConfig = {
		name: 'add-clinician-group',
		model: new ClinicianGroup(),
		successMessage: t('The clinician group has been created successfully'),
		errorMessage: handleResponseErrors,
		fields: [{
			key: 'administratorGroupName',
			type: 'text',
			label: t('Group name'),
			mandatory: true,
			focus: true,
			validators: {
				validator: (value) => !!trim(value),
				invalidMessage: t('system-settings.clinician-groups.cannot-be-empty')
			}
		}, {
			key: 'administratorGroupCategory',
			type: 'text',
			label: t('Group category')
		}]
	};

	const dialogConfig = {
		title: t('New clinician group')
	};

	formViewDialog({ formConfig, dialogConfig });
}
