import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import AssessmentField from './assessment-field';

const AssessmentFields = MultipleEntities.extend({
	urls,
	idName: 'dataExportTemplateColumnAssessmentFieldId',
	eventNamespace: 'export-template-assessment-field',
	model: AssessmentField,

	getAll () {
		return this.retrieve('assessmentFields');
	}
});

export default new AssessmentFields();
