import MultipleEntities from '../shared/multiple-entities';
import SystemSetting from './entity';
import urls from './urls';

/**
 * Entity
 * @class SystemSettings
 * @extends MultipleEntities
 */
const SystemSettings = MultipleEntities.extend({
	model: SystemSetting,
	urls,
	eventNamespace: 'system-setting',
	idName: 'systemSettingId',

	/**
	 * Get all system event log entries
	 *
	 * @method getAll
	 * @return {Object} new {{#crossLink "SystemSettings"}}{{/crossLink}} Collection
	 */
	getAll: function () {
		return this.retrieve('systemSettings');
	}
});

export default new SystemSettings();

