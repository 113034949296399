import cardUrl from 'cwcardurl';
import datetime from 'service/datetime/datetime';
import filterComponent from 'components/filter/filter';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import Vue from 'vue';
import { EMPTY_CHAR } from 'lib/chars';
import { assign, get, debounce, forEach, map, isEmpty, size } from 'lodash';
import { READ } from 'service/acl/access-levels';

const searchThreshold = (config = {}) => config.searchThreshold || 200;
const CONSENTS_SET_FILTER = 'consents/setFilter';
const setResults = (tile) => {
	tile.setLoaded();
	store.dispatch('filter/setResults', {
		results: store.getters['consents/collection']
	});
};

export default TileView.extend({
	title: t('Consent templates'),
	acl: [{
		checkpoint: 'system.settings.consents',
		op: READ
	}],
	actions: [
		'treatmentTypes/initForCurrentClinician',
		'consents/init'
	],
	tileData: ({ tile }) => ({

		fields: () => [{
			key: 'filter[treatmentType.id]',
			type: 'single-select',
			label: t('Treatment type'),
			values: map(store.getters['treatmentTypes/sortedByName'], (treatmentType) => ({
				id: treatmentType.id,
				label: treatmentType.name
			})),

			onChange: (value) => {
				store.dispatch(CONSENTS_SET_FILTER, {
					'filter[treatmentType.id]': map(value, (val, key) =>
						val ? key : undefined).join(',')
				});
				tile.runQuery();
			}
		}, {
			key: 'filter[status]',
			type: 'multi-select',
			label: t('Status'),
			values: [
				{ id: 'PUBLISHED', label: t('consents.statuses.PUBLISHED') },
				{ id: 'UNPUBLISHED_CHANGES', label: t('consents.statuses.UNPUBLISHED_CHANGES') },
				{ id: 'DEACTIVATED', label: t('consents.statuses.DEACTIVATED') },
				{ id: 'DRAFT', label: t('consents.statuses.DRAFT') }
			],

			onChange: (value) => {
				let status = '';

				forEach(value, (val, key) => {
					if (val === true) {
						status = status ? `${status},${key}` : key;
					}
				});

				store.dispatch(CONSENTS_SET_FILTER, { 'filter[status]': status });
				tile.runQuery();
			}
		}],

		runQuery: debounce(() => {
			tile.setLoading();
			store.dispatch('consents/search').then(setResults.bind(null, tile));
		}, searchThreshold(tile.config())),

		initSearchParams: () => {
			store.dispatch('consents/initSearchParams', {
				filters: {
					start: 0,
					limit: 10
				}
			});
		},

		filterDefaults: () => ({
			onClear: () => {
				store.dispatch('consents/resetFilter');
				tile.runQuery();
			},

			onApply: () => {
				tile.runQuery();
			},

			searchFields: [{
				type: 'search',
				label: t('Find'),
				startValue: store.state.filter.id === tile.title() ?
					get(
						store.state.filter.values['Find'],
						'query',
						store.state.filter.values['Find']
					) :
					'',
				onChange: (search) => {
					store.dispatch(CONSENTS_SET_FILTER, { search });
					tile.runQuery();
				}
			}]
		})
	}),

	loaded: ({ tile }) => {
		tile.clear();
		tile.vue = new Vue({
			el: document.createElement('div'),
			store,
			filters: {
				handleEmpty (value) {
					return !isEmpty(value) ? value : EMPTY_CHAR;
				}
			},
			computed: {
				consents: ({ $store }) => $store.getters['consents/data'],
				isMore: ({ $store }) => $store.getters['consents/isMore']
			},

			methods: {
				size,
				loadMore () {
					tile.setLoading();
					this.$store.dispatch('consents/loadMore').then(setResults.bind(null, tile));
				},
				editConsent (consent) {
					cardUrl.openCard('edit-consent', {
						consentId: consent.id
					});
				},

				createdAt (consent) {
					return datetime(consent.createdAt)
						.setTimezone(this.$store.state.user.timezoneName)
						.toMoment().format(t('date.formats.dateTime'));
				},
				statusClass (status) {
					const classes = ['consents__item'];

					if (status === 'PUBLISHED') {
						classes.push('consents__item--published');
					} else if (status === 'DEACTIVATED') {
						classes.push('consents__item--deactivated');
					} else {
						classes.push('consents__item--not-published');
					}
					return classes;
				},
				statusLabel: (status) => t(`consents.statuses.${status}`)
			},
			template: `
			<section class="consents">
				<table class="consents__table">
					<thead>
						<tr>
							<th class="table-header name">
								<span class="table-header__label" v-translate>Name</span>
							</th>
							<th class="table-header">
								<span class="table-header__label" v-translate>Version</span>
							</th>
							<th class="table-header">
								<span class="table-header__label" v-translate>Status</span>
							</th>
							<th class="table-header">
								<span class="table-header__label" v-translate>Treatment type</span>
							</th>
							<th class="table-header">
								<span class="table-header__label" v-translate>Created on</span>
							</th>
						</tr>
					</thead>
					<p
						class="no-results"
						v-if="!consents.length"
						v-translate
					>No results</p>
					<tbody>
						<tr @click="editConsent(consent)"
							v-for="consent in consents"
							:key="consent.id"
						>
							<td class="consents__item" >
								{{ consent.name }}
							</td>
							<td class="consents__item" >
								{{ consent.lastVersionNumber}}
							</td>
							<td :class="statusClass(consent.status)">
								{{statusLabel(consent.status)}}
							</td>
							<td class="consents__item" >
								<ul
									class="category-tag__list"
									v-if="size(consent.treatmentType)">
										<li
											class="category-tag__list-item"
											v-for="tt in consent.treatmentType">
												<icon
													name="categories"
													:small="true"
													class="category-tag__list-item-icon"
												/>
												<span
													class="category-tag__list-item-description">
													{{ tt.name }}
												</span>
										</li>
								</ul>
								<span v-else>{{ '' | handleEmpty }}</span>
							</td>
							<td class="consents__item" >{{ createdAt(consent) }}</td>
						</tr>
					</tbody>
				</table>
				<div class="consents-see-more-container">
					<button
						class="flat-button__button"
						v-if="isMore"
						v-translate
						@click="loadMore"
					>More results</button>
				</div>
			</section>
		`
		});

		tile.filter = filterComponent(assign(
			{ id: tile.title() },
			tile.filterDefaults(tile),
			{ filters: tile.fields({ tile }) }
		));
		tile.$el.append(tile.filter.$el);
		tile.$el.append(tile.vue.$el);
		tile.initSearchParams();
		tile.runQuery();
	}
});
