import t from 'translate';
import TileView from 'tile-view';
import Table from 'components/table/table';
import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import { get, isUndefined } from 'lodash';
import appActivation from '../../dialogs/app-activation';
import categoryTag from 'components/category-tag/category-tag';
import icons from 'components/icon/icon-mapping-default';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

export default TileView.extend({
	title: t('Activated Apps (in this installation)'),
	acl: [{
		checkpoint: 'system.settings.settings',
		op: 'read'
	}],
	instant: true,

	loaded ({ tile }) {
		const actions = {};

		if (can.delete(SYSTEM.SETTINGS.SETTINGS)) {
			actions.delete = (connection, tableActions) => {
				confirmation({
					icon: 'unlink',
					warning: true,
					title: t('Deactivate App'),
					message: t('Deactivate App "{connection}"?', {
						connection: connection.name
					})
				}).then(() => {
					store.dispatch('apps/deactivate', { connection });
					cwalert.success(t('Deactivated'));
				}, () => tableActions.cancel());
			};
		}

		tile.table = new Table({
			parent: tile.el,
			store: {
				items: () => get(store.state, 'apps.activated'),
				mutation: ['apps/activate', 'apps/deactivate', 'apps/update']
			},
			emptyListMessage: t('No items'),
			click: can.edit(SYSTEM.SETTINGS.SETTINGS) ? appActivation : null,
			columns: [{
				key: 'name',
				label: t('App')
			}, {
				key: 'treatmentTypes',
				label: t('Treatment type restriction'),
				render: (item) => categoryTag(item, {
					iconClassName: `icon--${icons.treatment}`
				})[0]
			}, {
				key: 'numberOfActivations',
				className: 'centered',
				label: t('Number of activations'),
				render: (data, { item }) => !isUndefined(item.numberOfActivations) ?
					item.numberOfActivations :
					0
			}],

			actions
		});
	}
});
