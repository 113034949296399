<template>
	<div v-if="isCustom">
		<select-input
			v-model="formData.treatmentType"
			:disabled="mirror"
			:icon="error"
			:invalid="v.formData.treatmentType.$error"
			:mandatory="true"
			:options="treatmentTypes"
			:placeholder="translate('Select treatment type')"
			class-prefix="tree-edit"
			item-label="name"
			label="Treatment type"
		/>
		<p
			v-if="!formData.treatmentType"
			class="invalid-msg tree-edit__invalid"
		>{{$t('No treatment type is selected for the export template')}}</p>

		<select-input
			v-model="formData.identity"
			:disabled="!formData.treatmentType || loading"
			:invalid="v.formData.identity.$error"
			:loading="loading"
			:mandatory="true"
			:options="attributes"
			:placeholder="translate('Select attribute')"
			class-prefix="tree-edit"
			item-label="label"
			label="Treatment attribute"
		/>
	</div>

	<div v-else>
		<select-input
			v-model="formData.identity"
			:disabled="mirror"
			:invalid="v.formData.identity.$error"
			:mandatory="true"
			:options="attributes"
			:placeholder="translate('Select attribute')"
			class-prefix="tree-edit"
			label="Treatment attribute"
		/>
	</div>
</template>

<script>
import editMixin from './_edit-mixin';
import { constant } from 'lodash';

export default {
	name: 'EditTreatment',
	mixins: [editMixin],
	props: {
		attributes: {
			type: Array,
			default: constant([])
		},
		elementType: {
			type: String,
			required: true
		},
		error: {
			type: String,
			default: ''
		},
		formData: {
			type: Object,
			required: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		mirror: {
			type: Boolean,
			default: false
		},
		treatmentTypes: {
			type: Array,
			default: constant([])
		},
		v: {
			type: Object,
			required: true
		}
	},

	computed: {
		isCustom: ({ elementType }) => elementType === 'treatmentCustomAttribute'
	}
};
</script>
