import { assign, constant, find, forOwn, get, isNil, isObject, transform } from 'lodash';
import t from 'translate';
import { updateName } from '../helpers/update-name';
import editAssessment from './edit-assessment';
import editColumn from './edit-column';
import editRespondent from './edit-respondent';
import editTreatment from './edit-treatment';
import { respondentWarning } from '../helpers/warnings';
import validationRegexp from '../helpers/validation-regexp';

export default {
	props: {
		item: {
			type: Object,
			required: true
		},
		mirror: {
			type: Boolean,
			default: false
		},
		mirrorRoot: {
			type: Boolean,
			default: false
		},
		overwriteLabel: {
			type: String,
			default: ''
		},
		parentContext: {
			type: String,
			default: ''
		},
		parentContextExcluded: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			loading: false,
			assessmentColumns: [
				'assessmentAttribute',
				'assessmentCalculation',
				'assessmentFlag',
				'assessmentQuestion'
			],
			respondentColumns: [
				'respondentAttribute',
				'respondentCustomAttribute',
				'respondentCustomAttributeHistory'
			],
			treatmentColumns: ['treatmentAttribute', 'treatmentCustomAttribute'],
			formData: {
				assessment: null,
				assessmentOrder: null,
				assessmentContext: null,
				codeTemplate: '',
				contextLabel: '',
				customName: false,
				identifierLabel: '',
				identity: null,
				label: null,
				name: ''
			},
			formOptions: {
				assessmentOrder: [{
					label: t('First in range'),
					value: 'first'
				}, {
					label: t('Last in range'),
					value: 'last'
				}]
			},
			identifierField: 'code',
			ids: {
				assessment: 'assessmentId',
				assessmentOrder: 'value',
				assessmentContext: 'assessmentInstanceContextId',
				treatmentType: 'id'
			}
		};
	},

	components: {
		editAssessment,
		editColumn,
		editRespondent,
		editTreatment
	},

	created () {
		this.setData();
	},

	computed: {
		allowMirrorUpdate () {
			if (!this.mirror) {
				return true;
			}
			return this.mirrorRoot;
		},
		assessmentType () {
			return find(this.assessmentColumns, (type) => type === this.item.type);
		},

		code () {
			if (this.item.type === 'folder') {
				return false;
			}
			let name = this.formData.name;

			if (this.formData.codeTemplate) {
				const identifierLabel = this.setIdentifier();
				const context = this.formData.assessmentContext;
				name = updateName({
					assessmentContext: context && context.assessmentInstanceContextId,
					codeTemplate: this.formData.codeTemplate,
					columnLabel: this.columnLabel,
					contextLabel: this.context(this.formData),
					identifierLabel,
					parentContextExcluded: this.parentContextExcluded,
					type: this.item.type
				});
			}
			return name;
		},

		columnLabel: constant(''),

		attributeDisabled () {
			return this.mirror || (!this.formData.assessment && this.loading);
		},

		availableContexts () {
			return transform(this.contexts, (result, element) => {
				if (element.assessmentInstanceContextId === '') {
					element.assessmentInstanceContextId = '0';
					element.assessmentInstanceContextLabel = t('Any context');
				}
				result.push(element);
			}, []);
		},

		codeString () {
			return this.formData.codeTemplate;
		},

		columnType () {
			if (find(this.assessmentColumns, (type) => type === this.item.type)) {
				return 'assessment';
			}

			if (find(this.respondentColumns, (type) => type === this.item.type)) {
				return 'respondent';
			}

			if (find(this.treatmentColumns, (type) => type === this.item.type)) {
				return 'treatment';
			}
			return '';
		},

		respondentHistoryWarning () {
			const data = {
				type: this.item.type,
				fields: {
					assessment: this.formData.assessment
				}
			};
			return respondentWarning(data);
		},

		respondentLabel () {
			return this.item.type === 'respondentAttribute' ?
				'dataExportTemplateColumnRespondentFieldLabel' :
				'respondentAttributeMetaLabel';
		},

		invalidCode () {
			return !validationRegexp.test(this.codeString) ||
				/\.$/.test(this.codeString);
		},

		invalidName () {
			if (this.loading) {
				return false;
			}
			const value = this.formData.customName ? this.formData.name : this.code;
			return !validationRegexp.test(value) || /\.$/.test(value);
		},

		submitDisabled () {
			return this.respondentHistoryWarning || this.invalidName;
		},

		availableAssessment () {
			if (!this.item.fields.assessment) {
				return true;
			}
			return !!this.formData.assessment;
		},

		availableTreatmentType () {
			if (!this.item.fields.treatmentType) {
				return true;
			}
			return !!this.formData.treatmentType;
		},

		respondentAssessments () {
			return this.assessments || [];
		},

		respondentCustomAttributeHistory () {
			return this.item.type === 'respondentCustomAttributeHistory';
		}
	},

	methods: {
		assignAssessment () {
			assign(
				this.formData,
				transform(this.item.fields, (memo, value, key) => assign(memo, {
					assessment: this.valueToEntity({
						entities: this.assessments,
						key,
						value
					}),
					assessmentOrder: this.valueToEntity({
						entities: this.formOptions.assessmentOrder,
						key,
						value
					})
				}[key]))
			);
		},

		init () {
			if (this.assessmentType) {
				this.assignAssessment();
				this.initAssessmentContext();
				this.initUniqueFields();
			} else {
				this.initIdentity();

				if (this.respondentCustomAttributeHistory) {
					this.assignAssessment();
				}
			}
			this.initStandardFields();
		},
		initAssessmentContext () {
			this.assignAssessment();
			assign(
				this.formData,
				transform(this.item.fields, (memo, value, key) => assign(memo, {
					assessmentContext: this.valueToEntity({
						entities: this.availableContexts,
						key,
						value
					})
				}[key]))
			);
		},
		initStandardFields () {
			this.formData = assign(this.formData, transform(
				this.item.fields,
				(memo, value, key) => assign(memo, {
					codeTemplate: {
						codeTemplate: value
					},
					columnLabel: {
						columnLabel: value
					},
					contextLabel: {
						contextLabel: value
					},
					customName: {
						customName: value
					},
					identifierLabel: {
						identifierLabel: value
					},
					name: {
						name: value
					},
					label: {
						label: value
					}
				}[key])
			));
		},
		initIdentity () {
			// retransform values to entities and set them to proper data models
			assign(
				this.formData,
				transform(
					this.item.fields,
					(memo, value, key) => assign(memo, {
						identity: this.valueToEntity({
							entities: this.attributes,
							key,
							value
						})
					}[key])
				)
			);
		},
		initUniqueFields: constant(false),

		valueToEntity ({ entities, key, value }) {
			return !isNil(value) ?
				{ [key]: find(entities, { [this.ids[key]]: value.toString() }) } :
				{ [key]: value };
		},

		entitiesToValues () {
			const field = ({ key, value }) => get(value, `${this.ids[key]}`);

			return transform(
				this.formData,
				(memo, value, key) => memo[key] = isObject(value) ?
					field({ key, value }) :
					value
			);
		},

		clearForm () {
			forOwn(this.formData, (val, key) => {
				this.formData[key] = null;
			});
		},

		columnData (column) {
			return this[column] || null;
		},

		context (data) {
			// context may come from a parent or be set on an element or be null
			if (data.assessmentContext) {
				return data.assessmentContext.assessmentInstanceContextLabel;
			}
			return data.contextLabel || null;
		},

		error (field) {
			return this[field] ? '' : 'error';
		},

		fillInLabels () {
			// check if identifier has been changed. Otherwise no action.
			if (
				get(this, 'item.fields.identity') !==
				get(this, `formData.identity[${this.identifierField}]`)
			) {
				this.formData.identifierLabel = (
					!this.formData.customName &&
					this.formData.identity
				) ?
					this.formData.identity[this.identifierField] :
					this.formData.identifierLabel;
				this.formData.label = this.assessmentType ?
					`${this.formData.columnLabel}_${this.formData.identifierLabel}` :
					this.formData.identifierLabel;
			}
		},

		setData () {
			this.clearForm();
			this.init();
		},

		setIdentifier () {
			if (this.formData.customName) {
				return this.formData.identifierLabel;
			}
			return this.formData.identity ? this.formData.identity[this.identifierField] : '';
		},

		submit () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				const data = assign({
					name: this.formData.name,
					contextLabel: this.context(this.formData)
				}, {
					columnLabel: this.columnLabel,
					customName: this.formData.customName,
					identifierLabel: this.formData.identifierLabel,
					label: this.formData.label
				});
				this.$emit('save', {
					item: this.item,
					fields: assign({}, this.entitiesToValues(), data)
				});
			}
		},

		warning ({ display }) {
			if (this.item.warning && display) {
				return 'error';
			}
			return '';
		}
	},

	watch: {
		// change value when customName set to false
		'code' (value) {
			this.formData.name = value;
			this.formData.columnLabel = this.columnLabel || null;
		},

		'formData.identity' () {
			// let other identity watcher fire (if set) to provide current identifierLabel
			this.fillInLabels();
		},

		'formData.assessmentContext' (newVal, oldVal) {
			if (!newVal && oldVal) {
				this.formData.contextLabel = this.parentContextExcluded ? '' : this.parentContext;
			}
		},

		// when the same component is edited with different data
		'item' () {
			this.setData();
		},

		'respondentHistoryWarning' (newVal, oldVal) {
			if (newVal) {
				this.item.warning = true;
			} else if (oldVal && this.formData.identity) {
				this.item.warning = false;
			}
		}
	},

	template: `
		<edit-column
			:code="code"
			:defaultType="defaultType"
			:formData="formData"
			:invalid="submitDisabled"
			:mirror="mirror"
			:overwriteLabel="overwriteLabel"
			:init="init"
			:setIdentifier="setIdentifier"
			:submit="submit"
			:warning="warning"
		>
			<template v-if="columnType === 'assessment'" v-slot:assessment>
				<edit-assessment
					:attributes="columnData('attributes')"
					:elementType="item.type"
					:calculations="columnData('calculations')"
					:flags="columnData('flags')"
					:questions="columnData('questions')"
					:assessments="assessments"
					:attributeDisabled="attributeDisabled"
					:error="error('availableAssessment')"
					:contexts="availableContexts"
					:formData="formData"
					:formOptions="formOptions"
					:loading="loading"
					:mirror="mirror"
					:warning="warning"
					:v="$v"
				/>
			</template>
			<template v-if="columnType === 'respondent'" v-slot:respondent>
				<edit-respondent
					:assessments="respondentAssessments"
					:label="defaultType"
					:attributes="attributes"
					:error="error('availableAssessment')"
					:formData="formData"
					:itemLabel="respondentLabel"
					:loading="loading"
					:mirror="mirror"
					:respondentCustomAttributeHistory="respondentCustomAttributeHistory"
					:v="$v"
				/>
			</template>
			<template v-if="columnType === 'treatment'" v-slot:treatment>
				<edit-treatment
					:elementType="item.type"
					:attributes="attributes"
					:error="error('availableTreatmentType')"
					:formData="formData"
					:mirror="mirror"
					:treatmentAttributes="columnData('treatmentAttributes')"
					:treatmentTypes="columnData('treatmentTypes')"
					:v="$v"
				/>
			</template>
		</edit-column>
	`
};
