import can from 'acl-can';
import cardUrl from 'cwcardurl';
import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import store from 'store';
import t from 'translate';
import { assign, map } from 'lodash';
import { SYSTEM } from 'service/acl/checkpoints';
import { READ } from 'service/acl/access-levels';

const removalCfg = {
	action: 'relationTypes/delete',
	question: 'Are you sure you want to delete this relation type?',
	success: 'Relation type has been deleted',
	title: 'Remove relation type'
};

const mapSingleField = (singleField) => map(store.getters[singleField], (type) => assign(
	type,
	{ label: t(type.name) }
));

export default SearchTileView.extend({
	title: t('Relation types'),
	instant: true,
	acl: [{
		checkpoint: SYSTEM.SETTINGS.RELATION_TYPES,
		op: READ
	}],

	searchFn (params) {
		const result = store.dispatch('relationTypes/search', params);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},

	fields: ({ tile }) => [{
		key: 'substituted',
		type: 'single-select',
		label: t('Consent kind'),
		values: mapSingleField('relationTypes/substituted'),

		onChange: (value) => {
			// prevent setting [null] filter
			const newVal = map(value, (val, key) => val ? key : undefined);
			tile.viewModel.set(
				'substituted',
				newVal[0] ? newVal : null
			);
			tile.runQuery();
		}
	}, {
		key: 'permanent',
		type: 'single-select',
		label: t('Permanency'),
		values: mapSingleField('relationTypes/types'),

		onChange: (value) => {
			tile.viewModel.set(
				'permanent',
				map(value, (val, key) => val ? key : undefined)
			);
			tile.runQuery();
		}
	}],

	tableCfg: ({ tile }) => {
		const substituted = store.getters['relationTypes/substituted'];
		const className = 'relation-types';
		const config = {
			store: {
				items: () => store.getters['relationTypes/list'],
				count: 'relationTypes/count',
				mutation: 'relationTypes/setList',
				total: 'relationTypes/total'
			},
			click: (model) => {
				cardUrl.openCard('relation-type-edit', {
					relationTypeId: model.id
				});
			},
			columns: [{
				label: t('Name'),
				key: 'name',
				css: `${className}__name`
			}, {
				label: t('Description'),
				key: 'description',
				css: `${className}__description`
			}, {
				label: t('Consent kind'),
				key: 'substituted',
				css: `${className}__type`,
				render: (enabled) => enabled ? t(substituted[1].name) : t(substituted[0].name)
			}, {
				label: t('Permanency'),
				key: 'permanent',
				css: `${className}__type`,
				render: (enabled) => enabled ? t('Permanent') : t('Inconstant')
			}]
		};

		if (can.remove(SYSTEM.SETTINGS.RELATION_TYPES)) {
			config.columns.push({
				label: t('Delete'),
				render () {
					searchTileDelete({ data: removalCfg, item: this.item, td: this.td, tile });
				}
			});
		}
		return config;
	}
});
