import { assign } from 'lodash';

import SingleEntity from '../shared/single-entity';
import common from './message-common';
import { messageDraft } from './urls';

/**
 * MessageDraft entity
 *
 * @class MessageDraft
 * @extends Message
 */
export default SingleEntity.extend(assign(common, {
	urlRoot: messageDraft.url,
	eventNamespace: 'message-draft'
}));
