import appBar from 'app-bar';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { when } from 'jquery';
import { get } from 'lodash';
import { RULE_SETS } from 'store/data-integration/rule-sets';

const card = {
	title: t('dataIntegration.ruleSets.edit'),
	name: 'data-integration-rule-set-edit',
	url: 'data-integration/rule-set/:ruleSetId',

	ctrl: ({ close }, { ruleSetId }) =>  {
		if (can.delete(SYSTEM.SETTINGS.DATA_INTEGRATION_RULES) && ruleSetId !== '0') {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'delete',
				title: t('dataIntegration.ruleSets.actions.remove'),
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('dataIntegration.ruleSets.actions.remove'),
						message: t('dataIntegration.ruleSets.actions.delete')
					}).done(() => {
						store.dispatch('dataIntegration/ruleSets/delete', ruleSetId).then(() => {
							cwalert.success(t('dataIntegration.ruleSets.messages.deleted'));
							close({ go: 'data-integration/rule-sets' });
						});
					});
				}
			});
		}
		return when(
			store.dispatch('treatmentTypes/initAll'),
			store.dispatch('dataIntegration/ruleSets/getSingle', ruleSetId),
			store.dispatch('dataIntegration/searchDataMapping', ruleSetId),
			store.dispatch('dataIntegration/enterprises/search'),
			store.dispatch('dataIntegration/ruleSets/checkDefaultRuleSet'),
			store.dispatch('dataIntegration/codeTypes/search')
		).then(() => store.dispatch(
			'dataIntegration/codes/searchCodesByEnterprise',
			get(store.getters[RULE_SETS.SINGLE_ITEM], 'enterprise.id')
		));
	}
};

export { card as ruleSetEdit };
