import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import { when } from 'jquery';
import appActivationDialog from '../dialogs/app-activation';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	url: 'activated-apps',
	name: 'system-settings-activated-apps',
	title: t('Activated Apps'),

	ctrl: () => {
		if (can.add(SYSTEM.SETTINGS.SETTINGS)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('Activate a new App'),
				click: () => {
					appActivationDialog();
				}
			});
		}

		return when(
			store.dispatch('apps/initActivated'),
			store.dispatch('apps/initStoreApps'),
			store.dispatch('treatmentTypes/initAll')
		);
	}
};

export { card as activatedApp };
