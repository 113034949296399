import cardurl from 'cwcardurl';
import t from 'translate';
import acl from 'acl';
import repository from 'repository';
import systemSettings from 'system-settings';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import listFields from '../../shared/list-fields';

export default SearchTileView.extend({
	title: t('Overdue Assessments'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: READ
	}, {
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS,
		op: READ
	}, {
		checkpoint: CLINICIAN.CLINICIANS.ASSESSMENTS,
		op: READ
	}],

	cardData: () => ['contexts', 'types', 'limit'],

	searchFn: repository.getExpiredAssessmentInstances.bind(repository),

	tableCfg () {
		const inst = this;
		const { deadline, treatment, respondent, title } = listFields();

		return {
			className: 'table--long-text',
			click (item) {
				cardurl.openCard('assessment-properties', {
					assessmentInstanceId: item.getId()
				});
			},
			rowsClickable: acl.checkAccess({
				op: READ,
				checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS
			}) && acl.checkAccess({
				op: READ,
				checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS
			}),
			columns: [
				deadline,
				treatment,
				respondent,
				title,
				{
					label: t('Type'),
					css: 'assessment-list__type',
					skip: () => !systemSettings.getBoolean('ASSESSMENT_TYPES'),
					render () {
						if (this.item.get('type')) {
							const type = inst.types.findWhere({
								id: `${this.item.get('type')}`
							});
							return type ? type.get('label') : '';
						}
						return '';
					}
				}, {
					label: t('Context'),
					css: 'assessment-list__context',
					render () {
						const contextId = this.item.get('assessmentInstanceContextId');

						if (contextId) {
							const context = inst.contexts.findWhere({
								assessmentInstanceContextId: contextId
							});
							return context ? context.getLabel() : '';
						}
						return '';
					}
				}
			]
		};
	}
});
