<template>
	<div>
		<text-input
			v-model="item.enterpriseId"
			:disabled="!enabled"
			:required="true"
			:mandatory="true"
			:invalid="vuelidate.item.enterpriseId.$error"
			label="dataIntegration.enterprise.fields.id"
			@input="vuelidate.item.enterpriseId.$touch()"
		/>
		<text-input
			v-model="item.secret"
			:disabled="!enabled"
			:mandatory="true"
			:invalid="vuelidate.item.secret.$error"
			label="dataIntegration.enterprise.fields.secret"
			@input="vuelidate.item.secret.$touch()"
		/>
		<text-input
			v-model="item.name"
			:disabled="!enabled"
			:required="true"
			:mandatory="true"
			:invalid="vuelidate.item.name.$error"
			label="dataIntegration.enterprise.fields.name"
			@input="vuelidate.item.name.$touch()"
		/>
		<text-input
			v-model="item.code"
			:disabled="!enabled"
			:mandatory="true"
			:invalid="vuelidate.item.code.$error"
			label="dataIntegration.enterprise.fields.code"
			@input="vuelidate.item.code.$touch()"
		/>
	</div>
</template>

<script>
export default {
	props: {
		enabled: {
			type: Boolean,
			default: true
		},
		item: {
			type: Object,
			required: true
		},
		vuelidate: {
			type: Object,
			required: true
		}
	}
};
</script>
