import t from 'translate';
import repository from 'repository';
import List from '../flag-list/tile';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';

export default List.extend({
	title: t('Assessment flags'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.FLAGS,
		op: READ
	}],
	render () {
		this.disable();
		this.assessmentInstanceId = this.cardContext().get('assessmentInstanceId');
		this.collection = repository.getAssessmentInstanceFlags(this.assessmentInstanceId);
		this.load();
	},
	columns: ['isRaised', 'isSeen', 'date', 'title'],
	loaded () {
		if (!this.collection.size()) {
			this.disable();

		} else {
			this.enable();
			List.prototype.loaded.call(this, 'respondent/timezone');
		}
	}
});
