import EntityModel from 'repo/apps/connected-app';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';
import { Collection } from 'backbone';
import appContext from 'app-context';

export default function () {
	const appModel = new EntityModel();

	const formConfig = {
		name: 'name',
		model: appModel,
		successMessage: t('Successfully added'),
		onBeforeSave () {
			appModel.set({
				activeApp: { id: +appModel.get('_activeApp') },
				treatment: { id: +appModel.get('_treatment') },
				respondent: { respondentId: +appModel.get('_respondent') } // <- b💩ckend
			});
		},

		onAfterSave (response) {
			store.dispatch('apps/addConnection', { model: response });
			appContext.trigger('dialog:close');
		},

		fields: [{
			key: '_activeApp',
			type: 'select',
			collection: new Collection(store.getters['apps/treatmentApps']),
			mandatory: true,
			determineTheOnlyOption: false,
			label: t('App'),
			description: t('Select App\u2026')
		}, {
			key: '_treatment',
			type: 'select',
			collection: new Collection(store.getters['treatment']),
			mandatory: true,
			disabled: true,
			label: t('Treatment'),
			description: t('Select treatment\u2026')
		}, {
			key: '_respondent',
			type: 'select',
			labelField: 'displayName',
			collection: new Collection(store.getters['treatment/treatmentRespondents']),
			mandatory: true,
			label: t('Respondent'),
			description: t('Select respondent\u2026')
		}]
	};

	const dialogConfig = {
		title: t('Connect App')
	};

	formViewDialog({ formConfig, dialogConfig });
}
