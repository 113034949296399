import TileView from 'tile-view';
import t from 'translate';
import store from 'store';
import { get } from 'lodash';
import { SimpleList, itemTemplate } from 'components/simple-list/simple-list';

export default TileView.extend({
	title: t('Available pages'),

	acl: [{
		checkpoint: 'administrator.respondents.informationpackages',
		op: 'read'
	}],

	actions: () => [['pages/init', { allRespondents: true }]],

	loaded: ({ tile }) => {
		const treatmentPages = store.getters['pages/active'];
		const respondentId = store.getters['respondent/respondentId'];

		const roleDescription = (item) => itemTemplate({
			config: tile.config().items,
			name: 'role',
			value: get(item.instance.treatmentRole, 'roleName', null),
			className: 'item-description__role item-description--small'
		});
		const packageDescription = (item) => itemTemplate({
			config: tile.config().items,
			name: 'package',
			value: item.instance.package.name,
			className: `item-description__package
					item-description--highlight
					item-description--small`
		});

		const renderDescription = (item) =>	`
			${roleDescription(item)}
			${packageDescription(item)}
		`;

		tile.list = new SimpleList({
			el: tile.el,
			items: treatmentPages,
			urlParams: (item) => (['content-package-instance-element', {
				respondent: respondentId,
				instance: item.instance.id,
				id: item.id
			}]),

			className: {
				positive: (item) => item.state === 'COMPLETED',
				negative: (item) => !item.elementVersion.optional && item.state !== 'COMPLETED',
				neutral: (item) => item.elementVersion.optional && item.state !== 'COMPLETED'
			},

			classNameDesc: {
				positive: () => t('Page is read'),
				negative: () => t('Page is not read - required'),
				neutral: () => t('Page is not read')
			},

			content: (item) => `
				<span class="simple-list__item-icon icon--page"></span>
				<div class="simple-list__item-description">
					<h3 class="item-description__title">${item.elementVersion.alias}</h3>
					${renderDescription(item)}
				</div>`
		});

		tile.$el.html(tile.list.$el);
	}
});
