'use strict';

import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';

export default TileView.extend({
	title: t('View link'),

	render: function() {
		const formCfg = {
			name: 'view-link',
			model: this.cardContext().get('link'),
			readonly: true,

			fields: [{
				key: 'name',
				type: 'paragraph',
				label: t('Name')
			}, {
				key: 'url',
				type: 'paragraph',
				label: t('URL'),
			}, {
				key: 'description',
				type: 'paragraph',
				label: t('Description')
			}, {
				key: 'openInNewWindow',
				type: 'paragraph',
				label: t('Open in new window'),
				value: () => this.cardContext().get('link').get('openInNewWindow') ? t('Yes') : t('No')
			}]
		};

		this.$el.html(formView(formCfg).$el);
	}
});
