var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("select-input", {
          attrs: {
            translate: false,
            label: _vm.$t("addAdapterInstance.adapter"),
            options: _vm.adaptersOptions,
            invalid: _vm.$v.form.adapter.$error,
            required: "",
            mandatory: ""
          },
          scopedSlots: _vm._u(
            [
              _vm.$v.form.adapter.$error
                ? {
                    key: "validations",
                    fn: function() {
                      return [
                        !_vm.$v.form.adapter.required
                          ? _c("p", [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.$t("general.validation.mandatory")
                                  ) +
                                  "\n\t\t\t\t"
                              )
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          ),
          model: {
            value: _vm.form.adapter,
            callback: function($$v) {
              _vm.$set(_vm.form, "adapter", $$v)
            },
            expression: "form.adapter"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("addAdapterInstance.name"),
            invalid: _vm.$v.form.name.$error,
            required: "",
            mandatory: ""
          },
          scopedSlots: _vm._u(
            [
              _vm.$v.form.name.$error
                ? {
                    key: "validations",
                    fn: function() {
                      return [
                        !_vm.$v.form.name.required
                          ? _c("p", [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.$t("general.validation.mandatory")
                                  ) +
                                  "\n\t\t\t\t"
                              )
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          ),
          model: {
            value: _vm.form.name,
            callback: function($$v) {
              _vm.$set(_vm.form, "name", $$v)
            },
            expression: "form.name"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("addAdapterInstance.description"),
            multiline: ""
          },
          model: {
            value: _vm.form.description,
            callback: function($$v) {
              _vm.$set(_vm.form, "description", $$v)
            },
            expression: "form.description"
          }
        }),
        _vm._v(" "),
        _c(
          "button-group",
          { attrs: { "align-right": "" } },
          [
            _c("submit-button", {
              attrs: {
                "can-submit": !_vm.processing,
                label: _vm.$t("general.buttons.add")
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }