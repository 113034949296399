/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import $ from 'jquery';
import cwalert from 'cwalert';
import t from 'translate';
import { throttle } from 'lodash';

// eslint-disable-next-line max-params
export default function (attributeName, model, options, mode = {}) {
	const __input = $('<input type="text" required="required" class="input-large" />')[0];

	options.style && $(__input).addClass(options.style);
	options.readonly && $(__input).attr('readonly', 'readonly');
	options.noempty && $(__input).attr('required', 'required');

	const patienceSave = throttle(() => {

		if (options.noempty && (__input.value === '')) {
			__input.value = model.get(attributeName);
			return;
		}

		model.set(attributeName, __input.value);

		if (!options.disableAutoSave) {
			model.save({}, {
				success () {
					cwalert.custom(t('general-list.ChangesSaved'), 'success');
				},
				error () {
					cwalert.custom(t('general-list.ChangesNotSaved'), 'error');
				}
			});
		}
	}, 3000, { leading: false });

	$(__input).change(patienceSave);

	const __refresh = function () {
		if (model.get(attributeName) === undefined) {
			model.set(attributeName, '');
			return;
		}

		if (mode.edit) {
			if (model.get(attributeName) !== __input.value) {
				model.set(attributeName, __input.value, { silent: true });
			}

		} else {
			__input.value = model.get(attributeName);
		}

		// flash the cell when changed remotely ?

	};

	/* binding the refresh if value of model attribute has changed */
	model.on(`change:${attributeName}`, __refresh);

	__refresh();

	this.getDOMNode = function () {
		return __input;
	};
}
