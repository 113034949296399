var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "wide-tile plan-instance-label__content" },
    [
      _c("ul", { staticClass: "plan-instance-label__container" }, [
        _c(
          "li",
          { staticClass: "plan-instance-label__element-container important" },
          [
            _c("p", { staticClass: "plan-instance-label__label--important" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.t("Plan")) + "\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "plan-instance-label__value--important" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.planInstance.name) + "\n\t\t\t")
            ])
          ]
        ),
        _vm._v(" "),
        _c("li", { staticClass: "plan-instance-label__element-container" }, [
          _c("p", { staticClass: "plan-instance-label__label--important" }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.t("workflow.properties.Status")) +
                "\n\t\t\t"
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "plan-instance-label__value--important" }, [
            _vm._v("\n\t\t\t\t" + _vm._s(_vm.displayStatus()) + "\n\t\t\t")
          ])
        ]),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "plan-instance-label__element-container" },
          [
            _c("datetime-input", {
              attrs: {
                label: "workflow.properties.StartDate",
                "class-prefix": "plan-instance",
                disabled: _vm.disabledDate,
                readonly: !_vm.planInstance.canChange
              },
              on: { "on-close": _vm.updateDate },
              model: {
                value: _vm.date,
                callback: function($$v) {
                  _vm.date = $$v
                },
                expression: "date"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }