import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import store from 'store';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { get } from 'lodash';

export default TileView.extend({
	title: t('Abuse report handling'),
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],
	features: enableCirclesCheck,
	instant: true,

	loaded: ({ tile }) => {
		const model = store.state.circles.abuseReports.currentReport.model;
		const statusCollection = store.state.circles.abuseReports.statuses.collection;
		model.set('status', get(model.get('status'), 'id'));

		tile.form = formView({
			model,
			onAfterSave: () => {
				model.set('status', model.get('status').id);
			},
			fields: [{
				label: t('Abuse report status'),
				type: 'select',
				key: 'status',
				collection: statusCollection,
				labelField: 'label'
			}, {
				label: t('Resolved'),
				type: 'checkbox',
				key: 'isResolved'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});
