import ExportTemplateList from './export-template-list/tile';
import ExportTemplate from './export-template';
import ExportTemplateClinicianGroups from './export-template-clinician-groups/tile';
import ExportTemplateTreatmentTypes from './export-template-treatment-types/tile';
import EditExportTemplate from './edit-export-template';

export default {
	'export-template-list': ExportTemplateList,
	'export-template': ExportTemplate,
	'export-template-clinician-groups': ExportTemplateClinicianGroups,
	'export-template-treatment-types': ExportTemplateTreatmentTypes,
	'edit-export-template': EditExportTemplate
};
