import Vue from 'vue';
import t from 'translate';
import { get, isFunction } from 'lodash';

export default (treatment) => new Vue({
	el: document.createElement('div'),
	data: {
		treatment: isFunction(get(treatment, 'toJSON')) ? treatment.toJSON() : treatment
	},
	template: `
		<p v-if="notComplete" class="treatment-warning warning">
			${t('Required roles should be set and primary respondent should be associated with role')}
		</p>
	`,
	computed: {
		notComplete: function () {
			return this.treatment && !this.treatment.complete
		}
	}
})
