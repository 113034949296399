import appBar from 'app-bar';
import can from 'acl-can';
import t from 'translate';
import store from 'store';
import { forEach } from 'lodash';

export default ({
	acl,
	action,
	card,
	dataIntegrationFn,
	dispatch,
	item,
	populate,
	translateType,
	type
}) => {
	if (can.add(acl)) {
		const translate = translateType ? translateType : type;
		const title = `dataIntegration.${translate}.actions.add`;
		const modalCfg = {
			acl,
			action,
			refresh: populate,
			success: t(`dataIntegration.${translate}.messages.added`),
			title,
			type
		};
		appBar.addButton({
			sourceCard: card.name,
			icon: 'add',
			title: t(title),
			click: () => {
				if (dispatch) {
					forEach(dispatch, (action) => {
						store.dispatch(action);
					});
				}
				modalCfg.item = { ...item };
				dataIntegrationFn(modalCfg);
			}
		});
	}
};
