<template>
	<div>
		<h1 class="consent-preview__header" v-html="name"/>
		<consent-content :options="options">
			<template #content>
				<div v-html="content" />
			</template>
			<template #optionContent="slotProps">
				<div v-html="slotProps.content" />
			</template>
		</consent-content>
	</div>
</template>

<script>
import ConsentContent from '../../components/consent-content';
import { escape } from 'lodash';
import t from 'service/lang/translate';

export default {
	title: t('Consent preview'),
	actions: ({ $store }) => [['consent/initConsentOptions', $store.getters.urlParams.consentId]],
	components: { ConsentContent },
	computed: {
		content: ({ $store }) => $store.state.consent.data.content,
		options: ({ $store }) => $store.getters['consent/options'],
		name: ({ $store }) => escape($store.getters['consent/consent'].name)
	}
};
</script>
