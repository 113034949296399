var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-dialog", {
    attrs: {
      "on-close": _vm.onClose,
      show: _vm.show,
      "class-name": _vm.modalClass,
      "custom-focus": true
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "span",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v(_vm._s(_vm.title))]
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(_vm.type, {
              tag: "component",
              on: { "stage-change": _vm.onStageChange, submit: _vm.onSubmit }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }