import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Column from './column';

const ExportTemplateColumnsEntity = MultipleEntities.extend({
	urls,
	model: Column,
	idName: 'dataExportTemplateColumnId',
	eventNamespace: 'export-template-column',
	canAdd (model) {
		return +model.get('fkDataExportTemplateColumnTemplateId') === +this.ownerId;
	},

	getFromTemplate (exportTemplateId) {
		return this.retrieve('columns', exportTemplateId);
	},

	getColumnNames () {
		return this.pluck('dataExportTemplateColumnName');
	}
});

export default new ExportTemplateColumnsEntity();
