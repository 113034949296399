import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import t from 'translate';
import cwalert from 'cwalert';
import { deprecatedCards, deprecatedTiles } from '../data';
import { assign } from 'lodash';

const REMOVE_CARD = 'cards/removeCard';
const removeCardLabel = 'cardConfig.healthCheck.removeCard';

const mismatchedCard = ({ item, portal }) => {
	const found = deprecatedCards[item['card-name']];
	const cfg = {};

	if (found) {
		assign(cfg, {
			action: 'cards/renameCard',
			payload: {
				oldName: item['card-name'],
				newName: found,
				portal
			},
			successMsg: t('cardConfig.healthCheck.renameCardConfirmation', {
				card: item['card-name'],
				newCard: found
			}),
			confirmConfig: {
				icon: 'edit',
				title: t('cardConfig.healthCheck.renameCard'),
				message: t('cardConfig.healthCheck.renameCardInfo', {
					card: item['card-name'],
					newCard: found
				})
			}
		});

	} else {
		const msg = found === false ?
			'cardConfig.healthCheck.cardNotHandledDesc' :
			'cardConfig.healthCheck.cardRemoveDesc';

		assign(cfg, {
			action: REMOVE_CARD,
			payload: { card: item, portal },
			successMsg: t('cardConfig.healthCheck.cardRemoveConfirmation', {
				card: item['card-name']
			}),
			confirmConfig: {
				icon: 'delete',
				title: t(removeCardLabel),
				message: t(msg, { card: item['card-name'] })
			}
		});
	}

	confirmation({
		...cfg.confirmConfig,
		warning: true
	})
		.then(() => store.dispatch(cfg.action, cfg.payload))
		.then(() => {
			cwalert.success(cfg.successMsg);
		});
};

const wrongPortalCard = ({ item, portal }) => {
	confirmation({
		icon: 'delete',
		warning: true,
		title: t(removeCardLabel),
		message: t('cardConfig.healthCheck.cardRemoveQuestion', {
			card: item['card-name']
		})
	})
		.then(() => store.dispatch(REMOVE_CARD, { card: item, portal }))
		.then(() => {
			cwalert.success(t('cardConfig.healthCheck.cardRemoveConfirmation', {
				card: item['card-name']
			}));
		});
};

const emptyCard = ({ item }) => {
	confirmation({
		icon: 'delete',
		warning: true,
		title: t(removeCardLabel),
		message: t(`cardConfig.healthCheck.removeEmptyCardQuestion`)
	})
		.then(() => store.dispatch(REMOVE_CARD, { card: item }))
		.then(() => {
			cwalert.success(t('cardConfig.removeEmptyCardConfirmation'));
		});
};

const mismatchedTile = ({ item }) => {
	const found = deprecatedTiles[item];
	const cfg = {
		action: 'cards/replaceTile',
		payload: { tileName: item }
	};

	if (found) {
		cfg.payload.newTile = found;
		assign(cfg, {
			successMsg: t('cardConfig.healthCheck.replaceTileConfirmation', {
				tile: item, newTile: found.tileName
			}),
			confirmConfig: {
				icon: 'edit',
				title: t('cardConfig.healthCheck.replaceTile'),
				message: t('cardConfig.healthCheck.tileNotHandledDesc',	{
					tile: item,
					newTile: found.tileName
				})
			}
		});

	} else {
		const msg = found === false ?
			'cardConfig.healthCheck.tileNotHandledDesc' :
			'cardConfig.healthCheck.tileRemoveDesc';
		assign(cfg, {
			successMsg: t('cardConfig.healthCheck.tileRemoveConfirmation', { tile: item }),
			confirmConfig: {
				icon: 'delete',
				title: t('cardConfig.healthCheck.removeTile'),
				message: t(msg, { tile: item })
			}
		});
	}

	confirmation({
		...cfg.confirmConfig,
		warning: true
	})
		.then(() => store.dispatch(cfg.action, cfg.payload))
		.then(() => {
			cwalert.success(cfg.successMsg);
		});
};

export const solve = ({ issue, portal }, item) => {
	const action = {
		mismatchedCard,
		wrongPortalCard,
		emptyCard,
		mismatchedTile
	}[issue];
	action({ item, portal });
};
