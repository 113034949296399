import $, { when } from 'jquery';
import t from 'translate';
import { assign, escape, find, map, trim } from 'lodash';
import CardDialog from 'service/card-dialog/card-dialog';
import formView from 'components/form-view/form-view';
import can from 'acl-can';
import store from 'store';
import cwalert from 'cwalert';
import Assignment from 'components/assignment';
import confirmation from 'components/confirmation/confirmation';
import { SYSTEM } from 'service/acl/checkpoints.json';
import handleResponseErrors from 'modules/general/shared/handle-response-errors';

const RESPONDENT_GROUP_CLINICIAN_GROUPS = 'respondentGroup/clinicianGroups';

export default function (group) {
	const $container = $('<div />');
	const dialog = new CardDialog({
		content: $container[0],
		title: t('Edit group')
	});

	dialog.setLoading();

	when(
		store.dispatch('respondentGroup/init', group.id),
		store.dispatch('clinicianGroups/initAll')
	).then(() => {
		dialog.setLoaded();
		const form = formView({
			name: 'edit-respondent-group',
			item: group,
			submit: (editedGroup) => {
				store.dispatch('respondentGroups/edit', editedGroup)
					.then(() => {
						cwalert.success(t(
							'Respondent group {group} has been updated',
							{ group: editedGroup.name }
						));
					})
					.catch(handleResponseErrors);
			},
			readonly: !can.edit(SYSTEM.SETTINGS.RESPONDENTGROUPS),
			fields: [{
				key: 'name',
				label: t('Name'),
				type: 'text',
				required: true,
				validators: {
					validator: (value) => !!trim(value),
					invalidMessage: t('system-settings.respondent-groups.cannot-be-empty')
				}
			}, {
				key: 'category',
				label: t('Category'),
				type: 'text'
			}, {
				key: 'common',
				label: t('Group is common'),
				type: 'checkbox'
			}]
		});

		$container.append(form.$el);
		const storedGroups = store.getters['clinicianGroups/all'];
		let groups = [];

		if (storedGroups.length) {
			groups = map(storedGroups, (obj) => {
				obj.category = escape(obj.category) || '--';
				obj.name = escape(obj.name);
				return obj;
			});
		}
		const assignment = new Assignment({
			label: t('Assigned clinician groups'),
			parent: $container[0],
			readonly: !can.edit(SYSTEM.SETTINGS.RESPONDENTGROUPS),
			allItems: groups,
			relationItems: store.getters[RESPONDENT_GROUP_CLINICIAN_GROUPS],
			relationAttributes: { id: group.id },
			itemPrimaryKey: 'id',
			selectorLabel: t('Select group to add'),
			selectorRender: (item) => `${item.name} [${item.category}]`,
			columns: [{
				key: 'name',
				label: t('Group name')
			}, {
				key: 'category',
				label: t('Group category')
			}],
			emptyListMessage: t('The respondent group has no clinician groups assigned'),
			onAdd: (relation, done) => {
				const clinicianGroup = find(
					store.getters['clinicianGroups/all'],
					relation.toJSON()
				);
				store.dispatch('respondentGroup/addClinicianGroup', clinicianGroup).then((resp) => {
					done({ relationObj: assign({}, clinicianGroup, resp) });
					assignment.table.setItems(store.getters[RESPONDENT_GROUP_CLINICIAN_GROUPS]);
				});
			},

			onDelete: (relation, done, cancel) => {
				confirmation({
					title: t('Remove'),
					icon: 'unlink',
					message: t('Are you sure you wish to remove this?'),
					warning: true
				}).done(() => {
					const clinicianGroup = find(
						store.getters['respondentGroup/clinicianGroups'],
						{ id: relation.id }
					);
					store.dispatch('respondentGroup/removeClinicianGroup', clinicianGroup)
						.then(() => {
							const items = store.getters[RESPONDENT_GROUP_CLINICIAN_GROUPS];
							assignment.table.setItems(items);
							done();
						});

				}).fail(() => {
					cancel();
				});
			}
		});
	});
}
