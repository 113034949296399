import MultipleEntities from '../shared/multiple-entities';
import ClinicianAttributeValue from './attribute-value';
import urls from './urls';

// Disclaimer: the name is clearly ugly. It's on purpose. B💩ckend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.

const ClinicianAttributeValues = MultipleEntities.extend({
	model: ClinicianAttributeValue,
	urls,
	eventNamespace: 'clinician-attribute-value',
	idName: 'administratorAttributeId',

	canAdd (model) {
		return +this.ownerId === +model.getClinicianId();
	},

	getAll (clinicianId) {
		return this.retrieve('listAttributeValues', clinicianId);
	}
});

export default new ClinicianAttributeValues();

