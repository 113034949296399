import $ from 'jquery';
import { Collection, View } from 'backbone';
import datetime from 'datetime';
import cardurl from 'cwcardurl';
import Table from 'components/table/table';
import t from 'translate';
import repository from 'repository';
import can from 'acl-can';
import store from 'store';
import { split } from 'lodash';

const className = 'plans-list__entry plans-list__';

export default View.extend({

	title: t('Plans'),
	acl: [{
		checkpoint: 'administrator.respondents.workflowplans',
		op: 'READ'
	}],
	appEvents: 'plan-instance.delete plan-instance.create plan-instance.update',
	render () {
		this.setLoading();
		this.respondent = store.state.respondent.model;
		const respondentId = this.respondent.getId();
		this.planInstances = repository.respondentPlanInstances(respondentId);
		this.treatments = repository.respondentTreatments(respondentId);

		if (!can.read('administrator.respondents.workflowplans')) {
			this.planInstances = new Collection();
		}
		$.when.apply($, [
			this.respondent,
			this.planInstances,
			this.treatments
		]).done(() => {
			this.planInstances.comparator = function (model1, model2) {
				const timestamp1 = +model1.get('workflowInstanceStartDate').unixtime;
				const timestamp2 = +model2.get('workflowInstanceStartDate').unixtime;
				const diff = timestamp2 - timestamp1;
				return diff === 0 ? 0 : diff / Math.abs(diff);
			};
			this.planInstances.sort();
			this.loaded();
		});
	},
	loaded () {
		this.setLoaded();
		const that = this;

		this.table = new Table({
			parent: this.el,
			collection: this.planInstances,
			emptyListMessage: t('No assessment plans available'),
			sortable: true,
			sortOrder: this.config().tableSort,
			click: (item) => {
				cardurl.openCard('plan-properties', { planInstanceId: item.getId() });
			},
			columns: [{
				key: 'startDate',
				label: t('Start date'),
				css: `${className}start-date`,
				render () {
					return datetime(this.item.getStartDate())
						.setTimezone(that.respondent.getTimezone())
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				key: 'name',
				label: t('Plan'),
				css: `${className}title`,
				render () {
					return this.item.getName();
				}
			}, {
				label: t('Treatment'),
				skip: function () {
					return this.treatments.size() <= 1;
				}.bind(this),
				css: `${className}treatment`,
				render () {
					const treatment = that.treatments.get(+this.item.get('treatment'));
					return (treatment && treatment.get('name')) || '';
				}
			}, {
				label: t('Stage'),
				css: `${className}stage`,
				render () {
					const stage = this.item.getStage();

					if (!stage) {
						const $td = $(this.td);
						const labelClass = split(`.${(`${className}stage`)}`, ' ').join('.');
						const $label = $td.siblings(labelClass);

						$td.add($label).addClass('optional');
					}
					return stage ? stage : '';
				}
			}, {
				key: 'status',
				label: t('Status'),
				css: `${className}status`,
				render () {
					return t(`workflow.properties.status.${this.item.getStatus()}`);
				}
			}]
		});
	}
});

