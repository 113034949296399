import addPlanInstance from './add-plan-instance/tile';
import planInformationTile from './plan-instance-information';
import planScreenshotTile from './plan-instance-screenshot/tile';
import respondentPlans from './respondent-plans/tile';
import treatmentPlans from '../../plan-instance/tiles/treatment-plans/tile';

export default {
	'add-plan-instance': addPlanInstance,
	'plan-instance-information': planInformationTile,
	'plan-instance-screenshot': planScreenshotTile,
	'respondent-plans': respondentPlans,
	'treatment-plans': treatmentPlans,
	'treatment-add-plan': addPlanInstance // @deprecated
};
