import TileView from 'tile-view';
import can from 'acl-can';
import t from 'translate';
import store from 'store';

export default TileView.extend({
	title: t('plans.plan-access.title'),
	acl: [{
		checkpoint: 'system.workflows.administratorgroups',
		op: 'READ'
	}],

	actions: () => [
		['plan/init', { planId: store.state.cardData.planId }],
		['plan/initClinicianGroups', store.state.cardData.planId],
		['clinicianGroups/initAll']
	],

	Vue: () => ({
		data: () => ({
			canAdd: can.add('system.workflows.administratorgroups'),
			canRemove: can.delete('system.workflows.administratorgroups'),
			fields: ['name', 'category'],
			labels: {
				empty: t('The assessment plan has no clinician groups assigned'),
				name: t('Group name'),
				category: t('Group category'),
				removeItem: t('Remove clinician group'),
				removeQuestion: t('Are you sure you wish to remove clinician group?'),
				removeSuccess: t('Clinician group removed successfully'),
				selectItem: t('- select clinician group to add -'),
				addSuccess: t('Clinician group added')
			},
			getters: {
				allItems: 'clinicianGroups/all',
				items: 'plan/clinicianGroups'
			},
			actions: {
				removeItem: 'plan/removeClinicianGroup',
				addItem: 'plan/addClinicianGroup'
			}
		}),
		template: `
			<assign-items
				:can-remove="canRemove"
				:can-add="canAdd"
				:fields="fields"
				:labels="labels"
				:getters="getters"
				:actions="actions"
			/>
		`
	})
});
