<template>
	<div>
		<div
			v-if="updating"
			v-loader-spinner
			class="data-integration--loading"
		/>
		<form @submit.prevent="onSubmit">
			<h3>{{toUpper('dataIntegration.ruleSets.labels.details')}}</h3>
			<div class="data-integration__rule-sets--container">
				<checkbox-input
					v-model="item.enabled"
					:disabled="!canEdit"
					:label="t('dataIntegration.ruleSets.fields.enable')"
					:translate="false"
					class-prefix="data-integration"
					input-class="checkbox-input"
					text-class="checkbox-text"
				/>
				<select-input
					v-model="treatmentType"
					:disabled="true"
					:invalid="invalid('treatmentType')"
					:mandatory="mandatoryTreatmentType"
					:options="treatmentTypesOptions"
					:searchable="false"
					class-prefix="data-integration"
					container-class="select-container"
					label="dataIntegration.ruleSets.fields.treatmentType"
				/>
				<text-input
					v-model="enterpriseName"
					:disabled="true"
					class-prefix="data-integration"
					label="dataIntegration.ruleSets.fields.enterprise"
				/>
			</div>
			<h3>{{toUpper('dataIntegration.ruleSets.labels.defaults')}}</h3>
			<div class="data-integration__rule-sets--container">
				<label
					v-translate
					:class="radioLabelClass('defaultAccess')"
				>dataIntegration.ruleSets.labels.allowLabel</label>
				<div class="data-integration__radio-row">
					<radio-input
						v-model="item.defaultAccess"
						:disabled="!canEdit"
						:value-name="true"
						label="dataIntegration.ruleSets.fields.allowed"
						@input="$v.item.defaultAccess.$touch()"
					/>
				</div>
				<div class="data-integration__radio-row data-integration__radio-row--last">
					<radio-input
						v-model="item.defaultAccess"
						:disabled="!canEdit"
						:value-name="false"
						label="dataIntegration.ruleSets.fields.denied"
						@input="$v.item.defaultAccess.$touch()"
					/>
				</div>

				<select-input
					v-model="codeType"
					class-prefix="data-integration"
					item-label="name"
					container-class="select-container"
					label="dataIntegration.ruleSets.fields.codeType"
					:options="availableCodeTypes"
					:placeholder="t('dataIntegration.ruleSets.actions.selectCodeType')"
					:searchable="false"
				/>

				<select-input
					v-model="selectedCode"
					:disabled="!canEdit || codesLoading"
					:invalid="invalid('code')"
					:mandatory="true"
					:options="availableCodes"
					:searchable="true"
					class-prefix="data-integration"
					container-class="select-container"
					item-label="name"
					label="dataIntegration.ruleSets.labels.mapping"
					@input="onCodeSelect"
				/>

				<selected-codes :codes="selectedCodes" @delete="removeSelectedCode" />

				<label
					v-translate
					:class="radioLabelClass('defaultMappedTo')"
				>dataIntegration.ruleSets.labels.defaultBelonging</label>
				<div class="data-integration__radio-row">
					<radio-input
						v-model="item.defaultMappedTo"
						value-name="MAIN_RESPONDENT"
						label="dataIntegration.ruleSets.fields.roleRespondent"
						:disabled="!canEdit"
						@input="$v.item.defaultMappedTo.$touch()"
					/>
				</div>
				<div class="data-integration__radio-row data-integration__radio-row--last">
					<radio-input
						v-model="item.defaultMappedTo"
						value-name="ROLE"
						label="dataIntegration.ruleSets.fields.roleRest"
						:disabled="!canEdit"
						@input="$v.item.defaultMappedTo.$touch()"
					/>
				</div>
			</div>
			<section class="data-integration__save-container">
				<submit-button
					:label="t('Update')"
					:can-submit="allowUpdate"
					class-prefix="data-integration"
				/>
			</section>
		</form>
	</div>
</template>

<script>
import can from 'acl-can';
import cwalert from 'cwalert';
import selectOptions from '../../helpers/select-options';
import t from 'translate';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { assign, cloneDeep, differenceBy, filter, find, get, map, reject, upperCase } from 'lodash';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';
import { CODE_TYPES } from 'store/data-integration/code-types';
import { CODES } from 'store/data-integration/codes';
import { RULE_SETS } from 'store/data-integration/rule-sets';
import selectedCodes from '../../components/selected-codes';

export default {
	instant: true,
	title: t('dataIntegration.ruleSets.edit'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
		op: READ
	}],
	components: { selectedCodes },
	data ()  {
		const ruleSet = this.$store.getters[RULE_SETS.SINGLE_ITEM];

		return {
			codeType: {},
			codesLoading: false,
			item: assign({}, ruleSet, {
				defaultCodes: map(ruleSet.defaultCodes, 'id')
			}),
			noTreatmentType: false,
			submitted: false,
			updating: false,
			selectedCode: {},
			selectedCodes: map(
				ruleSet.defaultCodes,
				(code) => assign({}, code, {
					codeType: get(
						find(this.$store.getters[CODES.WITH_ENTERPRISE], { id: code.id }),
						'codeType',
						{}
					)
				})
			)
		};
	},

	computed: {
		...mapGetters({
			defaultRuleSet: 'dataIntegration/ruleSets/default',
			enterprises: 'dataIntegration/enterprises/list',
			roles: 'treatmentType/sortedRoles',
			treatmentTypes: 'treatmentTypes/sortedByName',
			urlParams: 'urlParams'
		}),

		codeTypes: ({ $store }) => $store.getters[CODE_TYPES.SORTED],
		codesWithEnterprise: ({ $store }) => $store.getters[CODES.WITH_ENTERPRISE],
		codesWithoutEnterprise: ({ $store }) => $store.getters[CODES.WITHOUT_ENTERPRISE],

		availableCodeTypes: ({ codeTypes, selectedCodes }) => differenceBy(
			codeTypes,
			selectedCodes,
			(codeOrType) => get(codeOrType, 'codeType.id', codeOrType.id)
		),

		allowUpdate: ({ $v, canEdit }) => !$v.item.$invalid && canEdit,

		canEdit: ({ updating }) => can.edit(SYSTEM.SETTINGS.DATA_INTEGRATION_RULES) && !updating,

		codes: ({ codesWithEnterprise, codesWithoutEnterprise, item }) => item.enterprise ?
			codesWithEnterprise :
			codesWithoutEnterprise,

		filteredCodes: ({ codes, codeType }) => filter(codes, ['codeType.id', codeType.id]),

		availableCodes: (
			{ filteredCodes, selectedCodes }
		) => differenceBy(filteredCodes, selectedCodes, 'id'),

		defaultRule: ({ defaultRuleSet, urlParams }) => {
			if (!defaultRuleSet.length) {
				return false;
			}
			return +defaultRuleSet[0].id !== +urlParams.ruleSetId;
		},

		enterpriseName () {
			return get(
				this.item.enterprise,
				'name',
				t('dataIntegration.enterprise.options.noEnterprise')
			);
		},

		mandatoryTreatmentType () {
			return !this.defaultRule && !this.noTreatmentType;
		},

		treatmentType: {
			get () {
				return this.getOption({
					field: 'treatmentType',
					emptyLabel: t('dataIntegration.ruleSets.options.default')
				});
			},
			set (option) {
				this.item.treatmentType = this.setOption(this.item.treatmentType, option);
				this.item.treatmentType.enterprise = find(
					this.enterprises,
					{ id: option.enterprise }
				);
			}
		},

		treatmentTypesOptions () {
			return selectOptions(this.treatmentTypes, 'enterprise');
		}
	},

	created () {
		if (this.item.enterprise) {
			this.codesLoading = true;
			this.$store.dispatch(
				'dataIntegration/codes/searchCodesByEnterprise',
				this.item.enterprise.id
			).then(() => {
				this.codesLoading = false;
			});
		}
		this.noTreatmentType = !!this.item.treatmentType;
	},

	methods: {
		...mapActions('dataIntegration', ['ruleSetUpdate']),

		getOption ({ field, emptyLabel = false, innerProperty = 'name' }) {
			const noLabel = emptyLabel || '';
			return ({
				label: this.item[field] ? this.item[field][innerProperty] : noLabel,
				value: this.item[field] ? this.item[field].id : '',
				enterprise: this.item[field] ? this.item[field].enterprise : ''
			});
		},

		invalid (field) {
			const invalid = this.$v.item[field] && this.$v.item[field].$invalid;
			return this.submitted && invalid;
		},

		onSubmit () {
			this.submitted = true;

			if (!this.$v.item.$invalid) {
				const data = {
					...this.item,
					enterprise: get(this.item, 'enterprise.id', null),
					treatmentType: get(this.item, 'treatmentType.id', null)
				};
				this.updating = true;
				this.$store.dispatch('dataIntegration/ruleSets/update', data).then(() => {
					cwalert.success(t('dataIntegration.ruleSets.messages.updated'));
					this.updating = false;
				});
			}
		},

		radioLabelClass (type) {
			const classes = ['data-integration__label--mandatory'];

			if (this.$v.item[type].$error) {
				classes.push('data-integration__label--invalid');
			}
			return classes;
		},

		setOption (obj, option) {
			obj.id = option ? option.value : null;
			obj.name = option ? option.label : '';
			return obj;
		},

		toUpper: (phrase) => upperCase(t(phrase)),

		onCodeSelect () {
			this.selectedCodes.push(cloneDeep(this.selectedCode));
			this.item.defaultCodes.push(this.selectedCode.id);
			this.selectedCode = null;
			this.codeType = {};
		},

		removeSelectedCode (id) {
			this.selectedCodes = reject(this.selectedCodes, { id });
			this.item.defaultCodes = reject(this.item.defaultCodes, (code) => code === id);
		}
	},

	validations () {
		const validationConfig = {
			item: {
				defaultAccess: { required },
				defaultCodes: { required },
				defaultMappedTo: { required }
			}
		};

		if (this.item.treatmentType) {
			validationConfig.item.treatmentType = { required };
		}
		return validationConfig;
	}
};
</script>
