import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import MessageDraftAttachment from './message-draft-attachment';

/**
 * MessageDraftAttachments Entity
 * @class MessageDraftAttachments
 * @extends MultipleEntities
 */
const MessageDraftAttachments = new (MultipleEntities.extend({
	model: MessageDraftAttachment,
	urls,
	eventNamespace: 'message-draft-attachment'
}))();

export { MessageDraftAttachments };
