import { assign, forEach, get, split } from 'lodash';
import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import Attribute from 'repo/treatments/attribute-entity';
import store from 'store';
import can from 'acl-can';
import { DateTime } from 'luxon';

export default TileView.extend({
	acl: [{
		checkpoint: 'administrator.respondents.treatments',
		op: 'READ'
	}],
	title: t('Edit treatment attributes'),

	actions: () => [
		'treatment/init',
		'treatment/getAttributesDefinition',
		'treatment/getAttributesValues'
	],

	loaded: ({ tile }) => {
		assign(tile, {
			treatment: get(store.state, 'treatment.model'),
			treatmentTypeAttributes: store.getters['treatmentType/visibleAttributes'],
			treatmentAttributes: get(store.state, 'treatment.attributesValues.collection')
		});
		tile.$el.empty();
		!tile.treatmentTypeAttributes.length && tile.disable();
		forEach(tile.treatmentTypeAttributes, (treatmentTypeAttribute) => {
			tile.renderForm({ tile, treatmentTypeAttribute });
		});
	},

	renderForm: ({ tile, treatmentTypeAttribute }) => {
		const treatmentAttribute = tile.treatmentAttributes.findWhere({
			typeAttribute: `${treatmentTypeAttribute.id}`
		}) || new Attribute({
			treatment: tile.treatment.getId(),
			typeAttribute: `${treatmentTypeAttribute.id}`
		});

		const formConfig = {
			name: 'edit-treatment-attribute',
			model: treatmentAttribute,
			saveIndicator: true,
			hideMandatory: true,
			beforeSave: () => {
				if (treatmentTypeAttribute.type === 'DATE') {
					treatmentAttribute.set(
						'value',
						DateTime.fromISO(treatmentAttribute.get('value')).toFormat('yyyy-MM-dd')
					);
				}
			},
			successMessage: t('Successfully saved treatment attribute'),
			errorMessage: t('Error saving treatment attribute'),
			fields: [tile.getAttributeField({ treatmentTypeAttribute })]
		};

		tile.form = formView(formConfig);
		tile.$el.append(tile.form.$el);
	},

	getAttributeField ({ treatmentTypeAttribute }) {
		const field = {
			STRING: { type: 'text' },
			TEXTAREA: { type: 'textarea' },
			CHECKBOX: { type: 'checkbox' },
			NUMERIC: { type: 'number' },
			DATE: treatmentTypeAttribute.isReadonly ? { type: 'text' } : { type: 'date' },
			DROPDOWN: {
				type: 'select',
				description: t('Select a value'),
				values: () => split(treatmentTypeAttribute.parameters, /;/)
			}
		}[treatmentTypeAttribute.type];

		return assign(field, {
			key: 'value',
			label: treatmentTypeAttribute.label,
			mandatory: treatmentTypeAttribute.isRequired,
			readonly: !can.edit('administrator.respondents.treatments') ||
				treatmentTypeAttribute.isReadonly
		});
	}
});
