import Plan from 'repo/plans/entity';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';
import cardUrl from 'cwcardurl';

export default () => {
	const plan = new Plan();
	plan.attributes.details.author = store.getters.userId;

	const formConfig = {
		name: 'new-plan',
		model: plan,
		successMessage: t('Successfully created a plan'),
		errorMessage: t('Error creating a plan'),

		beforeSave: () => {
			plan.attributes.details.name = plan.get('name');
			plan.unset('name');
		},

		fields: [{
			key: 'name',
			label: t('Title'),
			mandatory: true,
			type: 'text',
			focus: true
		}],

		onAfterSave ({ entityId }) {
			cardUrl.openCard('plan', { planId: entityId });
		}
	};

	formViewDialog({
		formConfig,
		dialogConfig: { title: t('New plan') }
	});
};
