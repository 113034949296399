import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import TreatmentTypeAttribute from 'repo/treatment-types/attribute-entity';

export default function (params = {}) {
	const treatmentTypeAttribute = new TreatmentTypeAttribute({
		treatmentType: params.treatmentTypeId
	});
	const formConfig = {
		name: 'new-tt-attribute',
		model: treatmentTypeAttribute,

		fields: [{
			key: 'label',
			label: t('Label'),
			type: 'text',
			mandatory: true
		}, {
			key: 'isRequired',
			label: t('Required'),
			type: 'checkbox'
		}, {
			key: 'isReadonly',
			label: t('Readonly'),
			type: 'checkbox'
		}, {
			key: 'isVisible',
			label: t('Visible'),
			type: 'checkbox'
		}, {
			key: 'type',
			label: t('Type'),
			type: 'select',
			values: [{
				CHECKBOX: t('Checkbox')
			}, {
				STRING: t('String')
			}, {
				TEXTAREA: t('Textarea')
			}, {
				NUMERIC: t('Numeric')
			}, {
				DATE: t('Date')
			}, {
				DROPDOWN: t('Dropdown')
			}]
		}, {
			key: 'order',
			label: t('Order'),
			type: 'number',
			required: true
		}, {
			key: 'parameters',
			label: t('Parameters'),
			type: 'text'
		}]
	};

	formViewDialog({
		formConfig,
		dialogConfig: {
			title: t('New treatment type attribute')
		}
	});
}
