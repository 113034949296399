import TileView from 'tile-view';
import can from 'acl-can';
import datamodel from 'components/old-components/datamodel/datamodel';
import formView from 'components/form-view/form-view';
import t from 'translate';
import repository from 'repository';
import AccessRoleCheckpoint from 'repo/access-roles/access-role-checkpoint';
import { assign, flow, map, merge, sortBy } from 'lodash';

export default TileView.extend({
	title: t('system-access-management.category-access'),
	acl: [{
		checkpoint: 'system.settings.administratorroles',
		op: 'READ'
	}],

	cardData: () => ['roleId'],

	tileData: ({ tile }) => ({
		checkpoints: repository.getCheckpoints(),
		accessRoleCheckpoint: new AccessRoleCheckpoint({ administratorRoleId: +tile.roleId }),
		accessRoleCheckpoints: repository.getAccessRoleCheckpoints(tile.roleId)
	}),

	loaded () {
		this.renderForm().renderTable();
	},

	renderForm: function () {
		var tile = this;
		var canEdit = can.edit('system.settings.administratorroles');
		var formCfg = {
			name: 'access-role-misc',
			listenTo: ['submit'],
			model: tile.accessRoleCheckpoint,

			onAfterSave: function () {
				tile.accessRoleCheckpoint.set('relationId', null);
				tile.table.refresh();
			},

			fields: [{
				key: 'administratorRoleAccessId',
				label: t('Group name'),
				type: 'select',
				description: t('Select an access…'),
				required: true,
				readonly: !canEdit,
				values: flow([
					() => map(this.checkpoints.toJSON(), (checkpoint) => assign(
						checkpoint,
						{
							[checkpoint.administratorRoleAccessId]:
								t(`acl.checkpoints.${checkpoint.administratorRoleAccessName}`),
							name:
								t(`acl.checkpoints.${checkpoint.administratorRoleAccessName}`)
						}
					)),
					(checkpoints) => sortBy(checkpoints, ['name'])
				])
			}, {
				key: 'accessLevelId',
				label: t('settings.roles-management.AccessLevel'),
				type: 'select',
				description: t('Select an access level…'),
				required: true,
				readonly: !canEdit,
				values: [{
					1: 'NONE'
				}, {
					3: 'READONLY'
				}, {
					4: 'ADD'
				}, {
					5: 'EDIT'
				}, {
					6: 'DELETE'
				}, {
					7: 'SUPERVISOR'
				}, {
					8: 'ADMINISTRATOR'
				}]
			}],
			buttons: [{
				caption: t('Add'),
				name: 'add',
				type: 'submit',
				disabled: !canEdit
			}]
		};

		this.$el.append(formView(formCfg).$el);

		return this;
	},

	renderTable: function () {
		const models = sortBy(this.accessRoleCheckpoints.models, (model) => {
			const name = t(`acl.checkpoints.${model.get('checkpoint').roleAccess.name}`);
			return name.length ? name : model.get('checkpoint').roleAccess.name;
		});
		this.table = new datamodel.grid.Table({
			collection: merge(this.accessRoleCheckpoints, { models }),
			noFetch: true,
			rootAttribute: 'checkpoint', /* <- points to attribute in model that should be used as
										    model (YYYYEEEEESSSS!) */
			columns: {
				roleAccess: {
					label: t('system-manager.RoleAccessName'),
					rendererFactory: new datamodel.widgets.LabelFactory({
						valueProcessor: function (checkpoint) {
							var translated = t('acl.checkpoints.' + checkpoint.name);
							return translated.length ? translated : checkpoint.name;
						}
					})
				},
				accessLevel: {
					label: t('settings.roles-management.AccessLevel'),
					rendererFactory: new datamodel.widgets.LabelFactory({
						valueProcessor: function (level) {
							return t('acl.levels.' + level.label);
						}
					})
				}
			},

			actions: {
				remove: {
					disabled: !can.edit('system.settings.administratorroles'),
					label: ''
				}
			},
			container: this.el
		});

		return this;
	}
});
