import t from 'translate';
import appBar from 'app-bar';
import can from 'acl-can';
import cwalert from 'cwalert';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import store from 'store';

const card = {
	title: t('Edit a treatment'),
	name: 'edit-treatment',
	url: 'treatment/:treatmentId/edit',

	ctrl: ({ actions, setLoading }, { treatmentId }) =>
		actions([
			['treatment/init'],
			['treatment/treatmentRoles', treatmentId]
		]).then(() => {
			if (!can.remove('administrator.respondents.treatments')) {
				return false;
			}
			appBar.addButton(card.name, {
				title: t('Delete treatment'),
				icon: 'delete',
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete treatment'),
						message: t('treatment.delete')
					}).then(async () => {
						setLoading();
						const respondentId = store.getters.respondent.respondentId;
						await store.dispatch('treatment/destroy', treatmentId);

						cwalert.success(t('Successfully deleted treatment'));
						cardUrl.openCard('treatment-list', { respondentId });
					});
				}
			});

			return actions(['treatment/getAttributesDefinition']);
		})
};

export { card as editTreatment };
