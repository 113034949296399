<template>
	<section class="menu-composer">
		<div class="type">
			<radio-input
				v-model="type"
				class-prefix="type"
				value-name="clinician"
				label="Clinician Portal"
			/>
			<radio-input
				v-model="type"
				class-prefix="type"
				value-name="respondent"
				label="Respondent Portal"
			/>
		</div>

		<variant-edit :type="type" />

		<h2>Menu items</h2>
		<table class="table menu-composer__table">
			<thead>
				<tr>
					<th />
					<th>{{labels.label}}</th>
					<th>{{labels.destination}}</th>
					<th>{{labels.style}}</th>
					<th />
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in items"
					:key="index"
					tabindex="0"
					class="clickable-row"
					@click.stop="editItem(index)"
					@keyup.prevent.enter.space="editItem(index)"
				>
					<td class="menu-composer__item--order">
						<button-up
							v-if="index > 0 && allowEdit"
							:on-click="moveUp(index)"
						/><br />
						<button-down
							v-if="index < items.length - 1 && allowEdit"
							:on-click="moveDown(index)"
						/>
					</td>
					<td class="menu-composer__item menu-composer__item--label">
						<p class="table-cell-label">
							{{labels.label}}
						</p>
						<p>{{item.label}}</p>
					</td>
					<td class="menu-composer__item menu-composer__item--destination">
						<p class="table-cell-label">
							{{labels.destination}}
						</p>
						<p>{{item.target}}</p>
					</td>
					<td class="menu-composer__item menu-composer__item--style">
						<p class="table-cell-label">
							{{labels.style}}
						</p>
						<p>{{item.style}}</p>
					</td>
					<td class="menu-composer__item menu-composer__item--remove">
						<button-remove
							v-if="allowDelete"
							:on-click="remove({ item, index })"
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
import { escape } from 'lodash';
import cwalert from 'cwalert';
import cardUrl from 'cwcardurl';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import VariantEdit from './variant-edit';

export default {
	components: {
		VariantEdit
	},

	data () {
		return {
			allowEdit: can.edit('system.settings.settings'),
			allowDelete: can.remove('system.settings.settings'),
			state: this.tile.cardContext().get('state'),
			labels: {
				label: this.$t('Label'),
				destination: this.$t('Destination'),
				style: this.$t('Style')
			}
		};
	},
	computed: {
		type: {
			get: ({ state }) => state.type,
			set (type) {
				this.state.type = type;
				// communicate with card so that it can update URL
				this.tile.cardContext().set('state', { type }, { silent: true });
				this.tile.cardContext().trigger('change:state'); // need to call it manually
			}
		},
		items: ({ $store, state }) => $store.getters[`engine/${state.type}Sidebar/currentMenuItems`]
	},

	methods: {
		editItem (index) {
			const currentVariant = this.$store.getters[
				`engine/${this.state.type}Sidebar/currentVariant`
			];

			cardUrl.openCard('menu-composer-edit-item', {
				type: this.state.type,
				variant: currentVariant.name,
				index
			});
		},

		moveUp (index) {
			return () => {
				this.$store.dispatch(`engine/${this.state.type}Sidebar/moveItemUp`, index);
			};
		},

		moveDown (index) {
			return () => {
				this.$store.dispatch(`engine/${this.state.type}Sidebar/moveItemDown`, index);
			};
		},

		remove ({ item, index }) {
			return () => {
				confirmation({
					icon: 'delete',
					warning: true,
					title: this.$t('Remove item'),
					message: this.$t(`Are you sure you want to remove item <b>{label}</b>?`, {
						label: escape(item.label)
					})
				})
					.then(() => this.$store.dispatch(
						`engine/${this.state.type}Sidebar/remove`,
						index
					))
					.then(() => {
						cwalert.success(this.$t('Item {label} has been removed', {
							label: escape(item.label)
						}));
					});
			};
		}
	}
};
</script>
