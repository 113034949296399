import t from 'translate';
import repository from 'repository';
import appBar from 'app-bar';
import addLinkDialog from '../dialogs/new-link';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('Links'),
	name: 'links',
	url: 'links',

	ctrl: ({ cardConfig, cardData }) => {
		if (can.add(SYSTEM.SETTINGS.LINKS)) {
			appBar.addButton(card.name, {
				icon: 'add',
				title: t('New link'),
				click: addLinkDialog
			});
		}

		const categories = repository.getLinkCategories();

		cardData.set({
			limit: cardConfig.limit,
			categories
		});

		return categories;
	}
};

export { card as LinksList };
