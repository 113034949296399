<template>
	<div>
		<div>
			<div class="role-set-roles__container role-set-roles--mandatory">
				<p v-translate class="role-set-roles__label">
					Name
				</p>
				<input
					v-model="roleSetForm.name"
					type="text"
					:disabled="updatingRoleSet || !canEdit"
					class="role-set-roles__input"
					@input="$v.roleSetForm.name.$touch()"
				/>
				<template v-if="$v.roleSetForm.name.$invalid && !$v.roleSetForm.name.$pending">
					<mandatory-warning v-if="!$v.roleSetForm.name.required" name="name" />
					<mandatory-warning v-else-if="!$v.roleSetForm.name.unique" name="name">
						{{$t('roleSets.error.roleSetNameIsNotUnique')}}
					</mandatory-warning>
				</template>
			</div>
			<div class="role-set-roles__container">
				<p
					v-translate
					class="role-set-roles__label"
				>Description</p>
				<textarea
					v-model="roleSetForm.description"
					:disabled="updatingRoleSet || !canEdit"
					class="role-set-roles__textarea"
				/>
			</div>
			<div class="role-set-roles__container">
				<button
					v-translate
					:disabled="invalidName || updatingRoleSet || !canEdit"
					class="role-set-roles__button--save"
					@click="updateMainData"
				>Save</button>
			</div>
		</div>
		<div
			:class="{'role-set-roles__container--not-initialised': !roles}"
			class="role-set-roles__container--select"
		>
			<p v-translate class="role-set-roles__label">
				Select role
			</p>
			<div class="role-set-roles__select">
				<v-select
					:disabled="updatingRole || !canEdit"
					:options="filteredList"
					:placeholder="selectPlaceholder"
					:value="selected"
					label="name"
					@input="addRole"
				>
					<template #no-options>
						{{$t('selectInput.noResults')}}
					</template>
				</v-select>
			</div>
		</div>
		<div v-if="canEdit" class="role-set-roles__button-container">
			<p
				v-translate
				class="role-set-roles__label"
			>The role you are looking for does not exist?</p>
			<button
				v-translate
				class="role-set-roles__button"
				@click="addItem()"
			>Create a new role</button>
		</div>
		<div class="table__container">
			<div v-if="!roles" v-loader-spinner class="role-set-roles__loading-table" />
			<table v-if="roles && roles.length" class="table">
				<thead>
					<tr>
						<th v-translate>
							Roles
						</th>
						<th v-translate>
							Description
						</th>
						<th v-translate>
							Required
						</th>
						<th v-translate>
							Delete
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in roles"
						:key="item.role.id"
						:class="{ 'clickable-row': canEdit }"
					>
						<td @click="onItemClick(item.role.id)">
							<p class="table-cell-label">
								Roles
							</p>
							{{item.role.name}}
						</td>
						<td @click="onItemClick(item.role.id)">
							<p class="table-cell-label">
								Description
							</p>
							{{item.role.description}}
						</td>
						<td>
							<p class="table-cell-label">
								Required
							</p>
							<input
								v-model="item.required"
								:disabled="!canEdit"
								type="checkbox"
								@change="update(item.role.id, item.required)"
							/>
						</td>
						<td>
							<p class="table-cell-label">
								Delete
							</p>
							<button
								v-if="canDelete"
								class="icon icon--delete role-set-roles__button-delete"
								:title="removeLabel"
								@click.stop="deleteRole(item.role.id, item.id)"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<p v-if="roles && !roles.length" v-translate>
				No matches found
			</p>
		</div>
	</div>
</template>

<script>
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import mandatoryWarning from '../../components/mandatory-warning';
import roleDialog from '../../dialogs/edit-role-wrapper';
import vSelect from 'vue-select';
import { differenceBy, findIndex, map, noop, get, clone } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';
import checkRolesetAvailability from '../../util/check-roleset-availability';

export default {
	components: { mandatoryWarning, vSelect },
	data: ({ $store, tile }) => ({
		roleSetForm: clone($store.getters['roleSets/data']),
		canDelete: can.delete(SYSTEM.SETTINGS.TREATMENT_ROLES),
		canEdit: can.edit(SYSTEM.SETTINGS.TREATMENT_ROLES),
		canAdd: can.add(SYSTEM.SETTINGS.TREATMENT_ROLES),
		roleSetId: tile.cardContext().get('roleSetId'),
		selected: null,
		updatingRole: false,
		updatingRoleSet: false
	}),
	computed: {
		...mapGetters({
			roles: 'roleSetRoles/roles',
			rolesList: 'roles/list',
			roleSet: 'roleSets/data'
		}),
		filteredList () {
			if (!this.roles) {
				return [];
			}
			const roles = map(this.roles, 'role');
			return differenceBy(this.rolesList, roles, 'id');
		},
		invalidName () {
			const name = this.$v.roleSetForm.name;
			return name.$dirty && name.$invalid;
		},
		removeLabel: () => t('Remove role'),
		selectPlaceholder: () => t('Select a role or type a name')
	},

	methods: {
		...mapActions(
			'roleSetRoles',
			['addRoleToRoleSet', 'deleteRoleFromRoleSet', 'setRoleRequired']
		),
		...mapActions('roleSets', ['updateRoleSet']),
		addItem () {
			roleDialog({
				role: {},
				action: 'roleSetRoles/addNewRoleToRoleSet',
				successMsg: t('Role has been added to the role set'),
				title: t('New role')
			});
		},
		addRole (obj = null) {

			if (obj) {
				this.updatingRole = true;
				this.addRoleToRoleSet({ roleId: obj.id })
					.then(() => {
						cwalert.success(t('Role has been added to the role set'));
						this.updatingRole = false;
					})
					.catch(() => {
						cwalert.error(t('Error'));
						this.updatingRole = false;
					});
			}

		},
		deleteRole (roleId) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove role'),
				message: t('Are you sure you want to delete this role from the role set?')
			}).done(() => {
				this.$store.dispatch('roleSetRoles/deleteRoleFromRoleSet', { roleId })
					.then(() => {
						cwalert.success(t('Role has been deleted'));
					});
			});
		},

		onItemClick (roleId) {
			return this.canEdit ? this.showDetails(roleId) : noop();
		},

		showDetails (id) {
			const currentIndex = findIndex(this.roles, ['role.id', id]);

			if (currentIndex === -1) {
				return;
			}

			roleDialog({
				role: this.roles[currentIndex].role,
				action: 'roleSetRoles/updateRoleInRoleSet',
				successMsg: t('Role has been updated'),
				title: t('Edit role')
			});
		},
		update (roleId, required) {
			this.updatingRole = true;
			this.setRoleRequired({ roleId, required })
				.then(() => {
					cwalert.success(t('Role has been updated'));
					this.updatingRole = false;
				})
				.catch(() => {
					cwalert.error(t('Error'));
					this.updatingRole = false;
				});
		},
		updateMainData () {
			this.updatingRoleSet = true;
			this.updateRoleSet(this.roleSetForm)
				.then(() => {
					cwalert.success(t('Role set has been updated'));
					this.updatingRoleSet = false;
				})
				.catch(() => {
					cwalert.error(t('Error'));
					this.updatingRoleSet = false;
				});
		}
	},

	validations: {
		roleSetForm: {
			name: {
				required,

				async unique (value) {
					if (value === get(this.roleSet, 'name')) {
						return true;
					}

					return await checkRolesetAvailability(value);
				}
			}
		}
	}
};
</script>
