<template>
	<assign-items
		:use-search="false"
		:can-edit="false"
		:can-remove="false"
		:labels="labels"
		:getters="getters"
		:actions="actions"
	/>
</template>

<script>
import t from 'translate';

export default {
	title: t('Clinician groups with access to this consent'),
	actions: ['consent/setConsentClinicianGroups'],
	data: () => ({
		labels: {
			empty: t('There are no treatment types selected at this moment'),
			name: t('Clinician group')
		},
		getters: {
			allItems: '',
			items: 'consent/clinicianGroups'
		},
		actions: {
			removeItem: '',
			addItem: ''
		}
	})
};
</script>
