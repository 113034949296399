var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "modal-dialog__selection" }, [
    _c(
      "h3",
      {
        directives: [{ name: "translate", rawName: "v-translate" }],
        staticClass: "modal-dialog__selection-title"
      },
      [_vm._v(_vm._s(_vm.title))]
    ),
    _vm._v(" "),
    _c(
      "ul",
      [
        _c("li", { staticClass: "checkbox-input__field-container" }, [
          _c("label", { staticClass: "checkbox-input__label-container" }, [
            _c("input", {
              class: _vm.checkboxClasses,
              attrs: { type: "checkbox" },
              domProps: {
                checked: _vm.allSelected,
                indeterminate: _vm.someSelected
              },
              on: { click: _vm.toggleAll }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.itemLabelClasses
              },
              [_vm._v("Select all")]
            )
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.collection, function(item) {
          return _c(
            "li",
            {
              key: item[_vm.loopKey],
              staticClass: "checkbox-input__field-container"
            },
            [
              _c("label", { staticClass: "checkbox-input__label-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected"
                    }
                  ],
                  class: _vm.checkboxClasses,
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: item,
                    checked: Array.isArray(_vm.selected)
                      ? _vm._i(_vm.selected, item) > -1
                      : _vm.selected
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = item,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selected = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selected = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { class: _vm.itemLabelClasses }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(item[_vm.itemLabel]) + "\n\t\t\t\t"
                  )
                ])
              ])
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }