import can from 'acl-can';
import change from '../../helpers/change';
import cardUrl from 'cwcardurl';
import cwalert from 'service/cwalert';
import enabled from '../../helpers/enabled';
import onSuccessHandler from '../../helpers/on-success-handler';
import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import store from 'store';
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { assign, get, map } from 'lodash';

const FIELD_TYPE = 'single-select';
const SELF_NAME = 'code';

const removalCfg = {
	action: 'dataIntegration/codes/delete',
	question: 'dataIntegration.codes.actions.delete',
	title: 'dataIntegration.codes.actions.remove'
};

export default SearchTileView.extend({
	title: t('Codes'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
		op: READ
	}],
	searchFn (params) {
		const result = store.dispatch(
			'dataIntegration/codes/search',
			assign({ name: SELF_NAME }, params)
		);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},
	fields: ({ tile }) => [{
		key: 'codeType.id',
		type: FIELD_TYPE,
		label: t('dataIntegration.codeType.name'),

		values: map(
			store.getters['dataIntegration/codeTypes/sorted'],
			(codeType) => assign({}, codeType, { id: codeType.id, label: codeType.name })
		),

		onChange: (value) => change({ field: 'codeType.id', tile, value })
	}, {
		key: 'enterprise.id',
		type: FIELD_TYPE,
		label: t('dataIntegration.enterprise.name'),

		values: [
			{ id: null, label: t('dataIntegration.enterprise.options.noEnterprise') },
			...map(
				store.getters['dataIntegration/enterprises/sorted'],
				(enterprise) => assign(
					{},
					enterprise,
					{ id: enterprise.id, label: enterprise.name }
				)
			)
		],

		onChange: (value) => change({ field: 'enterprise.id', tile, value })
	}, {
		key: 'enabled',
		type: FIELD_TYPE,
		label: t('dataIntegration.codes.fields.enabled'),
		values: map(enabled, (type) => assign(
			type,
			{ label: type.name }
		)),

		onChange: (value) => change({ field: 'enabled', tile, value })
	}],

	tableCfg ({ tile }) {
		const className = 'codes-list';
		const config = {
			store: {
				items: () => store.getters['dataIntegration/codes/list'],
				count: 'dataIntegration/codes/count',
				mutation: 'dataIntegration/codes/setList',
				total: 'dataIntegration/codes/total'
			},
			click: (code) => {
				cardUrl.openCard('data-integration-code-edit', {
					codeId: code.id
				});
			},
			columns: [{
				label: t('dataIntegration.codes.fields.name'),
				key: 'name',
				css: `${className}__name`
			}, {
				label: t('dataIntegration.codes.fields.code'),
				key: 'code',
				css: `${className}__code`
			}, {
				label: t('dataIntegration.codes.fields.type'),
				key: 'codeType',
				css: `${className}__type-code`,
				render: (codeType) => codeType.code
			}, {
				label: t('dataIntegration.codes.fields.typeName'),
				key: 'codeType',
				css: `${className}__type-name`,
				render: (codeType) => codeType.name
			}, {
				label: t('dataIntegration.codes.fields.enterprise'),
				key: 'enterprise',
				css: `${className}__enterprise`,
				render: (enterprise) => {
					const empty = t('dataIntegration.enterprise.options.noEnterprise');
					return get(enterprise, 'name', empty);
				}
			}, {
				label: t('dataIntegration.codes.fields.enabled'),
				key: 'enabled',
				css: `${className}__enabled`,
				render: (enabled) => enabled ? t('yes') : t('no')
			}]
		};

		if (can.remove(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE)) {
			config.columns.push({
				label: t('Delete'),
				render () {
					searchTileDelete({
						data: removalCfg,
						item: this.item,
						onSuccess: onSuccessHandler({ tile, type: 'codes' }),
						td: this.td,
						tile,
						onError: () => {
							cwalert.error(t('dataIntegration.codes.messages.inUse'));
						}
					});
				}
			});
		}
		return config;
	}
});
