<template>
	<div>
		<section v-if="canAdd('data_integration_rules')" class="data-integration__add-container">
			<button
				v-translate
				:disabled="!availableRoles.length"
				class="submit-button__button submit-button__button--submit"
				type="submit"
				@click="add"
			>dataIntegration.roleMapping.actions.add</button>
		</section>
		<div v-if="roleMappingList.length">
			<table class="data-integration__role-mapping__table table">
				<thead>
					<tr>
						<th
							v-for="(header, i) in translatedHeaders"
							:key="i"
							scope="col"
						>{{header}}</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in roleMappingList"
						:key="item.id"
						:class="rowClass"
						tabindex="0"
						class="clickable-row"
						@click="update(item)"
					>
						<td>
							{{item.srcRole.name}}
						</td>
						<td>
							{{item.dstRole.name}}
						</td>
						<td :class="allowClass(item, className)">
							{{allowLabel(item, 'roleMapping')}}
						</td>
						<td v-if="canDelete('data_integration_rules')">
							<button
								class="icon-button__button"
								@click.stop="remove(item)"
							>
								<icon name="delete" :small="true" />
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<p v-translate>
				dataIntegration.roleMapping.hint
			</p>
		</div>
		<div v-else v-translate>
			No items
		</div>
	</div>
</template>

<script>
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import dialog from '../../dialogs/role-mapping';
import store from 'store';
import translateHeaders from '../../helpers/translate-headers';
import t from 'translate';
import { allowClass, allowLabel } from '../../helpers/allow-handler';
import { assign, find, filter } from 'lodash';
import { mapGetters } from 'vuex';
import { canAdd, canDelete, canUpdate } from '../../helpers/acl-handler';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	data: ({ tile }) => ({
		allowClass,
		allowLabel,
		className: 'data-integration__role-mapping',
		fields: ['role', 'roleMapped', 'allow'],
		id: tile.cardContext().get('ruleSetId'),
		emptyData: { srcRole: null, dstRole: null, access: true },
		modalCfg: {
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
			action: 'addAndRefreshRoleMapping',
			item: {},
			refresh: tile.runQuery,
			success: t('dataIntegration.roleMapping.messages.added'),
			title: 'dataIntegration.roleMapping.actions.add',
			type: 'roleMapping'
		},
		updating: false
	}),

	computed: {
		...mapGetters({
			params: 'urlParams',
			roles: 'treatmentType/sortedRoles',
			roleMappingList: 'dataIntegration/roleMappingList'
		}),

		availableRoles: ({ roles, roleMappingList }) => filter(
			roles, (role) => !find(roleMappingList,	['srcRole.id', role.id])
		),

		rowClass: ({ canUpdate }) => canUpdate('data_integration_rules') ? 'clickable-row' : '',

		translatedHeaders: ({ fields }) => translateHeaders({
			fields,	translation: 'dataIntegration.roleMapping.fields'
		})
	},

	methods: {
		add () {
			dialog(assign({
				roles: this.roles,
				rolesAvailable: this.availableRoles,
				ruleSet: { id: this.$store.getters.urlParams.ruleSetId }
			}, assign(this.modalCfg, { item: { ...this.emptyData } })));
		},

		canAdd,

		canDelete,

		canUpdate,

		remove (item) {
			return confirmation({
				icon: 'delete',
				warning: true,
				title: t('dataIntegration.roleMapping.actions.remove'),
				message: t('dataIntegration.roleMapping.actions.delete')
			}).done(() => {
				const data = { id: item.id, cardId: this.params.ruleSetId };
				store.dispatch('dataIntegration/deleteAndRefreshRoleMapping', data)
					.then(() => {
						cwalert.success(t('dataIntegration.roleMapping.messages.deleted'));
					});
			});
		},

		update (item) {
			if (this.canUpdate('data_integration_rules')) {
				dialog({
					...this.modalCfg,
					action: 'updateAndRefreshRoleMapping',
					item: {
						...item,
						srcRole: {
							label: item.srcRole.name,
							value: item.srcRole.id
						},
						dstRole: {
							label: item.dstRole.name,
							value: item.dstRole.id
						}
					},
					roles: this.roles,
					rolesAvailable: this.availableRoles,
					success: t('dataIntegration.roleMapping.messages.updated'),
					title: 'dataIntegration.roleMapping.actions.update'
				});
			}
		}
	}
};
</script>
