import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

/**
 * CheckpointGroup Entity
 * @class CheckpointGroup
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'administratorRoleAccessCategoryId',
	eventNamespace: 'checkpoint-group',
	urlRoot: urls.singleCheckpointGroup.url,

	getName: function () {
		const translated = t(`acl.groups.${this.get('administratorRoleAccessCategoryName')}`);
		return translated.length ? translated : this.get('administratorRoleAccessCategoryName');
	}
});
