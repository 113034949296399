import AssessmentContexts from './system-settings-assessment-contexts/tile';
import AssessmentDetails from './system-settings-assessment-details/tile';
import AssessmentList from './system-settings-assessment-list/tile';
import AssessmentPortal from './system-settings-assessment-portal/tile';
import AssessmentTypes from './system-settings-assessment-types/tile';
import Clinician from './system-settings-clinician/tile';
import ClinicianAttributes from './system-settings-clinician-attributes/tile';
import ClinicianAttributesAdd from './system-settings-clinician-attributes-add/tile';
import ClinicianGroups from './system-settings-clinician-groups/tile';
import Languages from './system-settings-languages/tile';
import MenuComposerList from './menu-composer-list/tile';
import MenuComposerEditItem from './menu-composer-edit-item';
import menuComposerPreview from './menu-composer-preview';
import miniShortcut from './mini-system-settings-shortcut/tile';
import Properties from './system-access-role-properties/tile';
import RelationTypes from './system-settings-relation-types/tile';
import RemoteScoring from './system-settings-remote-scoring/tile';
import RemoteScoringEdit from './system-settings-remote-scoring-edit/tile';
import Reports from './system-settings-reports/tile';
import Respondent from './system-settings-respondent/tile';
import RespondentAttributes from './system-settings-respondent-attributes/tile';
import RespondentAttributesAdd from './system-settings-respondent-attributes-add/tile';
import RespondentGroups from './system-settings-respondent-groups';
import RoleList from './system-access-role-list/tile';
import RolesHighLevel from './system-access-role-high-level/tile';
import RolesLowLevel from './system-access-role-low-level/tile';
import System from './system-settings-admin/tile';
import systemSettings from './system-settings';

export default  {
	'menu-composer-list': MenuComposerList,
	'menu-composer-edit-item': MenuComposerEditItem,
	'menu-composer-preview': menuComposerPreview,
	'system-settings': systemSettings,
	'system-settings-admin': System,
	'system-settings-assessment-contexts': AssessmentContexts,
	'system-settings-assessment-types': AssessmentTypes,
	'system-settings-assessment-list': AssessmentList,
	'system-settings-assessment-details': AssessmentDetails,
	'system-settings-assessment-portal': AssessmentPortal,
	'system-settings-clinician': Clinician,
	'system-settings-clinician-attributes': ClinicianAttributes,
	'system-settings-clinician-attributes-add': ClinicianAttributesAdd,
	'system-settings-clinician-groups': ClinicianGroups,
	'system-settings-languages': Languages,
	'system-settings-relation-types': RelationTypes,
	'system-settings-remote-scoring': RemoteScoring,
	'system-settings-remote-scoring-edit': RemoteScoringEdit,
	'system-settings-reports': Reports,
	'system-settings-respondent': Respondent,
	'system-settings-respondent-attributes': RespondentAttributes,
	'system-settings-respondent-attributes-add': RespondentAttributesAdd,
	'system-settings-respondent-groups': RespondentGroups,
	'mini-system-settings-shortcut': miniShortcut,
	'system-access-role-list': RoleList,
	'system-access-role-properties': Properties,
	'system-access-role-high-level': RolesHighLevel,
	'system-access-role-low-level': RolesLowLevel
};
