import urlify from 'service/urlify/urlify';

const relationTypesUrl = 'backend-commons/respondent-relation-type';

const relationTypeId = (id) => `${relationTypesUrl}/${id}`;
const relationTypes = () => `${relationTypesUrl}/`;
const searchRelationTypes = (params) => `${relationTypesUrl}/?${urlify(params)}`;
const searchSingleRelationType = (id) => `${relationTypesUrl}/${id}`;

export {
	relationTypeId,
	relationTypes,
	searchRelationTypes,
	searchSingleRelationType
};
