import { View } from 'backbone';
import t from 'translate';
import formView from 'components/form-view/form-view';
import { READ } from 'service/acl/access-levels';

const CHANGE_SHOW_ALL = 'change:show-all';
const reset = (view) => (model, changedValue) => {
	if (changedValue) {
		view.setChecked(false);
		view.model.set('value', false);
	}
};

export default View.extend({
	title: t('store.filters-title'),
	acl: [{
		checkpoint: 'system.packages.packages',
		op: READ
	}],

	render () {
		const filters = this.cardContext().get('filters');

		const form = formView({
			name: 'store-search-package',
			model: filters,
			preventSave: true,
			loader: false,
			fields: [{
				key: 'query',
				type: 'text',
				label: t('store.filters.search-for'),
				customize (view, formView) {
					view.ui.input.on('keyup', () => {
						view.inputChanged();
					});

					view.ui.input.focus();

					formView.on(CHANGE_SHOW_ALL, (model, changedValue) => {
						if (changedValue) {
							view.model.set('value', '');
						}
					});
				}
			}, {
				key: 'show-all',
				type: 'checkbox',
				label: t('store.filters.show-all'),
				customize (view, formView) {

					formView.on('change:query', (model, changedValue) => {
						if (changedValue.length > 0) {
							view.setChecked(false);
						}
						view.model.set('value', false);
					});

					formView.on('change:update-available', reset(view));
					formView.on('change:installed-only', reset(view));
				}
			}, {
				key: 'installed-only',
				type: 'checkbox',
				label: t('store.filters.installed-only'),
				customize (view, formView) {
					formView.on('change:update-available', (model, changedValue) => {
						if (changedValue) {
							view.setChecked(true);
							view.model.set('value', true);
						}
					});

					formView.on(CHANGE_SHOW_ALL, reset(view));
				}
			}, {
				key: 'update-available',
				type: 'checkbox',
				label: t('store.filters.update-available'),
				customize (view, formView) {
					formView.on(CHANGE_SHOW_ALL, reset(view));

					formView.on('change:installed-only', (model, changedValue) => {
						if (!changedValue) {
							view.setChecked(false);
							view.model.set('value', false);
						}
					});
				}
			}]
		});

		this.$el.append(form.$el);
	}
});

