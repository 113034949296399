import TileView from 'tile-view';
import Vue from 'vue';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import can from 'service/acl/acl-can';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Link categories'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.LINKS,
		op: READ
	}],

	cardData: () => ['linkCategories'],

	loaded: ({ tile }) => {
		tile.vm = new Vue({
			el: tile.el,
			data: {
				allowEdit: can.edit(SYSTEM.SETTINGS.LINKS),
				allowDelete: can.delete(SYSTEM.SETTINGS.LINKS),
				categories: tile.linkCategories,
				maxSize: 64
			},

			methods: {
				deleteComponent (category) {
					this.categories.splice(this.categories.indexOf(category), 1);
				},

				onSave: ({ model, data }) => {
					model.set('name', data.name);
					return model.save().then(() => {
						cwalert.success(t('Success'));
					}).fail(() => {
						cwalert.error(t('Error'));
					});
				},

				onDelete: ({ model, data }) =>
					confirmation({
						title: t('Remove item'),
						icon: 'category',
						warning: true,
						message: t('Are you sure you want to remove category {category}?', {
							category: data.name
						})
					}).then(() => model.destroy().then(() => {
						cwalert.success(t('Category has been deleted'));
					}, () => {
						cwalert.error(t('Error'));
					}))
			},

			template:
				`<div>
					<p v-if="!categories.length">${t('Link categories list is empty')}</p>
					<ul>
						<li
							v-for="category in categories.models"
							:key="category.getId()"
							v-on:deleteComponent="deleteComponent(category)"
						>
							<inline-edit
								:model="category"
								:onSave="onSave"
								:onDelete="onDelete"
								:maxSize="maxSize"
								:allowEdit="allowEdit"
								:allowDelete="allowDelete"
							/>
						</li>
					</ul>
				</div>`
		});

		tile.$el.html(tile.vm);
	}
});
