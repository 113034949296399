'use strict';

import t from 'translate';

const card = {
	title: t('Add a clinician'),
	name: 'clinician-add',
	url: 'clinician/add'
};

export { card as AddClinician };
