import appBar from 'app-bar';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('Edit role set'),
	name: 'edit-role-set',
	url: 'role-set/:roleSetId',

	ctrl: ({ populate, close }, { roleSetId }) =>  {
		if (can.delete(SYSTEM.SETTINGS.TREATMENT_ROLES)) {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'delete',
				title: t('Delete role set'),
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Remove role set'),
						message: t('Are you sure you want to delete this role set?')
					}).done(() => {
						store.dispatch('roleSets/deleteRoleSet', roleSetId).then(
							() => {
								cwalert.success(t('Role set has been deleted'));
								close({ openCard: 'role-sets' });
							},
							(res) => {
								cwalert.error(
									t(`roleSets.error.${res.responseJSON.exception.msg}`)
								);
							}
						);
					});
				}
			});
		}

		populate();
	}
};

export { card as editRoleset };
