/**
 * @class Repository
 */
export default {
	/**
	 * Get all system event logs
	 *
	 * @method getSystemSettings
	 * @return {Object} {{#crossLink "SystemSettings"}}{{/crossLink}}
	 * @example
	 *        Repository.getSystemSettings();
	 */
	getSystemSettings: function () {
		return this.SystemSettings.getAll();
	}
};

