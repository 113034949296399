import store from 'store';

// based on url params, initiate with respondent, treatment or none
export default ({ populate }, { respondentId, treatmentId }) => {
	if (respondentId) {
		return store.dispatch('respondent/init');

	} else if (treatmentId) {
		return store.dispatch('treatment/init');
	}

	store.dispatch('treatment/resetId');
	store.dispatch('respondent/resetId');
	populate();
};
