import { render, staticRenderFns } from "./mini-create-assessment-tile.vue?vue&type=template&id=cdc5a670&"
import script from "./mini-create-assessment-tile.vue?vue&type=script&lang=js&"
export * from "./mini-create-assessment-tile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/hdd1/gitlab-runner/builds/e07c1ccc/1/frontend/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cdc5a670')) {
      api.createRecord('cdc5a670', component.options)
    } else {
      api.reload('cdc5a670', component.options)
    }
    module.hot.accept("./mini-create-assessment-tile.vue?vue&type=template&id=cdc5a670&", function () {
      api.rerender('cdc5a670', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/scripts/modules/assessment/tiles/mini-create-assessment/mini-create-assessment-tile.vue"
export default component.exports