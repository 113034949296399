import TileView from 'tile-view';
import t from 'translate';
import TileListTile from './tile-list-tile';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('Tiles on card'),
	instant: true,
	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],

	vueComponent: () => ({ TileListTile })
});
