import { GET, POST, PUT, DELETE } from 'service/ajax/ajax';
import urls from './urls';

/**
 * @class Repository
 */
export default {
	/**
	 * Fetch adapters.
	 *
	 * @function fetchAdapters
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.fetchAdapters();
	 */
	fetchAdapters: () => GET({ url: urls.fetchAdapters.url, headers: { Accept: 'application/json' } }),

	/**
	 * Fetch adapter instances.
	 *
	 * @function fetchAdapterInstances
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.fetchAdapterInstances();
	 */
	fetchAdapterInstances: () => GET({ url: urls.fetchAdapterInstances.url, headers: { Accept: 'application/json' } }),

	/**
	 * Create adapter instance.
	 *
	 * @function createAdapterInstance
	 * @param {object} data - New adapter instance data.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.createAdapterInstance(data);
	 */
	createAdapterInstance: (data) => POST({ url: urls.createAdapterInstance.url, data, headers: { Accept: 'application/json' } }),

	/**
	 * Delete adapter instance.
	 *
	 * @function deleteAdapterInstance
	 * @param {number} id - Adapter instance id.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.deleteAdapterInstance(id);
	 */
	deleteAdapterInstance: (id) => DELETE({ url: urls.deleteAdapterInstance.url(id), headers: { Accept: 'application/json' } }),

	/**
	 * Fetch adapter instance capability.
	 *
	 * @function fetchAdapterInstanceCapability
	 * @param {object} obj - Request configuration.
	 * @param {number, string} obj.capabilityId - Adapter instance capability ID.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.fetchAdapterInstanceCapability({ capabilityId });
	 */
	fetchAdapterInstanceCapability: ({ capabilityId }) =>
		GET({ url: urls.fetchAdapterInstanceCapability.url(capabilityId), headers: { Accept: 'application/json' } }),

	/**
	 * Update adapter instance capability.
	 *
	 * @function updateAdapterInstanceCapability
	 * @param {object} obj - Request configuration.
	 * @param {number, string} obj.capabilityId - Adapter instance capability ID.
	 * @param {string} obj.data - Payload.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.updateAdapterInstanceCapability({ capabilityId, data });
	 */
	updateAdapterInstanceCapability: ({ capabilityId, data }) =>
		PUT({ url: urls.updateAdapterInstanceCapability.url(capabilityId), data, headers: { Accept: 'application/json' } }),

	/**
	 * Fetch adapter instance setting.
	 *
	 * @function fetchAdapterInstanceSetting
	 * @param {object} obj - Request configuration.
	 * @param {number, string} obj.settingId - Adapter instance setting ID.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.fetchAdapterInstanceSetting({ settingId });
	 */
	fetchAdapterInstanceSetting: ({ settingId }) =>
		GET({ url: urls.fetchAdapterInstanceSetting.url(settingId), headers: { Accept: 'application/json' } }),

	/**
	 * Update adapter instance setting.
	 *
	 * @function updateAdapterInstanceSetting
	 * @param {object} obj - Request configuration.
	 * @param {number, string} obj.settingId - Adapter instance setting ID.
	 * @param {string} obj.data - Payload.
	 * @returns {object} - Promise.
	 * @example
	 *        Repository.updateAdapterInstanceSetting({ settingId, data });
	 */
	updateAdapterInstanceSetting: ({ settingId, data }) =>
		PUT({ url: urls.updateAdapterInstanceSetting.url(settingId), data, headers: { Accept: 'application/json' } })
};
