<template>
	<form class="consent-information" @submit.prevent="restoreOldVersion">
		<checkbox-input
			v-model="consent.submittableByAdministrator"
			:disabled="true"
			class-prefix="consent-information"
			input-class="checkbox-input"
			label="consents.allowAnswerClinician"
		/>

		<select-input
			v-model="version"
			:options="versions"
			item-label="label"
			label="consents.versions"
			:placeholder="t('consents.selectVersion')"
			:searchable="false"
			:clearable="false"
			:disabled="!canEdit"
		/>

		<submit-button :can-submit="!!version" label="consents.restore" />

		<warning-overlay v-if="previewing">
			<p class="warning">
				{{t('consents.showingPreviousVersion')}}
			</p>
		</warning-overlay>
	</form>
</template>

<script>
import cardUrl from 'cwcardurl';
import t from 'service/lang/translate';
import { assign, get, map } from 'lodash';
import datetime from 'service/datetime/datetime';
import { clinicianName } from 'service/display-name/display-name';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

export default {
	title: t('Consent information'),
	actions: ({ $store }) => {
		const consentId = $store.getters.urlParams.consentId;
		return [
			['consent/setConsentVersion', consentId],
			['consent/initConsent', consentId]
		];
	},
	data () {
		return {
			currentVersion: null,
			restore: false,
			consentId: get(this.$store.getters.urlParams, 'consentId'),
			canEdit: can.edit(SYSTEM.SETTINGS.CONSENTS)
		};
	},
	computed: {
		version: {
			get: ({ currentVersion }) => currentVersion,
			set (newVal) {
				this.currentVersion = newVal;

				const versionNumber = get(newVal, 'id', null);
				const consentId = this.consentId;

				this.setDataLoading();
				this.$store.dispatch(
					'consent/consentVersion',
					{ consentId, versionNumber }
				).then(() => {
					this.setDataLoaded();
				});
			}
		},
		previewing: ({ consent, version }) => consent.lastVersionNumber > +get(version, 'version'),

		consent: ({ $store }) => $store.getters['consent/consent'],

		versions: ({ $store, versionLabel }) => map(
			$store.getters['consent/versions'],
			(version) => assign(version, { label: versionLabel(version) })
		)
	},
	methods: {
		versionLabel (version) {
			return [
				t('consents.versionNumber', { version: version.version }),
				', ',
				version.publishedAt ?
					datetime(version.publishedAt)
						.setTimezone(this.$store.getters.userTimezone)
						.toMoment()
						.format(t('date.formats.dateTime')) :
					t('Empty'),
				' : ',
				version.publishedBy ? clinicianName(version.publishedBy) : t('Empty')
			].join('');
		},

		restoreOldVersion () {
			const consentId = this.consentId;
			const versionNumber = this.version.id;
			this.setDataLoading();

			this.$store.dispatch(
				'consent/restoreConsent',
				{ consentId, versionNumber }
			).then(() => {
				this.setDataLoaded();
				this.closeCard({
					go: cardUrl.buildUrl('edit-consent', { consentId })
				});
			});
		}
	}
};

</script>
