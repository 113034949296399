import t from 'translate';

export default {
	template: {
		dataExportTemplateName: t('Export template name'),
		dataExportTemplateFileFormat: t('File format'),
		dataExportTemplateIsPublic: t('Public'),
		dataExportTemplateIsAvailableApi: t('Available in API'),
		dataExportTemplateAllowOverride: t('Allow Override'),
		dataExportTemplateIsSubmittedOnly: t('Only submitted assessments'),
		dataExportTemplateFilterDateRange: t('Date range'),
		dataExportTemplateFilterDateRangeStart: t('From'),
		dataExportTemplateFilterDateRangeStop: t('To'),
		languageId: t('Template language')
	},
	column: {
		dataExportTemplateColumnAttributeType: t('Type'),
		dataExportTemplateColumnName: t('Column name'),
		dataExportTemplateColumnLabel: t('Column label'),
		fkDataExportTemplateColumnAssessmentId: t('Assessment'),
		fkDataExportTemplateColumnContextId: t('Context'),
		dataExportTemplateColumnAssessmentOrder: t('Assessment order')
	}
};
