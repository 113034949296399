import { assign } from 'lodash';
import { DELETE, GET, PUT } from 'service/ajax/ajax';
import urls from './urls';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all ExportTemplates.
	 *
	 * @function getExportTemplates
	 * @returns {Object} - Export Templates Collection.
	 * @example
	 *        Repository.getExportTemplates();
	 */
	getExportTemplates () {
		return this.ExportTemplates.getAll();
	},

	/**
	 * Get all ExportTemplates.
	 *
	 * @function getReadyExportTemplates
	 * @returns {Object} - Export Templates Collection.
	 * @example
	 *        Repository.getReadyExportTemplates();
	 */
	getReadyExportTemplates () {
		return this.ExportTemplates.getReady();
	},

	/**
	 * Get ExportTemplate by id.
	 *
	 * @function getExportTemplate
	 * @param {number} id - Export Template ID.
	 * @returns {Object}  - Export Templates Model.
	 * @example
	 *        Repository.getExportTemplate(2);
	 */
	getExportTemplate (id) {
		return this.ExportTemplates.getById(id);
	},

	/**
	 * Get all AssessmentFields.
	 *
	 * @function getAssessmentFields
	 * @returns {Object} - Assessment Fields Collection.
	 * @example
	 *        Repository.getAssesmentFields();
	 */
	getAssessmentFields () {
		return this.AssessmentFields.getAll();
	},

	/**
	 * Get all RespondentFields.
	 *
	 * @function getRespondentFields
	 * @returns {Object} - Respondent Fields Collection.
	 * @example
	 *        Repository.getRespondentFields();
	 */
	getRespondentFields () {
		return this.RespondentFields.getAll();
	},

	/**
	 * Get all TreatmentFields.
	 *
	 * @function getTreatmentFields
	 * @returns {Object} - Treatment Fields Collection.
	 * @example
	 *        Repository.getAssesmentFields();
	 */
	getTreatmentFields () {
		return this.TreatmentFields.getAll();
	},

	/**
	 * Get getColumnTreatmentTypeAttributes from ExportTemplateColumn.
	 *
	 * @function getByColumn
	 * @param {number} columnId - Export Template Column ID.
	 * @returns {Object} - Attributes Collection.
	 * @example
	 *        Repository.getColumnTreatmentTypeAttributes();
	 */
	getColumnTreatmentTypeAttributes (columnId) {
		return this.ColumnTreatmentTypeAttributes.getByColumn(columnId);
	},

	/**
	 * Get all ExportInstances.
	 *
	 * @function getExportInstances
	 * @returns {Object} - Export Instances Collection.
	 * @example
	 *        Repository.getExportInstances();
	 */
	getExportInstances () {
		return this.ExportInstances.getAll();
	},

	/**
	 * Add a single ExportTemplateClinicianGroup.
	 *
	 * @function addExportTemplateClinicianGroup
	 * @param {Object} data - Parameters.
	 * @param {string} data.id - Id of a group.
	 * @param {string} data.templateId - Id of a template.
	 * @example
	 *        repository.addExportTemplateClinicianGroup({id: 1, templateId: 1});
	 */
	addExportTemplateClinicianGroup: (data) => PUT({
		url: urls.clinicianUpdate(data)
	}),

	/**
	 * Delete a single ExportTemplateClinicianGroup.
	 *
	 * @function deleteExportTemplateClinicianGroup
	 * @param {Object} data - Parameters.
	 * @param {string} data.id - Id of a group.
	 * @param {string} data.templateId - Id of a template.
	 * @example
	 *        repository.deleteExportTemplateClinicianGroup({id: 1, templateId: 1});
	 */
	deleteExportTemplateClinicianGroup: (data) => DELETE({
		url: urls.clinicianUpdate(data)
	}),

	/**
	 * Get ExportTemplateClinicianGroups by templateId.
	 *
	 * @function getExportTemplateClinicianGroups
	 * @param {number|string} templateId - Template id.
	 * @returns {Object} - TreamtnetType.
	 * @example
	 *        repository.getExportTemplateClinicianGroups(1);
	 */
	getExportTemplateClinicianGroups: (templateId) => {
		const url = urls.clinicianGroups(templateId);
		const request = GET({ url });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Add a single ExportTemplateTreatmentType.
	 *
	 * @function addExportTemplateTreatmentType
	 * @param {Object} data - Parameters.
	 * @param {string} data.id - Id of a type.
	 * @param {string} data.templateId - Id of a template.
	 * @example
	 *        repository.addExportTemplateTreatmentType({id: 1, templateId: 1});
	 */
	addExportTemplateTreatmentType: (data) => PUT({
		url: urls.treatmentUpdate(data)
	}),

	/**
	 * Delete a single ExportTemplateTreatmentType.
	 *
	 * @function deleteExportTemplateTreatmentType
	 * @param {Object} data - Parameters.
	 * @param {string} data.id - Id of a type.
	 * @param {string} data.templateId - Id of a template.
	 * @example
	 *        repository.deleteExportTemplateTreatmentType({id: 1, templateId: 1});
	 */
	deleteExportTemplateTreatmentType: (data) => DELETE({
		url: urls.treatmentUpdate(data)
	}),

	/**
	 * Get ExportTemplateTreatmentTypeOptions by templateId.
	 *
	 * @function getExportTemplateTreatmentTypeOptions
	 * @param {number|string} templateId - Template id.
	 * @returns {Object} - TreatmentType.
	 * @example
	 *        repository.getExportTemplateTreatmentTypeOptions(1);
	 */
	getExportTemplateTreatmentTypeOptions: (templateId) => {
		const url = urls.availableTreatmentTypes(templateId);
		const request = GET({ url });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Get ExportTemplateTreatmentTypes by templateId.
	 *
	 * @function getExportTemplateTreatmentTypes
	 * @param {number|string} templateId - Template id.
	 * @returns {Object} - TreatmentType.
	 * @example
	 *        repository.getExportTemplateTreatmentTypes(1);
	 */
	getExportTemplateTreatmentTypes: (templateId) => {
		const url = urls.treatmentTypes(templateId);
		const request = GET({ url });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Lock a single export template.
	 *
	 * @function lockExportTemplate
	 * @param {string} templateId - Id of a template.
	 * @example
	 *        repository.lockExportTemplate(1);
	 */
	lockExportTemplate: (templateId) => PUT({
		url: urls.lockTemplate(templateId)
	}),

	/**
	 * Unlock a single export template.
	 *
	 * @function unlockExportTemplate
	 * @param {string} templateId - Id of a template.
	 * @example
	 *        repository.unlockExportTemplate(1);
	 */
	unlockExportTemplate: (templateId) => PUT({
		url: urls.unlockTemplate(templateId)
	}),

	/**
	 * Get export template.
	 *
	 * @function exportTemplate
	 * @param {string} templateId - Id of a template.
	 * @example
	 *        repository.exportTemplate(1);
	 */
	exportTemplate: (templateId) => GET({
		url: urls.exportTemplate(templateId)
	}),

	/**
	 * Save export template.
	 *
	 * @function saveExportTemplate
	 * @param {object} params - TemplateId with data.
	 * @example
	 *        repository.saveExportTemplate({ templateId: 1, data });
	 */
	saveExportTemplate: ({ templateId, data }) => PUT({
		url: urls.exportTemplate(templateId),
		data
	}),

	/**
	 * Get single export template data.
	 *
	 * @function singleExportTemplate
	 * @param {string} templateId - Id of a template.
	 * @example
	 *        repository.singleExportTemplate(1);
	 */
	singleExportTemplate: (templateId) => GET({
		url: urls.templateData(templateId)
	}),

	/**
	 * Update a single export template's data.
	 *
	 * @function updateExportTemplateData
	 * @param {object} params - TemplateId with data.
	 * @example
	 *        repository.updateExportTemplateData({ templateId: 1, data });
	 */
	updateExportTemplateData: ({ templateId, data }) => PUT({
		url: urls.templateData(templateId),
		data
	}),

	/**
	 * Add a new consent option.
	 *
	 * @function addExportTemplateConsentOption
	 * @param {object} params - Consent id and consent option id.
	 * @example
	 *        repository.addExportTemplateConsentOption({ id: 1, optionId: 1 });
	 */
	addExportTemplateConsentOption: (params) => PUT({
		url: urls.consentOption(params)
	}),

	/**
	 * Delete a single consent option.
	 *
	 * @function deleteExportTemplateConsentOption
	 * @param {object} params - Consent id and consent option id.
	 * @example
	 *        repository.deleteExportTemplateConsentOption({ id: 1, optionId: 1 });
	 */
	deleteExportTemplateConsentOption: (params) => DELETE({
		url: urls.consentOption(params)
	}),

	/**
	 * Get consent options for a single export template.
	 *
	 * @function exportTemplateConsents
	 * @param {string} templateId - Id of a template.
	 * @example
	 *        repository.exportTemplateConsents(1);
	 */
	exportTemplateConsents: (templateId) => GET({
		url: urls.consentOptions(templateId)
	})
};
