export default {
	props: {
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		className: ({ name }) => `roles-dialog-invalid-${name}`
	},
	template: `
		<div :class="className">
			<p class="roles-dialog__invalid">
				<slot>{{$t('This field is mandatory')}}</slot>
			</p>
		</div>`
};
