import AttachmentsButton from 'components/attachments-button/attachments-button';
import appBar from 'app-bar';
import t from 'translate';
import repository from 'repository';
import { when } from 'jquery';

const card = {
	name: 'respondent-assessment-runtime',
	url: 'assessment/:id/open',
	title: t('assessment'),

	controller (id) {
		const assessmentInstance = repository.assessmentInstance(id);
		when(
			import(
				/* webpackChunkName: "cp-respondent-ar-instance" */
				`../../entities/assessment-runtime-instance`
			),
			assessmentInstance
		).then((module) => {
			const AssessmentContentModel = module.default;
			const content = new AssessmentContentModel({ assessmentInstanceId: id });

			content.fetch().then(() => {
				this.cardContext().set({
					'assessment-model': content,
					'assessmentInstanceId': id,
					assessmentInstance
				});
				this.populate();

				if (content.get('instructionFiles').length) {
					const attachmentsButton = new AttachmentsButton({
						model: content,
						respondentId: assessmentInstance.getRespondentId()
					});
					appBar.add(card.name, attachmentsButton, { order: 1 });
				}
			});
		});
	}
};

export default card;
