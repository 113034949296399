<template>
	<div class="card-list">
		<section class="card-list__filters">
			<div class="select-portal">
				<label class="select-portal__label-container">
					<p v-translate class="select-portal__label-text">{{label.portal}}</p>
					<select v-model="selected" class="select-portal__select">
						<option value="assessment">{{t(label.assessmentPortal)}}</option>
						<option value="atomic">{{t(label.atomicPortal)}}</option>
						<option value="clinician">{{t(label.clinicianPortal)}}</option>
						<option value="respondent">{{t(label.respondentPortal)}}</option>
					</select>
				</label>
			</div>

			<div class="filter">
				<label class="filter__label-container">
					<p v-translate class="filter__label-text">{{label.filter}}</p>
					<input v-model="filter" type="search" class="filter__search" />
				</label>
			</div>

			<div class="filter">
				<select-input
					v-model="selectedFeatures"
					:options="features"
					:multiple="true"
					label="Features"
				/>
			</div>

			<div class="custom-only">
				<div class="custom-only__label-container--checkbox">
					<p v-translate class="custom-only__label-text">
						{{label.customizedOnly}}
					</p>
					<label>
						<input v-model="customOnly" type="checkbox" class="custom-only__checkbox" />
						<span v-translate class="custom-only__value-description">Yes</span>
					</label>
				</div>
			</div>
		</section>
		<section class="card-list__list-container">
			<table class="card-list__list">
				<thead>
					<tr>
						<th
							v-translate
							class="card-list__list-title card-list__list-title--name"
							scope="col"
						>{{label.cardName}}</th>
						<th
							v-translate
							class="card-list__list-title card-list__list-title--variants"
							scope="col"
						>{{label.features}}</th>
						<th
							v-translate
							class="card-list__list-title card-list__list-title--variants"
							scope="col"
						>{{label.variants}}</th>
						<th
							v-translate
							class="card-list__list-title card-list__list-title--customized"
							scope="col"
						>{{label.customized}}</th>
						<th
							v-translate
							class="card-list__list-title card-list__list-title--enabled"
							scope="col"
						>{{label.enabled}}</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(card, i) in current"
						:key="i"
						class="card-list__list-row"
						tabindex="0"
						@click.stop="editCard(card)"
						@keyup.prevent.enter.space="editCard(card)"
					>
						<td	class="card-list__list-item card-list__list-item--name">
							<p class="card-list__list-value">
								{{card.name}}
							</p>
						</td>

						<td class="card-list__list-item card-list__list-item--features">
							<p v-translate class="card-list__list-label">
								{{label.features}}
							</p>
							<p class="card-list__list-value">
								<span
									v-for="feature in card.features"
									:key="feature"
								>{{feature}}</span>
							</p>
						</td>

						<td class="card-list__list-item card-list__list-item--variants">
							<p v-translate class="card-list__list-label">
								{{label.variants}}
							</p>
							<p class="card-list__list-value">
								{{card.variants}}
							</p>
						</td>

						<td class="card-list__list-item card-list__list-item--customized">
							<p v-translate class="card-list__list-label">
								{{label.customized}}
							</p>
							<p
								v-translate
								class="card-list__list-value"
							>{{card.customized ? 'Yes' : 'No'}}</p>
						</td>

						<td class="card-list__list-item card-list__list-item--enabled">
							<p v-translate class="card-list__list-label">
								{{label.enabled}}
							</p>
							<p
								v-translate
								class="card-list__list-value"
							>{{card.disabled ? 'No' : 'Yes'}}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
	</div>
</template>

<script>
import cardUrl from 'cwcardurl';
import t from 'service/lang/translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	title: t('cardConfig.cardList.title'),
	instant: true,
	acl: [{
		checkpoint: SYSTEM.SETTINGS.SETTINGS,
		op: READ
	}],
	data: () => ({
		label: {
			assessmentPortal: 'Assessment Portal',
			atomicPortal: 'Atomic Portal',
			clinicianPortal: 'Clinician Portal',
			customizedOnly: 'View customized only',
			filter: 'Filter',
			portal: 'Portal',
			respondentPortal: 'Respondent Portal',
			cardName: 'Card name',
			variants: 'Additional variants',
			customized: 'Customized',
			enabled: 'Enabled',
			features: 'Features'
		}
	}),

	computed: {
		features: ({ $store }) => $store.getters['cards/features'],
		current: ({ $store }) => $store.getters['cards/current'],
		customOnly: {
			get: ({ $store }) => $store.state.cards.customOnly,
			set (bool) {
				this.$store.dispatch('cards/setCustomOnly', bool);
			}
		},
		selected: {
			get: ({ $store }) => $store.state.cards.selected,
			set (type) {
				this.$store.dispatch('cards/setType', type);
			}
		},
		filter: {
			get: ({ $store }) => $store.state.cards.filter,
			set (filter) {
				this.$store.dispatch('cards/setFilter', filter);
			}
		},
		selectedFeatures: {
			get: ({ $store }) => $store.getters['cards/selectedFeatures'],
			set (features) {
				this.$store.dispatch('cards/setSelectedFeatures', features);
			}
		}
	},

	methods: {
		editCard (card) {
			cardUrl.openCard('edit-card', {
				type: this.selected,
				cardName: card.name
			});
		}
	}
};
</script>
