import MultipleEntities from '../shared/multiple-entities';
import ContentPackage from './content-package';
import urls from './urls';

const ContentPackages = MultipleEntities.extend({
	model: ContentPackage,
	urls,
	eventNamespace: 'information-package',

	getAll () {
		return this.retrieve('listAll');
	},

	getAvailable () {
		return this.retrieve('listAvailable');
	},

	getNotUnpublished () {
		return this.filter((model) => !model.isUnpublished());
	},

	search (params) {
		return this.retrieve('search', params);
	}
});

export default new ContentPackages();
