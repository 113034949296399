import TileView from 'tile-view';
import t from 'translate';
import can from 'acl-can';
export default TileView.extend({
	title: t('Clinician groups that have access to this information package'),
	acl: {
		checkpoint: 'system.settings.informationpackages',
		op: 'READ'
	},

	actions: (tile) => [
		['contentPackage/init', tile.cardContext().get('id')],
		['contentPackage/initClinicianGroups', tile.cardContext().get('id')],
		['clinicianGroups/initAll']
	],

	Vue: () => ({
		data: () => ({
			canRemove: can.edit('system.settings.informationpackages'),
			labels: {
				empty: t(`There are no clinician groups selected at this moment The information package will be available to all clinician groups`),
				name: t('Clinician group'),
				removeItem: t('Remove clinician group'),
				removeQuestion: t('Are you sure you wish to remove clinician group?'),
				removeSuccess: t('Clinician group removed successfully'),
				selectItem: t('- select clinician group to add -'),
				addSuccess: t('Clinician group added')
			},
			getters: {
				allItems: 'clinicianGroups/all',
				items: 'contentPackage/clinicianGroups'
			},
			actions: {
				removeItem: 'contentPackage/removeClinicianGroup',
				addItem: 'contentPackage/addClinicianGroup'
			}
		}),
		template: `
			<assign-items 
				:can-remove="canRemove"
				:labels="labels"
				:getters="getters"
				:actions="actions"
			/>
		`
	})
});

