import { param } from 'jquery';

const BASE_URL = 'backend-commons/information-package/instance/';

export default {
	single: {
		url: BASE_URL
	},

	listAll: {
		url: BASE_URL
	},

	listByRespondent: {
		url: (respondentId) => `${BASE_URL}?respondent=${respondentId}`
	},

	listActiveByRespondent: {
		url: (respondentId) => `${BASE_URL}?respondent=${respondentId}&active=1`
	},

	listByTreatment: {
		url: (treatmentId) => `${BASE_URL}?treatment=${treatmentId}`
	},

	listActiveByTreatment: {
		url: (treatmentId) => `${BASE_URL}?treatment=${treatmentId}&active=1`
	},

	singleElement: {
		url () {
			return `${BASE_URL}${this.get('instance').id}/element/`;
		}
	},

	listElementInstances: {
		url: (id) => `${BASE_URL}${id}/element`
	},

	complete: {
		url: (id) => `${BASE_URL}${id}/mark-as-completed/`
	},

	exercisesWithRoles: {
		url: (params) => `${BASE_URL}element/?filter[type]=EXERCISE&${param(params)}`
	},

	cpInstance: (id) => `${BASE_URL}${id}`
};
