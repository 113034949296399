import can from 'acl-can';
import change from '../../helpers/change';
import cwalert from 'service/cwalert';
import enabled from '../../helpers/enabled';
import enterprises from '../../dialogs/enterprises';
import onSuccessHandler from '../../helpers/on-success-handler';
import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import store from 'store';
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { assign, map, pick } from 'lodash';

const SELF_NAME = 'enterprise';

const removalCfg = {
	action: 'dataIntegration/enterprises/delete',
	question: 'dataIntegration.enterprise.actions.delete',
	title: 'dataIntegration.enterprise.actions.remove'
};

export default SearchTileView.extend({
	title: t('dataIntegration.enterprise.plural'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.ENTERPRISE,
		op: READ
	}],
	searchFn (params) {

		const result = store.dispatch(
			'dataIntegration/enterprises/search',
			assign({ name: SELF_NAME }, params)
		);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},

	fields: ({ tile }) => [{
		key: 'enabled',
		type: 'single-select',
		label: t('Enabled'),
		values: map(enabled, (type) => assign(type, { label: type.name })),

		onChange: (value) => change({ field: 'enabled', tile, value })
	}],

	tableCfg ({ tile }) {
		const className = 'enterprises-list';
		const modalCfg = {
			acl: SYSTEM.SETTINGS.ENTERPRISE,
			action: 'enterprises/update',
			edit: true,
			item: {},
			refresh: tile.runQuery,
			success: t('dataIntegration.enterprise.messages.updated'),
			title: 'dataIntegration.enterprise.actions.update',
			type: 'enterprise'
		};
		const config = {
			store: {
				items: () => store.getters['dataIntegration/enterprises/list'],
				count: 'dataIntegration/enterprises/count',
				mutation: 'dataIntegration/enterprises/setList',
				total: 'dataIntegration/enterprises/total'
			},
			click: (model) => {
				if (can.read(SYSTEM.SETTINGS.ENTERPRISE)) {
					modalCfg.item = { ...pick(model, [
						'id',
						'enabled',
						'enterpriseId',
						'secret',
						'code',
						'name'
					]) };
					enterprises(modalCfg);
				} else {
					cwalert.error(t('dataIntegration.enterprise.messages.noAccess'));
				}
			},
			columns: [{
				label: t('dataIntegration.enterprise.fields.id'),
				key: 'enterpriseId',
				css: `${className}__enterprise-id`
			}, {
				label: t('dataIntegration.enterprise.fields.secret'),
				key: 'secret',
				css: `${className}__enterprise-secret`
			}, {
				label: t('dataIntegration.enterprise.fields.name'),
				key: 'name',
				css: `${className}__enterprise-name`
			}, {
				label: t('dataIntegration.enterprise.fields.code'),
				key: 'code',
				css: `${className}__enterprise-external`
			}, {
				label: t('dataIntegration.enterprise.fields.enabled'),
				key: 'enabled',
				css: `${className}__enterprise-enabled`,
				render: (enabledBool) => enabledBool ? t('yes') : t('no')
			}]
		};

		if (can.remove(SYSTEM.SETTINGS.ENTERPRISE)) {
			config.columns.push({
				label: t('Delete'),
				render () {
					searchTileDelete({
						data: removalCfg,
						item: this.item,
						onSuccess: onSuccessHandler({ tile, type: 'enterprise' }),
						td: this.td,
						tile,
						onError: () => {
							cwalert.error(t('dataIntegration.enterprise.messages.inUse'));
						}
					});
				}
			});
		}
		return config;
	}
});
