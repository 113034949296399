import reports from './system-reports/tile';
import flags from './system-reports-flags/tile';
import inactiveRespondents from './system-reports-inactive-respondents/tile';
import overdueAssessments from './system-reports-overdue-assessments/tile';
import unansweredMessages from './system-reports-unanswered-messages/tile';

export default {
	'system-reports': reports,
	'system-reports-flags': flags,
	'system-reports-inactive-respondents': inactiveRespondents,
	'system-reports-overdue-assessments': overdueAssessments,
	'system-reports-unanswered-messages': unansweredMessages
};
