import TileView from 'tile-view';
import store from 'store';
import { get, some } from 'lodash';
import miniCreateAssessmentTile from './mini-create-assessment-tile';

const updateTile = ({ enable, tile }) => {
	enable ? tile.enable() : tile.disable();
};

const loadContent = ({ enable = false, treatmentTypeRoleId, tile }) => {
	store.getters['deniedClinicians/userDeniedByRole'] && updateTile({ enable, tile });

	if (treatmentTypeRoleId) {
		const found = some(store.getters['treatment/assignedRoles'], { treatmentTypeRoleId });
		const foundBoolean = enable ? found : !found;
		foundBoolean && updateTile({ enable, tile });
	}
};

export default TileView.extend({
	actions: ['treatment/init', 'deniedClinicians/init'],
	acl: [{
		checkpoint: 'administrator.respondents.assessmentplans',
		op: 'ADD'
	}, {
		checkpoint: 'administrator.respondents.assessmentcontent',
		op: 'READ'
	}],
	loaded: ({ tile }) => {
		const treatmentTypeRoleId = get(tile.config(), 'roleId');
		store.dispatch('deniedClinicians/setTreatmentTypeRoleId', treatmentTypeRoleId);
		loadContent({ treatmentTypeRoleId, tile });

		store.subscribe((mutation) => {
			if (mutation.type === 'treatment/deniedClinicians/add') {
				loadContent({ treatmentTypeRoleId, tile });
			} else if (mutation.type === 'treatment/deniedClinicians/remove') {
				store.dispatch('treatment/treatmentRoles', store.getters.treatment.id).then(() => {
					loadContent({ enable: true, treatmentTypeRoleId, tile });
				});
			}
		});
	},
	vueComponent: () => ({ miniCreateAssessmentTile })
});
