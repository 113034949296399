import $ from 'jquery';
import { isObject, isString } from 'lodash';
import { View } from 'backbone';
import acl from 'acl';
import icon from 'service/icon';
import cardUrl from 'cwcardurl';
import canAccess from 'core/engine/card/services/can-access';

export default (params) => {
	const title = params.title || params.caption;
	const iconName = params.icon;
	const attr = params.attr || {};
	const cp = params.acl;
	const card = params.card;

	const ItemView = View.extend({
		className: 'shortcut__container',
		initialize () {
			if (!card) {
				return;
			}
			this.setTargetCardName();
			this.isAccessible() && this.render();
		},

		render () {
			const href = cardUrl.buildUrl(card);

			const $link = $('<a />')
				.addClass('shortcut__action')
				.attr('href', href)
				.attr(attr);

			const $icon = $(icon(iconName, {
				title, type: params.type
			}))
				.addClass('shortcut__icon');

			const $caption = $('<span />')
				.addClass('shortcut__caption')
				.html(title);

			$link.append($icon, $caption);

			this.$el.empty().append($link);
		},

		isAccessible () {
			this.accessible = true;

			if (cp && !acl.checkAccess(cp)) {
				this.$el.hide();
				this.accessible = false;

			} else if (!card || !canAccess(this.targetCardName)) {
				this.accessible = false;
			}
			return this.accessible;
		},

		setTargetCardName () {
			if (isString(card)) {
				this.targetCardName = card;

			} else if (isObject(card)) {
				this.targetCardName = card.name;
			}
		}
	});

	return new ItemView();
};

