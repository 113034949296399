export const reservedKeys = ['clinicianAttributeId'];

export const components = {
	clinicianAttributeId: {
		beforeCreate () {
			this.$store.dispatch('clinicianAttributes/initDefinitions');
		},
		props: {
			objKey: {
				type: String,
				required: true
			},
			objVal: {
				type: [String, Number],
				required: true,
				default: ''
			},
			onChange: Function
		},

		template: `
		<div class="tile-config__clinician-attribute-container">
			<select class="tile-config__clinician-attribute-select" v-model="attribute">
				<option v-for="definition in definitions" :value="definition.id">
					{{definition.label}}
				</option>
			</select>
		</div>`,

		computed: {
			definitions () {
				return this.$store.getters['clinicianAttributes/definitions'];
			},

			attribute: {
				get () {
					return this.objVal;
				},

				set (value) {
					this.onChange({ key: this.objKey, value });
				}
			}
		},

		methods: {

		}
	}
};
