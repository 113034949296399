import repository from 'repository';
import cardUrl from 'cwcardurl';

const card = {
	name: 'content-package-instance-element',
	url: 'respondent/:respondent/content-package/:instance/element/:id',

	ctrl: ({ cardData }, { respondent, instance, id }) => {
		const types = {
			ASSESSMENT: (model) => cardUrl.buildUrl('assessment-properties', {
				assessmentInstanceId: model.get('assessmentInstance').id
			}),
			EXERCISE: (model) => cardUrl.buildUrl('content-package-instance-exercise', {
				respondent,
				instance: model.get('instance').id,
				id: model.getId()
			}),
			PAGE: (model) => cardUrl.buildUrl('page', { id: model.get('page').id }),
			MEDIA: (model) => cardUrl.buildUrl('view-media', { id: model.get('uploadedMedia').id }),
			LINK: (model) => cardUrl.buildUrl('view-link', { id: model.get('link').id })
		};

		const element = repository.ContentPackageInstanceElements.getById(id, {
			instance: { id: instance }
		});

		element.then(() => {
			const redirectTo = types[element.get('type')](element);
			cardData.trigger('card:close', { go: redirectTo });
		});
	}
};

export { card as InstanceElement };
