import { Model, Collection } from 'backbone';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import CWTable from 'components/table/table';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import { clinicianName } from 'service/display-name/display-name';
import { find } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Clinicians assigned to this respondent'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.RESPONDENTS.CLINICIAN_ASSIGNED
	}],

	tileData: ({ tile }) => ({
		limit: tile.config().limit || 10,
		respondentClinicians: repository.getRespondentCliniciansByRespondentId(
			store.getters['respondent/respondentId']
		),
		clinicians: new Collection()
	}),

	init: ({ tile }) => {
		const respondentId = store.getters['respondent/respondentId'];
		tile.listenTo(tile.clinicians, 'add', (clinician) => {
			tile.respondentClinicians.create({
				respondentId,
				administratorId: `${clinician.get('administratorId')}`
			}, {
				// `Backbone.Collection.create` doesn't return a promise, yeeaaahhh!
				success: () => {
					cwalert.success(t('Clinician {clinicianName} has been ' +
						'assigned to respondent {respondentName}', {
						clinicianName: clinicianName(clinician.toJSON()),
						respondentName: store.state.respondent.model.displayName()
					}));
				}
			});
		});

		tile.listenTo(tile.clinicians, 'remove', (clinician) => {
			tile.respondentClinicians.findWhere({
				administratorId: `${clinician.get('administratorId')}`
			}).destroy().then(() => {
				cwalert.success(t('Clinician {clinicianName} has been ' +
					'revoked from respondent {respondentName}', {
					clinicianName: clinicianName(clinician.toJSON()),
					respondentName: store.state.respondent.model.displayName()
				}));
			});
		});
	},

	loaded: ({ data, tile }) => {
		data.clinicians.reset(data.respondentClinicians.toJSON());
		const dummyModel = new Model();
		const className = 'search-results-list__entry clinicians-' +
			'list__entry clinicians-list__clinician-';
		const mainClassName = 'search-results-list__entry--main';

		tile.form = formView({
			name: 'respondent-clinicians',
			model: dummyModel,
			preventSave: true,
			loader: false,
			disabled: !can.add(CLINICIAN.RESPONDENTS.CLINICIAN_ASSIGNED),

			fields: [{
				key: 'clinicianId',
				type: 'search',
				label: t('Clinician'),
				limit: data.limit,
				provideResults: (query) => repository.searchClinicians({
					search: query,
					administratorAccountEnabled: true,
					start: 0,
					limit: data.limit
				}),
				placeholder: t('Type clinician name'),
				itemLabel: (clinician) => clinicianName(clinician.toJSON()),
				onSelectItem: (clinician, view) => {
					let assigned;
					const clinicianId = clinician.get('administratorId');

					if (data.clinicians) {
						assigned = find(data.clinicians.models, (model) =>
							model.get('administratorId') === clinicianId);
					}

					if (assigned) {
						cwalert.notice(t('Clinician {clinicianName} is ' +
							'already assigned to respondent {respondentName}', {
							clinicianName: clinicianName(clinician.toJSON()),
							respondentName: store.state.respondent.model.displayName()
						}));

					} else {
						data.clinicians.add(clinician);
					}
					view.clear();
				}
			}]
		});

		const tableCfg = {
			parent: tile.el,
			collection: data.clinicians,
			emptyListMessage: t('The respondent has no direct clinicians assigned'),

			columns: [{
				label: t('Full name'),
				css: `${className}full-name ${mainClassName}`,
				render () {
					return clinicianName(this.item.toJSON());
				}
			}],
			actions: {}
		};

		if (can.remove(CLINICIAN.RESPONDENTS.CLINICIAN_ASSIGNED)) {
			tableCfg.actions['delete'] = (clinician, callbacks) => {
				confirmation({
					title: t('Remove'),
					icon: 'unlink',
					message: t('Are you sure you wish to withdraw ' +
						'clinician {clinicianName} from respondent {respondentName}?', {
						clinicianName: clinicianName(clinician.toJSON()),
						respondentName: store.state.respondent.model.displayName()
					}),
					warning: true
				}).then(() => {
					data.clinicians.remove(clinician);
				}, () => {
					callbacks.cancel();
				});
			};
		}

		tile.$el.append(tile.form.$el);
		tile.table = new CWTable(tableCfg);
	}
});

