import SearchTileView from 'core/engine/tile/views/search-tile-view';
import $ from 'jquery';
import t from 'translate';
import repository, { Clinicians } from 'repository';
import cardUrl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import icon from 'service/icon';
import cwalert from 'cwalert';
import categoryTag from 'components/category-tag/category-tag';
import { EMPTY_CHAR } from 'lib/chars';
import { assign, compact, map, isObject } from 'lodash';
import can from 'service/acl/acl-can';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

const isValidUrl = (string) => {
	try {
		return isObject(new URL(string));
	} catch (_) {
		return false;
	}
};

export default SearchTileView.extend({
	title: t('Links'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.LINKS,
		op: READ
	}],
	cardData: () => ['categories'],
	appEvents: {
		'link.create' () {
			this.runQuery();
		}
	},

	searchFn: (...args) => repository.searchLinks(...args),

	fields () {
		const ids = (value) => compact(map(value, (item, key) => item && key));

		return [{
			key: 'category',
			type: 'multi-select',
			label: t('Category'),

			values: map(this.categories.toJSON(), (category) => assign({}, category, {
				id: category.id,
				label: category.name
			})),

			onChange: (value) => {
				this.viewModel.set('category', ids(value));
				this.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry link-list__entry link-list__';
		const mainClassName = 'search-results-list__entry--main';

		return {

			click: (link) => {
				cardUrl.openCard('edit-link', { id: link.getId() });
			},
			columns: [{
				key: 'name',
				label: t('Name'),
				className: `${mainClassName} ${className}name`,
				render: (value, { item, td }) => {
					const viewIcon = icon('external-link', {
						title: t('Open link'),
						small: true,
						className: 'link-list__name-icon'
					});

					const $edit = $('<a />')
						.attr({
							target: item.get('openInNewWindow') ? '_blank' : '_self',
							href: item.get('url')
						})
						.text(item.get('name'))
						.append(viewIcon);

					$edit.on('click', (e) => {
						e.stopPropagation();
					});

					$(td).html($edit).append(categoryTag(item.get('category')));
				}
			}, {
				key: 'isValid',
				label: t('Valid'),
				className: `${className}is-valid`,
				render: (value, { item, td }) => {
					const viewIcon = icon('check', {
						small: true,
						className: 'link-list__icon'
					});

					$(td).append(isValidUrl(item.get('url')) ? viewIcon : EMPTY_CHAR);
				}
			}, {
				label: t('Modified by'),
				className: `${className}modified-by`,
				render: (value, { item }) => {
					const clinician = new Clinicians.model(item.get('modifiedBy'));
					return clinician.displayName ? clinician.displayName() : t('Not specified');
				}
			}],
			rowsClickable: can.edit(SYSTEM.SETTINGS.LINKS),
			deleteFilter: () => can.remove(SYSTEM.SETTINGS.LINKS),
			actions: {
				delete: (link, actions) => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete link'),
						message: t('Delete link "{link}"?', { link: link.get('name') })
					}).then(() => {
						const linkName = link.get('name');
						link.destroy().then(() => {
							this.results.remove(link);
							cwalert.success(t(
								'Link "{linkName}" successfully removed',
								{ linkName }
							));

							// re-run the search, hacky way to notify filters that results have
							// changed
							this.runQuery();
						}, () => {
							cwalert.error(t('Unable to remove link "{linkName}"', { linkName }));
						});

					}, () => {
						actions.cancel();
					});

					return false;
				}
			}
		};
	}
});
