import appBar from 'app-bar';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	title: t('Relation types'),
	name: 'system-settings-relation-types',
	url: 'system/relation-types',

	ctrl: ({ populate }) =>  {
		can.add(SYSTEM.SETTINGS.RELATION_TYPES) && appBar.addButton({
			sourceCard: card.name,
			icon: 'add',
			title: t('Add relation type'),
			click: () => {
				cardUrl.openCard('relation-type-edit', {
					relationTypeId: 0
				});
			}
		});

		populate();
	}
};

export { card as relationTypeList };
