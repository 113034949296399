var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "content-field",
          { attrs: { label: _vm.$t("editAdapterInstanceCapability.code") } },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.adapterCapability.systemAdapterCapabilityCode) +
                "\n\t\t"
            )
          ]
        ),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceCapability.name")
          },
          model: {
            value: _vm.form.name,
            callback: function($$v) {
              _vm.$set(_vm.form, "name", $$v)
            },
            expression: "form.name"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceCapability.description"),
            multiline: ""
          },
          model: {
            value: _vm.form.description,
            callback: function($$v) {
              _vm.$set(_vm.form, "description", $$v)
            },
            expression: "form.description"
          }
        }),
        _vm._v(" "),
        _c("checkbox-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceCapability.enabled")
          },
          model: {
            value: _vm.form.enabled,
            callback: function($$v) {
              _vm.$set(_vm.form, "enabled", $$v)
            },
            expression: "form.enabled"
          }
        }),
        _vm._v(" "),
        _c("checkbox-input", {
          attrs: {
            translate: false,
            label: _vm.$t(
              "editAdapterInstanceCapability.defaultRespondentEnabled"
            )
          },
          model: {
            value: _vm.form.defaultRespondentEnabled,
            callback: function($$v) {
              _vm.$set(_vm.form, "defaultRespondentEnabled", $$v)
            },
            expression: "form.defaultRespondentEnabled"
          }
        }),
        _vm._v(" "),
        _c("checkbox-input", {
          attrs: {
            translate: false,
            label: _vm.$t("editAdapterInstanceCapability.allowRespondentToggle")
          },
          model: {
            value: _vm.form.respondentToggleEnabled,
            callback: function($$v) {
              _vm.$set(_vm.form, "respondentToggleEnabled", $$v)
            },
            expression: "form.respondentToggleEnabled"
          }
        }),
        _vm._v(" "),
        _c(
          "button-group",
          { attrs: { "align-right": "" } },
          [
            _c("submit-button", {
              attrs: {
                "can-submit": !_vm.processing,
                label: _vm.$t("general.buttons.save")
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }