import appBar from 'app-bar';
import t from 'translate';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import appContext from 'app-context';
import repository from 'repository';
import confirmation from 'components/confirmation/confirmation';
import store from 'store';
import storeModuleBlueprint from 'store/components/tree-view/module-blueprint';
import validationTypes from '../helpers/validation-types';
import { assign, includes, noop } from 'lodash';
import { EXPORT_TEMPLATE } from 'store/export-template/export-template';
import cardControls from 'core/engine/card/services/card-controls';

let unsubscribe;

const SELF_NAME = 'export-template';
const STORE_MODULE_PATH = ['components', 'tree-view', SELF_NAME];
const mutationsToSubscribe = [
	'exportTemplate/setClinicianGroups',
	'exportTemplate/setTreatmentTypesErrors',
	'components/tree-view/export-template/setElementsWarning',
	'components/tree-view/export-template/setInvalidElements'
];

const editTemplateButton = ({ exportTemplate, sourceCard }) => {
	appBar.addCardLink({
		sourceCard,
		card: 'edit-export-template',
		click: () => {
			unsubscribe();
			cardUrl.openCard('edit-export-template', {
				exportTemplateId: exportTemplate.get('dataExportTemplateId')
			});
		},
		query: {
			exportTemplateId: exportTemplate.get('dataExportTemplateId')
		},
		title: t('Edit export template content'),
		icon: 'tree-view'
	});
};

const downloadButton = ({ exportTemplate, sourceCard }) => {
	if (store.getters['components/tree-view/export-template/saveAllowed']) {
		appBar.addButton({
			sourceCard,
			title: t('Download sample'),
			icon: 'download',
			url: exportTemplate.getDownloadUrl().toString()
		});
	}
};

const setDelete = ({ exportTemplate, sourceCard }) => {
	if (can.remove('system.settings.export-templates')) {
		appBar.addButton({
			sourceCard,
			title: t('Delete template'),
			icon: 'delete',
			click () {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Delete template'),
					message: t('Are you sure?')
				}).then(() => {
					exportTemplate.destroy().then(() => {
						cwalert.success(t('general-list.RemoveSuccessfully'));
						appContext.trigger('export-template.delete');

						if (cardControls.getCurrent() === sourceCard) {
							cardControls.closeCard();
						}
					}, () => {
						cwalert.failure(t('general-list.RemoveError'));
					});
				});

				return false;
			}
		});
	}
};

const setButtons = ({ exportTemplate, sourceCard }) => {
	appBar.clear(SELF_NAME);

	const availableGroups = store.getters[EXPORT_TEMPLATE.CLINICIAN_GROUPS].length;
	const canEdit = can.edit('system.settings.export-templates');
	const locked = store.getters['exportTemplateGui/locked'];
	const treatmentTypeError = store.getters[EXPORT_TEMPLATE.TREATMENT_TYPE_WARNING];

	if (availableGroups && canEdit && !locked && !treatmentTypeError) {
		editTemplateButton({ exportTemplate, sourceCard });
		downloadButton({ exportTemplate, sourceCard });
	}
	setDelete({ exportTemplate, sourceCard });
};

const setTemplateData = ({ exportTemplate, sourceCard }) => {
	const treeData = store.dispatch('exportTemplateGui/init', {
		templateId: exportTemplate.getId(),
		userId: store.getters.userId
	});
	treeData.then(() => {
		const items = assign({}, store.getters['exportTemplateGui/data']);

		// inner store may be already registered so only if there are no localItems
		if (!store.getters[`${STORE_MODULE_PATH.join('/')}/localItems`]) {
			store.registerModule(STORE_MODULE_PATH, storeModuleBlueprint());
		}
		store.dispatch('components/tree-view/export-template/init', {
			items,
			types: validationTypes,
			updateFn: noop
		}).then(() => {
			setButtons({ exportTemplate, sourceCard });
		});
	});
};

const refreshButtons = ({ exportTemplate, sourceCard }) => {
	// list of assessments must be ready before tree data send to the inner store
	// but it cannot be requested as a default (there will be an error if no clinician
	// group is assigned to a template) - otherwise tree items' availability may be incorrect
	store.dispatch('exportTemplate/getAvailableAssessments', exportTemplate.getId())
		.then(() => {
			setTemplateData({ exportTemplate, sourceCard });
		});
};

const card = {
	title: t('export-template-management.editExportTemplate'),
	name: 'export-template',
	url: 'export-template/:id',

	ctrl: ({ cardData }, { id }) => {
		const exportTemplate = repository.getExportTemplate(id);
		const contexts = repository.getContexts();
		const assessmentFields = repository.getAssessmentFields();
		const respondentFields = repository.getRespondentFields();
		const treatmentFields = repository.getTreatmentFields();
		const respondentAttributes = repository.getRespondentAttributeDefinitions();

		return Promise.all([
			store.dispatch('exportTemplate/getClinicianGroups', id),
			store.dispatch('languages/init'),
			exportTemplate,
			contexts,
			assessmentFields,
			respondentFields,
			treatmentFields,
			respondentAttributes
		]).then(() => {
			cardData.set({
				clinicianGroups: store.getters[EXPORT_TEMPLATE.CLINICIAN_GROUPS],
				Contexts: contexts,
				AssessmentFields: assessmentFields,
				RespondentFields: respondentFields,
				TreatmentFields: treatmentFields,
				RespondentAttributes: respondentAttributes,
				exportTemplate
			});

			card.loaded(exportTemplate);
		});
	},

	loaded (exportTemplate) {
		if (store.getters[EXPORT_TEMPLATE.CLINICIAN_GROUPS].length) {
			refreshButtons({ exportTemplate, sourceCard: card.name });
		}
		unsubscribe = store.subscribe((mutation) => {
			const here = store.getters.cardName === card.name;

			if (includes(mutationsToSubscribe, mutation.type) && here) {
				setButtons({ exportTemplate, sourceCard: card.name });
			}
		});
	}
};

export { card as ExportTemplate };
