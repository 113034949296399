import { forEach, get, size } from 'lodash';
import t from 'translate';
import cwalert from 'service/cwalert';

export const handleErrors = (errors) => {
	let errorMessage = t('Error');

	if (errors && size(errors)) {
		forEach(errors, (error) => {
			errorMessage = t(`backendDictionary.errors.${error}`);
			cwalert.error(errorMessage);
		});
	}

	return errorMessage;
};

export default (model) => {
	const errors = get(model, 'responseJSON.errors', {});

	return handleErrors(errors);
};
