var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "clinician-label__content" },
    [
      _c("ul", { staticClass: "clinician-label__container" }, [
        _c(
          "section",
          { staticClass: "clinician-label__element-container important" },
          [
            _c("li", { staticClass: "clinician-label__label-container" }, [
              _c(
                "p",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "clinician-label__value"
                },
                [_vm._v("\n\t\t\t\t\tClinician\n\t\t\t\t")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "clinician-label__value-container" }, [
              _c("p", { staticClass: "clinician-label__value" }, [
                _c("a", { attrs: { href: _vm.link } }, [
                  _vm._v(_vm._s(_vm.displayName))
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "clinician-label__element-container important" },
          [
            _c("li", { staticClass: "clinician-label__label-container" }, [
              _c(
                "p",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "clinician-label__value"
                },
                [_vm._v("\n\t\t\t\t\tUsername\n\t\t\t\t")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "clinician-label__value-container" }, [
              _c("p", { staticClass: "clinician-label__value" }, [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.clinician.username) + "\n\t\t\t\t"
                )
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("timezone-warning", {
        attrs: { "person-type": "clinician", clinician: _vm.clinician }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }