import t from 'translate';
import mixin from './_mixin';
import { assessmentValidation } from './_validation-mixin';
import { mapActions, mapGetters } from 'vuex';
import { get, transform, assign, isEqual } from 'lodash';

export default () => ({
	name: 'assessment-question',
	mixins: [mixin, assessmentValidation],

	data: () => ({
		defaultType: t('Assessment question'),
		formData: {
			assessmentQuestionCode: null
		},

		ids: {
			identity: 'code'
		}
	}),

	computed: {
		...mapGetters({
			assessments: 'exportTemplate/sortedAssessments',
			contexts: 'contexts/sortedWithEmpty',
			questions: 'assessment/questions'
		}),

		columnLabel () {
			return this.formData.assessment ? this.formData.assessment.assessmentCode : '';
		},

		identifierLabel () {
			return this.formData.assessmentQuestionCode ? this.formData.assessmentQuestionCode : '';
		}

	},

	methods: {
		...mapActions('assessment', ['getQuestions', 'resetQuestions']),
		...mapActions('exportTemplateGui', ['processedFields']),

		initUniqueFields () {
			// retransform values to entities and set them to proper data models
			assign(this.formData, transform(this.item.fields, (memo, value, key) =>
				assign(memo, {
					assessmentQuestionCode: {
						assessmentQuestionCode: value
					}
				}[key])));
		}
	},

	watch: {
		'formData.assessment' (assessment) {
			if (get(assessment, 'assessmentId')) {
				this.loading = true;
				this.resetQuestions();
				this.getQuestions({
					assessmentId: assessment.assessmentId
				}).then(() => {
					const assessmentQuestion = get(this.item.fields, 'identity');

					if (!this.formOptions.identity && assessmentQuestion) {
						assign(this.formData, this.valueToEntity({
							entities: this.questions,
							key: 'identity',
							value: assessmentQuestion
						}));
					}
					this.loading = false;
				});
			}

			this.formData.identity = null;
		},

		'formData.identity' (identity) {
			// question code that was manually modified should stay until identity is changed
			!isEqual(
				get(identity, 'assessmentQuestionId'),
				get(this.item.fields, 'identity')
			) && (this.formData.assessmentQuestionCode = get(identity, 'code'));
		}
	},

	beforeDestroy () {
		this.resetQuestions();
	}
});
