import appBar from 'app-bar';
import t from 'translate';

const card = {
	name: 'system-settings-respondent-attributes',
	title: t('System Settings - Respondent attributes'),
	url: 'system/respondent/attributes',

	controller () {
		this.populate();

		appBar.addCardLink(card.name, {
			card: 'system-settings-respondent-attributes-add',
			title: t('Add respondent attribute'),
			icon: 'add'
		});
	}
};

export { card as respondentAttributes };
