import urls from './urls';
import appContext from 'app-context';
import types from './element-types';
import ContentPackages from './content-packages';
import Elements from './elements';
import Categories from './categories';
import { DELETE, GET, PUT } from 'service/ajax/ajax';
import { map } from 'lodash';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all ContentPackages.
	 *
	 * @function getContentPackages
	 * @returns {Object} - ContentPackages Collection.
	 * @example
	 *        repository.getContentPackages();
	 */
	getContentPackages: () => ContentPackages.getAll(),

	/**
	 * @function searchContentPackages.
	 *
	 * @param {Object} params - Search parameters.
	 * @returns {Object} - ContentPackages Collection.
	 * @example
	 * 		repository.searchContentPackages({ start: 0, limit: 10, search: 'content' });
	 */
	searchContentPackages: (params) => ContentPackages.search(params),

	/**
	 * Get all ContentPackages that are available via clinician groups.
	 *
	 * @function availableContentPackages
	 * @returns {Object} - ContentPackages Collection.
	 * @example
	 *        repository.availableContentPackages();
	 */
	availableContentPackages: () => ContentPackages.getAvailable(),

	/**
	 * Mark content package as published.
	 *
	 * @param {Object} contentPackage  - Content Package.
	 * @returns {Object}               - Promise.
	 * @example
	 * 		const contentPackage = repository.getContentPackageById(18);
	 * 		repository.publishContentPackage(contentPackage);
	 */
	publishContentPackage: (contentPackage) => {
		contentPackage.set('status', contentPackage.STATUS_PUBLISHED);

		return PUT({ url: urls.publish.url(contentPackage.getId()) }).then(() => {
			appContext.trigger('information-package.__update', contentPackage);
		});
	},

	/**
	 * Get Content Package.
	 *
	 * @function getContentPackageById
	 * @param  {number} contentPackageId - Content Package ID.
	 * @returns {Object}                 - ContentPackage Model.
	 * @example
	 * 		repository.getContentPackageById(5);
	 */
	getContentPackageById: (contentPackageId) => ContentPackages.getById(contentPackageId),

	/**
	 * Get ContentPackageClinicianGroups by their ContentPackage id.
	 *
	 * @function contentPackageClinicianGroups
	 * @param {number} cpId - Content Package ID.
	 * @returns {Object}    - Promise.
	 * @example
	 *        repository.contentPackageClinicianGroups(2);
	 */
	contentPackageClinicianGroups: (cpId) => GET({ url: urls.clinicianGroups(cpId) }),

	/**
	 * Add a clinician group to content package.
	 *
	 * @function addClinicianGroupToContentPackage
	 * @param {Object} params                  - Parameters.
	 * @param {number} params.contentPackageId - Content Package ID.
	 * @param {number} params.clinicianGroupId - Clinician Group ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 * 		repository.addClinicianGroupToContentPackage({
	 * 			contentPackageId: 2,
	 * 			clinicianGroupId: 3
	 * 		});
	 */
	addClinicianGroupToContentPackage: ({ contentPackageId, clinicianGroupId }) => PUT({
		url: urls.clinicianGroup({ contentPackageId, clinicianGroupId })
	}),

	/**
	 * Remove a clinician group to content package.
	 *
	 * @function removeClinicianGroupFromContentPackage
	 * @param {Object} params                  - Parameters.
	 * @param {number} params.contentPackageId - Content Package ID.
	 * @param {number} params.clinicianGroupId - Clinician Group ID.
	 * @returns {Object}                       - Promise.
	 * @example
	 * 		repository.removeClinicianGroupFromContentPackage({
	 * 			contentPackageId: 2,
	 * 			clinicianGroupId: 3
	 * 		});
	 */
	removeClinicianGroupFromContentPackage: ({ contentPackageId, clinicianGroupId }) => DELETE({
		url: urls.clinicianGroup({ contentPackageId, clinicianGroupId })
	}),

	/**
	 * Get all ContentPackageElements.
	 *
	 * @function getContentPackageElements
	 * @param {number} contentPackageId - Content Package ID.
	 * @returns {Object} - ContentPackageElements Collection.
	 * @example
	 *        Repository.getContentPackageElements();
	 */
	getContentPackageElements: (contentPackageId) => Elements.getAll(contentPackageId),

	/**
	 * Get all available information package element types.
	 *
	 * @function getContentPackageElementTypes
	 * @returns {Object} - Types.
	 * @example
	 * 		repository.getContentPackageElementTypes();
	 */
	getContentPackageElementTypes: () => map(types, (value, key) => ({ [key]: value })),

	/**
	 * Get all content package categories.
	 *
	 * @function contentPackageCategories
	 * @returns {Object} - ContentPackageCategories Collection.
	 * @example
	 *        Repository.getContentPackageCategories();
	 */
	contentPackageCategories: () => Categories.getAll()
};

