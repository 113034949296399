var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.$t("menuComposer.variantCriteria.title")) +
            "\n\t"
        )
      ]),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          value: _vm.newCriterion,
          translate: false,
          label: _vm.$t("menuComposer.variantCriteria.addCriterion"),
          options: _vm.availableCriteria,
          disabled: !_vm.availableCriteria.length
        },
        on: { input: _vm.addCriterion }
      }),
      _vm._v(" "),
      _vm._l(_vm.selectedVariant.when, function(value, criterion) {
        return _c(
          "div",
          { key: criterion, staticClass: "sidebar-variant__criterion" },
          [
            _c(_vm.criterionComponent(criterion), {
              tag: "component",
              attrs: { criteria: _vm.currentCriteria },
              on: {
                update: _vm.updateCriteria,
                remove: function($event) {
                  return _vm.removeCriterion(criterion)
                }
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }