import decisionAnswer from './decision-instance-answer/tile';
import decisionInstanceInformation from './decision-instance-information';
import miniDecisions from './mini-respondent-decisions/tile';
import respondentDecisions from './respondent-decisions/tile';
import treatmentDecisions from './treatment-decisions/tile';
import decisionsTile from './decisions-list/tile';

export default {
	'decision-instance-information': decisionInstanceInformation,
	'decision-instance-answer': decisionAnswer,
	'mini-respondent-decisions': miniDecisions,
	'respondent-decisions': respondentDecisions,
	'treatment-decisions': treatmentDecisions,
	'decisions-list': decisionsTile
};
