import TileView from 'tile-view';
import t from 'translate';
import roleSetRolesTile from './role-set-roles-tile';

export default TileView.extend({
	title: t('Add roles to set'),
	actions: (tile) => {
		const roleSetId = tile.cardContext().get('roleSetId');
		return [
			['roleSetRoles/getSingleRoleSet', roleSetId],
			['roleSets/searchSingle', roleSetId],
			['roles/getAllRoles']
		];
	},
	vueComponent: () => ({ roleSetRolesTile })
});
