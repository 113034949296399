import TileView from 'tile-view';
import t from 'translate';
import { EXPORT_TEMPLATE } from 'store/export-template/export-template';

export default TileView.extend({
	title: t('Treatment types'),

	actions: (tile) => {
		const templateId = tile.cardContext().get('id');

		return [['exportTemplate/getTreatmentTypes', templateId]];
	},

	Vue: () => ({
		data: {
			fields: ['name'],
			labels: {
				addSuccess: t('Treatment type has been added'),
				empty: t('No treatment type assigned'),
				name: t('Treatment type'),
				removeItem: t('Remove treatment type'),
				removeQuestion: t('Are you sure you want to delete this treatment type'),
				removeSuccess: t('Treatment type has been deleted'),
				selectItem: t('Select treatment type or type a name'),
				selectLabel: t('Limit to treatment type'),
				warning: t('There are unavailable results inside the list')
			},
			getters: {
				allItems: 'exportTemplate/treatmentTypeOptions',
				items: 'exportTemplate/treatmentTypes'
			},
			actions: {
				removeItem: 'exportTemplate/deleteAndRefreshTreatmentType',
				addItem: 'exportTemplate/addAndRefreshTreatmentType'
			}
		},
		computed: {
			warning: ({ $store }) => $store.getters[EXPORT_TEMPLATE.TREATMENT_TYPE_WARNING]
		},
		template: `
			<assign-items
				:compare="true"
				:fields="fields"
				:labels="labels"
				:getters="getters"
				:actions="actions"
				:warning="warning">
				<template v-slot:warning>
					<p>{{ labels.warning }}</p>
				</template>
			</assign-items>
		`
	})
});
