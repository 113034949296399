import { assign } from 'lodash';
import TileView from 'tile-view';
import can from 'acl-can';
import t from 'translate';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import ClinicianAttributeValue from 'repo/clinicians/attribute-value';
import store from 'store';

export default TileView.extend({
	tagName: 'section',
	className: 'my-attributes__container',
	userType: 'clinician',
	title: t('clinician.my-attributes.title'),
	acl: [{
		op: 'read',
		checkpoint: 'system.administratorpreferences.attributes'
	}],

	cardData: () => ['model'],

	tileData: () => ({
		availableAttributes: repository.getClinicianAttributeDefinitions(),
		clinicianAttributes: repository.getClinicianAttributeValues(store.state.user.userId)
	}),

	loaded: ({ tile }) => {
		const attributes = tile.availableAttributes;
		const attributeValues = tile.clinicianAttributes;

		if (!attributes.length) {
			tile.$el.append(t('No available attributes'));
		}

		const formViews = [];

		// create new formView instance for each custom attribute
		attributes.each((attribute) => {
			let attributeValue = attributeValues.find((attributeVal) => {
				const attributeMetaId = attributeVal.get('fk_administrator_attribute_administrator_attribute_meta_id');
				return attributeMetaId === attribute.get('administratorAttributeMetaId');
			});
			const input = tile.getInputByAttributeType(attribute.get('administratorAttributeMetaType'));

			attributeValue = attributeValue || new ClinicianAttributeValue({
				'fk_administrator_attribute_administrator_id': +store.state.user.userId,
				'fk_administrator_attribute_administrator_attribute_meta_id': +attribute.get('administratorAttributeMetaId'),
				'fkAdministratorAttributeAdministratorAttributeMeta': attribute.toJSON()
			});

			const cfg = {
				classNames: ['no-fieldset-margin'],
				model: attributeValue,
				hideMandatory: true,
				saveIndicator: true,
				readonly: !can.edit('system.administratorpreferences.attributes'),
				fields: [{
					key: 'administratorAttributeValue',
					label: attribute.get('administratorAttributeMetaLabel'),
					mandatory: attribute.get('administratorAttributeMetaRequired'),
					readonly: !can.edit('system.administratorpreferences.attributes') || attribute.get('administratorAttributeMetaReadonly'),
					type: input.type
				}]
			};

			assign(cfg.fields[0], input.config);

			formViews.push(formView(cfg).$el);
		});

		tile.$el.append(formViews);
	},

	getInputByAttributeType: (type) => ({
		STRING: { type: 'text' },
		TEXTAREA: { type: 'textarea' },
		CHECKBOX: { type: 'checkbox' },
		NUMERIC: { type: 'number' },
		DATE: { type: 'date' },
		DROPDOWN: {
			type: 'select',
			config: {
				values: (attrVal) => {
					const meta = attrVal.get('fkAdministratorAttributeAdministratorAttributeMeta');
					const vals = meta.administratorAttributeMetaParameters.split(/;/);
					return vals;
				}
			}
		}
	})[type]
});
