var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.$emit("remove")
            }
          }
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.$t("menuComposer.variantCriteria.removeCriterion")) +
              " "
          ),
          _c("icon", { attrs: { name: "delete", small: "" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          translate: false,
          label: _vm.$t("Treatment types"),
          options: _vm.availableTreatmentTypes,
          "item-label": "name",
          multiple: ""
        },
        model: {
          value: _vm.selectedTreatmentTypes,
          callback: function($$v) {
            _vm.selectedTreatmentTypes = $$v
          },
          expression: "selectedTreatmentTypes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }