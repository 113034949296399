import can from 'acl-can';
import t from 'translate';
import appBar from 'app-bar';
import addPlanDialog from '../dialogs/plan-add';
import repository from 'repository';

const card = {
	title: t('Plan search'),
	name: 'search-plans',
	url: 'plan/search/?q=(:search)',

	ctrl: ({ cardConfig, cardData }) => {
		const triggers = repository.getPlanTriggers();

		can.add('system.workflows.workflows') && appBar.addButton(card.name, {
			title: t('Add plan'),
			icon: 'add',
			click: addPlanDialog
		});

		cardData.set({
			hideResults: cardConfig.startEmpty,
			triggers
		});

		return triggers;
	}
};

export { card as SearchPlans };
