import t from 'translate';
import eventHandlers from '../../respondent-management/shared/event-handlers-mixin';
import store from 'store';

const card = {
	name: 'respondent-plans',
	url: 'respondent/:respondentId/plans',
	title: t('Respondent Plans'),

	appEvents: {
		'respondent.delete respondent.lose-access' (respondent) {
			eventHandlers.closeCard({
				respondent,
				data: this.cardData,
				close: this.close,
				name: card.name
			});
		}
	},

	ctrl: (context, { respondentId }) => {
		store.dispatch('treatment/resetId');
		return store.dispatch('respondent/init', { respondentId });
	}
};

export { card as RespondentPlans };
