import Backbone from 'backbone';
import dom from 'cwdom';
import cardurl from 'cwcardurl';
import CWtable from 'components/table/table';
import t from 'translate';
import repository from 'repository';
import store from 'store';

export default Backbone.View.extend({
	className: '',

	title: t('system-manager.ExportTemplates'),
	acl: [{
		checkpoint: 'system.settings.export-templates',
		op: 'READ'
	}],

	appEvents: [
		'export-template.create',
		'export-template.delete',
		'export-template.update'
	],

	render () {
		this.setLoading();
		this.exportTemplates = repository.getExportTemplates();
		this.exportTemplates.done(this.loaded.bind(this)).always(this.setLoaded.bind(this));
	},

	loaded () {
		const tile = this;
		const models = this.exportTemplates.models;

		tile.table = new CWtable({
			parent: tile.el,

			items: models,
			emptyListMessage: t('No templates found'),
			item (item) {
				return item;
			},

			click (item) {
				cardurl.openCard('export-template', {
					id: item.id
				});
			},
			columns: {
				dataExportTemplateName: {
					label: t('export-template-management.table-headers.name'),
					render () {
						return this.item.get('dataExportTemplateName');
					}
				},

				dataExportTemplateIsPublic: {
					label: t('export-template-management.table-headers.IsPublic'),
					render () {
						const attrs = {
							type: 'checkbox',
							disabled: 'disabled'
						};

						if (this.item.get('dataExportTemplateIsPublic')) {
							attrs.checked = 'checked';
						}
						dom.createElement({
							tag: 'input',
							parent: this.td,
							attrs
						});
					}
				},
				dataExportTemplateIsAvailableApi: {
					label: t('export-template-management.table-headers.API'),
					render () {
						const attrs = {
							type: 'checkbox',
							disabled: 'disabled'
						};

						if (this.item.get('dataExportTemplateIsAvailableApi')) {
							attrs.checked = 'checked';
						}
						dom.createElement({
							tag: 'input',
							parent: this.td,
							attrs
						});
					}
				},
				dataExportTemplateAllowOverride: {
					label: t('export-template-management.table-headers.AllowOverride'),
					render () {
						const attrs = {
							type: 'checkbox',
							disabled: 'disabled'
						};

						if (this.item.get('dataExportTemplateAllowOverride')) {
							attrs.checked = 'checked';
						}
						dom.createElement({
							tag: 'input',
							parent: this.td,
							attrs
						});
					}
				},

				dataExportTemplateCreatedDate: {
					label: t('export-template-management.table-headers.createdDate'),
					render () {
						return this.item.getCreatedDate(true)
							.setTimezone(store.getters.userTimezone)
							.toMoment()
							.format('DD.MM.YYYY HH:mm');
					}
				}
			}
		});
	}
});

