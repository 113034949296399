import t from 'translate';
import store from 'store';

const card = {
	name: 'assessment-report',
	url: 'assessment/:assessmentInstanceId/report/:reportId/',
	title: t('assessment.report'),

	ctrl: ({ cardData }, { assessmentInstanceId, reportId }) =>
		store.dispatch('assessmentInstance/init', { assessmentInstanceId }).then(() => {
			cardData.set({ assessmentInstanceId, reportId });
		})
};

export { card as AssessmentReport };

