var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          invalid: _vm.vuelidate.item.srcRole.$error,
          label: "dataIntegration.roleMapping.fields.role",
          mandatory: true,
          options: _vm.roleAvailableOptions,
          placeholder: _vm.t("dataIntegration.roleMapping.actions.selectRole"),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.srcRole.$touch()
          }
        },
        model: {
          value: _vm.item.srcRole,
          callback: function($$v) {
            _vm.$set(_vm.item, "srcRole", $$v)
          },
          expression: "item.srcRole"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          invalid: _vm.vuelidate.item.dstRole.$error,
          label: "dataIntegration.roleMapping.fields.roleMapped",
          mandatory: true,
          options: _vm.roleOptions,
          placeholder: _vm.t(
            "dataIntegration.roleMapping.actions.selectRoleMapped"
          ),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.dstRole.$touch()
          }
        },
        model: {
          value: _vm.item.dstRole,
          callback: function($$v) {
            _vm.$set(_vm.item, "dstRole", $$v)
          },
          expression: "item.dstRole"
        }
      }),
      _vm._v(" "),
      _c("radio-input", {
        attrs: {
          "value-name": true,
          label: "dataIntegration.ruleSets.fields.allowed",
          "class-prefix": "data-integration"
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.access.$touch()
          }
        },
        model: {
          value: _vm.item.access,
          callback: function($$v) {
            _vm.$set(_vm.item, "access", $$v)
          },
          expression: "item.access"
        }
      }),
      _vm._v(" "),
      _c("radio-input", {
        attrs: {
          "value-name": false,
          label: "dataIntegration.dataMapping.fields.denied",
          "class-prefix": "data-integration"
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.access.$touch()
          }
        },
        model: {
          value: _vm.item.access,
          callback: function($$v) {
            _vm.$set(_vm.item, "access", $$v)
          },
          expression: "item.access"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }