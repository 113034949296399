import TileView from 'tile-view';
import t from 'translate';
import Table from 'components/table/table';
import cardUrl from 'cwcardurl';
import datetime from 'datetime';
import can from 'acl-can';
import store from 'store';

export default TileView.extend({
	title: t('Information package elements'),

	actions: ['contentPackageInstance/init'],

	loaded: ({ tile }) => {
		tile.elements = tile.cardContext().get('elements');
		const respondent = store.getters.respondent;
		tile.table = new Table({
			parent: tile.el,
			collection: tile.elements,
			click: (element) => {
				if (!can.read('system.settings.media') && element.get('type') === 'MEDIA') {
					// Do nothing. When backend starts to return media object, do something with it

				} else {
					cardUrl.openCard('content-package-instance-element', {
						respondent: respondent.respondentId,
						instance: element.get('instance').id,
						id: element.getId()
					});
				}
			},

			noClick: (element) => element.isAssessment() && !element.get('assessmentInstance'),

			columns: [{
				label: t('Alias'),
				render: (val, { item }) => item.get('elementVersion').alias
			}, {
				label: t('Type'),
				render: (val, { item }) => item.getTypeLabel()
			}, {
				label: t('State'),
				render: (val, { item }) => item.getStateLabel()
			}, {
				label: t('Completed'),
				render: (val, { item }) => {
					const completedAt = item.get('completedAt');

					if (completedAt) {
						return datetime(completedAt)
							.setTimezone(respondent.respondentTimezoneName)
							.toMoment()
							.format(t('date.formats.dateTime'));
					}
				}
			}]
		});
	}
});
