/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import Text from './Text';

export default function (options = {}) {
	const rendererOptions = options.rendererOptions ?
		options.rendererOptions :
		{};

	if (options.displayValue) {
		rendererOptions.displayValue = options.displayValue;
	}

	if (options.disableAutoSave) {
		rendererOptions.disableAutoSave = options.disableAutoSave;
	}

	if (options.noempty) {
		rendererOptions.noempty = options.noempty;
	}

	if (options.style) {
		rendererOptions.style = options.style;
	}

	this.createRenderer = function (attributeName, model, opts) {
		if (options.disableAutoSave === undefined) {
			rendererOptions.disableAutoSave = opts.disableAutoSave;
		}

		if (opts.readonly) {
			rendererOptions.readonly = opts.readonly;
		}

		if (opts.noempty) {
			rendererOptions.noempty = opts.noempty;
		}

		return new Text(attributeName, model, rendererOptions, opts.mode);
	};
}
