import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import ReasonTemplate from 'repo/flags/reason-template';

export default () => {
	const formConfig = {
		name: 'add-reason-template',
		model: new ReasonTemplate(),

		fields: [{
			key: 'name',
			label: t('Name'),
			type: 'text',
			mandatory: true
		}, {
			key: 'reasonOrder',
			label: t('Order'),
			type: 'number'
		}]
	};

	formViewDialog({
		formConfig,
		dialogConfig: { title: t('New reason template') }
	});
};

