import MultipleEntities from '../shared/multiple-entities';
import Clinician from './clinician-entity';
import urls from './urls';

/**
 * TreatmentClinicians Entity
 * @class TreatmentClinicians
 * @extends MultipleEntities
 */
const TreatmentCliniciansEntity = MultipleEntities.extend({
	model: Clinician,
	urls,
	eventNamespace: 'treatment-clinician',
	idName: 'administratorId',

	canAdd: function (model) {
		return +model.get('treatment') === +this.ownerId;
	},

	events: {
		'add': function (model) {
			// models stored on the server don't have `treatment`
			// attribute which is needed to deduct correct url for deletion
			model.set('treatment', +this.ownerId);
		}
	},

	/**
	 * Get all TreatmentClinicians
	 * @method getByTreatmentId
	 * @param {Integer|String} treatmentId
	 * @return {Object} new TreatmentClinicians Collection
	 * @example
	 *        Repository.TreatmentClinicians.getAll();
	 */
	getByTreatmentId: function (treatmentId) {
		return this.retrieve('listClinicians', treatmentId);
	}
});

export default new TreatmentCliniciansEntity();
