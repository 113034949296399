import Backbone from 'backbone';
import $ from 'jquery';
import Table from 'components/table/table';
import dom from 'cwdom';
import cwalert from 'cwalert';
import t from 'translate';
import cardurl from 'cwcardurl';
import confirmation from 'components/confirmation/confirmation';
import repository from 'repository';
import can from 'acl-can';
import { forEach, noop } from 'lodash';

export default Backbone.View.extend({

	title: t('system.exports.exportTemplates'),
	acl: [{
		checkpoint: 'system.export.templates',
		op: 'READ'
	}],
	appEvents: 'system-export-instance.add',

	render (params) {
		this.params = params;
		this.exportInstances = repository.getExportInstances();
		this.exportInstances.comparator = function (model) {
			return -model.get('dataExportInstanceId');
		};
		this.exportTemplates = repository.getReadyExportTemplates();
		this.setLoading();

		$.when(this.exportInstances, this.exportTemplates)
			.done(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	loaded () {
		this.$el.empty();
		this.exportsList = this.createList();

		this.exportInstances.sort();
		this.$el.append(this.exportsList.$el);
	},

	checkStatus (tile) {
		tile.queue = [];
		// eslint-disable-next-line lodash/prefer-lodash-method
		tile.exportInstances.filter((model) => {
			const status = model._get('Status');

			if (status === 'FINISHED' || status === 'FAILED') {
				return true;
			}
			tile.queue[model.getId()] = model;
		});

		let timer = 0;

		const loader = () => {
			let i;
			let j = 0;

			forEach(tile.queue, (entry = { fetch: noop }) => {

				if (entry.wait) {
					return;
				}

				// Max three models at any given time.
				i++;

				if (i >= 3) {
					return;
				}
				entry.wait = true;
				j++;
				entry.fetch({
					success: () => {
						const status = entry._get('Status');

						if (status === 'FINISHED' || status === 'FAILED') {
							delete tile.queue[entry.getId()];
						}
					},
					error: () => {
						delete tile.queue[entry.getId()];
					}
				});
			});

			if (j === 0) {
				forEach(tile.queue, (m) => {
					m.wait = false;
				});
			}

			if (!tile.queue.length) {
				window.clearInterval(timer);
			}
		};
		timer = window.setInterval(loader, 5000);
	},

	createList () {
		const inst = this;

		const actions = {};
		const canDeleteTemplate = can['delete']('system.export.templates');

		if (canDeleteTemplate) {
			actions['delete'] = function (item, cb) {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Delete export'),
					message: t('Are you sure you wish to delete this export?')
				}).done(() => {
					const mid = item.getId();

					item.destroy({
						success () {
							cwalert.success(t('general-list.RemoveSuccessfully'));
							delete inst.queue[mid];
						},
						error () {
							cwalert.failure(t('general-list.RemoveError'));
						}
					});
				}).fail(() => {
					cb.cancel();
				});
			};
		}

		this.checkStatus(this);

		return new (Backbone.View.extend({
			initialize () {
				this.listenTo(
					inst.exportInstances,
					'add remove change:dataExportInstanceStatus',
					this.render
				);
				this.render();
			},
			render () {
				this.$el.empty();

				this.table = new Table({
					parent: this.el,
					items: inst.exportInstances.models,

					click (item) {
						cardurl.openCard('system-exports-templates-details', {
							id: item.get('dataExportInstanceId')
						});
					},
					columns: {
						template: {
							label: t('export-template-management.table-headers.name'),
							render () {
								dom.alterElement(this.td, {
									content: [
										inst.exportTemplates.get(
											this.item.get('fkDataExportInstanceTemplateId')
										).getName()
									]
								});
							}
						},
						format: {
							label: t('general-list.Format'),
							render () {
								dom.alterElement(this.td, {
									content: [this.item._get('FileFormat')]
								});
							}
						},
						modifiedDate: {
							label: t('general-list.lastModifiedDate'),
							render () {
								dom.alterElement(this.td, {
									content: [this.item.getLastModifiedDate(true)]
								});
							}
						},

						status: {
							label: t('general-list.Status'),
							render () {
								dom.alterElement(this.td, {
									content: [this.item._get('Status')]
								});
								$(this.td).attr('data-status', this.item.getId());
							}
						},
						download: {
							label: t('general-list.Download'),
							render () {
								const url = this.item.getDownloadURL();

								const buttonDownload = $('<a>').html(
									t('general-list.Download')
								).addClass('cwbutton secondary')
									.attr('data-file-download', '')
									.on('click', () => {
										if ($(buttonDownload).hasClass('disabled')) {
											return false;
										}
										window.location = url;
									});

								if (this.item._get('Status') !== 'FINISHED') {
									buttonDownload.addClass('disabled')
										.attr('disabled', 'disabled');
								}
								dom.alterElement(this.td, {
									content: [buttonDownload[0]]
								});
							}
						}
					},

					actions

				});
			}
		}))();
	}
});

