import planAccess from './plan-access/tile';
import planRoleSets from './plan-role-sets';
import planSettings from './plan-settings/tile';
import simulator from './plan-simulator/tile';
import simulatorLog from './plan-simulator-log/tile';
import search from './search-plans/tile';

export default {
	'plan-access': planAccess,
	'plan-role-sets': planRoleSets,
	'plan-settings': planSettings,
	'plan-simulator': simulator,
	'plan-simulator-log': simulatorLog,
	'search-plans': search
};

