export default {
	allCheckpoints: {
		url: 'backend-commons/administrator/rest/access/all'
	},

	singleCheckpoint: {
		url: 'backend-commons/administrator/rest/access/'
	},

	allCheckpointGroups: {
		url: 'backend-commons/administrator/rest/accesscategory/all'
	},

	singleCheckpointGroup: {
		url: 'backend-commons/administrator/rest/accesscategory/'
	},

	singleRole: {
		url: 'backend-commons/administrator/rest/role/'
	},

	listRoles: {
		url: 'backend-commons/administrator/rest/role/all'
	},

	listActiveRoles: {
		url: 'backend-commons/administrator/rest/role/all?active-only=1'
	},

	checkpointInRole: {
		url: 'backend-commons/administrator/rest/role_has_access/'
	},

	checkpointsInRole: {
		url: (roleId) => `backend-commons/administrator/rest/role_has_access/by-role/${roleId}`
	},

	checkpointGroupInRole: {
		url: 'backend-commons/administrator/rest/role_has_accesscategory/'
	},

	checkpointGroupsInRole: {
		url: (roleId) => `backend-commons/administrator/rest/role_has_accesscategory/by-role/${roleId}`
	}
};
