var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-view",
      attrs: { novalidate: "", autocomplete: "off" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("module-form", {
        ref: "module",
        on: { validate: _vm.onValidate },
        model: {
          value: _vm.module,
          callback: function($$v) {
            _vm.module = $$v
          },
          expression: "module"
        }
      }),
      _vm._v(" "),
      _c(
        "button-group",
        [
          _c("submit-button", {
            attrs: {
              "can-submit": _vm.valid,
              label: _vm.$t("general.buttons.add")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }