import urlify from 'service/urlify/urlify';

const rolesUrl = 'backend-commons/role';
const roleSetsUrl = 'backend-commons/role-set';

const roleSetRole = ({ roleId, roleSetId }) => `${roleSetsUrl}/${roleSetId}/role/${roleId}`;
const roles = `${rolesUrl}/`;
const roleSets = `${roleSetsUrl}/`;
const rolesId = (id) => `${rolesUrl}/${id}`;
const roleSetsId = (id) => `${roleSetsUrl}/${id}`;
const searchRoles = (params) => {
	let type = '';

	if (params.type && params.type[0]) {
		type = `filter[type]=${params.type[0]}&`;
	}
	return `${rolesUrl}/?${type}${urlify(params)}`;
};
const searchRoleSets = (params) => `${roleSetsUrl}/?${urlify(params)}`;
const searchSingleRoleSet = (id) => `${roleSetsUrl}/${id}/role/`;
const roleByName = (name) => `${rolesUrl}/?name=${name}&start=0&limit=1`;
const rolesetByName = (name) => `${roleSetsUrl}/?name=${name}&start=0&limit=1`;

export {
	roleByName,
	rolesetByName,
	roles,
	roleSets,
	rolesId,
	roleSetsId,
	roleSetRole,
	searchRoles,
	searchRoleSets,
	searchSingleRoleSet
};
