<template>
	<form class="form-view" @submit.prevent="submit">
		<select-input
			v-if="show"
			v-model="formData.assessmentContext"
			:options="availableContexts"
			:placeholder="translate('Select context')"
			item-value="assessmentInstanceContextId"
			class-prefix="tree-edit"
			label="Set context for template"
			item-label="assessmentInstanceContextLabel"
		/>
		<select-input
			v-if="show"
			v-model="formData.assessmentOrder"
			:options="formOptions.assessmentOrder"
			:placeholder="translate('Select order')"
			class-prefix="tree-edit"
			label="Assessment order"
		/>
		<text-input
			v-model="formData.codeTemplate"
			class-prefix="tree-edit"
			label="Code string"
		/>
		<p
			v-if="invalidCode"
			v-translate
			class="invalid-msg tree-edit__invalid"
		>Code template invalid</p>
		<button-group>
			<submit-button
				label="Save"
				:can-submit="!invalidCode"
			/>
			<plain-button
				:on-click="reset"
				label="Reset"
			/>
		</button-group>
	</form>
</template>

<script>
import editMixin from './_edit-mixin';
import mixin from './_mixin';
import { mapGetters } from 'vuex';
import { assign, transform } from 'lodash';

const DEFAULT_CODE_TEMPLATE = '#ItemID#_#Assessment#_#Context#';

const DEFAULT_CONFIG = {
	assessmentContext: null,
	assessmentOrder: 'first',
	codeTemplate: DEFAULT_CODE_TEMPLATE,
	identity: null
};

export default {
	name: 'ExportTemplate',
	mixins: [editMixin, mixin],

	data: () => ({
		formData: { ...DEFAULT_CONFIG },
		show: true
	}),

	computed: {
		...mapGetters({ contexts: 'contexts/sortedWithEmpty' })
	},

	methods: {
		init () {
			// retransform values to entities and set them to proper data models
			assign(this.formData, transform(this.item.fields, (memo, value, key) =>
				assign(memo, {
					assessmentOrder: this.valueToEntity({
						entities: this.formOptions.assessmentOrder,
						key,
						value
					}),
					assessmentContext: this.valueToEntity({
						entities: this.contexts,
						key,
						value
					}),
					codeTemplate: {
						codeTemplate: value
					}
				}[key])));
		},

		reset () {
			this.show = false;
			this.formData = { ...DEFAULT_CONFIG };
			setTimeout(() => this.show = true, 0);
		},

		submit () {
			const values = this.entitiesToValues();
			this.$emit('save', {
				item: this.item,
				fields: {
					assessmentContext: values.assessmentContext,
					assessmentOrder: values.assessmentOrder,
					codeTemplate: this.formData.codeTemplate,
					contextLabel: this.context(this.formData)
				}
			});
		},

		updateCode (e) {
			if (!e) {
				this.formData.codeTemplate = DEFAULT_CODE_TEMPLATE;
			}
		}
	}
};
</script>
