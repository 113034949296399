var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.canAdd("data_integration_rules")
      ? _c("section", { staticClass: "data-integration__add-container" }, [
          _c(
            "button",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass:
                "submit-button__button submit-button__button--submit",
              attrs: {
                disabled: !_vm.assessments || !_vm.codes,
                type: "submit"
              },
              on: { click: _vm.add }
            },
            [_vm._v("dataIntegration.dataMapping.actions.add")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.dataMappingList.length
      ? _c("div", [
          _c(
            "table",
            { staticClass: "data-integration__data-mapping__table table" },
            [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.translatedHeaders, function(header, i) {
                    return _c("th", { key: i, attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(header))
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.dataMappingList, function(item) {
                  return _c(
                    "tr",
                    {
                      key: item.id,
                      class: _vm.rowClass,
                      attrs: { tabindex: "0" },
                      on: {
                        click: function($event) {
                          return _vm.update(item)
                        }
                      }
                    },
                    [
                      _c("td", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.superAssessment.code) +
                            "\n\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.portalLabel(item.submitPortal)) +
                            "\n\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.code.code) +
                            "\n\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.code.codeType.code) +
                            "\n\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { class: _vm.allowClass(item, _vm.className) }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.allowLabel(item, "dataMapping")) +
                            "\n\t\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _vm.canDelete("data_integration_rules")
                        ? _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "icon-button__button",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.remove(item)
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  attrs: { name: "delete", small: true }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [_vm._v("\n\t\t\tdataIntegration.dataMapping.hint\n\t\t")]
          )
        ])
      : _c(
          "div",
          { directives: [{ name: "translate", rawName: "v-translate" }] },
          [_vm._v("\n\t\tNo items\n\t")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }