import { map } from 'lodash';

const shortcutItems = (groups) => map(groups, (group) => {
	group.shortcuts = map(group.shortcuts, ([title, card, icon]) => ({ title, card, icon }));
	return group;
});

const config = [{
	groupTitle: 'System',
	shortcuts: [
		['System', 'system-settings-admin', 'system'],
		['Clinician', 'system-settings-clinician', 'clinician-settings'],
		['Respondent', 'system-settings-respondent', 'respondent-settings'],
		['Assessment Portal', 'system-settings-assessment-portal', 'assessment-portal'],
		['Card and tile configuration', 'card-list', 'card-configuration'],
		['Activated Apps', 'system-settings-activated-apps', 'activated-apps'],
		['adapterInstances.title', 'adapter-instances', 'adapters'],
		['Menu composer', 'menu-composer', 'menu-composer']
	]
}, {
	groupTitle: 'Users',
	shortcuts: [
		['Access control', 'system-access-management', 'access-control'],
		['Clinician groups', 'system-settings-clinician-groups', 'clinician-group'],
		['Clinician attributes', 'system-settings-clinician-attributes', 'clinician-attribute'],
		[
			'Respondent attributes',
			'system-settings-respondent-attributes',
			'respondent-attribute'
		],
		['Respondent groups', 'system-settings-respondent-groups', 'respondent-group'],
		['User relation types', 'system-settings-relation-types', 'relation-types']
	]
}, {
	groupTitle: 'Assessments',
	shortcuts: [
		['Assessment list', 'system-settings-assessment', 'assessment'],
		['Assessment types', 'system-settings-assessment-types', 'assessment-types'],
		['Assessment contexts', 'system-settings-assessment-contexts', 'context'],
		['Remote scoring', 'system-settings-remote-scoring', 'remote-scoring'],
		['Reports', 'system-settings-reports', 'report'],
		['Store', 'store', 'assessment-store']
	]
}, {
	groupTitle: 'Treatments',
	shortcuts: [
		['Treatment types', 'treatment-type', 'treatment-type'],
		['Roles', 'roles', 'roles'],
		['Role sets', 'role-sets', 'role-set']
	]
}, {
	groupTitle: 'Content',
	shortcuts: [
		['Information packages', 'content-packages', 'content-package'],
		['Content package categories', 'content-package-categories', 'content-package-category'],
		['Consent templates', 'consents', 'consent-template'],
		['Pages', 'pages', 'page'],
		['Page categories', 'system-settings-page-categories', 'page-category'],
		['Media and documents', 'media', 'media'],
		['Media categories', 'system-settings-media-categories', 'media-categories'],
		['Links', 'links', 'link'],
		['Link categories', 'system-settings-link-categories', 'link-categories']
	]
}, {
	groupTitle: 'Circles',
	shortcuts: [
		['Circle categories', 'system-settings-circle-categories', 'circle-categories'],
		[
			'Circle abuse report statuses',
			'system-settings-circle-abuse-report-statuses',
			'circle-abuse-report-status'
		]
	]
}, {
	groupTitle: 'Data integration',
	shortcuts: [
		['dataIntegration.enterprise.plural', 'data-integration-enterprises', 'enterprises'],
		['dataIntegration.codes.plural', 'data-integration-codes', 'codes'],
		['dataIntegration.codeType.plural', 'data-integration-code-types', 'code-types'],
		['dataIntegration.ruleSets.plural', 'data-integration-rule-sets', 'rule-sets']
	]
}, {
	groupTitle: 'Misc',
	shortcuts: [
		['Languages', 'system-settings-languages', 'language'],
		['Export templates', 'export-templates', 'export'],
		['Flag reason templates', 'reason-templates', 'flag-reason-template'],
		['Goal categories', 'system-settings-goal-categories', 'goal-category']
	]
}];

export default shortcutItems(config);
