import TileView from 'tile-view';
import $, { when } from 'jquery';
import cwalert from 'cwalert';
import t from 'translate';
import can from 'acl-can';
import repository from 'repository';
import FlagsTriggerAssignmentView from './views/flags-trigger';
import AssessmentExpiredTriggerView from './views/assessment-expired-trigger';
import formView from 'components/form-view/form-view';
import confirmation from 'components/confirmation/confirmation';
import tpl from './tile-template';
import { compile } from 'handlebars';
import { assign, escape, find, forEach, get, isFunction, isUndefined, reduce } from 'lodash';
import store from 'store';
import planeditorSupport from '../../shared/planeditor-support';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

const template = compile(tpl);

const roleSetMismatched = () => {
	const roleSets = store.getters['plan/roleSets'];
	const treatmentTypeRoleSets = store.getters['plan/treatmentTypeRoleSets'];

	if (roleSets.length > treatmentTypeRoleSets.length) {
		return true;
	}
	return reduce(roleSets, (error, roleSet) => {
		if (!find(treatmentTypeRoleSets, { id: roleSet.id })) {
			return true;
		}
		return error;
	}, false);
};

const updateWarning = (init, treatmentType = null) => {
	const warning = $('.warning--transparent');

	if (init) {
		const content = $(
			`<span class="icon icon__default icon__error icon-2x"></span>
			<span>${t('Plan error - selected role set')}</span>`
		);
		warning.append(content);
	}
	warning.hide();

	if (treatmentType && roleSetMismatched()) {
		warning.show();
	}
};

export default TileView.extend({
	title: t('plans.settings.title'),
	acl: [{
		checkpoint: SYSTEM.PLANS.PLANS,
		op: READ
	}],

	cardData: () => ['planId', 'plan'],

	actions: () => [
		['plan/getRoleSets', store.state.cardData.planId],
		['plan/init', { planId: store.state.cardData.planId }],
		['plan/initExpiredAssessments', store.state.cardData.planId],
		['treatmentTypes/initForCurrentClinician'],
		['assessments/getEnabled'],
		['assessmentFlags/init'],
		['plan/initFlags', store.state.cardData.planId]
	],

	loaded: ({ tile }) => {
		tile.setDataLoading();
		assign(tile, {
			roleSets: store.getters['plan/roleSets'],
			treatmentTypes: store.getters['treatmentTypes/sortedByName'],
			treatmentTypeRoleSets: store.getters['plan/treatmentTypeRoleSets'],
			triggers: repository.getPlanTriggers(),
			inactiveRespondentTrigger: repository.InactiveRespondentTriggers.getById(tile.planId, {
				workflow: tile.planId
			})
		});

		when(
			tile.triggers,
			tile.inactiveRespondentTrigger,
			tile.treatmentTypeRoleSets
		).then(() => {
			tile.renderTile(tile);
			tile.setDataLoaded();
			store.subscribe((mutation) => {
				if (mutation.type === 'plan/addRoleSet' || mutation.type === 'plan/deleteRoleSet') {
					updateWarning(false, tile.plan.attributes['details:treatmentType']);
				}
			});
		});
	},

	renderTile: (tile) => {
		const flagsAssignmentView = new FlagsTriggerAssignmentView();
		const expiredAssessmentAssignmentView = new AssessmentExpiredTriggerView();

		const inactiveRespondentTriggerForm = formView({
			name: 'edit-inactive-respondent',
			model: tile.inactiveRespondentTrigger,
			fields: [{
				key: 'days',
				type: 'text',
				label: t('Days'),
				hint: t('Days separated by semicolon, for example "3;5;14"'),
				validators: {
					validator: 'regexp',
					args: /^[0-9;]+$/,
					invalidMessage: t('Days requested format error')
				}
			}]
		});

		if (!planeditorSupport()) {
			tile.$el.append(
				`<em class="warning standard-margin">${t('planEditor.supportWarning')}</em>`
			);
		}

		tile.renderForm(tile);

		const setupEditor = (el) => {
			let disableTreatmentType = ' ';
			let disabled = ' ';

			if (!can.edit(SYSTEM.PLANS.PLANS)) {
				disabled = ' disabled="disabled" ';
				disableTreatmentType = ' disabled="disabled" ';
			}

			$(el).append(template({
				disabled,
				disableTreatmentType
			}));
			updateWarning(true, tile.plan.attributes['details:treatmentType']);
		};

		const triggerOptionsUpdate = (v) => {
			const $triggerAssessment = $('.trigger-assessment').hide();
			const $triggerFlag = $('.trigger-flag').hide();
			const $triggerInactiveRespondent = $('.trigger-inactive-respondent').hide();

			switch (v) {
			case '3': { // assessment expired
				$triggerAssessment.show();
				break;
			}

			case '6': { // assessment flag
				$triggerFlag.show();
				break;
			}

			case '8': { // inactive respondent trigger
				$triggerInactiveRespondent.show();
				break;
			}
			}
		};

		const triggerOptionsSave = (v) => {
			switch (v) {
			case '3':
			case '6': { // assessment expired || assessment flag
				break;
			}

			case '8': {
				tile.inactiveRespondentTrigger.set('days', null);
				break;
			}

			default: {
				tile.inactiveRespondentTrigger.set('days', null);
			}
			}

			flagsAssignmentView.clear().render();
			expiredAssessmentAssignmentView.clear().render();
		};

		const saveDiagram = (plan, callback) => {
			const planToSave = plan;
			const $form = $('.plan-properties');

			// grab diagram details
			const formdata = $form.serializeArray();
			const details = planToSave.get('details');

			forEach(formdata, (item) => {
				details[item.name] = item.value;
			});

			// eslint-disable-next-line lodash/prefer-lodash-method
			$form.find('input[type="checkbox"]').each(function () {
				if (this.checked) {
					details[this.name] = 1;

				} else {
					details[this.name] = 0;
				}
			});

			details.author = store.state.user.userId;

			planToSave.set('details', details);

			/** This is needed so that a potentially outdated diagram is not saved along with the
			 *  workflow. The editor is now loaded in a separate tab, and the diagram must be saved
			 *  from there.
			 **/
			planToSave.unset('diagram', { silent: true });

			planToSave.save({}).then(() => {
				isFunction(callback) && callback(planToSave);
				cwalert.success(t('Changes saved'));
			});
		};

		setupEditor(tile.el);
		$('.trigger-flag').html(flagsAssignmentView.el);
		flagsAssignmentView.render();
		$('.trigger-assessment').html(expiredAssessmentAssignmentView.el);
		expiredAssessmentAssignmentView.render();
		$('.trigger-inactive-respondent').html(inactiveRespondentTriggerForm.$el);

		// marshaling backbone model into jquery ajax data
		const data = { data: tile.plan.attributes };

		// BEGIN OF DATA PROCESSING
		// Set the workflow settings
		if (!isUndefined(typeof data.data.details)) {
			const $planTrigger = $('.plan-trigger');
			// populate triggers
			$planTrigger.empty();
			$planTrigger.on('change', () => {
				triggerOptionsSave($planTrigger.val());
				saveDiagram(tile.plan);
			});
			$planTrigger.append('<option value=""></option>');
			tile.triggers.each((model) => {
				$planTrigger.append(`
					<option value='${model.get('workflowTriggerId')}'>
						${model.get('workflowTriggerName')}
					</option>
				`);
			});
			$planTrigger.val(get(data, 'data.details.workflowTrigger', ''));

			triggerOptionsUpdate($planTrigger.val());
			$planTrigger.on('change', () => {
				triggerOptionsUpdate($planTrigger.val());
			});

			const $treatmentType = $('.treatment-type');

			$treatmentType.empty().append('<option value=""></option>');
			forEach(tile.treatmentTypes, (treatmentType) => {
				$treatmentType.append(
					`<option value="${treatmentType.id}">${escape(treatmentType.name)}</option>`
				);
			});
			$treatmentType.val(get(data, 'data.details.treatmentType', ''));

			$treatmentType.on('change', () => {
				const currentTreatmentType = +tile.plan.get('details').treatmentType || 0;
				const newTreatmentType = +$treatmentType.val();
				const treatmentTypeChanged = (currentTreatmentType !== newTreatmentType);

				treatmentTypeChanged && confirmation({
					warning: true,
					icon: 'edit',
					title: t('Change treatment type'),
					message: t('planEditor.treatmentTypeWarning')
				}).then(() => {
					saveDiagram(tile.plan, () => {
						tile.plan.get('details').treatmentType = newTreatmentType;
						tile.plan.set('details:treatmentType', newTreatmentType);

						if (newTreatmentType && treatmentTypeChanged) {
							store.dispatch('plan/checkTreatmentTypeRoleSets', newTreatmentType)
								.then(() => updateWarning(false, newTreatmentType));

							if (newTreatmentType && !currentTreatmentType) {
								cwalert.success(t('planEditor.treatmentTypeAdd'));

							} else if (newTreatmentType) {
								cwalert.success(t('planEditor.rolesRemoved'));

							} else {
								cwalert.success(t('planEditor.treatmentTypesRemoved'));
							}
						} else {
							updateWarning(false, newTreatmentType);
							cwalert.success(t('Successfully saved'));
						}
					});
				}, () => {
					$treatmentType.val(currentTreatmentType);
				});
			});
		}
	},

	renderForm: (tile) => {
		tile.plan.normalize();
		const cfg = {
			name: 'edit-plan',
			model: tile.plan,
			readonly: !can.edit(SYSTEM.PLANS.PLANS),
			saveIndicator: true,
			beforeSave () {
				// model structure is… well, strange,
				// normalize happens on sync automatically but denormalize
				// needs to be called deliberately
				// see repo/plans/assessment.js to get an idea what's going on there

				tile.plan.denormalize();

				// pseudo-patch
				tile.plan.unset('diagram').unset('png');

				// after adding new plan details id doesn't exist
				if (!tile.plan.get('details').id && !tile.plan.get('workflowImage')) {
					tile.plan.set(
						'details',
						assign(tile.plan.get('details'), { id: tile.plan.getId() }),
						{ silent: true }
					);
				}
			},

			fields: [{
				key: 'details:name',
				type: 'text',
				label: t('Plan name'),
				mandatory: true
			}, {
				key: 'details:enabled',
				type: 'checkbox',
				label: t('Enabled')
			}, {
				key: 'details:allowManual',
				type: 'checkbox',
				label: t('Manual')
			}, {
				key: 'details:description',
				type: 'textarea',
				label: t('Description')
			}]
		};

		tile.$el.append(formView(cfg).$el);
	}
});
