import t from 'translate';
import Messages from '../../shared/messages-tile';
import store from 'store';
import { constant } from 'lodash';
import formView from 'components/form-view/form-view';

export default Messages.extend({
	sender: constant('RESPONDENT'),
	features: ['ENABLE_MESSAGING_SYSTEM'],
	actions: () => {
		const { treatmentId, respondentId } = store.getters.urlParams;
		return [
			['treatment/getMessages', { treatmentId, respondentId }],
			'treatments/initForRespondent'
		];
	},
	acl: [{
		checkpoint: 'administrator.respondents.communication',
		op: 'READ'
	}],

	adjustments: (tile) => {
		const formConfig = {
			name: 'treatment',
			model: tile.cardContext(),
			preventSave: true,
			fields: [{
				type: 'select',
				key: 'treatmentId',
				label: t('Treatment'),
				values: store.getters['treatments/enabled']
			}]
		};

		tile.form = formView(formConfig);
		tile.$el.prepend(tile.form.$el);
	}
});
