import Vue from 'vue';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import t from 'translate';

import { map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default Vue.component('mandatory-warning', {
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	computed: {
		...mapGetters({ respondentRelations: 'respondentRelations/relations' }),
		...mapGetters({ roleRelations: 'relationTypes/substituted' }),
		relations () {
			return map(this.respondentRelations, (single) => {
				const substituted = single.respondentRelationType.substituted;
				single.respondentRelationType.substitution = substituted ?
					this.roleRelations[1].name :
					this.roleRelations[0].name;
				return single;
			});
		}
	},
	methods: {
		...mapActions('respondentRelations', ['deleteRelationAndRefresh']),
		deleteItem (obj) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove relation'),
				message: t('Are you sure you want to delete this relation')
			}).done(() => {
				const deleteData = {
					relationTypeId: obj.respondentRelationType.id,
					respondent1Id: obj.respondent1.id,
					respondent2Id: obj.respondent2.id
				};
				this.deleteRelationAndRefresh(deleteData).then(() => {
					cwalert.success(t('Relation has been deleted'));
				});
			});
		},
		isAccessible (row) {
			if (!can.delete('administrator.respondents.relations')) {
				return false;
			}
			return row.respondent1.isAccessible && row.respondent2.isAccessible;
		},
		relatedRespondent (row) {
			const respondent = this.set(row);
			return respondent.displayName;
		},
		relatedRespondentAccessible (row) {
			const respondent = this.set(row);
			return respondent.isAccessible;
		},
		set (row) {
			return +row.respondent1.id !== +this.id ?
				row.respondent1 :
				row.respondent2;
		}
	},
	template:
	`<div class="table__container">
		<table v-if="respondentRelations.length" class="table">
			<thead>
				<tr>
					<th v-translate>Name</th>
					<th v-translate>Related to</th>
					<th v-translate>Consent kind</th>
					<th v-translate>Delete</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="relation in relations">
					<td>{{ relation.respondentRelationType.name}}</td>
					<td v-if="relatedRespondentAccessible(relation)">
						<span>{{ relatedRespondent(relation) }}</span>
					</td>
					<td v-else>
						<p><i v-translate>You are not authorised</i></p>
					</td>
					<td>{{ relation.respondentRelationType.substitution }}</td>
					<td v-if="isAccessible(relation)">
						<p class="table-cell-label">Delete</p>
						<button
							@click.stop="deleteItem(relation)"
							class="icon icon--delete treatment-role-sets__delete-button"
						></button>
					</td>
					<td v-else>
						<p><i v-translate>You are not authorised</i></p>
					</td>
				</tr>
			</tbody>
		</table>
		<p v-else class="respondent-relations__empty" v-translate>No results</p>
	</div>`
});
