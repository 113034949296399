import backendConfig from 'service/backend-config';
import * as cards from './cards/cards';
import tiles from './tiles/tiles';

export default () => {
	const demographics = backendConfig.getBackendConfigValue('demographics');
	const externalSource = demographics ? demographics['external-source'] : 0;

	if (externalSource) {
		tiles['new-respondent'] = tiles['new-respondent-external'];
	}

	return { cards, tiles };

};
