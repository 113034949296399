import t from 'translate';
import appBar from 'app-bar';
import can from 'acl-can';
import repository from 'repository';
import newReasonTemplate from '../dialogs/new-reason-template';

const card = {
	name: 'reason-templates',
	title: t('Flag reason templates'),
	url: 'reason-templates',

	ctrl: ({ cardData }) => {
		const reasonTemplates = repository.getReasonTemplates();
		cardData.set({ reasonTemplates });

		can.add('system.settings.flag-unraise-reasons') && appBar.addButton(card.name, {
			icon: 'add',
			click: newReasonTemplate,
			title: t('New reason template')
		});

		return reasonTemplates;
	}
};

export { card as reasonTemplates };
