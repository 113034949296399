export default {
	name: 'editColumn',
	props: {
		code: {
			type: String,
			default: ''
		},
		defaultType: {
			type: String,
			default: ''
		},
		init: {
			type: Function,
			required: true
		},
		invalid: {
			type: Boolean,
			default: false
		},
		formData: {
			type: Object,
			required: true
		},
		mirror: {
			type: Boolean,
			default: false
		},
		overwriteLabel: {
			type: String,
			default: ''
		},
		setIdentifier: {
			type: Function,
			required: true
		},
		submit: {
			type: Function,
			required: true
		},
		warning: {
			type: Function,
			required: true
		}
	},

	computed: {
		identifierLabel: {
			get () {
				return this.setIdentifier();
			},
			set (value) {
				this.formData.identifierLabel = value;
			}
		}
	},

	template: `
		<form class="form-view" @submit.prevent="submit">
			<text-input
				classPrefix="tree-edit"
				label="Type"
				:disabled="true"
				:value="defaultType"
			/>
			<slot name="assessment" />
			<slot name="respondent" />
			<slot name="treatment" />
			<text-input
				classPrefix="tree-edit"
				label="item id"
				v-model="identifierLabel"
				:disabled="!formData.identity || !formData.customName || mirror"
			/>
			<checkbox-input
				v-model="formData.customName"
				class-prefix="tree-edit"
				input-class="checkbox"
				:disabled="mirror"
				:label="overwriteLabel"
				:omit-default-class="true"
			/>
			<text-input
				classPrefix="tree-edit"
				label="Column label"
				v-model="formData.label"
				:disabled="mirror"
			/>
			<text-input
				label="Column name"
				classPrefix="tree-edit"
				v-model="code"
				:class-additional="warning({ display: true })"
				:disabled="true"
				:mandatory="true"
			/>
			<p
				v-if="invalid"
				class="invalid-msg tree-edit__invalid"
				v-translate
			>Column name invalid</p>
			<button-group>
				<submit-button
					:canSubmit="!invalid"
					label="Save"
				/>
				<plain-button
					:on-click="init"
					label="Reset"
				/>
			</button-group>
		</form>
		`
};
