var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("p", { staticClass: "content-field__text" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "content-field__value" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }