import MultipleEntities from '../shared/multiple-entities';
import File from './file';
import urls from './urls';

/**
 * Files
 * @class Files
 * @extends MultipleEntities
 */
const Files = MultipleEntities.extend({
	model: File,
	urls,
	eventNamespace: 'file'
});

export default new Files();
