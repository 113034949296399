import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

/**
 * Checkpoint Entity
 * @class Checkpoint
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'administratorRoleAccessId',
	eventNamespace: 'checkpoint',
	urlRoot: urls.singleCheckpoint.url,

	getName: function () {
		const translated = t(`acl.checkpoints.${this.get('administratorRoleAccessName')}`);
		return translated.length ? translated : this.get('administratorRoleAccessName');
	}
});

