import Match from './match';
import { filter } from 'lodash';

export default function (filters, packageModels) {
	var match = new Match(filters.get('query'));

	return filter(packageModels, function (storePackage) {
		var packageInfo = storePackage.get('identifier');

		if (filters.get('installed-only') && (packageInfo.importStatus === 'NEW')) {
			return false;
		}

		if (filters.get('update-available') && (packageInfo.importStatus !== 'UPDATE')) {
			return false;
		}

		if (!filters.get('query') || match.test(packageInfo.code) || match.test(packageInfo.name)) {
			return true;
		}

		return false;
	});
}

