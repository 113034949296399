<template>
	<section class="consent-submit">
		<h1 class="consent-submit__title">
			{{consent.name}}
		</h1>

		<form @submit.prevent="onSubmit">
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div class="consent-submit__content" v-html="consent.content" />
			<select-input
				v-if="multipleRespondentConsent"
				v-model="respondent"
				:options="respondents"
				label="consents.submitRespondentLabel"
				:get-option-label="respondentLabel"
			/>
			<div
				v-for="option in options"
				:key="option.id"
				class="consent-submit__option"
			>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<p class="consent-submit__option-content" v-html="option.content" />
				<checkbox-input
					v-model="answer(option).answer"
					input-class="consent-submit__checkbox"
					text-class="consent-submit__statement"
					:label="option.statement || ''"
					:translate="false"
				/>
			</div>
			<div class="consent-submit__button-container">
				<submit-button
					label="Submit"
					:can-submit="canSubmit"
				/>
			</div>
		</form>
	</section>
</template>

<script>
import confirmation from 'components/confirmation/confirmation';
import t from 'translate';
import cwalert from 'cwalert';
import { mapGetters } from 'vuex';
import { cloneDeep, compact, find, get, map } from 'lodash';
import { respondentName } from 'service/display-name/display-name';

export default {
	actions: ({ $store }) => {
		const consentInstanceId = get($store.getters.urlParams, 'consentInstanceId');
		return [
			['consentInstance/init', { consentInstanceId }],
			['consentInstance/initOptions', { consentInstanceId }]
		];
	},

	data () {
		return {
			respondent: {},
			options: this.initialOptions()
		};
	},

	computed: {
		...mapGetters({
			consent: 'consentInstance/consentInstance',
			canSubmit: 'consentInstance/clinicianCanSubmit',
			respondents: 'consentInstance/respondents'
		}),
		multipleRespondentConsent: ({ options }) => compact(
			map(options, (opt) => opt.answers.length > 1)
		).length > 1
	},

	watch: {
		respondent () {
			this.options = this.initialOptions();
		}
	},

	created () {
		this.respondent = this.respondents[0];
	},

	methods: {
		respondentLabel: (respondent) =>
			`${respondentName(respondent)} (${respondent.roleName})`,

		initialOptions () {
			return cloneDeep(this.$store.getters['consentInstance/optionsForCurrentRespondents']);
		},
		onSubmit () {
			confirmation({
				title: t('consents.submitConsent'),
				message: t('consents.description.submit')
			}).done(() => {
				this.setDataLoading();
				this.$store.dispatch('consentInstance/submitForRespondent', {
					options: this.options, respondentId: this.respondent.id
				}).then(() => {
					this.setDataLoaded();
					cwalert.success(t('consents.submittedSuccess'));
					this.closeCard();
				});
			});
		},
		inputName (id, index) {
			return `answer_${id}_${index}`;
		},
		previousAnswer (option) {
			return option.isPreviousRespondent;
		},
		answer (option) {
			return find(option.answers, ['respondent.id', this.respondent.id]);
		}
	}
};
</script>
