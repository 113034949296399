import t from 'translate';
import can from 'acl-can';
import appBar from 'app-bar';
import store from 'store';

const card = {
	title: t('Media and documents'),
	name: 'media',
	url: 'media',

	ctrl: ({ cardConfig, cardData }) => {
		if (can.add('system.settings.media')) {
			appBar.addCardLink({
				sourceCard: card.name,
				card: 'add-media',
				title: t('Add media'),
				icon: 'add'
			});
		}

		cardData.set({ limit: cardConfig.limit || 10 });
		return store.dispatch('media/categories/init');
	}
};

export { card as List };
