var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setup-tile" }, [
    _c("section", { staticClass: "tile-name" }, [
      _c(
        "h3",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "tile-name__subheading"
        },
        [_vm._v("\n\t\t\tTile name\n\t\t")]
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "tile-name__heading" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.currentTile.tileName) + "\n\t\t")
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "container-config" },
      [
        _c(
          "h3",
          { directives: [{ name: "translate", rawName: "v-translate" }] },
          [_vm._v("\n\t\t\tConfiguration\n\t\t")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "edit-object" }, [
          _c("label", [
            _c(
              "p",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                staticClass: "edit-object__label-text"
              },
              [_vm._v("Title")]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "edit-object__text",
              attrs: { type: "text", readonly: !_vm.allowEdit },
              domProps: { value: _vm.currentTile.customTitle },
              on: { blur: _vm.onCustomTitleChange }
            })
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.containerProps, function(prop) {
          return _c("edit-object", {
            key: prop.key,
            attrs: {
              readonly: !_vm.allowEdit,
              "obj-key": prop.key,
              "obj-val": prop.val,
              "on-change": _vm.onContainerPropChange,
              label: prop.label,
              values: prop.values
            }
          })
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("section", { staticClass: "tile-config" }, [
      _c(
        "h3",
        { directives: [{ name: "translate", rawName: "v-translate" }] },
        [_vm._v("\n\t\t\tTile configuration\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "ul",
        [
          _vm._l(_vm.currentTile.tileConfig, function(objVal, objKey) {
            return _c(
              "li",
              { key: objKey, staticClass: "tile-config__item" },
              [
                _c("p", { staticClass: "tile-config__type-label" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.typeLabel(objVal)) +
                      "\n\t\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  staticClass: "tile-config__key",
                  attrs: { type: "text", readonly: !_vm.allowEdit },
                  domProps: { value: objKey },
                  on: {
                    blur: function($event) {
                      return _vm.onConfigKeyChange(objKey, $event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("edit-object", {
                  attrs: {
                    "obj-key": objKey,
                    "obj-val": objVal,
                    "on-change": _vm.onConfigValueChange,
                    config: _vm.config(objKey),
                    label: false,
                    readonly: !_vm.allowEdit
                  }
                }),
                _vm._v(" "),
                _vm.reserved(objKey)
                  ? _c(objKey, {
                      tag: "component",
                      attrs: {
                        "obj-key": objKey,
                        "obj-val": objVal,
                        "on-change": _vm.onConfigValueChange
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.allowEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "tile-config__button--remove",
                        on: {
                          click: function($event) {
                            return _vm.removeConfigKey(objKey)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass: "tile-config__button-label"
                          },
                          [_vm._v("Remove key")]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("li", { staticClass: "tile-config__item--new" }, [
            _c(
              "h4",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v("\n\t\t\t\t\tAdd new key\n\t\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tile-config__new-item-container" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newConfigType,
                        expression: "newConfigType"
                      }
                    ],
                    staticClass: "tile-config__type",
                    attrs: { disabled: !_vm.allowEdit },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.newConfigType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onNewConfigTypeChange
                      ]
                    }
                  },
                  _vm._l(_vm.configTypes, function(type, typeName) {
                    return _c(
                      "option",
                      { key: typeName, domProps: { value: typeName } },
                      [_vm._v(_vm._s(type.label))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newConfigKey,
                      expression: "newConfigKey"
                    },
                    { name: "trim", rawName: "v-trim" }
                  ],
                  staticClass: "tile-config__key",
                  attrs: { type: "text", readonly: !_vm.allowEdit },
                  domProps: { value: _vm.newConfigKey },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newConfigKey = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("edit-object", {
                  attrs: {
                    "obj-key": _vm.newConfigKey,
                    "obj-val": _vm.newConfigValue,
                    config: _vm.config(_vm.newConfigKey),
                    label: false,
                    "on-change": _vm.onNewConfigValChange,
                    readonly: !_vm.allowEdit
                  }
                }),
                _vm._v(" "),
                _vm.allowEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "tile-config__button--add",
                        on: { click: _vm.addConfigKey }
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass: "tile-config__button-label"
                          },
                          [_vm._v("Add key")]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "config-preview" }, [
      _c(
        "h3",
        { directives: [{ name: "translate", rawName: "v-translate" }] },
        [_vm._v("\n\t\t\tJSON view\n\t\t")]
      ),
      _vm._v(" "),
      _c("button", {
        staticClass: "tile-config__button--copy-to-clipboard",
        on: { click: _vm.copyToClipboard }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "tile-config__copy-button-label",
          on: { click: _vm.copyToClipboard }
        },
        [_vm._v("Copy to clipboard")]
      ),
      _vm._v(" "),
      _c("pre", { staticClass: "json-view" }, [_vm._v(_vm._s(_vm.tileJSON))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }