import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * RoleCheckpoint Entity
 * @class RoleCheckpoint
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.checkpointInRole.url,
	eventNamespace: 'access-role-checkpoint',
	idAttribute: 'relationId',

	defaults: {
		administratorRoleId: null,
		administratorRoleAccessId: null,
		accessLevelId: null,
		administratorRoleHasAccessScope: null
	}
});
