import appUrl from 'service/app-url/app-url';
import can from 'acl-can';
import { forEach, isEmpty, split } from 'lodash';
import cardUrl from 'cwcardurl';
import t from 'translate';
import $ from 'jquery';
import iconFile from 'components/icon/icon-mapping-file';

const renderAttachmentList = ($listItem) =>
	$(`<ul class="attachments__list attachments__list--sub-list" />`).appendTo($listItem);

const checkReportGenerated = (report, $listItem, $reportLink) => {
	if (report.get('isNotGenerated')) {
		const notGeneratedTxt = `${
			report.get('title')
		}, ${t('assessment.properties.report.forceGenerate')}`;

		$(`<span>${notGeneratedTxt}</span>`).appendTo($listItem);

		if (can.supervise('administrator.respondents.assessmentplans')) {
			$reportLink.appendTo($listItem);
		}
	} else {
		$listItem.addClass('reports__list-item--generated');
		$reportLink.html(report.get('title')).appendTo($listItem);
	}
};

const renderGenerated = function ({ report, $listItem, assessmentInstance, mediaReferences }) {
	const generatedReportUrl = cardUrl.buildUrl('assessment-report', {
		assessmentInstanceId: assessmentInstance.getId(),
		reportId: report.get('id')
	});
	const $reportLink = $(`<a 
		href="${generatedReportUrl}"
		class="report__link tile__action"
	>t('assessment.properties.report.Generate')</a>`);

	checkReportGenerated(report, $listItem, $reportLink);

	let $attachmentList;

	if (!isEmpty(report.get('instructionFiles'))) {
		$attachmentList = renderAttachmentList($listItem);
	}

	forEach(report.get('instructionFiles'), (reportAttachment) => {
		const ext = split(reportAttachment, ('.')).pop();
		const iconExt = iconFile[ext] ? iconFile[ext] : iconFile['default'];

		const $li = $(`<li class="attachments__list-item" />`).appendTo($attachmentList);
		const repondentId = assessmentInstance.getRespondentId();
		const reportUrl = mediaReferences.getFileUrl(reportAttachment);
		const attachmentUrl = `${reportUrl}?respondentId=${repondentId}`;

		$('<span />').addClass([
			'attachments__attachment-icon',
			'icon',
			'icon--default',
			`icon--file-${iconExt}`
		]).appendTo($li);
		$(`<a 
			class="attachments__attachment-link" 
			href="${appUrl(attachmentUrl)}"
		>${reportAttachment}</a>`).appendTo($li);
	});
};

export default renderGenerated;
