import SingleEntity from '../shared/single-entity';
import urls from './urls';

// Disclaimer: the name is clearly ugly. It's on purpose. B💩ckend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little reminder of that.

export default SingleEntity.extend({
	urlRoot: urls.singleAttributeDefinition.url,
	eventNamespace: 'clinician-attribute-definition',
	idAttribute: 'administratorAttributeMetaId',

	defaults: {
		administratorAttributeMetaReadonly: false,
		administratorAttributeMetaRequired: false,
		administratorAttributeMetaType: 'CHECKBOX',
		administratorAttributeMetaParameters: '',
		administratorAttributeMetaShow: true
	},

	getType () {
		return this.get('administratorAttributeMetaType');
	},

	getParameters () {
		return this.get('administratorAttributeMetaParameters');
	},

	getLabel () {
		return this.get('administratorAttributeMetaLabel');
	},

	isReadOnly () {
		return !!this.get('administratorAttributeMetaReadonly');
	},

	isRequired () {
		return !!this.get('administratorAttributeMetaRequired');
	}
});
