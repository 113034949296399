/* eslint-disable camelcase */
import can from 'acl-can';
import cwalert from 'cwalert';
import Editor from '@tinymce/tinymce-vue';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import Vue from 'vue';
import { reduce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';

export default TileView.extend({
	actions: (tile) => {
		const consentId = tile.cardContext().get('consentId');
		return [
			['consent/initConsent', consentId]
		];
	},
	loaded: ({ tile }) => {
		const el = document.createElement('div');
		tile.$el.append(el);
		import('tinymce').then((tinyMCE) => {
			window.tinymce = tinyMCE.destroy;
			window.tinymce = tinyMCE.default;
			tile.vue = new Vue({
				el,
				components: {
					editor: Editor
				},
				store,
				data: {
					opts: {
						theme_url: 'tinymce/themes/silver/theme.min.js',
						skin_url: 'tinymce/skins/ui/oxide',
						toolbar: 'bold italic underline',
						invalid_elements: 'script',
						menubar: false,
						height: 200,
						content_css: [
							'main.css',
							'styles/custom.css'
						]
					},
					updatingConsent: false
				},
				computed: {
					...mapGetters({ consent: 'consent/consent' }),
					canSubmit: ({ $v }) =>
						can.edit('system.settings.consents') && !$v.consent.name.$invalid,

					errors () {
						const errors = [{
							rule: this.$v.consent.name.required,
							hint: t('consents.nameInvalidRequired')
						}, {
							rule: this.$v.consent.name.maxLength,
							hint: t('consents.nameInvalidMaxlength')
						}];

						return reduce(errors, (memo, { rule, hint }) => {
							!rule && memo.push(hint);
							return memo;
						}, []);
					},

					status: ({ consent }) => t(`consents.statuses.${consent.status}`)
				},
				methods: {
					t,
					...mapActions('consent', ['updateConsent']),
					saveConsentInfo () {
						this.updatingConsent = true;
						this.updateConsent(this.consent).then(() => {
							cwalert.success(t('consents.updated'));
							this.updatingConsent = false;
							store.dispatch('consent/initConsent', this.consent.id);
						});
					}
				},

				template: `
				<div>
					<div class="consent__field-container consents-dialog--mandatory">
						<text-input
							v-model="consent.name"
							class-prefix="consents-dialog"
							:mandatory="true"
							:disabled="updatingConsent"
							:label="t('Name')"
							:invalid="$v.consent.name.$invalid"
						>
							<template #validations>
								<p v-for="error in errors">
									{{ error }}
								</p>
							</template>
						</text-input>
					</div>
					<div class="consent-status__container">
						<p>
							<span v-translate>Status</span>
							<span class="consent-status__status">
								{{ status }}
							</span>
						</p>
					</div>
					<div>
						<label
							class="consents-dialog consents-dialog__label-container">
								<p
									class="
										consents-dialog__label-text
										consents-dialog__mandatory_field 
										consents-dialog__name
									"
									v-translate
								>Text (introduction)</p>
								<editor
									:init="opts"
									:disabled="updatingConsent"
									v-model="consent.content"
								/>
						</label>
						<div class="consent-checkbox__container">
							<label>
								<input
									:disabled="updatingConsent"
									v-model="consent.submittableByAdministrator"
									type="checkbox"
									class="consent-checkbox" />
								<span
									class="consent-checkbox__label"
									v-translate
								>consents.allowAnswerClinician</span>
							</label>
						</div>
						<div class="consent-checkbox__container">
							<label>
								<input
									:disabled="updatingConsent"
									v-model="consent.requireAllResponsible"
									type="checkbox"
									class="consent-checkbox" />
								<span
									class="consent-checkbox__label"
									v-translate
								>consents.requireAllAnswers</span>
							</label>
						</div>
						<div class="consent-checkbox__container">
							<label>
								<input
									:disabled="updatingConsent"
									v-model="consent.combinedAnswers"
									type="checkbox"
									class="consent-checkbox" />
								<span
									class="consent-checkbox__label"
									v-translate
								>consents.combinations</span>
							</label>
						</div>
						<div class="consent-button__container">
							<button
								:disabled="!canSubmit"
								class="consents-dialog__button consents-dialog__button--submit"
								@click="saveConsentInfo"
								v-translate
							>Save</button>
						</div>
					</div>
				</div>
      			`,
				validations: {
					consent: {
						name: {
							maxLength: maxLength(60),
							required
						}
					}
				}
			});
		});
	}
});
