<template>
	<div>
		<div
			v-if="updating"
			v-loader-spinner
			class="data-integration--loading"
		/>
		<form @submit.prevent="onSubmit">
			<checkbox-input
				v-model="item.enabled"
				class-prefix="data-integration"
				input-class="checkbox-input"
				text-class="checkbox-text"
				:disabled="!canEdit"
				:label="t('dataIntegration.codes.fields.enableCode')"
				:translate="false"
			/>
			<text-input
				v-model="item.name"
				class-prefix="data-integration"
				label="dataIntegration.codes.fields.friendly"
				:disabled="!canEdit"
				:invalid="invalid({ field: 'name' })"
				:mandatory="true"
				:required="true"
			/>
			<text-input
				v-model="item.code"
				class-prefix="data-integration"
				:label="t('dataIntegration.codes.fields.code')"
				:disabled="!canEdit"
				:invalid="invalid({ field: 'code' })"
				:mandatory="true"
				:required="true"
			/>
			<select-input
				v-model="codeTypeOption"
				container-class="select-container"
				class-prefix="data-integration"
				label="dataIntegration.codes.fields.type"
				:disabled="!canEdit"
				:invalid="invalid({ field: 'codeType', object: true })"
				:mandatory="true"
				:options="codeTypes"
				:searchable="false"
			/>
			<select-input
				v-model="enterpriseOption"
				container-class="select-container"
				class-prefix="data-integration"
				label="dataIntegration.codes.fields.enterprise"
				:placeholder="t('dataIntegration.enterprise.actions.select')"
				:disabled="!canEdit"
				:options="enterprises"
				:searchable="false"
			/>
			<section class="data-integration__save-container">
				<submit-button
					:label="t('Update')"
					:can-submit="allowUpdate"
					class-prefix="data-integration"
				/>
			</section>
		</form>
	</div>
</template>

<script>
import can from 'acl-can';
import cwalert from 'cwalert';
import t from 'translate';
import { required } from 'vuelidate/lib/validators';
import { SYSTEM } from 'service/acl/checkpoints';
import { forEach, get, map } from 'lodash';
import { READ } from 'service/acl/access-levels';

export default {
	title: t('dataIntegration.codes.name'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
		op: READ
	}],

	actions: () => ['dataIntegration/codeTypes/search'],

	data: () => ({
		emptyEnterprise: {
			label: t('dataIntegration.enterprise.options.noEnterprise'),
			value: 0
		},
		submitted: false,
		updating: false
	}),

	computed: {
		enterprises: ({ $store }) => $store.getters['dataIntegration/enterprises/listWithEmpty'],
		item: ({ $store }) => $store.getters['dataIntegration/codes/data'],

		codeTypes: ({ $store }) => map(
			$store.getters['dataIntegration/codeTypes/enabled'],
			(codeType) => ({
				label: codeType.name,
				value: codeType.id
			})
		),

		allowUpdate: ({ $v, canEdit }) => !$v.item.$invalid && canEdit,

		canEdit: ({ updating }) => can.edit(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE) && !updating,

		codeTypeOption: {
			get: ({ item }) => ({
				label: item.codeType.name,
				value: item.codeType.id
			}),
			set (option) {
				this.item.codeType.name = option.label;
				this.item.codeType.id = option.value;
			}
		},

		enterpriseOption: {
			get ({ emptyEnterprise, item }) {
				if (!item.enterprise) {
					return emptyEnterprise;
				}
				return ({
					label: item.enterprise.name,
					value: item.enterprise.id
				});
			},
			set (option) {
				if (option) {
					this.item.enterprise = {
						id: option.value,
						name: option.label
					};
				} else {
					this.item.enterprise = null;
				}
			}
		}
	},

	methods: {
		invalid ({ field, object = false }) {
			const property = object ? this.$v.item[field].id : this.$v.item[field];
			return this.submitted && property.$invalid;
		},

		onSubmit () {
			this.submitted = true;

			if (!this.$v.item.$invalid) {
				const data = {
					...this.item,
					codeType: get(this, 'item.codeType.id', null),
					enterprise: get(this, 'item.enterprise.id', null)
				};
				this.updating = true;
				this.$store.dispatch('dataIntegration/codes/update', data).then(() => {
					cwalert.success(t('dataIntegration.codes.messages.updated'));
					this.updating = false;
				}).catch((err) => {
					if (err.responseJSON.errors) {
						forEach(err.responseJSON.errors, (message) => {
							cwalert.error(t(`dataIntegration.errors.${message}`));
						});
					}
					this.updating = false;
				});
			}
		}
	},
	validations: {
		item: {
			code: { required },
			codeType: {
				id: { required }
			},
			name: { required }
		}
	}
};
</script>
