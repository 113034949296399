import MultipleEntities from '../../shared/multiple-entities';
import urls from './triggers-urls';
import InactiveRespondentTrigger from './inactive-respondent-entity';

const InactiveRespondentTriggers = MultipleEntities.extend({
	urls,
	model: InactiveRespondentTrigger,
	eventNamespace: 'inactive-respondent-trigger'
});

export default new InactiveRespondentTriggers();
