import { Model, Collection } from 'backbone';
import TileView from 'tile-view';
import t from 'translate';
import formView from 'components/form-view/form-view';
import cardurl from 'cwcardurl';
import cwalert from 'cwalert';
import systemSettings from 'system-settings';
import appContext from 'app-context';
import can from 'acl-can';
import DemographicsRespondentModel from './model';
import repository from 'repository';
import store from 'store';
import { defer, find, get } from 'lodash';
import backendConfig from 'service/backend-config';

const defaultSsnLength = 11;
const demographicsConfig = () => backendConfig.getBackendConfigValue('demographics');

const BACKEND_USER_NOT_FOUND = 'USER_NOT_FOUND_IN_DEMOGRAPHICS_PROVIDER';

export default TileView.extend({
	title: t('Import new respondent'),
	acl: () => {
		const checkpoints = [{
			checkpoint: 'administrator.respondents.respondents',
			op: 'CREATE'
		}];

		if (systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY')) {
			checkpoints.push({
				checkpoint: 'administrator.respondents.groups',
				op: 'READ'
			});
		}

		return checkpoints;
	},

	actions: ['treatmentTypes/initForCurrentClinician'],

	tileData: () => ({
		ssnLength: demographicsConfig()['person-identifier-length'] || defaultSsnLength,
		groupIsMandatory: systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY'),
		respondentGroups: can.read('administrator.respondents.groups') ?
			repository.RespondentGroups.currentClinicianWithCommon() :
			new Collection()
	}),

	loaded: ({ tile }) => {
		tile.treatmentTypes = store.getters['treatmentTypes/sortedByName'];
		const card = tile.card();
		const defaultTreatment = find(tile.treatmentTypes, { isDefault: true });
		const respondent = new DemographicsRespondentModel({
			treatmentType: get(defaultTreatment, 'id', '')
		});
		const groupHint = tile.groupIsMandatory ? t('Respondent group is mandatory') : '';
		let targetCard = (tile.config() && tile.config().redirect) || 'respondent-status';

		const cfg = {
			name: 'respondent-external-new',
			model: respondent,
			errorMessage (model) {
				const result = model.responseJSON;

				const message = get(result, 'exception.msg') === BACKEND_USER_NOT_FOUND ?
					t('newRespondentExternal.userNotFound') :
					t('general.messages.changes-not-saved');

				return message;
			},
			listenTo: ['submit'],
			autocomplete: false,
			onAfterSave (responseData) {
				// unnecessary request, but we should be consistent
				// and check in backend that we have access to this respondent
				// @TODO change .get('respondentId') to getId() when
				// DemographicsRespondentModel will be ready in repository
				repository.getRespondent(respondent.get('respondentId'))
					.done(() => {
						cwalert.success(t('Respondent account successfully created'));
						defer(() => {
							card.close({
								go: cardurl.buildUrl(targetCard, {
									respondentId: get(responseData, 'respondentId', null),
									treatmentId: get(responseData, 'treatmentId', null)
								})
							});
						});
					}).fail(() => {
						cwalert.warning(t('Respondent account added successfully, ' +
							'but you don\'t have access to him or her.'));
						defer(() => {
							card.close();
						});
					}).always(() => {
						appContext.trigger('respondent.create', respondent.clone());
						respondent.clear();
					});
			},

			fields: [{
				key: 'respondentUsername',
				type: 'text',
				mandatory: true,
				label: t('D/H/FH number'),
				min: tile.ssnLength,
				max: tile.ssnLength,
				hint: t('{num} digits', {
					num: tile.ssnLength
				}),
				validators: {
					validator: 'regexp',
					args: new RegExp(demographicsConfig()['person-identifier-regexp']),
					invalidMessage: t('Should be numeric, {num} digits long', {
						num: tile.ssnLength
					})
				}
			}, {
				key: 'respondentGroup',
				type: 'select',
				collection: new Collection([new Model({
					respondentGroupName: t('-- no group --'),
					respondentGroupId: ''
				})].concat(tile.respondentGroups.models)),
				labelField: 'respondentGroupName',
				keyField: 'respondentGroupId',
				label: t('Respondent Group'),
				mandatory: tile.groupIsMandatory,
				hint: groupHint
			}, {
				key: 'treatmentType',
				label: t('Treatment'),
				type: 'select',
				values: tile.treatmentTypes,
				description: t('Select treatment type'),
				mandatory: true,
				hint: tile.treatmentTypes.length ?
					undefined :
					t('There are no available treatments to select from')
			}],

			buttons: [{
				caption: t('Add'),
				name: 'add',
				type: 'submit'
			}, {
				caption: t('Add and go to treatment'),
				name: 'addAndGoToTreatment',
				type: 'submit',
				action () {
					targetCard = 'treatment';
				}
			}, {
				caption: t('Reset'),
				name: 'reset',
				type: 'reset',
				role: 'clear'
			}]
		};

		tile.$el.append(formView(cfg).$el);
	}
});
