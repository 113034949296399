import can from 'acl-can';
import appbar from 'app-bar';
import t from 'translate';
import newAssessmentType from '../dialogs/new-assessment-type';
import store from 'store';

const card = {
	name: 'system-settings-assessment-types',
	title: t('Assessment types'),
	url: 'system/assessment-types',

	ctrl: () => store.dispatch('types/init').then(() => {
		can.add('system.settings.assessment-types') && appbar.addButton(card.name, {
			icon: 'add',
			click: newAssessmentType,
			title: t('New assessment type')
		});
	})
};

export { card as assessmentTypes };
