import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import systemSettings from 'system-settings';
import can from 'acl-can';
import t from 'translate';
import RespondentModel from 'repo/respondents/respondent';
import datetime from 'datetime';
import repository from 'repository';
import store from 'store';
import Vue from 'vue';
import { Collection } from 'backbone';
import { required } from 'vuelidate/lib/validators';
import {
	assign, defer, find, forEach, get, isUndefined, keys, isNull, split, filter, reduce
} from 'lodash';

export default TileView.extend({
	acl: () => {
		const checkpoints = [{
			checkpoint: 'administrator.respondents.respondents',
			op: 'CREATE'
		}];

		if (systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY')) {
			checkpoints.push({
				checkpoint: 'administrator.respondents.groups',
				op: 'READ'
			});
		}

		return checkpoints;
	},

	title: t('respondent-list.NewRespondent'),

	actions: [
		'treatmentTypes/initForCurrentClinician',
		'languages/init',
		'timezones/init'
	],

	tileData: () => ({
		groupIsMandatory: systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY'),
		attributes: repository.getRespondentAttributeDefinitions(),
		groups: can.read('administrator.respondents.groups') ?
			repository.RespondentGroups.currentClinicianWithCommon() :
			new Collection()
	}),

	loaded: ({ tile }) => {
		let respondent = new RespondentModel();

		const vm = new Vue({
			el: document.createElement('div'),
			data: {
				show: false,
				formData: {}
			},

			computed: {
				items () {
					return store.getters['treatmentType/visibleAttributes'];
				}
			},

			watch: {
				formData (formData) {
					respondent.set('treatmentAttributes', formData);
				}
			},

			methods: {
				component (type) {
					return {
						CHECKBOX: 'checkbox-input',
						DATE: 'datetime-input',
						DROPDOWN: 'select-input'
					}[type] || 'text-input';
				},

				getOptions (item) {
					return !isNull(item.parameters) ? split(item.parameters, /;/) : null;
				},

				isMultiline (item) {
					return item.type === 'TEXTAREA';
				},

				isNumeric (item) {
					return item.type === 'NUMERIC';
				}
			},

			validations () {
				const requiredItems = filter(this.items, { isRequired: true });
				const formData = reduce(requiredItems, (memo, item) => {
					memo[item.id] = { required };
					return memo;
				}, {});

				return { formData };
			},

			template: `
				<div
					v-if="show && items.length"
					class="form-view__field-container"
				>
					<component
						v-for="item in items"
						type="date"
						:format="$t('date.formats.dateLuxon')"
						:key="item.id"
						:is="component(item.type)"
						:label="item.label"
						:translate="false"
						:multiline="isMultiline(item)"
						:numeric="isNumeric(item)"
						:options="getOptions(item)"
						:mandatory="item.isRequired"
						:required="item.isRequired"
						:readonly="item.isReadonly"
						v-model="formData[item.id]"
						@input="$v.$touch()"
					/>
				</div>
			`
		});

		const loadAttributes = (treatmentTypeId) => {
			store.dispatch('treatmentType/initAttributes', {
				treatmentTypeId
			}).then(() => {
				tile.setLoaded();
				vm.show = true;
				vm.formData = {};
			});
		};

		const initDefaultTreatment = () => {
			const defaultTreatmentType = find(
				store.getters['treatmentTypes/sortedByName'],
				{ isDefault: true }
			);

			// suddenly it turned out to be the situation when there are no default treatment
			// types in the system
			if (!isUndefined(defaultTreatmentType)) {
				respondent.set({ treatmentTypeId: defaultTreatmentType.id });
				loadAttributes(defaultTreatmentType.id);
			}
		};

		// eslint-disable-next-line complexity, max-statements
		const initForm = () => {
			const card = tile.card();
			const attributes = tile.attributes.getVisible();
			const groupMandatory = tile.groupIsMandatory;
			const groupHint = (groupMandatory) ? t('Respondent group is mandatory') : '';
			const targetCard = (tile.config() && tile.config().redirect) || 'respondent-status';

			const cfg = {
				name: 'respondent-new',
				model: respondent,
				successMessage: t('respondent.account-creation.success'),
				errorMessage (model) {
					const result = model.responseJSON;

					if (!!result.exception && !!result.exception.msg) {
						// [pats] this is terrible, maybe we should use exception code
						// dictionary or something like that
						return `${t('general-list.ChangesNotSaved')} ${(t(result.exception.msg) ?
							t(result.exception.msg) :
							'')}`;
					}

					return null;
				},
				listenTo: ['submit'],
				autocomplete: false,
				beforeSave () {
					// Provide `attributes` property so backend can set custom
					// attributes. Data consistency +3!
					respondent.attributes.attributes = {};
					const respondentKeys = keys(respondent.toJSON());
					forEach(respondentKeys, (keyName) => {
						if (!/attr:/.test(keyName)) {
							return;
						}
						// eslint-disable-next-line lodash/prefer-lodash-method
						const attrId = +keyName.split(':')[1];
						respondent.attributes.attributes[attrId] = respondent.get(keyName);
					});

					const respondentBirthdate = this.model.get('respondentBirthdate');

					if (respondentBirthdate) {
						const date = datetime(respondentBirthdate).toObject();
						this.model.set('respondentBirthdate', date, { silent: true });
					}
				},

				onAfterSave (responseData) {
					// unnecessary request, but we should be consistent and check in backend that we
					// have access to this respondent

					const tempRespondent = new RespondentModel(respondent.toJSON());

					return tempRespondent.fetch()
						.done(() => {
							const redirectTo = cardUrl.buildUrl(targetCard, {
								respondentId: tempRespondent.getId(),
								treatmentId: get(responseData, 'treatmentId', null)
							});
							defer(() => {
								card.close({ go: redirectTo, manualOverride: true });
							});
						}).fail(() => {
							cwalert.warning(t('respondent.account-creation.success-restricted'));
							defer(() => {
								if (card.cardData.get('cardName') === 'home-clinician') {
									respondent = new RespondentModel();
									tile.$el.html(formView(initForm()).$el);
									initDefaultTreatment();
								} else {
									card.close();
								}
							});
						}).always(() => {
							// Remove `attributes` property and remove custom
							// attributes from entity. Data consistency +8!
							if (respondent.attributes.hasOwnProperty('attributes')) {
								forEach(respondent.attributes.attributes, (attrVal, attrKey) => {
									respondent.unset(`attr:${attrKey}`, { silent: true });
								});
								delete respondent.attributes.attributes;
							}
						});
				},

				fieldsets: [{
					name: 'respondent',
					caption: t('respondent-list.PersonalData'),
					fields: [
						'respondentFirstName',
						'respondentLastName',
						'respondentUsername',
						'respondentLanguageId',
						'respondentPassword',
						'respondentPhone',
						'respondentEmail',
						'respondentTimezoneName',
						'respondentGender',
						'respondentBirthdate',
						'respondentAddress',
						'respondentGroup',
						'treatmentTypeId',
						'respondentCommunicationDisabled',
						'respondentAccountEnabled',
						'respondentTestAccount'
					]
				}, {
					name: 'attributes',
					caption: t('respondent-list.CustomAttributes'),
					fields: []
				}, {
					name: 'buttons',
					buttons: ['add', 'reset']
				}],

				fields: [{
					key: 'respondentFirstName',
					type: 'text'
				}, {
					key: 'respondentLastName',
					type: 'text'
				}, {
					key: 'respondentUsername',
					type: 'username',
					variant: 'respondent',
					hint: t('general-list.userNameHint'),
					mandatory: true
				}, {
					key: 'respondentLanguageId',
					type: 'select',
					values: store.state.languages.data,
					labelField: 'languageLabel',
					keyField: 'languageId'
				}, {
					key: 'respondentPassword',
					type: 'password',
					mandatory: true
				}, {
					key: 'respondentPhone',
					label: t('PhoneAndCode'),
					type: 'tel'
				}, {
					key: 'respondentEmail',
					type: 'email'
				}, {
					key: 'respondentTimezoneName',
					type: 'select',
					values: store.state.timezones.data,
					labelField: 'timezoneName',
					keyField: 'timezoneName'

				}, {
					key: 'respondentGender',
					type: 'select',
					values: [
						{ 0: t('general-list.Female') },
						{ 1: t('general-list.Male') },
						{ 2: t('general-list.Unknown') }
					]
				}, {
					key: 'respondentBirthdate',
					type: 'date'
				}, {
					key: 'respondentAddress',
					type: 'text'
				}, {
					key: 'respondentGroup',
					type: 'select',
					collection: tile.groups,
					labelField: 'respondentGroupName',
					keyField: 'respondentGroupId',
					label: t('Respondent Group'),
					setNullLabel: t('-- no group --'),
					mandatory: groupMandatory,
					hint: groupHint
				}, {
					key: 'treatmentTypeId',
					type: 'select',
					values: store.getters['treatmentTypes/sortedByName'],
					label: t('Treatment types'),
					setNullLabel: t('Select a treatment type'),
					mandatory: true,
					validators: {
						validator: () => !vm.$v.$invalid,
						invalidMessage: t('Fill in the required attributes below')
					},
					customize (view, formView) {
						view.listenTo(
							formView,
							'change:treatmentTypeId',
							(model, treatmentTypeId) => {
								const $parent = view.ui.select
									.closest('.form-view__field-container');
								$parent.after(vm.$el);

								tile.setLoading();
								vm.show = false;

								loadAttributes(treatmentTypeId);
							}
						);
					}
				}, {
					key: 'respondentCommunicationDisabled',
					type: 'checkbox'
				}, {
					key: 'respondentAccountEnabled',
					type: 'checkbox'
				}, {
					key: 'respondentTestAccount',
					type: 'checkbox'
				}],
				buttons: [{
					caption: t('Add'),
					name: 'add',
					type: 'submit'
				}, {
					caption: t('Reset'),
					name: 'reset',
					type: 'reset',
					role: 'reset',
					action () { /* need to manually reset respondent attrs because they aren't in
					respondent default model */
						forEach(attributes, (attribute) => {
							const keyId = attribute.get('respondentAttributeMetaId');
							cfg.model.set(`attr:${keyId}`, '');
						});
						cfg.model.set('respondentTestAccount', false);
						cfg.model.unset('respondentGroup');
					}
				}]
			};

			if (!attributes.length) {
				cfg.fieldsets.splice(1, 1);
			}

			// eslint-disable-next-line max-statements
			forEach(attributes, (attribute) => {
				const parameters = attribute.get('respondentAttributeMetaParameters');
				const keyName = `attr:${attribute.get('respondentAttributeMetaId')}`;
				const input = tile.getInputByAttributeType(attribute);

				const inputCfg = {
					key: keyName,
					label: attribute.get('respondentAttributeMetaLabel'),
					type: input.type,
					description: input.description,
					readonly: attribute.get('respondentAttributeMetaReadonly'),
					mandatory: input.type !== 'checkbox' &&
					attribute.get('respondentAttributeMetaRequired')
				};

				if (input.type === 'text' || input.type === 'textarea') {
					inputCfg.max = 255; // current DB limit
				}

				assign(inputCfg, input.config);

				if (parameters.length) {
					inputCfg.values = split(parameters, /;/);
				}
				cfg.fields.push(inputCfg);
				cfg.fieldsets[1].fields.push(keyName);
			});

			return cfg;
		};

		tile.$el.html(formView(initForm()).$el);

		initDefaultTreatment();
	},

	getInputByAttributeType (attribute) {
		return {
			STRING: {
				type: 'text'
			},
			TEXTAREA: {
				type: 'textarea',
				config: {
					rows: attribute.get('respondentAttributeMetaParameters')
				}
			},
			CHECKBOX: {
				type: 'checkbox'
			},
			NUMERIC: {
				type: 'number'
			},
			DATE: {
				type: 'date'
			},
			DROPDOWN: {
				type: 'select',
				description: t('Select a value')
			}
		}[attribute.get('respondentAttributeMetaType')];
	}
});
