import Backbone from 'backbone';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import t from 'translate';
import Url from 'lib/url/url';

export default Backbone.View.extend({

	title: t('Clinician groups - Respondent groups mapping'),
	acl: [{
		checkpoint: 'system.export.administratorrespondentgroups',
		op: 'OPEN'
	}],

	render: function () {
		var M = Backbone.Model.extend({
			sync: function () {
				var dfd = $.Deferred();
				var url = new Url('backend-commons/export/rest/administratorGroup/respondentGroup/all', null, {
					querySeparator: '',
					prependBackendRoot: 'system'
				});
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		var cfg = {
			name: 'system-export-administratorrespondentgroups',
			model: new M(),
			loader: false,
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			fields: [],
			buttons: [{
				caption: t('Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
