import appBar from 'app-bar';
import can from 'acl-can';
import cardUrl from 'cwcardurl';
import t from 'translate';
import store from 'store';

const card = {
	title: t('Flag'),
	name: 'flag',
	url: 'flag/:flagId/',

	ctrl: ({ cardData }, { flagId }) => store.dispatch('flag/initPure', { flagId }).then(() => {
		const flag = store.state.flag.model;
		cardData.set({
			flag,
			assessmentId: store.state.flag.data.assessment.assessmentId,
			assessmentInstanceId: store.state.flag.data.assessmentInstance.assessmentInstanceId
		});
		card.mountButtons(flag);
	}),

	mountButtons (flag) {
		appBar.clear(card.name);

		if (can.edit('administrator.respondents.flags') && flag.get('isRaised')) {
			appBar.addButton(card.name, {
				icon: 'check',
				title: t('Lower flag'),
				click: () => {
					cardUrl.openCard('lower-flag', {
						respondentId: flag.respondentId(),
						flagId: flag.getId()
					});
				}
			});
		}

		if (can.read('administrator.respondents.assessmentplans')) {
			appBar.addButton(card.name, {
				icon: 'assessment',
				title: t('Open assessment properties'),
				click: () => {
					cardUrl.openCard('assessment-properties', {
						assessmentInstanceId: flag.assessmentInstanceId()
					});
				}
			});
		}

		if (can.read('administrator.respondents.assessmentcontent')) {
			appBar.addButton(card.name, {
				icon: 'open-folder',
				title: t('Open assessment'),
				click: () => {
					const prefix = flag.get('assessmentInstance').assessmentInstanceClinicianOnly ?
						'clinician' :
						'respondent';
					const cardName = `${prefix}-assessment-runtime`;

					cardUrl.openCard(cardName, { id: flag.assessmentInstanceId() });
				}
			});
		}
	}
};

export { card as flag };
