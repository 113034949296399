<template>
	<div class="lockout-status">
		<div class="lockout-status__text">
			{{$t('lockout.account-locked')}}
		</div>
		<div class="lockout-status__action">
			<submit-button
				v-if="canUnlock"
				label="lockout.unlock"
				@click="$emit('unlock')"
			/>
		</div>
	</div>
</template>

<script>
import SubmitButton from 'components/form/submit-button';

export default {
	components: {
		SubmitButton
	},

	props: {
		canUnlock: {
			type: Boolean,
			default: false
		}
	}
};
</script>
