'use strict';

import t from 'translate';

const card = {
	title: t('Flags'),
	name: 'raised-flags',
	url: 'raised-flags'
};

export { card as raised };
