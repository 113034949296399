<template>
	<div class="setup-tile">
		<section class="tile-name">
			<h3 v-translate class="tile-name__subheading">
				Tile name
			</h3>
			<h2 class="tile-name__heading">
				{{currentTile.tileName}}
			</h2>
		</section>
		<section class="container-config">
			<h3 v-translate>
				Configuration
			</h3>
			<div class="edit-object">
				<label>
					<p v-translate class="edit-object__label-text">Title</p>
					<input
						class="edit-object__text"
						type="text"
						:value="currentTile.customTitle"
						:readonly="!allowEdit"
						@blur="onCustomTitleChange"
					/>
				</label>
			</div>

			<edit-object
				v-for="prop in containerProps"
				:key="prop.key"
				:readonly="!allowEdit"
				:obj-key="prop.key"
				:obj-val="prop.val"
				:on-change="onContainerPropChange"
				:label="prop.label"
				:values="prop.values"
			/>
		</section>
		<section class="tile-config">
			<h3 v-translate>
				Tile configuration
			</h3>
			<ul>
				<li
					v-for="(objVal, objKey) in currentTile.tileConfig"
					:key="objKey"
					class="tile-config__item"
				>
					<p class="tile-config__type-label">
						{{typeLabel(objVal)}}
					</p>
					<input
						class="tile-config__key"
						type="text"
						:value="objKey"
						:readonly="!allowEdit"
						@blur="onConfigKeyChange(objKey, $event)"
					/>
					<edit-object
						:obj-key="objKey"
						:obj-val="objVal"
						:on-change="onConfigValueChange"
						:config="config(objKey)"
						:label="false"
						:readonly="!allowEdit"
					/>
					<component
						:is="objKey"
						v-if="reserved(objKey)"
						:obj-key="objKey"
						:obj-val="objVal"
						:on-change="onConfigValueChange"
					/>
					<button
						v-if="allowEdit"
						class="tile-config__button--remove"
						@click="removeConfigKey(objKey)"
					>
						<span v-translate class="tile-config__button-label">Remove key</span>
					</button>
				</li>
				<li class="tile-config__item--new">
					<h4 v-translate>
						Add new key
					</h4>
					<div class="tile-config__new-item-container">
						<select
							v-model="newConfigType"
							class="tile-config__type"
							:disabled="!allowEdit"
							@change="onNewConfigTypeChange"
						>
							<option
								v-for="(type, typeName) in configTypes"
								:key="typeName"
								:value="typeName"
							>{{type.label}}</option>
						</select>
						<input
							v-model="newConfigKey"
							v-trim
							class="tile-config__key"
							type="text"
							:readonly="!allowEdit"
						/>
						<edit-object
							:obj-key="newConfigKey"
							:obj-val="newConfigValue"
							:config="config(newConfigKey)"
							:label="false"
							:on-change="onNewConfigValChange"
							:readonly="!allowEdit"
						/>
						<button
							v-if="allowEdit"
							class="tile-config__button--add"
							@click="addConfigKey"
						>
							<span v-translate class="tile-config__button-label">Add key</span>
						</button>
					</div>
				</li>
			</ul>
		</section>
		<section class="config-preview">
			<h3 v-translate>
				JSON view
			</h3>
			<button class="tile-config__button--copy-to-clipboard" @click="copyToClipboard" />
			<span
				v-translate
				class="tile-config__copy-button-label"
				@click="copyToClipboard"
			>Copy to clipboard</span>
			<pre class="json-view">{{tileJSON}}</pre>
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { valueType } from 'components/edit-object/checks';
import can from 'acl-can';
import cwalert from 'cwalert';
import { reservedKeys, components } from './custom-keys';
import { includes, isObject, replace } from 'lodash';
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';

export default {
	title: t('Tile setup'),
	instant: true,
	acl: [{
		checkpoint: SYSTEM.SETTINGS.SETTINGS,
		op: READ
	}],
	components,
	data: () => ({
		widthValues: ['', 1, 2, 3, 4, 'max'],
		heightValues: ['', 1, 2, 3, 4, 'auto'],
		minMaxWidthValues: ['', 1, 2, 3],
		newConfigKey: '',
		newConfigVal: '',
		newConfigType: 'string',
		pristineNewConfigValue: true,
		configTypes: {
			string: {
				label: t('editTile.string'),
				defaultValue: ''
			},
			number: {
				label: t('editTile.number'),
				defaultValue: 0
			},
			boolean: {
				label: t('editTile.boolean'),
				defaultValue: false
			},
			object: {
				label: t('editTile.object'),
				defaultValue: { key: '' }
			},
			array: {
				label: t('editTile.array'),
				defaultValue: ['']
			},
			matrix: {
				label: t('editTile.matrix'),
				defaultValue: [[0, 0]]
			},
			arrayArray: {
				label: t('editTile.arrayArray'),
				defaultValue: [
					['']
				]
			},
			booleanObject: {
				label: t('editTile.booleanObject'),
				defaultValue: { key: false }
			},
			objectArray: {
				label: t('editTile.objectArray'),
				defaultValue: [{
					key: 'value'
				}]
			}
		}
	}),

	computed: {
		containerProps () {
			return [{
				...this.keyVal('width'),
				values: this.widthValues,
				label: t('Width')
			}, {
				...this.keyVal('height'),
				values: this.heightValues,
				label: t('Height')
			}, {
				...this.keyVal('minWidth'),
				values: this.minMaxWidthValues,
				label: t('Minimum width')
			}, {
				...this.keyVal('maxWidth'),
				values: this.minMaxWidthValues,
				label: t('Maximum width')
			}, {
				...this.keyVal('column'),
				label: t('Place below previous tile')
			}, {
				...this.keyVal('micro'),
				label: t('Small tile')
			}, {
				...this.keyVal('collapse'),
				label: t('Collapse to bottom')
			}, {
				...this.keyVal('overflow', true),
				label: t('Overflow visible')
			}, {
				...this.keyVal('boxless', false),
				label: t('Boxless')
			}];
		},
		newConfigValue () {
			return this.pristineNewConfigValue ?
				this.configTypes[this.newConfigType].defaultValue :
				this.newConfigVal;
		},
		tileJSON () {
			return JSON.stringify(this.currentTile, null, 4);
		},
		allowEdit () {
			return can.edit('system.settings.settings');
		},
		allowDelete () {
			return can.delete('system.settings.settings');
		},
		...mapGetters({ currentTile: 'cards/tiles/currentTile' })
	},

	methods: {
		keyVal (key, defVal = false) {
			return {
				key,
				val: this.currentTile.containerConfig[key] || defVal
			};
		},

		onCustomTitleChange (e) {
			this.$store.dispatch('cards/setTileCustomTitle', e.target.value);
		},

		onContainerPropChange ({ path, value }) {
			this.$store.dispatch('cards/setTileContainerProp', { path, value });
		},

		onConfigValueChange ({ path, value }) {
			this.$store.dispatch('cards/setTileConfigValue', { path, value });
		},

		onConfigKeyChange (prevKey, e) {
			this.$store.dispatch('cards/setTileConfigKey',
				{ oldKey: prevKey, newKey: e.target.value });
		},

		config (key) {
			if (key === 'tableSort') {
				return {
					matrixMin: [0, 0],
					matrixMax: [Infinity, 1]
				};
			}

			return {};
		},

		onNewConfigTypeChange () {
			this.pristineNewConfigValue = true;
		},

		onNewConfigValChange ({ value, key, path }) {
			this.pristineNewConfigValue = false;

			// HACK -- no idea why but have to enforce object being an object when adding new one
			this.newConfigVal = includes(['object', 'booleanObject'], this.newConfigType) &&
				!isObject(value) ?
				{ [replace(path, `${key}.`, '')]: value } :
				value;
		},

		addConfigKey () {
			this.$store.dispatch('cards/setTileConfigValue', {
				path: this.newConfigKey,
				value: this.newConfigValue
			});
			this.newConfigKey = '';
		},

		removeConfigKey (key) {
			this.$store.dispatch('cards/removeTileConfigKey', { key });
		},

		typeLabel (value) {
			return this.configTypes[valueType(value)].label;
		},

		copyToClipboard () {
			const textarea = document.createElement('textarea');
			textarea.innerHTML = this.tileJSON;
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			document.body.removeChild(textarea);
			cwalert.success(t('JSON configuration copied to clipboard'));
		},

		reserved: (key) => includes(reservedKeys, key)
	}
};
</script>
