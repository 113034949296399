import { View } from 'backbone';
import $ from 'jquery';
import renderPackageInfo from './elements/package-list-item';
import filterPackages from './elements/filter-packages';
import AsyncWorker from '../helpers/async-worker';
import StorePackages from '../../entities/packages';
import t from 'translate';
import { noop } from 'lodash';
import { READ } from 'service/acl/access-levels';

const maxItemCount = 5;

export default View.extend({
	title: t('system-manager.AvailablePackages'),
	acl: [{
		checkpoint: 'system.packages.packages',
		op: READ
	}],

	init () {
		this.worker = new AsyncWorker(renderPackageInfo);
	},

	render () {
		this.packages = new StorePackages([], { 'sort-by': '%name% (%code%)' });
		this.setLoading();
		this.packages.fetch().then(() => {
			this.loaded();
		});

	},

	loaded () {
		this.setLoaded();
		const tile = this;

		this.sortedPackages = this.packages.models;

		this.cardContext().get('filters').on('change', () => {
			tile.worker.cancel();
			tile.clear();
			tile.filter(); // eslint-disable-line lodash/prefer-lodash-method
		});

		this.filter();  // eslint-disable-line lodash/prefer-lodash-method
	},

	filter () {
		this.renderTheList(filterPackages(this.cardContext().get('filters'), this.sortedPackages));
	},

	noMatch () {
		$(`<p>${t('store.no-packages-match-the-query')}</p>`).appendTo(this.el);
	},

	renderTheList (packages) {
		if (!packages.length) {
			return this.noMatch();
		}
		const $container = $('<div class="store-table"><ul /></div>').appendTo(this.el);

		// eslint-disable-next-line lodash/prefer-lodash-method
		this.worker.consume(packages.slice(0, maxItemCount), $container.find('ul')[0]);
		this.worker.done(noop);

		const moreResults = packages.slice(maxItemCount);

		if (moreResults.length) {
			const $buttonContainer = $('<div />')
				.addClass('see-more store-packages')
				.appendTo(this.$el);

			return $('<button />')
				.appendTo($buttonContainer)
				.addClass('store__more-results')
				.html(t('store.see-all-results', {
					count: moreResults.length
				}))
				.on('click', () => {
					// eslint-disable-next-line lodash/prefer-lodash-method
					this.worker.consume(moreResults, $container.find('ul')[0]).done(noop);
					$buttonContainer.empty().detach();
				});
		}

		return {};
	}
});

