import { Model, Collection } from 'backbone';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import CWTable from 'components/table/table';
import cwalert from 'cwalert';
import formView from 'components/form-view/form-view';
import repository from 'repository';
import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { map, assign, get, find, filter, isUndefined } from 'lodash';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

const respondentIdPath = 'respondent.id';

export default TileView.extend({
	title: t('Clinicians denied access to this treatment'),
	actions: ['deniedClinicians/init'],

	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_DENIED,
		op: READ
	}],

	tileData: ({ tile }) => ({
		limit: tile.config().limit || 10,
		roles: filter(store.getters['treatment/roles'], ['treatmentTypeRole.type', 'RESPONDENT']),
		clinicians: new Collection()
	}),
	loaded: ({ tile }) => {
		tile.clinicians.reset(store.state.deniedClinicians.data);
		const dummyModel = new Model().set(
			'respondent',
			get(store.state.treatment.data, 'respondent.displayName')
		);
		const className = `
			search-results-list__entry clinicians-list__entry clinicians-list__clinician-full-name
			search-results-list__entry--main
		`;
		const roles = map(tile.roles, (item) => assign({}, item, {
			roleLabel: `${item.treatmentTypeRole.name} - ${item.respondent.displayName}`
		}));
		dummyModel.listenTo(dummyModel, 'change:clinicianId', () => {
			tile.form.disableSubmit();

			if (!isUndefined(dummyModel.get('clinicianId'))) {
				tile.form.enableSubmit();
			}
		});
		tile.form = formView({
			model: dummyModel,
			preventSave: true,
			listenTo: ['submit'],
			loader: false,
			disabled: !can.add(CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_DENIED),
			onAfterSave () {
				tile.setLoading();
				const clinicianId = dummyModel.get('clinicianId');
				const respondentId = dummyModel.get('roleRespondentId');

				const denialExists = find(
					store.getters['deniedClinicians/deniedClinicians'],
					({ administrator, respondent }) =>
						administrator.administratorId === clinicianId &&
						+respondent.id === respondentId
				);

				if (denialExists) {
					cwalert.notice(t(`denialList.denialExistForRespondent`));
					tile.setLoaded();
				} else {
					store.dispatch('treatment/deniedClinicians/add', dummyModel.toJSON())
						.then(() => {
							cwalert.success(t(`denialList.addedClinician`));
							tile.setLoaded();
							dummyModel.set('roleRespondentId', null);
						});
				}
			},
			fields: [{
				key: 'clinicianId',
				type: 'search',
				label: t('Clinician'),
				limit: tile.limit,
				provideResults: (query) => repository.searchClinicians({
					search: query,
					administratorAccountEnabled: true,
					start: 0,
					limit: tile.limit
				}),
				placeholder: t('Type clinician name'),
				itemLabel: (clinician) => clinicianName(clinician.toJSON())
			}, {
				key: 'treatmentRole',
				type: 'select',
				collection: new Collection(roles),
				label: t('Role - Respondent name'),
				labelField: 'roleLabel',
				show: {
					condition: () => store.getters['treatment/roles'].length
				},
				setNullLabel: t('Select role'),
				onChange: () => {
					const dummyRole = dummyModel.get('treatmentRole');
					const roles = store.getters['treatment/roles'];
					const mainRespondentId = get(store.getters.treatment, respondentIdPath);
					const selectedRole = find(roles, {
						id: +dummyRole
					});
					dummyModel.set(
						'roleRespondentId',
						get(selectedRole, respondentIdPath) || mainRespondentId
					);
				}
			}],
			buttons: [{
				caption: t('Add'),
				name: 'save',
				key: 'save',
				type: 'submit',
				disabled: true
			}]
		});
		const tableCfg = {
			parent: tile.el,
			store: {
				items: () => get(store.state, 'treatment.deniedClinicians.data'),
				mutation: ['treatment/deniedClinicians/add', 'treatment/deniedClinicians/remove']
			},
			emptyListMessage: t('denialList.emptyList'),

			columns: [{
				key: 'administrator',
				label: t('Full name'),
				css: `${className}`,
				render: (clinician) => clinicianName(clinician)
			}, {
				label: t('Role'),
				css: `${className}`,
				render () {
					const treatmentRole = find(this.item.treatment.treatmentRoles, (role) =>
						+get(role, respondentIdPath) === +get(this.item, respondentIdPath));

					return get(treatmentRole, 'treatmentTypeRole.name');
				}
			}, {
				key: 'respondent',
				label: t('Respondent'),
				css: `${className}`,
				render: (respondent) => {

					if (store.getters['treatment/roles'].length && respondent !== null) {
						return respondentName(respondent);
					}
					return t('this respondent');
				}
			}],
			actions: {
				delete: !can.remove(CLINICIAN.RESPONDENTS.TREATMENT_CLINICIAN_DENIED) ?
					null :
					(clinician, actions) => {
						confirmation({
							title: t('Remove'),
							icon: 'unlink',
							message: t(`denialList.withdrawClinician`, {
								clinicianName: clinicianName(clinician)
							}),
							warning: true
						}).then(() => {
							store.dispatch('treatment/deniedClinicians/remove', clinician)
								.then(() => {
									cwalert.success(t(`denialList.removedClinician`, {
										clinicianName: clinicianName(clinician)
									}));
								});
						}, () => {
							actions.cancel();
						});
					}
			}
		};
		tile.$el.append(tile.form.$el);
		tile.table = new CWTable(tableCfg);
	}
});
