import TileView from 'tile-view';
import t from 'translate';
import Table from 'components/table/table';
import moment from 'moment';
import cardUrl from 'cwcardurl';
import systemSettings from 'system-settings';
import store from 'store';

export default TileView.extend({
	title: t('Information packages'),
	acl: {
		op: 'read',
		checkpoint: 'administrator.respondents.informationpackages'
	},

	actions: ['contentPackageInstances/init'],

	loaded: ({ tile }) => {
		const cpInstances = store.state.contentPackageInstances.collection;

		tile.table = new Table({
			className: 'information-package-instance-list__table',
			parent: tile.el,
			collection: cpInstances.sortByDate({ desc: true }),
			sortable: true,
			sortOrder: tile.config().tableSort,
			click: (informationPackageInstance) => {
				cardUrl.openCard('content-package-properties', {
					contentPackageInstanceId: informationPackageInstance.getId()
				});
			},
			columns: [{
				label: t('Start date'),
				key: 'startAt',
				className: 'information-package-instance-list__start-date',
				render: (startAt) => tile.getDate({ date: startAt })
			}, {
				label: t('Name'),
				className: 'information-package-instance-list__name',
				key: 'name'
			}, {
				label: t('Status'),
				className: 'information-package-instance-list__state',
				render: (status, { item }) => item.status()
			}, {
				label: t('Role'),
				key: 'roleName',
				className: 'information-package-instance-list__role'
			}, {
				label: t('Respondent'),
				key: 'respondent',
				skip: () => store.getters.respondent && !store.getters.treatment,
				className: 'information-package-instance-list__respondent',
				render: (respondent) => respondent.displayName
			}, {
				label: t('Treatment'),
				key: 'treatmentName',
				skip: () => store.getters.treatment,
				className: 'information-package-instance-list__treatment'
			}, {
				label: t('Type'),
				key: 'typeName',
				skip: () => !systemSettings.getBoolean('ASSESSMENT_TYPES'),
				className: 'information-package-instance-list__type'
			}, {
				label: t('Context'),
				key: 'contextName',
				className: 'information-package-instance-list__context'
			}, {
				label: t('Uncompleted'),
				className: 'information-package-instance-list__remaining-items',
				render: (uncompleted, { item }) => {
					const todo = item.get('elementsTotal') - item.get('elementsCompleted');
					return `${todo}/${item.get('elementsTotal')}`;
				}
			}]
		});
	},

	getDate: ({ date }) => {
		const timezone = store.getters['respondent/timezone'];
		return date ? moment.tz(moment.utc(date), timezone).format(t('date.formats.dateTime')) : '';
	}
});
