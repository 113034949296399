import t from 'translate';
import settings from 'system-settings';
import store from 'store';
import appBar from 'app-bar';
import can from 'acl-can';
import { get } from 'lodash';
import { TREATMENT } from 'store/treatments/treatment';

const card = {
	title: t('Messages'),
	name: 'treatment-messages',
	url: 'treatment/:treatmentId/treatment-messages',

	ctrl: ({ close }, { treatmentId }) => {
		if (!settings.getBoolean('ENABLE_MESSAGING_SYSTEM')) {
			close();
			return;
		}
		// obtain treatment first to get respondentId which is needed for messages retrieve
		return store.dispatch('treatment/init', { treatmentId }).then(() => {
			if (can.add('administrator.respondents.communication') &&
				!get(store.state, 'respondent.data.respondentCommunicationDisabled')) {
				appBar.addCardLink({
					sourceCard: card.name,
					card: 'respondent-new-message',
					title: t('New message'),
					icon: 'add',
					query: {
						treatmentId,
						respondentId: get(store.getters[TREATMENT.MAIN_RESPONDENT], 'id')
					}
				});
			}
		});
	}
};

export { card as TreatmentMessages };
