<template>
	<div>
		<button @click="$emit('remove')">
			{{$t('menuComposer.variantCriteria.removeCriterion')}} <icon name="delete" small />
		</button>
		<select-input
			v-model="selectedTreatmentTypes"
			:translate="false"
			:label="$t('Treatment types')"
			:options="availableTreatmentTypes"
			item-label="name"
			multiple
		/>
	</div>
</template>

<script>
import { includes, filter, map } from 'lodash';

export default {
	props: {
		criteria: {
			type: Object,
			required: true
		}
	},

	computed: {
		treatmentTypes: ({ $store }) => $store.getters['treatmentTypes/sortedByName'],
		availableTreatmentTypes: ({ criteria, treatmentTypes }) => filter(
			treatmentTypes,
			(tt) => !includes(criteria.treatmentTypeId, tt.id)
		),

		selectedTreatmentTypes: {
			get: ({ criteria, treatmentTypes }) => filter(
				treatmentTypes,
				(tt) => includes(criteria.treatmentTypeId, tt.id)
			),

			set (treatmentTypes) {
				this.$emit('update', {
					...this.criteria,
					treatmentTypeId: map(treatmentTypes, 'id')
				});
			}
		}
	}
};
</script>
