import { render, staticRenderFns } from "./index.vue?vue&type=template&id=14d50008&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/hdd1/gitlab-runner/builds/e07c1ccc/1/frontend/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14d50008')) {
      api.createRecord('14d50008', component.options)
    } else {
      api.reload('14d50008', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=14d50008&", function () {
      api.rerender('14d50008', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/scripts/modules/treatment-programs/tiles/add-treatment-program/index.vue"
export default component.exports