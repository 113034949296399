import { GET } from 'service/ajax/ajax';
import { assign } from 'lodash';

/*
	This function is used in many methods for get requests.
	In order not to duplicate it each time moved to the shared directory.
*/
export default (url, separator = '?') => {
	const request = GET({ url, separator });
	const response = assign({}, { data: [] }, request.then((json) => {
		response.data = json;
		return request;
	}));
	return response;
};
