import mixin from './_mixin';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

export default (modalCfg) => new Vue({
	mixins: [mixin(modalCfg)],
	data: {
		codes: modalCfg.codes,
		updating: false
	},
	computed: {
		codeOptions () {
			return this.selectOptions(this.codes);
		}
	},
	template: `
		<div>
			<standard-dialog
				:config="config"
				:codeOptions="codeOptions"
				:codes="codes"
				:processing="processing"
				:updating="updating"
				:vuelidate="$v"
			></standard-dialog>
		</div>
		`,
	validations: {
		item: {
			code: {
				required
			}
		}
	}
});
