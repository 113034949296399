import ContentPackageInstance from 'repo/content-package-instances/cp-instance';
import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import moment from 'moment';
import repository from 'repository';
import settings from 'system-settings';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import warning from 'modules/treatments/components/warning';
import handleReady from 'modules/treatments/shared/handle-ready';
import { respondentName } from 'service/display-name/display-name';
import { Collection } from 'backbone';
import {
	assign, concat, defer, filter, get, isFinite, reduce, sortBy, split, toLower
} from 'lodash';
import redirectOnSave from 'modules/general/shared/redirect-on-save';

const RESPONDENT_ID = 'respondent/respondentId';

const contentPackagesByTreatment = (allContentPackages) => {
	const contentPackages = new Collection(allContentPackages.getNotUnpublished());

	return ({ treatments, treatmentId, treatment }) => {
		if (!treatmentId) {
			return new Collection();
		}

		const currentTreatment = treatment || treatments.get(treatmentId);
		const treatmentTypeId = get(currentTreatment.get('treatmentType'), 'id');
		const cPsInTreatmentType = filter(
			contentPackages.models, (contentPackage) =>
				get(contentPackage.get('treatmentType'), 'id') === treatmentTypeId
		);
		const cPsWithoutTreatmentType = contentPackages.where({ treatmentType: null });

		return sortBy(
			concat(cPsInTreatmentType, cPsWithoutTreatmentType), (contentPackage) =>
				toLower(contentPackage.get('name'))
		);
	};
};

const dummyModel = () => {
	const initialDate = get(store.getters.urlParams, 'datetime');
	const startTime = split(settings.getString('DEFAULT_ASSESSMENT_TIME'), /:/);
	const startTimestamp = moment(initialDate).hours(startTime[0]).minutes(startTime[1]).unix();

	return new ContentPackageInstance({
		context: null,
		type: null,
		startAt: datetime(startTimestamp * 1000).toObject(),
		respondent: store.getters[RESPONDENT_ID],
		treatment: get(store.getters.treatment, 'id')
	});
};

export default TileView.extend({
	title: t('Add content package'),
	acl: [{
		op: 'add',
		checkpoint: 'administrator.respondents.informationpackages'
	}],

	tileData: ({ tile }) => ({
		limit: tile.config().limit || 10,
		currentTreatment: store.state.treatment.model,
		treatments: store.getters[RESPONDENT_ID] ?
			repository.mainRespondentTreatments(store.getters[RESPONDENT_ID]) :
			new Collection(),
		contexts: repository.getContexts(),
		types: repository.getAssessmentTypes(),
		availableContentPackages: repository.availableContentPackages(),
		cpInstance: dummyModel(),
		treatmentRoles: new Collection()
	}),

	init: ({ tile }) => {
		tile.listenTo(tile.cpInstance, 'change:treatment', (cpInstance, treatmentId) => {
			tile.currentTreatment = tile.treatments.get(treatmentId);
		});
	},

	loaded: ({ tile }) => {
		tile.$el.append(warning(tile.treatment));

		tile.currentTreatments = new Collection(
			tile.respondent ?
				tile.treatments.clone().filterEnabledCompleted() :
				[]
		);

		let timezone = (store.getters.respondent || {}).respondentTimezoneName;
		const contentPackages = contentPackagesByTreatment(tile.availableContentPackages);
		const currentContentPackages = () => contentPackages({
			treatmentId: tile.cpInstance.get('treatment'),
			treatment: tile.currentTreatment,
			treatments: tile.currentTreatments
		});

		const currentRole = () => tile.treatmentRoles.get(tile.cpInstance.get('roleId'));

		const fields = [!store.getters[RESPONDENT_ID] && {
			key: '_respondent',
			type: 'search',
			label: t('Respondent'),
			mandatory: true,
			limit: tile.limit,
			provideResults: (search) => repository.searchRespondents({
				search,
				respondentAccountEnabled: true,
				start: 0,
				limit: tile.limit
			}),
			placeholder: t('Type respondent name'),
			itemLabel: (model) => respondentName(model.toJSON()),
			getVal: (respondentId) => +respondentId,
			customize: (view, formView) => {
				formView.on('change:respondentId', (changedModel, changedValue) => {
					const respondents = view.provideResults();
					respondents.then(() => {
						const respondent = respondents.get(changedValue);

						if (changedValue && respondent) {
							timezone = respondent.get('respondentTimezoneName');
						}
					});
				});
			}
		}, !store.getters.treatment && {
			key: 'treatment',
			type: 'select',
			collection: tile.currentTreatments,
			description: t('Select a treatment'),
			label: t('Treatment'),
			readonly: !tile.cpInstance.has('respondentId'),
			mandatory: true,
			getVal: (treatmentId) => +treatmentId,
			customize: (view) => {
				store.getters[RESPONDENT_ID] && tile.searchTreatments({
					tile,
					view,
					respondentId: store.getters[RESPONDENT_ID]
				});

				view.listenTo(
					tile.cpInstance,
					'change:_respondent',
					(changedModel, respondentId) => {
						tile.searchTreatments({ tile, view, respondentId });
					}
				);
			}
		}, {
			key: 'roleId',
			type: 'select',
			label: t('Role'),
			mandatory: true,
			value: null,
			setNullLabel: t('Select role'),
			collection: tile.treatmentRoles,
			getVal: (roleId) => +roleId,
			show: {
				listenTo: tile,
				event: 'treatment-roles.reset',
				condition: () => tile.treatmentRoles.length
			}
		}, {
			key: 'informationPackage',
			label: t('Information package'),
			type: 'select',
			mandatory: true,
			description: t('Select information package…'),
			collection: new Collection(currentContentPackages()),
			disabled: !tile.cpInstance.has('treatment'),
			getVal: (contentPackageId) => +contentPackageId,
			customize: (view) => {
				const description = view.model.get('description');

				const populate = () => {
					if (!tile.cpInstance.has('treatment')) {
						return;
					}
					view.setInitial();
					view.collection.reset(currentContentPackages());
					view.model.set('disabled', !view.collection.size());
					view.setReadOnly(!view.collection.size());

					if (!view.collection.size()) {
						tile.cpInstance.set('informationPackage', null);
						view.model.set('description',
							t('No information packages for selected treatment'));

					} else {
						view.model.set({ description });
					}
				};
				defer(populate);
				!store.getters.treatment &&
				view.listenTo(tile.cpInstance, 'change:treatment', populate);
				view.listenTo(tile.cpInstance, 'change:informationPackage', view.setInitial);
			}
		}, {
			key: 'type',
			label: t('Type'),
			type: 'select',
			collection: tile.types,
			labelField: 'label',
			show: {
				condition: () => settings.getBoolean('ASSESSMENT_TYPES')
			},
			setNullLabel: t('-- no type --')
		}, {
			key: 'context',
			label: t('Context'),
			type: 'select',
			collection: tile.contexts,
			keyField: 'assessmentInstanceContextId',
			labelField: 'assessmentInstanceContextLabel',
			setNullLabel: t('-- no context --')
		}, {
			label: t('Start date'),
			key: 'startAt',
			type: 'datetime',
			mandatory: true
		}];

		tile.form = formView({
			name: 'add-content-package',
			model: tile.cpInstance,
			listenTo: ['submit'],
			beforeSave () {
				tile.cpInstance.setStartDate(tile.cpInstance.get('startAt'), timezone);

				const roleAssigned = this.model.get('roleId') &&
					currentRole() &&
					currentRole().get('respondent') &&
					+currentRole().get('respondent').id !== +this.model.get('respondent');

				tile.cpInstance.set('roleId', +tile.cpInstance.get('roleId'));

				if (roleAssigned) {
					tile.cpInstance.set('roleId', +tile.cpInstance.get('roleId'));
					this.model.set(
						'respondent',
						currentRole().get('respondent').id,
						{ silent: true }
					);

				} else {
					const respondentId = store.getters[RESPONDENT_ID] ||
						tile.cpInstance.get('_respondent');
					tile.cpInstance.set('respondent', respondentId);
				}
			},
			onAfterSave () {
				const contentPackageInstanceId = tile.cpInstance.getId();

				tile.onTreatmentChange({ tile, model: this.model });
				tile.cpInstance.unset('id');

				redirectOnSave({
					config: get(tile.config(), 'redirectOnSave', {}),
					fallback: ['content-package-properties', { contentPackageInstanceId }],
					otherwise: () => {
						// disable submit, user have to change something
						tile.form.disableSubmit();
					}
				});
			},
			fields,
			buttons: [{
				caption: t('Add'),
				type: 'submit',
				name: 'add-content-package-submit',
				role: 'submit'
			}]
		});

		tile.cpInstance.on('change', () => {
			tile.handleComplete({ tile });
		});

		tile.setRoles({ tile, model: tile.cpInstance });
		tile.onTreatmentChange({ tile, model: tile.cpInstance });

		handleReady(tile);
		tile.handleComplete({ tile });
	},

	handleComplete: ({ tile }) => {
		if (tile.treatment && tile.treatment.get && !tile.treatment.get('complete')) {
			tile.form.disableSubmit();

		} else {
			tile.form.enableSubmit();
		}
	},

	searchTreatments ({ tile, view, respondentId }) {
		if (!isFinite(+respondentId)) {
			view.model.set('disabled', true).set('value', null);
			return view.collection.reset();
		}

		view.setLoading();
		store.dispatch('treatments/initForMainRespondent', { respondentId }).then(() => {
			view.setLoaded();
			tile.currentTreatments.reset(store.getters['treatments/ready']);

			const isSingleTreatment = tile.currentTreatments.size() === 1;

			if (isSingleTreatment) {
				tile.cpInstance.set('treatment', tile.currentTreatments.models[0].get('id'));
				view.setInitial();
			}

			!tile.currentTreatments.size() && view.model.set('value', null);

			view.model.set('disabled', isSingleTreatment);
			view.setReadOnly(isSingleTreatment);
		});
	},

	setRoles: ({ tile, model }) => {
		model.set('roleId', null);
		const treatment = tile.currentTreatment ||
			tile.currentTreatments.get(model.get('treatment'));
		const treatmentRoles = treatment &&
			treatment.get('treatmentRoles') &&
			reduce(treatment.get('treatmentRoles'), (memo, role) => {
				let name = `${role.treatmentTypeRole.name} `;

				if (role.respondent) {
					name += role.respondent.isAccessible ?
						`[${respondentName(role.respondent)}]` :
						t('<no access to respondent>');
					memo.push(assign({}, role, { name }));
				}

				return memo;
			}, []);

		tile.treatmentRoles.reset(treatmentRoles || []);
		tile.trigger('treatment-roles.reset');
	},

	onTreatmentChange: ({ tile, model }) => {
		tile.listenTo(model, 'change:treatment', () => {
			tile.setRoles({ tile, model });
			tile.handleComplete({ tile });
		});
	}
});
