import t from 'translate';

export const CLINICIAN_LABELS = () => ({
	mismatched: t('cardConfig.healthCheck.mismatchedClinician'),
	wrongPortal: t('cardConfig.healthCheck.wrongPortalClinician')
});

export const RESPONDENT_LABELS = () => ({
	mismatched: t('cardConfig.healthCheck.mismatchedRespondent'),
	wrongPortal: t('cardConfig.healthCheck.wrongPortalRespondent')
});

export const ASSESSMENT_LABELS = () => ({
	mismatched: t('cardConfig.healthCheck.mismatchedAssessment'),
	wrongPortal: t('cardConfig.healthCheck.wrongPortalAssessment')
});

export const ATOMIC_LABELS = () => ({
	mismatched: t('cardConfig.healthCheck.mismatchedAtomic'),
	wrongPortal: t('cardConfig.healthCheck.wrongPortalAtomic')
});

export const EMPTY_CARD_INFO = (card) => `
	${t('cardConfig.healthCheck.willBeRemoved')}
	<pre>${JSON.stringify(card, null, 4)}</pre>
`;

export const HEALTH_STATUS = (bool) => ({
	true: t('cardConfig.healthCheck.configHealthy'),
	false: t('cardConfig.healthCheck.configNotHealthy')
}[bool]);

export const TOGGLE = (bool, count) => ({
	true: t('cardConfig.healthCheck.hideItems', { count }),
	false: t('cardConfig.healthCheck.showItems', { count })
}[bool]);
