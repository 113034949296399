<template>
	<assign-items
		:can-add="canEdit"
		:can-remove="canEdit"
		:fields="fields"
		:labels="labels"
		:getters="getters"
		:actions="actions"
	/>
</template>

<script>
import t from 'translate';
import can from 'service/acl/acl-can';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default {
	title: t('Clinician groups that have access to this treatment type'),
	acl: {
		checkpoint: SYSTEM.SETTINGS.TREATMENT_TYPES,
		op: READ
	},

	actions: () => [['clinicianGroups/initAll']],

	data: () => ({
		canEdit: can.edit(SYSTEM.SETTINGS.TREATMENT_TYPES),
		fields: ['name'],
		labels: {
			addSuccess: t('Clinician group added successfully'),
			empty: t('There are no clinician groups selected'),
			name: t('Clinician group'),
			removeItem: t('Remove the clinician group'),
			removeQuestion: t('Are you sure you wish to remove the clinician group'),
			removeSuccess: t('Clinician group removed successfully'),
			selectItem: t('- select clinician group to add -')
		},
		getters: {
			allItems: 'clinicianGroups/all',
			items: 'treatmentType/clinicianGroups'
		},
		actions: {
			addItem: 'treatmentType/addAndRefreshClinicianGroup',
			removeItem: 'treatmentType/deleteAndRefreshClinicianGroup'
		}
	})
};
</script>
