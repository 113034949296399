import CardDialog from 'service/card-dialog/card-dialog';
import cardUrl from 'cwcardurl';
import cwalert from 'cwalert';
import store from 'store';
import t from 'translate';
import Vue from 'vue';
import { closeDialog } from 'service/form-view-dialog';
import { maxLength, required } from 'vuelidate/lib/validators';
import { reduce } from 'lodash';

export default ({ consent, action, successMsg, title, done }) => {
	const dialog = new Vue({
		el: document.createElement('div'),
		store,
		data: {
			action,
			done,
			successMsg,
			template: '',
			version: '',
			id: consent.id || '',
			loading: false,
			name: consent.name || '',
			new: !consent.id,
			type: consent.type || 'BLANK',
			versionLoading: true
		},
		computed: {
			processing: () => t('Processing data'),
			templates: ({ $store }) => $store.state.consents.consents,
			versions: ({ $store }) => $store.state.consent.version,
			nameTooLong () {
				return this.name.length > 60;
			},
			invalidName: ({ $v }) => $v.name.$error,
			errors () {
				const errors = [{
					rule: this.$v.name.required,
					hint: t('consents.nameInvalidRequired')
				}, {
					rule: this.$v.name.maxLength,
					hint: t('consents.nameInvalidMaxlength')
				}];

				return reduce(errors, (memo, { rule, hint }) => {
					!rule && memo.push(hint);
					return memo;
				}, []);
			}
		},
		methods: {
			t,
			closeDialog (action, message) {
				this.setLoading(false);

				if (message) {
					closeDialog();
					this[action]();
					cwalert.success(t(message));
				}
			},
			onCancel () {
				closeDialog();
			},
			setLoading (bool) {
				this.loading = bool;
			},
			submitConsent () {
				this.$v.$touch();

				if (!this.$v.$invalid) {
					this.setLoading(true);

					if (this.type === 'COPY') {
						const { template: consentId, name, version } = this;
						store.dispatch('consent/copyConsent', { consentId, name, version })
							.then(({ id: consentId }) => {
								cwalert.success(t('Consent has been copied'));
								cardUrl.openCard('edit-consent', { consentId });
							});
						closeDialog();
					} else {
						const data = { name: this.name, selected: this.selected };
						data.id = !this.new ? this.id : null;
						store.dispatch(this.action, data)
							.then((response) => {
								cwalert.success(this.successMsg);

								if (this.done) {
									this.done(response.id);
								}
								closeDialog();
							});
					}
				}
			},
			getVersion () {
				this.versionLoading = true;
				const consentId = this.template;
				store.dispatch('consent/setConsentVersion', consentId).then(() => {
					this.versionLoading = false;
				});
			}
		},
		template: `
		<div>
			<form class="consent" @submit.prevent="submitConsent">
				<div class="consents-dialog__field-container consents-dialog--mandatory">
					<text-input
						class-prefix="consents-dialog"
						v-model="name"
						:label="t('Name')"
						:mandatory="true"
						:invalid="invalidName"
					>
						<template v-if="invalidName" #validations>
							<p v-for="error in errors">
								{{ error }}
							</p>
						</template>
					</text-input>
				</div>
				<div
					class="consents-dialog__field-container
					consents-dialog__field-container--radio
					consents-dialog--mandatory">
					<div class="consents-dialog__labels-group-container">
						<label class="consents-dialog__label-container">
							<input type="radio"
								v-model="type"
								class="consents-dialog--radio"
								value="BLANK"
							/>
							<p class="
								consents-dialog__label-radio
								consents-dialog__label-text
							" v-translate>Start from a blank document</p>
						</label>
						<label class="consents-dialog__label-container">
							<input type="radio"
								v-model="type"
								class="consents-dialog--radio"
								value="COPY"
							/>
							<p class="
								consents-dialog__label-radio
								consents-dialog__label-text
							" v-translate>Start by copying contents from another consent</p>
						</label>
					</div>
				</div>
				<div class="consents-dialog__field-container" v-if="type === 'COPY'">
					<div class="consents-dialog__select">
						<label>Consent</label>
						<select
							class="consents-dialog__input"
							@change="getVersion"
							v-model="template">
						  <option disabled value="">Select consent template...</option>
						  <option
						  	v-for="template in templates"
						  	:value="template.id"
						  	:key="template.id"
						  >{{ template.name }}</option>
						</select>
					</div>
					<div class="consents-dialog__select">
						<label>Older versions</label>
						<select :disabled="!template || versionLoading"
							class="consents-dialog__input"
							v-model="version"
						>
						  <option value="">Working copy</option>
						  <option v-for="version in versions"
							  :value="version.id"
							  :key="version.id"
						  >{{ version.version }}</option>
						</select>
					</div>
				</div>
				<div class="consents-dialog__buttons-container">
					<div class="
						consents-dialog__field-container
						consents-dialog__field-container--button
					">
						<button
							:disabled="invalidName"
							class="consents-dialog__button consents-dialog__button--submit"
							type="submit"
							v-translate
						>Create</button>
					</div>
				</div>
			</form>
			<div v-if="loading"
				v-loader-spinner
				class="consents-dialog consents-dialog__loader"
			>{{ processing }}</div>
		</div>
		`,
		validations: {
			name: { maxLength: maxLength(60), required }
		}
	});
	return new CardDialog({ preventFocusTrap: true, title: t(title), content: dialog.$el });
};
