var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "treatment-attribute__container",
      class: _vm.additionalStates
    },
    [
      _c("div", { staticClass: "treatment-attribute__title-container" }, [
        _vm.title
          ? _c("p", { staticClass: "treatment-attribute__title" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.subtitle
          ? _c("p", { staticClass: "treatment-attribute__subtitle" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.subtitle) + "\n\t\t")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "treatment-attribute__value" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.displayValue) + "\n\t")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }