<template>
	<div class="abuse-report">
		<section class="abuse-report__section">
			<h2 class="abuse-report__header">
				{{t('Reported abuse details')}}
			</h2>
			<ul class="abuse-report__details">
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Reported user')}}
					</p>
					<avatar-nickname
						v-if="currentReport.circleUser"
						:user="currentReport.circleUser"
					/>
				</li>
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Reported date')}}
					</p>
					<p class="abuse-report__detail-description">
						<time :datetime="currentReport.reportedAt">
							{{reportedDate}}
						</time>
					</p>
				</li>
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Reported by')}}
					</p>
					<avatar-nickname
						v-if="currentReport.reportedBy"
						:user="currentReport.reportedBy"
					/>
				</li>
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Message that was reported')}}
					</p>
					<p
						class="abuse-report__detail-description"
						v-html="escapedContent"
					/>
				</li>

				<li v-if="contentChanged || postDeleted" class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Actual post content')}}
					</p>
					<p
						v-if="postDeleted"
						class="
							abuse-report__detail-description
							abuse-report__detail-description--informative
						"
					>{{t('Post has been removed')}}</p>
					<p
						v-else-if="contentChanged"
						class="abuse-report__detail-description"
						v-html="escapedPostContent"
					/>
				</li>

				<li v-if="currentReport.circlePost" class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Posted date')}}
					</p>
					<p class="abuse-report__detail-description">
						<time :datetime="currentReport.circlePost.createdAt">
							{{postedDate}}
						</time>
					</p>
				</li>
			</ul>
		</section>

		<section class="abuse-report__section">
			<h2 class="abuse-report__header">
				{{t('Circle details')}}
			</h2>
			<ul class="abuse-report__details">
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Circle owner')}}
					</p>
					<avatar-nickname
						v-if="currentReport.circle && currentReport.circle.owner"
						:user="currentReport.circle.owner"
					/>
				</li>
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Circle name')}}
					</p>
					<p class="abuse-report__detail-description">
						{{currentReport.circle ? currentReport.circle.name : ''}}
					</p>
				</li>
				<li class="abuse-report__detail">
					<p class="abuse-report__detail-name">
						{{t('Circle description')}}
					</p>
					<p class="abuse-report__detail-description">
						{{currentReport.circle ? currentReport.circle.description : ''}}
					</p>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
import t from 'service/lang/translate';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import avatarNickname from '../../components/avatar-nickname';
import { mapState } from 'vuex';
import datetime from 'service/datetime/datetime';
import store from 'store';
import escape from '../../helpers/escape-content';
import { get } from 'lodash';

export default {
	title: t('Abuse report details'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	features: enableCirclesCheck,
	instant: true,

	components: { ...avatarNickname },
	computed: {
		...mapState('circles/abuseReports', ['currentReport']),
		reportedDate () {
			return datetime(this.currentReport.reportedAt)
				.setTimezone(store.state.user.timezoneName)
				.toMoment()
				.format(t('date.formats.dateTime'));
		},

		postedDate () {
			return datetime(this.currentReport.circlePost.createdAt)
				.setTimezone(store.state.user.timezoneName)
				.toMoment()
				.format(t('date.formats.dateTime'));
		},

		escapedContent () {
			return escape(this.currentReport.content);
		},

		escapedPostContent () {
			return escape(
				this.currentReport.circlePost ?
					this.currentReport.circlePost.content :
					''
			);
		},

		contentChanged () {
			return this.currentReport.postContent !== this.currentReport.circlePost ?
				get(this.currentReport, 'circlePost.content', '') :
				'';
		},

		postDeleted () {
			return this.currentReport.circlePost ?
				this.currentReport.circlePost.isDeleted :
				true;
		}
	}
};
</script>
