<template>
	<div>
		<text-input
			v-model.trim="form.name"
			:translate="false"
			:label="$t('treatmentProgram.name')"
			required
			mandatory
			:invalid="$v.form.name.$error"
			@input="onInput"
		>
			<template v-if="$v.form.name.$error" #validations>
				<p v-if="!$v.form.name.required">
					{{$t('general.validation.mandatory')}}
				</p>
			</template>
		</text-input>

		<template v-if="editing">
			<text-input
				v-model.trim="form.description"
				:translate="false"
				:label="$t('treatmentProgram.description')"
				@input="onInput"
			/>

			<media-search
				v-model="form.cover"
				:label="$t('treatmentProgram.cover')"
				:mime-types="allowedCoverTypes"
				@input="onInput"
			/>
		</template>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import TextInput from 'components/form/text-input';
import MediaSearch from 'modules/media/components/media-search';

export default {
	components: {
		TextInput,
		MediaSearch
	},

	props: {
		value: {
			type: Object,
			required: true
		},

		editing: {
			type: Boolean,
			default: false
		}
	},

	data: ({ value }) => ({
		form: { ...value },
		allowedCoverTypes: [
			'image/jpeg',
			'image/png',
			'image/gif'
		]
	}),

	methods: {
		validate () {
			this.$v.$touch();

			return !this.$v.$invalid;
		},

		onInput () {
			this.$emit('validate', this.validate());
			this.$emit('input', this.form);
		}
	},

	validations: {
		form: {
			name: {
				required
			}
		}
	}
};
</script>
