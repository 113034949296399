import EntityModel from 'repo/apps/activated-app';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';
import appContext from 'app-context';
import cwalert from 'cwalert';

export default function (app = {}) {
	const appModel = new EntityModel(app);
	const isNew = appModel.isNew();

	const formConfig = {
		name: 'name',
		model: appModel,
		preventSave: true,
		onAfterSave () {
			// because b💩ckend
			appModel.set({
				app: { id: +appModel.get('clientId') }
			});
			this.setLoading();

			const action = isNew ? 'apps/activate' : 'apps/update';
			store.dispatch(action, { model: appModel }).then(() => {
				appContext.trigger('dialog:close');
				cwalert.success(isNew ? t('Successfully added') : t('Successfully saved'));
			}, () => {
				this.setLoaded();
				cwalert.error(t('Error'));
			});
		},
		fields: [isNew ?
			{
				label: t('App'),
				key: 'clientId',
				type: 'select',
				values: store.getters['apps/available'],
				mandatory: true,
				readonly: !store.getters['apps/available'].length,
				description: store.getters['apps/available'].length ?
					t('Select App\u2026') :
					t('No Apps available for connection')
			} :
			{
				label: t('App'),
				key: 'name',
				type: 'paragraph'
			},
		{
			label: t('Restrict to treatment types'),
			key: 'treatmentTypes',
			type: 'multiselect',
			values: store.state.treatmentTypes.data,
			description: t('Select treatment types\u2026'),
			header: t('Select treatment types')
		}
		]
	};

	const dialogConfig = {
		title: isNew ? t('Connect App') : t('App connection details')
	};

	formViewDialog({ formConfig, dialogConfig });
}
