<script>
import assessment from './assessment-wizard';
import treatment from './treatment-wizard';
import respondent from './respondent-wizard';
import { assign, map } from 'lodash';

export default {
	components: {
		assessment,
		treatment,
		respondent
	},

	props: {
		type: {
			type: String,
			required: true
		},
		show: {
			type: Boolean,
			required: true
		},
		onClose: {
			type: Function,
			required: true
		}
	},

	data () {
		return {
			stage: '',
			title: {
				assessment: 'Assessment data wizard',
				treatment: 'Treatment data wizard',
				respondent: 'Respondent data wizard'
			}[this.type]
		};
	},

	computed: {
		modalClass () {
			const classes = [];

			if (this.typeRespondent) {
				classes.push('export-template-tile__respondent-wizard');
			}

			if (this.stage === 'identity') {
				classes.push('modal-dialog--overflow');
			}
			return classes.join(' ');
		},
		typeRespondent () {
			return this.type === 'respondent';
		}
	},
	methods: {
		onStageChange (stage) {
			this.stage = stage;
		},

		onSubmit (data) {
			// default value must be placed as the label field to pass validation
			this.$emit(
				'onSubmit',
				map(data, (element) => assign({ label: element.identifierLabel }, element))
			);
			this.onClose();
		}
	}
};
</script>

<template>
	<modal-dialog
		:on-close="onClose"
		:show="show"
		:class-name="modalClass"
		:custom-focus="true"
	>
		<template #title>
			<span v-translate>{{title}}</span>
		</template>

		<template #content>
			<component
				:is="type"
				@stage-change="onStageChange"
				@submit="onSubmit"
			/>
		</template>
	</modal-dialog>
</template>
