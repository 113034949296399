import shared from './shared';
import adapters from 'modules/adapters/adapters';
import assessment from './assessment/clinician-assessment';
import assessmentRuntime from './assessment-runtime/clinician-assessment-runtime';
import cardConfigurator from './card-configurator/card-configurator';
import circles from './circles/clinician-circles';
import clinician from './clinician/clinician';
import clinicianManagement from './clinician-management/clinician-management';
import consents from './consents/cp-consents';
import contentPackages from './content-package/content-package';
import dataIntegration from './data-integration/data-integration';
import decision from './decision-instance/decision-instance';
import exportTemplates from './export-template/export-template';
import goals from './goals/clinician-goals';
import flags from './flags/flags';
import links from './links/clinician-links';
import media from './media/clinician-media';
import messages from './messages/clinician-messages';
import pages from './pages/clinician-pages';
import planInstances from './plan-instance/plan-instance';
import plans from './plans/plans';
import relationTypes from './relation-types/relation-types';
import respondentManagement from './respondent-management/respondent-management';
import store from './store/store';
import appsManagement from './apps-management/clinician-store-apps';
import systemExports from './system-exports/system-exports';
import systemLog from './system-log/system-log';
import systemReports from './system-reports/system-reports';
import systemSettings from './system-settings/system-settings';
import treatments from './treatments/clinician-treatments';
import treatmentPrograms from './treatment-programs/treatment-programs';
import treatmentTypes from './treatment-type/treatment-type';
import videoChat from './video-chat/clinician-video-chat';

export default [
	...shared, adapters, assessment, assessmentRuntime, cardConfigurator, circles, clinician,
	clinicianManagement, consents, contentPackages, dataIntegration, decision, exportTemplates,
	flags, goals, links, media, messages, pages, planInstances, plans, relationTypes,
	respondentManagement, store, appsManagement, systemExports, systemLog, systemReports,
	systemSettings, treatments, treatmentPrograms, treatmentTypes, videoChat
];
