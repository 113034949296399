import t from 'translate';

const card = {
	title: t('Inactive respondents'),
	name: 'system-reports-inactive-respondents',
	url: 'system-reports/inactive-respondents'

};

export { card as inactiveRespondents };
