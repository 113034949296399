import TileView from 'tile-view';
import can from 'acl-can';
import $ from 'jquery';
import t from 'translate';
import formView from 'components/form-view/form-view';
import appContext from 'app-context';
import Assignment from 'components/assignment';
import repository from 'repository';
import systemSettings from 'system-settings';

export default TileView.extend({
	title: t('Assessment details'),
	acl: [{
		checkpoint: 'system.settings.assessments',
		op: 'OPEN'
	}],

	cardData: () => ['assessmentId'],
	tileData: ({ tile }) => ({
		assessment: repository.getAssessmentById(tile.assessmentId),
		clinicianGroups: repository.getClinicianGroups(),
		assessmentToClinicianGroups: repository.getAssessmentClinicianGroups(tile.assessmentId)
	}),

	loaded: ({ tile }) => {
		tile.renderDetails(tile);
		tile.renderGroups(tile);
	},

	renderDetails: (tile) => {
		const previousAttributes = tile.assessment.previousAttributes();

		const formConfig = {
			name: 'edit-assessment',
			model: tile.assessment,
			caption: tile.assessment.displayName(),
			saveIndicator: true,
			errorMessage (model) {
				const result = model.responseJSON;

				if (result.exception) {
					const msg = result.exception.msg ? t(result.exception.msg) : '';
					return `${t('general-list.ChangesNotSaved')} ${msg}`;
				}
				return '';
			},
			onAfterSave: () => {
				appContext.trigger('assessment.change', { assessment: tile.assessment });

				if (
					previousAttributes.assessmentAutospawnIdentifier !==
					tile.assessment.get('assessmentAutospawnIdentifier')
				) {
					appContext.trigger('autospawn.identifier-change');
				}
			},

			readonly: !can.edit('system.settings.assessments'),

			fields: [{
				name: 'assessment-package',
				type: 'paragraph',
				label: t('Package'),
				value: tile.assessment.getPackageName()
			}, {
				key: 'assessmentExportAllowed',
				type: 'checkbox',
				label: t('Export allowed')
			}, {
				key: 'assessmentNoRespondent',
				type: 'checkbox',
				label: t('Clinician only')
			}, {
				key: 'assessmentNonResponsiveUI',
				label: t('Non-responsive layout'),
				type: 'checkbox',
				disabled: !tile.assessment.get('assessmentNonResponsiveUICanChange')
			}, {
				key: 'assessmentAutospawnIdentifier',
				label: t('system-manager.assessment-management.details.autospawn-identifier'),
				type: 'text',
				name: 'assessmentAutospawnIdentifier',
				disabled: !!tile.assessment.get('assessmentNoRespondent'),
				customize: (view, formView) => {
					formView.on('change:assessmentNoRespondent', (model, changedValue) => {
						view.model.trigger('change:disabled', model, changedValue);
					});

				},
				validators: {
					validator: 'regexp',
					args: /^[a-zA-Z0-9._~-]{0,32}$/,
					invalidMessage:
						t('system-manager.assessment-management.details.invalid-identifier')
				},
				max: 32
			}, {
				key: 'assessmentAutospawnUrl',
				type: 'paragraph',
				label: t('Autospawn url'),
				value: tile.assessment.getAutospawnURL(),
				customize (view, formView, formModel) {

					formView.on('formChange:assessmentAutospawnIdentifier', (fieldView) => {
						if (fieldView.getFormValue() === '') {
							formModel.set('assessmentAutospawnUrl', '');

						} else {
							formModel.set(
								'assessmentAutospawnUrl',
								tile.assessment.getAutospawnURL(fieldView.getFormValue())
							);
						}
					});

					formView.on('change:assessmentNoRespondent', () => {
						formModel.set('assessmentAutospawnUrl', tile.assessment.getAutospawnURL());
					});

				}
			}, {
				key: 'assessmentContinueIfStarted',
				label: t('Continue if started'),
				type: 'checkbox',
				disabled: !!tile.assessment.get('assessmentNoRespondent'),
				customize (view, formView) {
					formView.on('change:assessmentNoRespondent', (model, changedValue) => {
						view.model.trigger('change:disabled', model, changedValue);
					});
				}
			}, {
				key: 'showUnansweredQuestionsPopup',
				label: t('Show Unanswered Questions Popup'),
				type: 'checkbox',
				disabled: !systemSettings.getBoolean('ASSESSMENT_POPUPS')
			}, {
				key: 'completionProgressCounterType',
				label: t('Completion progress type'),
				type: 'select',
				setNullLabel: t('Use default'),
				values: [{
					id: 'NONE',
					name: t('Hide visualisation')
				}, {
					id: 'PERCENTAGE',
					name: t('Show percentage')
				}]
			}]
		};

		tile.$el.append(formView(formConfig).$el);
	},

	renderGroups: (tile) => {
		$(`<h2>${t('Clinician groups that have access to this assessment')}</h2>`)
			.appendTo(tile.$el);

		tile.assignment = new Assignment({
			readonly: !can.edit('system.settings.assessments'),
			parent: tile.el,
			relationAttributes: { assessmentId: tile.assessmentId },
			allItemsCollection: tile.clinicianGroups,
			itemPrimaryKey: 'administratorGroupId',
			selectorLabel: t(' - select clinician group to add -'),
			selectorRender: (item) => item.administratorGroupName,
			relationCollection: tile.assessmentToClinicianGroups,
			columns: [{
				key: 'administratorGroupName',
				label: t('Clinician group')
			}],
			emptyListMessage: t(
				'There are no clinician groups selected at this moment.' +
				' All clinicians will have access to this assessment.'
			)
		});
	}
});

