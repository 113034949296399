import { when } from 'jquery';
import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';
import MediaModel from 'repo/media/entity';
import File from 'repo/files/file';
import slugify from 'slugify';
import can from 'acl-can';
import store from 'store';
import { get } from 'lodash';

import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	acl: [{
		op: READ,
		checkpoint: SYSTEM.SETTINGS.MEDIA
	}],

	title: t('Edit media / document'),

	cardData: () => ['mediaModel'],

	tileData: ({ tile }) => ({
		addMedia: !tile.mediaModel
	}),

	getFile ({ tile }) {
		const file = tile.mediaModel.get('file');
		tile.file = file ? repository.getFile(file.id) : new File();

		return tile.file;
	},

	loaded ({ tile }) {
		if (tile.addMedia) {
			tile.setTitle(t('Add media / document'));
			tile.mediaModel = new MediaModel();
		}

		tile.setDataLoading();
		when(tile.getFile({ tile })).then(() => {
			tile.setDataLoaded();

			const disabled = tile.addMedia ?
				!can.add(SYSTEM.SETTINGS.MEDIA) :
				!can.edit(SYSTEM.SETTINGS.MEDIA);
			const formCfg = {
				name: 'edit-page',
				model: tile.mediaModel,
				listenTo: ['submit'],
				saveIndicator: true,
				disabled,

				onAfterSave: () => {
					tile.addMedia && tile.card().close({ openCard: 'media' });
				},

				fields: [{
					key: 'file',
					type: 'file',
					label: t('File'),
					fileModel: tile.file,
					mandatory: true,
					constraints: 'media'
				}, {
					key: 'title',
					type: 'text',
					label: t('Title'),
					mandatory: true
				}, {
					key: 'category',
					type: 'multiselect',
					label: t('Category'),
					collection: get(store.state, 'media.categories.collection'),
					title: t('media')
				}, {
					key: 'description',
					type: 'textarea',
					label: t('Description'),
					mandatory: true
				}, {
					key: 'alias',
					type: 'username',
					variant: 'media',
					propName: 'alias',
					label: t('Permanent link'),
					mandatory: true,
					description: {
						available: t('This alias is available'),
						unavailable: t('This alias is already in use'),
						error: t('Failed to check the alias'),
						progress: t('Checking the alias availability')
					},
					customize: (view) => {
						view.listenTo(this.file, 'change', () => {
							view.model.set('value', slugify(this.file.get('name') || ''));
							view.inputHasChanged();
						});
					}
				}],
				buttons: [{
					name: 'submit',
					type: 'submit',
					caption: tile.addMedia ? t('Add') : t('Save'),
					role: 'submit',
					disabled
				}]
			};

			tile.$el.html(formView(formCfg).$el);
		});
	}
});
