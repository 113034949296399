import t from 'translate';
import { debounce, findIndex, noop, unescape } from 'lodash';

/**
 * Quick-search component.
 *
 * @param {Function} clear - *required - callback to remove all the results.
 * @param {boolean} exceeded - If the total amount is higher than the maximum amount per list.
 * @param {number} excludeId - An indicator if some id should be excluded from the respondent list.
 * @param {Array} list - *required - Array of objects as a result of searching action.
 * @param {boolean} loading - *required - An indicator if a search is being currently in progress.
 * @param {string} placeholder - Placeholder for an input field.
 * @param {Function} getResults - *required - Callback to provide unitary way of data searching.
 * @param {Function} submit - *required - Callback to redirect to a specific search page.
 * @param {number} total - Total amount of results.
 * @param {string} url - Url to allow searching the whole range of results for the specific phrase.
 * @param {boolean} urls - An indicator if an anchor tag should be displayed on the list.
 */

export default {
	name: 'quick-search',
	props: {
		clear: {
			required: true,
			type: Function
		},
		exceeded: {
			type: Boolean
		},
		excludedId: {
			type: Number,
			default: 0
		},
		getResults: {
			required: true,
			type: Function
		},
		list: {
			required: true,
			type: Array
		},
		loading: {
			required: true,
			type: Boolean
		},
		onClick: {
			type: Function,
			default: noop
		},
		placeholder: {
			type: String,
			default: ''
		},
		submit: {
			type: Function,
			default: noop
		},
		total: {
			type: Number,
			default: 0
		},
		url: {
			type: String,
			required: true
		},
		urls: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: null
		}
	},
	data: () => ({
		focusIndex: 0,
		initialised: false,
		phrase: ''
	}),
	computed: {
		exceededIndex () {
			return this.exceeded ? 2 : 1;
		},
		excludedList () {
			if (!this.excludedId) {
				return this.list;
			}
			const currentList = [...this.list];
			const excludedIndex = findIndex(this.list, ['rows[0].id', this.excludedId]);

			if (excludedIndex !== -1) {
				currentList.splice(excludedIndex, 1);
			}
			return currentList;
		},
		incrementFocus () {
			const focusIndex = this.exceeded ? this.focusIndex - 1 : this.focusIndex;
			return this.list.length > focusIndex;
		},
		seeAll () {
			const count = this.total;
			return t('See all ({count}) results', { count });
		},
		showResults () {
			return this.list.length && this.phrase.length;
		},

		buttonTitle: () => t('Go to search page')
	},
	methods: {
		focusDecrement () {
			if (this.focusIndex > 1) {
				this.focusIndex--;
				this.setFocus();
			}
		},
		focusIncrement () {
			if (this.incrementFocus) {
				this.focusIndex++;
				this.setFocus();
			}
		},
		handleClick (val) {
			if (this.onClick) {
				this.onClick(val);
			}
			this.phrase = val.name;
		},
		inputFocus () {
			this.focusIndex = 0;
			this.$refs.searchInput.focus();
		},
		onClear () {
			this.focusIndex = 0;
			this.clear();
			this.inputFocus();
			this.initialised = false;
			this.phrase = '';
			// otherwise form submitted on firefox
			event.preventDefault();
		},

		onInputDebounce: debounce(function () {
			this.initialised = true;
			this.focusIndex = 0;
			this.getResults(this.phrase);
		}, 500),

		onInput (event) {
			this.phrase = event.target.value;

			this.onInputDebounce();
		},

		onSubmit () {
			if (this.submit) {
				this.submit(this.phrase);
			}
		},
		setFocus () {
			const index = `result-${this.focusIndex}`;

			if (this.exceeded && this.focusIndex === 1) {
				this.$refs[index].focus();
			} else {
				this.$refs[index][0].focus();
			}
		},
		unescapeName: (name) => unescape(name)
	},
	template: `
		<form
			class="quick-search__form"
			:class="{ 'quick-search__form--loading': loading }"
			@submit.prevent="onSubmit"
		>
			<div class="quick-search__field-container">
				<input
					ref="searchInput"
					class="quick-search__input"
					type="text"
					:value="phrase"
					:placeholder="placeholder"
					:aria-label="label"
					@input="onInput"
					@keyup.down="focusIncrement"
				/>
				<div v-show="loading" v-loader-spinner:small class="quick-search__loader"></div>
			</div>
			<div class="quick-search__action-container">
				<button
					v-if="initialised"
					tabindex="0"
					class="quick-search__clear"
					type="button"
					@click="onClear"
				>
					<icon
						name="times"
						:small="true"
					/>
				</button>
				<div v-if="!initialised">
					<icon
						className="quick-search__search-icon"
						name="search"
						:small="true"
					/>
					<button
						class="quick-search__action"
						tabindex="0"
						:title="buttonTitle"
						type="submit"
					>
					</button>
				</div>
			</div>
			<ol
				v-if="showResults"
				:class="{ 'quick-search__loading': loading }"
				class="quick-search__results"
				@keyup.down.tab="focusIncrement"
				@keyup.esc="inputFocus"
				@keyup.up="focusDecrement"
			>
				<li v-if="exceeded" class="quick-search__result-container">
					<a v-if="urls"
						class="quick-search__result quick-search__result--all"
						:href="url"
						ref="result-1"
						tabindex="0"
					>{{ seeAll }}</a>
				</li>
				<li v-for="(item, index) in excludedList" class="quick-search__result-container">
					<a v-if="urls"
						class="quick-search__result"
						:href="item.url"
						:ref="'result-' + (index + exceededIndex)"
						tabindex="0"
					>
						<p
							v-for="row in item.rows"
							:class="row.class"
						>{{ unescapeName(row.name) }}</p>
					</a>
					<span v-else>
						<button v-for="row in item.rows"
							:class="row.class"
							class="quick-search__result quick-search__result-button"
							@click="handleClick(row)">{{ row.name }}</button>
					</span>
				</li>
			</ol>
		</form>`
};
