import $ from 'jquery';
import { assign, filter, forEach, includes, split } from 'lodash';
import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import t from 'translate';
import store from 'store';
import { READ } from 'service/acl/access-levels';
import { chooseRoute, inputTypes } from '../../shared';
import { formCfgFactory } from '../../shared/index';

const RESPONDENT_AGE_LIMIT = 'system.respondent.age-limit';

export default TileView.extend({
	className: '',
	title: t('System'),

	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],

	actions: () => ['systemSettings/init'],

	loaded: ({ tile }) => {
		const excludedLabels = [
			'admin.cms.homepage',
			'respondent.cms.homepage',
			'admin.cms.logoutpage',
			'respondent.cms.logoutpage',
			'assessment.cms.logoutpage'
		];
		const formViews = [];
		const settings = filter(store.getters['systemSettings/system'], ((setting) =>
			!includes(excludedLabels, setting.systemSettingLabel)
		));

		// create new FormView instance for each custom attribute
		forEach(settings, (setting) => {
			let values = [];

			forEach({
				EMAIL: setting.systemSettingLabel === 'system.workflow.email.replyTo',
				DROPDOWN: setting.systemSettingSourceId === 'system.i18n.daysOfWeek' ||
					setting.systemSettingSourceId === 'system.mobile.country-prefixes' ||
					setting.systemSettingLabel === 'system.assessment.inheritance-scope'
			}, (condition, newType) => {
				if (condition) {
					setting.systemSettingType = newType;
				}
			});

			let type = inputTypes[setting.systemSettingType] || 'text';

			chooseRoute([{
				when: () => setting.systemSettingSourceId === 'system.mobile.country-prefixes',
				then: () => {
					values = split(setting.systemSettingDescription, ';');
					type = 'select';
				}
			}, {
				when: () => setting.systemSettingSourceId === 'system.i18n.daysOfWeek',
				then: () => {
					values = [{
						0: t('system.settings.daysOfWeek.Monday')
					}, {
						6: t('system.settings.daysOfWeek.Sunday')
					}];
					type = 'select';
				}
			}, {
				when: () => setting.systemSettingLabel === 'system.assessment.inheritance-scope',
				then: () => {
					values = [
						{ TREATMENT: t('Treatment') },
						{ PLAN: t('Plan') }
					];
				}
			}]);

			const cfg = formCfgFactory({ setting, type });

			chooseRoute([{
				when: () => setting.systemSettingLabel === 'system.assessment.defaultTime',
				then: () => {
					assign(cfg.fields[0], {
						required: true,
						type: 'time'
					});
				}
			}, {
				when: () => includes([
					'administrator.personal-settings.password-expire-days',
					'respondent.personal-settings.password-expire-days',
					'administrator.personal-settings.password-expire-days-warning',
					'respondent.personal-settings.password-expire-days-warning',
					RESPONDENT_AGE_LIMIT
				], setting.systemSettingLabel),
				then: () => {
					assign(cfg.fields[0], {
						min: 1,
						max: setting.systemSettingLabel === RESPONDENT_AGE_LIMIT ?
							150 :
							999
					});
				}
			}, {
				when: () => includes([
					'administrator.personal-settings.password-expire-days-warning',
					'respondent.personal-settings.password-expire-days-warning',
					RESPONDENT_AGE_LIMIT
				], setting.systemSettingLabel),
				then: () => {
					const txt = setting.systemSettingLabel === RESPONDENT_AGE_LIMIT ?
						t('years of age') :
						t('days prior to expiration');

					assign(cfg.fields[0], {
						customize (view) {
							const $txtAfterInput = $('<span />')
								.addClass('form-view__label-text')
								.text(` ${txt}`);
							view.ui.input
								.addClass('form-view__input--ultra-narrow')
								.after($txtAfterInput);
						}
					});
				}
			}, {
				when: () => values,
				then: () => {
					cfg.fields[0].values = values;
				}
			}]);

			formViews.push(formView(cfg).$el);
		});

		tile.$el.append(formViews);
	}
});

