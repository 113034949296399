import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';
import formView from 'components/form-view/form-view';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';

export default TileView.extend({
	title: t('Edit link'),
	acl: [{
		checkpoint: SYSTEM.SETTINGS.LINKS,
		op: READ
	}],
	cardData: () => ['id'],

	tileData: ({ tile }) => ({
		link: repository.getLink(tile.id),
		categories: repository.getLinkCategories()
	}),

	loaded: ({ tile, data }) => {
		tile.form = formView({
			name: 'edit-link',
			model: data.link,
			listenTo: ['submit'],
			saveIndicator: true,

			fields: [{
				key: 'name',
				type: 'text',
				label: t('Name'),
				mandatory: true
			}, {
				key: 'url',
				type: 'text',
				label: t('URL'),
				mandatory: true
			}, {
				key: 'category',
				type: 'multiselect',
				label: t('Category'),
				collection: data.categories,
				title: t('link')
			}, {
				key: 'description',
				type: 'textarea',
				label: t('Description'),
				mandatory: true
			}, {
				key: 'openInNewWindow',
				type: 'checkbox',
				label: t('Open in new window')
			}],
			buttons: [{
				name: 'submit',
				type: 'submit',
				caption: t('Save'),
				role: 'submit'
			}]
		});

		tile.$el.html(tile.form.$el);
	}
});
