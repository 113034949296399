import { Model, View } from 'backbone';
import formView from 'components/form-view/form-view';
import $ from 'jquery';
import datetime from 'datetime';
import Url from 'lib/url/url';
import can from 'acl-can';
import t from 'translate';
import { clone } from 'lodash';

export default View.extend({

	title: t('Export assessment plans'),
	acl: [{
		checkpoint: 'system.export.workflows',
		op: 'OPEN'
	}],

	render () {

		const DummyModel = Model.extend({
			defaults: {
				anonymous: true,
				test: false
			},
			sync () {
				const dfd = $.Deferred();
				const params = clone(this.attributes);

				if (this.get('start')) {
					params.start = datetime(this.get('start')).toMoment().unix();
				}

				if (this.get('end')) {
					params.end = datetime(this.get('end')).toMoment().unix();
				}

				const url = new Url(
					`backend-commons/export/rest/workflowInstanceStatus/all?${$.param(params)}`,
					null,
					{
						querySeparator: '',
						prependBackendRoot: 'system'
					}
				);
				url.go();
				dfd.resolve();

				return dfd.promise();
			}
		});

		const cfg = {
			name: 'system-export-administrators',
			model: new DummyModel(),
			listenTo: ['submit'],
			classNames: ['no-fieldset-margin'],
			loader: true,
			squeeze: true,
			fieldsets: [{
				name: 'filter',
				caption: t('system-manger.FilterExport', 'Filter export'),
				fields: ['anonymous']
			}, {
				name: 'datefilters',
				caption: t('system.exports.optional.dateFilter', 'Date range (optional)'),
				fields: ['start', 'end'],
				buttons: ['system-export-submit']
			}],

			fields: [{
				key: 'anonymous',
				type: 'checkbox',
				label: t('Unidentified respondent'),
				disabled: !can.supervise('system.export.workflows')
			}, {
				key: 'start',
				type: 'date',
				label: t('system.exports.dateFilter.StartDate')
			}, {
				key: 'end',
				type: 'date',
				label: t('system.exports.dateFilter.EndDate')
			}],

			buttons: [{
				caption: t('general-list.Export'),
				type: 'submit',
				name: 'system-export-submit',
				role: 'submit'
			}]
		};

		this.$el.append(formView(cfg).$el);
	}
});
