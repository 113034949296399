import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * RoleCheckpointGroup Entity
 * @class RoleCheckpointGroup
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.checkpointGroupInRole.url,
	eventNamespace: 'access-role-checkpoint-group',
	idAttribute: 'relationId',

	defaults: {
		administratorRoleId: null,
		administratorRoleAccessCategoryId: null,
		accessLevelId: null
	}
});
