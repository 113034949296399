var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-check" }, [
    _c(
      "section",
      { staticClass: "config-check__section" },
      [
        _c("h3", [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.t("cardConfig.healthCheck.cards")) +
              "\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("health-indicator", {
          attrs: {
            healthy: _vm.healthyCards,
            toggle: _vm.toggleCards,
            toggled: _vm.showCards,
            count:
              _vm.size("wrongPortalCards") +
              _vm.size("mismatchedCards") +
              _vm.emptyCards.length
          }
        }),
        _vm._v(" "),
        !_vm.healthyCards
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCards,
                    expression: "showCards"
                  }
                ]
              },
              [
                _vm._l(_vm.types, function(type, i) {
                  return _c("check-items", {
                    key: i + "mismatched",
                    attrs: {
                      items: _vm.mismatchedCards[type.name],
                      description: type.label.mismatched,
                      info: _vm.info("mismatchedCard"),
                      solve: _vm.solve({
                        issue: "mismatchedCard",
                        portal: type.name
                      })
                    }
                  })
                }),
                _vm._v(" "),
                _vm._l(_vm.types, function(type, i) {
                  return _c("check-items", {
                    key: i + "wrongPortal",
                    attrs: {
                      items: _vm.wrongPortalCards[type.name],
                      description: type.label.wrongPortal,
                      info: _vm.info("wrongPortalCard"),
                      solve: _vm.solve({
                        issue: "wrongPortalCard",
                        portal: type.name
                      })
                    }
                  })
                }),
                _vm._v(" "),
                _c("check-items", {
                  attrs: {
                    items: _vm.emptyCards,
                    description: "cardConfig.healthCheck.emptyCards",
                    info: _vm.emptyCardInfo,
                    solve: _vm.solve({ issue: "emptyCard" })
                  }
                })
              ],
              2
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "config-check__section" },
      [
        _c("h3", [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.t("cardConfig.healthCheck.tiles")) +
              "\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c("health-indicator", {
          attrs: {
            healthy: _vm.healthyTiles,
            toggle: _vm.toggleTiles,
            toggled: _vm.showTiles,
            count: _vm.mismatchedTiles.length
          }
        }),
        _vm._v(" "),
        !_vm.healthyTiles
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTiles,
                    expression: "showTiles"
                  }
                ]
              },
              [
                _c("check-items", {
                  attrs: {
                    items: _vm.mismatchedTiles,
                    info: _vm.info("mismatchedTile"),
                    solve: _vm.solve({ issue: "mismatchedTile" })
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }