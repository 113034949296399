import TileView from 'tile-view';
import { when } from 'jquery';
import t from 'translate';
import PlayerView from './views/player';
import SettingsView from './views/settings';
import repository from 'repository';
import cwalert from 'cwalert';
import { assign } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';
import store from 'store';

const loadSimulator = () => import(
	/* webpackChunkName: "workflow-simulator" */
	'../../simulator-engine/workflow-simulator'
);

export default TileView.extend({
	title: t('plans.simulator.tile-title'),
	acl: [{
		checkpoint: SYSTEM.PLANS.PLANS,
		op: READ
	}],

	init () {
		store.dispatch('assessments/getEnabled');
		store.dispatch('plan/getAvailableRoles', { planId: this.cardContext().get('planId') });
		this.assessments = repository.getAssessments();
		this.plan = repository.getPlanById(this.cardContext().get('planId'));
	},

	onRender () {
		when(loadSimulator(), this.plan, this.assessments).then((module) => {
			this.loaded(module.default);
			this.setLoaded();
		});
	},

	setListener (Simulator) {
		const planId = this.cardContext().get('planId');
		const logentries = this.cardContext().get('simulator-log');
		window.addEventListener('message', (e) => {
			if (e.origin === window.location.origin) {
				cwalert.notice(t('Plan refreshing'));
				this.setLoading();
				this.plan = repository.getPlanById(planId);
				this.plan.then(() => {
					this.simulator = new Simulator({ plan: this.plan, logentries });
					this.settings.refresh({ plan: this.plan, simulator: this.simulator });
					this.setLoaded();
				});
			}
		}, false);
	},

	loaded (Simulator) {
		if (!this.plan) {
			return;
		}

		this.setListener(Simulator);

		this.simulator = new Simulator({
			plan: this.plan,
			logentries: this.cardContext().get('simulator-log')
		});

		assign(this, {
			consents: this.simulator.getConsents(),
			decisions: this.simulator.getDecisions(),
			databranches: this.simulator.getDataBranches(),
			logentries: this.simulator.getLogEntries(),
			settings: new SettingsView({
				plan: this.plan,
				simulator: this.simulator
			}),
			player: new PlayerView({
				tile: this,
				simulator: this.simulator,
				assessments: this.assessments
			})
		});

		this.$el
			.append(this.settings.$el)
			.append(this.player.$el);
	}

});

