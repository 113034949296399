import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import roleDialog from '../../dialogs/edit-role-wrapper';
import store from 'store';
import t from 'translate';
import { assign, map, pick } from 'lodash';
import cwalert from 'cwalert';
import can from 'service/acl/acl-can';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';

const removalCfg = {
	action: 'roles/deleteRole',
	question: 'Are you sure you want to delete this role?',
	success: 'Role has been deleted',
	title: 'Remove role'
};

const className = 'roles-list';

export default SearchTileView.extend({
	title: t('Roles'),
	instant: true,
	acl: [{
		checkpoint: SYSTEM.SETTINGS.TREATMENT_ROLES,
		op: READ
	}],

	searchFn (params) {
		const result = store.dispatch('roles/search', params);
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},

	fields: ({ tile }) => [{
		key: 'type',
		type: 'single-select',
		label: t('Type'),
		values: map(store.getters['roles/types'], (type) => assign(
			type,
			{ label: t(type.name) }
		)),

		onChange: (value) => {
			// prevent setting [null] filter
			const newVal = map(value, (val, key) => val ? key : undefined);
			tile.viewModel.set(
				'type',
				newVal[0] ? newVal : null
			);
			tile.runQuery();
		}
	}],

	tableCfg: ({ tile }) => ({
		store: {
			items: () => store.getters['roles/list'],
			count: 'roles/count',
			mutation: 'roles/setList',
			total: 'roles/total'
		},
		click: can.edit(SYSTEM.SETTINGS.TREATMENT_ROLES) ?
			(model) => {
				roleDialog({
					role: { ...pick(model, ['id', 'description', 'name', 'type']) },
					action: 'roles/updateRole',
					successMsg: t('Role has been updated'),
					title: 'Edit role',
					done: () => tile.runQuery()
				});
			} :
			null,
		columns: [{
			label: t('Name'),
			key: 'name',
			css: `${className}__name`
		}, {
			label: t('Description'),
			key: 'description',
			css: `${className}__description`
		}, {
			label: t('Type'),
			key: 'type',
			css: `${className}__type`
		}, can.delete(SYSTEM.SETTINGS.TREATMENT_ROLES) ?
			{
				label: t('Delete'),
				render () {
					searchTileDelete({
						data: removalCfg,
						item: this.item,
						td: this.td,
						tile,
						onError: (res) => {
							cwalert.error(t(`roles.error.${res.responseJSON.exception.msg}`));
						}
					});
				}
			} :
			null
		]
	})
});
