import appBar from 'app-bar';
import cardUrl from 'cwcardurl';
import roleDialog from '../dialogs/edit-role-wrapper';
import t from 'translate';
import can from 'service/acl/acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	title: t('Role sets'),
	name: 'role-sets',
	url: 'role-sets',

	ctrl: ({ populate }) =>  {
		if (can.add(SYSTEM.SETTINGS.TREATMENT_ROLES)) {
			appBar.addButton({
				sourceCard: card.name,
				icon: 'add',
				title: t('Add role set'),
				click: () => {
					const redirect = (newId) => {
						cardUrl.openCard('edit-role-set', {
							roleSetId: newId
						});
					};
					roleDialog({
						role: { roleSet: true },
						action: 'roleSets/addRoleSet',
						successMsg: t('Role set has been set'),
						title: t('New role set'),
						done: redirect
					});
				}
			});
		}

		populate();
	}
};

export { card as roleSets };
