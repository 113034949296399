import TileView from 'tile-view';
import t from 'service/lang/translate';
import MenuComposerListTile from './menu-composer-list-tile';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	title: t('Menu composer'),
	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],
	actions: [
		'treatmentTypes/initAll',
		'engine/respondentSidebar/init',
		'engine/clinicianSidebar/init'
	],
	vueComponent: () => ({ MenuComposerListTile })
});
