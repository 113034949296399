import addConfig from '../helpers/add-config';
import code from '../dialogs/codes';
import store from 'store';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { when } from 'jquery';

const card = {
	title: t('dataIntegration.codes.plural'),
	name: 'data-integration-codes',
	url: 'data-integration/codes',

	ctrl: ({ populate }) =>  {
		addConfig({
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
			action: 'codes/add',
			card,
			dataIntegrationFn: code,
			item: {
				code: '',
				codeType: null,
				enabled: false,
				enterprise: null,
				name: ''
			},
			populate,
			translateType: 'codes',
			type: 'code'
		});
		return when(
			store.dispatch('dataIntegration/codeTypes/search', { enabled: true, search: '' }),
			store.dispatch('dataIntegration/enterprises/search', { enabled: true, search: '' })
		);
	}
};

export { card as codes };
