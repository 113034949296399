import { Plans, PlanTriggers, PlanRevisions } from './definitions';
import { DELETE, GET, POST } from 'service/ajax/ajax';
import urls from './urls';

/**
 * @class Repository
 */
export default {
	/**
	 * Get Plan by id.
	 *
	 * @function getPlanById
	 * @param {number} planId - Plan ID.
	 * @returns {object} Plan.
	 * @example
	 *        repository.getPlanById(7);
	 */
	getPlanById: (planId) => Plans.getById(planId),

	/**
	 * Delete plan.
	 *
	 * @param {number} planId - Plan ID.
	 * @returns {object} Plan.
	 * @example
	 *        repository.deletePlan(7);
	 */
	deletePlan: (planId) => DELETE({
		url: urls.planSingle(planId)
	}),

	/**
	 * Get all Plans for current Clinician.
	 *
	 * @function getPlansForCurrentClinician
	 * @param {object} params - Parameters.
	 * @returns {object} Plans Collection.
	 * @example
	 *        repository.currentClinicianPlans({ query: 'pl' });
	 */
	currentClinicianPlans: (params) => Plans.getForCurrentClinician(params),

	/**
	 * Get all Plans by Respondent Id.
	 *
	 * @function plansForRespondent
	 * @param {number} respondentId - Respondent ID.
	 * @returns {object} Plans Collection.
	 * @example
	 *        repository.plansForRespondent(19);
	 */
	plansForRespondent: (respondentId) => Plans.getByRespondentId(respondentId),

	/**
	 * Get new collection of Plans which match search params.
	 *
	 * @function searchPlans
	 * @param {object} params - Search params.
	 * @returns {object} Plans Collection.
	 * @example
	 *        repository.searchPlans({
     * 			query: 'def',
     * 			limit: 10,
     * 			start: 0
     * 		});
	 */
	searchPlans: (params) => Plans.search(params),

	/**
	 * Get all PlanTriggers.
	 *
	 * @function getPlanTriggers
	 * @returns {object} PlanTriggers Collection.
	 * @example
	 *        repository.getPlanTriggers();
	 */
	getPlanTriggers: () => PlanTriggers.getAll(),

	/**
	 * Get all ClinicianGroups for a given plan.
	 *
	 * @function planClinicianGroups
	 * @param {number|string} planId - Plan ID.
	 * @returns {object}             - Promise.
	 * @example
	 *        repository.planClinicianGroups(34);
	 */
	planClinicianGroups: (planId) => GET({ url: urls.clinicianGroups(planId) }),

	/**
	 * Add a clinician group to plan.
	 *
	 * @function addClinicianGroupToPlan
	 * @param {object} params - Parameters.
	 * @param {number} params.planId - Plan ID.
	 * @param {number} params.clinicianGroupId - Clinician Group ID.
	 * @returns {object}                       - Promise.
	 * @example
	 * 		repository.addClinicianGroupToPlan({
	 * 			planId: 2,
	 * 			clinicianGroupId: 3
	 * 		});
	 */
	addClinicianGroupToPlan: ({ planId, clinicianGroupId }) => POST({
		url: urls.clinicianGroup(),
		data: {
			administratorGroupId: clinicianGroupId,
			workflowId: planId
		}
	}),

	/**
	 * Remove a clinician group from plan.
	 *
	 * @function removeClinicianGroupFromPlan
	 * @param {number} relationId - Relation ID created on b💩ckend.
	 * @returns {object}          - Promise.
	 * @example
	 * 		repository.removeClinicianGroupFromPlan(123);
	 */
	removeClinicianGroupFromPlan: ({ relationId }) => DELETE({
		url: urls.clinicianGroup(relationId)
	}),

	/**
	 * Get all ExpiredAssessments for a given plan.
	 *
	 * @function planExpiredAssessments
	 * @param {number} planId - Plan ID.
	 * @returns {object} Promise.
	 * @example
	 *        repository.planExpiredAssessments(3);
	 */
	planExpiredAssessments: (planId) => GET({
		url: urls.expiredAssessments(planId)
	}),

	/**
	 * Add an expired assessment trigger to plan.
	 *
	 * @function addExpiredAssessmentToPlan
	 * @param {object} params - Parameters.
	 * @param {number} params.planId - Plan ID.
	 * @param {number} params.assessmentCode - Assessment Code.
	 * @returns {object}                       - Promise.
	 * @example
	 * 		repository.addExpiredAssessmentToPlan({
	 * 			planId: 2,
	 * 			clinicianGroupId: 3
	 * 		});
	 */
	addExpiredAssessmentToPlan: ({ planId, assessmentCode }) => POST({
		url: urls.expiredAssessment(),
		data: {
			assessmentCode,
			workflowId: planId
		}
	}),

	/**
	 * Remove an expired assessment trigger from plan.
	 *
	 * @function removeExpiredAssessmentFromPlan
	 * @param {number} relationId - Relation ID created on b💩ckend.
	 * @returns {object}          - Promise.
	 * @example
	 * 		repository.removeExpiredAssessmentFromPlan(123);
	 */
	removeExpiredAssessmentFromPlan: ({ relationId }) => DELETE({
		url: urls.expiredAssessment(relationId)
	}),

	/**
	 * Get all flag triggers for a given plan.
	 *
	 * @function planFlags
	 * @param {number} planId - Plan ID.
	 * @returns {object} Promise.
	 * @example
	 *        repository.planFlags(3);
	 */
	planFlags: (planId) => GET({
		url: urls.flags(planId)
	}),

	/**
	 * Add a flag trigger trigger to plan.
	 *
	 * @function addFlagToPlan
	 * @param {object} params - Parameters.
	 * @param {number} params.planId - Plan ID.
	 * @param {number} params.triggerId - Flag Trigger ID.
	 * @returns {object}                       - Promise.
	 * @example
	 * 		repository.addFlagToPlan({
	 * 			planId: 2,
	 * 			clinicianGroupId: 3
	 * 		});
	 */
	addFlagToPlan: ({ planId, triggerId }) => POST({
		url: urls.flag(),
		data: {
			workflowTriggerFlagId: triggerId,
			workflowId: planId
		}
	}),

	/**
	 * Remove a flag trigger from plan.
	 *
	 * @function removeFlagFromPlan
	 * @param {number} relationId - Relation ID created on b💩ckend.
	 * @returns {object}          - Promise.
	 * @example
	 * 		repository.removeFlagFromPlan(123);
	 */
	removeFlagFromPlan: ({ relationId }) => DELETE({
		url: urls.flag(relationId)
	}),

	/**
	 * Get revisions for a given plan.
	 *
	 * @param {number|string} planId - Plan ID.
	 * @returns {object} PlanRevisions Collection.
	 * @example
	 *        repository.planRevisions(5);
	 */
	planRevisions: (planId) => PlanRevisions.getForPlan(planId),

	/**
	 * Get a revision.
	 *
	 * @param {number|string} revisionId - Plan Revision ID.
	 * @returns {object} PlanRevision Model.
	 * @example
	 *         repository.planRevision(452);
	 */
	planRevision: (revisionId) => PlanRevisions.getById(revisionId),

	/**
	 * Get available roles by plan id.
	 *
	 * @function getAvailableRolesById
	 * @param {number} planId - Plan ID.
	 * @returns {Array} Roles.
	 * @example
	 *        repository.getAvailableRolesById(7);
	 */
	getAvailableRolesById: (planId) => GET({ url: urls.availableRoles(planId) })
};
