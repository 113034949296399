import SingleEntity from '../shared/single-entity';
import urls from './urls';
import datetime from 'datetime';
import appContext from 'app-context';
import t from 'translate';

export default SingleEntity.extend({
	idAttribute: 'dataExportInstanceId',
	eventNamespace: 'export-instance',
	urlRoot: urls.instance.url,

	defaults: {
		dataExportInstanceStatus: 'NOT_STARTED'
	},
	prefix: 'dataExportInstance',

	initialize () {
		this.listenTo(this, 'change:dataExportInstanceFilterDateRange', (model, range) => {
			if (range === 'CUSTOM') {
				return;
			}

			model.set({
				dataExportInstanceFilterDateRangeStart: null,
				dataExportInstanceFilterDateRangeStop: null
			}, {
				silent: true
			});
		});
	},

	_get (key) {
		return this.get(this.prefix + key);
	},

	getDownloadURL () {
		return urls.instanceDownload.url(this.getId());
	},

	getDate (fieldName) {
		const format = (formatted) => {
			const dontParse = formatted === false || this._get(fieldName) === null;
			return dontParse ? this._get(fieldName) : datetime(this._get(fieldName))
				.setTimezone(appContext.get('user').get('timezoneName'))
				.toMoment()
				.format(t('date.formats.dateTime'));
		};

		return {
			isFormatted: format
		};
	},

	getCreatedDate (formatted) {
		return this.getDate('CreatedDate').isFormatted(formatted);
	},

	getLastModifiedDate (formatted) {
		return this.getDate('LastModifiedDate').isFormatted(formatted);
	},

	getRanges () {
		return {
			start: this.getDate('FilterDateRangeStart').isFormatted(true),
			stop: this.getDate('FilterDateRangeStop').isFormatted(true)
		};
	}
});

