import add from './add-assessment/tile';
import attachments from './assessment-instance-attachments/tile';
import barChart from './assessment-value-bar-chart';
import clinicianAssessments from './clinician-assessments/tile';
import files from './assessment-instance-files/tile';
import information from './assessment-instance-information/tile';
import respondentAssessments from './respondent-assessments/tile';
import miniAssessmentValue from './mini-assessment-value';
import miniCreateAssessment from './mini-create-assessment/tile';
import miniList from './mini-respondent-assessments/tile';
import miniPlotChart from './mini-assessment-value-plot-chart';
import overdue from './overdue-assessments/tile';
import pieChart from './assessment-value-pie-chart';
import plotChart from './assessment-value-plot-chart';
import report from './assessment-instance-report/tile';
import reports from './assessment-instance-reports/tile';
import simpleAssessmentList from './simple-assessments/tile';
import treatmentAssessments from './treatment-assessments/tile';
import unseenAssessments from './unseen-assessments/tile';

export default  {
	'add-assessment': add,
	'assessment-instance-attachments': attachments,
	'assessment-instance-files': files,
	'assessment-instance-information': information,
	'assessment-instance-report': report,
	'assessment-instance-reports': reports,
	'assessment-value-bar-chart': barChart,
	'assessment-value-pie-chart': pieChart,
	'assessment-value-plot-chart': plotChart,
	'clinician-assessments': clinicianAssessments,
	'mini-assessment-value': miniAssessmentValue,
	'mini-assessment-value-plot-chart': miniPlotChart,
	'mini-create-assessment': miniCreateAssessment,
	'mini-respondent-assessments': miniList,
	'overdue-assessments': overdue,
	'respondent-assessments': respondentAssessments,
	'simple-assessments': simpleAssessmentList,
	'treatment-assessments': treatmentAssessments,
	'unseen-assessments': unseenAssessments
};
