import TileView from 'tile-view';
import t from 'translate';
import datetime from 'datetime';
import moment from 'moment';
import store from 'store';
import { SimpleList, itemTemplate } from 'components/simple-list/simple-list';
import { RESPONDENT } from 'store/respondents/respondent';

export default TileView.extend({
	title: t('Available assessments'),

	acl: [{
		checkpoint: 'administrator.respondents.assessmentplans',
		op: 'read'
	}],

	actions: () => ['assessmentInstances/init'],

	roleDescription: ({ tile, item }) => itemTemplate({
		config: tile.config().items,
		name: 'role',
		value: item.roleName,
		className: 'item-description__role item-description--small'
	}),

	contextDescription: ({ tile, item }) => itemTemplate({
		config: tile.config().items,
		name: 'context',
		value: item.assessmentInstanceContextLabel,
		className: `
				item-description__context
				item-description--highlight
				item-description--small
			`
	}),

	expiredDescription: ({ item }) => itemTemplate({
		config: ['expired'],
		name: 'expired',
		value: `${t('Expire')}: ${
			datetime(item.assessmentInstanceEndDate)
				.setTimezone(store.getters[RESPONDENT.TIMEZONE])
				.toMoment()
				.format(t('date.formats.dateTime'))}`,
		className: 'item-description__expired item-description--small',
		tag: 'p'
	}),

	loaded: ({ tile }) => {
		const now = moment().valueOf();
		let assessments = store.getters['assessmentInstances/available'];

		if (tile.config().expired) {
			tile.setTitle(t('Expired assessments'));
			assessments = store.getters['assessmentInstances/expired'];
		}

		const renderDescription = (item) => `
			${tile.roleDescription({ tile, item })}
			${tile.contextDescription({ tile, item })}
			${tile.expiredDescription({ item })}
		`;

		tile.list = new SimpleList({
			el: tile.el,
			items: assessments,
			urlParams: (item) => (['assessment-properties', {
				assessmentInstanceId: item.assessmentInstanceId
			}]),

			className: {
				positive: (item) => item.assessmentInstanceStartDate.unixtime * 1000 < now &&
					now < item.assessmentInstanceEndDate.unixtime * 1000,
				neutral: (item) => item.assessmentInstanceStartDate.unixtime * 1000 > now,
				negative: (item) => item.assessmentInstanceEndDate.unixtime * 1000 < now
			},

			classNameDesc: {
				positive: () => t('Assessment is available'),
				neutral: () => t('Assessment isn\'t started'),
				negative: () => t('Assessment has expired')
			},

			content: (item) => `
				<i class="simple-list__item-icon fa icon--assessment assessment-icon"></i>
				<div class="simple-list__item-description">
					<h3 class="item-description__title">${item.assessmentTitle}</h3>
					${renderDescription(item)}
				</div>`
		});

		tile.$el.html(tile.list.$el);
	}
});
