import $ from 'jquery';
import { View } from 'backbone';
import Table from 'components/table/table';
import cardurl from 'cwcardurl';
import t from 'translate';
import datetime from 'datetime';
import $respondentLink from '../../util/respondent-link/respondent-link';
import can from 'acl-can';
import appContext from 'app-context';
import repository from 'repository';
import systemSettings from 'system-settings';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

export default View.extend({
	title: t('Clinician rated assessments'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CLINICIANS.ASSESSMENTS
	}],

	render () {
		this.setLoading();

		this.contexts = repository.getContexts();
		this.types = repository.getAssessmentTypes();
		this.assessmentInstances = repository.getMyAssessmentInstances();

		$.when(this.contexts, this.types, this.assessmentInstances)
			.done(this.loaded.bind(this))
			.always(this.setLoaded.bind(this));
	},

	loaded () {
		this.clear();
		const inst = this;

		inst.table = new Table({
			className: 'table--long-text',
			parent: this.el,
			collection: this.assessmentInstances,
			click (item) {
				cardurl.openCard('assessment-properties', {
					assessmentInstanceId: item.getId()
				});
			},
			rowsClickable: can.read(CLINICIAN.RESPONDENTS.RESPONDENTS) &&
			can.read(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS),
			columns: [{
				label: t('Deadline'),
				css: 'assessment-list__deadline',
				render () {
					return datetime(this.item.getEndDate())
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				label: t('Treatment'),
				css: 'assessment-list__treatment',
				key: 'treatmentName'
			}, {
				label: t('Respondent'),
				css: 'assessment-list__respondent',
				render () {
					$respondentLink(this.item.toJSON()).appendTo(this.td);
				}
			}, {
				label: t('Title'),
				css: 'assessment-list__title',
				key: 'assessmentTitle'
			}, {
				label: t('Type'),
				css: 'assessment-list__type',
				skip () {
					return !systemSettings.getBoolean('ASSESSMENT_TYPES');
				},
				render () {
					const type = inst.types.findWhere({
						id: `${this.item.get('type')}`
					});
					return type ? type.get('label') : '';
				}
			}, {
				label: t('Context'),
				css: 'assessment-list__context',
				render () {
					const context = inst.contexts.findWhere({
						assessmentInstanceContextId: this.item.get('assessmentInstanceContextId')
					});

					if (context && !!context.getId()) {
						return context.getLabel();

					}

					return '';
				}
			}]
		});

		return this;
	}
});

