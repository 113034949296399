import t from 'translate';
import ctrl from 'core/engine/card/ctrl-presets/add-instance';
import { generateUrls } from 'components/agenda-view/helpers';

const card = {
	title: t('Add an assessment'),
	name: 'add-assessment',
	url: generateUrls('add-assessment'),
	ctrl
};

export { card as AddAssessmentInstance };
