'use strict';

import t from 'translate';

const card = {
	title: t('Unseen flags'),
	name: 'unseen-flags',
	url: 'unseen-flags'
};

export { card as unseen };
