/* eslint-disable lodash/prefer-lodash-method */
import $ from 'jquery';
import acl from 'acl';
import assessmentCode from '../../components/assessment-portal-code';
import assessmentWarning from '../../components/assessment-warning';
import can from 'acl-can';
import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import store from 'store';
import systemSettings from 'system-settings';
import t from 'translate';
import TileView from 'tile-view';
import { compile } from 'handlebars';
import { defaults, map, isNull, isObject, toLower } from 'lodash';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ, EDIT, SUPERVISOR } from 'service/acl/access-levels';
import { EMPTY_CHAR } from 'lib/chars';

const dateFormat = 'date.formats.dateTime';
const assessmentPropertiesNever = 'assessment__properties.never';
const basicClassName = 'assessment-instance-information';
const pValue = 'p.form-view__paragraph-value';
const className = (suffix) => `${basicClassName}__${suffix}`;

const toMoment = (date) => datetime(date).toMoment();
const formatDate = (date) => toMoment(date).format(t(dateFormat));

const SYSTEM = 'PLAN';

const transformStatus = ({ value, type, submittedBy = null }) => {
	if (isNull(value)) {
		return submittedBy === SYSTEM ?
			t('assessment__properties.system') :
			t(assessmentPropertiesNever);
	}
	return {
		date: datetime(value.date)
			.setTimezone(store.getters['respondent/timezone'])
			.toMoment()
			.format(t(dateFormat)),
		portal: !isObject(value) ? t(`assessment__properties.${toLower(value)}`) : null
	}[type] || value;
};

const assessmentStatusFields = (model) => map([{
	key: 'firstSubmitAt',
	label: t('assessment.properties.firstSubmitAt'),
	transform: (firstSubmitAt) => transformStatus({
		value: firstSubmitAt,
		type: 'date'
	})
}, {
	key: 'firstSubmitBy',
	label: t('assessment.properties.firstSubmitBy'),
	transform: (firstSubmitBy) => transformStatus({
		value: firstSubmitBy,
		type: 'model',
		submittedBy: model.get('firstSubmitPortal')
	})
}, {
	key: 'firstSubmitPortal',
	label: t('assessment.properties.firstSubmitPortal'),
	transform: (firstSubmitPortal) => transformStatus({
		value: firstSubmitPortal,
		type: 'portal'
	})
}, {
	key: 'lastSubmitAt',
	label: t('assessment.properties.lastSubmitAt'),
	transform: (lastSubmitAt) => transformStatus({
		value: lastSubmitAt,
		type: 'date'
	})
}, {
	key: 'lastSubmitBy',
	label: t('assessment.properties.lastSubmitBy'),
	transform: (firstSubmitBy) => transformStatus({
		value: firstSubmitBy,
		type: 'model',
		submittedBy: model.get('lastSubmitPortal')
	})
}, {
	key: 'lastSubmitPortal',
	label: t('assessment.properties.lastSubmitPortal'),
	transform: (lastSubmitPortal) => transformStatus({
		value: lastSubmitPortal,
		type: 'portal'
	})
}, {
	key: 'lastModifiedAt',
	label: t('assessment.properties.lastModifiedAt'),
	transform: (lastModifiedAt) => transformStatus({
		value: lastModifiedAt,
		type: 'date'
	})
}, {
	key: 'lastModifiedBy',
	label: t('assessment.properties.lastModifiedBy'),
	transform: (firstSubmitBy) => transformStatus({
		value: firstSubmitBy,
		type: 'model',
		submittedBy: model.get('lastModifiedPortal')
	})
}, {
	key: 'lastModifiedPortal',
	label: t('assessment.properties.lastModifiedPortal'),
	transform: (lastModifiedPortal) => transformStatus({
		value: lastModifiedPortal,
		type: 'portal'
	})
}], (field) => defaults(field, {
	type: 'paragraph',
	transform: transformStatus
}));

export default TileView.extend({
	title: t('assessment-properties.info-title'),

	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS,
		op: READ
	}, {
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: READ
	}],

	startInputType: 'datetime',
	endInputType: 'datetime',
	startDateFieldName: 'startDate',
	endDateFieldName: 'endDate',
	pristineStartDate: null,
	pristineEndDate: null,

	className: className('container'),
	template: compile(`
		{{#if isTestAssessment}}
			<p class="{{className}}__test-warning">
				<strong>{{t 'NOTE: FOR TESTING PURPOSES ONLY'}}</strong>
			</p>
		{{/if}}
		<h3 class="{{className}}__title">{{assessmentTitle}}</h3>
		<div class="{{className}}__form-container"></div>
	`),

	actions: ['contexts/init', 'types/init'],

	loaded () {
		const respondent = store.getters.respondent;

		this.model = store.state.assessmentInstance.model;
		this.model.set({
			className: basicClassName,
			respondentTimezone: respondent.respondentTimezoneName
		});
		this.pristineStartDate = this.model.getStartDate();
		this.pristineEndDate = this.model.getEndDate();
		this.datetimeFallback();

		if (this.model.isSubmitted() && !this.model.get('isSeen')) {
			this.model.markAsSeen();
		}

		this.$el.html(this.template(this.model.toJSON()));

		this.renderForm(this.model);
	},

	canEditStartDate () {
		return !this.model.isStarted() && can.edit(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS);
	},

	canEditEndDate () {
		return !this.model.isSubmitted() && can.edit(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS);
	},

	datetimeFallback () {
		// "temporary" solution
		if (!Modernizr.inputtypes['datetime-local']) {
			if (!this.canEditStartDate()) {
				this.startDateFieldName = 'textStartDate';
				this.model.set({
					[this.startDateFieldName]: formatDate(this.model.getStartDate())
				});
				this.startInputType = 'text';
			}

			if (!this.canEditEndDate()) {
				this.endDateFieldName = 'textEndDate';
				this.model.set({
					[this.endDateFieldName]: formatDate(this.model.getEndDate())
				});
				this.endInputType = 'text';
			}
		}
	},

	properDateFieldName (type) {
		const editableDate = type === 'Start' ? !this.canEditStartDate() : !this.canEditEndDate();

		if (!Modernizr.inputtypes['datetime-local'] && editableDate) {
			return `text${type}Date`;
		}
		return `${toLower(type)}Date`;
	},

	validateStartDate (value) {
		if (!this.canEditStartDate()) {
			return toMoment(this.pristineStartDate) <
				toMoment(this.model.get('endDate'));
		} else if (!this.canEditEndDate()) {
			return toMoment(value) < toMoment(this.pristineEndDate);
		}
		return toMoment(value) < toMoment(this.model.get('endDate'));
	},

	validateEndDate (value) {
		if (!this.canEditEndDate()) {
			return toMoment(this.pristineEndDate) >
				toMoment(this.model.get('startDate'));
		} else if (!this.canEditStartDate()) {
			return toMoment(value) > toMoment(this.pristineStartDate);
		}
		return toMoment(value) > toMoment(this.model.get('startDate'));
	},

	renderForm (model) {
		const change = (fieldName, props) => {
			this.form.fieldViews[fieldName].model.set(props);
		};
		const blockDates = () => {
			change('endDate', { disabled: true });
			change('startDate', { disabled: true });
		};
		const unblockDates = () => {
			change(this.properDateFieldName('End'), { disabled: false });
			change(this.properDateFieldName('Start'), { disabled: !this.canEditStartDate() });
		};

		const onDateChange = ({ validation }) => {
			validation[0].then(() => {
				blockDates();
			});
		};

		const op = this.model.isSubmitted() ? SUPERVISOR : EDIT;

		const statusLabel = (status) => status ? t(`assessment.${status}`) : EMPTY_CHAR;

		const cfg = {
			name: 'assessment-properties',
			model: this.model,
			readonly: !can.read(CLINICIAN.RESPONDENTS.ASSESSMENTPLANS),
			saveIndicator: true,

			onBeforeSave: () => {
				this.model.set({
					contextId: this.model.get('assessmentInstanceContextId')
				}, {
					silent: true
				});
				this.model
					.setStartDate(this.model.get('startDate'))
					.setEndDate(this.model.get('endDate'));
			},

			onAfterSave: () => {
				unblockDates();
			},

			fields: [{
				key: 'assessmentVersion',
				type: 'paragraph',
				label: t('assessment.properties.Version')
			}, {
				key: 'packageVersion',
				type: 'paragraph',
				label: t('assessment.properties.PackageVersion')
			}, {
				key: '_assessmentInstanceStatus',
				type: 'paragraph',
				label: t('assessment.properties.Status'),
				value: statusLabel(this.model.getStatus()),
				customize: (view, context, formModel) => {
					const renderStatus = () => {
						view.$el
							.find(pValue)
							.html(statusLabel(this.model.getStatus()));
					};

					if (!formModel.isListeningTo('change:assessmentInstanceStatus')) {
						formModel.on('change:assessmentInstanceStatus', renderStatus);
					}
					renderStatus();
				}
			}, {
				key: '_lastModifiedDate',
				label: t('Last modified'),
				type: 'paragraph',
				customize: (view, context, formModel) => {
					const renderDate = () => {
						view.$el
							.find(pValue)
							.html(this.lastModifiedDateString());
					};

					if (!formModel._events['change:lastModifiedDate']) {
						formModel.on(
							'change:lastModifiedDate change:assessmentInstanceLastModified',
							renderDate
						);
					}
					renderDate();
				}
			}, {
				key: 'delivered',
				label: this.model.isClosed() ? t('Closed') : t('Delivered'),
				type: 'paragraph',
				customize: (view) => {
					let deliveryDateString = t(assessmentPropertiesNever);
					let deliveryDate;

					if (this.model.getDeliveredDate()) {
						deliveryDate = datetime(this.model.getDeliveredDate())
							.setTimezone(this.model.get('respondentTimezone'))
							.toMoment();
						deliveryDateString = deliveryDate.format(t(dateFormat));
					}

					const overdue = deliveryDate &&
						(deliveryDate > datetime(this.model.get('endDate')).toMoment());
					const content = [deliveryDateString];
					const $warningEl = $('<span />').addClass('overdue-text').html(t('Overdue'))[0];

					overdue && content.unshift($warningEl);

					view.$el.find(pValue).empty().append(content);
				}
			}, {
				key: 'type',
				type: 'select',
				label: t('Type'),
				collection: store.state.types.collection,
				labelField: 'label',
				setNullLabel: t('-- no type --'),
				show: {
					condition: () => systemSettings.getBoolean('ASSESSMENT_TYPES')
				},
				readonly: !acl.checkAccess({
					op,
					checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS
				})
			}, {
				key: 'assessmentInstanceContextId',
				type: 'select',
				values: store.getters['contexts/shortLabel'],
				keyField: 'assessmentInstanceContextId',
				labelField: 'assessmentInstanceContextLabel',
				label: t('Context'),
				setNullLabel: t('-- no context --'),
				disabled: !acl.checkAccess({
					op,
					checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS
				}),
				onChange: (e) => {
					const contextLabel = e.value ? e.label : '';
					this.model.set('assessmentInstanceContextLabel', contextLabel);
				}
			}, {
				key: this.startDateFieldName,
				label: t('Start date'),
				type: this.startInputType,
				timezone: this.model.get('respondentTimezone'),
				disabled: !this.canEditStartDate(),
				onChange: onDateChange,
				validators: {
					validator: (value) => this.validateStartDate(value),
					invalidMessage: t('Start date cannot be set after end date')
				}
			}, {
				key: this.endDateFieldName,
				label: t('End date'),
				type: this.endInputType,
				timezone: this.model.get('respondentTimezone'),
				disabled: !this.canEditEndDate(),
				onChange: onDateChange,
				validators: {
					validator: (value) => this.validateEndDate(value),
					invalidMessage: t('End date cannot be set before start date')
				}
			}, ...assessmentStatusFields(model)]
		};
		this.form = formView(cfg);
		this.appendAssessmentCode(this);
		this.appendAssessmentWarning(this);

		this.$(`.${className('form-container')}`).append(this.form.$el);
	},

	appendAssessmentCode: (tile) => {
		const canAccess = !tile.model.isClinicianOnly()  &&
			can.read(CLINICIAN.RESPONDENTS.ASSESSMENT_CODES);

		if (systemSettings.getBoolean('ENABLE_ASSESSMENT_PORTAL') && canAccess) {
			const classNames = {
				mainContainerClass: [
					'form-view__field-container',
					'form-view__field-container--paragraph',
					'form-view__field-container--assessmentVersion',
					'form-view__field-container--collapsible'
				].join(' '),
				containerClass: 'form-view__paragraph-container',
				labelClass: 'form-view__paragraph-label form-view__label-text',
				valueClass: 'form-view__paragraph-value',
				generateButtonClass: 'flat-button__button flat-button__button--submit',
				sendButtonClass: 'flat-button__button flat-button__button--submit'
			};
			tile.form.$el.find('.form-view__fieldset').prepend(assessmentCode(classNames).$el);
		}
	},

	appendAssessmentWarning: (tile) => {
		tile.form.$el.find('.form-view__fieldset').prepend(assessmentWarning().$el);
	},

	lastModifiedDateString () {
		return {
			true: datetime(this.model.getLastModifiedDate())
				.setTimezone(this.model.get('respondentTimezone'))
				.toMoment()
				.format(t(dateFormat)),
			false: t(assessmentPropertiesNever)
		}[!!this.model.getLastModifiedDate()];
	}
});
