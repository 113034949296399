import Backbone from 'backbone';
import t from 'translate';
import RespondentAttribute from 'repo/respondents/attribute-definition';
import formView from 'components/form-view/form-view';

export default Backbone.View.extend({
	acl: [{
		checkpoint: 'system.settings.respondentattributes',
		op: 'ADD'
	}],
	title: t('Add respondent attribute'),

	render () {
		this.respondentAttribute = new RespondentAttribute();

		const form = formView(this.formConfig());
		this.$el.append(form.$el);
	},

	formConfig () {
		const cfg = {
			name: 'respondent-attributes-add',
			model: this.respondentAttribute,
			listenTo: ['submit'],

			onAfterSave: function () {
				this.card().close();
			}.bind(this),

			fields: [{
				key: 'respondentAttributeMetaLabel',
				type: 'text',
				mandatory: true
			}, {
				key: 'respondentAttributeMetaReadonly',
				type: 'checkbox'
			}, {
				key: 'respondentAttributeMetaRequired',
				type: 'checkbox'
			}, {
				key: 'respondentAttributeMetaType',
				type: 'select',
				values: [{
					CHECKBOX: t('Checkbox')
				}, {
					STRING: t('String')
				}, {
					TEXTAREA: t('Textarea')
				}, {
					NUMERIC: t('Numeric')
				}, {
					DATE: t('Date')
				}, {
					DROPDOWN: t('Dropdown')
				}]
			}, {
				key: 'respondentAttributeMetaOrder',
				type: 'number',
				mandatory: true
			}, {
				key: 'respondentAttributeMetaParameters',
				type: 'text'
			}, {
				key: 'respondentAttributeMetaShow',
				type: 'checkbox'
			}, {
				key: 'respondentAttributeMetaKeepHistory',
				type: 'checkbox'
			}],

			buttons: [{
				caption: t('general-list.Add'),
				type: 'submit',
				name: 'clinician-attribute-submit',
				role: 'submit'
			}]
		};
		return cfg;
	}
});

