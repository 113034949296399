import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import store from 'store';
import { filter, size } from 'lodash';

export default CounterMiniTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.manualinputs',
		op: 'READ'
	}],
	caption: t('Pending decision(s)'),
	listen: () => store.state.decisions.collection,
	actions: () => ['decisions/init'],

	count: () => size(filter(
		store.state.decisions.collection.toJSON(),
		(decision) => !decision.responseDate
	)),
	total: () => size(store.state.decisions.collection.toJSON()),

	targetCard: () => ['respondent-decisions', {
		respondentId: store.getters['respondent/respondentId']
	}]
});

