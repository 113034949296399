import t from 'translate';
import settings from 'system-settings';
import appBar from 'app-bar';
import cardUrl from 'cwcardurl';
import { when } from 'jquery';
import store from 'store';
import can from 'acl-can';
import { get } from 'lodash';

const card = {
	title: t('Respondent Messages'),
	name: 'respondent-messages',
	url: 'respondent/:respondentId/treatment/(:treatmentId)/messages',

	ctrl ({ cardData, close }, { respondentId, treatmentId }) {
		if (!settings.getBoolean('ENABLE_MESSAGING_SYSTEM')) {
			close();
			return;
		}

		cardData.listenTo(cardData, 'change:treatmentId', () => {
			cardUrl.openCard(card.name, {
				respondentId,
				treatmentId: cardData.get('treatmentId')
			});
		});

		const load = () => when(
			store.dispatch('messages/init', { respondentId, treatmentId }),
			store.dispatch('treatment/init', { treatmentId })
		).then(() => {
			if (
				can.add('administrator.respondents.communication') &&
				!get(store.state, 'respondent.data.respondentCommunicationDisabled')
			) {
				appBar.addCardLink({
					sourceCard: card.name,
					card: 'respondent-new-message',
					title: t('New message'),
					icon: 'add',
					query: { treatmentId, respondentId }
				});
			}
		});

		if (!parseInt(treatmentId, 10)) {
			return store.dispatch('treatments/initForRespondent', { respondentId }).then(() => {
				const id = store.getters['treatments/enabled'][0].id;
				return cardUrl.openCard(card.name, {
					treatmentId: id,
					respondentId
				});
			});
		}

		return load();
	}
};

export { card as RespondentMessages };
