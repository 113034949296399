var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "object-information__container object-information__content"
    },
    [
      _c("div", { staticClass: "object-information__element-container" }, [
        _c(
          "p",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            staticClass: "object-information__label-container"
          },
          [_vm._v("\n\t\t\tPlan\n\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "object-information__value-container" }, [
          _c("a", { attrs: { href: _vm.planUrl } }, [
            _vm._v(_vm._s(_vm.planName))
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "object-information__element-container important" },
        [
          _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "object-information__label-container"
            },
            [_vm._v("\n\t\t\tRecipient\n\t\t")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "object-information__value-container" }, [
            _c("strong", [_vm._v(_vm._s(_vm.recipientName))]),
            _vm._v(" (" + _vm._s(_vm.recipientType) + ")\n\t\t")
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "object-information__element-container" }, [
        _c(
          "p",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            staticClass: "object-information__label-container"
          },
          [_vm._v("\n\t\t\tStart date\n\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "object-information__value-container" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.startDate) + "\n\t\t")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }