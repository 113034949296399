import appContext from 'app-context';
import AssessmentInstance from 'repo/assessment-instances/assessment-instance';
import datetime from 'datetime';
import formView from 'components/form-view/form-view';
import moment from 'moment';
import repository from 'repository';
import store from 'store';
import systemSettings from 'system-settings';
import t from 'service/lang/translate';
import TileView from 'tile-view';
import warning from 'modules/treatments/components/warning';
import handleReady from 'modules/treatments/shared/handle-ready';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { assign, find, get, isUndefined, map, split } from 'lodash';
import { Collection } from 'backbone';
import { ADD } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import redirectOnSave from 'modules/general/shared/redirect-on-save';

const RESPONDENT_ID = 'respondent/respondentId';
const localState = {};

const offset = () => {
	const defaultDeadline = systemSettings.getInteger('DEFAULT_ASSESSMENT_DEADLINE');
	const defaultDeadlineUnit = systemSettings.getString('DEFAULT_ASSESSMENT_DEADLINE_UNIT');

	return {
		Days: 24 * 3600,
		Hours: 3600,
		Minutes: 60
	}[defaultDeadlineUnit] * defaultDeadline;
};

const dummyModel = () => {
	const initialDate = get(store.getters.urlParams, 'datetime');
	const startTime = split(systemSettings.getString('DEFAULT_ASSESSMENT_TIME'), ':');
	const startTimestamp = moment(initialDate).hours(startTime[0]).minutes(startTime[1]).unix();
	const endTimestamp = startTimestamp + offset();

	return new AssessmentInstance({
		fkAssessmentInstanceContextId: null,
		start: datetime(startTimestamp * 1000).toObject(),
		end: datetime(endTimestamp * 1000).toObject(),
		respondentId: store.getters[RESPONDENT_ID],
		treatment: get(store.getters.treatment, 'id')
	});
};

const treatmentsData = (model) => ({
	treatments: store.getters['treatments/ready'],
	treatment: store.getters.treatment || find(store.getters['treatments/ready'], {
		id: +model.get('treatment')
	})
});

const determineDisabled = (view, formView, formModel) => {
	view.listenTo(formModel, 'change:treatment', () => {
		view.model.set('disabled', !formModel.has('treatment'));
	});
};

const populateTreatment = (model) =>
	store.getters[RESPONDENT_ID] ?
		treatmentsData(model) :
		{
			treatment: undefined,
			treatments: []
		};

export default TileView.extend({
	title: t('Add assessment'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: ADD
	}],

	tileData: () => ({
		assessments: store.dispatch('assessments/getEnabled'),
		contexts: store.dispatch('contexts/init'),
		treatments: store.getters[RESPONDENT_ID] ?
			store.dispatch('treatments/initForMainRespondent', {
				respondentId: store.getters[RESPONDENT_ID]
			}) :
			{},
		types: store.dispatch('types/init'),
		treatmentRoles: new Collection(),
		respondent: store.getters.respondent
	}),

	// @TODO rewrite tile to Vue
	// eslint-disable-next-line max-statements
	loaded: ({ tile }) => {
		// Prevent caching wrong respondent, see defect 11931.
		localState.respondentId = null;

		const { limit, searchable } = tile.config();
		let timezone = tile.respondent ? tile.respondent.respondentTimezoneName : false;
		const model = dummyModel();

		const contexts = store.getters['contexts/shortLabel'];
		const assessments = store.state.assessments.collection;
		const types = store.state.types.collection;
		const { treatment, treatments } = populateTreatment(model);

		const currentRole = (roleId) => roleId && tile.treatmentRoles.get(roleId);

		tile.$el.append(warning(treatment).$el);
		const fields = [!store.getters[RESPONDENT_ID] && {
			key: 'respondentId',
			type: 'search',
			label: t('Respondent'),
			limit,
			mandatory: true,
			// @TODO(2017-07-03): Replace with store.dispatch
			provideResults: (search) => repository.searchRespondents({
				limit,
				respondentAccountEnabled: true,
				search,
				start: 0
			}),
			placeholder: t('Type respondent name'),
			itemLabel: (model) => model.displayName(),
			onSelectItem: (respondent) => {
				timezone = respondent.get('respondentTimezoneName');
				// save to prevent main respondentId loss on submit
				localState.respondentId = respondent.get('respondentId');
			}
		}, !store.getters['treatment/treatmentId'] && {
			key: 'treatment',
			type: 'select',
			collection: new Collection(treatments),
			description: t('Select a treatment'),
			label: t('Treatment'),
			readonly: !model.has('respondentId'),
			mandatory: true,
			customize: (view, formView) => {
				view.listenTo(formView, 'change:respondentId', (changedModel, respondentId) => {
					if (isUndefined(respondentId)) {
						view.model.set('disabled', true).set('value', null);
						return view.collection.reset();
					}

					tile.setLoading();
					store.dispatch('treatments/initForMainRespondent', {
						respondentId
					}).then(() => {
						tile.setLoaded();
						view.collection.reset(store.getters['treatments/ready']);
						const isSingleTreatment = view.collection.size() === 1;
						isSingleTreatment && formView.model.set(
							'treatment',
							view.collection.models[0].get('id')
						);
						view.model.set('disabled', isSingleTreatment);
						view.setReadOnly(isSingleTreatment);
					});
				});
			}
		}, {
			key: 'roleId',
			type: 'select',
			label: t('Role'),
			value: null,
			setNullLabel: t('Select role'),
			collection: tile.treatmentRoles,
			getVal: (roleId) => +roleId,
			show: {
				listenTo: tile,
				event: 'treatment-roles.reset',
				condition: () => tile.treatmentRoles.length
			}
		}, searchable ?
			{
				key: 'assessmentId',
				type: 'search',
				limit,
				label: t('Assessment'),
				disabled: !model.has('treatment'),
				mandatory: true,
				placeholder: t('Type assessment name'),
				// @TODO(2017-07-03): Replace with store.dispatch
				provideResults: (search) => repository.searchAssessments(search === ' ' ?
					{
						search: ''
					} :
					{
						search,
						...limit && { limit } // b💩ckend returns empty response if url has limit=
					}),
				itemLabel: (model) => model.get('assessmentName'),
				customize: determineDisabled
			} :
			{
				key: 'assessmentId',
				type: 'select',
				label: t('Assessment'),
				disabled: !model.has('treatment'),
				mandatory: true,
				collection: assessments,
				labelField: 'assessmentName',
				keyField: 'assessmentId',
				description: t('Select assessment'),
				customize: determineDisabled
			},
		{
			key: 'type',
			type: 'select',
			collection: types,
			labelField: 'label',
			label: t('Type'),
			show: { condition: () => systemSettings.getBoolean('ASSESSMENT_TYPES') },
			setNullLabel: t('-- no type --')
		}, {
			key: 'fkAssessmentInstanceContextId',
			type: 'select',
			keyField: 'assessmentInstanceContextId',
			labelField: 'assessmentInstanceContextLabel',
			label: t('Context'),
			setNullLabel: t('-- no context --'),
			values: contexts
		}, {
			label: t('Start date'),
			key: 'start',
			type: 'datetime',
			mandatory: true,
			customize: (view, formView) => {
				formView.on('change:end', (model, changedValue) => {
					const endDate = datetime(changedValue).toMoment().unix();
					const startDate = datetime(view.model.get('value')).toMoment().unix();

					if (endDate < startDate) {
						view.model.set('value', { unixtime: endDate - offset() });
					}
				});
			}
		}, {
			label: t('Due date'),
			key: 'end',
			type: 'datetime',
			mandatory: true,
			customize: (view, formView) => {
				formView.on('change:start', (model, changedValue) => {
					const startDate = datetime(changedValue).toMoment().unix();
					const endDate = datetime(view.model.get('value')).toMoment().unix();

					if (startDate > endDate) {
						view.model.set('value', { unixtime: startDate + offset() });
					}
				});
			}
		}];

		let cacheModel = {};
		tile.form = formView({
			name: 'add-assessment',
			model,
			listenTo: ['submit'],
			refreshModelOnSave: true,

			beforeSave () {
				cacheModel = this.model.clone();
				const roleId = this.model.get('roleId');
				const roleAssigned = roleId && (+get(
					currentRole(roleId),
					'respondent.id'
				) !== +this.model.get('respondentId'));

				this.model.set('roleId', +this.model.get('roleId'));

				if (roleAssigned) {
					const source = currentRole(roleId).get('role').type === 'CLINICIAN' ?
						'administrator' :
						'respondent';

					this.model.set(`${source}Id`, currentRole(roleId).get(source).id, {
						silent: true
					});

				} else {
					// when clinician role is chosen, main respondentId should be sent
					this.model.set(
						'respondentId',
						localState.respondentId || get(tile.respondent, 'respondentId')
					);
				}

				this.model.unset('assessmentInstanceId').set({
					assessmentTitle: assessments
						.get(+this.model.get('assessmentId'))
						.get('assessmentName'),
					assessmentInstanceStartDate: datetime(
						datetime(this.model.get('start'))
							.toDate()
							.setSeconds(0)
					)
						.toUtcFromTimezone(timezone)
						.toObject(),
					assessmentInstanceEndDate: datetime(
						datetime(this.model.get('end'))
							.toDate()
							.setSeconds(0)
					)
						.toUtcFromTimezone(timezone)
						.toObject()
				});

				return false;
			},
			onAfterSave () {
				tile.onTreatmentChange({ tile, model: this.model });
				this.model.set({
					start: cacheModel.get('start'),
					end: cacheModel.get('end')
				});

				redirectOnSave({
					config: get(tile.config(), 'redirectOnSave', {}),
					fallback: [
						'assessment-properties',
						{ assessmentInstanceId: this.model.getId() }
					],
					otherwise: () => {
						// disable submit, user have to change something
						tile.form.disableSubmit();

						this.model.on('change', () => {
							tile.form.enableSubmit();
						});
					}
				});
				appContext.trigger('assessment-instance.create', this.model);
			},
			fields,
			buttons: [{
				caption: t('Add'),
				type: 'submit',
				name: 'add-assessment-instance-submit',
				role: 'submit'
			}]
		});

		tile.setRoles({ tile, model });
		tile.onTreatmentChange({ tile, model });
		handleReady(tile);
		tile.handleComplete({ tile, model });
	},

	setRoles: ({ tile, model }) => {
		model.set('roleId', null);
		const { treatment } = treatmentsData(model);
		const treatmentRoles = map(get(treatment, 'treatmentRoles', []), (role) => {
			let name = `${role.treatmentTypeRole.name} `;

			if (role.respondent) {
				name += role.respondent.isAccessible ?
					`[${respondentName(role.respondent)}]` :
					t('<no access to respondent>');

			} else if (role.administrator) {
				name += `[${clinicianName(role.administrator)}]`;
			}

			return assign({}, role, { name });
		});

		tile.treatmentRoles.reset(treatmentRoles);
		tile.trigger('treatment-roles.reset');
	},

	onTreatmentChange: ({ tile, model }) => {
		tile.listenTo(model, 'change:treatment', () => {
			tile.setRoles({ tile, model });
			tile.handleComplete({ tile, model });
		});
	},

	handleComplete: ({ tile, model }) => {
		if (model) {
			const { treatment } = treatmentsData(model);

			if (treatment && !treatment.complete) {
				tile.form.disableSubmit();
			}

		} else {
			tile.form.enableSubmit();
		}
	}
});
