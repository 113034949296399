import TileView from 'tile-view';
import datetime from 'datetime';
import Table from 'components/table/table';
import can from 'acl-can';
import t from 'translate';
import cardurl from 'cwcardurl';
import repository from 'repository';
import store from 'store';
import { assign, get } from 'lodash';

export default TileView.extend({
	title: t('Decisions'),
	acl: [{
		op: 'read',
		checkpoint: 'administrator.respondents.manualinputs'
	}],

	actions: ['decisions/init'],

	loaded: ({ tile }) => {
		assign(tile, {
			respondent: store.getters.respondent,
			treatment: store.getters.treatment,
			decisions: store.state.decisions.collection,
			roles: repository.getTreatmentRoles(store.getters.treatment.id),
			treatmentTypeRoles: repository.getTreatmentTypeRoles(
				store.getters.treatment.treatmentType.id
			)
		});

		tile.table = new Table({
			parent: tile.el,
			collection: tile.decisions,
			emptyListMessage: t('No decisions'),
			sortable: true,
			sortOrder: tile.config().tableSort,
			click: (item) => {
				cardurl.openCard('decision-properties', {
					decisionId: item.get('inputId')
				});
			},
			columns: [{
				label: t('decisions.labels.issueDate'),
				key: 'issueDate',
				render: (date, { item }) => datetime(item.get('issueDate'))
					.setTimezone(tile.respondent.respondentTimezoneName)
					.toMoment()
					.format(t('date.formats.dateTime'))
			}, {
				label: t('decisions.labels.role'),
				key: 'role',
				render: (role) => get(role, 'name', '')
			}, {
				label: t('decisions.labels.occupiedBy'),
				key: 'role',
				render: (role) => get(role, 'clinician.displayName', '')
			}, {
				label: t('decisions.labels.plan'),
				key: 'workflowInstanceName',
				render: (planInstanceName, { item }) => {
					if (can.read('administrator.respondents.workflowplans')) {
						return item.get('workflowInstanceName');
					}
				}
			}, {
				label: t('decisions.labels.question'),
				key: 'message',
				render: (message, { item }) => item.get('message')
			}, {
				label: t('decisions.labels.response'),
				key: 'value',
				render: (value, { item }) => item.getResponseText()
			}, {
				label: t('decisions.labels.admin'),
				key: 'administratorRespondent',
				render: (admin) => {
					if (admin) {
						return admin.displayName;
					}
				}
			}, {
				label: t('decisions.labels.responseDate'),
				key: 'responseDate',
				render: (responseDate) => {
					if (responseDate) {
						return datetime(responseDate)
							.setTimezone(tile.respondent.respondentTimezoneName)
							.toMoment()
							.format(t('date.formats.dateTime'));
					}
				}
			}]
		});
	}
});
