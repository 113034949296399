var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "export-template-tile" },
    [
      _c("tree-view", {
        attrs: {
          items: _vm.data,
          blueprints: _vm.blueprints,
          "download-url": _vm.downloadUrl,
          file: _vm.file,
          icons: _vm.icons,
          "on-add": _vm.onAdd,
          "save-template": _vm.saveTemplate,
          "tree-store-config": _vm.treeStoreConfig,
          types: _vm.types,
          "update-helpers": _vm.updateHelpers,
          name: "export-template"
        },
        on: { passButtonRefs: _vm.setButtonRefs }
      }),
      _vm._v(" "),
      _c("column-edit"),
      _vm._v(" "),
      _vm.wizard.show
        ? _c("columns-wizard", {
            attrs: {
              type: _vm.wizard.type,
              show: _vm.wizard.show,
              "on-close": _vm.onClose
            },
            on: { onSubmit: _vm.bulkAdd }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }