import * as urls from './urls';
import getResults from '../shared/get-results';
import { DELETE, POST, PUT } from 'service/ajax/ajax';

/**
 * @class Repository
 */
export default {

	/**
	 * Add new relation type.
	 *
	 * @function addRelationType
	 * @param {Object} data - Parameters.
	 * @param {string} data.name - Relation name.
	 * @param {string} data.description - Relation description.
	 * @param {string} data.respondent1RoleName - Name of the first respondent.
	 * @param {string} data.respondent2RoleName - Name of the second respondent.
	 * @param {boolean} data.substituted - Boolean whether a relation has a substitution.
	 * @param {boolean} data.permanent - Boolean whether a relation is permanent.
	 * @returns {Object} - RelationType.
	 * @example
	 *      repository.addRelationType({
	 * 			name: 'name',
	 * 			description: 'desc',
	 * 			respondent1RoleName: 'First',
	 * 			respondent2RoleName: 'Second',
	 * 			substituted: true,
	 * 			permanent: false
	 * 		});
	 */
	addRelationType: (data) => POST({ url: urls.relationTypes(), data }),

	/**
	 * Delete a relation type.
	 *
	 * @function deleteRelationType
	 * @param {number} id - Id of a relation type.
	 * @example
	 *        repository.deleteRelationType(1);
	 */
	deleteRelationType: (id) => DELETE({ url: urls.relationTypeId(id) }),

	/**
	 * Get a relation type based on its id.
	 *
	 * @function relationType
	 * @param {number} id - Id of a relation type.
	 * @returns {Object} - RelationType.
	 * @example
	 *        repository.relationType(1);
	 */
	relationType: (id) => {
		const url = urls.searchSingleRelationType(id);
		return getResults(url);
	},

	/**
	 * Get a list of all relations.
	 *
	 * @function getAllRelationTypes
	 * @returns {Array} - RelationType.
	 * @example repository.getAllRelationTypes();
	 */
	getAllRelationTypes: () => {
		const url = urls.relationTypes();
		return getResults(url);
	},

	/**
	 * Get a list of relation types based on filters.
	 *
	 * @function searchRelationTypes
	 * @param {Object} params - Parameters.
	 * @param {string} params.search - Name to be searched.
	 * @param {Array} params.filter - Array of parameters (types).
	 * @param {number} params.start - Number of a first result to be returned.
	 * @param {string} params.limit - Limit.
	 * @returns {Array} - RelationType.
	 * @example repository.searchRelationTypes(
	 * 		{ search: 'test', start: 0, limit: 10, filter: ['test'] }
	 * 	);
	 */
	searchRelationTypes: (params) => {
		const url = urls.searchRelationTypes(params);
		return getResults(url);
	},

	/**
	 * Update a relation type.
	 *
	 * @function updateRelationType
	 * @param {Object} data - Parameters.
	 * @param {string} data.name - Relation name.
	 * @param {string} data.description - Relation description.
	 * @param {string} data.respondent1RoleName - Name of the first respondent.
	 * @param {string} data.respondent2RoleName - Name of the second respondent.
	 * @param {boolean} data.substituted - Boolean whether a relation has a substitution.
	 * @param {boolean} data.permanent - Boolean whether a relation is permanent.
	 * @returns {Object} - RelationType.
	 * @example
	 *        repository.updateRelationType({
	 * 			name: 'name',
	 * 			description: 'desc',
	 * 			respondent1RoleName: 'First',
	 * 			respondent2RoleName: 'Second',
	 * 			substituted: true,
	 * 			permanent: false
	 * 		});
	 */
	updateRelationType: (data) => PUT({ url: urls.relationTypeId(data.id), data })
};
