<template>
	<div class="tile__content">
		<shortcuts-list
			:config="config"
		/>
	</div>
</template>

<script>
import config from './config';
import shortcutsList from '../../components/shortcuts-list';
import t from 'translate';
import { assign, map } from 'lodash';

const checkpoints = map([
	'system.settings.circles',
	'system.settings.circle-abuse-report-status',
	'system.settings.settings',
	'system.settings.administratorgroups',
	'system.settings.administratorattributes',
	'system.settings.administratorroles',
	'system.settings.flag-unraise-reasons',
	'system.settings.goals',
	'system.settings.respondentattributes',
	'system.settings.respondentgroups',
	'system.settings.contexts',
	'system.settings.consents',
	'system.settings.assessment-types',
	'system.settings.assessments',
	'system.settings.remotescoring',
	'system.settings.reports',
	'system.settings.contentmanagement',
	'system.settings.languages',
	'system.settings.links',
	'system.settings.treatment-types',
	'system.settings.treatment-roles',
	'system.settings.informationpackages',
	'system.settings.pages',
	'system.settings.media',
	'system.packages.packages',
	'system.settings.data-integration-code',
	'system.settings.data-integration-rules',
	'system.settings.export-templates',
	'system.settings.maintenance-mode',
	'system.settings.relation-types',
	'system.settings.adapter-instances'
], (checkpoint) => assign({}, { checkpoint, op: 'READ' }));

export default {
	title: t('System settings'),
	instant: true,
	acl: {
		op: 'or',
		checkpoints
	},
	components: { shortcutsList },
	data: () => ({ config })
};
</script>
