import Backbone from 'backbone';
import t from 'translate';
import shortcutBuilder from 'components/shortcut/shortcut-builder';

export default Backbone.View.extend({

	title: t('System reports'),
	acl: {
		op: 'or',
		checkpoints: [{
			checkpoint: 'system.reports.flags',
			op: 'READ'
		}, {
			checkpoint: 'system.reports.inactiverespondents',
			op: 'READ'
		}, {
			checkpoint: 'system.reports.unansweredmessages',
			op: 'READ'
		}, {
			checkpoint: 'system.reports.expiredassessments',
			op: 'READ'
		}]
	},

	render: function () {
		this.$el.append(shortcutBuilder(this.shortcutCfg));
		return this;
	},

	shortcutCfg: [{
		groupTitle: '',
		shortcuts: [{
			title: t('Flags'),
			card: 'system-reports-flags',
			icon: 'flag'
		}, {
			title: t('Inactive respondents'),
			card: 'system-reports-inactive-respondents',
			icon: 'respondent'
		}, {
			title: t('Overdue assessments'),
			card: 'system-reports-overdue-assessments',
			icon: 'overdue'
		}, {
			title: t('Unanswered messages'),
			card: 'system-reports-unanswered-messages',
			icon: 'message'
		}]
	}]
});
