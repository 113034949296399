import DenialList from '../../treatments/tiles/treatment-denial-list/tile';
import addTreatment from './add-treatment/tile';
import editTreatment from './edit-treatment/tile';
import editTreatmentAttributes from './edit-treatment-attributes/tile';
import miniTreatmentList from './mini-treatment-list/tile';
import myTreatments from './my-treatments/tile';
import roles from './roles/tile';
import roleSetRoles from './role-set-roles/tile';
import roleSets from './role-sets/tile';
import search from './search-treatments/tile';
import treatmentAttribute from './treatment-attribute';
import treatmentClinicians from './treatment-clinicians/tile';
import treatmentLabel from './treatment-label/tile';
import treatmentList from './treatment-list/tile';
import treatmentProgress from './treatment-progress';
import treatmentQuickSearch from './treatment-quick-search/tile';
import treatmentRoles from './treatment-roles/tile';
import treatmentOverview from './treatment-overview/tile';

export default {
	'add-treatment': addTreatment,
	'edit-treatment': editTreatment,
	'edit-treatment-attributes': editTreatmentAttributes,
	'mini-treatment-list': miniTreatmentList,
	'my-treatments': myTreatments,
	roles,
	'role-set-roles': roleSetRoles,
	'role-sets': roleSets,
	'search-treatments': search,
	'treatment-attribute': treatmentAttribute,
	'treatment-clinicians': treatmentClinicians,
	'treatment-label': treatmentLabel,
	'treatment-list': treatmentList,
	'treatment-progress': treatmentProgress,
	'treatment-quick-search': treatmentQuickSearch,
	'treatment-roles': treatmentRoles,
	'treatment-overview': treatmentOverview,
	'treatment-denial-list': DenialList
};
