import store from 'store';
import TileView from 'tile-view';
import t from 'translate';

export default TileView.extend({
	title: t('Clinician groups'),

	actions: () => {
		const userId = store.state.user.userId;

		return [
			['clinician/initAssignedGroups', { clinicianId: userId }]
		];
	},

	Vue: () => ({
		data: {
			fields: ['name'],
			labels: {
				name: t('Clinician group'),
				empty: t('No clinician groups assigned'),
				removeItem: t('Remove clinician group'),
				removeQuestion: t('Are you sure you want to delete this clinician group'),
				removeSuccess: t('Clinician group has been deleted'),
				selectItem: t('Select clinician group or type a name'),
				selectLabel: t('Limit to clinician group'),
				addSuccess: t('Clinician group has been added')
			},
			getters: {
				allItems: 'clinician/assignedGroups',
				items: 'exportTemplate/clinicianGroups'
			},
			actions: {
				removeItem: 'exportTemplate/deleteAndRefreshClinicianGroup',
				addItem: 'exportTemplate/addAndRefreshClinicianGroup'
			}
		},
		template: `
			<assign-items 
				:fields="fields"
				:labels="labels"
				:getters="getters"
				:actions="actions"
				:mandatory="true"
			/>
		`
	})
});
