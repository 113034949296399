var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mini-create-assessment-tile" }, [
    _c(
      "div",
      {
        staticClass:
          "mini-create-assessment-tile__link mini-create-assessment-tile__container",
        attrs: { tabindex: "0" },
        on: {
          click: _vm.createAssessmentInstance,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.createAssessmentInstance($event)
          }
        }
      },
      [
        _c("p", { staticClass: "mini-create-assessment-tile__title" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }