import showAddExportTemplateDialog from '../dialogs/export-template-new';
import can from 'service/acl/acl-can';
import t from 'translate';
import appBar from 'app-bar';
import { SYSTEM } from 'service/acl/checkpoints.json';

const card = {
	title: t('export-templates.card-title'),
	name: 'export-templates',
	appEvents: 'export-template.delete',
	url: 'export-templates',

	ctrl: ({ populate }) => {
		if (can.add(SYSTEM.SETTINGS.EXPORT_TEMPLATES)) {
			appBar.addButton(card.name, {
				title: t('Add new export template'),
				icon: 'add',
				click: () => {
					showAddExportTemplateDialog();
				}
			});
		}

		populate();
	}
};

export { card as ExportTemplates };
