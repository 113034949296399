import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import { isFunction, isObject } from 'lodash';

export default CounterMiniTile.extend({
	acl: [{
		checkpoint: 'administrator.respondents.flags',
		op: 'READ'
	}],
	icon: 'flag',
	important: true,

	init: function () {
		CounterMiniTile.prototype.init.apply(this, arguments);
		this.config = isFunction(this.config) && isObject(this.config()) ? this.config() : {};
	},

	caption: function () {
		return !!this.config.caption ? t(this.config.caption) : t('Flag(s)');
	},

	targetCard: function () {
		var targetCard = this.config.targetCard;
		var params = this.cardContext().get('id') ? {
			id: this.cardContext().get('id')
		} : {};
		return [targetCard, params];
	},

	loaded: function () {
		this.count = this.resource.value();
		this.redraw();
	}
});
