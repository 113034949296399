import sharedDialog from '../shared/dialog';
import { required } from 'vuelidate/lib/validators';

export default (modalCfg) => {
	const validations = {
		item: {
			code: {
				required
			},
			enterpriseId: {
				required
			},
			name: {
				required
			},
			secret: {
				required
			}
		}
	};
	return { ...sharedDialog({ modalCfg, validations }) };
};
