import MultipleEntities from '../shared/multiple-entities';
import ReportInstance from './instance';
import urls from './urls';

/**
 * ReportInstances Entity
 * @class ReportInstances
 * @extends MultipleEntities
 */
const ReportInstancesEntity = MultipleEntities.extend({
	model: ReportInstance,
	urls,
	idName: 'reportId',
	eventNamespace: 'report-instance',

	/**
	 * Get generated Reports
	 * @method getGenerated
	 * @return {Object} new {{#crossLink "Reports"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Reports.getGenerated();
	 */
	getGenerated: function () {
		return this.filter((model) => model.isGenerated());
	},

	getByAssessmentInstanceId: function (assessmentInstanceId) {
		return this.retrieve('reportsByAssessmentId', assessmentInstanceId);
	}
});

export default new ReportInstancesEntity();
