import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import MessageAttachment from './message-attachment';

/**
 * MessageAttachments Entity
 * @class MessageAttachments
 * @extends MultipleEntities
 */
const MessageAttachments = new (MultipleEntities.extend({
	model: MessageAttachment,
	urls,
	eventNamespace: 'message-attachment'
}))();

export { MessageAttachments };
