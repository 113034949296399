/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */
import cwalert from 'cwalert';
import t from 'translate';
import $ from 'jquery';
import { forEach } from 'lodash';

export default function (attributeName, model, options, mode = {}) {
	const __select = document.createElement('select');

	if (options.readonly) {
		$(__select).attr('disabled', 'disabled');
	}

	const __map = options.displayValue.getMap();
	const __options = {};

	let __selected = undefined;

	forEach(__map, (item, key) => {
		const opt = document.createElement('option');
		opt.innerHTML = item;
		opt.value = key;

		if (!__selected) {
			__selected = opt;
		}

		__select.appendChild(opt);

		__options[key] = opt;
	});

	/* update model state on value change */
	$(__select).on('change', function () {
		__selected = (__select.selectedIndex === -1) ?
			undefined :
			__select.options[__select.selectedIndex];
		model.set(attributeName, __selected.value);
		$(this).trigger('blur');

		if (!options.disableAutoSave) {
			model.save({}, {

				success () {
					cwalert.custom(t('general-list.ChangesSaved'), 'success');
				},

				error () {
					cwalert.custom(t('general-list.ChangesNotSaved'), 'error');
				}
			});
		}
	});

	/* refresh the displayed value */
	const __refresh = function () {
		if (model.get(attributeName) === undefined) {
			model.set(attributeName, __selected.value);
			return;
		}

		if (mode.edit) {
			if (model.get(attributeName) !== __selected.value) {
				model.set(attributeName, __selected.value, { silent: true });
			}

		} else {
			const key = model.get(attributeName);
			let opt = __options[key];

			if (!opt) {
				opt = document.createElement('option');
				opt.innerHTML = options.displayValue.getDisplayValue(key);
				opt.value = key;

				__select.appendChild(opt);

				__options[key] = opt;
			}

			opt.selected = true;
		}
	};

	model.on(`change:${attributeName}`, __refresh);

	__refresh();

	this.getDOMNode = function () {
		return __select;
	};
}
