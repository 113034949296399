import t from 'translate';
import repository from 'repository';
import { when } from 'jquery';
import Page from 'repo/pages/entity';

const card = {
	title: t('Add page'),
	name: 'add-page',
	url: 'page/add',

	ctrl: ({ cardData }) => {
		const page = new Page();
		const categories = repository.getPageCategories();
		cardData.set({ page, categories });

		return when(page, categories);
	}
};

export { card as Add };
