import Row from './row';
import t from 'translate';
import Collection from '../Collection';
import $ from 'jquery';
import { every, forEach } from 'lodash';

const Table = function (params = {}) {
	const rootAttribute = params.rootAttribute;

	const callbacks = {
		onRowClick: params.onRowClick
	};

	let readonly = params.readonly;

	if (params.aclEntryAttributeName) {
		readonly = {
			aclEntryAttributeName: params.aclEntryAttributeName
		};
	}

	const __inst = this;
	const __records = document.createElement('div');
	const __grid = document.createElement('div');
	const __table = document.createElement('table');

	let __container = undefined;
	let __firstLoad = true;

	const __ajaxLoader = document.createElement('div');
	__ajaxLoader.className = 'ajax-loader';

	const __ajaxLoaderText = document.createElement('h3');
	__ajaxLoaderText.innerHTML = t('general-list.Loading');
	__ajaxLoaderText.className = 'ajax-loader-text';
	__ajaxLoader.appendChild(__ajaxLoaderText);

	const __ajaxLoaderImg = document.createElement('img');
	__ajaxLoaderImg.src = 'images/ajax-loader.gif'; // @TODO: get this from settings object
	__ajaxLoader.appendChild(__ajaxLoaderImg);

	this.displayAjaxLoader = function () {
		if (__records.parentNode) {
			__records.parentNode.removeChild(__records);
		}
		__grid.appendChild(__ajaxLoader);

		return this;
	};

	this.getTableNode = function () {
		return __table;
	};

	this.removeAjaxLoader = function () {
		if (__ajaxLoader.parentNode) {
			__ajaxLoader.parentNode.removeChild(__ajaxLoader);
		}
		__grid.appendChild(__records);

		return this;
	};

	/*
	 * Table placement in the Document.
	 * May be given in the constructor params: container.
	 * May be changed at all time using setContainer(parent) method.
	 */
	this.setContainer = function (parentNode) {
		__container = parentNode;

		if (__firstLoad) {
			this.displayAjaxLoader();

		}
	};

	let __tableContainer = params.container;

	if (!__tableContainer) {
		__tableContainer = document.createElement('div');
		__tableContainer.className = 'cwview';

		if (params.tagId) {
			__tableContainer.setAttribute('id', params.tagId);
		}
	}

	if (params.header) {
		const __header = document.createElement('h1');
		__header.innerHTML = params.header;
		__tableContainer.appendChild(__header);
	}

	const __topToolbar = document.createElement('div');
	__topToolbar.className = 'top toolbar';
	__tableContainer.appendChild(__topToolbar);

	__grid.className = 'grid';
	__tableContainer.appendChild(__grid);

	this.getDOMNode = function () {
		return __grid;
	};

	__records.className = 'records';
	__grid.appendChild(__records);

	__table.className = `cwtable ${params.css || ''}`;
	$(__table).addClass(params.style);
	__records.appendChild(__table);

	const __noRecordsBox = document.createElement('div');
	__noRecordsBox.className = 'no-records';
	__records.appendChild(__noRecordsBox);
	$(__noRecordsBox).hide();

	const __noRecordsMsg = document.createElement('p');
	__noRecordsMsg.innerHTML = t('general-list.NoRecordsFound');
	__noRecordsBox.appendChild(__noRecordsMsg);

	const __tHead = document.createElement('thead');
	__table.appendChild(__tHead);

	const __tHeadTr = document.createElement('tr');
	__tHead.appendChild(__tHeadTr);

	const columns = params.columns;

	if (!params.columns) {
		console.alert('columns definition is required');
	}

	const __headers = {};
	const __fields = {};

	const __addHeader = function (label, className) {
		const header = {
			label: document.createTextNode(label),
			th: document.createElement('th')
		};

		header.label.nodeValue = label;

		if (className) {
			header.th.className = className;
		}
		header.th.appendChild(header.label);

		__tHeadTr.appendChild(header.th);

		return header;
	};

	const __actionsCount = {};
	const __actions = {};

	/* group actions by location (row left, right or other) */
	if (params.actions) {
		forEach(params.actions, (item, action) => {
			const location = item.location ?
				item.location :
				'right';

			if (!__actions[location]) {
				__actionsCount[location] = 0;
				__actions[location] = {};
			}

			__actions[location][action] = item;
			__actionsCount[location]++;
		});
	}

	if (__actions.left) {
		__addHeader(' ');
	}

	forEach(columns, (item, attributeName) => {
		__headers[attributeName] = __addHeader(item.label, item.className);

		__fields[attributeName] = {
			rendererFactory: item.rendererFactory
		};
	});

	if (__actions.right) {
		__addHeader(' ');
	}

	const __tBody = document.createElement('tbody');
	__table.appendChild(__tBody);

	this.detach = function () {
		if (__tableContainer.parentNode) {
			__tableContainer.parentNode.removeChild(__tableContainer);
		}

		this.setAutoRefresh(false);

		return this;
	};

	/**
	 * Collection represented by the table.
	 * May be given in the constructor params: collection.
	 * May be changed at any time.
	 * Changing the collection will cause every current item to be removed first and a new internal
	 * list will be created.
	 */
	let __collection = undefined;
	let __rows = [];
	const __rowAttributes = params['row-attributes'];

	let backboneCollection;

	/**
 * Repaints the table using fresh models from the server.
* This function is used by the latter refresh method.
*
* This one is trick because it has to:
* do not touch the dom structure if nothing changed
* do not recreate rows already exist
* delete rows that are no longer present in the current collection state
* undelete previously deleted rows if they appear again in the collection
* maintain the collection order (also if the order has changed).
 *
 * @example
 * @param params
 */
	const __repaint = function (params) {
		if (__firstLoad) {
			__inst.removeAjaxLoader();

			__firstLoad = false;
		}

		const models = params.data;
		const order = params.order;

		if (order.length === 0) {
			$(__noRecordsBox).show();
			$(__table).hide();

		} else {
			$(__noRecordsBox).hide();
			$(__table).show();
		}

		// check whenever __rows and __order are equal order. if so then return.

		if (order.length === __rows.length) {
			let eq = true;
			every(order, (item, i) => {
				if (item !== __rows[i].getModelId()) {
					eq = false;

					return false;
				}

				return true;
			});

			if (eq) {
				return true;
			}
		}

		// create correct order list using existing rows if possible and creating new ones whenever
		// neccesary

		// row map
		// deatch all rows
		const __rowMap = {};
		forEach(__rows, (item) => {
			__rowMap[item.getModelId()] = item;
			item.detach();
		});

		// append every row in actual order
		__rows = [];
		forEach(order, (id) => {
			const model = models[id];

			if (__rowMap[id]) {
				__tBody.appendChild(__rowMap[id].getTr());
				__rows.push(__rowMap[id]);

			} else {
				__rows.push(new Row({
					columns,
					rootAttribute,
					'container': __tBody,
					'fields': __fields,
					model,
					'actions': __actions,
					'actionsCount': __actionsCount,
					'collection': backboneCollection,
					'onRowClick': callbacks.onRowClick,
					readonly,
					'row-attributes': __rowAttributes
				}));
			}
		});

		// voila ! :)
		return;
	};

	this.getCollection = function () {
		return __collection;
	};

	this.setCollection = function (collection) {
		__collection = new Collection(collection, {
			fetchParams: params.fetchParams,
			fetchType: params.fetchType
		});
		backboneCollection = collection;

		/**
		 * Remove old item nodes from the table.
		 * Together with replacing the __rows with an empty array it should let garbage collector
		 * do its work.
		 */
		forEach(__rows, (item) => {
			item.detach();
		});

		__rows = [];

		backboneCollection.on('add', () => {
			__collection.refresh();
		});

		__collection.on('fetch', (p) => {
			__repaint(p);

			if (typeof params.onLoad == 'function') {
				params.onLoad.call(__inst, p);
			}
		});

		__collection.on('empty', () => {
			$(__noRecordsBox).show();
			$(__table).hide();
		});

		return this;
	};

	/**
 * Sync the collection with the server
* and refresh the table view.
*
* This is an asynchronus operation.
*
* Important: This function will be called in window context as an interval handler and
* therefore must not relay on 'this'.
 *
 * @example
 */
	this.refresh = function () {
		if (__collection === undefined) {
			throw 'Collection is undefined.';
		}
		__collection.refresh();

		return this;
	};

	/**
	 * If collection parameter was supplied then fetch the collection and display its representation.
	 */
	if (params.collection) {
		this.setCollection(params.collection);

		if (!params.noFetch) {
			this.refresh();

		} else {
			__collection.reload();
		}
	}

	/**
 * Enables or disables auto refresh of the table.
 *
 * @param interval - Interval in miliseconds. False to disable auto refresh.
 * @example
 */
	this.setAutoRefresh = function (interval) {
		__collection.setAutoRefresh(interval);

		return this;
	};

	const __tableActions = document.createElement('div');
	__topToolbar.appendChild(__tableActions);
	__tableActions.className = 'table-actions';

	if ((typeof params.newBtn == 'object') && (!params.newBtn.disabled)) {
		const newBtn = document.createElement('a');
		__tableActions.appendChild(newBtn);
		newBtn.id = params.newBtn.id;
		newBtn.tabIndex = 0;

		if (params.newBtn.className) {
			newBtn.className = params.newBtn.className;
		}

		if (params.newBtn.href) {
			newBtn.href = params.newBtn.href;
		}

		if (params.newBtn.iconClass) {
			const icon = document.createElement('i');
			icon.className = params.newBtn.iconClass;

			newBtn.appendChild(icon);
		}

		if (params.newBtn.click) {
			$(newBtn).on('click', params.newBtn.click);
		}

		if (params.newBtn.label) {
			const label = document.createElement('span');
			label.innerHTML = params.newBtn.label;

			newBtn.appendChild(label);
		}
	}

	if (params.container) {
		this.setContainer(params.container);
	}
};

export default Table;
