import { GET, PUT, PATCH } from 'service/ajax/ajax';

export default {
	rawGET: (url) => GET({
		url,
		cache: true,
		prepend: false,
		dataPath: '',
		headers: {
			Accept: 'application/json'
		}
	}),

	rawPUT: (url, payload) => PUT({
		url,
		prepend: false,
		data: payload
	}),
	rawPATCH: (url, payload) => PATCH({
		url,
		prepend: false,
		data: payload
	})
};
