import appBar from 'app-bar';
import t from 'translate';

const card = {
	name: 'system-settings-respondent-attributes-add',
	title: t('Add respondent attribute'),
	url: 'system/respondent/attributes/add',

	controller () {
		this.populate();

		appBar.addCardLink(card.name, {
			card: 'system-settings-respondent-attributes',
			title: t('System settings - respondent attributes'),
			icon: 'attribute'
		});
	}
};

export { card as addRespondentAttribute };
