import TileView from 'tile-view';
import store from 'store';

import cardUrl from 'cwcardurl';
import { respondentName } from 'service/display-name/display-name';
import { map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import quickSearch from 'components/quick-search/quick-search';
import t from 'translate';

export default TileView.extend({
	title: t('Respondent search'),
	actions: (tile) => [['respondentSearch/setLimit', tile.config().limit || 10]],
	acl: [{
		checkpoint: 'administrator.respondents.respondents',
		op: 'read'
	}],

	Vue: () => ({
		data: {
			loading: false,
			placeholder: '',
			url: ''
		},
		components: {
			quickSearch
		},
		computed: {
			...mapGetters({ exceeded: 'respondentSearch/exceeded' }),
			...mapGetters({ limit: 'respondentSearch/limit' }),
			...mapGetters({ total: 'respondentSearch/total' }),
			list () {
				let list = store.getters['respondentSearch/list'];

				if (list.length) {
					list = this.provideListData(list);
				}
				this.loading = false;
				return list;
			}
		},
		methods: {
			...mapActions('respondentSearch', ['provideResults', 'setSearchList']),
			clearResults () {
				this.setSearchList([]);
			},
			getResults (search) {
				this.loading = true;
				const params = this.provideParams(search);
				this.provideResults(params);
				this.url = this.provideMoreUrl(search);
			},
			provideListData: (list) => map(list, (item) => ({
				rows: [{ name: respondentName(item) }],
				url: cardUrl.buildUrl('respondent-status', { respondentId: item.respondentId })
			})),
			provideMoreUrl: (search) => cardUrl.buildUrl('search-respondents', { search }),
			provideParams (search) {
				return {
					search,
					start: 0,
					limit: this.limit
				};
			},
			submit: (search) => cardUrl.openCard('search-respondents', { search })
		},
		created () {
			this.url = this.provideMoreUrl('');
		},
		template:
		`<quick-search
			:exceeded="exceeded"
			:getResults="getResults"
			:list="list"
			:loading="loading"
			:placeholder="placeholder"
			:total="total"
			:url="url"
			:clear="clearResults"
			:submit="submit"
			:label="$t('Respondent search')"
		/>
		`
	})
});
