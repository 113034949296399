import { assign, forEach } from 'lodash';
import TileView from 'tile-view';
import $ from 'jquery';
import formView from 'components/form-view/form-view';
import can from 'acl-can';
import t from 'translate';
import repository from 'repository';
import ClinicianAttributeValue from 'repo/clinicians/attribute-value';
import store from 'store';

export default TileView.extend({
	title: t('admin-portal.Personalia'),
	acl: [{
		checkpoint: 'system.administrators.administrators',
		op: 'OPEN'
	}],
	actions: ['languages/init', 'timezones/init'],
	tileData: ({ tile }) => {
		const id = tile.cardContext().get('id');

		return {
			clinician: repository.clinician(id),
			attributeDefinitions: repository.getClinicianAttributeDefinitions(),
			attributeValues: repository.getClinicianAttributeValues(id),
			isYou: id === store.getters.userId
		};
	},

	loaded () {
		this.renderClinicianForm();
		this.renderAttributesForms();
	},

	renderClinicianForm () {
		store.dispatch('languages/setSelected', this.clinician.get('administratorLanguageId'));

		const cfg = {
			name: 'edit-clinician',
			model: this.clinician,
			readonly: !can.edit('system.administrators.administrators'),
			autocomplete: false,
			hideMandatory: true,

			fields: [{
				key: 'administratorFirstName',
				type: 'text'
			}, {
				key: 'administratorLastName',
				type: 'text',
				autocomplete: false
			}, {
				key: 'administratorUsername',
				type: 'paragraph',
				readonly: true,
				hint: t('general-list.userNameHint')
			}, {
				key: 'administratorPassword',
				type: 'password'
			}, {
				key: 'administratorLanguageId',
				type: 'select',
				label: t('Language'),
				values: store.getters['languages/available']
			}, {
				key: 'administratorPhone',
				type: 'text'
			}, {
				key: 'administratorEmail',
				type: 'email'
			}, {
				key: 'administratorTimezoneName',
				type: 'select',
				values: store.getters['timezones/list'],
				label: t('Timezone')
			}, {
				key: 'administratorAccountEnabled',
				type: 'checkbox',
				readonly: this.isYou || !can.edit('system.administrators.administrators')
			}, {
				key: 'administratorTestAccount',
				type: 'checkbox',
				readonly: this.isYou || !can.edit('system.administrators.administrators')
			}]
		};

		this.$el.html(formView(cfg).$el);
	},

	renderAttributesForms () {
		const $header = $('<h2 />')
			.addClass('form-view__fieldset__caption')
			.html(t('respondent-list.CustomAttributes'));

		if (!this.attributeDefinitions.size()) {
			return;
		}

		const formViews = [];

		// create new FormView instance for each custom attribute
		forEach(this.attributeDefinitions.getVisible(), (attributeDefinition) => {
			let attributeValue = this.attributeValues.find((attributeVal) => {
				const attributeMetaId =
					attributeVal.get('fk_administrator_attribute_administrator_attribute_meta_id');
				return +attributeMetaId === +attributeDefinition.getId();
			});

			const input = this.getInputByAttributeType(attributeDefinition);

			attributeValue = attributeValue || new ClinicianAttributeValue({
				fk_administrator_attribute_administrator_id: +this.clinician.getId(),
				fk_administrator_attribute_administrator_attribute_meta_id:
					+attributeDefinition.getId(),
				fkAdministratorAttributeAdministratorAttributeMeta: attributeDefinition.toJSON()
			});

			const cfg = {
				classNames: ['no-fieldset-margin'],
				model: attributeValue,
				hideMandatory: true,
				fields: [{
					key: 'administratorAttributeValue',
					label: attributeDefinition.getLabel(),
					readonly:
						!can.edit('system.administrators.administrators') ||
						attributeDefinition.isReadOnly(),
					type: input.type,
					description: input.description,
					mandatory: input.type !== 'checkbox' && attributeDefinition.isRequired()
				}]
			};

			if (input.type === 'textarea' || input.type === 'string') {
				cfg.max = 255; // Current DB limit
			}

			assign(cfg.fields[0], input.config);

			formViews.push(formView(cfg).$el);
		});

		this.$el.append($header, formViews);
	},

	getInputByAttributeType (attributeDefinition) {
		return {
			STRING: {
				type: 'text'
			},
			TEXTAREA: {
				type: 'textarea',
				config: {
					rows: attributeDefinition.getParameters()
				}
			},
			CHECKBOX: {
				type: 'checkbox'
			},
			NUMERIC: {
				type: 'number'
			},
			DATE: {
				type: 'date'
			},
			DROPDOWN: {
				type: 'select',
				description: t('Select a value'),
				config: {
					values (attrVal) {
						const meta =
							attrVal.get('fkAdministratorAttributeAdministratorAttributeMeta');
						const vals = meta.administratorAttributeMetaParameters.split(/;/);
						return vals;
					}
				}
			}
		}[attributeDefinition.getType()];
	}
});

