import Vue from 'vue';
import { get } from 'lodash';
import store from 'store';
import t from 'translate';
import datetime from 'datetime';

Vue.component('assessment-warning', {
	props: {
		submittedInAssessmentPortalAt: {
			type: [String, Boolean],
			default: ''
		},
		emptyRoleFlag: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		submittedWarning () {
			return t('This assessment has been submitted in Assessment Portal at <b>{time}</b>', {
				time: datetime(this.submittedInAssessmentPortalAt)
					.setTimezone(get(store.getters, 'respondent.respondentTimezoneName'))
					.toMoment()
					.format(t('date.formats.dateTime'))
			});
		}
	},
	template: `
		<div>
			<section
				v-if="submittedInAssessmentPortalAt"
				class="warning assessment-warning"
				v-html="submittedWarning"
			></section>
			<section 
				v-if="emptyRoleFlag" 
				class="warning assessment-warning" 
				v-translate
			>The assessment has been assigned to a role that is not occupied anymore</section>
		</div>
	`
});

export default () => new Vue({
	el: document.createElement('div'),
	data: {
		submittedInAssessmentPortalAt: get(
			store.state,
			'assessmentInstance.data.assessmentInstanceStatus.isSubmitted'
		) && get(store.state, 'assessmentInstance.data.submittedInAssessmentPortalAt'),
		emptyRoleFlag: get(store.state, 'assessmentInstance.data.emptyRoleFlag')
	},
	template: `<assessment-warning 
		:submittedInAssessmentPortalAt="submittedInAssessmentPortalAt"
		:emptyRoleFlag="emptyRoleFlag"
	/>`
});
