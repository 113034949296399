/**
 * @class Repository
 */
import { PATCH } from 'service/ajax/ajax';
import urls from './urls';

export default {

	/**
	 * Get a collection of all MessageDrafts for a given treatment.
	 *
	 * @function getDrafts
	 * @param {object} params            - Parameters.
	 * @param {number} params.treatment  - Treatment ID.
	 * @param {number} params.respondent - Respondent ID.
	 * @returns {object}                 - Collection.
	 * @example
	 * 		repository.getDrafts({
	 * 			treatment: 7,
	 * 			respondent: 19
	 * 		});
	 */
	messageDrafts ({ treatment, respondent }) {
		return this.MessageDrafts.getForTreatment({ treatment, respondent });
	},

	/**
	 * Get a collection of all Messages for a given treatment and respondent.
	 *
	 * @function getMessages
	 * @param {object} params              - Parameters.
	 * @param {number} params.treatmentId  - Treatment ID.
	 * @param {number} params.respondentId - Respondent ID.
	 * @returns {object}                   - Collection.
	 * @example
	 * 		repository.getMessages({
	 * 			treatment: 7,
	 * 			respondent: 19
	 * 		});
	 */
	messages ({ treatmentId, respondentId }) {
		return this.Messages.getForTreatment({ treatment: treatmentId, respondent: respondentId });
	},

	/**
	 * Get a collection of all unreplied Messages for current clinician.
	 *
	 * @function getUnreplied
	 * @returns {object} - Collection.
	 * @example
	 * 		repository.getUnrepliedMessages();
	 */
	unrepliedMessages () {
		return this.Messages.getUnreplied();
	},

	/**
	 *
	 * @param {object} params              - Parameters.
	 * @param {number} params.treatmentId  - Treatment ID.
	 * @param {number} params.respondentId - Respondent ID.
	 * @param {number} params.messageId    - Message ID.
	 * @param {boolean} params.answered    - Should the message be mark as answered.
	 * @returns {object}                   - Promise.
	 * @example
	 * 		repository.toggleMessageAnswer({
	 * 			respondentId: 272,
	 * 			treatmentId: 582,
	 * 			messageId: 900,
	 * 			answered: true
 	 * 		}
	 */
	toggleMessageAnswer: ({ respondentId, treatmentId, messageId, answered }) => PATCH({
		url: urls.single({ respondentId, treatmentId, messageId }),
		data: { answered }
	})
};
