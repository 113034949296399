import t from 'translate';
import repository from 'repository';
import can from 'acl-can';
import appBar from 'app-bar';
import appContext from 'app-context';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';
import { when } from 'jquery';
import store from 'store';
import { assign } from 'lodash';

const eventName = (suffixes) => suffixes.split(' ').map((suffix) =>
	`information-package-instance.${suffix}`).join(' ');

const card = {
	title: t('Information package properties'),
	name: 'content-package-properties',
	url: 'content-package/:contentPackageInstanceId',
	appEvents: {
		[eventName('state-change-start')] () {
			appBar.clear(card.name);
			this.setLoading();
		},
		[eventName('state-change-end')] ({ cpInstance, close }) {

			when(
				this.cardData.get('elements').fetch(),
				store.state.contentPackageInstance.model.fetch()
			).then(() => {
				this.setLoaded();
				card.mountButtons(cpInstance, close);
			});
		}
	},

	ctrl: ({ cardData, close, actions, populate }, { contentPackageInstanceId }) => {
		const elements = repository.contentPackageInstanceElements(contentPackageInstanceId);
		store.dispatch('respondent/resetId');
		store.dispatch('treatment/resetId');
		when(
			actions(['contentPackageInstance/init']),
			elements
		).then(() => {
			cardData.set({ elements });
			card.mountButtons(store.state.contentPackageInstance.model, close);
		});

		populate();
	},

	mountButtons (cpInstance, close) {
		if (!can.edit('administrator.respondents.informationpackages')) {
			return;
		}

		appBar.clear(card.name);

		const method = {
			true: 'mountDeactivateButton',
			false: 'mountActivateButton'
		}[cpInstance.isActive()];
		card[method](cpInstance, close);

		!cpInstance.isCompleted() && card.mountCompleteButton(cpInstance, close);

		card.mountDeleteButton(cpInstance, close);
	},

	mountActivateButton (cpInstance, close) {
		const buttonCfg = card.buttonCfgFactory({ cpInstance, action: 'activate', close });

		appBar.addButton(assign(buttonCfg, {
			icon: 'unlock',
			title: t('Activate information package')
		}));
	},

	mountDeactivateButton (cpInstance, close) {
		const buttonCfg = card.buttonCfgFactory({ cpInstance, action: 'deactivate', close });

		appBar.addButton(assign(buttonCfg, {
			icon: 'lock',
			title: t('Deactivate information package')
		}));
	},

	mountCompleteButton (cpInstance, close) {
		appBar.addButton({
			sourceCard: card.name,
			icon: 'check-circle',
			title: t('Mark information package as completed'),
			click: () => {
				appContext.trigger(eventName('state-change-start'));
				cpInstance.complete().then(() => {
					appContext.trigger(eventName('state-change-end'), { cpInstance, close });
					cwalert.success(t('Information package "{name}" has been marked as complete', {
						name: cpInstance.get('name')
					}));
				});
			}
		});
	},

	mountDeleteButton (cpInstance, close) {
		if (can.remove('administrator.respondents.informationpackages')) {
			appBar.addButton({
				sourceCard: card.name,
				title: t('Delete information package'),
				icon: 'delete',
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete information package'),
						message: t('Are you sure you want to delete the information package?')
					}).then(() => {
						cpInstance.destroy().then(() => {
							cwalert.success(t('Information package has been removed'));
							close();
						});
					});
				}
			});
		}
	},

	buttonCfgFactory ({ cpInstance, action, close }) {
		const buttonCfg = {
			sourceCard: card.name,
			click: () => {
				appContext.trigger(eventName('state-change-start'));
				cpInstance[action].call(cpInstance).then(() => {
					appContext.trigger(
						eventName('state-change-end complete'),
						{ cpInstance, close }
					);
					cwalert.success(t({
						activate: 'Information package "{name}" has been activated',
						deactivate: 'Information package "{name}" has been deactivated'
					}[action], {
						name: cpInstance.get('name')
					}));
				});
			},
			extend: (obj) => {
				assign(buttonCfg, obj);
				return buttonCfg;
			}
		};

		return buttonCfg;
	}
};

export { card as InstanceProperties };
