<template>
	<lockout-status
		v-if="status"
		:can-unlock="canUnlock"
		@unlock="onUnlockClick"
	/>
</template>

<script>
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints';
import { CLINICIAN_LOCK } from 'store/clinicians/clinician-lock';
import LockoutStatus from 'components/lockout-status';
import can from 'service/acl/acl-can';
import cwalert from 'service/cwalert';

export default {
	title: t('lockout.title'),

	actions: ({ $store }) => ([
		[CLINICIAN_LOCK.INIT, $store.getters.urlParams.id]
	]),

	acl: [{
		checkpoint: SYSTEM.SECURITY.LOCKS,
		op: READ
	}],

	components: {
		LockoutStatus
	},

	computed: {
		status: ({ $store }) => $store.getters[CLINICIAN_LOCK.DATA],
		canUnlock: () => can.delete(SYSTEM.SECURITY.LOCKS)
	},

	created () {
		if (!this.status.isLocked) {
			this.tile.disable();
		}
	},

	methods: {
		async onUnlockClick () {
			await this.$store.dispatch(
				CLINICIAN_LOCK.UNLOCK,
				this.$store.getters.urlParams.id
			);

			cwalert.success(this.$t('lockout.unlock-success'));

			this.tile.disable();
		}
	}
};
</script>
