import $ from 'jquery';
import TileView from 'tile-view';
import { Collection } from 'backbone';
import cardurl from 'cwcardurl';
import datetime from 'datetime';
import Table from 'components/table/table';
import t from 'translate';
import icon from 'service/icon';
import systemSettings from 'system-settings';
import can from 'acl-can';
import store from 'store';
import { assign, compact, get, split } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

const className = 'assessment-list__entry assessment-list__';

let anyAssessmentClosed = false;
const respondentTimezone = ({ tile }) => tile.respondent.getTimezone();

const formatDate = ({ date, tile }) => datetime(date)
	.setTimezone(respondentTimezone({ tile }))
	.toMoment()
	.format(t('date.formats.dateTime'));

const addOptional = (td, clsName) => {
	const $td = $(td);
	const labelClass = `.${split(className + clsName, ' ').join('.')}`;
	const $label = $td.siblings(labelClass);
	$td.add($label).addClass('optional');
};

export default TileView.extend({
	title: t('Assessments'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.RESPONDENTS,
		op: READ
	}],

	actions: () => compact([
		'assessmentInstances/init',
		'respondent/init',
		'treatments/initForRespondent',
		can.read(CLINICIAN.RESPONDENTS.PLANS) &&
		'planInstances/init',
		'types/init'
	]),

	tableCfg: ({ tile }) => ({
		className: 'table--long-text',
		collection: tile.assessmentInstances,
		parent: tile.el,
		emptyListMessage: t('general-list.NoAssessmentHistory'),
		sortable: true,
		sortOrder: tile.config().tableSort,
		extractText: ({ content, $el }) => {
			// eslint-disable-next-line lodash/prefer-lodash-method
			const $icon = $el.find('.icon');

			if ($icon.length) {
				return $icon.attr('title');
			}
			return content;
		},
		click: (item) => {
			cardurl.openCard('assessment-properties', {
				assessmentInstanceId: item.id
			});
		},
		columns: [{
			key: 'isSeen',
			label: t('Seen'),
			css: `${className}seen`,
			render: (isSeen, { item }) => {
				if (item.isSubmitted()) {
					if (item.get('isSeen')) {
						return icon('check-circle', {
							modifier: 'success',
							title: t('Assessment has been seen')
						});
					}

					return icon('times-circle', {
						modifier: 'failure',
						title: t('Assessment has not been seen')
					});

				}
				return icon('minus-circle', {
					title: t('Assessment is not submitted')
				});
			}
		}, {
			key: 'assessmentTitle',
			label: t('general-list.Assessment'),
			css: `${className}title`
		}, {
			key: 'assessmentInstanceStartDate',
			label: t('general-list.StartDate'),
			css: `${className}start-date`,
			render: (date) => formatDate({ date, tile })
		}, {
			key: 'assessmentInstanceEndDate',
			label: t('general-list.EndDate'),
			css: `${className}end-date`,
			render: (date) => formatDate({ date, tile })
		}, {
			key: 'planInstanceId',
			label: t('Related plan'),
			css: `${className}plan`,
			skip: !can.read(CLINICIAN.RESPONDENTS.PLANS),
			render: (planInstanceId, { td }) => {
				let plan;

				if (planInstanceId) {
					plan = tile.planInstances.get(planInstanceId);
				}

				if (plan) {
					return $('<a />')
						.addClass('card-link')
						.text(plan.get('name'))
						.attr('href', cardurl.buildUrl(
							'plan-properties',
							{ planInstanceId }
						))[0] || '';

				}
				addOptional(td, 'plan');
				return '';
			}
		}, {
			key: 'treatment',
			label: t('Treatment'),
			skip: () => tile.treatments.size() <= 1,
			css: `${className}treatment`,
			render: (treatment) => {
				const currentTreatment = tile.treatments.get(treatment);
				return (currentTreatment && currentTreatment.get('name')) || '';
			}
		}, {
			label: t('Type'),
			skip: () => !systemSettings.getBoolean('ASSESSMENT_TYPES'),
			css: `${className}type`,
			render: (type, { item }) => {
				if (item.get('type')) {
					const type = tile.types.findWhere({ id: item.get('type').toString() });
					return type.get('label');
				}

				return '';
			}
		}, {
			label: t('general-list.Context'),
			key: 'assessmentInstanceContextLabel',
			css: `${className}context`,
			render: (contextLabel = '', { td }) => {
				if (!contextLabel.length) {
					addOptional(td, 'context');
				}
				return contextLabel || '';
			}
		}, {
			key: 'assessmentInstanceLastModified',
			label: t('general-list.DelieveredDate'),
			css: `${className}delivered`,
			render: (lastModified, { item, td }) => {
				const isSubmitted = item.isSubmitted();

				if (isSubmitted) {
					let content = item.getLastModifiedDate() ?
						datetime(item.getLastModifiedDate())
							.setTimezone(respondentTimezone({ tile }))
							.toMoment()
							.format(t('date.formats.dateTime')) :
						'';

					if (item.isClosed()) {
						anyAssessmentClosed = true;
						content += ' (*)';
					}
					return content;

				}
				addOptional(td, 'delivered');
				return t('Assessment not delivered');
			}
		}]
	}),

	loaded: ({ tile }) => {
		assign(tile, {
			assessmentInstances: get(store.state, 'assessmentInstances.collection'),
			types: get(store.state, 'types.collection'),
			treatments: get(store.state, 'treatments.collection'),
			respondent: get(store.state, 'respondent.model'),
			planInstances: can.read(CLINICIAN.RESPONDENTS.PLANS) ?
				get(store.state, 'planInstances.collection') :
				new Collection()
		});

		tile.assessmentInstances.comparator = (a, b) =>
			a.get('assessmentInstanceStartDate').unixtime -
			b.get('assessmentInstanceStartDate').unixtime;

		tile.assessmentInstances.sort();

		tile.table = new Table(tile.tableCfg({ tile }));

		if (anyAssessmentClosed) {
			const $closedNote = $('<span />')
				.addClass('assessment-list__disclaimer')
				.html(t(
					'(*) A delivery date marked with this symbol means ' +
					'the assessment has been explicitly {i1}closed{i2}', {
						i1: '<i>',
						i2: '</i>'
					}
				));
			tile.$el.append($closedNote);
		}
	}
});

