var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.repeated
        ? _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "data-integration__repeated-warning"
            },
            [_vm._v("dataIntegration.dataMapping.messages.repeated")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          invalid: _vm.vuelidate.item.superAssessment.$error,
          label: "dataIntegration.dataMapping.fields.assessment",
          mandatory: true,
          options: _vm.assessmentOptions,
          placeholder: _vm.t(
            "dataIntegration.dataMapping.actions.selectAssessment"
          ),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.superAssessment.$touch()
          }
        },
        model: {
          value: _vm.item.superAssessment,
          callback: function($$v) {
            _vm.$set(_vm.item, "superAssessment", $$v)
          },
          expression: "item.superAssessment"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          invalid: _vm.vuelidate.item.submitPortal.$error,
          label: "dataIntegration.dataMapping.fields.portal",
          mandatory: true,
          options: _vm.portalOptions,
          placeholder: _vm.t(
            "dataIntegration.dataMapping.actions.selectPortal"
          ),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.submitPortal.$touch()
          }
        },
        model: {
          value: _vm.item.submitPortal,
          callback: function($$v) {
            _vm.$set(_vm.item, "submitPortal", $$v)
          },
          expression: "item.submitPortal"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: _vm.updating,
          label: "dataIntegration.dataMapping.fields.codeType",
          mandatory: true,
          options: _vm.codeTypes,
          placeholder: _vm.t(
            "dataIntegration.dataMapping.actions.selectCodeType"
          ),
          searchable: false
        },
        model: {
          value: _vm.codeType,
          callback: function($$v) {
            _vm.codeType = $$v
          },
          expression: "codeType"
        }
      }),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          "class-prefix": _vm.classPrefix,
          "container-class": "select-container",
          disabled: !_vm.codeType,
          label: "dataIntegration.dataMapping.fields.code",
          mandatory: true,
          options: _vm.codes,
          placeholder: _vm.t("dataIntegration.dataMapping.actions.selectCode"),
          searchable: false
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.code.$touch()
          }
        },
        model: {
          value: _vm.item.code,
          callback: function($$v) {
            _vm.$set(_vm.item, "code", $$v)
          },
          expression: "item.code"
        }
      }),
      _vm._v(" "),
      _c("radio-input", {
        attrs: {
          "value-name": true,
          label: "dataIntegration.ruleSets.fields.allowed",
          "class-prefix": "data-integration"
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.access.$touch()
          }
        },
        model: {
          value: _vm.item.access,
          callback: function($$v) {
            _vm.$set(_vm.item, "access", $$v)
          },
          expression: "item.access"
        }
      }),
      _vm._v(" "),
      _c("radio-input", {
        attrs: {
          "value-name": false,
          label: "dataIntegration.dataMapping.fields.denied",
          "class-prefix": "data-integration"
        },
        on: {
          input: function($event) {
            return _vm.vuelidate.item.access.$touch()
          }
        },
        model: {
          value: _vm.item.access,
          callback: function($$v) {
            _vm.$set(_vm.item, "access", $$v)
          },
          expression: "item.access"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }