/**
 * @author Marcin Malinowski
 * @copyright Checkware (c) 2012
 *
 * This widget represents a texttual model attribute.
 * It can be editable or readonly mode.
 *
 */

import $ from 'jquery';
import cwalert from 'cwalert';
import t from 'translate';

export default function (attributeName, model, options, mode = {}) {
	const __input = document.createElement('input');
	__input.setAttribute('type', 'text');
	__input.required = options.required || false;
	__input.className = 'input-medium';

	if (options.readonly) {
		$(__input).attr('readonly', 'readonly');
	}

	if (options.required) {
		$(__input).addClass('required');
	}

	let __saveTimer;

	$(__input).on('change', () => {
		clearTimeout(__saveTimer);
		__saveTimer = setTimeout(() => {

			if (__input.value !== '' || !__input.required) {
				model.set(attributeName, __input.value);

				if (!options.disableAutoSave) {
					model.save({}, {
						success () {
							cwalert.custom(t('general-list.ChangesSaved'), 'success');
						},

						error () {
							cwalert.custom(t('general-list.ChangesNotSaved'), 'error');
						}
					});
				}
			}

		}, 300);
	});

	const __refresh = function () {
		if (model.get(attributeName) === undefined) {
			model.set(attributeName, '');
			return;
		}

		if (mode.edit) {
			if (model.get(attributeName) !== __input.value) {
				model.set(attributeName, __input.value, { silent: true });
			}

		} else {
			__input.value = model.get(attributeName);
		}

		// flash the cell when changed remotely ?

	};

	/* binding the refresh if value of model attribute has changed */
	model.on(`change:${attributeName}`, __refresh);

	__refresh();

	this.getDOMNode = function () {
		return __input;
	};
}
