var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-treatment-program__container",
      class: { "is-active": _vm.isActive }
    },
    [
      _c("div", { staticClass: "edit-treatment-program__container-header" }, [
        _c("div", [
          _c(
            "h4",
            {
              staticClass:
                "\n\t\t\t\t\tedit-treatment-program__container-title\n\t\t\t\t\tedit-treatment-program__container-title--module\n\t\t\t\t"
            },
            [
              _c(
                "button",
                {
                  attrs: {
                    title: _vm.$t("treatmentProgram.module.editProperties")
                  },
                  on: { click: _vm.selectModule }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.$t("treatmentProgram.module.title", {
                          index: _vm.index + 1,
                          name: _vm.module.name
                        })
                      ) +
                      "\n\t\t\t\t"
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "edit-treatment-program__container-delete",
              attrs: { title: _vm.$t("treatmentProgram.module.deleteModule") },
              on: { click: _vm.deleteModule }
            },
            [_c("icon", { attrs: { name: "delete" } })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }