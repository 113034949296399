<template>
	<div>
		<text-input
			v-model="item.name"
			:disabled="!enabled"
			:required="true"
			:mandatory="true"
			:invalid="vuelidate.item.name.$error"
			:label="fieldName('name')"
			@input="vuelidate.item.name.$touch()"
		/>
		<text-input
			v-model="item.code"
			class-prefix="data-integration"
			:class-additional="codeInputClass"
			:disabled="!enabled"
			:required="true"
			:mandatory="true"
			:invalid="vuelidate.item.code.$error"
			:label="fieldName('code')"
			@input="vuelidate.item.code.$touch()"
		/>

	</div>
</template>

<script>
import t from 'translate';

export default {
	props: {
		enabled: {
			type: Boolean,
			default: true
		},
		item: {
			type: Object,
			required: true
		},
		type: {
			type: String,
			default: ''
		},
		vuelidate: {
			type: Object,
			required: true
		}
	},

	computed: {
		codeInputClass () {
			return this.enabled ? '' : 'input--opaque';
		}
	},

	methods: {
		fieldName (field) {
			const type = this.type === 'code' ? 'codes' : 'codeType';
			return `dataIntegration.${type}.fields.${field}`;
		},
		t
	}
};
</script>
