import { assign, forEach } from 'lodash';
import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import t from 'translate';
import store from 'store';
import { formCfgFactory } from '../../shared/index';
import { READ } from 'service/acl/access-levels';

export default TileView.extend({
	className: '',
	title: t('Assessment Portal'),

	acl: [{
		checkpoint: 'system.settings.settings',
		op: READ
	}],

	tileData: () => ({
		systemSettings: store.getters['systemSettings/assessment']
	}),

	loaded: ({ tile }) => {
		const formViews = [];

		// create new FormView instance for each custom attribute
		forEach(tile.systemSettings, (setting) => {
			const label = setting.systemSettingLabel;

			const cfg = formCfgFactory({ setting });

			if (label === 'assessment-portal.send-code.text') {
				const description = t('system-settings.assessment-portal.smsDescription');
				assign(cfg.fields[0], {
					hint: `${cfg.fields[0].hint}<br />${description}`,
					rows: 5
				});
			}

			formViews.push(formView(cfg).$el);
		});

		tile.$el.append(formViews);
	}
});

