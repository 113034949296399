<template>
	<section class="maintenance-mode__container" :class="containerClass">
		<h3>{{title}}</h3>
		<div v-if="maintenanceMode.active">
			<span v-translate>dataIntegration.maintenanceMode.from </span>
			<span>: {{lockDate}}</span>
			<div>{{labelactivate}} : {{displayName}}</div>
		</div>
		<button
			v-if="canUpdate"
			class="
				form-view__button
				form-view__button--button
				data-integration__maintenance-mode-button"
			@click="update"
		>{{buttonName}}</button>
	</section>
</template>

<script>
import can from 'acl-can';
import cwalert from 'cwalert';
import datetime from 'service/datetime/datetime';
import { clinicianName } from 'service/display-name/display-name';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';
import { delay } from 'lodash';

export default {
	acl: [{
		op: READ,
		checkpoint: SYSTEM.SETTINGS.MAINTENANCE_MODE
	}],
	actions: ({ $store }) => [['dataIntegration/getMaintenanceData', $store.getters.userId]],
	computed: {
		maintenanceMode: ({ $store }) => $store.getters['dataIntegration/maintenanceData'],

		buttonName: ({ maintenanceMode, t }) => maintenanceMode.active ?
			t('dataIntegration.maintenanceMode.return') :
			t('dataIntegration.maintenanceMode.activate'),

		canUpdate: () => can.edit(SYSTEM.SETTINGS.MAINTENANCE_MODE),

		containerClass: ({ maintenanceMode }) => {
			const classes = ['data-integration__maintenance-mode__container'];

			if (maintenanceMode.active) {
				classes.push('data-integration__maintenance-mode--active');
			}
			return classes;
		},

		displayName: ({ maintenanceMode }) => {
			if (!maintenanceMode.startedBy) {
				return '';
			}
			return unescape(clinicianName(maintenanceMode.startedBy));
		},
		labelactivate: ({ t }) => t('dataIntegration.maintenanceMode.activatedBy'),

		lockDate: ({ $store, maintenanceMode, t }) => {
			if (!maintenanceMode.startedAt) {
				return '';
			}
			return datetime(maintenanceMode.startedAt)
				.setTimezone($store.getters.userTimezone)
				.toMoment()
				.format(t('date.formats.dateTime'));
		},
		title: ({ maintenanceMode, t }) => maintenanceMode.active ?
			t('dataIntegration.maintenanceMode.maintenance') :
			t('dataIntegration.maintenanceMode.normal')
	},
	methods: {
		update () {
			this.setLoading();
			this.$store.dispatch(
				'dataIntegration/updateAndRefreshMaintenanceMode',
				{
					action: this.maintenanceMode.active ? 'unlock' : 'lock',
					userId: this.$store.getters.userId
				}
			).then(() => {
				delay(() => {
					this.setLoaded();
				}, 300);
				cwalert.success(this.t('dataIntegration.maintenanceMode.updated'));
			});
		}
	}
};
</script>
