import can from 'acl-can';
import change from '../../helpers/change';
import codeTypes from '../../dialogs/code-types';
import cwalert from 'service/cwalert';
import enabled from '../../helpers/enabled';
import onSuccessHandler from '../../helpers/on-success-handler';
import searchTileDelete from 'core/engine/tile/helpers/search-tile-delete';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import store from 'store';
import t from 'translate';
import { READ } from 'service/acl/access-levels';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { assign, map, pick } from 'lodash';

const SELF_NAME = 'codeType';

const removalCfg = {
	action: 'dataIntegration/codeTypes/delete',
	question: 'dataIntegration.codeType.actions.delete',
	title: 'dataIntegration.codeType.actions.remove'
};

export default SearchTileView.extend({
	title: t('dataIntegration.codeType.plural'),
	acl: [{ checkpoint: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE, op: READ }],
	searchFn (params) {
		const action = 'dataIntegration/codeTypes/search';
		const result = store.dispatch(action, assign({ name: SELF_NAME }, params));
		result.then(([response]) => {
			result.models = response.data;
		});
		return result;
	},
	fields: ({ tile }) => [{
		key: 'enabled',
		type: 'single-select',
		label: t('dataIntegration.codeType.fields.enabled'),
		values: map(enabled, (type) => assign(
			type,
			{ label: type.name }
		)),

		onChange: (value) => change({ field: 'enabled', tile, value })
	}],

	tableCfg ({ tile }) {
		const className = 'code-types-list';
		const modalCfg = {
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_CODE,
			action: 'codeTypes/update',
			edit: true,
			item: {},
			refresh: tile.runQuery,
			success: t('dataIntegration.codeType.messages.updated'),
			title: t('dataIntegration.codeType.actions.update'),
			type: 'codeType'
		};
		const config = {
			store: {
				items: () => store.getters['dataIntegration/codeTypes/list'],
				count: 'dataIntegration/codeTypesCount',
				mutation: 'dataIntegration/setCodeTypeList',
				total: 'dataIntegration/codeTypesTotal'
			},
			click: (model) => {
				if (can.read(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE)) {
					modalCfg.item = { ...pick(model, ['id', 'code', 'name', 'enabled']) };
					codeTypes(modalCfg);
				} else {
					cwalert.error(t('dataIntegration.codeType.messages.noAccess'));
				}
			},
			columns: [{
				label: t('dataIntegration.codeType.fields.name'),
				key: 'name',
				css: `${className}__name`
			}, {
				label: t('dataIntegration.codeType.fields.code'),
				key: 'code',
				css: `${className}__code`
			}, {
				label: t('dataIntegration.codeType.fields.enabled'),
				key: 'enabled',
				css: `${className}__enabled`,
				render: (enabledBool) => enabledBool ? t('yes') : t('no')
			}]
		};

		if (can.remove(SYSTEM.SETTINGS.DATA_INTEGRATION_CODE)) {
			const column = {
				label: t('Delete'),
				render () {
					searchTileDelete({
						data: removalCfg,
						item: this.item,
						onSuccess: onSuccessHandler({ tile, type: 'codeType' }),
						td: this.td,
						tile,
						onError: () => {
							cwalert.error(t('dataIntegration.codeType.messages.inUse'));
						}
					});
				}
			};
			config.columns.push(column);
		}
		return config;
	}
});
