import addConfig from '../helpers/add-config';
import dialog from '../dialogs/rule-sets';
import store from 'store';
import t from 'translate';
import { SYSTEM } from 'service/acl/checkpoints.json';
import { when } from 'jquery';

const card = {
	title: t('dataIntegration.ruleSets.name'),
	name: 'data-integration-rule-sets',
	url: 'data-integration/rule-sets',

	ctrl: ({ populate }) =>  {
		addConfig({
			acl: SYSTEM.SETTINGS.DATA_INTEGRATION_RULES,
			action: 'ruleSets/addAndRefreshWithTreatmentTypes',
			card,
			dataIntegrationFn: dialog,
			dispatch: [
				'dataIntegration/ruleSets/checkDefaultRuleSet',
				'dataIntegration/ruleSets/initWithEnterprise'
			],
			item: {
				defaultAccess: true,
				defaultCodes: null,
				defaultMappedTo: null,
				enabled: false,
				enterprise: null,
				treatmentType: null
			},
			populate,
			translateType: 'ruleSets',
			type: 'ruleSet'
		});
		return when(
			store.dispatch('dataIntegration/treatmentTypes'),
			store.dispatch('dataIntegration/codes/search', { enabled: true, search: '' }),
			store.dispatch('dataIntegration/enterprises/search', { enabled: true, search: '' }),
			store.dispatch('dataIntegration/codeTypes/search', { enabled: true, search: '' }),
			store.dispatch('dataIntegration/ruleSets/withTreatmentTypes')
		);
	}
};

export { card as ruleSets };
