import { every } from 'lodash';

export default function (query) {
	const keywords = query.toLowerCase().split(/\s+/);

	this.test = function (str) {
		const testStr = str.toLowerCase();

		let match = true;
		every(keywords, (keyword) => {
			if (testStr.indexOf(keyword) === -1) {
				match = false;
				return match;
			}

			return true;
		});

		return match;
	};
}
