import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { isFunction  } from 'lodash';

// Disclaimer: the name is clearly ugly. It's on purpose. B💩ckend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.
export default SingleEntity.extend({
	urlRoot: urls.singleAttributeValue.url,
	eventNamespace: 'clinician-attribute-value',
	idAttribute: 'administratorAttributeId',

	normalize () {
		const meta = this.get('fkAdministratorAttributeAdministratorAttributeMeta');

		if (meta) {
			const metaType = meta.administratorAttributeMetaType;
			const attributeValue = this.get('administratorAttributeValue');
			const actions = {
				CHECKBOX: (value) => [true, '1'].indexOf(value) > -1
			};

			isFunction(actions[metaType]) && this.set({
				administratorAttributeValue: actions[metaType](attributeValue)
			}, { silent: true });
		}
	},

	getClinicianId () {
		return this.get('fk_administrator_attribute_administrator_id');
	}
});

