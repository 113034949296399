import t from 'translate';
import store from 'store';
import appBar from 'app-bar';
import cwalert from 'service/cwalert';
import confirmation from 'components/confirmation/confirmation';
import cardControls from 'core/engine/card/services/card-controls';
import can from 'acl-can';
import { ADAPTER_INSTANCE } from 'store/adapters/adapter-instance';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	title: t('adapterInstance.title'),
	name: 'adapter-instance',
	url: 'system/adapter-instance/:adapterInstanceId',

	ctrl ({ populate }) {
		can.delete(SYSTEM.SETTINGS.ADAPTER_INSTANCES) && appBar.addButton({
			sourceCard: card.name,
			title: t('adapterInstance.remove.button'),
			icon: 'delete',
			click: () => {
				const adapterInstanceId = store.getters.urlParams.adapterInstanceId;

				confirmation({
					icon: 'delete',
					warning: true,
					title: t('adapterInstance.remove.title'),
					message: t('adapterInstance.remove.message')
				}).done(() => {
					store.dispatch(ADAPTER_INSTANCE.DELETE, { adapterInstanceId })
						.then(() => {
							cwalert.success(t('general.messages.remove.success'));
							cardControls.closeCard();
						})
						.catch(() => {
							cwalert.error(t('general.messages.changes-not-saved'));
						});
				});
			}
		});

		populate();
	}
};

export { card as adapterInstance };
