import Backbone from 'backbone';
import t from 'translate';
import shortcutBuilder from 'components/shortcut/shortcut-builder';
import { SYSTEM } from 'service/acl/checkpoints';

export default Backbone.View.extend({

	title: t('System exports'),
	acl: {
		op: 'or',
		checkpoints: [{
			checkpoint: 'system.export.administratorrespondentgroups',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.respondentgroups',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.administrators',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.respondents',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.workflows',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.assessmentdata',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.assessmentstatus',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.administratorgroups',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.loginsessions',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.assessmentdata',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.treatments',
			op: 'OPEN'
		}, {
			checkpoint: 'system.export.templates',
			op: 'OPEN'
		}, {
			checkpoint: SYSTEM.EXPORT.SYSTEM_EVENT_LOGS,
			op: 'OPEN'
		}]
	},

	render () {
		this.$el.append(shortcutBuilder(this.shortcutCfg));
		return this;
	},

	shortcutCfg: [{
		groupTitle: '',
		shortcuts: [{
			title: t('Clinician - respondent group mapping'),
			card: 'system-exports-clinician-to-respondent-groups-mapping',
			icon: 'sitemap'
		}, {
			title: t('Respondent groups'),
			card: 'system-exports-respondent-groups',
			icon: 'respondent-group'
		}, {
			title: t('Clinician groups'),
			card: 'system-exports-clinician-groups',
			icon: 'respondent-group'
		}, {
			title: t('Clinicians'),
			card: 'system-exports-clinicians',
			icon: 'clinician'
		}, {
			title: t('Respondents'),
			card: 'system-exports-respondents',
			icon: 'respondent'
		}, {
			title: t('Assessment plans'),
			card: 'system-exports-assessment-plans',
			icon: 'plan'
		}, {
			title: t('Assessment data'),
			card: 'system-exports-assessment-data',
			icon: 'assessment-data'
		}, {
			title: t('Assessment status'),
			card: 'system-exports-assessment-status',
			icon: 'assessment-status'
		}, {
			title: t('system.exports.exportTemplates'),
			card: 'system-exports-templates',
			icon: 'export-template'
		}, {
			title: t('Login sessions'),
			card: 'system-exports-login-sessions',
			icon: 'logout'
		}, {
			title: t('Treatments'),
			card: 'system-exports-treatments',
			icon: 'treatment'
		}, {
			title: t('System logs'),
			card: 'system-exports-logs',
			icon: 'log'
		}]
	}]
});
