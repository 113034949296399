<template>
	<div class="treatment-program-properties">
		<h3 class="treatment-program-properties__header">
			{{$t('treatmentProgram.properties')}}
		</h3>

		<form
			novalidate
			autocomplete="off"
			class="form-view"
			@submit.prevent="onSubmit"
		>
			<treatment-program-form
				v-model="treatmentProgram"
				editing
				@validate="onValidate"
				@input="onSubmit"
			/>
		</form>
	</div>
</template>

<script>
import { debounce } from 'lodash';
import cwalert from 'service/cwalert';
import { error } from 'service/log/log';
import TreatmentProgramForm from '../../../components/treatment-program-form';
import { TREATMENT_PROGRAM } from 'store/treatments/treatment-program';

export default {
	components: {
		TreatmentProgramForm
	},

	data: ({ $store }) => ({
		treatmentProgram: $store.getters[TREATMENT_PROGRAM.DATA],
		valid: false
	}),

	methods: {
		onValidate (valid) {
			this.valid = valid;
		},

		async onSubmit () {
			if (this.valid) {
				await this.save(this.treatmentProgram);
			}
		},

		save: debounce(async function (treatmentProgram) {
			try {
				await this.$store.dispatch(
					TREATMENT_PROGRAM.UPDATE,
					treatmentProgram
				);

				cwalert.success(this.$t('general.messages.update.success'));
			} catch (err) {
				cwalert.error(this.$t('general.messages.changes-not-saved'));
				error(err);
			}
		}, 500)
	}
};
</script>
