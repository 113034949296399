var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "treatment-program-properties" }, [
    _c("h3", { staticClass: "treatment-program-properties__header" }, [
      _vm._v(
        "\n\t\t" + _vm._s(_vm.$t("treatmentProgram.module.properties")) + "\n\t"
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form-view",
        attrs: { novalidate: "", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("module-form", {
          attrs: { editing: "" },
          on: { validate: _vm.onValidate, input: _vm.onSubmit },
          model: {
            value: _vm.module,
            callback: function($$v) {
              _vm.module = $$v
            },
            expression: "module"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }