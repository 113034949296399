import $ from 'jquery';
import repository, { Clinicians } from 'repository';
import t from 'translate';
import datetime from 'datetime';
import appContext from 'app-context';
import can from 'acl-can';
import cwalert from 'cwalert';
import cardUrl from 'cwcardurl';
import getFileType from 'service/get-file-type';
import icon from 'service/icon';
import confirmation from 'components/confirmation/confirmation';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import categoryTag from 'components/category-tag/category-tag';
import store from 'store';
import { assign, compact, get, map } from 'lodash';

const ACL_CHECKPOINT = 'system.settings.media';

export default SearchTileView.extend({
	title: t('Media and documents'),
	acl: [{
		op: 'READ',
		checkpoint: 'system.settings.media'
	}],

	searchFn: (...args) => repository.searchMedia(...args),
	fields: ({ tile }) => {
		const ids = (value) => compact(map(value, (item, key) => item && key));

		return [{
			key: 'category.id',
			type: 'multi-select',
			label: t('Category'),

			values: map(
				get(store.state, 'media.categories.data'),
				(category) => assign({}, category, {
					id: category.id,
					label: category.name
				})
			),

			onChange: (value) => {
				tile.viewModel.set('category.id', ids(value));
				tile.runQuery();
			}
		}];
	},

	tableCfg () {
		const className = 'search-results-list__entry media-list__entry media-list__';
		const mainClassName = 'search-results-list__entry--main';

		return {
			click: (mediaModel) => {
				cardUrl.openCard('edit-media', { mediaId: mediaModel.getId() });
			},
			rowsClickable: can.read(ACL_CHECKPOINT),
			columns: [{
				key: 'title',
				label: t('Title'),
				className: `${mainClassName} ${className}title`,
				render: (value, { item, td }) => {
					$(td)
						.addClass(`media-list__title--${getFileType(item.getType())}`)
						.text(item.get('title'))
						.append(categoryTag(item.get('category')));
				}
			}, {
				label: t('Last modified'),
				className: `${className}last-modified`,
				render: (value, { item }) => {
					if (!item.get('modifiedAt')) {
						return t('Not specified');
					}

					return datetime(item.get('modifiedAt'))
						.setTimezone(appContext.get('user').get('timezoneName'))
						.toMoment()
						.format(t('date.formats.dateTime'));
				}
			}, {
				label: t('Modified by'),
				className: `${className}modified-by`,
				render (value, { item }) {
					const clinician = new Clinicians.model(item.get('modifiedBy'));
					return clinician.displayName ? clinician.displayName() : t('Not specified');
				}
			}, {
				label: '',
				className: `${className}preview`,
				render: (value, { item, td }) => {
					const viewIcon = icon('show', {
						title: t('View file'),
						small: true
					});
					const $edit = $('<a />')
						.attr({
							target: '_blank',
							href: item.getPermalink()
						})
						.append(viewIcon);
					$edit.on('click', (e) => {
						e.stopPropagation();
					});

					$(td).html($edit);
				}
			}],
			deleteFilter: () => can.remove(ACL_CHECKPOINT),
			actions: {
				delete: (mediaModel, actions) => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete media'),
						message: t('Delete media "{mediaName}" ?', {
							mediaName: mediaModel.get('title')
						})
					}).then(() => {
						const mediaName = mediaModel.get('title');

						// so called soft delete
						mediaModel.save({ deleted: true }).then(() => {
							this.results.remove(mediaModel);
							cwalert.success(t('Media "{mediaName}" successfully removed', {
								mediaName
							}));

							// re-run the search, hacky way to notify filters that results have
							// changed
							this.runQuery();
						});

					}, () => {
						actions.cancel();
					});

					return false;
				}
			}
		};
	}
});
