var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shortcut__container" }, [
    _c(
      "a",
      {
        staticClass: "shortcut__action",
        attrs: { href: _vm.link(_vm.item.card) }
      },
      [
        _c("icon", {
          attrs: { name: _vm.item.icon, "class-name": "shortcut__icon" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "shortcut__caption" }, [
          _vm._v(_vm._s(_vm.t(_vm.item.title)))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }