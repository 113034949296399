import MultipleEntities from '../shared/multiple-entities';
import ContentPackageCategory from './category';
import urls from './urls';

const ContentPackageCategories = MultipleEntities.extend({
	model: ContentPackageCategory,
	urls,
	eventNamespace: 'content-package-category',

	getAll () {
		return this.retrieve('category');
	}
});

export default new ContentPackageCategories();
