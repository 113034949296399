import t from 'service/lang/translate';

const card = {
	name: 'goals',
	url: [
		'goals',
		'treatment/:treatmentId/goals',
		'respondent/:respondentId/goals'
	],
	title: t('goals.goals')
};

export { card as Goals };
