export default {
	fetchAdapters: {
		url: `backend/adapters`
	},

	fetchAdapterInstances: {
		url: `backend/adapter-instances`
	},

	fetchAdapterInstance: {
		url: (id) => `backend/adapter-instance/${id}`
	},

	createAdapterInstance: {
		url: `backend/adapter-instances`
	},

	deleteAdapterInstance: {
		url: (id) => `backend/adapter-instances/${id}`
	},

	fetchAdapterInstanceCapability: {
		url: (capabilityId) => `backend/adapter-instance-capabilities/${capabilityId}`
	},

	updateAdapterInstanceCapability: {
		url: (capabilityId) => `backend/adapter-instance-capabilities/${capabilityId}`
	},

	fetchAdapterInstanceSetting: {
		url: (settingId) => `backend/adapter-instance-settings/${settingId}`
	},

	updateAdapterInstanceSetting: {
		url: (settingId) => `backend/adapter-instance-settings/${settingId}`
	}
};
