import Vue from 'vue';
import cardUrl from 'cwcardurl';
import executeIfFunction from 'execute-if-function';
import { indexOf, some, isUndefined, isFunction, isNull, isEmpty, escape } from 'lodash';
import SimpleListComponent from './index';

/**
 *	Simplified-list component (Vue instance)
 *
 *	@usage new SimpleList({ params })
 *
 *	@param {object} items - array-like object with data to be displayed
 *	@param {object} content - html of item to loop through
 *	@param {array} urlParams - properties to build an item's URL-link
 *	@param {object} el - element the vue instance should be mounted to
 *	@param {object, props} className - callbacks called to check item state
 *	@param {object, props} classNameDesc - titles translations for item state
 *
 */

const SimpleList = ({ items, content, urlParams = [], el, className, classNameDesc }) =>
	new Vue({
		el,
		components: { SimpleList: SimpleListComponent },

		data: {
			items,
			content
		},

		methods: {
			className (item) {
				let result;

				some(['positive', 'neutral', 'negative'], (stateType) => {
					result = className[stateType](item) ? `state-${stateType}` : false;
					return result;
				});

				return result;
			},

			getTitle (item) {
				return {
					'state-positive': executeIfFunction(classNameDesc.positive),
					'state-negative': executeIfFunction(classNameDesc.negative),
					'state-neutral': executeIfFunction(classNameDesc.neutral)
				}[this.className(item)];
			},

			getUrl (item) {
				return isFunction(urlParams) && cardUrl.buildUrl(...urlParams(item));
			}
		},

		template: `
			<simple-list
				:items="items"
				:class-name="className"
				:get-title="getTitle"
				:get-url="getUrl"
				:content="content"
			/>
		`
	}).$el;

/*
 *	Helper template for item description
 */
const itemTemplate = ({ config, name, value, className, tag = 'span' }) => {
	if (indexOf(config, name) + 1 && !isUndefined(value) && !isNull(value) && !isEmpty(value)) {
		return `<${tag} class="${className}">${escape(value)}</${tag}>`;
	}

	return '';
};

export { SimpleList, itemTemplate };
